import { IUserGroupTableData, IUserGroupTableDataParams } from "./GroupAccessPage.interfaces";


const getGroupsTableData = ({ groups, onSettingsButtonClick }: IUserGroupTableDataParams): IUserGroupTableData[] => {
  return groups.map(({ created, id, name, smartCodes = [], userCount }) => {
    return {
      id,
      avatarData: {
        name,
      },
      name,
      smartCodes: smartCodes
        .filter(({ code }) => code)
        .map(({ code }) => code)
        .join(', '),
      userCount,
      created: created,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getGroupsTableData };
