export const NetfrontServices = {
  BONOBO: 'BONOBO',
  BONOBO_MANAGEMENT: 'BONOBO_MANAGEMENT',
  EKARDO: 'EKARDO',
  HOWLER: 'HOWLER',
  MURIQUI: 'MURIQUI',
  VERVET: 'VERVET',
  CAPUCHIN: 'CAPUCHIN',
  TRIPLE_E: 'TRIPLE_E',
};

const OURFUTURES_PROJECT_ID = 'Gelada-Project-6-531ecc29-b44f-4234-a91a-3d11be5cb63c';

const menuGroups = {
  content: 'Content',
  feedback: 'Feedback',
  notifications: 'Notifications',
  userEntry: 'User entry',
  questionnaires: 'Questionnaires',
  social: 'Social',
  users: 'Users',
};

const menuItems: { [key: string]: {
  iconId: string;
  isOnlyVisibleIfHasMobileApplication?: boolean;
  projectId?: string;
  route: string;
  text: string;
}} = {
  allModulePages: {
    route: 'export-all-module-pages',
    text: 'All module progress',
    iconId: '',
  },
  allModulePageCompletions: {
    route: 'export-all-module-page-completions',
    text: 'All module page completions',
    iconId: '',
  },
  bookmarks: {
    route: 'export-bookmarks',
    text: 'Bookmarks',
    iconId: '',
  },
  comments: {
    route: 'export-comments',
    text: 'Comments',
    iconId: 'id_comments_icon',
  },
  diary: {
    route: 'export-triplee-diaries',
    text: 'Diaries',
    iconId: '',
  },
  logAccess: {
    route: 'export-triplee-log-access',
    text: 'Log access',
    iconId: '',
  },
  userGoals: {
    route: 'export-triplee-user-goals',
    text: 'User goals',
    iconId: '',
  },
  feedback: {
    route: 'export-feedback',
    text: 'Feedback',
    iconId: '',
  },
  groups: {
    route: 'export-groups',
    text: 'Groups',
    iconId: 'id_groups_icon',
  },
  groupsContacts: {
    route: 'export-groups-contacts',
    text: 'Groups contacts',
    iconId: 'id_contacts_tab_icon',
  },
  logins: {
    route: 'export-logins',
    text: 'Logins',
    iconId: 'id_enter_icon',
  },
  sessions: {
    route: 'export-sessions',
    text: 'Sessions',
    iconId: '',
  },
  mobileAppAccess: {
    route: 'export-mobile-app-access',
    text: 'Mobile app access',
    isOnlyVisibleIfHasMobileApplication: true,
    iconId: 'id_mobile_icon',
  },
  modulePages: {
    route: 'export-module-pages',
    text: 'Module progress',
    iconId: '',
  },
  modulePageCompletions: {
    route: 'export-module-page-completions',
    text: 'Module page completions',
    iconId: '',
  },
  notifications: {
    route: 'export-notifications',
    text: 'Notifications',
    iconId: 'id_notify_users_icon',
  },
  pages: {
    route: 'export-pages',
    text: 'Pages loaded',
    iconId: '',
  },
  posts: {
    route: 'export-posts',
    text: 'Posts',
    iconId: 'id_posts_icon',
  },
  postLogs: {
    route: 'export-post-logs',
    text: 'Post Logs',
    iconId: 'id_resource_icon',
  },
  commentLogs: {
    route: 'export-comment-logs',
    text: 'Comment Logs',
    iconId: 'id_resource_icon',
  },
  questionnaires: {
    route: 'export-questionnaires',
    text: 'User responses',
    iconId: '',
  },
  users: {
    route: 'export-users',
    text: 'Profile information',
    iconId: 'id_user_icon',
  },
  loginsTrials: {
    route: 'export-logins-trials',
    text: 'Login trials',
    projectId: OURFUTURES_PROJECT_ID,
    iconId: 'id_enter_icon',
  },
};

const reportOptions = [
  {
    group: menuGroups.users,
    iconId: 'id_partners_icon',
    image: '',
    description: '',
    items: [
      menuItems.groups,
      menuItems.groupsContacts,
      menuItems.logins,
      menuItems.loginsTrials,
      menuItems.users,
      menuItems.sessions,
      menuItems.mobileAppAccess
    ],
  },
  {
    group: menuGroups.content,
    iconId: 'id_content_icon', // TODO: @ash this icon needs to be updated
    image: '',
    description: '',
    items: [
      menuItems.allModulePages,
      menuItems.allModulePageCompletions,
      menuItems.modulePages,
      menuItems.modulePageCompletions,
      menuItems.bookmarks,
      menuItems.pages,
    ],
    service: 'EKARDO',
  },
  {
    group: menuGroups.notifications,
    iconId: 'id_notify_users_icon',
    image: '',
    description: '',
    items: [menuItems.notifications],
    service: 'HOWLER',
  },
  {
    group: menuGroups.questionnaires,
    iconId: 'id_questions_icon',
    image: '',
    description: '',
    items: [menuItems.questionnaires],
    service: 'EKARDO',
  },
  {
    group: menuGroups.feedback,
    iconId: '',
    image: '',
    description: '',
    items: [menuItems.feedback],
    service: 'EKARDO',
  },
  {
    group: menuGroups.social,
    iconId: 'id_social_icon',
    image: '',
    description: '',
    items: [menuItems.comments, menuItems.commentLogs, menuItems.posts, menuItems.postLogs],
    service: 'BONOBO',
  },
  {
    group: menuGroups.userEntry,
    iconId: '',
    image: '',
    description: '',
    items: [menuItems.diary, menuItems.userGoals],
    service: 'TRIPLE_E',
  },
];

export { reportOptions };
