import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CONTENT_SECTION } from './useDeleteSection.graphql';
import {
  IDeleteSectionMutationGraphQLResponse,
  IDeleteSectionMutationVariables,
  IHandleDeleteSectionParams,
  IUseDeleteSectionOptions,
  IUseDeleteSection,
} from './useDeleteSection.interfaces';

const useDeleteSection = (options?: IUseDeleteSectionOptions): IUseDeleteSection => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteSectionOptions);

  const [executeDeleteSection, { loading: isLoading }] = useEkardoMutation<IDeleteSectionMutationGraphQLResponse, IDeleteSectionMutationVariables>({
    mutation: mutation ?? DELETE_CONTENT_SECTION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { deleteContentSection: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteSection = async ({ contentSectionId }: IHandleDeleteSectionParams) => {
    await executeDeleteSection({
      variables: {
        contentSectionId,
      },
    });
  };

  return {
    handleDeleteSection,
    isLoading,
  };
};

export { useDeleteSection };
