

import { useTripleELazyQuery } from 'hooks';

import { EXPORT_DIARIES } from './useGetDiariesReport.graphql';
import {
  IGetDiariesReportQueryGraphQLResponse,
  IGetDiariesReportQueryVariables,
  IHandleGetDiariesReportParams,
  IUseGetDiariesReport,
  IUseGetDiariesReportOptions,
} from './useGetDiariesReport.interfaces';

const useGetDiariesReport = (options?: IUseGetDiariesReportOptions): IUseGetDiariesReport => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: tripleEProjectId } = options ?? ({} as IUseGetDiariesReportOptions);

  const [executeGetDiariesReport, { loading: isLoading }] = useTripleELazyQuery<
    IGetDiariesReportQueryGraphQLResponse,
    IGetDiariesReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          project_id: tripleEProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportDiaries } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportDiaries,
        });
      },
      onError,
    },
    query: query ?? EXPORT_DIARIES,
    token,
  });


  const handleGetDiariesReport = async ({
    dateFrom,
    dateTo,
  }: IHandleGetDiariesReportParams) => {
    await executeGetDiariesReport({
      variables: {
        dateFrom,
        dateTo,
      },
    });
  };

  return {
    handleGetDiariesReport,
    isLoading,
  };
};

export { useGetDiariesReport };
