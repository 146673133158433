import { gql } from '@apollo/client';

const UPDATE_PAGE = gql`
  mutation UpdateContentPage($contentPage: UpdateContentPageInput!) {
    contentPage {
      updateContentPage(contentPage: $contentPage) {
        id
        url
      }
    }
  }
`;

export { UPDATE_PAGE };
