import React, { useContext, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ControlledForm, Dialog, FormFieldProps, SideBarTabSet, Spinner, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { AnimationTab, EventTab, UpsertSectionGeneralTab, animationTabValidation, eventTabValidation } from 'components';
import { ContentBuilderContext } from 'context';
import { useGetSectionDetails, useToast, useUpsertAssetWrapper, useUpsertSection } from 'hooks';
import { FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getSectionCommonVariables, getSectionDefaultValues } from './SectionSidebarView.helpers';



const SectionSidebarView = () => {
  const { isSectionSidebarOpen, closeSectionSidebar, sectionSidebarDetails, handleDeleteEntity, handleCreateEntity, handleUpdateSection: handleUpdateEntity, projectId, pageId } = useContext(ContentBuilderContext);
  const { handleToastError } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { containerId, sectionId, sort } = sectionSidebarDetails ?? {};

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        contentLayoutId: yup.number().label('Layout').required(),
        ...eventTabValidation,
        ...animationTabValidation,
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetSectionDetails, isLoading: isGetSectionDetailsLoading = false } = useGetSectionDetails({
    fetchPolicy: 'no-cache',
    onCompleted: ({ section: returnedSection }) => {
      setDefaultValues(getSectionDefaultValues({ contentLayoutId: Number(returnedSection.contentLayoutId), section: returnedSection }));
    },
    onError: handleGetError,
  });

  const { handleUpsertSection, isLoading: isUpsertSectionLoading = false } = useUpsertSection({
    onCreate: () => {
      reset();
      closeSectionSidebar();
      handleCreateEntity();
    },
    onUpdate: (returnedSection) => {
      reset();
      closeSectionSidebar();
      handleUpdateEntity(returnedSection);
    }
  });

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();

  const handleDelete = () => {
    if (!sectionId) return;
    setIsDeleteDialogOpen(false);
    handleDeleteEntity({
      type: 'section',
      id: Number(sectionId)
    });
  };

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const handleSave = (item: FormFieldProps) => {

    const {
      contentLayoutId: saveContentLayoutId,
      assetId,
    } = item;

    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: droppedFileEventAssetRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && assetId ? assetId : undefined,
      callBack: (asset?: IDBAsset) => {
        const commonVariables = getSectionCommonVariables({
          asset,
          eventAssetId: asset ? String(asset.assetId): undefined,
          sort: Number(sort),
          item,
          createContentLayoutId: !sectionId ? Number(saveContentLayoutId): undefined,
        });

        handleUpsertSection({
          contentPageId: Number(pageId),
          sectionId,
          containerId: Number(containerId),
          variables: commonVariables,
        });
      }
    });
  };


  useEffect(() => {
    if (!sectionId) {
      setDefaultValues(getSectionDefaultValues({}));
    } else {
      handleGetSectionDetails({
        contentSectionId: sectionId,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  const isLoading = isUpsertAssetLoading || isUpsertSectionLoading || isGetSectionDetailsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >


      <SideBarTabSet
        ref={tabsetRef}
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={() => {
          closeSectionSidebar();
        }}
        hasViewPadding={false}
        isSideBarOpen={isSectionSidebarOpen}
        tabs={[
          {
            iconId: 'id_general_tab_icon',
            id: 'id_general_tab',
            label: 'General',
            view: () => isSectionSidebarOpen ? (
              <UpsertSectionGeneralTab
                containerNumber={sectionId ? defaultValues.sectionContainers.length: undefined }
                control={control}
              />
            ) : <></>,
          },
          {
            iconId: 'id_usage_icon',
            id: 'id_event_tab',
            label: 'Event',
            view: () => isSectionSidebarOpen ? (
              <EventTab
                control={control}
                initialEvent={defaultValues.event}
                isSnippet={false}
                onDeleteAsset={handleRemoveAsset}
                onDrop={handleDropFile}
              />
            ) : <></>,
          },
          {
            iconId: 'id_film_icon',
            id: 'id_animation_tab',
            label: 'Animation',
            view: () => isSectionSidebarOpen ? (
              <AnimationTab
                animationType={defaultValues.animation ?? ''}
                control={control}
                isSnippet={false}
              />
            ) : <></>,
          },
        ]}
        onDelete={sectionId ? () => setIsDeleteDialogOpen(true): undefined }
        onSaveButtonType="submit"
      />
      </ControlledForm>

      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete section?`}
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};


export { SectionSidebarView };
