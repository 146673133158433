import { gql } from '@apollo/client';

export const FRAGMENT_SPACER = gql`
  fragment spacer on ContentSnippetSpacerType {
    height
    lineBreak
    widthPercentage
    alignment
  }
`;
