import { gql } from '@apollo/client';

const GET_GROUP_USERS = gql`
  query getUsers($groupId: Int!) {
    group {
      getUsers(groupId: $groupId) {
        userId
        user {
          id
          firstname
          lastname
          credential {
            email
          }
        }
      }
    }
  }
`;

export { GET_GROUP_USERS };
