import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_SINGLE_LINE_FEEDBACK_CONFIG } from './useUpsertSummaryFeedbackConfigSingleLine.graphql';
import {
  IUpsertSummaryFeedbackConfigSingleLineMutationGraphQLResponse,
  IUpsertSummaryFeedbackConfigSingleLineMutationVariables,
  IHandleUpsertSummaryFeedbackConfigSingleLineParams,
  IUseUpsertSummaryFeedbackConfigSingleLineOptions,
  IUseUpsertSummaryFeedbackConfigSingleLine,
} from './useUpsertSummaryFeedbackConfigSingleLine.interfaces';

const useUpsertSummaryFeedbackConfigSingleLine = (options?: IUseUpsertSummaryFeedbackConfigSingleLineOptions): IUseUpsertSummaryFeedbackConfigSingleLine => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpsertSummaryFeedbackConfigSingleLineOptions);

  const [executeUpsertSummaryFeedbackConfigSingleLine, { loading: isLoading }] = useEkardoMutation<IUpsertSummaryFeedbackConfigSingleLineMutationGraphQLResponse, IUpsertSummaryFeedbackConfigSingleLineMutationVariables>({
    mutation: mutation ?? CREATE_SINGLE_LINE_FEEDBACK_CONFIG,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { addSummaryFeedbackConfigurationSingleLine: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpsertSummaryFeedbackConfigSingleLine = async ({
    contentSnippetSummaryId,
    questionResponseSetId,
    label,
  }: IHandleUpsertSummaryFeedbackConfigSingleLineParams) => {
    await executeUpsertSummaryFeedbackConfigSingleLine({
      variables: {
        contentSnippetSummaryId,
        questionResponseSetId,
        label,
      }
    });
  };

  return {
    handleUpsertSummaryFeedbackConfigSingleLine,
    isLoading,
  };
};

export { useUpsertSummaryFeedbackConfigSingleLine };
