import { gql } from '@apollo/client';

const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($userId: Int!, $status: EUserStatus!) {
    user {
      updateUserStatus(userId: $userId, status: $status)
    }
  }
`;

export { UPDATE_USER_STATUS };
