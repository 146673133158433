import { gql } from '@apollo/client';

const CREATE_POST_ASSET_MUTATION = gql`
  mutation CreatePostAsset($asset: CreatePostAssetInputType!) {
    asset {
      createPostAsset(asset: $asset) {
        asset {
          presignedUrl
        }
        signedUrl
      }
    }
  }
`;

export { CREATE_POST_ASSET_MUTATION };
