import { useBonoboMutation } from '../useBonoboMutation';

import { UPDATE_POST_TOPIC_MUTATION } from './useUpdatePostTopic.graphql';
import {
  IUpdatePostTopicMutationGraphQLResponse,
  IUpdatePostTopicMutationVariables,
  IHandleUpdatePostTopicParams,
  IUseUpdatePostTopicOptions,
  IUseUpdatePostTopic,
} from './useUpdatePostTopic.interfaces';

const useUpdatePostTopic = (options?: IUseUpdatePostTopicOptions): IUseUpdatePostTopic => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseUpdatePostTopicOptions);

  const [executeUpdatePostTopic, { loading: isLoading }] = useBonoboMutation<
    IUpdatePostTopicMutationGraphQLResponse,
    IUpdatePostTopicMutationVariables
  >({
    mutation: mutation ?? UPDATE_POST_TOPIC_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          post: { updateTopic: postTopicConnection },
        } = data;

        onCompleted({ postTopicConnection });
      },
      onError,
    },
    token,
  });

  const handleUpdatePostTopic = async ({ postId, topicId }: IHandleUpdatePostTopicParams) => {
    await executeUpdatePostTopic({
      variables: {
        postId,
        topicId,
      },
    });
  };

  return {
    handleUpdatePostTopic,
    isLoading,
  };
};

export { useUpdatePostTopic };
