import { useBonoboMutation } from '../useBonoboMutation';

import { BULK_UPDATE_COMMUNITY_ROLE_MUTATION } from './useBulkUpdateCommunityRole.graphql';
import {
  IBulkUpdateCommunityRoleMutationGraphQLResponse,
  IBulkUpdateCommunityRoleMutationVariables,
  IHandleBulkUpdateCommunityRoleParams,
  IUseBulkUpdateCommunityRoleOptions,
  IUseBulkUpdateCommunityRole,
} from './useBulkUpdateCommunityRole.interfaces';

const useBulkUpdateCommunityRole = (options?: IUseBulkUpdateCommunityRoleOptions): IUseBulkUpdateCommunityRole => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseBulkUpdateCommunityRoleOptions);

  const [executeBulkUpdateCommunityRole, { loading: isLoading }] = useBonoboMutation<
  IBulkUpdateCommunityRoleMutationGraphQLResponse,
  IBulkUpdateCommunityRoleMutationVariables
  >({
    mutation: mutation ?? BULK_UPDATE_COMMUNITY_ROLE_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          communityConnection: { bulkUpdateCommunityRole: communityRoleConnection },
        } = data;

        onCompleted({ communityRoleConnection });
      },
      onError,
    },
    token,
  });

  const handleBulkUpdateCommunityRole = async ({ communityId, role, userIds }: IHandleBulkUpdateCommunityRoleParams) => {
    await executeBulkUpdateCommunityRole({
      variables: {
        communityId,
        role,
        userIds,
      },
    });
  };

  return {
    handleBulkUpdateCommunityRole,
    isLoading,
  };
};

export { useBulkUpdateCommunityRole };
