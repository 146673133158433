
import { IMobileAccessTableData, IMobileAccessTableDataParams } from "./MobileAccessReportPage.interfaces";


const getMobileAccessTableData = ({ items }: IMobileAccessTableDataParams): IMobileAccessTableData[] => {
  return items.map(({
    appVersion = '',
    dateOpened = '',
    email = '',
    firstName = '',
    id,
    lastDateOpened = '',
    lastName = '',
    schoolCode = '',
    totalOpen = '',
   }) => {
    return {
      appVersion,
      dateOpened,
      email,
      firstName,
      id,
      lastDateOpened,
      lastName,
      schoolCode,
      totalOpen,
    };
  });
};

export { getMobileAccessTableData };
