import { gql } from '@apollo/client';

const CREATE_CONTENT_SNIPPET = gql`
  mutation createContentSnippet(
    $baseSnippet: CreateContentSnippetInputType!,
    $audioSnippet: ContentSnippetAudioInputType,
    $buttonSnippet: ContentSnippetButtonInputType,
    $embedSnippet: ContentSnippetEmbedInputType,
    $summarySnippet: ContentSnippetSummaryInputType,
    $textSnippet: ContentSnippetTextInputType,
    $videoSnippet: ContentSnippetVideoInputType,
    $codeSnippet: ContentSnippetCodeInputType,
    $documentSnippet: ContentSnippetDocumentInputType,
    $formSnippet: ContentSnippetFormInputType,
    $imageSnippet: ContentSnippetImageInputType,
    $questionSnippet: ContentSnippetQuestionInputType,
    $quoteSnippet: ContentSnippetQuoteInputType,
  ) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: $baseSnippet,
        contentSnippetAudio: $audioSnippet,
        contentSnippetSummary: $summarySnippet,
        contentSnippetText: $textSnippet,
        contentSnippetVideo: $videoSnippet,
        contentSnippetCode: $codeSnippet,
        contentSnippetDocument: $documentSnippet,
        contentSnippetForm: $formSnippet,
        contentSnippetImage: $imageSnippet,
        contentSnippetQuestion: $questionSnippet,
        contentSnippetEmbed: $embedSnippet,
        contentSnippetButton: $buttonSnippet,
        contentSnippetQuote: $quoteSnippet,
      ) {
        id
      }
    }
  }
`;

export { CREATE_CONTENT_SNIPPET };
