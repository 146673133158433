import { Icon } from '@netfront/ui-library';
import format from 'date-fns/format';

import { IGetPostsTableDataParams, IPostsTableData } from './ModerationPage.interfaces';

const getPostsTableData = ({ posts, onSettingsButtonClick, onAttachmentButtonClick }: IGetPostsTableDataParams): IPostsTableData[] => {
  return posts.map(({ author, commentCount, createdDate, id, message, assets }): IPostsTableData => {

    const attachments = assets?.map((asset) => {
      const { presignedUrl, contentType } = asset;

      const [type] = contentType.split('/');
      return {
        icon: type === 'image' ? <img alt="post attachment" className="c-posts-table__attachment-image" src={presignedUrl} />: <Icon className="c-posts-table__attachment-icon" id="id_file_asset_icon" />,
        id,
        onClick: onAttachmentButtonClick,
      };
    });

    return {
      attachments: attachments ?? [],
      author,
      comments: commentCount,
      createdDate: format(new Date(createdDate), 'dd/MM/yyyy'),
      id,
      post: message,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getPostsTableData };
