import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_TAG_MUTATION } from './useCreateTag.graphql';
import {
  ICreateTagMutationGraphQLResponse,
  ICreateTagMutationVariables,
  IHandleCreateTagParams,
  IUseCreateTagOptions,
  IUseCreateTag,
} from './useCreateTag.interfaces';

const useCreateTag = (options?: IUseCreateTagOptions): IUseCreateTag => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateTagOptions);

  const [executeCreateTag, { loading: isLoading }] = useEkardoMutation<ICreateTagMutationGraphQLResponse, ICreateTagMutationVariables>({
    mutation: mutation ?? CREATE_TAG_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          tagMutation: {
            addTagInProject: { id, name },
          },
        } = data;

        void onCompleted({
          id,
          name,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateTag = async ({ projectId, tagName }: IHandleCreateTagParams) => {
    await executeCreateTag({
      variables: {
        projectId,
        tagName,
      },
    });
  };

  return {
    handleCreateTag,
    isLoading,
  };
};

export { useCreateTag };
