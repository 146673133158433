import { gql } from '@apollo/client';

const CREATE_FORM_MUTATION = gql`
  mutation CreateContentGroupForm(
    $projectId: String!
    $title: String!
    $description: String!
    $formType: EFormType
  ) {
    contentGroup {
      createContentGroupForm(
        contentGroupform: {
          projectId: $projectId
          title: $title
          description: $description
          formType: $formType
        }
      ) {
        id
        title
        description
        url
        formType
        contentPages {
          id
          contentSections {
            id
            sectionContainers {
              id
            }
          }
        }
      }
    }
  }
`;

export { CREATE_FORM_MUTATION };
