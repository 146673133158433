import { gql } from '@apollo/client';

const GET_ASSET_CAPTIONS_QUERY = gql`
  query GetAssetCaptions($assetId: String!) {
    caption {
      byAsset(assetId: $assetId) {
        from
        to
        id
        text
      }
    }
  }
`;

export { GET_ASSET_CAPTIONS_QUERY };
