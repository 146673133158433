import { useCapuchinLazyQuery } from 'hooks';

import { EXPORT_USER_SESSIONS } from './useGetUserSessionsReport.graphql';
import {
  IGetUserSessionsReportQueryGraphQLResponse,
  IGetUserSessionsReportQueryVariables,
  IHandleGetUserSessionsReportParams,
  IUseGetUserSessionsReport,
  IUseGetUserSessionsReportOptions,
} from './useGetUserSessionsReport.interfaces';

const useGetUserSessionsReport = (options?: IUseGetUserSessionsReportOptions): IUseGetUserSessionsReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetUserSessionsReportOptions);

  const [executeGetUserSessionsReport, { loading: isLoading }] = useCapuchinLazyQuery<
    IGetUserSessionsReportQueryGraphQLResponse,
    IGetUserSessionsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ reportQueries: { sessions } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: sessions,
        });
      },
      onError,
    },
    query: query ?? EXPORT_USER_SESSIONS,
    token,
  });


  const handleGetUserSessionsReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetUserSessionsReportParams) => {
    await executeGetUserSessionsReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetUserSessionsReport,
    isLoading,
  };
};

export { useGetUserSessionsReport };
