import { gql } from '@apollo/client';

const EXPORT_GROUPS_CONTACTS = gql`
  query ExportGroupContacts($projectId: String!, $fileType: FileType, $ianaTimeZone: String!) {
    report {
      groupContacts(projectId: $projectId, fileType: $fileType, ianaTimeZone: $ianaTimeZone) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_GROUPS_CONTACTS };
