import { gql } from '@apollo/client';

const DELETE_SUMMARY_FEEDBACK_ITEM = gql`
  mutation deleteSummaryFeedbackConfiguration($contentSnippetSummaryId: Int!) {
    summaryFeedback {
      deleteSummaryFeedbackConfiguration(contentSnippetSummaryId: $contentSnippetSummaryId)
    }
  }
`;

export { DELETE_SUMMARY_FEEDBACK_ITEM };
