import { gql } from '@apollo/client';

const UPDATE_ASSET_CAPTION_MUTATION = gql`
  mutation UpdateAssetCaption($id: Int!, $from: Int!, $to: Int!, $text: String = null) {
    caption {
      update(id: $id, from: $from, to: $to, text: $text) {
        from
        to
        id
        text
      }
    }
  }
`;

export { UPDATE_ASSET_CAPTION_MUTATION };
