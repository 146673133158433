import { gql } from '@apollo/client';

const CREATE_NUMBER_CONFIGURATION = gql`
  mutation createNumberQuesConfigRequest(
    $projectId: String!
    $description: String!
    $defaultValue: Int!
    $maxValue: Int!
    $minValue: Int!
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationNumber: {
          defaultValue: $defaultValue
          maxValue: $maxValue
          minValue: $minValue
        }
      ) {
        id
      }
    }
  }
`;


export { CREATE_NUMBER_CONFIGURATION };
