import React from 'react';

import { CheckboxGroup } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { ConditionCheckboxQuestionProps } from './ConditionCheckboxQuestion.interfaces';

const ConditionCheckboxQuestion = ({ control, questionResponses }: ConditionCheckboxQuestionProps) => {
  return (
    <Controller
      control={control}
      name="questionResponseCheckboxId"
      render={({ field }) => (

        <CheckboxGroup
          items={questionResponses}
          legendText="Question response"
          tooltipPosition="start"
          tooltipText="This action will execute if the checkbox selection is as selected below"
          values={field.value}
          isLabelSideBySide
          {...field}
        />

      )}
    />
  );
};


export { ConditionCheckboxQuestion };
