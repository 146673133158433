import { useVervetLazyQuery } from '../useVervetLazyQuery';

import { GET_ALL_PROJECT_DISCOUNTS_QUERY } from './useGetAllProjectDiscounts.graphql';
import {
  IGetAllProjectDiscountsQueryGraphQLResponse,
  IGetAllProjectDiscountsQueryVariables,
  IHandleGetAllProjectDiscountsParams,
  IUseGetAllProjectDiscounts,
  IUseGetAllProjectDiscountsOptions,
} from './useGetAllProjectDiscounts.interfaces';

const useGetAllProjectDiscounts = (options?: IUseGetAllProjectDiscountsOptions): IUseGetAllProjectDiscounts => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAllProjectDiscountsOptions);

  const [executeGetAllProjectDiscounts, { loading: isLoading }] = useVervetLazyQuery<
    IGetAllProjectDiscountsQueryGraphQLResponse,
    IGetAllProjectDiscountsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          discounts: { getAllProjectDiscounts: discountsConnection },
        } = data;

        onCompleted({
          discountsConnection,
        });
      },
      onError,
    },
    query: query ?? GET_ALL_PROJECT_DISCOUNTS_QUERY,
    token,
  });

  const handleGetAllProjectDiscounts = ({ projectGuid }: IHandleGetAllProjectDiscountsParams) => {
    void executeGetAllProjectDiscounts({
      variables: {
        projectGuid,
      },
    });
  };

  return {
    handleGetAllProjectDiscounts,
    isLoading,
  };
};

export { useGetAllProjectDiscounts };
