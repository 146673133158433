import { gql } from '@apollo/client';

const EXPORT_USER_GOALS = gql`
  query exportUserGoals($dateFrom: DateTime!, $dateTo: DateTime!) {
    report {
      exportUserGoals(start: $dateFrom, end: $dateTo) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_USER_GOALS };
