import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_COUNTRIES_QUERY } from './useGetCountries.graphql';
import {
  IGetCountriesQueryGraphQLResponse,
  IUseGetCountries,
  IUseGetCountriesOptions,
} from './useGetCountries.interfaces';

const useGetCountries = (options?: IUseGetCountriesOptions): IUseGetCountries => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetCountriesOptions);

  const [executeGetCountries, { loading: isLoading }] = useEkardoLazyQuery<IGetCountriesQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          __type: { enumValues },
        } = data;

        void onCompleted({ enumValues });
      },
      onError,
    },
    query: query ?? GET_COUNTRIES_QUERY,
    token,
  });

  const handleGetCountries = () => {
    void executeGetCountries();
  };

  return {
    handleGetCountries,
    isLoading,
  };
};

export { useGetCountries };
