import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DEFINE_RESPONSE_SET } from './useDefineResponseSet.graphql';
import {
  IDefineResponseSetMutationGraphQLResponse,
  IDefineResponseSetMutationVariables,
  IHandleDefineResponseSetParams,
  IUseDefineResponseSetOptions,
  IUseDefineResponseSet,
} from './useDefineResponseSet.interfaces';

const useDefineResponseSet = (options?: IUseDefineResponseSetOptions): IUseDefineResponseSet => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDefineResponseSetOptions);

  const [executeDefineResponseSet, { loading: isLoading }] = useEkardoMutation<IDefineResponseSetMutationGraphQLResponse, IDefineResponseSetMutationVariables>({
    mutation: mutation ?? DEFINE_RESPONSE_SET,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { defineQuestionResponseSet: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleDefineResponseSet = async ({
    questionConfigurationId,
    questionResponseSetId,
  }: IHandleDefineResponseSetParams) => {
    await executeDefineResponseSet({
      variables: {
        questionConfigurationId,
        questionResponseSetId,
      }
    });
  };

  return {
    handleDefineResponseSet,
    isLoading,
  };
};

export { useDefineResponseSet };
