
const LANGUAGE_FILTER_BULK_ACTION_DIALOG_TITLE_MAP = Object.freeze({
  updateType: 'Bulk update language filter type',
  delete: 'Bulk delete language filters',
});


const LANGUAGE_FILTER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP = Object.freeze({
  updateType: 'Update',
  delete: 'Delete',
});

export { LANGUAGE_FILTER_BULK_ACTION_DIALOG_TITLE_MAP, LANGUAGE_FILTER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP };
