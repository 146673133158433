import { useBonoboMutation } from '../useBonoboMutation';

import { CREATE_COMMENT_MUTATION } from './useCreateComment.graphql';
import {
  ICreateCommentMutationGraphQLResponse,
  ICreateCommentMutationVariables,
  IHandleCreateCommentParams,
  IUseCreateCommentOptions,
  IUseCreateComment,
} from './useCreateComment.interfaces';

const useCreateComment = (options?: IUseCreateCommentOptions): IUseCreateComment => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseCreateCommentOptions);

  const [executeCreateComment, { loading: isLoading }] = useBonoboMutation<
    ICreateCommentMutationGraphQLResponse,
    ICreateCommentMutationVariables
  >({
    mutation: mutation ?? CREATE_COMMENT_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          comment: { create: commentsConnection },
        } = data;

        onCompleted({ commentsConnection });
      },
      onError,
    },
    token,
  });

  const handleCreateComment = async ({ message, postId }: IHandleCreateCommentParams) => {
    await executeCreateComment({
      variables: {
        message,
        postId,
      },
    });
  };

  return {
    handleCreateComment,
    isLoading,
  };
};

export { useCreateComment };
