import { IDBAsset } from '@netfront/common-library';
import { IDBContentEvent } from '@netfront/ekardo-content-library';
import { FormFieldProps } from '@netfront/ui-library';
import * as yup from 'yup';

const eventTabValidation = {
  event: yup.string(),
  style: yup.string().when('event', {
    is: (event: string) => event && event !== 'NONE',
    then: yup.string().label('Trigger type').required(),
    otherwise: yup.string().nullable(),
  }),
  iconHorizontalAlignment: yup.string().when('event', {
    is: (event: string) => event && event !== 'NONE',
    then: yup.string().label('Alignment').required(),
    otherwise: yup.string().nullable(),
  }),
  eventDirection: yup.string().when('event', {
    is: (event: string) => event && event !== 'NONE',
    then: yup.string().label('Direction').required(),
    otherwise: yup.string().nullable(),
  }),
  triggerStyle: yup.string().when(['event', 'style'], {
    is: (event: string, style: string) => event === 'REVEAL' && style !== 'ICON_ONLY',
    then: yup.string().label('Trigger icon').required(),
    otherwise: yup.string().nullable(),
  }),
  eventTitle: yup.string().when(['event', 'style'], {
    is: (event: string, style: string) => event && event !== 'NONE' && ['TEXT_AND_ICON', 'TEXT_ONLY'].includes(style),
    then: yup.string().label('Label').required(),
    otherwise: yup.string().nullable(),
  }),
};

const getContentEventDetails = (contentEvent?: IDBContentEvent): FormFieldProps => {
  const {
    id: contentEventId,
    event,
    style,
    eventTitle,
    iconHorizontalAlignment,
    defaultToOpen: isDefaultOpen = false,
    eventDirection,
    triggerStyle,
    titleColor,
    borderColor,
    backgroundColor,
    asset,
  } = contentEvent ?? {};

  const { presignedUrl, assetId } = asset ?? {};

  return {
    assetId,
    contentEventId,
    backgroundColor: backgroundColor ?? '#fff',
    borderColor: borderColor ?? '#fff',
    event,
    eventDirection,
    eventTitle: eventTitle ?? '',
    iconHorizontalAlignment,
    image: presignedUrl ?? '',
    isDefaultOpen,
    style,
    triggerStyle,
    titleColor: titleColor ?? '#000',
  };
};

const getFormattedContentEvent = ({ item, asset, assetId, type = 'SNIPPET' }: {asset?: IDBAsset; assetId?: IDBAsset['assetId']; item: FormFieldProps; type?: string }) => {
  const {
    assetId: currentAssetId,
    backgroundColor,
    borderColor,
    contentEventId,
    event,
    eventDirection,
    eventTitle,
    titleColor,
    iconHorizontalAlignment,
    isDefaultOpen,
    style,
    triggerStyle,
  } = item;

  return {
    asset,
    assetId: assetId ?? currentAssetId,
    backgroundColor,
    borderColor,
    contentEntity: type,
    contentEventId,
    event,
    eventDirection,
    eventTitle,
    iconHorizontalAlignment,
    isDefaultOpen,
    style,
    titleColor,
    triggerStyle,
  };
};

const getUpsertContentEventStatus = (item: FormFieldProps) => {
  const { contentEventId, event, animation } = item;
  const shouldUpdateEvent = Boolean(contentEventId);
  const shouldCreateEvent = !contentEventId && (Boolean(event) || Boolean(animation));

  return {
    shouldCreateEvent,
    shouldUpdateEvent,
  };
};


const getUpsertContentEventVariables = (item: FormFieldProps, type: 'create' | 'update'): FormFieldProps => {
  const newItem = {...item};

  if (type === 'create') {
    // CreateContentEventInput input does not require this parameter but we cannot pass it as part of the input as api blows up
    delete newItem.contentEventId;
  } else {
    // UpdateContentEventInput input does not require this parameter but we cannot pass it as part of the input as api blows up
    delete newItem.contentEntity;
  }

  return newItem;
};

const getQuickAddEventValues = (values: FormFieldProps) => {
  return {
    ...values,
    assetId: undefined,
    image: undefined,
    contentEventId: undefined
  };
}

export { eventTabValidation, getContentEventDetails, getFormattedContentEvent, getUpsertContentEventStatus, getUpsertContentEventVariables, getQuickAddEventValues };
