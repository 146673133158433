import { gql } from '@apollo/client';

const CREATE_CONTENT_EVENT = gql`
  mutation CreateContentEvent($contentEvent: CreateContentEventInput!) {
    contentEvent {
      createEvent(
        contentEvent: $contentEvent
      ) {
        id
      }
    }
  }
`;

export { CREATE_CONTENT_EVENT };
