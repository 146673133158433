import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { GlossaryItemBulkAddTab, GlossaryItemGeneralTab , TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useSearchPaginatedGlossary, useToast } from 'hooks';
import { IGlossaryListItem } from 'interfaces';
import last from 'lodash.last';
import { useRouter } from 'next/router';



import { GLOSSARY_TABLE_COLUMNS } from './GlossaryPage.constants';
import { getGlossaryTableData } from './GlossaryPage.helpers';
import { IGlossaryTableData } from './GlossaryPage.interfaces';


const GlossaryPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError } = useToast();
  const defaultPageSize = 10;

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allGlossaryItems, setAllGlossaryItems] = useState<IGlossaryListItem[]>();
  const [filter, setFilter] = useState<string>();
  const [glossaryItem, setGlossaryItem] = useState<IGlossaryListItem>();
  const [glossaryTableData, setGlossaryTableData] = useState<IGlossaryTableData[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalGlossaryItems, setTotalGlossaryItems] = useState(0);

  const {
    handleFetchMorePaginatedGlossary,
    handleSearchPaginatedGlossary,
    isLoading: isGetGlossaryLoading,
  } = useSearchPaginatedGlossary({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ glossaryList: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const glossaryItems = edges.map(({ node }) => node);
      setAllGlossaryItems(glossaryItems);
      setIsLoadMoreDisabled(glossaryItems.length >= totalCount || totalCount <= pageSize);
      setTotalGlossaryItems(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleFilterSearch = (value: string) => {
    void handleSearchPaginatedGlossary({
      first: pageSize,
      projectId: String(projectId),
      filter: value,
    });

    setFilter(value);
  };

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setGlossaryItem(undefined);
  };

  const handleUpdate = () => {
    handleCloseSidebar();
    void handleSearchPaginatedGlossary({
      first: pageSize,
      projectId: String(projectId),
      filter: filter,
    });
  }

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };


  const addNewGlossaryItem = () => {
    setGlossaryItem(undefined);
    setIsSideBarOpen(true);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleSearchPaginatedGlossary({
      first: pageSize,
      projectId: String(projectId),
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allGlossaryItems) {
      return;
    }

    setGlossaryTableData(
      getGlossaryTableData({
        glossaryItems: allGlossaryItems,
        onSettingsButtonClick: (item) => {
          setIsSideBarOpen(true);
          setGlossaryItem(item);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGlossaryItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <TablePageTemplate<IGlossaryTableData>
      activePage="library"
      activeSubPage="glossary"
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Library',
      }, {
        key: '2',
        content: 'Glossary',
      }]}
      columns={GLOSSARY_TABLE_COLUMNS}
      data={glossaryTableData}
      defaultActiveTabId="id_general_tab"
      description={`Glossary for ${String(projectName)}`}
      handleAddNewClick={addNewGlossaryItem}
      handleOnPageSizeChange={handleOnPageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMorePaginatedGlossary({
          after: lastCursor,
          first: pageSize,
          projectId: String(projectId),
          filter: filter,
        });
      }}
      handleSearch={handleFilterSearch}
      informationBoxMessage={'Use the glossary to highlight important terms in your project and activate a tooltip description for further information.'}
      isLoading={isGetGlossaryLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      searchPlaceholder="Search"
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="glossary items"
      tabs={[
        {
          iconId: 'id_general_tab_icon',
          id: 'id_general_tab',
          label: 'General',
          view: () => <GlossaryItemGeneralTab glossaryItem={glossaryItem} projectId={String(projectId)} onClose={handleCloseSidebar} onUpdate={handleUpdate}/>,
        },
        {
          iconId: 'id_general_tab_icon',
          id: 'id_bulk_add_tab',
          label: 'Bulk upload',
          isHidden: Boolean(glossaryItem),
          view: () => <GlossaryItemBulkAddTab projectId={String(projectId)} onClose={handleCloseSidebar} onUpdate={handleUpdate}/>,
        },
      ]}
      title={`${String(projectName)} Glossary`}
      toggleIsSideBarOpen={handleCloseSidebar}
      totalItems={totalGlossaryItems}
      hideSideBarButtons
    />
  );
};

export { GlossaryPage };
