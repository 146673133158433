import { Column } from 'react-table';

import { IUserSessionsTableData } from './SessionsReportPage.interfaces';



const USER_SESSION_COLUMNS: ReadonlyArray<Column<IUserSessionsTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
    width:'7%',
  },
  {
    accessor: 'startSession',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Start session</div>,
  },
  {
    accessor: 'startSessionUtc',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Start session (utc)</span>,
  },
  {
    accessor: 'endSession',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">End session</span>,
  },
  {
    accessor: 'endSessionUtc',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">End session (utc)</span>,
  },

];

export { USER_SESSION_COLUMNS };
