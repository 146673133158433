import { Column } from 'react-table';

import { IBookmarkTableData } from './BookmarksPage.interfaces';


const BOOKMARK_COLUMNS: ReadonlyArray<Column<IBookmarkTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
    width: '10%',
  },
  {
    accessor: 'userType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User type</div>,
    width: '10%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
  },
  {
    accessor: 'moduleName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Module title</span>,
  },
  {
    accessor: 'pageUrl',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Page url</span>,
  },
  {
    accessor: 'createdOn',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Created</span>,
  },
];

export { BOOKMARK_COLUMNS };
