import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, DatePicker, Dialog, FlexContainer, IDateRange, IOption, Select, ToggleSwitch, Toolbar } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { getReportDropdownOptions, TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetQuestionnairesReport, useGetProjectSteps, useToast, useGetQuestionnairesReportFromRequest } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { QUESTIONNAIRES_COLUMNS } from './QuestionnairesReportPage.constants';
import { getQuestionnairesTableData, removeHeadersFromArray } from './QuestionnairesReportPage.helpers';
import { QuestionnairesCsvRowData, IQuestionnairesItem, IQuestionnairesTableData } from './QuestionnairesReportPage.interfaces';

import { DROPDOWN } from '../../Reporting.constants';


const QuestionnairesReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [questionnairesItems, setQuestionnairesItems] = useState<IQuestionnairesItem[]>();
  const [allQuestionnairesItems, setAllQuestionnairesItems] = useState<IQuestionnairesItem[]>([]);
  const [questionnairesTableData, setQuestionnairesTableData] = useState<IQuestionnairesTableData[]>([]);
  const [dateRange, setDateRange] = useState<IDateRange>();
  const [doesIncludeNonAccountData, setDoesIncludeNonAccountData] = useState<boolean>(false);
  const [doesIncludeDeletedQuestions, setDoesIncludeDeletedQuestions] = useState<boolean>(false);
  const [headingType, setHeadingType] = useState<string>('');
  const [stepId, setStepId] = useState<number>();
  const [userFlowOptions, setUserFlowOptions] = useState<IOption[]>([]);
  const [fileType, setFileType] = useState<'EXCEL' | 'CSV' | 'SPSS'>('CSV');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [requestId, setRequestId] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);



  const { handleGetProjectSteps, isLoading: isGetProjectStepsLoading = false } = useGetProjectSteps({
    onCompleted: (data) => {
        const { userFlowSteps } = data;

        setUserFlowOptions(
          getReportDropdownOptions(
            userFlowSteps,
            [{ key: 'stepName' }, { key: 'url', prefix: 'URL: /' }],
            'id',
          )
        );
    }
  });

  const {
    handleGetQuestionnairesReportFromRequest,
    isLoading: isGetQuestionnairesFromRequestLoading = false,
  } = useGetQuestionnairesReportFromRequest({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { asset } }) => {
      const { presignedUrl } = asset ?? {};
      if (!presignedUrl) {
        void handleGetQuestionnairesReportFromRequest({ id: String(requestId)});
        return;
      }

      if (isViewOnly) {
        setIsDialogOpen(false);
        axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data, meta } = Papa.parse<QuestionnairesCsvRowData>(String(res.data), {
            header: true,
            skipEmptyLines: true,
          });

          const { fields } = meta;

          const questionHeaders = removeHeadersFromArray(fields ?? [], ['User Id', 'Tracking Id', 'Group name', 'User type', 'Tag', 'Started (utc)', 'Completed (utc)', 'Time to complete']);

          const formattedItems = data.map((row: QuestionnairesCsvRowData) => {
            const questionKeyValues: {[key: string]: string} = {};
            questionHeaders.forEach((header) => {
              questionKeyValues[header] = String(row[header]);
            });
            return {
              id: Number(row['User Id']),
              trackingId: String(row['Tracking Id']),
              groupName: String(row['Group name']),
              userType: String(row['User type']),
              tag: String(row['Tag']),
              startedOnUtc: String(row['Started (utc)']),
              completedOnUtc: String(row['Completed (utc)']),
              timeToComplete: String(row['Time to complete']),
              ...questionKeyValues
            }
          });
          
          setTotalCount(data.length);
          setAllQuestionnairesItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setQuestionnairesItems(paginatedItems);
          setLastItemIndex(pageSize);

        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
        setIsViewOnly(false);
      } else {
        setIsDialogOpen(false);
        window.open(presignedUrl, '_blank');
      }

    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const {
    handleGetQuestionnairesReport,
    isLoading: isGetQuestionnairesLoading = false,
  } = useGetQuestionnairesReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { id } }) => {
      if (!id) return;
      setRequestId(String(id));
      void handleGetQuestionnairesReportFromRequest({ id });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleDownload = () => {
    if (!headingType || !stepId) return;
    void handleGetQuestionnairesReport({
      dateFrom: dateRange?.start ? dateRange.start : undefined,
      dateTo: dateRange?.end ? dateRange.end : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType,
      columnHeadingType: headingType,
      includeNonAccountData: doesIncludeNonAccountData,
      stepId,
      includeDeletedQuestions: doesIncludeDeletedQuestions
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateReport = () => {

    if (!headingType || !stepId) return;
    void handleGetQuestionnairesReport({
      dateFrom: dateRange?.start ? dateRange.start : undefined,
      dateTo: dateRange?.end ? dateRange.end : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: 'CSV',
      columnHeadingType: headingType,
      includeNonAccountData: doesIncludeNonAccountData,
      stepId,
      includeDeletedQuestions: doesIncludeDeletedQuestions
    });
  };

  const openGenerateDialog = (isViewDataOnly = false) => {
    setIsDialogOpen(true);
    setIsViewOnly(isViewDataOnly);
  };

  const handleDownloadType = (type: 'EXCEL' | 'CSV' | 'SPSS') => {
    setFileType(type);
    setIsDialogOpen(true);
    setIsViewOnly(false);
  };

  const cancelOpenDialog = () => {
    setIsDialogOpen(false);
    setIsViewOnly(false);
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setQuestionnairesItems(allQuestionnairesItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setQuestionnairesItems(allQuestionnairesItems.slice(0, updateIndex));
      resolve();
    });
  };


  useEffect(() => {
    if (!questionnairesItems) {
      return;
    }

    setQuestionnairesTableData(
      getQuestionnairesTableData({
        items: questionnairesItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnairesItems]);

  useEffect(() => {
    if (!projectId) return;
    void handleGetProjectSteps({ projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetQuestionnairesLoading || isGetProjectStepsLoading || isGetQuestionnairesFromRequestLoading;

  return (
    <>
      <TablePageTemplate<IQuestionnairesTableData>
        activePath={['reporting', 'reporting-questionnaires', 'export-questionnaires']}
        additionalBreadcrumbItems={[{
          key: '1',
          content: 'Reporting',
        }, {
          key: '2',
          content: 'Questionnaire user responses',
        }]}
        childrenEnd={
          <div className="c-report-datepicker">
            <DatePicker
              hasPadding={false}
              id="id_date_selector"
              labelText="Select date range"
              selectedDate={dateRange}
              isLabelHidden
              isRange
              onDateRangeChangeHandler={(e) => {
                if (!e) return;
                setDateRange(e);
              }}
            />
          </div>
        }
        childrenMiddle={
          <div className="c-report-heading-select">
            <Select
              hasPadding={false}
              id="id_heading_type_select"
              labelDefaultText="Select a column heading type"
              labelText="Heading type"
              name="heading_type"
              options={[
                {
                  id: 'IDENTIFIER',
                  name: 'Question identifier',
                  value: 'IDENTIFIER',
                },
                {
                  id: 'QUESTION',
                  name: 'Question label',
                  value: 'QUESTION',
                },
                {
                  id: 'CONCATENATING',
                  name: 'Question responses concatenated',
                  value: 'CONCATENATING',
                }
              ]}
              value={String(headingType)}
              isLabelHidden
              onChange={({ target: { value }}) => setHeadingType(value)}
            />
          </div>
        }
        childrenStart={
          <Select
            additionalClassNames="c-report-step-select"
            hasPadding={false}
            id="id_userflow_select"
            labelDefaultText={DROPDOWN.DEFAULT_OPTION_LABEL.STEP}
            labelText="Step"
            name="userflow_select"
            options={userFlowOptions}
            value={String(stepId)}
            isLabelHidden
            onChange={({ target: { value }}) => setStepId(Number(value))}
          />
        }
        columns={QUESTIONNAIRES_COLUMNS}
        data={questionnairesTableData}
        description={`User questionniare response reports for ${String(projectName)}`}
        handleOnPageSizeChange={handleChangePageSize}
        handleOnPaginate={handlePaginate}
        informationBoxMessage={'This page exports user questionnaire responses based on the selected filters. The result of the export will be transfered by email directly in your inbox.'}
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        logoUrl={project?.logo?.presignedUrl}
        pageSize={pageSize}
        pageTitle={String(projectName)}
        size={project?.logo?.presignedUrl ? 'small': 'medium'}
        tableType="reports"
        title={`${String(projectName)} reporting`}
        totalItems={totalCount}
      >
        <Toolbar
        childrenEnd={
          headingType && stepId ? (
            <FlexContainer gap="2x-small">
              <Button
                size="xs"
                text="View data"
                onClick={() => openGenerateDialog(true)}
              />
              <Button
                additionalClassNames="c-report-download-button"
                iconId="id_csv_icon"
                size="xs"
                text="Download as csv"
                isIconOnly
                onClick={() => handleDownloadType('CSV')}
              />
              <Button
                additionalClassNames="c-report-download-button"
                iconId="id_excel_icon"
                size="xs"
                text="Download as excel"
                isIconOnly
                onClick={() => handleDownloadType('EXCEL')}
              />
              <Button
                size="xs"
                text="SPSS"
                onClick={() => handleDownloadType('SPSS')}
              />
            </FlexContainer>
          ): undefined
        }
          childrenMiddle={
            <FlexContainer gap="2x-small">
              <ToggleSwitch
                additionalClassNames="c-report-toggle"
                id="id_include_non_account_data"
                isChecked={doesIncludeNonAccountData}
                labelText="Include non account data"
                isInline
                onChange={({ target: { checked: isChecked }}) => setDoesIncludeNonAccountData(isChecked)}
              />
              <ToggleSwitch
                additionalClassNames="c-report-toggle"
                id="id_include_deleted_questions"
                isChecked={doesIncludeDeletedQuestions}
                labelText="Include deleted questions"
                isInline
                onChange={({ target: { checked: isChecked }}) => setDoesIncludeDeletedQuestions(isChecked)}
              />

            </FlexContainer>
          }
          label="filters"
        />
      </TablePageTemplate>
      <Dialog
        confirmText={isViewOnly ? 'Generate' : 'Download'}
        isOpen={isDialogOpen}
        title={isViewOnly ? 'Generate data for viewing' : `Download ${fileType}`}
        onCancel={cancelOpenDialog}
        onClose={cancelOpenDialog}
        onConfirm={isViewOnly ? generateReport : handleDownload}
      >
        <p>Due to the nature of this report. It could take some time to prepare. Please be patient.</p>
      </Dialog>
    </>
  );
};

export { QuestionnairesReportPage };
