/* eslint-disable no-console */
import { useContext } from 'react';

import {
  Container,
  EmptyState,
  Spinner,
} from '@netfront/ui-library';
import { ContentBuilderPreview, ContentBuilderTemplatePage } from 'components';
import { ContentBuilderContext, ContentBuilderProvider, DashboardContext, IBulkActionEntity } from 'context';
import { IEntityType } from 'interfaces';
import capitalize from 'lodash.capitalize';

import { ContentBuilderPageProps } from './ContentBuilderPage.interfaces';


const ContentBuilderPageInner = () => {

  const {
    contentType,
    contentPage,
    isLoading = false,
    isEmptyPage = false,
    firstSectionGroupId,
    openDeleteEntityDialog,
    openUpdateEntitySidebar,
    openBulkActionDialog,
    addItemToEntity,
    openActionsSidebar,
    handleEnterFormsMode,
    handleLeaveFormsMode,
  } = useContext(ContentBuilderContext);
  const { dashboardLink } = useContext(DashboardContext);

  return (
    <>
      <Spinner isLoading={isLoading}/>
      <ContentBuilderTemplatePage
        activePageId=""
        additionalClassNames=""
        dashboardLink={`${String(dashboardLink)}/alpha-content/${String(contentType)}`}
        description="Content builder"
        pageTitle={`${capitalize(contentType)} content builder`}
        settingItems={[
          {
            id: 'id_edit_item',
            iconId: 'id_settings_icon',
            label: 'Edit',
            isBulkOption: false,
            isHidden: false,
            onClick: ({ id: entityId, type: entityType }) => openUpdateEntitySidebar(Number(entityId), entityType as IEntityType)
          },
          {
            id: 'id_add_new_item',
            iconId: 'id_enter_icon',
            label: 'Add new item in folder',
            isVisibleByTypeArray: ['section', 'container', 'sectionGroup'],
            onClick: ({ id: entityId, type: entityType }) => addItemToEntity(Number(entityId), entityType as IEntityType),
          },
          {
            id: 'id_entity_actions',
            iconId: 'id_condition_icon',
            label: 'Actions',
            isHidden: false,
            onClick: ({ id: entityId, type: entityType }) => openActionsSidebar(Number(entityId), entityType as IEntityType),
          },
          // {
          //   id: 'id_duplicate_item',
          //   iconId: 'id_copy_icon',
          //   label: 'Duplicate',
          //   isVisibleByTypeArray: ['targetSnippet'],
          //   isBulkOption: false,
          //   onClick: () => alert('Duplicate')
          // },
          {
            id: 'id_enter_form_builder_item',
            iconId: 'id_form_icon',
            label: 'Enter form builder',
            isVisibleByTypeArray: ['targetSnippet'],
            isBulkOption: false,
            onClick: ({ id: entityId })  => handleEnterFormsMode(Number(entityId)),
          },
          {
            id: 'id_leave_form_builder_item',
            iconId: 'id_form_icon',
            label: 'Leave form builder',
            isVisibleByTypeArray: ['targetSnippet'],
            isBulkOption: false,
            onClick: ()  => handleLeaveFormsMode(),
          },
          {
            id: 'id_validation_item',
            iconId: 'id_filter_icon',
            label: 'Update validation',
            isBulkOption: true,
            isVisibleByTypeArray: ['bulk'],
            onBulkClick: (selectedOptions: { id: string; type?: string; }[]) => openBulkActionDialog({ selectedEntities: selectedOptions.map(({id, type }) => ({id, type: type === 'targetSnippet' ? 'SNIPPET' : type?.toUpperCase() })) as IBulkActionEntity[], type: 'updateValidation' }),
            onClick: ({ id: entityId, type }) => openDeleteEntityDialog({ id: Number(entityId), type: type as IEntityType}),
          },
          {
            id: 'id_visibility_item',
            iconId: 'id_eye_hide_icon',
            label: 'Update visibility',
            isVisibleByTypeArray: ['bulk'],
            isBulkOption: true,
            onBulkClick: (selectedOptions: { id: string; type?: string; }[]) => openBulkActionDialog({ selectedEntities: selectedOptions.map(({id, type }) => ({id, type: type === 'targetSnippet' ? 'SNIPPET' : type?.toUpperCase() })) as IBulkActionEntity[], type: 'updateVisibility' }),
            onClick: ({ id: entityId, type }) => openDeleteEntityDialog({ id: Number(entityId), type: type as IEntityType}),
          },
          {
            id: 'id_delete_item',
            iconId: 'id_bin_icon',
            label: 'Delete',
            isBulkOption: true,
            isHidden: false,
            onBulkClick: (selectedOptions: { id: string; type?: string; }[]) => openBulkActionDialog({ selectedEntities: selectedOptions.map(({id, type }) => ({id, type: type === 'targetSnippet' ? 'SNIPPET' : type?.toUpperCase() })) as IBulkActionEntity[], type: 'delete' }),
            onClick: ({ id: entityId, type }) => openDeleteEntityDialog({ id: Number(entityId), type: type as IEntityType}),
          },
        ]}
      >
        <Container additionalClassNames="c-content-builder-container" alignment="left" size="2x-large">
          {isEmptyPage && firstSectionGroupId && (
            <EmptyState
              buttonText="New section"
              imageUrl="/images/content-landing.svg"
              size="large"
              text="Get building by hitting new section below. Need some help? Check out our help guide."
              title="Get started!"
              onClick={() => addItemToEntity(firstSectionGroupId, 'sectionGroup')} />
            )}
          {contentPage && !isEmptyPage && (
            <ContentBuilderPreview contentPage={contentPage}/>
          )}
        </Container>
      </ContentBuilderTemplatePage>
    </>
  );
};


const ContentBuilderPage = ({ contentType, contentGroupUrl, pageUrl }: ContentBuilderPageProps) => {
  return (
    <ContentBuilderProvider contentGroupUrl={contentGroupUrl} contentType={contentType} isForm={false} pageUrl={pageUrl}>
      <ContentBuilderPageInner/>
    </ContentBuilderProvider>
  )
}

export { ContentBuilderPage };
