import React, { useEffect, useState } from 'react';

import { IDBAsset } from '@netfront/common-library';
import { IconRadioGroup, IconRadioItemProps, SidebarDialog } from '@netfront/ui-library';
import { AssetGeneralTab, ChooseAssetFromLibrary, ContentBuilderTabWrapper, UpsertEmbedSnippetGeneralTab } from 'components';
import capitalize from 'lodash.capitalize';
import { Controller } from 'react-hook-form';


import { ContentBuilderUpsertAssetProps } from './ContentBuilderUpsertAsset.interfaces';


const ContentBuilderUpsertAsset = ({
  defaultValues,
  setValue,
  control,
  onDrop,
  onChooseFromLibrary,
  initialView = 'selector',
  isCreate = false,
  projectId,
  assetType,
  hasEmbedOption = false,
}: ContentBuilderUpsertAssetProps) => {

  const [view, setView] = useState<string>('selector');
  const [isChooseFromLibraryDialogOpen, setIsChooseFromLibraryDialogOpen] = useState<boolean>(false);
  const [hasChosenFromLibrary, setHasChosenFromLibrary] = useState<boolean>(false);
  const [upsertMethodOptions, setUpsertMethodOptions] = useState<IconRadioItemProps[]>([]);

  const handleSelectAssetFromLibrary = ({ asset }: { asset: IDBAsset }) => {
    setView('upload');
    setIsChooseFromLibraryDialogOpen(false);
    setHasChosenFromLibrary(true);
    onChooseFromLibrary({ asset });
  };

  const cancelChooseFromLibrary = () => {
    setView('selector');
    setHasChosenFromLibrary(false);
    setIsChooseFromLibraryDialogOpen(false);
  };

  useEffect(() => {
    setView(initialView);
  }, [initialView])

  useEffect(() => {
    if (view !== 'library') return;
    setIsChooseFromLibraryDialogOpen(true);
  }, [view]);

  useEffect(() => {
    const options: IconRadioItemProps[] = [
      {
        iconId: 'id_download_hollow_icon',
        label: 'Upload',
        value: 'upload',
      },
      {
        iconId: 'id_library_icon',
        label: `${capitalize(assetType)} library`,
        value: 'library',
      },
    ];

    if (hasEmbedOption) options.push({
      iconId: 'id_embed_icon',
      label: 'Embed',
      value: 'embed',
    });

    setUpsertMethodOptions(options);

  }, [assetType, hasEmbedOption]);

  return (
    <ContentBuilderTabWrapper
      additionalBreadcrumbs={isCreate && view !== 'selector' ? [
        {
          key: assetType,
          onClick: () => {
            setView('selector');
            setValue('type', assetType);
            setHasChosenFromLibrary(false);
          },
          content: capitalize(assetType),
        },
        {
          key: 'view',
          content: <span className="c-breadcrumb-item__link">{hasChosenFromLibrary ? 'Library' : capitalize(view)}</span>
        }
      ]: undefined}
      hasSnippetTypeBreadcrumb={isCreate && view === 'selector'}
    >
      { view === 'selector' && (
        <Controller
          control={control}
          name="view"
          render={({ field }) => (
            <IconRadioGroup
              id="new_snippet_type_other"
              items={upsertMethodOptions}
              label="Type"
              selectedValue={view}
              hasLabels
              isLabelHidden
              isOutlined
              {...field}
              onChange={(value) => {
                field.onChange(value);
                setView(value);
                if (value === 'embed') setValue('type', 'embed');
              }}
            />
          )}
        />

      )}
      { view === 'upload' && (
        <AssetGeneralTab
          asset={defaultValues.asset}
          assetId={defaultValues.assetId ?? ''}
          assetType={assetType}
          control={control}
          initialFileName={defaultValues.fileName ?? ''}
          initialIsDownloadAvailable={defaultValues.isDownloadAvailable ?? ''}
          isInContentBuilder={!isCreate || hasChosenFromLibrary}
          setValue={setValue}
          isLabelSideBySide
          onDrop={onDrop}
        />
      )}

      { view === 'embed' && (
        <UpsertEmbedSnippetGeneralTab
          assetType={assetType}
          control={control}
        />
      )}
      {view === 'library' && (
        <SidebarDialog
          isOpen={isChooseFromLibraryDialogOpen}
          onClose={cancelChooseFromLibrary}
        >
          {isChooseFromLibraryDialogOpen && (
            <ChooseAssetFromLibrary
              additionalBreadcrumbs={[
                {
                  key: assetType,
                  onClick: () => {
                    setView('selector');
                    setValue('type', assetType);
                    setHasChosenFromLibrary(false);
                  },
                  content: capitalize(assetType),
                },
                {
                  key: 'view',
                  content: <span className="c-breadcrumb-item__link">{hasChosenFromLibrary ? 'Library' : capitalize(view)}</span>
                }
              ]}
              assetType={assetType}
              projectId={projectId}
              hasContainer
              onCancel={cancelChooseFromLibrary}
              onSave={handleSelectAssetFromLibrary}
            />
          )}
        </SidebarDialog>
      )}
    </ContentBuilderTabWrapper>
  );
};

export { ContentBuilderUpsertAsset };
