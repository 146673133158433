import { gql } from '@apollo/client';

const CREATE_RADIO_FEEDBACK_CONFIG = gql`
  mutation createRadioSummaryFeedbackConfig($contentSnippetSummaryId: Int!, $questionResponseSetId: Int!, $label: String!) {
    summaryFeedback {
      addSummaryFeedbackConfigurationRadio(
        contentSnippetSummaryId: $contentSnippetSummaryId
        questionResponseSetId: $questionResponseSetId
        label: $label
      )
    }
  }
`;

export { CREATE_RADIO_FEEDBACK_CONFIG };
