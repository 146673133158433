
import { IProgressTrackingSubTableData, IProgressTrackingSubTableDataParams } from './ProgressTrackingSubTable.interfaces';


const getProgressTrackingSubTableData = ({ userFlowProgress }: IProgressTrackingSubTableDataParams): IProgressTrackingSubTableData[] => {
  return userFlowProgress.map(({ userFlowId, progress, userFlowName }): IProgressTrackingSubTableData => {
    return {
      id: userFlowId,
      title: userFlowName,
      progress,
    };
  });
};


export { getProgressTrackingSubTableData };
