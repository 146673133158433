import { IDBContentEvent } from '@netfront/ekardo-content-library';
import { FormFieldProps } from '@netfront/ui-library';
import * as yup from 'yup';

const animationTabValidation = {
  animation: yup.string().nullable(),
  animationDirection: yup.string().nullable().when(['animation'], {
    is: (animation: string) => (Boolean(animation) && animation !== 'NONE') ,
    then: yup.string().label('Animation direction').required()
  }),
  millisecondDelay: yup.string().nullable().when(['animation'], {
    is: (animation: string) => (Boolean(animation) && animation !== 'NONE') ,
    then: yup.string().label('Delay time').required()
  }),
  millisecondSpeed: yup.string().nullable().when(['animation'], {
    is: (animation: string) => (Boolean(animation) && animation !== 'NONE') ,
    then: yup.string().label('Animation Speed').required()
  }),
};

const getContentAnimationDetails = (contentEvent?: IDBContentEvent): FormFieldProps => {
  const {
    animation,
    animationDirection,
    millisecondDelay,
    millisecondSpeed,
    infinite: isInfinite,
  } = contentEvent ?? {};

  return {
    animation,
    animationDirection,
    millisecondDelay: millisecondDelay ?? 1000,
    millisecondSpeed: millisecondSpeed ?? 1000,
    isInfinite: isInfinite ?? false,
  };
};

const getFormattedContentAnimation = (item: FormFieldProps) => {
  const {
    animation,
    animationDirection,
    millisecondDelay,
    millisecondSpeed,
    isInfinite,
  } = item;


  return {
    animation,
    animationDirection,
    millisecondDelay,
    millisecondSpeed,
    infinite: isInfinite,
  };
};

export { animationTabValidation, getContentAnimationDetails, getFormattedContentAnimation };
