import { ApolloQueryResult } from '@apollo/client';
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { SEARCH_PAGINATED_GLOSSARY_QUERY } from './useSearchPaginatedGlossary.graphql';
import {
  ISearchPaginatedGlossaryQueryGraphQLResponse,
  ISearchPaginatedGlossaryQueryVariables,
  IHandleFetchMorePaginatedGlossaryParams,
  IHandleSearchPaginatedGlossaryParams,
  IUseSearchPaginatedGlossary,
  IUseSearchPaginatedGlossaryOptions,
} from './useSearchPaginatedGlossary.interfaces';

const useSearchPaginatedGlossary = (options?: IUseSearchPaginatedGlossaryOptions): IUseSearchPaginatedGlossary => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseSearchPaginatedGlossaryOptions);

  const [executeSearchPaginatedGlossary, { fetchMore, loading: isLoading }] = useEkardoLazyQuery<
  ISearchPaginatedGlossaryQueryGraphQLResponse,
  ISearchPaginatedGlossaryQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          textTooltip: { list: glossaryList },
        } = data;

        void onCompleted({
          glossaryList,
        });
      },
      onError,
    },
    query: query ?? SEARCH_PAGINATED_GLOSSARY_QUERY,
    token,
  });

  const handleFetchMorePaginatedGlossary = ({
    after,
    filter,
    first,
    projectId,
  }: IHandleFetchMorePaginatedGlossaryParams): Promise<ApolloQueryResult<ISearchPaginatedGlossaryQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          textTooltip: { list: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          textTooltip: { list: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          textTooltip: {
            ...previouslyFetchedScenarioConnection,
            list: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  const handleSearchPaginatedGlossary = ({
    after,
    filter,
    first,
    projectId,
  }: IHandleSearchPaginatedGlossaryParams) => {
    void executeSearchPaginatedGlossary({
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  return {
    handleFetchMorePaginatedGlossary,
    handleSearchPaginatedGlossary,
    isLoading,
  };
};

export { useSearchPaginatedGlossary };
