
const iconConfig: {[key: number]: string[]} = {
  1: ['100%'],
  2: ['50%', '50%'],
  3: ['66%', '33%'],
  4: ['33%', '66%'],
  5: ['25%', '75%'],
  6: ['75%', '25%'],
  7: ['33%', '33%', '33%'],
  8: ['50%', '25%', '25%'],
  9: ['25%', '25%', '50%'],
  10: ['25%', '25%', '25%', '25%'],
};

export { iconConfig };
