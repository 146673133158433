import { gql } from '@apollo/client';

const UPDATE_COMMENT_MUTATION = gql`
  mutation updateComment($id: Int!, $message: String!) {
    comment {
      update(request: { id: $id, message: $message }) {
        author {
          displayedName
        }
        id
        authorId
        author {
          firstName
          lastName
        }
        message
        createdDate
        createdDateTime
        updatedDate
      }
    }
  }
`;

export { UPDATE_COMMENT_MUTATION };
