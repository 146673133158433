import { gql } from '@apollo/client';

const CREATE_MULTI_LINE_TEXT_CONFIGURATION = gql`
  mutation createMultilineTextQuesConfigRequest(
    $projectId: String!
    $description: String!
    $caseSensitive: Boolean!
    $maxLength: Int!
    $minLength: Int!
    $regex: String!
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationMultilineText: {
          caseSensitive: $caseSensitive
          maxLength: $maxLength
          minLength: $minLength
          regex: $regex
        }
      ) {
        id
      }
    }
  }
`;

export { CREATE_MULTI_LINE_TEXT_CONFIGURATION };
