import { ApolloQueryResult } from '@apollo/client';

import { useHowlerLazyQuery } from 'hooks/social/useHowlerLazyQuery';

import { GET_NOTIFICATION_GROUPS } from './useGetNotificationGroups.graphql';
import {
  IGetNotificationGroupsQueryGraphQLResponse,
  IGetNotificationGroupsQueryVariables,
  IHandleFetchMorePaginatedNotificationGroupsParams,
  IHandleGetNotificationGroupsParams,
  IUseGetNotificationGroups,
  IUseGetNotificationGroupsOptions,
} from './useGetNotificationGroups.interfaces';

const useGetNotificationGroups = (options?: IUseGetNotificationGroupsOptions): IUseGetNotificationGroups => {
  const {
    fetchPolicy,
    onCompleted,
    onError,
    query,
    token,
  } = options ?? ({} as IUseGetNotificationGroupsOptions);

  const [executeGetNotificationGroups, { fetchMore, loading: isLoading }] = useHowlerLazyQuery<
  IGetNotificationGroupsQueryGraphQLResponse,
  IGetNotificationGroupsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          notification: { notificationGroups },
        } = data;

        onCompleted({
          notificationGroups,
        });
      },
      onError,
    },
    query: query ?? GET_NOTIFICATION_GROUPS,
    token,
  });

  const handleFetchMorePaginatedNotificationGroups = ({
    after,
    first,
    projectId,
  }: IHandleFetchMorePaginatedNotificationGroupsParams): Promise<ApolloQueryResult<IGetNotificationGroupsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          notification: { notificationGroups: newlyFetchedNotificationGroups },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedNotificationGroups;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          notification: { notificationGroups: previouslyFetchedNotificationGroups },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedNotificationGroups;

        return {
          ...previousQueryResult,
          notification: {
            ...previouslyFetchedNotificationGroups,
            notificationGroups: {
              ...newlyFetchedNotificationGroups,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        projectId,
      },
    });
  };

  const handleGetNotificationGroups = ({ after, first, projectId }: IHandleGetNotificationGroupsParams) => {
    void executeGetNotificationGroups({
      variables: {
        after,
        first,
        projectId,
      },
    });
  };

  return {
    handleFetchMorePaginatedNotificationGroups,
    handleGetNotificationGroups,
    isLoading,
  };
};

export { useGetNotificationGroups };
