import { IconIdType } from "@netfront/ui-library";

export const NetfrontServices = {
  BONOBO: 'BONOBO',
  BONOBO_MANAGEMENT: 'BONOBO_MANAGEMENT',
  EKARDO: 'EKARDO',
  HOWLER: 'HOWLER',
  MURIQUI: 'MURIQUI',
  VERVET: 'VERVET',
  CAPUCHIN: 'CAPUCHIN',
};

export const SnippetTypeIcon: {[key: string]: IconIdType} = {
  ContentSnippetAppType: 'id_app_icon',
  ContentSnippetAudioType: 'id_audio_tab_icon',
  ContentSnippetButtonType: 'id_button_icon',
  ContentSnippetClusterResultType: 'id_cluster_icon',
  ContentSnippetCodeType: 'id_code_icon',
  ContentSnippetDocumentType: 'id_form_icon',
  ContentSnippetEmbedType: 'id_embed_icon',
  ContentSnippetFormType: 'id_spacer_icon',
  ContentSnippetImageType: 'id_image_hollow_icon',
  ContentSnippetImageMapType: 'id_image_slider_icon',
  ContentSnippetMapType: 'id_map_icon',
  ContentSnippetQuestionType: 'id_questionnaire_icon',
  ContentSnippetQuoteType: 'id_quote_icon',
  Reveal: 'id_tooltip_icon',
  ContentSnippetSliderType: 'id_image_slider_icon',
  ContentSnippetSpacerType: 'id_slider_icon',
  Table: 'id_table_icon',
  Tabs: 'id_tabs_icon',
  ContentSnippetTextType: 'id_textbox_icon',
  ContentSnippetVideoType: 'id_hollow_video_icon',
  ContentSnippetSummaryType: 'id_list_view_icon',
  ContentSnippetAvatarType: 'id_avatar_icon',
};
