

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_PAGE_QUERY } from './useGetContentPage.graphql';
import {
  IGetContentPageQueryGraphQLResponse,
  IGetContentPageQueryVariables,
  IHandleGetContentPageParams,
  IUseContentPage,
  IUseContentPageOptions,
} from './useGetContentPage.interfaces';



const useGetContentPage = (options?: IUseContentPageOptions): IUseContentPage => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseContentPageOptions);

  const [executeGetContentPage, { loading: isLoading }] = useEkardoLazyQuery<
    IGetContentPageQueryGraphQLResponse,
    IGetContentPageQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { getContentPage: contentPage },
        } = data;


        onCompleted({
          contentPage,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_CONTENT_PAGE_QUERY,
    token,
  });

  const handleGetContentPage = async ({
    contentPageId,
  }: IHandleGetContentPageParams) => {
    await executeGetContentPage({
      variables: {
        contentPageId,
      },
    });
  };

  return {
    handleGetContentPage,
    isLoading,
  };
};

export { useGetContentPage };
