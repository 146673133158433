import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_CHARACTER_MUTATION } from './useUpdateCharacter.graphql';
import {
  IUpdateCharacterMutationGraphQLResponse,
  IUpdateCharacterMutationVariables,
  IHandleUpdateCharacterParams,
  IUseUpdateCharacterOptions,
  IUseUpdateCharacter,
} from './useUpdateCharacter.interfaces';

const useUpdateCharacter = (options?: IUseUpdateCharacterOptions): IUseUpdateCharacter => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateCharacterOptions);

  const [executeUpdateCharacter, { loading: isLoading }] = useEkardoMutation<IUpdateCharacterMutationGraphQLResponse, IUpdateCharacterMutationVariables>({
    mutation: mutation ?? UPDATE_CHARACTER_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          character: {
            updateCharacter: character,
          },
        } = data;

        void onCompleted({ character });
      },
      onError,
    },
    token,
  });

  const handleUpdateCharacter = async ({ characterId, image, name }: IHandleUpdateCharacterParams) => {
    await executeUpdateCharacter({
      variables: {
        characterId,
        image,
        name,
      },
    });
  };

  return {
    handleUpdateCharacter,
    isLoading,
  };
};

export { useUpdateCharacter };
