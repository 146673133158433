const DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS = process.env.REACT_APP_API_QUERY_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS;

const FILE_TYPE_SUPPORT_ALERT_VISIBILITY_TIME_IN_SECONDS = 3;

const DEFAULT_PAGE_SIZE = 10;

const DESCRIPTION_MAX_LENGTH = 100;

const MAX_UPLOAD_FILE_SIZE = 200;

const documentSidebarGeneralViewConstants = Object.freeze({
  debounceQueryWaitTimeInMilliseconds: DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS,
  defaultPageSize: DEFAULT_PAGE_SIZE,
  descriptionMaxLength: DESCRIPTION_MAX_LENGTH,
  fileTypeSupportAlertVisibilityInSeconds: FILE_TYPE_SUPPORT_ALERT_VISIBILITY_TIME_IN_SECONDS,
  maxUploadFileSize: MAX_UPLOAD_FILE_SIZE,
});

export { documentSidebarGeneralViewConstants };
