import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_CONTENT_EVENT } from './useCreateContentEvent.graphql';
import {
  ICreateContentEventMutationGraphQLResponse,
  ICreateContentEventMutationVariables,
  IHandleCreateContentEventParams,
  IUseCreateContentEventOptions,
  IUseCreateContentEvent,
} from './useCreateContentEvent.interfaces';

const useCreateContentEvent = (options?: IUseCreateContentEventOptions): IUseCreateContentEvent => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateContentEventOptions);

  const [executeCreateContentEvent, { loading: isLoading }] = useEkardoMutation<
  ICreateContentEventMutationGraphQLResponse,
  ICreateContentEventMutationVariables
  >({
    mutation: mutation ?? CREATE_CONTENT_EVENT,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentEvent: {
            createEvent: contentEvent,
          },
        } = data;

        void onCompleted({contentEvent});
      },
      onError,
    },
    token,
  });

  const handleCreateContentEvent = ({
    contentEvent,
  }: IHandleCreateContentEventParams) => {
    void executeCreateContentEvent({
      variables: {
        contentEvent,
      },
    });
  };

  return {
    handleCreateContentEvent,
    isLoading,
  };
};

export { useCreateContentEvent };
