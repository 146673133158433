import { gql } from '@apollo/client';

export const FRAGMENT_DOCUMENT = gql`
  fragment document on ContentSnippetDocumentType {
    assetId
    document: asset {
      alt
      assetId
      contentType
      description
      fileName
      isFavorite
      isPublic
      tagList {
        id
        name
      }
      presignedUrl
      projectId
      publicS3Key
      publicUrl
      s3Key
      title
      transcript
      type
      uploaded
      user {
        firstName
        lastName
      }
      xAxisFocus
      yAxisFocus
    }
    asset {
      alt
      assetId
      contentType
      description
      fileName
      isFavorite
      isPublic
      tagList {
        id
        name
      }
      presignedUrl
      projectId
      publicS3Key
      publicUrl
      s3Key
      title
      transcript
      type
      uploaded
      user {
        firstName
        lastName
      }
      xAxisFocus
      yAxisFocus
    }
  }
`;
