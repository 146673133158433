
import { IDiariesTableData, IDiariesTableDataParams } from "./DiariesReportPage.interfaces";


const getDiariesTableData = ({ items }: IDiariesTableDataParams): IDiariesTableData[] => {
  return items.map(({
    behaviour = '',
    behaviourType = '',
    createdOnUtc = '',
    date = '',
    email = '',
    firstName = '',
    id,
    lastName = '',
    value = '',
   }) => {
    return {
      behaviour,
      behaviourType,
      createdOnUtc,
      date,
      email,
      firstName,
      id,
      lastName,
      value,
    };
  });
};

export { getDiariesTableData };
