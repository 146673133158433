import { useHowlerLazyQuery } from 'hooks';

import { EXPORT_NOTIFICATIONS } from './useGetNotificationsReport.graphql';
import {
  IGetNotificationsReportQueryGraphQLResponse,
  IGetNotificationsReportQueryVariables,
  IHandleGetNotificationsReportParams,
  IUseGetNotificationsReport,
  IUseGetNotificationsReportOptions,
} from './useGetNotificationsReport.interfaces';

const useGetNotificationsReport = (options?: IUseGetNotificationsReportOptions): IUseGetNotificationsReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetNotificationsReportOptions);

  const [executeGetNotificationsReport, { loading: isLoading }] = useHowlerLazyQuery<
    IGetNotificationsReportQueryGraphQLResponse,
    IGetNotificationsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportNotifications } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportNotifications,
        });
      },
      onError,
    },
    query: query ?? EXPORT_NOTIFICATIONS,
    token,
  });


  const handleGetNotificationsReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetNotificationsReportParams) => {
    await executeGetNotificationsReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetNotificationsReport,
    isLoading,
  };
};

export { useGetNotificationsReport };
