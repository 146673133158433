import { gql } from '@apollo/client';

const GET_PROJECT_STEPS = gql`
  query getProjectSteps($projectId: String!) {
    userFlowStep {
      getStepsOfProject(projectId: $projectId) {
        id
        contentGroupId
        stepName
        url
      }
    }
  }
`;
export { GET_PROJECT_STEPS };
