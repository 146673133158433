import { gql } from '@apollo/client';

const DELETE_POST_MUTATION = gql`
  mutation DeletePost($postId: Int!) {
    post {
      delete(postId: $postId)
    }
  }
`;

export { DELETE_POST_MUTATION };
