import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_CALENDER_CONFIGURATION } from './useCreateCalendarQuestionConfig.graphql';
import {
  ICreateCalendarQuestionConfigMutationGraphQLResponse,
  ICreateCalendarQuestionConfigMutationVariables,
  IHandleCreateCalendarQuestionConfigParams,
  IUseCreateCalendarQuestionConfigOptions,
  IUseCreateCalendarQuestionConfig,
} from './useCreateCalendarQuestionConfig.interfaces';

const useCreateCalendarQuestionConfig = (options?: IUseCreateCalendarQuestionConfigOptions): IUseCreateCalendarQuestionConfig => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateCalendarQuestionConfigOptions);

  const [executeCreateCalendarQuestionConfig, { loading: isLoading }] = useEkardoMutation<ICreateCalendarQuestionConfigMutationGraphQLResponse, ICreateCalendarQuestionConfigMutationVariables>({
    mutation: mutation ?? CREATE_CALENDER_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { createQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateCalendarQuestionConfig = async ({
    defaultDate,
    description,
    maxDate,
    minDate,
    projectId,
  }: IHandleCreateCalendarQuestionConfigParams) => {
    await executeCreateCalendarQuestionConfig({
      variables: {
        defaultDate,
        description,
        maxDate,
        minDate,
        projectId,
      }
    });
  };

  return {
    handleCreateCalendarQuestionConfig,
    isLoading,
  };
};

export { useCreateCalendarQuestionConfig };
