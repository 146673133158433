import React, { useEffect, useState } from 'react';

import { Input, Label } from '@netfront/ui-library';

import { convertDaysAndHoursToSeconds, convertSecondsToDaysAndHours } from './ConditionDateTimespan.helpers';
import { ConditionDateTimespanProps } from './ConditionDateTimespan.interfaces';

const ConditionDateTimespan = ({ id, onChange, name, value, labelText }: ConditionDateTimespanProps) => {
  const [days, setDays] = useState<number>(0);

  const handleDaysUpdate = ({ target: { value: updatedValue } }: { target: { value: string}}) => {
    setDays(Number(updatedValue));
    onChange({ name, value: convertDaysAndHoursToSeconds(Number(updatedValue), 0) });
  };

  useEffect(() => {
    const { days: initialDays } = convertSecondsToDaysAndHours(value ?? 0);

    setDays(initialDays);
  }, [value]);

  return (
    <section className="c-condition-timespan">
      <Label forId={`id_${id}_days`} labelText={labelText} />

      <div className="c-condition-timespan__inputs">
        <div className="c-condition-timespan__input-wrapper">
          <Input
            additionalClassNames="c-condition-timespan__input"
            id={`id_${id}_days`}
            labelText="Days"
            name={`days_${id}`}
            type="number"
            value={days}
            isLabelHidden
            isLabelSideBySide
            onChange={handleDaysUpdate}
          />
          <span>Days</span>
        </div>
      </div>
    </section>
  );
};

export { ConditionDateTimespan };
