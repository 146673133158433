import { Column } from 'react-table';

import { IPagesLoadedTableData } from './PagesLoadedPage.interfaces';


const PAGES_LOADED_COLUMNS: ReadonlyArray<Column<IPagesLoadedTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
    width: '10%',
  },
  {
    accessor: 'userType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User type</div>,
    width: '10%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
    width: '10%',
  },
  {
    accessor: 'title',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Title</span>,
  },
  {
    accessor: 'url',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Url</span>,
  },
  {
    accessor: 'date',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Date</span>,
    width: '20%',
  },

];

export { PAGES_LOADED_COLUMNS };
