import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_SNIPPET_DETAILS } from './useGetSnippetDetails.graphql';
import {
  IGetSnippetDetailsQueryGraphQLResponse,
  IGetSnippetDetailsQueryVariables,
  IHandleGetSnippetDetailsParams,
  IUseGetSnippetDetails,
  IUseGetSnippetDetailsOptions,
} from './useGetSnippetDetails.interfaces';

const useGetSnippetDetails = (options?: IUseGetSnippetDetailsOptions): IUseGetSnippetDetails => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetSnippetDetailsOptions);

  const [executeGetSnippetDetails, { loading: isLoading }] = useEkardoLazyQuery<
  IGetSnippetDetailsQueryGraphQLResponse,
  IGetSnippetDetailsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { getContentSnippet: snippet},
        } = data;

        void onCompleted({
          snippet,
        });
      },
      onError,
    },
    query: query ?? GET_CONTENT_SNIPPET_DETAILS,
    token,
  });

  const handleGetSnippetDetails = ({ contentSnippetId }: IHandleGetSnippetDetailsParams) => {
    void executeGetSnippetDetails({
      variables: {
        contentSnippetId,
      },
    });
  };

  return {
    handleGetSnippetDetails,
    isLoading,
  };
};

export { useGetSnippetDetails };
