

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { EXPORT_FEEDBACK } from './useGetFeedbackReport.graphql';
import {
  IGetFeedbackReportQueryGraphQLResponse,
  IGetFeedbackReportQueryVariables,
  IHandleGetFeedbackReportParams,
  IUseGetFeedbackReport,
  IUseGetFeedbackReportOptions,
} from './useGetFeedbackReport.interfaces';

const useGetFeedbackReport = (options?: IUseGetFeedbackReportOptions): IUseGetFeedbackReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetFeedbackReportOptions);

  const [executeGetFeedbackReport, { loading: isLoading }] = useEkardoLazyQuery<
    IGetFeedbackReportQueryGraphQLResponse,
    IGetFeedbackReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportFeedback } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportFeedback,
        });
      },
      onError,
    },
    query: query ?? EXPORT_FEEDBACK,
    token,
  });


  const handleGetFeedbackReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetFeedbackReportParams) => {
    await executeGetFeedbackReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetFeedbackReport,
    isLoading,
  };
};

export { useGetFeedbackReport };
