import { gql } from '@apollo/client';

const DELETE_CODE_ASSET = gql`
  mutation deleteAsset($assetId: String!) {
    asset {
      deleteAsset(assetId: $assetId)
    }
  }
`;


export { DELETE_CODE_ASSET };
