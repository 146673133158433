/* eslint-disable @typescript-eslint/indent */
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_REPORTABLE_USER_FLOW } from './useGetReportableUserFlows.graphql';
import {
  IGetReportableUserFlowsQueryGraphQLResponse,
  IGetReportableUserFlowsQueryVariables,
  IUseGetReportableUserFlows,
  IUseGetReportableUserFlowsOptions,
} from './useGetReportableUserFlows.interfaces';

const useGetReportableUserFlows = (options?: IUseGetReportableUserFlowsOptions): IUseGetReportableUserFlows => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetReportableUserFlowsOptions);

  const [executeGetReportableUserFlows, { loading: isLoading }] = useEkardoLazyQuery<
    IGetReportableUserFlowsQueryGraphQLResponse,
    IGetReportableUserFlowsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ userFlow: { getReportableUserFlows } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userFlows: getReportableUserFlows,
        });
      },
      onError,
    },
    query: GET_REPORTABLE_USER_FLOW,
    token,
  });

  const handleGetReportableUserFlows = async ({ projectId }: IGetReportableUserFlowsQueryVariables) => {
    await executeGetReportableUserFlows({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetReportableUserFlows,
    isLoading,
  };
};

export { useGetReportableUserFlows };
