import { gql } from '@apollo/client';

const UPDATE_ANNOUNCE_QUERY = gql`
  mutation updateAnnounce(
    $id: Int!
    $title: String!
    $location: String!
    $content: String!
    $start: DateTime
    $end: DateTime
    $isActive: Boolean!
  ) {
    announce {
      update(id: $id, title: $title, location: $location, content: $content, start: $start, end: $end, isActive: $isActive) {
        id
      }
    }
  }
`;

export { UPDATE_ANNOUNCE_QUERY };
