
import { ILoginsTrialsTableData, ILoginsTrialsTableDataParams } from "./LoginsTrialsPage.interfaces";


const getLoginsTrialsTableData = ({ items }: ILoginsTrialsTableDataParams): ILoginsTrialsTableData[] => {
  return items.map(({
    email = '',
    firstName = '',
    groupName = '',
    id,
    lastName = '',
    loginDate = '',
   }) => {
    return {
      email,
      firstName,
      groupName,
      id,
      lastName,
      loginDate,
    };
  });
};

export { getLoginsTrialsTableData };
