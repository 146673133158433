import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SNIPPET_ANSWERS } from './useGetConditionSnippetAnswers.graphql';
import { IGetConditionSnippetAnswersQueryGraphQLResponse, IGetConditionSnippetAnswersQueryVariables, IHandleGetConditionSnippetAnswersParams, IUseGetConditionSnippetAnswers, IUseGetConditionSnippetAnswersOptions } from './useGetConditionSnippetAnswers.interfaces';

const useGetConditionSnippetAnswers = (options?: IUseGetConditionSnippetAnswersOptions): IUseGetConditionSnippetAnswers => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetConditionSnippetAnswersOptions);
  const [executeGetConditionSnippetAnswers, { loading: isLoading }] = useEkardoLazyQuery<
  IGetConditionSnippetAnswersQueryGraphQLResponse,
  IGetConditionSnippetAnswersQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: {
            question
          },
        } = data;

        onCompleted({
          question
        });
      },
      onError,
    },
    query: query ?? GET_SNIPPET_ANSWERS,
    token,
  });

  const handleGetConditionSnippetAnswers = ({ contentSnippetQuestionId }: IHandleGetConditionSnippetAnswersParams) => {
    void executeGetConditionSnippetAnswers({
      variables: {
        contentSnippetQuestionId,
      },
    });
  };

  return {
    handleGetConditionSnippetAnswers,
    isLoading,
  };
};

export { useGetConditionSnippetAnswers };
