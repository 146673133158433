import { gql } from '@apollo/client';

const CREATE_POST_MUTATION = gql`
  mutation createPost($message: String!, $topicId: Int, $tags: [String]) {
    post {
      create(request: { shareOption: EVERYONE, postType: FEED, message: $message, topicId: $topicId, tags: $tags }) {
        id
        __typename
        ... on PostGraphType {
          id
          message
          topic {
            id
            title
          }
          authorId
          author {
            id
            displayedName
            firstName
            lastName
            postCount
            email
          }
          commentCount
          comments {
            edges {
              node {
                authorId
                author {
                  id
                  displayedName
                }
                message
                postId
              }
            }
          }
          topic {
            title
          }
          tags
          createdDate
          createdDateTime
          assets {
            assetId
            s3Key
            contentType
            fileName
            presignedUrl
          }
        }
      }
    }
  }
`;

export { CREATE_POST_MUTATION };
