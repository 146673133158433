import { ApolloQueryResult } from '@apollo/client';
import { useBonoboLazyQuery } from 'hooks';

import { GET_POSTS_QUERY } from './useGetPosts.graphql';
import {
  IGetPaginatedPostsQueryGraphQLResponse,
  IGetPaginatedPostsQueryVariables,
  IHandleFetchMorePaginatedPostsParams,
  IHandleGetPaginatedPostsParams,
  IUseGetPaginatedPosts,
  IUseGetPaginatedPostsOptions,
} from './useGetPosts.interfaces';

const useGetPaginatedPosts = (options?: IUseGetPaginatedPostsOptions): IUseGetPaginatedPosts => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetPaginatedPostsOptions);

  const [executeGetPaginatedPosts, { fetchMore, loading: isLoading }] = useBonoboLazyQuery<
    IGetPaginatedPostsQueryGraphQLResponse,
    IGetPaginatedPostsQueryVariables
  >({
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          post: { getFeedPosts: postConnection },
        } = data;

        onCompleted({
          postConnection,
        });
      },
      onError,
    },
    query: query ?? GET_POSTS_QUERY,
    token,
  });

  const handleFetchMorePaginatedPosts = ({
    after,
    first,
    from,
    message,
    to,
  }: IHandleFetchMorePaginatedPostsParams): Promise<ApolloQueryResult<IGetPaginatedPostsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          post: { getFeedPosts: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          post: { getFeedPosts: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          post: {
            ...previouslyFetchedScenarioConnection,
            getFeedPosts: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        from,
        message,
        to,
      },
    });
  };

  const handleGetPaginatedPosts = ({ after, first, from, message, to }: IHandleGetPaginatedPostsParams) => {
    void executeGetPaginatedPosts({
      variables: {
        after,
        first,
        from,
        message,
        to,
      },
    });
  };

  return {
    handleFetchMorePaginatedPosts,
    handleGetPaginatedPosts,
    isLoading,
  };
};

export { useGetPaginatedPosts };
