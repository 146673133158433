import { useEffect, useState } from 'react';

import { Label, Preloader, InformationBox, SettingsButton, Spacing, FlexContainer, ButtonIconOnly, SidebarDialog } from '@netfront/ui-library';
import capitalize from 'lodash.capitalize';

import { AssetCaptionsFormView } from './AssetCaptionsFormView';
import { handleCalculateCaptionTimes } from './AssetSidebarCaptions.helpers';
import { AssetSidebarCaptionsViewProps } from './AssetSidebarCaptionsView.interfaces';

import { IAssetCaption, useToast, useGetAssetCaptions } from '../../../../../../hooks';


const AssetSidebarCaptionsView = ({ assetId, assetType }: AssetSidebarCaptionsViewProps) => {
  const { handleToastError } = useToast();

  const [assetCaptions, setAssetCaptions] = useState<IAssetCaption[]>([]);
  const [selectedCaption, setSelectedCaption] = useState<IAssetCaption>();

  const [isUpsertCaptionDialogOpen, setIsUpsertCaptionDialogOpen] = useState<boolean>(false);

  const { handleGetAssetCaptions: executeGetAssetCaptions, isLoading: isGetAssetCaptionsLoading = false } = useGetAssetCaptions({
    onCompleted: ({ assetCaptionsConnection }) => {
      setAssetCaptions(assetCaptionsConnection);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleOpenCaptionDialog = (caption?: IAssetCaption) => {
    setSelectedCaption(caption);
    setIsUpsertCaptionDialogOpen(true);
  };

  const handleCloseCaptionDialog = () => {
    setSelectedCaption(undefined);
    setIsUpsertCaptionDialogOpen(false);
  };

  useEffect(() => {
    if (!assetId) {
      return;
    }

    executeGetAssetCaptions({
      assetId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return (
    <div className="c-asset-sidebar-caption-view">
      <>
        {isGetAssetCaptionsLoading ? (
          <Preloader isLoading={isGetAssetCaptionsLoading} />
        ) : (
          <section className="c-sidebar-section c-sidebar-section--aligned">
            <Label forId="copy" labelText={`${capitalize(assetType)} captions`} tooltipText={`Caption of the ${assetType} asset`} />

            <Spacing>
              {assetCaptions.length ? (
                <ul className="c-asset-sidebar-caption-view__caption-list">
                  {assetCaptions.map((caption) => {
                    const { id, from, to, text } = caption;

                    return (
                      <li key={`${id}-${from}`} className="c-asset-sidebar-caption-view__caption-item">
                        <span className="c-asset-sidebar-caption-view__caption-title">{text}</span>
                        <span className="c-asset-sidebar-caption-view__caption-time">
                          {handleCalculateCaptionTimes(from)} - {handleCalculateCaptionTimes(to)}
                        </span>

                        <SettingsButton supportiveText="Open caption" onClick={() => handleOpenCaptionDialog(caption)} />
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <InformationBox message="No caption created for the asset" />
              )}
            </Spacing>

            <FlexContainer justifyContent="end" tag="div">
              <ButtonIconOnly
                iconId="id_plus_icon"
                text="Add caption"
                onClick={() => {
                  handleOpenCaptionDialog(undefined);
                }}
              />
            </FlexContainer>
          </section>
        )}
      </>
      <SidebarDialog
        isOpen={isUpsertCaptionDialogOpen}
        onClose={handleCloseCaptionDialog}
      >
        <AssetCaptionsFormView
          assetId={assetId}
          selectedCaption={selectedCaption}
          onCancel={handleCloseCaptionDialog}
          onSave={() => {
            if (!assetId) {
              return;
            }

            executeGetAssetCaptions({
              assetId,
            });
          }}
        />
      </SidebarDialog>
    </div>
  );
};

export { AssetSidebarCaptionsView };
