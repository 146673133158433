import { ChangeEvent, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { Dialog, Input, Select, SidebarButtons, Spacing, Spinner, Textarea } from '@netfront/ui-library';
import { useCreateCommunity, useDeleteCommunity, useToast, useUpdateCommunity } from 'hooks';
import { IDBCommunity } from 'interfaces';

import { communitySidebarGeneralViewConstants } from './CommunitySidebarGeneralView.constants';
import { CommunitySidebarGeneralViewProps } from './CommunitySidebarGeneralView.interfaces';

const CommunitySidebarGeneralView = ({
  projectId,
  onClose,
  onDeleted,
  onUpdated,
  onCreated,
  selectedCommunity,
}: CommunitySidebarGeneralViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { maxCommunityDescriptionLength } = communitySidebarGeneralViewConstants;

  const { handleToastError } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [currentCommunity, setCurrentCommunity] = useState<IDBCommunity>(
    selectedCommunity ??
      ({
        id: 0,
        privacy: 'PUBLIC',
        status: 'PENDING',
        title: '',
        description: '',
      } as IDBCommunity),
  );

  const {
    id: communityId,
    title: communityTitle = '',
    description: communityDescription,
    privacy: communityPrivacy = 'PUBLIC',
    status: communityStatus = 'PENDING',
    key: communityKey = '',
  } = currentCommunity;

  const { handleCreateCommunity: executeCreateCommunity, isLoading: isCreateCommunityLoading = false } = useCreateCommunity({
    onCompleted: ({ communityConnection }) => {
      onCreated(communityConnection);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  const { handleDeleteCommunity: executeDeleteCommunity, isLoading: isDeleteCommunityLoading = false } = useDeleteCommunity({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      onDeleted(currentCommunity.id);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId,
  });

  const { handleUpdateCommunity: executeUpdateCommunity, isLoading: isUpdateCommunityLoading = false } = useUpdateCommunity({
    onCompleted: ({ communityConnection }) => {
      onUpdated(communityConnection);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  const handleCreateCommunity = () => {
    executeCreateCommunity({
      inputType: {
        title: communityTitle,
        description: communityDescription,
        privacy: communityPrivacy,
        status: communityStatus,
      },
    });
  };

  const handleUpdateCommunity = () => {
    executeUpdateCommunity({
      inputType: {
        id: communityId,
        title: communityTitle,
        privacy: communityPrivacy,
        description: communityDescription,
        status: communityStatus,
      },
    });
  };

  const handleUpdateCommunityInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentCommunity(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBCommunity),
    );
  };

  const isLoading = isUpdateCommunityLoading || isDeleteCommunityLoading || isCreateCommunityLoading;

  return (
    <div className="c-community-sidebar-general-view">
      {isLoading && <Spinner isLoading={isLoading} />}

      <Spacing size="large">
        <Input id="title" labelText="Title" name="title" tooltipText="Community title" type="text" value={communityTitle} onChange={handleUpdateCommunityInput} />
      </Spacing>

      {selectedCommunity && (
        <Spacing size="large">
          <Input id="key" labelText="Key" name="key" tooltipText="Community key" type="text" value={communityKey} isDisabled isReadOnly onChange={handleUpdateCommunityInput} />
        </Spacing>
      )}

      <Spacing size="large">
        <Textarea
          id="description"
          labelText="Description"
          maxLength={maxCommunityDescriptionLength}
          name="description"
          tooltipText="Community description"
          value={communityDescription}
          onChange={handleUpdateCommunityInput}
        />
      </Spacing>

      <Spacing size="large">
        <Select
          id="privacy"
          labelText="Privacy"
          name="privacy"
          options={[
            {
              name: 'Public',
              value: 'PUBLIC',
              id: 1,
            },
            {
              name: 'Private',
              value: 'PRIVATE',
              id: 2,
            },
          ]}
          tooltipText="Public communities will be accessible by all users"
          value={communityPrivacy}
          isLabelHidden
          onChange={handleUpdateCommunityInput}
        />
      </Spacing>

      <Spacing size="large">
        <Select
          id="status"
          labelText="Status"
          name="status"
          options={[
            {
              name: 'Pending',
              value: 'PENDING',
              id: 1,
            },
            {
              name: 'Published',
              value: 'PUBLISHED',
              id: 2,
            },
            {
              name: 'Unpublished',
              value: 'UNPUBLISHED',
              id: 3,
            },
          ]}
          tooltipText="Only published communities will be available to post in"
          value={communityStatus}
          isLabelHidden
          onChange={handleUpdateCommunityInput}
        />

      </Spacing>

      {selectedCommunity && (

        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete Community: ${communityTitle}`}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            if (!communityId) {
              return;
            }

            executeDeleteCommunity({
              communityId,
            });
          }}
        />
      )}

      <SidebarButtons
        onCancel={onClose}
        onDelete={selectedCommunity ? () => setIsDeleteDialogOpen(true): undefined}
        onSave={selectedCommunity ? handleUpdateCommunity : handleCreateCommunity}
        onSaveButtonType="button"
      />
    </div>
  );
};

export { CommunitySidebarGeneralView };
