import { InformationBox } from '@netfront/ui-library';
import cx from 'classnames';

import { UserSidebarGeneralViewProps } from './UserSidebarGeneralView.interfaces';

const UserSidebarGeneralView = ({ additionalClassNames, selectedUser }: UserSidebarGeneralViewProps) => {
  return (
    <div className={cx('c-user-sidebar-general-view', additionalClassNames)}>

      {selectedUser?.posts.length ? (
        <div className="c-user-sidebar-general-view__posts">
          <h4 className="c-user-sidebar-general-view__posts-title">Comments</h4>
          <ul className="c-user-sidebar-general-view__posts-list">
            {selectedUser.posts.map(({ id, topic, message }) => (
              <li key={id} className="c-user-sidebar-general-view__posts-list-item">
                {topic?.title ? <span className="c-user-sidebar-general-view__posts-list-item-title">{topic.title} - </span> : null}
                <span className="c-user-sidebar-general-view__posts-list-item-message">{message}</span>
              </li>
            ))}
          </ul>
          <div className="c-user-sidebar-general-view__posts-list-item-count">{selectedUser.posts.length} comments</div>
        </div>
      ) : (
        <InformationBox message={`${String(selectedUser?.firstName)} has not made any posts`} />
      )}
    </div>
  );
};

export { UserSidebarGeneralView };
