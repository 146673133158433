import { gql } from '@apollo/client';

const GET_LINKABLE_PAGES = gql`
  query getPages(
    $after: String
    $first: Int
    $tags: [Int]
    $type: Int
    $sorting: ESorting
    $contentGroupId: Int
    $contentGroupType: EContentGroupType
    $message: String
    $containsExactMessage: Boolean
    $projectId: String!
  ) {
    contentPage {
      search(
        projectId: $projectId
        after: $after
        first: $first
        tags: $tags
        type: $type
        containsExactMessage: $containsExactMessage
        contentGroupId: $contentGroupId
        contentGroupType: $contentGroupType
        message: $message
        sorting: $sorting
      ) {
        totalCount
        pageInfo {
          endCursor
        }
        edges {
          cursor
          node {
            id
            title
            contentGroup {
              id
              title
            }
            created
            featured
            url
          }
        }
      }
    }
  }
`;

export { GET_LINKABLE_PAGES };
