import { gql } from '@apollo/client';

const GET_CODE_ASSET = gql`
  query GetAsset($assetId: String!) {
    asset {
      getAsset(assetId: $assetId) {
        s3Key
        contentType
        assetId
        alt
        fileName
        tags
        transcript
        alt
        xAxisFocus
        uploaded
        presignedUrl
      }
    }
  }
`;

export { GET_CODE_ASSET };
