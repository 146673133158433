import { IOrdersTableDataParams, IOrdersTableData } from './OrdersPage.interfaces';

const getOrdersTableData = ({ orders, onSettingsButtonClick }: IOrdersTableDataParams): IOrdersTableData[] => {
  return orders.map(({ id, group, createdAtUtc, status, items }): IOrdersTableData => {
    return {
      groupName: group.name,
      id,
      latestOrder: new Date(createdAtUtc).toLocaleDateString(),
      licences: items.length,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
      status,
    };
  });
};

export { getOrdersTableData };
