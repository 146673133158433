import { gql } from '@apollo/client';

const DELETE_ASSET_MUTATION = gql`
  mutation DeleteAsset($assetId: String!) {
    asset {
      deleteAsset(assetId: $assetId)
    }
  }
`;

export { DELETE_ASSET_MUTATION };
