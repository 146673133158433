import { ISnippetOption } from './SnippetTypeSelectorSidebarView.interfaces';

import { SnippetTypeConst, underScoredToResponseTypeConst } from '../../../../../constants';

const snippetTypeDefaultOptions: ISnippetOption[] = [
  {
    iconId: 'id_textbox_icon',
    label: 'Text',
    value: SnippetTypeConst.TEXT,
    type: 'general',
  },
  {
    iconId: 'id_image_hollow_icon',
    label: 'Image',
    value: SnippetTypeConst.IMAGE,
    type: 'general',
  },
  {
    iconId: 'id_hollow_video_icon',
    label: 'Video',
    value: SnippetTypeConst.VIDEO,
    type: 'general',
  },
  {
    iconId: 'id_audio_icon',
    label: 'Audio',
    value: SnippetTypeConst.AUDIO,
    type: 'general',
  },
  {
    iconId: 'id_actions_icon',
    label: 'Document',
    value: SnippetTypeConst.DOCUMENT,
    type: 'general',
  },
  {
    iconId: 'id_button_icon',
    label: 'Button',
    value: SnippetTypeConst.BUTTON,
    type: 'general',
  },
  {
    iconId: 'id_forms_icon',
    label: 'Form block',
    value: SnippetTypeConst.FORM,
    type: 'forms',
  },
  {
    iconId: 'id_textbox_icon',
    label: 'Single-line',
    value: underScoredToResponseTypeConst.SINGLE_TEXT,
    type: 'forms',
  },
  {
    iconId: 'id_multi_line_icon',
    label: 'Multi-line',
    value: underScoredToResponseTypeConst.MULTI_TEXT,
    type: 'forms',
  },
  {
    iconId: 'id_radio_icon',
    label: 'Radio',
    value: underScoredToResponseTypeConst.RADIO,
    type: 'forms',
  },
  {
    iconId: 'id_checkbox_icon',
    label: 'Checkbox',
    value: underScoredToResponseTypeConst.CHECKBOX,
    type: 'forms',
  },
  {
    iconId: 'id_replace_icon',
    label: 'Multi-answer',
    value: underScoredToResponseTypeConst.MULTI_RESPONSE,
    type: 'forms',
  },
  // {
  //   iconId: 'id_layout_icon',
  //   label: 'Match',
  //   value: underScoredToResponseTypeConst.MATCH,
  //   type: 'forms',
  // },
  {
    iconId: 'id_forms_icon',
    label: 'Number',
    value: underScoredToResponseTypeConst.NUMBER,
    type: 'forms',
  },
  {
    iconId: 'id_inverted_calendar_icon',
    label: 'Calendar',
    value: underScoredToResponseTypeConst.CALENDAR,
    type: 'forms',
  },
  // {
  //   iconId: 'id_slider_icon',
  //   label: 'Slider',
  //   value: underScoredToResponseTypeConst.SLIDER,
  //   type: 'forms',
  // },
  {
    iconId: 'id_dropdown_icon',
    label: 'Dropdown',
    value: underScoredToResponseTypeConst.DROP_DOWN_LIST,
    type: 'forms',
  },


  // {
  //   iconId: 'id_image_slider_icon',
  //   label: 'Slider',
  //   value: SnippetTypeConst.SLIDER,
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_tabs_icon',
  //   label: 'Tab',
  //   value: SnippetTypeConst.TABS,
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_social_icon',
  //   label: 'Social links',
  //   value: SnippetTypeConst.SOCIAL_LINKS, does not exist
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_social_icon',
  //   label: 'Social feed',
  //   value: SnippetTypeConst.SOCIAL_FEED, does not exist
  //   type: 'other',
  // },
  {
    iconId: 'id_quote_icon',
    label: 'Quote',
    value: SnippetTypeConst.QUOTE,
    type: 'other',
  },
  {
    iconId: 'id_list_view_icon',
    label: 'Summary',
    value: SnippetTypeConst.SUMMARY,
    type: 'other',
  },
  {
    iconId: 'id_code_icon',
    label: 'Code',
    value: SnippetTypeConst.CODE,
    type: 'other',
  },
  // {
  //   iconId: 'id_map_icon',
  //   label: 'Map',
  //   value: SnippetTypeConst.MAP,
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_app_icon',
  //   label: 'App',
  //   value: SnippetTypeConst.APP,
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_pin_icon',
  //   label: 'Image region',
  //   value: SnippetTypeConst.IMAGE_MAP,
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_cluster_icon',
  //   label: 'Clusters',
  //   value: SnippetTypeConst.CLUSTER,
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_record_icon',
  //   label: 'Record',
  //   value: SnippetTypeConst.RECORD, does not exist
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_directory_icon',
  //   label: 'Directory',
  //   value: SnippetTypeConst.RECORD, does not exist
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_avatar_icon',
  //   label: 'Avatar',
  //   value: SnippetTypeConst.AVATAR,
  //   type: 'other',
  // },
  // {
  //   iconId: '', icon needed
  //   label: 'Placeholder',
  //   value: SnippetTypeConst.PLACEHOLDER,  does not exist
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_events_icon',
  //   label: 'Events',
  //   value: SnippetTypeConst.EVENTS,  does not exist
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_image_hollow_icon',
  //   label: 'Cartoon',
  //   value: SnippetTypeConst.CARTOON,  does not exist
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_help_icon',
  //   label: 'Help',
  //   value: SnippetTypeConst.HELP,  does not exist
  //   type: 'other',
  // },
  // {
  //   iconId: 'id_activity_icon',
  //   label: 'Activity',
  //   value: SnippetTypeConst.ACTIVITY,  does not exist
  //   type: 'other',
  // },
];


export { snippetTypeDefaultOptions };
