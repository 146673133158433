import { ApolloQueryResult } from '@apollo/client';

import { useHowlerLazyQuery } from 'hooks/social/useHowlerLazyQuery';

import { GET_ANNOUNCES } from './useGetAnnounces.graphql';
import {
  IGetAnnouncesQueryGraphQLResponse,
  IGetAnnouncesQueryVariables,
  IHandleFetchMorePaginatedAnnouncesParams,
  IHandleGetAnnouncesParams,
  IUseGetAnnounces,
  IUseGetAnnouncesOptions,
} from './useGetAnnounces.interfaces';

const useGetAnnounces = (options?: IUseGetAnnouncesOptions): IUseGetAnnounces => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetAnnouncesOptions);

  const [executeGetAnnounces, { fetchMore, loading: isLoading }] = useHowlerLazyQuery<
    IGetAnnouncesQueryGraphQLResponse,
    IGetAnnouncesQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          announce: { byProject },
        } = data;

        onCompleted({
          announces: byProject,
        });
      },
      onError,
    },
    query: GET_ANNOUNCES,
    token,
  });

  const handleFetchMorePaginatedAnnounces = ({
    after,
    first,
    projectId,
  }: IHandleFetchMorePaginatedAnnouncesParams): Promise<ApolloQueryResult<IGetAnnouncesQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          announce: { byProject: newlyFetchedAnnounces },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedAnnounces;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          announce: { byProject: previouslyFetchedAnnounces },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedAnnounces;

        return {
          ...previousQueryResult,
          notification: {
            ...previouslyFetchedAnnounces,
            notificationGroups: {
              ...newlyFetchedAnnounces,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        projectId,
      },
    });
  };

  const handleGetAnnounces = ({ after, first, projectId, filter }: IHandleGetAnnouncesParams) => {
    void executeGetAnnounces({
      variables: {
        after,
        first,
        projectId,
        filter,
      },
    });
  };

  return {
    handleFetchMorePaginatedAnnounces,
    handleGetAnnounces,
    isLoading,
  };
};

export { useGetAnnounces };
