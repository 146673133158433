import axios from 'axios';

const pushImageToAws = async (signedUrl: string, uploadedFile: File | undefined, callBack: () => void) => {
  const { type } = uploadedFile ?? {};

  await axios.put(String(signedUrl), uploadedFile, { headers: { 'content-type': String(type)} }).then(() => {
    callBack();
  });
};

export { pushImageToAws };
