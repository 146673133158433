import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ASSET_QUERY } from './useGetAsset.graphql';
import {
  IGetAssetQueryGraphQLResponse,
  IGetAssetQueryVariables,
  IHandleGetAssetParams,
  IUseGetAsset,
  IUseGetAssetOptions,
} from './useGetAsset.interfaces';

const useGetAsset = (options?: IUseGetAssetOptions): IUseGetAsset => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAssetOptions);

  const [executeGetAsset, { loading: isLoading }] = useEkardoLazyQuery<IGetAssetQueryGraphQLResponse, IGetAssetQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: {
            getAsset: { presignedUrl },
          },
        } = data;

        void onCompleted({ presignedUrl });
      },
      onError,
    },
    query: query ?? GET_ASSET_QUERY,
    token,
  });

  const handleGetAsset = ({ assetId }: IHandleGetAssetParams) => {
    void executeGetAsset({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleGetAsset,
    isLoading,
  };
};

export { useGetAsset };
