

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { EXPORT_ALL_MODULE_PAGE_COMPLETIONS } from './useGetAllModulePagesCompletedReport.graphql';
import {
  IGetAllModulePagesCompletedReportQueryGraphQLResponse,
  IGetAllModulePagesCompletedReportQueryVariables,
  IHandleGetAllModulePagesCompletedReportParams,
  IUseGetAllModulePagesCompletedReport,
  IUseGetAllModulePagesCompletedReportOptions,
} from './useGetAllModulePagesCompletedReport.interfaces';

const useGetAllModulePagesCompletedReport = (options?: IUseGetAllModulePagesCompletedReportOptions): IUseGetAllModulePagesCompletedReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAllModulePagesCompletedReportOptions);

  const [executeGetAllModulePagesCompletedReport, { loading: isLoading }] = useEkardoLazyQuery<
    IGetAllModulePagesCompletedReportQueryGraphQLResponse,
    IGetAllModulePagesCompletedReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportAllModulePageCompletions } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportAllModulePageCompletions,
        });
      },
      onError,
    },
    query: query ?? EXPORT_ALL_MODULE_PAGE_COMPLETIONS,
    token,
  });


  const handleGetAllModulePagesCompletedReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    userFlowId,
  }: IHandleGetAllModulePagesCompletedReportParams) => {
    await executeGetAllModulePagesCompletedReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        userFlowId,
      },
    });
  };

  return {
    handleGetAllModulePagesCompletedReport,
    isLoading,
  };
};

export { useGetAllModulePagesCompletedReport };
