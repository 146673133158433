
import { ICommentLogsTableData, ICommentLogsTableDataParams } from "./CommentLogsReportPage.interfaces";


const getCommentLogsTableData = ({ items }: ICommentLogsTableDataParams): ICommentLogsTableData[] => {
  return items.map(({
    action,
    authorId,
    comment,
    commentDate,
    commentDateUtc,
    groupName,
    id,
    userType,
   }) => {
    return {
      action,
      authorId,
      comment,
      commentDate,
      commentDateUtc,
      groupName,
      id,
      userType,
    };
  });
};

export { getCommentLogsTableData };
