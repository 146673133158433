
// Content Event Sidebar
export const styleTypeOptions = [
  /*
  {
    id: 0,
    value: 'NONE',
    name: 'None',
  },
  */
  {
    id: 0,
    value: 'TEXT_ONLY',
    name: 'Text',
  },
  {
    id: 1,
    value: 'TEXT_AND_ICON',
    name: 'Text & custom icon',
  },
  {
    id: 2,
    value: 'ICON_ONLY',
    name: 'Custom icon',
  },
];

export const triggerStyleTypeOptions = [
  {
    id: 0,
    value: 'CIRCLE_ARROW',
    name: 'Circle arrow',
  },
  {
    id: 1,
    value: 'ARROW',
    name: 'Arrow',
  },
  {
    id: 2,
    value: 'CIRCLE_PLUS',
    name: 'Circle plus',
  },
  {
    id: 3,
    value: 'PLUS',
    name: 'Plus',
  },
];

export const typeOptions = [
  {
    id: 0,
    value: 'NONE',
    name: 'None',
  },
  {
    id: 1,
    value: 'REVEAL',
    name: 'Reveal',
  },
  {
    id: 2,
    value: 'POP_UP',
    name: 'Pop up',
  },
  {
    id: 3,
    value: 'FLIP',
    name: 'Flip',
  },
];

export const directionOptions = [
  {
    id: 0,
    value: 'TOP',
    name: 'Top',
  },
  {
    id: 1,
    value: 'RIGHT',
    name: 'Right',
  },
  {
    id: 2,
    value: 'LEFT',
    name: 'Left',
  },
  {
    id: 3,
    value: 'BOTTOM',
    name: 'Bottom',
  },
];
