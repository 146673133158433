/* eslint-disable @typescript-eslint/indent */
import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { ADD_ANNOUNCE_QUERY } from './useAddAnnounce.graphql';
import {
  IAddAnnounceQueryGraphQLResponse,
  IAddAnnounceQueryVariables,
  IUseAddAnnounce,
  IUseAddAnnounceOptions,
} from './useAddAnnounce.interfaces';

const useAddAnnounce = (options?: IUseAddAnnounceOptions): IUseAddAnnounce => {
  const { onCompleted, onError, token } = options ?? ({} as IUseAddAnnounceOptions);

  const [executeAddAnnounce, { loading: isLoading }] = useHowlerMutation<IAddAnnounceQueryGraphQLResponse, IAddAnnounceQueryVariables>({
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }
        const {
          announce: { add },
        } = data;

        onCompleted({ announce: add });
      },
      onError,
    },
    mutation: ADD_ANNOUNCE_QUERY,
    token,
  });

  const handleAddAnnounce = async (params: IAddAnnounceQueryVariables) => {
    await executeAddAnnounce({
      variables: params,
    });
  };

  return {
    handleAddAnnounce,
    isLoading,
  };
};

export { useAddAnnounce };
