import React, { useContext, useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, Input, ToggleSwitch, ColorPickerPreview, ColorPicker, InputFieldWrapper, Select, SelectWithSearch, ISearchList, Spinner } from '@netfront/ui-library';
import { ContentBuilderTabWrapper } from 'components';
import { ContentBuilderContext } from 'context';
import { useGetLinkablePages, useToast } from 'hooks';
import { Controller } from 'react-hook-form';

import { UpsertButtonSnippetGeneralTabProps } from './UpsertButtonSnippetGeneralTab.interfaces';


const UpsertButtonSnippetGeneralTab = ({ control, hasInternalLink = false, setValue, initialLinkedPageTitle }: UpsertButtonSnippetGeneralTabProps) => {
  const { projectId, contentGroupId } = useContext(ContentBuilderContext);
  const { handleToastError } = useToast();

  const [isInternal, setIsInternal] = useState<boolean>(hasInternalLink);
  const [isBackgroundColorPickerOpen, setIsBackgroundColorPickerOpen] = useState<boolean>(false);
  const [isBorderColorPickerOpen, setIsBorderColorPickerOpen] = useState<boolean>(false);
  const [isFontColorPickerOpen, setIsFontColorPickerOpen] = useState<boolean>(false);
  const [isHoverColorPickerOpen, setIsHoverColorPickerOpen] = useState<boolean>(false);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [linkedPageTitle, setLinkedPageTitle] = useState<string>();
  const [pageOptions, setPageOptions] = useState<ISearchList[]>([]);

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const { handleGetLinkablePages, isLoading: isGetLinkablePagesLoading = false } = useGetLinkablePages({
    onCompleted: ({ contentPages }) => {
      const suggestions = contentPages.map(({ id, title, contentGroup: { title: contentGroupTitle} }) => ({
          id,
          label: `${String(contentGroupTitle)} - ${String(title)}`,
        }));
        setPageOptions(suggestions);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handlePickLinkedPage = (selectedId: string | number) => {
    setValue('pageId', Number(selectedId));
    setValue('hyperlink', '');
    const selectedPage = pageOptions.find(({ id }) => id === Number(selectedId));
    if (selectedPage) setLinkedPageTitle(selectedPage.label);
  };

  useEffect(() => {
    if (!initialLinkedPageTitle) return;

    setLinkedPageTitle(initialLinkedPageTitle);

  }, [initialLinkedPageTitle]);

  useEffect(() => {
    if (!(projectId && contentGroupId)) return;

    if (pageOptions.length > 0) return;

    void handleGetLinkablePages({
      contentGroupId,
      projectId,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, contentGroupId]);

  return (
    <ContentBuilderTabWrapper>
      <Spinner isLoading={isGetLinkablePagesLoading} />

      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_button_snippet_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="innerText"
          render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_button_snippet_text"
                labelText="Text"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )
          }
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="isInternal"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_internal_link"
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText="Internal link"
              tooltipText="Internal linking: If switched on you can search and link to pages within your project, updates to the content URL will not break this link. If switched off you can add external absolute links"
              isInline
              {...field}
              onChange={(event) => {
                const { target: { checked: isChecked }} = event;
                field.onChange(event);
                setIsInternal(isChecked);
                setValue('pageId', '');
                setLinkedPageTitle('');
              }}
            />
          )}
        />
      </Spacing>

      {isInternal ? (
        <Spacing>
          <Controller
            control={control}
            name="pageId"
            render={({ fieldState }) => (
              <InputFieldWrapper
                id="id_linked_page"
                label="Search"
                message={{ error: getFormFieldErrorMessage(fieldState), success: ''}}
                type="select"
                isLabelSideBySide
                isRequired
              >
                <SelectWithSearch
                  buttonText="Search pages"
                  countText="pages"
                  defaultValue={linkedPageTitle}
                  isDisplaySearchContent={isSearchContentVisible}
                  searchList={pageOptions.map(({id, label: pageOptionLabel }) => ({
                    id,
                    label: pageOptionLabel,
                  }))}
                  isAvatarVisible
                  onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
                  onSearchItemClick={handlePickLinkedPage}
                />
              </InputFieldWrapper>
            )}
          />
        </Spacing>
      ): (
        <Spacing>
          <Controller
            control={control}
            name="hyperlink"
            render={({ field, fieldState }) => (
                <Input
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_button_snippet_hyperlink"
                  labelText="Hyperlink"
                  placeholder="Link e.g. https://google.com"
                  type="text"
                  isLabelSideBySide
                  {...field}
                />
              )
            }
          />
        </Spacing>
      )}

      <Spacing>
        <Controller
          control={control}
          name="buttonStyle"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_link_style"
              labelText="Style"
              options={[
                {
                  id: 'SMALL',
                  value: 'SMALL',
                  name: 'Small'
                },
                {
                  id: 'MEDIUM',
                  value: 'MEDIUM',
                  name: 'Medium'
                },
                {
                  id: 'LARGE',
                  value: 'LARGE',
                  name: 'Large'
                }
              ]}
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="target"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_link_target"
              labelText="Target"
              options={[
                {
                  id: 'blank',
                  value: '_blank',
                  name: 'Blank'
                },
                {
                  id: 'self',
                  value: '_self',
                  name: 'Self'
                }
              ]}
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="fontColor"
          render={({ field, fieldState }) => (
            <div className="c-sidebar-color-picker">
              <InputFieldWrapper
                id="id_font_colour"
                label="Font colour"
                message={{error: getFormFieldErrorMessage(fieldState), success: ''}}
                type="custom"
                isLabelSideBySide
              >
                <Spacing size="x-small">
                  <ColorPickerPreview
                    selectedColor={field.value}
                    onClick={() => setIsFontColorPickerOpen(!isFontColorPickerOpen)} />
                </Spacing>
                {isFontColorPickerOpen && <ColorPicker additionalClassNames="" color={field.value} type="hex" onHexChange={field.onChange} />}
              </InputFieldWrapper>
            </div>
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="buttonBackgroundColor"
          render={({ field, fieldState }) => (
            <div className="c-sidebar-color-picker">
              <InputFieldWrapper
                id="id_background_colour"
                label="Background colour"
                message={{error: getFormFieldErrorMessage(fieldState), success: ''}}
                type="custom"
                isLabelSideBySide
              >
                <Spacing size="x-small">
                  <ColorPickerPreview
                    selectedColor={field.value}
                    onClick={() => setIsBackgroundColorPickerOpen(!isBackgroundColorPickerOpen)} />
                </Spacing>
                {isBackgroundColorPickerOpen && <ColorPicker additionalClassNames="" color={field.value} type="hex" onHexChange={field.onChange} />}
              </InputFieldWrapper>
            </div>
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="hoverColor"
          render={({ field, fieldState }) => (
            <div className="c-sidebar-color-picker">
              <InputFieldWrapper
                id="id_hover_colour"
                label="Hover colour"
                message={{error: getFormFieldErrorMessage(fieldState), success: ''}}
                type="custom"
                isLabelSideBySide
              >
                <Spacing size="x-small">
                  <ColorPickerPreview
                    selectedColor={field.value}
                    onClick={() => setIsHoverColorPickerOpen(!isHoverColorPickerOpen)} />
                </Spacing>
                {isHoverColorPickerOpen && <ColorPicker additionalClassNames="" color={field.value} type="hex" onHexChange={field.onChange} />}
              </InputFieldWrapper>
            </div>
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="borderColor"
          render={({ field, fieldState }) => (
            <div className="c-sidebar-color-picker">
              <InputFieldWrapper
                id="id_border_colour"
                label="Border colour"
                message={{error: getFormFieldErrorMessage(fieldState), success: ''}}
                type="custom"
                isLabelSideBySide
              >
                <Spacing size="x-small">
                  <ColorPickerPreview
                    selectedColor={field.value}
                    onClick={() => setIsBorderColorPickerOpen(!isBorderColorPickerOpen)} />
                </Spacing>
                {isBorderColorPickerOpen && <ColorPicker additionalClassNames="" color={field.value} type="hex" onHexChange={field.onChange} />}
              </InputFieldWrapper>
            </div>
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_sensitive_content"
              isChecked={field.value}
              labelText="Sensitive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isVisible"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_visible"
              isChecked={field.value}
              labelText="Is visible"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

    </ContentBuilderTabWrapper>
  );
};

export { UpsertButtonSnippetGeneralTab };
