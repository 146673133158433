import { gql } from '@apollo/client';

const REMOVE_FROM_FAVORITE_ASSETS_MUTATION = gql`
  mutation RemoveFromFavoriteAssets($assetId: String!) {
    user {
      removeFavouriteAsset(assetId: $assetId)
    }
  }
`;

export { REMOVE_FROM_FAVORITE_ASSETS_MUTATION };
