import { gql } from '@apollo/client';

const GET_ASSET_QUERY = gql`
  query GetAsset($assetId: String!) {
    asset {
      getAsset(assetId: $assetId) {
        presignedUrl
      }
    }
  }
`;

export { GET_ASSET_QUERY };
