import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_CONTAINER } from './useUpdateContainer.graphql';
import {
  IUpdateContainerMutationGraphQLResponse,
  IUpdateContainerMutationVariables,
  IUseUpdateContainerOptions,
  IUseUpdateContainer,
  IUpdateContainerMutationParams,
} from './useUpdateContainer.interfaces';

const useUpdateContainer = (options?: IUseUpdateContainerOptions): IUseUpdateContainer => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateContainerOptions);

  const [executeUpdateContainer, { loading: isLoading }] = useEkardoMutation<IUpdateContainerMutationGraphQLResponse, IUpdateContainerMutationVariables>({
    mutation: mutation ?? UPDATE_CONTAINER,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { updateContainer: container },
        } = data;

        onCompleted({
          container,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateContainer = async ({
    // TODO @ash remove when contentContainer input is setup
    backgroundColor,
    borderColor,
    containerId,
    sectionId,
    title,
    visible,


    // contentContainer,
    shouldCreateEvent,
    shouldUpdateEvent,
    updateContentEvent,
    createContentEvent,
  }: IUpdateContainerMutationParams) => {
    await executeUpdateContainer({
      variables: {
        backgroundColor,
        borderColor,
        containerId,
        sectionId,
        title,
        visible,
        // contentContainer,
        shouldCreateEvent,
        shouldUpdateEvent,
        updateContentEvent,
        createContentEvent,
      }
    });
  };

  return {
    handleUpdateContainer,
    isLoading,
  };
};

export { useUpdateContainer };
