import { useBonoboMutation } from '../useBonoboMutation';

import { CREATE_TOPIC_MUTATION } from './useCreateTopic.graphql';
import {
  ICreateTopicMutationGraphQLResponse,
  ICreateTopicMutationVariables,
  IHandleCreateTopicParams,
  IUseCreateTopicOptions,
  IUseCreateTopic,
} from './useCreateTopic.interfaces';

const useCreateTopic = (options?: IUseCreateTopicOptions): IUseCreateTopic => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseCreateTopicOptions);

  const [executeCreateTopic, { loading: isLoading }] = useBonoboMutation<
    ICreateTopicMutationGraphQLResponse,
    ICreateTopicMutationVariables
  >({
    mutation: mutation ?? CREATE_TOPIC_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          topic: { addTopic: topicsConnection },
        } = data;

        onCompleted({ topicsConnection });
      },
      onError,
    },
    token,
  });

  const handleCreateTopic = async ({ projectId, title, status }: IHandleCreateTopicParams) => {
    await executeCreateTopic({
      variables: {
        projectId,
        title,
        status,
      },
    });
  };

  return {
    handleCreateTopic,
    isLoading,
  };
};

export { useCreateTopic };
