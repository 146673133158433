import { gql } from '@apollo/client';

const SEARCH_PAGINATED_GLOSSARY_QUERY = gql`
  query SearchPaginatedGlossary(
    $projectId: String!
    $filter: String
    $first: Int
    $after: String
  ) {
    textTooltip {
      list(
        projectId: $projectId
        filter: $filter
        first: $first
        after: $after
      ) {
        totalCount
        edges {
          node {
            description
            id
            title
          }
        }
      }
    }
  }
`;


export { SEARCH_PAGINATED_GLOSSARY_QUERY };
