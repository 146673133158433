import { Column } from 'react-table';

import { ICommentLogsTableData } from './CommentLogsReportPage.interfaces';



const COMMENT_LOGS_COLUMNS: ReadonlyArray<Column<ICommentLogsTableData>> = [
  {
    accessor: 'authorId',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Author id</div>,
    width: '7%',
  },
  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Comment id</div>,
    width: '7%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
  },
  {
    accessor: 'userType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">User type</span>,
    width: '10%',
  },
  {
    accessor: 'comment',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Comment</span>,
    width: '30%',
  },
  {
    accessor: 'commentDate',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Comment date</span>,
  },

];

export { COMMENT_LOGS_COLUMNS };
