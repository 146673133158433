import { useContext, useEffect, useState } from "react";

import { IContentPage } from "@netfront/ekardo-content-library";
import { EmptyState, PageContainer, Spinner } from "@netfront/ui-library";
import { FirstPageSidebarView, PageLayout } from "components";
import { DashboardContext } from "context";
import { useGetContentPages, useToast } from "hooks";
import capitalize from "lodash.capitalize";
import { useRouter } from "next/router";

import { ContentBuilderEmptyPageProps } from "./ContentBuilderEmptyPage.interfaces";


const ContentBuilderEmptyPage = ({ contentType, contentGroupUrl }: ContentBuilderEmptyPageProps) => {
  const { handleToastError } = useToast();
  const { query, push } = useRouter();
  const { dashboardLink } = useContext(DashboardContext);
  const { projectId: queryProjectId } = query;

  const [projectId, setProjectId] = useState<string>();
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [contentGroupId, setContentGroupId] = useState<number>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleRedirect = (url: string) => {
    push(`${String(redirectUrl)}/${url}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };

  const { handleGetContentPages, isLoading: isGetContentPagesLoading = false } = useGetContentPages({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentGroup }) => {
      const { id, firstPage } = contentGroup;
      const { url: pageUrl = ''} = firstPage ?? {};

      if (pageUrl) {
        handleRedirect(pageUrl);
      } else {
        setContentGroupId(id);
      }
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleSave = (contentPage: IContentPage) => {
    const { url } = contentPage;
    setIsDialogOpen(false);
    handleRedirect(url);
  };

  useEffect(() => {
    if (!(contentGroupUrl && projectId)) return;

    handleGetContentPages({
      url: contentGroupUrl,
      projectId,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentGroupUrl, projectId]);

  useEffect(() => {
    if (!queryProjectId) return;

    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    if (!(dashboardLink && contentGroupUrl)) return;
    setRedirectUrl(`${String(dashboardLink)}/alpha-content/builder/${contentType.toLowerCase()}/${contentGroupUrl}`);

  }, [dashboardLink, contentGroupUrl, contentType])

  const isLoading = isGetContentPagesLoading;
  return (
    <PageLayout
      meta={{ description: 'Content builder' }}
      title={`${capitalize(contentType)} content builder`}
      hasPrivateLayout
    >
      <Spinner isLoading={isLoading} />
      <PageContainer>
        <EmptyState
          buttonText="New page"
          imageUrl="/images/content-landing.svg"
          size="large"
          text="Get building by hitting new page below. Need some help? Check out our help guide."
          title="Get started!"
          onClick={() => setIsDialogOpen(true)} />
      </PageContainer>
        <FirstPageSidebarView
          contentGroupId={Number(contentGroupId)}
          isOpen={isDialogOpen}
          onCancel={() => setIsDialogOpen(false)}
          onSave={handleSave}
        />
    </PageLayout>
  );
}

export { ContentBuilderEmptyPage };
