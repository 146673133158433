import { useEffect, useState } from 'react';

import { AvailableChart, AvailableChartProps, ChartPicker, ILineChartDataset, ILegend, LineChartCard, BarChartCard, IBarChartDataset } from '@netfront/ui-library';
import { IGetTeacherRegistrationTrialOnCompletedResponse, useGetTeacherRegistrationTrial } from 'hooks';
import { DailyCount } from 'interfaces';

import { InsightsChartsConstants } from '../Insights.constants';

import { UserRegistrationProps } from './TrialUserRegistrationChart.interfaces';

const TrialUserRegistrationChart = ({ projectId, end, start, isFullWidth = false }: UserRegistrationProps) => {
  const LABEL = 'Trial user registration';
  const { colors: [primaryColor], borderWidth, barThicknessSmall: barThickness } = InsightsChartsConstants;

  const [activeChart, setActiveChart] = useState<AvailableChart>('Line');
  const [dailyCount, setDailyCount] = useState<DailyCount[]>([]);
  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };


  const onGetTeacherRegistrationCompleted = (data: IGetTeacherRegistrationTrialOnCompletedResponse) => {
    const { userRegistration } = data;
    setDailyCount(userRegistration);
  };

  const { handleGetTeacherRegistrationTrial } = useGetTeacherRegistrationTrial({
    onCompleted: onGetTeacherRegistrationCompleted,
  });


  const legends: ILegend[] = [
    {
      color: primaryColor,
      label: 'Trial user registration',
    },
  ];

  const getTotalCount = () => {
    return dailyCount.reduce((acc, dc) => {
      acc += dc.count;
      return acc;
    }, 0);
  }

  const lineChart: ILineChartDataset[] = [
    {
      backgroundColor: primaryColor,
      borderColor: primaryColor,
      data: dailyCount.map((r) => r.count),
      label: 'Trial user',
      borderWidth,
    },
  ];

  const barData: IBarChartDataset[] = [
    {
      backgroundColor: [primaryColor],
      data: dailyCount.map((d) => d.count),
      label: '',
      id: 'id_daily_key',
      barThickness,
      borderWidth,
    },
  ]

  const availableCharts: AvailableChartProps[] = [
    {
      value: 'Line',
      component: <LineChartCard datasets={lineChart} displayValue={getTotalCount()} label={LABEL} labels={dailyCount.map((r) => new Date(r.date).toLocaleDateString('en-AU', { day: '2-digit', month: '2-digit' }))} legends={legends} />,
    },
    {
      value: 'Bar',
      component: (
        <BarChartCard
          datasets={barData}
          displayedValue={getTotalCount()}
          label={LABEL}
          labels={dailyCount.map((r) => new Date(r.date).toLocaleDateString('en-AU', { day: '2-digit', month: '2-digit' }))}
          legends={legends}
        />
      ),
    },
  ];



  useEffect(() => {
    if (!projectId) return;
    void handleGetTeacherRegistrationTrial({
      end,
      projectId,
      start,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, projectId]);


  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_user_registration_chart_picker"
      isFullWidth={isFullWidth}
      onChange={onActiveChartChange}
    />
  );
};

export { TrialUserRegistrationChart };
