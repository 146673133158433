import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentSnippet } from '@netfront/ekardo-content-library';
import { getUpsertContentEventVariables, IQuestionSnippetConfig } from 'components';
import {
  IHandleCreateCalendarQuestionConfigParams,
  IHandleCreateCheckboxQuestionConfigParams,
  IHandleCreateDropDownListQuestionConfigParams,
  IHandleCreateMultiLineTextQuestionConfigParams,
  IHandleCreateMultiResponseTextQuestionConfigParams,
  IHandleCreateNumberQuestionConfigParams,
  IHandleCreateRadioQuestionConfigParams,
  IHandleCreateSingleTextQuestionConfigParams,
  IHandleCreateSliderQuestionConfigParams,
  IHandleCreateSnippetParams,
  IHandleUpdateSnippetParams,
  useCreateCalendarQuestionConfig,
  useCreateCheckboxQuestionConfig,
  useCreateContentEvent,
  useCreateDropDownListQuestionConfig,
  useCreateMultiLineTextQuestionConfig,
  useCreateMultiResponseTextQuestionConfig,
  useCreateNumberQuestionConfig,
  useCreateQuestionConfigMessages,
  useCreateRadioQuestionConfig,
  useCreateSingleTextQuestionConfig,
  useCreateSliderQuestionConfig,
  useCreateSnippet,
  useDefineResponseSet,
  useToast,
  useUpdateSnippet,
} from 'hooks';

import { ResponseTypeFunction, ResponseTypeParams } from './useUpsertQuestionSnippet.interfaces';

import { responseTypeToUnderScoredConst } from '../../../../../constants';



const useUpsertQuestionSnippet = ({ onCreate, onUpdate, projectId }: { onCreate: () => void; onUpdate: (returnedSnippet: IContentSnippet) => void; projectId: string}) => {
  const questionSnippetVariablesRef = useRef<{value :IQuestionSnippetConfig | undefined}>({value: undefined});
  const containerRef = useRef<{value :number | undefined}>({value: undefined});
  const sortRef = useRef<{value :number | undefined}>({value: undefined});
  const snippetRef = useRef<{value : IContentSnippet | undefined}>({value: undefined});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleCreateContentEvent, isLoading: isCreateContentEventLoading = false } = useCreateContentEvent({
    onCompleted: () => {
      handleToastSuccess({
        message: `Question snippet successfully created`,
      });

      onCreate();

    },
    onError: handleGetError,
  });

  const { handleCreateSnippet, isLoading: isCreateSnippetLoading = false } = useCreateSnippet({
    onCompleted: ({ snippet : { id }}) => {

      const { shouldCreateEvent = false, contentEvent = {} } = questionSnippetVariablesRef.current.value ?? {};

      if (!shouldCreateEvent) {
        handleToastSuccess({
          message: `Question snippet successfully created`,
        });
        onCreate();
      } else {

        const { contentEntity } = contentEvent;
        handleCreateContentEvent({
          contentEvent: {
            entityId: id,
            contentEntity,
            ...getUpsertContentEventVariables(contentEvent, 'create'),
          }
        });
      }

    },
    onError: handleGetError,
  });

  const { handleUpdateSnippet, isLoading: isUpdateSnippetLoading = false } = useUpdateSnippet({
    onCompleted: ({ snippet: returnedSnippet }) => {
      handleToastSuccess({
        message: `Questionsnippet successfully updated`,
      });

      onUpdate(returnedSnippet);
    },
    onError: handleGetError,
  });


  const { handleCreateQuestionConfigMessages } = useCreateQuestionConfigMessages({
    onError: handleGetError,
  });

  const handleCreateQuestionConfigurationOnCompleted = (questionConfigId: number) => {
    const { baseSnippet, questionSnippet } = questionSnippetVariablesRef.current.value ?? {};

    const { question, behavior, identifier = '', majorNumber = '', minorNumber } = questionSnippet ?? {};

    handleCreateQuestionConfigMessages({
      configurationId: questionConfigId,
      messages: [
        {
          type: 'CORRECT',
          text: '',
        },
        {
          type: 'INCORRECT',
          text: '',
        },
        {
          type: 'EXAMPLE',
          text: '',
        },
        {
          type: 'INCOMPLETE',
          text: '',
        },

      ],
    });

    const createVariables = {
      baseSnippet: {
        ...baseSnippet,
        sort: Number(sortRef.current.value),
        containerId: Number(containerRef.current.value),
      } ,
      questionSnippet: {
        behavior,
        questionConfigurationId: questionConfigId,
        identifier,
        majorNumber,
        minorNumber,
        question,
        mandatory: behavior === 'MANDATORY'
      }
    } as IHandleCreateSnippetParams;

    handleCreateSnippet({
      ...createVariables,
    });
  };



  // For certain question types we need to define the response set, currently for Checkbox, Radio, DropDownList
  const { handleDefineResponseSet, isLoading: isDefineResponseSetLoading = false } = useDefineResponseSet({
    onCompleted: ({ configuration }) => handleCreateQuestionConfigurationOnCompleted(configuration.id),
    onError: handleGetError,
  });

  const handleResponseSetQuestionConfigOnCompleted = (questionConfigId: number, isDefinitionRequired = false) => {
    const { configuration } = questionSnippetVariablesRef.current.value ?? {};

      if (isDefinitionRequired) {
        const { responseSetId } = configuration ?? {};
        handleDefineResponseSet({
          questionConfigurationId: questionConfigId,
          questionResponseSetId: responseSetId,
        });
      } else {
        handleCreateQuestionConfigurationOnCompleted(questionConfigId);
      }
  };

  // Create the question config
  const { handleCreateCalendarQuestionConfig, isLoading: isCreateCalendarQuestionConfigLoading = false } = useCreateCalendarQuestionConfig({
    onCompleted: ({ configuration }) => handleResponseSetQuestionConfigOnCompleted(configuration.id),
    onError: handleGetError,
  });

  const { handleCreateCheckboxQuestionConfig, isLoading: isCreateCheckboxQuestionConfigLoading = false } = useCreateCheckboxQuestionConfig({
    onCompleted: ({ configuration }) => handleResponseSetQuestionConfigOnCompleted(configuration.id, true),
    onError: handleGetError,
  });

  const { handleCreateDropDownListQuestionConfig, isLoading: isCreateDropDownListQuestionConfigLoading = false } = useCreateDropDownListQuestionConfig({
    onCompleted: ({ configuration }) => handleResponseSetQuestionConfigOnCompleted(configuration.id, true),
    onError: handleGetError,
  });

  const { handleCreateMultiLineTextQuestionConfig, isLoading: isCreateMultiLineTextQuestionConfigLoading = false } = useCreateMultiLineTextQuestionConfig({
    onCompleted: ({ configuration }) => handleResponseSetQuestionConfigOnCompleted(configuration.id),
    onError: handleGetError,
  });

  const { handleCreateMultiResponseTextQuestionConfig, isLoading: isCreateMultiResponseTextQuestionConfigLoading = false } = useCreateMultiResponseTextQuestionConfig({
    onCompleted: ({ configuration }) => handleResponseSetQuestionConfigOnCompleted(configuration.id),
    onError: handleGetError,
  });

  const { handleCreateNumberQuestionConfig, isLoading: isCreateNumberQuestionConfigLoading = false } = useCreateNumberQuestionConfig({
    onCompleted: ({ configuration }) => handleResponseSetQuestionConfigOnCompleted(configuration.id),
    onError: handleGetError,
  });

  const { handleCreateRadioQuestionConfig, isLoading: isCreateRadioQuestionConfigLoading = false } = useCreateRadioQuestionConfig({
    onCompleted: ({ configuration }) => handleResponseSetQuestionConfigOnCompleted(configuration.id, true),
    onError: handleGetError,
  });

  const { handleCreateSingleTextQuestionConfig, isLoading: isCreateSingleTextQuestionConfigLoading = false } = useCreateSingleTextQuestionConfig({
    onCompleted: ({ configuration }) => handleResponseSetQuestionConfigOnCompleted(configuration.id),
    onError: handleGetError,
  });

  const { handleCreateSliderQuestionConfig, isLoading: isCreateSliderQuestionConfigLoading = false } = useCreateSliderQuestionConfig({
    onCompleted: ({ configuration }) => handleResponseSetQuestionConfigOnCompleted(configuration.id, true),
    onError: handleGetError,
  });

  const responseTypeFunctionMap = {
    'CALENDAR': (params: ResponseTypeParams) => handleCreateCalendarQuestionConfig(params as IHandleCreateCalendarQuestionConfigParams),
    'CHECKBOX':  (params: ResponseTypeParams) => handleCreateCheckboxQuestionConfig(params as IHandleCreateCheckboxQuestionConfigParams),
    'DROP_DOWN_LIST':  (params: ResponseTypeParams) => handleCreateDropDownListQuestionConfig(params as IHandleCreateDropDownListQuestionConfigParams),
    'MULTI_TEXT':  (params: ResponseTypeParams) => handleCreateMultiLineTextQuestionConfig(params as IHandleCreateMultiLineTextQuestionConfigParams),
    'MULTI_RESPONSE':  (params: ResponseTypeParams) => handleCreateMultiResponseTextQuestionConfig(params as IHandleCreateMultiResponseTextQuestionConfigParams),
    'NUMBER':  (params: ResponseTypeParams) => handleCreateNumberQuestionConfig(params as IHandleCreateNumberQuestionConfigParams),
    'RADIO':  (params: ResponseTypeParams) => handleCreateRadioQuestionConfig(params as IHandleCreateRadioQuestionConfigParams),
    'SINGLE_TEXT':  (params: ResponseTypeParams) => handleCreateSingleTextQuestionConfig(params as IHandleCreateSingleTextQuestionConfigParams),
    'SLIDER':  (params: ResponseTypeParams) => handleCreateSliderQuestionConfig(params as IHandleCreateSliderQuestionConfigParams),
  };

  const getResponseSetVariables = (
    type: string,
    config: IQuestionSnippetConfig
  ): ResponseTypeParams => {
    const { baseSnippet, configuration } = config;
    const { entityTitle: title } = baseSnippet;

    const {
      allowUserGeneratedResponses,
      maxLength,
      minLength,
      regex,
      maxCheckedResponse,
      minValue,
      maxValue,
      defaultValue,
      minDate,
      maxDate,
      defaultDate,
      sliderMinValue,
      sliderMaxValue,
      sliderDefaultValue,
      sliderStep,
      sliderTicks,
      maxResponse,
      minResponse,
    } = configuration;

    const QuestionTypeVariableMap: { [key: string]: ResponseTypeParams } = {
      'CALENDAR': {
        minDate: minDate ? new Date(String(minDate)) : undefined,
        maxDate: maxDate ? new Date(String(maxDate)) : undefined,
        defaultDate: defaultDate ? new Date(String(defaultDate)) : undefined,
        description: String(title),
        projectId: String(projectId),
      },
      'CHECKBOX': {
        allowUserGeneratedResponses: Boolean(allowUserGeneratedResponses),
        title: String(title),
        maxCheckedResponse: Number(maxCheckedResponse),
        description: String(title),
        projectId: String(projectId),
      },
      'DROP_DOWN_LIST': {
        canModify: true,
        title: String(title),
        description: String(title),
        projectId: String(projectId),
      },
      'MULTI_RESPONSE': {
        maxResponse: Number(maxResponse),
        minResponse: Number(minResponse),
        description: String(title),
        projectId: String(projectId),
      },
      'MULTI_TEXT': {
        caseSensitive: true,
        maxLength: Number(maxLength),
        minLength: Number(minLength),
        regex: String(regex),
        description: String(title),
        projectId: String(projectId),
      },
      'NUMBER': {
        minValue: Number(minValue),
        maxValue: Number(maxValue),
        defaultValue: Number(defaultValue),
        description: String(title),
        projectId: String(projectId),
      },
      'RADIO': {
        allowUserGeneratedResponses: Boolean(allowUserGeneratedResponses),
        title: String(title),
        canModify: true,
        description: String(title),
        projectId: String(projectId),
      },
      'SINGLE_TEXT': {
        maxLength: Number(maxLength),
        minLength: Number(minLength),
        regex: String(regex),
        description: String(title),
        projectId: String(projectId),
      },
      'SLIDER': {
        minValue: Number(sliderMinValue),
        maxValue: Number(sliderMaxValue),
        defaultValue: Number(sliderDefaultValue),
        step: Number(sliderStep),
        ticks: sliderTicks ?? [],
        description: String(title),
        projectId: String(projectId),
        title: String(title),
      },
    };

    return QuestionTypeVariableMap[type];
  };

  const getQuestionConfigSettings = (type: string, config: IQuestionSnippetConfig): {
    function: ResponseTypeFunction;
    params: ResponseTypeParams;
  } => {
    const params = getResponseSetVariables(type, config);

    const executeFunction = responseTypeFunctionMap[type as keyof typeof responseTypeFunctionMap];
    return {
      function: executeFunction,
      params,
    };
  };

  const createQuestionConfiguration = (type: string, config: IQuestionSnippetConfig) => {

    if (type === responseTypeToUnderScoredConst.Match) {
      handleCreateQuestionConfigurationOnCompleted(Number(config.configuration.configId));
    } else {
      const {
        params,
        function: executeFunction,
      } = getQuestionConfigSettings(type, config);
      executeFunction(params);
    }
  };

  const handleUpsertQuestionSnippet = ({ containerId, snippet, sort, variables }: {containerId: number; snippet?: IContentSnippet; sort: number; variables: IQuestionSnippetConfig; }) => {
    questionSnippetVariablesRef.current.value = variables;
    containerRef.current.value = containerId,
    snippetRef.current.value = snippet;
    sortRef.current.value = sort;

    const {
      baseSnippet,
      questionSnippet,
      shouldCreateEvent = false,
      shouldUpdateEvent = false,
      contentEvent,
      configuration
    } = variables;

    const { contentEntity, contentEventId } = contentEvent;

    const { resType } = configuration;

    const { question, behavior, identifier, configId, majorNumber, minorNumber } = questionSnippet;
    if (!snippet) {
      createQuestionConfiguration(String(resType), variables);
    } else {

      const updateVariables = {
        baseSnippet: {
          ...baseSnippet,
          id: Number(snippet.id),
        },
        questionSnippet: {
          behavior,
          identifier,
          majorNumber,
          minorNumber,
          question,
          mandatory: behavior === 'MANDATORY',
          questionConfigurationId: configId
        },
        shouldCreateEvent,
        shouldUpdateEvent,
        updateContentEvent: shouldUpdateEvent ? {
          contentEventId: Number(contentEventId),
          ...getUpsertContentEventVariables(contentEvent, 'update'),
        }: undefined ,
        createContentEvent: shouldCreateEvent ? {
          entityId: Number(snippet.id),
          contentEntity,
          ...getUpsertContentEventVariables(contentEvent, 'create'),
        }: undefined
      } as IHandleUpdateSnippetParams;
      handleUpdateSnippet({
        ...updateVariables
      });
    }
  };

  return {
    isLoading:
      isCreateCalendarQuestionConfigLoading ||
      isCreateCheckboxQuestionConfigLoading ||
      isCreateDropDownListQuestionConfigLoading ||
      isCreateMultiResponseTextQuestionConfigLoading ||
      isCreateMultiLineTextQuestionConfigLoading ||
      isCreateNumberQuestionConfigLoading ||
      isCreateRadioQuestionConfigLoading ||
      isCreateSingleTextQuestionConfigLoading ||
      isCreateSliderQuestionConfigLoading ||
      isDefineResponseSetLoading ||
      isUpdateSnippetLoading ||
      isCreateContentEventLoading ||
      isCreateSnippetLoading,
    handleUpsertQuestionSnippet,
  };
};
export { useUpsertQuestionSnippet };
