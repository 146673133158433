import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, DatePicker, FlexContainer, IDateRange } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetDiariesReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { DIARIES_COLUMNS } from './DiariesReportPage.constants';
import { getDiariesTableData } from './DiariesReportPage.helpers';
import { DiariesCsvRowData, IDiariesItem, IDiariesTableData } from './DiariesReportPage.interfaces';



const DiariesReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [diariesItems, setDiariesItems] = useState<IDiariesItem[]>();
  const [allDiariesItems, setAllDiariesItems] = useState<IDiariesItem[]>([]);
  const [diariesTableData, setDiariesTableData] = useState<IDiariesTableData[]>([]);
  const [dateRange, setDateRange] = useState<IDateRange>();
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetDiariesReport,
    isLoading: isGetDiariesLoading = false,
  } = useGetDiariesReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<DiariesCsvRowData>(String(res.data), {
            header: true,
            skipEmptyLines: true,
          });

          const formattedItems = data.map((row: DiariesCsvRowData) => ({
            id: Number(row['Id']),
            firstName: String(row['First name']),
            lastName: String(row['Last name']),
            email: String(row['Email']),
            behaviour: String(row['Behaviour']),
            behaviourType: String(row['Behaviour type']),
            date: String(row['Date']),
            value: String(row['Value']),
            createdOnUtc: String(row['Created (utc)']),
          }));
          
          setTotalCount(data.length);
          setAllDiariesItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setDiariesItems(paginatedItems);
          setLastItemIndex(pageSize);
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  const generateReport = () => {
    if (!dateRange || !dateRange.start || !dateRange.end) return
    void handleGetDiariesReport({
      dateFrom: dateRange.start,
      dateTo: dateRange.end,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setDiariesItems(allDiariesItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setDiariesItems(allDiariesItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!diariesItems) {
      return;
    }

    setDiariesTableData(
      getDiariesTableData({
        items: diariesItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diariesItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetDiariesLoading;

  return (
    <TablePageTemplate<IDiariesTableData>
      activePath={['reporting', 'reporting-user-entry', 'export-triplee-diaries']}
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Reporting',
      }, {
        key: '2',
        content: 'Diaries',
      }]}
      childrenEnd={
        <FlexContainer gap="2x-small">
          <Button
            isDisabled={!dateRange || !dateRange.start || !dateRange.end}
            size="xs"
            text="Generate"
            onClick={generateReport}
          />
          {csvUrl && (
            <Button
              additionalClassNames="c-report-download-button"
              iconId="id_csv_icon"
              linkButton={{
                linkComponent: 'a',
                id: `id_csv_download`,
                url: csvUrl,
                target: '_blank'
              }}
              size="xs"
              text="Download"
              isIconOnly
            />
          )}
        </FlexContainer>
      }
      childrenMiddle={(
        <DatePicker
          hasPadding={false}
          id="id_date_selector"
          labelText="Select date range"
          selectedDate={dateRange}
          isLabelHidden
          isRange
          onDateRangeChangeHandler={(e) => {
            if (!e) return;
            setDateRange(e);
          }}
        />
      )}
      columns={DIARIES_COLUMNS}
      data={diariesTableData}
      description={`Diary reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports user diaries based on the selected filters'}
      isLoading={isLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      searchPlaceholder="Search"
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="reports"
      title={`${String(projectName)} reporting`}
      totalItems={totalCount}
    />
  );
};

export { DiariesReportPage };
