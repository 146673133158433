import { ChangeEvent, FormEvent, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { Dialog, Textarea, NavigationButton, Spacing, Preloader, SidebarButtons } from '@netfront/ui-library';
import { useCreateComment, useDeleteComment, useUpdateComment, useToast } from 'hooks';
import { IDBComment } from 'interfaces';

import { AddPostCommentViewProps } from './AddPostCommentView.interfaces';

const AddPostCommentView = ({
  onClose,
  onOpenAddCommentView,
  post,
  projectId,
  selectedComment,
  onCreated,
  onDeleted,
  onUpdated,
}: AddPostCommentViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { handleToastError } = useToast();

  const [commentMessage, setCommentMessage] = useState<string>('');
  const [currentComment, setCurrentComment] = useState<IDBComment>(
    selectedComment ??
      ({
        id: 0,
        message: '',
      } as IDBComment),
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const { id: commentId, message } = currentComment;

  const { handleCreateComment: executeCreateComment, isLoading: isCreateCommentLoading = false } = useCreateComment({
    onCompleted: ({ commentsConnection }) => {
      onOpenAddCommentView();

      onCreated(post, commentsConnection);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId: String(projectId),
  });

  const { handleUpdateComment: executeUpdateComment, isLoading: isUpdateCommentLoading = false } = useUpdateComment({
    onCompleted: ({ commentsConnection }) => {
      onOpenAddCommentView();

      onUpdated(post, commentsConnection);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId: String(projectId),
  });

  const { handleDeleteComment: executeDeleteComment, isLoading: isDeleteCommentLoading = false } = useDeleteComment({
    onCompleted: () => {
      onOpenAddCommentView();

      onDeleted(post, currentComment);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId: String(projectId),
  });

  const handleUpdateCommentInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = event;

    setCommentMessage(value);

    setCurrentComment({
      ...currentComment,
      message: value,
    } as IDBComment);
  };

  const handleCreateComment = () => {
    void executeCreateComment({
      postId: post.id,
      message: commentMessage,
    });
  };

  const handleSubmitCommentForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!selectedComment) {
      handleCreateComment();
    } else {
      handleUpdateComment();
    }
  };

  const handleUpdateComment = () => {
    void executeUpdateComment({
      id: commentId,
      message: commentMessage,
    });
  };

  const isLoading = isCreateCommentLoading || isDeleteCommentLoading || isUpdateCommentLoading;

  return (
    <form className="c-add-post-comment-view" onSubmit={handleSubmitCommentForm}>
      <Preloader isLoading={isLoading} />

      <Spacing size="2x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <NavigationButton
            additionalClassNames="c-action__back-to-action"
            direction="previous"
            iconId="id_caret_icon"
            rotationCssSuffix="90"
            text="Post"
            onClick={onOpenAddCommentView}
          />
        </section>
      </Spacing>

      <Spacing size="large">
        <Textarea
          id="comment"
          labelText="Add new comment"
          maxLength={500}
          name="comment"
          tooltipText="Add post comment"
          value={currentComment.message}
          isRequired
          onChange={handleUpdateCommentInput}
        />
      </Spacing>

      {selectedComment ? (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete comment: ${String(message)}?`}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            if (!commentId) {
              return;
            }

            executeDeleteComment({
              commentId,
            });
          }}
        />
      ) : null}

      <SidebarButtons
        onCancel={onClose}
        onDelete={selectedComment ? () => setIsDeleteDialogOpen(true) : undefined}
        onSaveButtonType="submit"
      />
    </form>
  );
};

export { AddPostCommentView };
