import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_RESPONSE_SET } from './useCreateResponseSet.graphql';
import {
  ICreateResponseSetMutationGraphQLResponse,
  ICreateResponseSetMutationVariables,
  IHandleCreateResponseSetParams,
  IUseCreateResponseSetOptions,
  IUseCreateResponseSet,
} from './useCreateResponseSet.interfaces';

const useCreateResponseSet = (options?: IUseCreateResponseSetOptions): IUseCreateResponseSet => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateResponseSetOptions);

  const [executeCreateResponseSet, { loading: isLoading }] = useEkardoMutation<ICreateResponseSetMutationGraphQLResponse, ICreateResponseSetMutationVariables>({
    mutation: mutation ?? CREATE_RESPONSE_SET,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionSet: { add: config },
        } = data;

        onCompleted({
          config,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateResponseSet = async ({
    projectId,
    responsesCheckbox,
    responsesRadio,
    responsesDropDownList,
    title,
  }: IHandleCreateResponseSetParams) => {
    await executeCreateResponseSet({
      variables: {
        projectId,
        responsesCheckbox,
        responsesRadio,
        responsesDropDownList,
        title,
      }
    });
  };

  return {
    handleCreateResponseSet,
    isLoading,
  };
};

export { useCreateResponseSet };
