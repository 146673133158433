import { gql } from '@apollo/client';

export const FRAGMENT_AUDIO = gql`
  fragment audio on ContentSnippetAudioType {
    assetId
    audio: asset {
      alt
      assetId
      contentType
      description
      fileName
      isFavorite
      isPublic
      tagList {
        id
        name
      }
      presignedUrl
      projectId
      publicS3Key
      publicUrl
      s3Key
      title
      transcript
      type
      uploaded
      user {
        firstName
        lastName
      }
      xAxisFocus
      yAxisFocus
    }
    asset {
      alt
      assetId
      contentType
      description
      fileName
      isFavorite
      isPublic
      tagList {
        id
        name
      }
      presignedUrl
      projectId
      publicS3Key
      publicUrl
      s3Key
      title
      transcript
      type
      uploaded
      user {
        firstName
        lastName
      }
      xAxisFocus
      yAxisFocus
    }
  }
`;
