export const filterDuplicatesByKey = <T>(array: T[], key: keyof T): T[] => {
  const seenKeys: Record<string, boolean> = {};
  return array.filter((obj) => {
    const keyValue = obj[key];
    const keyString = String(keyValue);
    if (!seenKeys[keyString]) {
      seenKeys[keyString] = true;
      return true;
    }
    return false;
  });
};
