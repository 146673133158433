import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_GLOSSARY_ITEM_MUTATION } from './useDeleteGlossaryItem.graphql';
import {
  IDeleteGlossaryItemMutationGraphQLResponse,
  IDeleteGlossaryItemMutationVariables,
  IHandleDeleteGlossaryItemParams,
  IUseDeleteGlossaryItemOptions,
  IUseDeleteGlossaryItem,
} from './useDeleteGlossaryItem.interfaces';

const useDeleteGlossaryItem = (options?: IUseDeleteGlossaryItemOptions): IUseDeleteGlossaryItem => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteGlossaryItemOptions);

  const [executeDeleteGlossaryItem, { loading: isLoading }] = useEkardoMutation<
  IDeleteGlossaryItemMutationGraphQLResponse,
  IDeleteGlossaryItemMutationVariables
  >({
    mutation: mutation ?? DELETE_GLOSSARY_ITEM_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          delete: isCompleted,
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteGlossaryItem = ({ id }: IHandleDeleteGlossaryItemParams) => {
    void executeDeleteGlossaryItem({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteGlossaryItem,
    isLoading,
  };
};

export { useDeleteGlossaryItem };
