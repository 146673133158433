import { gql } from '@apollo/client';

const EXPORT_QUESTIONNAIRES = gql`
  query ExportQuestionnaires(
    $columnHeadingType: EExportQuestionnaireColumnHeadingType!
    $ianaTimeZone: String!
    $includeNonAccountData: Boolean!
    $stepId: Int!
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $fileType: FileType
    $includeDeletedQuestions: Boolean!
  ) {
    report {
      exportQuestionnaires(
        columnHeadingType: $columnHeadingType
        dateFrom: $dateFrom
        dateTo: $dateTo
        ianaTimeZone: $ianaTimeZone
        includeNonAccountData: $includeNonAccountData
        stepId: $stepId
        fileType: $fileType
        includeDeletedQuestions: $includeDeletedQuestions
      ) {
        id
      }
    }
  }
`;


export { EXPORT_QUESTIONNAIRES };
