import { gql } from '@apollo/client';

const GET_USER_ACTIVE = gql`
  query getUserRegistration($projectId: String!, $start: DateTime!, $end: DateTime!) {
    insights {
      userActive(projectId: $projectId, start: $start, end: $end) {
        count
        date
      }
    }
  }
`;

export { GET_USER_ACTIVE };
