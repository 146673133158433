import { gql } from '@apollo/client';

const GET_RESPONSE_SET_SUGGESTIONS = gql`
  query searchResponseSetRequest($type: EQuestionResponseSetType, $projectId: String!, $title: String) {
    questionResponseSet {
      searchResponseSet(projectId: $projectId, type: $type, title: $title) {
        id
        title
        availableResponses {
          id
          label
          questionResponseSetId
          value
        }
      }
    }
  }
`;

export { GET_RESPONSE_SET_SUGGESTIONS };
