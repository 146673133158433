import { gql } from '@apollo/client';

export const FRAGMENT_EMBED = gql`
  fragment embed on ContentSnippetEmbedType {
    embed: code
    title
    embedAssetType: type
    isAutoPlay
    isAuthorVisible
    embedProvider
  }
`;
