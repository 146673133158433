import React, { useEffect, useState, useContext } from 'react';

import { IQuestionAction } from '@netfront/ekardo-content-library';
import { Spacing, InformationBox, Table, ButtonIconOnly, FlexContainer, SidebarButtons, InputFieldWrapper } from '@netfront/ui-library';
import { ContentBuilderContext } from 'context';

import { ActionsOverviewProps } from './ActionsOverview.interfaces';

import { getActionTypeName } from '../../UpsertActionsTab.helpers';



const ActionsOverview = ({ onEditClick, onDeleteClick, actionItems, onDelete }: ActionsOverviewProps) => {
  const { actionsSidebarDetails, closeActionsSidebar } = useContext(ContentBuilderContext);
  const { type = 'targetSnippet' } = actionsSidebarDetails ?? {};

  const [items, setItems] = useState<IQuestionAction[]>([]);

  useEffect(() => {
    setItems(actionItems);
  }, [actionItems]);
  return (
    <>
      <Spacing>
        <InformationBox message="Create one or more actions to personalise the selected content. Multiple actions will be treated with the 'OR' operator." />
      </Spacing>

      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'type',
                Cell: ({ value }: { value: string }) => <span>{value}</span>,
                Header: () => <div>Type</div>,
                width: '25%',
              },
              {
                accessor: 'title',
                Cell: ({ value }: { value: string }) => <span>{value}</span>,
                Header: () => <div>Title</div>,
                width: '70%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleDelete,
                    handleEdit,
                    id,
                  }
                }: {
                  value: {
                    handleDelete: (id: number) => void;
                    handleEdit: (id: number) => void;
                    id: number;
                  }
                }) => (
                  <FlexContainer gap="2x-small" justifyContent="end">
                    <ButtonIconOnly
                      additionalClassNames=""
                      iconId="id_bin_icon"
                      isIconBorderVisible={false}
                      text="Delete action"
                      onClick={() => handleDelete(id)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      iconId="id_caret_down_icon"
                      isIconBorderVisible={false}
                      text="Edit action"
                      onClick={() => handleEdit(id)}
                    />
                  </FlexContainer>
                ),
              },
            ]}
            data={items.map((item) => ({
              type: getActionTypeName(type, item.__typename),
              title: item.title,
              id: item.id,
              settingsButtonData: {
                id: item.id,
                handleEdit: onEditClick,
                handleDelete: onDeleteClick,
              },
            }))}
          />
        </Spacing>
      )}

      <Spacing>
        <InputFieldWrapper
          id="id_add_action"
          label="Add action"
          labelType="span"
          type="custom"
          isLabelSideBySide
        >
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <ButtonIconOnly iconId="id_plus_icon" text="Add action" onClick={() => onEditClick(undefined)} />
          </FlexContainer>
        </InputFieldWrapper>
      </Spacing>

      <SidebarButtons
        isSaveButtonDisabled={true}
        onCancel={closeActionsSidebar}
        onDelete={onDelete}
      />
    </>
  );
};


export { ActionsOverview };
