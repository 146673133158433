
import { IGoalsTableData, IGoalsTableDataParams } from "./GoalsReportPage.interfaces";


const getGoalsTableData = ({ items }: IGoalsTableDataParams): IGoalsTableData[] => {
  return items.map(({
    createdOn = '',
    email = '',
    firstName = '',
    goalTitle = '',
    id,
    lastName = '',
   }) => {
    return {
      createdOn,
      email,
      firstName,
      goalTitle,
      id,
      lastName,
    };
  });
};

export { getGoalsTableData };
