

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { EXPORT_GROUPS_CONTACTS } from './useGetGroupsContactsReport.graphql';
import {
  IGetGroupsContactsReportQueryGraphQLResponse,
  IGetGroupsContactsReportQueryVariables,
  IHandleGetGroupsContactsReportParams,
  IUseGetGroupsContactsReport,
  IUseGetGroupsContactsReportOptions,
} from './useGetGroupsContactsReport.interfaces';

const useGetGroupsContactsReport = (options?: IUseGetGroupsContactsReportOptions): IUseGetGroupsContactsReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetGroupsContactsReportOptions);

  const [executeGetGroupsContactsReport, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGroupsContactsReportQueryGraphQLResponse,
    IGetGroupsContactsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { groupContacts } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: groupContacts,
        });
      },
      onError,
    },
    query: query ?? EXPORT_GROUPS_CONTACTS,
    token,
  });


  const handleGetGroupsContactsReport = async ({
    fileType,
    projectId,
    ianaTimeZone,
  }: IHandleGetGroupsContactsReportParams) => {
    await executeGetGroupsContactsReport({
      variables: {
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetGroupsContactsReport,
    isLoading,
  };
};

export { useGetGroupsContactsReport };
