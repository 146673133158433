
import { IBookmarkTableData, IBookmarkTableDataParams } from "./BookmarksPage.interfaces";


const getBookmarkTableData = ({ items }: IBookmarkTableDataParams): IBookmarkTableData[] => {
  return items.map(({
    createdOn,
    createdOnUtc,
    groupName,
    id,
    moduleName,
    pageUrl,
    userType,
   }) => {
    return {
      createdOn,
      createdOnUtc,
      groupName,
      id,
      moduleName,
      pageUrl,
      userType,
    };
  });
};

export { getBookmarkTableData };
