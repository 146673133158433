import { ApolloQueryResult } from '@apollo/client';
import { useBonoboLazyQuery } from 'hooks';

import { GET_USERS_QUERY } from './useGetPaginatedUsers.graphql';
import {
  IGetPaginatedUsersQueryGraphQLResponse,
  IGetPaginatedUsersQueryVariables,
  IHandleFetchMorePaginatedUsersParams,
  IHandleGetPaginatedUsersParams,
  IUseGetPaginatedUsers,
  IUseGetPaginatedUsersOptions,
} from './useGetPaginatedUsers.interfaces';

const useGetPaginatedUsers = (options?: IUseGetPaginatedUsersOptions): IUseGetPaginatedUsers => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetPaginatedUsersOptions);

  const [executeGetPaginatedUsers, { fetchMore, loading: isLoading }] = useBonoboLazyQuery<
  IGetPaginatedUsersQueryGraphQLResponse,
  IGetPaginatedUsersQueryVariables
  >({
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          user: { getUsers: usersConnection },
        } = data;

        onCompleted({
          usersConnection,
        });
      },
      onError,
    },
    query: query ?? GET_USERS_QUERY,
    token,
  });

  const handleFetchMorePaginatedUsers = ({
    after,
    filter,
    first,
  }: IHandleFetchMorePaginatedUsersParams): Promise<ApolloQueryResult<IGetPaginatedUsersQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          user: { getUsers: newlyFetchedUsersConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedUsersConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          user: { getUsers: previouslyFetchedUsersConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedUsersConnection;

        return {
          ...previousQueryResult,
          user: {
            ...previouslyFetchedUsersConnection,
            getUsers: {
              ...newlyFetchedUsersConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        first,
      },
    });
  };

  const handleGetPaginatedUsers = ({ after, filter, first, communityId }: IHandleGetPaginatedUsersParams) => {
    void executeGetPaginatedUsers({
      variables: {
        after,
        filter,
        first,
        communityId,
      },
    });
  };

  return {
    handleFetchMorePaginatedUsers,
    handleGetPaginatedUsers,
    isLoading,
  };
};

export { useGetPaginatedUsers };
