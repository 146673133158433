import { Column } from 'react-table';

import { IGroupsContactsTableData } from './GroupsContactsPage.interfaces';


const GROUPS_CONTACTS_COLUMNS: ReadonlyArray<Column<IGroupsContactsTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
    width: '10%',
  },
  {
    accessor: 'displayName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Display name</div>,
    width: '10%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
  },
  {
    accessor: 'groupAddressLine1',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group address line 1</span>,
  },
  {
    accessor: 'groupAddressLine2',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group address line 2</span>,
  },
  {
    accessor: 'groupAddressState',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group address state</span>,
  },
  {
    accessor: 'groupAddressCity',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group address city</span>,
  },
  {
    accessor: 'groupAddressCountry',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group address country</span>,
  },
  {
    accessor: 'detailUrl',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Detail url</span>,
  },
];

export { GROUPS_CONTACTS_COLUMNS };
