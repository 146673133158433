import { FormFieldProps } from "@netfront/ui-library";

const getPageDefaultValues = (): FormFieldProps => {

  return {
    title: 'Page',
    seoDescription: '',
    url: '',
    presignedUrl: '',
    tags: [],
    status: 'PUBLISHED',
    isSensitive: false,
  }
};


export { getPageDefaultValues };
