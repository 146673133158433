import { gql } from '@apollo/client';

const EXPORT_FEEDBACK = gql`
  query ExportFeedback(
    $ianaTimeZone: String!
    $projectId: String
    $url: String
    $dateFrom: DateTime
    $dateTo: DateTime
    $fileType: FileType
  ) {
    report {
      exportFeedback(
        ianaTimeZone: $ianaTimeZone
        projectId: $projectId
        url: $url
        dateFrom: $dateFrom
        dateTo: $dateTo
        fileType: $fileType
      ) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_FEEDBACK };
