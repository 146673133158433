import { IGetLanguageFilterTableDataParams, ILanguageFilterTableData } from "./LanguageFilterPage.interfaces";


const getLanguageFilterTableData = ({ languageFilters, onSettingsButtonClick }: IGetLanguageFilterTableDataParams): ILanguageFilterTableData[] => {
  return languageFilters.map(({ content, id, isEmbargoingContent, isNegative, type, projectId }): ILanguageFilterTableData => {

    return {
      content,
      isEmbargoingContent,
      isNegative,
      type,
      projectId,
      id,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getLanguageFilterTableData };
