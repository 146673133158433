import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentGroup } from '@netfront/ekardo-content-library';
import { FormFieldProps } from '@netfront/ui-library';
import { useCreateForm, useCreateSection, useCreateSnippet, useToast, useUpdateFormStatus } from 'hooks';



const useCreateFormSnippetWrapper = () => {
  const variablesRef = useRef<{value: FormFieldProps | undefined}>({value: undefined });
  const contentGroupRef = useRef<{value: IContentGroup | undefined}>({value: undefined });
  const dynamicCallBackRef = useRef<((returnedContainerId?: number) => void) | null>(null);

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleUpdateFormStatus, isLoading: isUpdateFormLoadingStatus = false } = useUpdateFormStatus({
    onError: handleGetError,
  });

  const { handleCreateSnippet, isLoading: isCreateSnippetLoading = false } = useCreateSnippet({
    onError: handleGetError,
  });

  const { handleCreateSection, isLoading: isCreateSectionLoading = false } = useCreateSection({
    onCompleted: ({ section : { sectionContainers }}) => {
      const [firstContainer] = sectionContainers;

      const { id } = firstContainer;
      if (dynamicCallBackRef.current) {
        dynamicCallBackRef.current(id);
      }

      variablesRef.current.value = undefined;
      dynamicCallBackRef.current = null;

    },
    onError: handleGetError,
  });

  const { handleCreateForm, isLoading: isCreateFormLoading = false } = useCreateForm({
    onCompleted: ({ contentGroup }) => {
      const { contentPages } = contentGroup;

      const { id: contentSnippetFormId } = contentGroup;

      const [firstPage] = contentPages;
      const { id } = firstPage;
      contentGroupRef.current.value = contentGroup;
      handleToastSuccess({ message: 'Form successfully created'});

      const { sort, isVisible = false, containerId } = variablesRef.current.value ?? {};

      handleCreateSnippet({
        baseSnippet: {
          entityTitle: 'Form snippet',
          sort: Number(sort),
          visible: isVisible,
          containerId: Number(containerId),
        },
        formSnippet: {
          contentSnippetFormId,
        },
      });

      handleCreateSection({
        contentSection: {
          contentLayoutId: 1,
          sort: 0,
          containers: [ { sort: 0 }],
          contentPageId: id,
          visible: isVisible,
          title: 'Section'
        }
      });

      handleUpdateFormStatus({
        contentGroupId: contentSnippetFormId,
        status: 'PUBLISHED',
      })
    },
    onError: handleGetError,
  });

  const handleCreateFormSnippetWrapper = ({
    callBack,
    isCreateForm = true,
    item,
  }: {
    callBack: (returnedContainerId?: number) => void;
    isCreateForm?: boolean;
    item: FormFieldProps;
  }) => {
    if (!isCreateForm) {
      callBack();
      return;
    };
    const { projectId } = item;

    dynamicCallBackRef.current = callBack;
    variablesRef.current.value = item;

    handleCreateForm({
      projectId: projectId,
      title: 'Form created in content builder',
      description: 'Form created in content builder',
      formType: 'LIST',
    });
  };

  return {
    isLoading: isUpdateFormLoadingStatus  || isCreateSectionLoading || isCreateFormLoading || isCreateSnippetLoading,
    handleCreateFormSnippetWrapper,
  };
};
export { useCreateFormSnippetWrapper };
