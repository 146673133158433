import { IGlossaryListItem } from "interfaces";

import { IGlossaryTableData, IGlossaryTableDataParams } from "./GlossaryPage.interfaces";


const getGlossaryTableData = ({ glossaryItems, onSettingsButtonClick }: IGlossaryTableDataParams): IGlossaryTableData[] => {
  return glossaryItems.map(({ description, id, title }) => {
    return {
      title,
      id,
      description,
      settingsButtonData: {
        item: {
          description,
          id,
          title
        } as IGlossaryListItem,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getGlossaryTableData };
