const MAX_POST_DESCRIPTION_LENGTH = 500;

const MAX_POST_IMAGE_FILE_SIZE = 500;

export const POSTS_DROPZONE_FILES_MIME_TYPES = {
  'application/msword': {
    icon: 'id_docx_icon',
  },
  'application/pdf': {
    icon: 'id_pdf_icon',
  },
  'application/vnd.ms-excel': {
    icon: 'id_xlsx_icon',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    icon: 'id_xlsx_icon',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    icon: 'id_docx_icon',
  },
};

const postSidebarGeneralViewConstants = Object.freeze({
  maxPostDescriptionLength: MAX_POST_DESCRIPTION_LENGTH,
  maxPostImageFileSize: MAX_POST_IMAGE_FILE_SIZE,
  postsDropzoneFilesMimeTypes: POSTS_DROPZONE_FILES_MIME_TYPES,
});

export { postSidebarGeneralViewConstants };
