import { gql } from '@apollo/client';

const EXPORT_NOTIFICATIONS = gql`
  query ExportNotifications($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    report {
      exportNotifications(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_NOTIFICATIONS };
