import { useContext, useEffect, useState } from 'react';

import { ContentBuilderNavigation, Dialog } from '@netfront/ui-library';
import { ActionsSidebarView , SnippetSidebarView, SectionSidebarView, ContainerSidebarView, ContentBuilderBulkActionDialog, SectionGroupSidebarView, PageSidebarView } from 'components';
import { ContentBuilderContext } from 'context';
import { useToast } from 'hooks';
import { IEntityType } from 'interfaces';
import { useRouter } from 'next/router';


import { ContentBuilderTemplatePageProps } from './ContentBuilderTemplatePage.types';

import { PageLayout } from '../../PageLayout';

const ContentBuilderTemplatePage = ({
  additionalClassNames,
  description,
  pageTitle,
  children,
  activePageId,
  dashboardLink,
  settingItems,
}: ContentBuilderTemplatePageProps) => {

  const { push } = useRouter();
  const { handleToastError } = useToast();
  const {
    pageOptions,
    navigationItems,
    pageUrl,
    openCreateEntitySidebar,
    deleteEntityDetails,
    closeDeleteEntityDialog,
    handleDeleteEntity,
    bulkActionDetails,
    baseUrl,
    pageId,
    openPageSidebar,
    isFormsMode = false,
  } = useContext(ContentBuilderContext);

  const { entityId, type: entityType, isOpen = false } = deleteEntityDetails ?? {};
  const { isOpen: isBulkActionOpen = false } = bulkActionDetails ?? {};

  const [contentBuilderPageUrl, setContentBuilderPageUrl] = useState('');

  const handleSelectPage = (value: string) => {
    if (!(value && baseUrl)) return;
    push(`${contentBuilderPageUrl}/${value}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };

  useEffect(() => {
    if (!baseUrl) return;

    setContentBuilderPageUrl(baseUrl);

  }, [baseUrl]);

  return (
    <PageLayout
      additionalClassNames={additionalClassNames}
      meta={{ description: description }}
      title={pageTitle}
      hasPrivateLayout
    >
      <ContentBuilderNavigation
        activePageId={activePageId}
        dashboardLink={dashboardLink}
        navigationItems={navigationItems}
        pageDropdownOptions={!isFormsMode ? [
          {
            id: 'id_edit_page',
            iconId: 'id_settings_icon',
            label: 'Edit',
            isHidden: false,
            onClick: () => openPageSidebar(Number(pageId)),
          },
          {
            id: 'id_create_page',
            iconId: 'id_plus_icon',
            label: 'Create page',
            isHidden: false,
            onClick: () => openPageSidebar(),
          },
        ]: undefined}
        selectedValue={String(pageUrl)}
        selectOptions={pageOptions}
        settingsDropdownOptions={settingItems}
        showExpandedHighlight={false}
        hasBulkActions
        hasHighlightOnHoverSiblingChild
        isFullyExpandedOnLoad
        onAddClick={(selectedId: string | number, type: string) => openCreateEntitySidebar(Number(selectedId), type as IEntityType)}
        onSelectChange={({target : { value }}: { target: { value: string }}) => handleSelectPage(value)}
      >
        <div style={{width: '100%'}}>{children}</div>
      </ContentBuilderNavigation>

      <ActionsSidebarView />
      <SnippetSidebarView />
      <SectionSidebarView />
      <ContainerSidebarView />
      <SectionGroupSidebarView />
      <PageSidebarView />
      { entityType && entityId && (
        <Dialog
          isOpen={isOpen}
          title={`Delete ${entityType  === 'targetSnippet' ? 'snippet' : entityType}?`}
          isNarrow
          onCancel={closeDeleteEntityDialog}
          onClose={closeDeleteEntityDialog}
          onConfirm={() => handleDeleteEntity({ id: entityId, type: entityType })}
        />
      )}
      { isBulkActionOpen && (
        <ContentBuilderBulkActionDialog
          isOpen={isBulkActionOpen}
        />
      )}

    </PageLayout>
  );
};

export { ContentBuilderTemplatePage };
