import { IKeyListItem, IPillVariant } from '@netfront/ui-library';
import format from 'date-fns/format';
import { ProgressTrackerUserFlowStatusType } from 'interfaces';
import { generateRandomId } from 'utils';

import { IProgressTrackingTableDataParams, IProgressTrackingTableData } from './ProgressTrackingPage.interfaces';

const getProgressTrackingTableData = ({ progressTrackings, onViewUserClick }: IProgressTrackingTableDataParams): IProgressTrackingTableData[] => {
  return progressTrackings.map(({
    dateRegistered,
    email,
    firstName,
    lastName,
    group,
    id,
    userFlowProgress,
    isProjectAdmin,
    permission,
  }): IProgressTrackingTableData => {
    const isAdmin = isProjectAdmin || permission === 'MANAGE_USERS';

    return {
      actions: {
        email,
        id,
        onClick: onViewUserClick,
      },
      avatarData: {
        fullName: `${firstName} ${lastName}`.trim(),
        isAdmin,
        isProjectAdmin,
        permission,
        id: String(id),
      },
      id,
      dateRegistered: format(new Date(dateRegistered), 'dd/MM/yyyy'),
      group,
      userFlowProgress
    };
  });
};

const getStatusByPercentageValue = (percentage: number): ProgressTrackerUserFlowStatusType => {
  if (percentage === 100) return 'COMPLETED';
  if (percentage > 0 && percentage < 100) return 'AVAILABLE';
  return 'AVAILABLE';
}


const getFriendlyStatus = (status: ProgressTrackerUserFlowStatusType): string => {
  const statusMap: Record<ProgressTrackerUserFlowStatusType, string> = {
    AVAILABLE: 'Not started',
    COMPLETED: 'Completed',
    IN_PROGRESS: 'In progress',
    LOCKED: 'Locked',
    OVERDUE: 'Overdue'
  };

  return statusMap[status];
}

const getVariantByDates = ({ started, completed }: {completed?: string; started?: string }): IPillVariant => {
  let status: ProgressTrackerUserFlowStatusType = 'AVAILABLE';
  if (!started) status = 'AVAILABLE';
  if (started && !completed) status = 'IN_PROGRESS';
  if (completed) status = 'COMPLETED';

  return getUserFlowStatusVariant(status);
}


const getUserFlowStatusVariant = (status: ProgressTrackerUserFlowStatusType): IPillVariant => {

  const statusMap: Record<ProgressTrackerUserFlowStatusType, IPillVariant> = {
    AVAILABLE: 'purple',
    COMPLETED: 'green',
    IN_PROGRESS: 'blue',
    LOCKED: 'default',
    OVERDUE: 'red',
  }

  return statusMap[status];

}

const getProgressTrackingKeyItems = (): IKeyListItem[] => {
  return [
    {
      id: generateRandomId(),
      title: 'Open',
      variant: 'purple',
    },
    {
      id: generateRandomId(),
      title: 'Overdue',
      variant: 'red',
    },
    {
      id: generateRandomId(),
      title: 'In progress',
      variant: 'blue',
    },
    {
      id: generateRandomId(),
      title: 'Complete',
      variant: 'green',
    },
    {
      id: generateRandomId(),
      title: 'Locked',
      variant: 'default',
    },
  ]
};


export { getProgressTrackingTableData, getProgressTrackingKeyItems, getUserFlowStatusVariant, getFriendlyStatus, getStatusByPercentageValue, getVariantByDates };
