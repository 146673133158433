import { gql } from '@apollo/client';

const GET_GROUP_CODES = gql`
  query($projectId: String!) {
    group {
      searchGroups(projectId: $projectId, status: [ACTIVE]) {
        id
        name
        identifier
      }
    }
  }
`;

export { GET_GROUP_CODES };
