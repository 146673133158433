import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_PAGE } from './useCreatePage.graphql';
import {
  ICreatePageMutationGraphQLResponse,
  ICreatePageMutationVariables,
  IUseCreatePageOptions,
  IUseCreatePage,
  ICreatePageMutationParams,
} from './useCreatePage.interfaces';

const useCreatePage = (options?: IUseCreatePageOptions): IUseCreatePage => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreatePageOptions);

  const [executeCreatePage, { loading: isLoading }] = useEkardoMutation<ICreatePageMutationGraphQLResponse, ICreatePageMutationVariables>({
    mutation: mutation ?? CREATE_PAGE,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { createContentPage: contentPage },
        } = data;

        onCompleted({
          contentPage,
        });
      },
      onError,
    },
    token,
  });

  const handleCreatePage = async ({
    assetId,
    contentGroupId,
    sensitiveContent,
    seoDescription,
    seoTitle,
    tags,
    title,
    url,
  }: ICreatePageMutationParams) => {
    await executeCreatePage({
      variables: {
        contentPage: {
          assetId,
          contentGroupId,
          sensitiveContent,
          seoDescription,
          seoTitle,
          tags,
          title,
          url,
        }
      }
    });
  };

  return {
    handleCreatePage,
    isLoading,
  };
};

export { useCreatePage };
