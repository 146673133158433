import { gql } from '@apollo/client';

export const FRAGMENT_CALENDAR = gql`
  fragment calendarConf on Calendar {
    description
    id
    maxDate
    minDate
    defaultDate
    messages {
      correct
      incomplete
      incorrect
    }
    __typename
  }
`;
