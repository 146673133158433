

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { EXPORT_PAGE_LOADED } from './useGetPagesLoadedReport.graphql';
import {
  IGetPagesLoadedReportQueryGraphQLResponse,
  IGetPagesLoadedReportQueryVariables,
  IHandleGetPagesLoadedReportParams,
  IUseGetPagesLoadedReport,
  IUseGetPagesLoadedReportOptions,
} from './useGetPagesLoadedReport.interfaces';

const useGetPagesLoadedReport = (options?: IUseGetPagesLoadedReportOptions): IUseGetPagesLoadedReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPagesLoadedReportOptions);

  const [executeGetPagesLoadedReport, { loading: isLoading }] = useEkardoLazyQuery<
    IGetPagesLoadedReportQueryGraphQLResponse,
    IGetPagesLoadedReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportPageLoaded } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportPageLoaded,
        });
      },
      onError,
    },
    query: query ?? EXPORT_PAGE_LOADED,
    token,
  });


  const handleGetPagesLoadedReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetPagesLoadedReportParams) => {
    await executeGetPagesLoadedReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetPagesLoadedReport,
    isLoading,
  };
};

export { useGetPagesLoadedReport };
