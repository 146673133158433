import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_GLOSSARY_ITEM_MUTATION } from './useCreateGlossaryItem.graphql';
import {
  ICreateGlossaryItemMutationGraphQLResponse,
  ICreateGlossaryItemMutationVariables,
  IHandleCreateGlossaryItemParams,
  IUseCreateGlossaryItemOptions,
  IUseCreateGlossaryItem,
} from './useCreateGlossaryItem.interfaces';

const useCreateGlossaryItem = (options?: IUseCreateGlossaryItemOptions): IUseCreateGlossaryItem => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateGlossaryItemOptions);

  const [executeCreateGlossaryItem, { loading: isLoading }] = useEkardoMutation<
  ICreateGlossaryItemMutationGraphQLResponse,
  ICreateGlossaryItemMutationVariables
  >({
    mutation: mutation ?? CREATE_GLOSSARY_ITEM_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          add
        } = data;

        void onCompleted(add);
      },
      onError,
    },
    token,
  });

  const handleCreateGlossaryItem = ({
    projectId,
    description,
    title,
  }: IHandleCreateGlossaryItemParams) => {
    void executeCreateGlossaryItem({
      variables: {
        request: {
          projectId,
          description,
          title
        },
      },
    });
  };

  return {
    handleCreateGlossaryItem,
    isLoading,
  };
};

export { useCreateGlossaryItem };
