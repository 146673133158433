import { gql } from '@apollo/client';

const GET_ACTIVE_GROUPS_QUERY = gql`
  query ActiveGroup($projectId: String!, $start: DateTime!, $end: DateTime!) {
    insights {
      activeGroups(
        projectId: $projectId
        start: $start
        end: $end
      ) {
        count
        key
      }
    }
  }
`;

export { GET_ACTIVE_GROUPS_QUERY };
