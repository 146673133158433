import { gql } from '@apollo/client';

const GET_USERS_QUERY = gql`
  query GetUsers($after: String, $filter: String, $first: Int, $postedFrom: DateTime, $postedTo: DateTime, $communityId: Int) {
    user {
      getUsers(after: $after, filter: $filter, first: $first, postedFrom: $postedFrom, postedTo: $postedTo, communityId: $communityId) {
        edges {
          cursor
          node {
            id
            ... on UserGraphType {
              id
              communityConnections {
                edges {
                  node {
                    community {
                      title
                    }
                  }
                }
              }
              email
              firstName
              lastName
              displayedName
              postCount
              status
              posts {
                assets {
                  assetId
                  contentType
                  fileName
                  s3Key
                }
                author {
                  id
                  displayedName
                  email
                  firstName
                  lastName
                  postCount
                }
                authorId
                commentCount
                comments {
                  edges {
                    node {
                      author {
                        displayedName
                        id
                      }
                      authorId
                      message
                      postId
                    }
                  }
                }
                createdDate
                createdDateTime
                id
                message
                tags
                topic {
                  id
                  title
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_USERS_QUERY };
