import { useBonoboMutation } from '../useBonoboMutation';

import { DELETE_LANGUAGE_FILTER } from './useDeleteLanguageFilter.graphql';
import {
  IDeleteLanguageFilterMutationGraphQLResponse,
  IDeleteLanguageFilterMutationVariables,
  IHandleDeleteLanguageFilterParams,
  IUseDeleteLanguageFilterOptions,
  IUseDeleteLanguageFilter,
} from './useDeleteLanguageFilter.interfaces';

const useDeleteLanguageFilter = (options?: IUseDeleteLanguageFilterOptions): IUseDeleteLanguageFilter => {
  const { mutation, onCompleted, onError, token, } = options ?? ({} as IUseDeleteLanguageFilterOptions);

  const [executeDeleteLanguageFilter, { loading: isLoading }] = useBonoboMutation<IDeleteLanguageFilterMutationGraphQLResponse, IDeleteLanguageFilterMutationVariables>({
    mutation: mutation ?? DELETE_LANGUAGE_FILTER,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          languageFilter: { delete: isCompleted },
        } = data;

        onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleDeleteLanguageFilter = async ({ id }: IHandleDeleteLanguageFilterParams) => {
    await executeDeleteLanguageFilter({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteLanguageFilter,
    isLoading,
  };
};

export { useDeleteLanguageFilter };
