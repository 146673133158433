import { gql } from '@apollo/client';

const UPDATE_DISCOUNT_MUTATION = gql`
  mutation UpdateDiscount(
    $id: Int!
    $code: String
    $description: String
    $maxUsage: Int
    $percentage: Decimal
    $period: DiscountPeriodInput
  ) {
    discounts {
      update(request: { id: $id, code: $code, description: $description, maxUsage: $maxUsage, percentage: $percentage, period: $period }) {
        id
        code
        description
        expiresAtUtc
        status
        startsAtUtc
        percentage
        maxUsage
      }
    }
  }
`;

export { UPDATE_DISCOUNT_MUTATION };
