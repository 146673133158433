import { gql } from '@apollo/client';

const BULK_REMOVE_MEMBERS_FROM_COMMUNITY = gql`
  mutation BulkRemoveUsersFromCommunity($communityId: Int!, $userIds: [Int]!) {
    communityConnection {
      removeUsersFromCommunity(communityId: $communityId, userIds: $userIds)
    }
  }
`;

export { BULK_REMOVE_MEMBERS_FROM_COMMUNITY };
