import { gql } from '@apollo/client';

import { FRAGMENT_RESPONSE_SET } from './responseSetFragment';

export const FRAGMENT_RADIO = gql`
  fragment radioConf on Radio {
    allowUserGeneratedResponses
    description
    id
    messages {
      correct
      incomplete
      incorrect
    }
    responseSetID
    responseSet {
      ...ResponseSetFragment
    }
    __typename
  }
  ${FRAGMENT_RESPONSE_SET}
`;
