import { FormEvent, useState } from 'react';

import { ButtonIconOnly, FlexContainer, Icon, Label, SidebarButtons, Spacing, Spinner, Table } from '@netfront/ui-library';
import { Uploader } from 'components';
import { useToast, useBulkUpsertLanguageFilter } from 'hooks';
import { DBLanguageFilterType } from 'interfaces';
import { read as readXLSX, utils as utilsXLSX } from 'xlsx';


import { LanguageFilterSidebarBulkUploadViewProps } from './LanguageFilterSidebarBulkUploadView.interfaces';

const LanguageFilterSidebarBulkUploadView = ({ onClose, onUpdate, projectId }: LanguageFilterSidebarBulkUploadViewProps) => {
  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();

  const [newLanguageFilterItems, setNewLanguageFilterItems] = useState<{
    content: string;
    id: string;
    isEmbargoingContent: boolean;
    isNegative: boolean
    type: DBLanguageFilterType;
  }[]>([]);


  const { handleBulkUpsertLanguageFilter, isLoading: isBulkUpsertLanguageFiltersLoading = false } = useBulkUpsertLanguageFilter({
    onCompleted: () => {
      handleToastSuccess({
        message: `Language filters successfully created`,
      });
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleCreateBulkInvitations = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newLanguageFilterItems.find((r) => r.content === '')) {
      handleToastCustomError({ message: 'All the excel fields must be mapped-out' });
      return;
    }

    const itemsWithoutIds = newLanguageFilterItems.map(({content, type, isEmbargoingContent, isNegative }) => {
      return {
        content,
        type,
        isEmbargoingContent,
        isNegative,
        projectId: String(projectId),
      };
    });

    handleBulkUpsertLanguageFilter({
      items: itemsWithoutIds,
    });
  };


  const handleReadExcel = (input: File) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const { target } = event;
      const workbook = readXLSX(target?.result, {
        type: 'binary',
      });

      const [sheet] = Object.values(workbook.Sheets);
      const contentAsObject = utilsXLSX.sheet_to_json(sheet) as [];
      const formattedItems = contentAsObject.map(({Content: content, Type: type, IsEmbargoingContent: isEmbargoingContent, IsNegative: isNegative }) => {
        return {
          content,
          type: type as DBLanguageFilterType,
          isEmbargoingContent,
          isNegative,
          id: (Math.random() + 1).toString(36).substring(7)
        };
      });

      setNewLanguageFilterItems(formattedItems);
    };
    reader.readAsBinaryString(input);
  }

  const handleClearItems = () => {
    setNewLanguageFilterItems([]);
  };

  const handleFileDrop = (uploadedFile: File | undefined) => {
    if (uploadedFile) handleReadExcel(uploadedFile);
  };

  const isLoading = isBulkUpsertLanguageFiltersLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />

      <form
        onSubmit={handleCreateBulkInvitations}
      >


        <Spacing>
          {Boolean(newLanguageFilterItems.length) && (
            <FlexContainer>
              <Label fontWeight="semi-bold" forId="" labelText="Uploaded items" labelType={'span'}/>
              <ButtonIconOnly
                iconId="id_close_icon"
                text="close sidebar"
                onClick={handleClearItems}
              />
            </FlexContainer>
          )}
          {Boolean(newLanguageFilterItems.length) && (
            <>
              <Table
                columns={[
                  {
                    accessor: 'content',
                    Cell: ({ value }: { value: string }) => <div>{value}</div>,
                    Header: () => <div>Content</div>,
                  },
                  {
                    accessor: 'type',
                    Cell: ({ value }: { value: string }) => <div>{value}</div>,
                    Header: <div>Type</div>,
                  },
                  {
                    accessor: 'isEmbargoingContent',
                    Cell: ({ value }: { value: boolean }) => <div>{value ? 'True': 'False'}</div>,
                    Header: <div>Embargoing content</div>,
                  },
                  {
                    accessor: 'isNegative',
                    Cell: ({ value }: { value: boolean }) => <div>{value ? 'True': 'False'}</div>,
                    Header: <div>Is negative</div>,
                  },
                ]}
                data={newLanguageFilterItems}
              />
            </>
          )}
        </Spacing>

        <Spacing size="2x-large">
          <Label fontWeight="semi-bold" forId="" labelText="Download template" labelType={'span'} tooltipText="Download our predefined excel template to add your language filter items to"/>
          <a
            href="https://ekardo.s3.ap-southeast-2.amazonaws.com/file-templates/language_filter_template.xlsx"
            id="download-template"
            download
          >
            <Icon className="c-icon--large" id="id_xlsx_icon" />
          </a>
        </Spacing>

        <Uploader
          assetType={'document'}
          labelText={'Upload region'}
          tooltipText={'Upload multiple language filters at once by using an excel document'}
          onDelete={handleClearItems}
          onDrop={handleFileDrop}
        />

        <SidebarButtons
          buttonSize="xs"
          isSaveButtonDisabled={Boolean(!newLanguageFilterItems.length)}
          submitButtonText="Save"
          onCancel={onClose}
          onSaveButtonType="submit"
        />

      </form>
    </>
  );
};

export { LanguageFilterSidebarBulkUploadView };
