import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_MULTI_RESPONSE_TEXT_CONFIGURATION } from './useCreateMultiResponseTextQuestionConfig.graphql';
import {
  ICreateMultiResponseTextQuestionConfigMutationGraphQLResponse,
  ICreateMultiResponseTextQuestionConfigMutationVariables,
  IHandleCreateMultiResponseTextQuestionConfigParams,
  IUseCreateMultiResponseTextQuestionConfigOptions,
  IUseCreateMultiResponseTextQuestionConfig,
} from './useCreateMultiResponseTextQuestionConfig.interfaces';

const useCreateMultiResponseTextQuestionConfig = (options?: IUseCreateMultiResponseTextQuestionConfigOptions): IUseCreateMultiResponseTextQuestionConfig => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateMultiResponseTextQuestionConfigOptions);

  const [executeCreateMultiResponseTextQuestionConfig, { loading: isLoading }] = useEkardoMutation<ICreateMultiResponseTextQuestionConfigMutationGraphQLResponse, ICreateMultiResponseTextQuestionConfigMutationVariables>({
    mutation: mutation ?? CREATE_MULTI_RESPONSE_TEXT_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { createQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateMultiResponseTextQuestionConfig = async ({
    description,
    maxResponse,
    minResponse,
    projectId,
  }: IHandleCreateMultiResponseTextQuestionConfigParams) => {
    await executeCreateMultiResponseTextQuestionConfig({
      variables: {
        description,
        maxResponse,
        minResponse,
        projectId,
      }
    });
  };

  return {
    handleCreateMultiResponseTextQuestionConfig,
    isLoading,
  };
};

export { useCreateMultiResponseTextQuestionConfig };
