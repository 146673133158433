import { ITopicsTableDataParams, ITopicsTableData } from './TopicsPage.interfaces';

const getTopicsTableData = ({ topics, onSettingsButtonClick }: ITopicsTableDataParams): ITopicsTableData[] => {
  return topics.map(({ id, title, status }): ITopicsTableData => {
    return {
      id,
      title,
      status,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getTopicsTableData };
