import React, { useState, useEffect } from 'react';

import { ISummaryFeedbackConfiguration } from '@netfront/ekardo-content-library';
import { Dialog, SidebarDialog, Spinner } from '@netfront/ui-library';

import { FeedbackOverview, UpsertFeedback } from './Components';
import { UpsertSummarySnippetFeedbackTabProps } from './UpsertSummarySnippetFeedbackTab.interfaces';

import { useDeleteSnippetSummaryFeedbackConfigItem, useGetSnippetSummaryFeedbackConfig, useToast } from '../../../../../../hooks';

const UpsertSummarySnippetFeedbackTab = ({ snippet, onClose, onDelete, watch, initialQuestionId }: UpsertSummarySnippetFeedbackTabProps) => {
  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();

  const [isUpsertMode, setIsUpsertMode] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState<number>();
  const [deleteFeedbackId, setDeleteFeedbackId] = useState<number>();
  const [items, setItems] = useState<ISummaryFeedbackConfiguration[]>([]);
  const [feedbackConfig, setFeedbackConfig] = useState<ISummaryFeedbackConfiguration>();

  const { handleGetSnippetSummaryFeedbackConfig, isLoading: isGetSnippetSummaryFeedbackLoading = false } =
    useGetSnippetSummaryFeedbackConfig({
      onCompleted: ({ config }) => {
        // temporarily set this to an array until we can add multiple feedback questions to a summary
        if (config) setItems([config]);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const { handleDeleteSnippetSummaryFeedbackConfigItem, isLoading: isDeleteSnippetSummaryFeedbackItemLoading = false } =
    useDeleteSnippetSummaryFeedbackConfigItem({
      onCompleted: ({ isCompleted }) => {
        if (isCompleted) {
          void handleGetSnippetSummaryFeedbackConfig({
            contentSnippetSummaryId: snippet.id,
          });
          setIsUpsertMode(false);
          handleCloseFeedback();
          handleToastSuccess({
            message: `Summary feedback successfully deleted`,
          });
        } else {
          handleToastCustomError({
            message: `Whoops something went wrong`,
          });
        }
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const handleUpsertFeedback = (id?: number) => {
    setSelectedFeedbackId(id);
    setIsUpsertMode(true);
  };

  const handleCloseFeedback = () => {
    setSelectedFeedbackId(undefined);
    setIsUpsertMode(false);
  };

  const deleteFeedbackItem = (id: number) => {
    void handleDeleteSnippetSummaryFeedbackConfigItem({
      contentSnippetSummaryId: id,
    });
  };

  const cancelDeleteFeedbackItem = () => {
    setIsDeleteDialogOpen(false);
    setDeleteFeedbackId(undefined);
  }

  const onSaveFeedback = () => {
    void handleGetSnippetSummaryFeedbackConfig({
      contentSnippetSummaryId: snippet.id,
    });
    handleCloseFeedback();
  };

  useEffect(() => {
    setFeedbackConfig(items.find((item) => item.contentSnippetSummaryId === selectedFeedbackId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeedbackId]);

  useEffect(() => {
    void handleGetSnippetSummaryFeedbackConfig({
      contentSnippetSummaryId: snippet.id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  const isLoading = isDeleteSnippetSummaryFeedbackItemLoading || isGetSnippetSummaryFeedbackLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />

        <FeedbackOverview
          feedbackItems={items}
          initialQuestionId={initialQuestionId}
          watch={watch}
          onClose={onClose}
          onDelete={onDelete}
          onDeleteClick={(id: number) => {
            setIsDeleteDialogOpen(true);
            setDeleteFeedbackId(id);
          }}
          onEditClick={handleUpsertFeedback}
        />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete feedback configuration?"
        isNarrow
        onCancel={cancelDeleteFeedbackItem}
        onClose={cancelDeleteFeedbackItem}
        onConfirm={() => deleteFeedbackItem(Number(deleteFeedbackId))}
      />

      <SidebarDialog
        isOpen={isUpsertMode}
        onClose={handleCloseFeedback}
      >
        <UpsertFeedback
          feedbackConfig={feedbackConfig}
          snippetId={snippet.id}
          onCancel={handleCloseFeedback}
          onDelete={(id: number) => {
            setIsDeleteDialogOpen(true);
            setDeleteFeedbackId(id);
          }}
          onSave={onSaveFeedback}
        />
      </SidebarDialog>
    </>
  );
};

export { UpsertSummarySnippetFeedbackTab };
