import { CountriesType } from "@netfront/common-library";
import { IDirectory } from "@netfront/ekardo-content-library";
import { IDirectoryOperatingHours } from "interfaces";

import { IEditableDirectory } from "./LibraryDirectorySidebarView.interfaces";

export const getFormattedDirectory = (directory?: IDirectory): IEditableDirectory => {
  return !directory
    ? {
      assetId: undefined,
      description: '',
      directoryTypeKey: '',
      directoryTypeId: undefined,
      phoneNumber: '',
      email: '',
      title: '',
      subTitle: '',
      url: '',
      files: [],
      id: undefined,
      status: '',
      city: '',
      country: null as unknown as CountriesType,
      line1: '',
      longitude: '',
      latitude: '',
      operatingHours: [],
      postcode: '',
      state: '',
      suburb: '',
      linkedTags: []
    } as IEditableDirectory
    : {
      assetId: directory.asset?.assetId,
      description: String(directory.description ?? ''),
      directoryTypeKey: String(directory.directoryType?.key ?? ''),
      directoryTypeId: directory.directoryType?.id,
      phoneNumber: String(directory.phoneNumber ?? ''),
      email: String(directory.email ?? ''),
      id: directory.id,
      title: String(directory.title),
      subTitle: String(directory.subTitle ?? ''),
      url: String(directory.url ?? ''),
      linkedTags: directory.linkedTags ?? [],
      status: String(directory.status),
      city: String(directory.address?.city ?? ''),
      files: directory.files.map((file) => file.asset),
      country: directory.address?.country as CountriesType,
      line1: String(directory.address?.line1 ?? ''),
      longitude: String(directory.address?.longitude ?? ''),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      operatingHours: directory.operatingHours?.map((operatingHoursItem: string) => JSON.parse(operatingHoursItem || '{}') as IDirectoryOperatingHours) ?? [],
      latitude: String(directory.address?.latitude ?? ''),
      postcode: String(directory.address?.postcode ?? ''),
      state: String(directory.address?.state ?? ''),
      suburb: String(directory.address?.suburb ?? ''),
    };
}
