import { Spinner } from '@netfront/ui-library';

import { PageLayout } from '../PageLayout';
import { PageTitleBanner } from '../PageTitleBanner';

import { PublicPageLayoutProps } from './PublicPageLayout.types';

const PublicPageLayout: React.FC<PublicPageLayoutProps> = ({ afterTitle, children, isPreloaderVisible = false, title }) => {
  return (
    <PageLayout title={title}>
      <PageTitleBanner afterTitle={afterTitle} title={title} />
      <Spinner isLoading={isPreloaderVisible} />
      <div className="c-container py-12">{children}</div>
    </PageLayout>
  );
};

export { PublicPageLayout };
