import { useBonoboMutation } from '../useBonoboMutation';

import { CREATE_POST_ASSET_MUTATION } from './useCreatePostAsset.graphql';
import {
  ICreatePostAssetMutationGraphQLResponse,
  ICreatePostAssetMutationVariables,
  IHandleCreatePostAssetParams,
  IUseCreatePostAssetOptions,
  IUseCreatePostAsset,
} from './useCreatePostAsset.interfaces';

const useCreatePostAsset = (options?: IUseCreatePostAssetOptions): IUseCreatePostAsset => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseCreatePostAssetOptions);

  const [executeCreatePostAsset, { loading: isLoading }] = useBonoboMutation<
    ICreatePostAssetMutationGraphQLResponse,
    ICreatePostAssetMutationVariables
  >({
    mutation: mutation ?? CREATE_POST_ASSET_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: {
            createPostAsset: { asset: assetConnection, signedUrl },
          },
        } = data;

        const newAsset = {
          ...assetConnection,
          signedUrl,
        };

        void onCompleted(newAsset);
      },
      onError,
    },
    token,
  });

  const handleCreatePostAsset = ({ asset }: IHandleCreatePostAssetParams) => {
    void executeCreatePostAsset({
      variables: {
        asset,
      },
    });
  };

  return {
    handleCreatePostAsset,
    isLoading,
  };
};

export { useCreatePostAsset };
