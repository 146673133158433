import { Column } from 'react-table';

import { IDiariesTableData } from './DiariesReportPage.interfaces';



const DIARIES_COLUMNS: ReadonlyArray<Column<IDiariesTableData>> = [
  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Id</div>,
  },
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">First name</div>,
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Last name</div>,
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Email</span>,
  },
  {
    accessor: 'behaviour',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Behaviour</span>,
  },
  {
    accessor: 'behaviourType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Behaviour type</span>,
  },
  {
    accessor: 'date',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Date</span>,
  },
  {
    accessor: 'value',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Value</span>,
  },
  {
    accessor: 'createdOnUtc',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Created (utc)</span>,
  },

];

export { DIARIES_COLUMNS };
