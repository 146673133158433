import { useEffect, useState } from 'react';

import { ChartPicker, AvailableChart, ILegend, AvailableChartProps, ILineChartDataset, LineChartCard, IBarChartDataset, BarChartCard } from '@netfront/ui-library';
import { IGetUserActiveOnCompletedResponse, useGetUserActive } from 'hooks';
import { DailyCount } from 'interfaces';

import { InsightsChartsConstants } from '../Insights.constants';

import { UserActiveProps } from './UserActive.interfaces';

const UserActiveChart = ({ projectId, end, start, isFullWidth = false  }: UserActiveProps) => {
  const LABEL = 'User active';
  const { colors: [primaryColor], borderWidth, barThicknessSmall: barThickness } = InsightsChartsConstants;

  const [activeChart, setActiveChart] = useState<AvailableChart>('Line');
  const [dailyCount, setDailyCount] = useState<DailyCount[]>([]);
  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  useEffect(() => {
    void handleGetUserActive({
      projectId,
      end,
      start,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, start, end]);

  const onGetUserRegistrationCompleted = (data: IGetUserActiveOnCompletedResponse) => {
    const { userActive } = data;
    setDailyCount(userActive);
  };

  const { handleGetUserActive } = useGetUserActive({
    onCompleted: onGetUserRegistrationCompleted,
  });

  const lineChart: ILineChartDataset[] = [
    {
      backgroundColor: primaryColor,
      borderColor: primaryColor,
      data: dailyCount.map((r) => r.count),
      label: 'Active users',
      borderWidth,
    },
  ];

  const barData: IBarChartDataset[] = [
    {
      backgroundColor: [primaryColor],
      data: dailyCount.map((d) => d.count),
      label: '',
      id: 'id_daily_key',
      barThickness,
      borderWidth,
    },
  ]

  const legends: ILegend[] = [
    {
      color: primaryColor,
      label: 'Active users',
    },
  ];

  const getTotalCount = () => {
    return dailyCount.reduce((acc, dc) => {
      acc += dc.count;
      return acc;
    }, 0);
  };

  const availableCharts: AvailableChartProps[] = [
    {
      value: 'Line',
      component: (
        <LineChartCard
          datasets={lineChart}
          displayValue={getTotalCount()}
          label={LABEL}
          labels={dailyCount.map((r) => new Date(r.date).toLocaleDateString('en-AU', { day: '2-digit', month: '2-digit' }))}
          legends={legends}
        />
      ),
    },
    {
      value: 'Bar',
      component: (
        <BarChartCard
          datasets={barData}
          displayedValue={getTotalCount()}
          label={LABEL}
          labels={dailyCount.map((r) => new Date(r.date).toLocaleDateString('en-AU', { day: '2-digit', month: '2-digit' }))}
          legends={legends}
        />
      ),
    },
  ];

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_user_active_chart_picker"
      isFullWidth={isFullWidth}
      onChange={onActiveChartChange}
    />
  );
};

export { UserActiveChart };
