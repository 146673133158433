import { useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { DBAssetTypeType, IAssetTag, IDBAsset } from '@netfront/common-library';
import { FormFieldProps } from '@netfront/ui-library';
import { ICreateAssetOnCompletedResponse, IUpdateAssetOnCompletedResponse, useCreateAsset, useDeleteAsset, useToast, useUpdateAsset } from 'hooks';
import { pushImageToAws } from 'utils';



const useUpsertAssetWrapper = () => {
  const uploadedFileRef = useRef<{value: File | undefined}>({value: undefined });
  const deletedAssetIdRef = useRef<{value: string | undefined}>({ value: undefined });
  const dynamicCallBackRef = useRef<((asset?: IDBAsset) => void) | null>(null);
  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);

  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleDeleteAsset } = useDeleteAsset({
    onError: handleGetError,
  });

  const cleanUpAndReturn = (data?: ICreateAssetOnCompletedResponse | IUpdateAssetOnCompletedResponse) => {
    if (dynamicCallBackRef.current) {
      dynamicCallBackRef.current(data);
    }

    uploadedFileRef.current.value = undefined;
    deletedAssetIdRef.current.value = undefined;
    dynamicCallBackRef.current = null;
  };

  const handleUpsertAssetResponse = async (signedUrl: string, data?: ICreateAssetOnCompletedResponse | IUpdateAssetOnCompletedResponse) => {
    if (uploadedFileRef.current.value) {
      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, uploadedFileRef.current.value, () => {
        setIsAssetUploadToAwsLoading(false);
        if (deletedAssetIdRef.current.value) {
          handleDeleteAsset({
            assetId: deletedAssetIdRef.current.value
          });
        };

        cleanUpAndReturn(data);

      });
    } else {
      cleanUpAndReturn();
    }
  };

  const { handleUpdateAsset, isLoading: isUpdateAssetLoading = false } = useUpdateAsset({
    onCompleted: async (data) => {
      const { signedUrl } = data;
      await handleUpsertAssetResponse(String(signedUrl), data);

    },
    onError: handleGetError,
  });

  const { handleCreateAsset, isLoading: isCreateAssetLoading = false } = useCreateAsset({
    onCompleted: async (data) => {
      const { signedUrl } = data;

      await handleUpsertAssetResponse(signedUrl, data);
    },
    onError: handleGetError,
  });

  const handleUpsertAsset = ({
    assetIdKey = 'assetId',
    assetType,
    uploadedFile,
    deletedFileId,
    callBack,
    item,
    projectId,
    isCreate = false,
    hasUpdateAsset = false,
  }: {
    assetIdKey?: string;
    assetType: string;
    callBack: (asset?: IDBAsset) => void;
    deletedFileId?: string;
    hasUpdateAsset?: boolean;
    isCreate?: boolean;
    item?: FormFieldProps;
    projectId: string;
    uploadedFile?: File;
  }) => {
    if (isCreate && uploadedFile) {
      uploadedFileRef.current.value = uploadedFile;
      deletedAssetIdRef.current.value = deletedFileId;
      dynamicCallBackRef.current = callBack;
      const { name: imageName, type, size } = uploadedFile;
      const { tagList = [] } = item ?? {}

      handleCreateAsset({
        alt: imageName,
        contentType: type,
        description: imageName,
        fileName: imageName,
        fileSizeInBytes: size,
        imageSize: assetType === 'image' ? 'SMALL' : undefined,
        title: imageName,
        projectId: projectId,
        tagList: (tagList as IAssetTag[]).map(({ name: tagName }: { name: string}) => tagName),
        type: assetType.toUpperCase() as DBAssetTypeType,
      });
    } else {

      if (hasUpdateAsset) {
        const { contentType, fileName = '', tagList = [], alt, description = '', title } = item ?? {};
        const { type = '', name = '', size = 0 } = uploadedFile ?? {};
        handleUpdateAsset({
          assetId: String(item?.[assetIdKey]),
          alt,
          contentType: uploadedFile ? type : contentType,
          description,
          fileName: uploadedFile ? name : String(fileName),
          fileSizeInBytes: uploadedFile ? size : undefined,
          imageSize: assetType === 'image' ? 'SMALL' : undefined,
          tagIds: (tagList as IAssetTag[]).map(({id}: {id: number}) => id),
          title,
        });
      }

      callBack();

      if (deletedFileId) {
        handleDeleteAsset({
          assetId: deletedFileId,
        });
      }
    }
  };

  return {
    isLoading: isCreateAssetLoading || isAssetUploadToAwsLoading || isUpdateAssetLoading,
    handleUpsertAsset,
  };
};
export { useUpsertAssetWrapper };
