import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_QUESTION_CONFIG_MESSAGE } from './useCreateQuestionConfigMessages.graphql';
import {
  ICreateQuestionConfigMessagesMutationGraphQLResponse,
  ICreateQuestionConfigMessagesMutationVariables,
  IHandleCreateQuestionConfigMessagesParams,
  IUseCreateQuestionConfigMessagesOptions,
  IUseCreateQuestionConfigMessages,
} from './useCreateQuestionConfigMessages.interfaces';

const useCreateQuestionConfigMessages = (options?: IUseCreateQuestionConfigMessagesOptions): IUseCreateQuestionConfigMessages => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateQuestionConfigMessagesOptions);

  const [executeCreateQuestionConfigMessages, { loading: isLoading }] = useEkardoMutation<ICreateQuestionConfigMessagesMutationGraphQLResponse, ICreateQuestionConfigMessagesMutationVariables>({
    mutation: mutation ?? CREATE_QUESTION_CONFIG_MESSAGE,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { defineMessages: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateQuestionConfigMessages = async ({
    configurationId,
    messages,
   }: IHandleCreateQuestionConfigMessagesParams) => {
    await executeCreateQuestionConfigMessages({
      variables: {
        configurationId,
        messages,
      }
    });
  };

  return {
    handleCreateQuestionConfigMessages,
    isLoading,
  };
};

export { useCreateQuestionConfigMessages };
