import { gql } from '@apollo/client';

const CREATE_COMMENT_MUTATION = gql`
  mutation createComment($postId: Int, $message: String) {
    comment {
      create(request: { postId: $postId, message: $message }) {
        author {
          displayedName
        }
        id
        authorId
        author {
          firstName
          lastName
        }
        message
        createdDate
        createdDateTime
      }
    }
  }
`;

export { CREATE_COMMENT_MUTATION };
