import { gql } from '@apollo/client';

const DELETE_TAG_MUTATION = gql`
  mutation DeleteTag($tagId: Int!) {
    tagMutation {
      removeTag(tagId: $tagId)
    }
  }
`;

export { DELETE_TAG_MUTATION };
