import toast from 'react-hot-toast';

import { getToastErrorMessages } from './useToast.helpers';
import { IHandleToastErrorParams, IHandleToastSuccessParams, IUseToast } from './useToast.types';

const useToast = (): IUseToast => {
  const handleToastError = ({ error, shouldUseFriendlyErrorMessage = false }: IHandleToastErrorParams) => {
    getToastErrorMessages({
      error,
      shouldUseFriendlyErrorMessage,
    }).forEach((errorMessage) => toast.error(errorMessage));
  };

  const handleToastSuccess = ({ durationInMilliseconds: duration, message }: IHandleToastSuccessParams) => {
    toast.success(message, {
      duration,
    });
  };

  const handleToastCustomError = ({ durationInMilliseconds: duration, message }: IHandleToastSuccessParams) => {
    toast.error(message, {
      duration,
    });
  };

  return {
    handleToastCustomError,
    handleToastError,
    handleToastSuccess,
  };
};

export { useToast };
