import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_SECTION } from './useUpdateSection.graphql';
import {
  IUpdateSectionMutationGraphQLResponse,
  IUpdateSectionMutationVariables,
  IUseUpdateSectionOptions,
  IUseUpdateSection,
  IUpdateSectionMutationParams,
} from './useUpdateSection.interfaces';

const useUpdateSection = (options?: IUseUpdateSectionOptions): IUseUpdateSection => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateSectionOptions);

  const [executeUpdateSection, { loading: isLoading }] = useEkardoMutation<IUpdateSectionMutationGraphQLResponse, IUpdateSectionMutationVariables>({
    mutation: mutation ?? UPDATE_SECTION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { updateSection: section },
        } = data;

        onCompleted({
          section,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateSection = async ({
    contentSection,
    shouldCreateEvent,
    shouldUpdateEvent,
    updateContentEvent,
    createContentEvent,

  }: IUpdateSectionMutationParams) => {
    await executeUpdateSection({
      variables: {
        contentSection,
        shouldCreateEvent,
        shouldUpdateEvent,
        updateContentEvent,
        createContentEvent,
      }
    });
  };

  return {
    handleUpdateSection,
    isLoading,
  };
};

export { useUpdateSection };
