import { gql } from '@apollo/client';

const CREATE_DIRECTORY_TYPE_MUTATION = gql`
  mutation createDirectoryType($projectId: String!, $name: String!, $key: String!) {
    directoryType {
      add(projectId: $projectId, name: $name, key: $key) {
        id
      }
    }
  }
`;

export { CREATE_DIRECTORY_TYPE_MUTATION };
