import { gql } from '@apollo/client';


const GET_CONTENT_SECTION_GROUP_DETAILS = gql`
  query getSectionGroupDetails($id: Int!) {
    sectionGroup {
      get(id: $id) {
        id
        sort
        backgroundColor
        visible
        borderColor
        title
        cssStyle {
          id
          name
        }
        background {
          assetId
          contentType
          s3Key
        }

        style
        contentEvent {
          id
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          asset {
            assetId
            contentType
            s3Key
          }
          backgroundColor
          borderColor
          titleColor
          style
          triggerStyle
          iconHorizontalAlignment
          isDefaultOpen
        }
      }
    }
  }
`;

export { GET_CONTENT_SECTION_GROUP_DETAILS };
