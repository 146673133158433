
import { IUserSessionsTableData, IUserSessionsTableDataParams } from "./SessionsReportPage.interfaces";


const getUserSessionsTableData = ({ items }: IUserSessionsTableDataParams): IUserSessionsTableData[] => {
  return items.map(({
    endSession = '',
    endSessionUtc = '',
    id,
    startSession = '',
    startSessionUtc = '',
   }) => {
    return {
      endSession,
      endSessionUtc,
      id,
      startSession,
      startSessionUtc,
    };
  });
};

export { getUserSessionsTableData };
