import { gql } from '@apollo/client';

const GET_DIRECTORY_TYPES = gql`
  query getDirectoryType($projectId: String!) {
    directoryType {
      get(projectId: $projectId) {
        id
        name
        key
      }
    }
  }
`;

export { GET_DIRECTORY_TYPES };
