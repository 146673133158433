const BUTTONS = {
  TEXT: {
    DOWNLAD_SPSS: 'Download SPSS',
    DOWNLOAD_CSV: 'Download CSV',
    DOWNLOAD_EXCEL: 'Download Excel',
  },
};

const FILE_TYPE = {
  EXCEL: 'EXCEL',
  CSV: 'CSV',
};

const DATE_PICKER = {
  LABELS: {
    DATE_FROM: 'Date from',
    DATE_TO: 'Date to',
  },
  NAMES: {
    DATE_FROM: 'dateFrom',
    DATE_TO: 'dateTo',
  },
};

const DROPDOWN = {
  DEFAULT_OPTION_ID: -1,
  DEFAULT_OPTION_LABEL: {
    GROUP_CODE: 'Select a group code',
    STEP: 'Select a step',
    USER_FLOW: 'Select a user flow',
  },
};

const PHONE_NUMBER = {
  INPUT_FIELD_KEY: 'phone-number',
  MAX_LENGTH: 11,
};

const TEXT = {
  OPTIONAL: '(optional)',
};

export {
  BUTTONS,
  DATE_PICKER,
  DROPDOWN,
  FILE_TYPE,
  PHONE_NUMBER,
  TEXT,
};
