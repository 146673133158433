import { AssetType, IconRadioItemProps } from "@netfront/ui-library";

const getEmbedTypeOptions = (assetType: AssetType): IconRadioItemProps[] => {
  const options = [
    {
      iconId: 'id_download_hollow_icon',
      label: 'Soundcloud',
      value: 'SOUNDCLOUD',
      isVisible: assetType === 'audio',
    },
    {
      iconId: 'id_download_hollow_icon',
      label: 'Spotify',
      value: 'SPOTIFY',
      isVisible: assetType === 'audio',
    },
    {
      iconId: 'id_download_hollow_icon',
      label: 'iTunes',
      value: 'ITUNES',
      isVisible: assetType === 'audio',
    },
    {
      iconId: 'id_download_hollow_icon',
      label: 'YouTube',
      value: 'YOUTUBE',
      isVisible: assetType === 'video',
    },
    {
      iconId: 'id_download_hollow_icon',
      label: 'Vimeo',
      value: 'VIMEO',
      isVisible: assetType === 'video',
    },
  ];

  return options.filter(({ isVisible }) => isVisible) as IconRadioItemProps[];
};

export { getEmbedTypeOptions };
