import { gql } from '@apollo/client';

const UPDATE_FORM_STATUS = gql`
  mutation UpdateContentGroup($contentGroupId: Int!, $status: EContentGroupStatus!) {
    contentGroup {
      updateContentGroupStatus(id: $contentGroupId, status: $status) {
        id
      }
    }
  }
`;

export { UPDATE_FORM_STATUS };
