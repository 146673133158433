import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DETACH_DIRECTORY_FILE } from './useDetachDirectoryFile.graphql';
import {
  IDetachDirectoryFileMutationGraphQLResponse,
  IDetachDirectoryFileMutationVariables,
  IHandleDetachDirectoryFileParams,
  IUseDetachDirectoryFile,
  IUseDetachDirectoryFileOptions,
} from './useDetachDirectoryFile.interfaces';

const useDetachDirectoryFile = (options?: IUseDetachDirectoryFileOptions): IUseDetachDirectoryFile => {
  const { onCompleted, onError, mutation, token } = options ?? ({} as IUseDetachDirectoryFileOptions);


  const [executeDetachDirectoryFile, { loading: isLoading }] = useEkardoMutation<
  IDetachDirectoryFileMutationGraphQLResponse,
  IDetachDirectoryFileMutationVariables
  >({
    mutation: mutation ?? DETACH_DIRECTORY_FILE,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          directory: { detachFile: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDetachDirectoryFile = ({ id, assetId }: IHandleDetachDirectoryFileParams) => {
    void executeDetachDirectoryFile({
      variables: {
        id,
        assetId,
      },
    });
  };

  return {
    handleDetachDirectoryFile,
    isLoading,
  };
};

export { useDetachDirectoryFile };
