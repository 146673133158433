import { gql } from '@apollo/client';


export const DETACH_DIRECTORY_FILE = gql`
  mutation detatchFile($id: Int!, $assetId: String!) {
    directory {
      detachFile(id: $id, assetId: $assetId)
    }
  }
`;
