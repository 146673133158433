import { gql } from '@apollo/client';

const DELETE_COMMUNITY_MUTATION = gql`
  mutation DeleteCommunity($communityId: Int!) {
    community {
      delete(communityId: $communityId)
    }
  }
`;

export { DELETE_COMMUNITY_MUTATION };
