
// TODO: @ash update to icon ids
const RESPONSE_TYPE_ICON: { [key: string]: string } = {
  SummaryFeedbackConfigurationRadioType: 'radio',
  SummaryFeedbackConfigurationRankAndNoteType: 'text',
  SummaryFeedbackConfigurationMultiResponseType: 'Multi response',
  SummaryFeedbackConfigurationRangeType: 'range',
};

const RESPONSE_TYPE: { [key: string]: string } = {
  SummaryFeedbackConfigurationRadioType: 'RADIO',
  SummaryFeedbackConfigurationRankAndNoteType: 'SINGLE_TEXT',
  SummaryFeedbackConfigurationMultiResponseType: 'MULTI_RESPONSE',
  SummaryFeedbackConfigurationRangeType: 'RANGE',
};

export { RESPONSE_TYPE_ICON, RESPONSE_TYPE };
