import { useBonoboMutation } from '../useBonoboMutation';

import { UPDATE_POST_MUTATION } from './useUpdatePost.graphql';
import {
  IUpdatePostMutationGraphQLResponse,
  IUpdatePostMutationVariables,
  IHandleUpdatePostParams,
  IUseUpdatePostOptions,
  IUseUpdatePost,
} from './useUpdatePost.interfaces';

const useUpdatePost = (options?: IUseUpdatePostOptions): IUseUpdatePost => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseUpdatePostOptions);

  const [executeUpdatePost, { loading: isLoading }] = useBonoboMutation<IUpdatePostMutationGraphQLResponse, IUpdatePostMutationVariables>({
    mutation: mutation ?? UPDATE_POST_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          post: { update: postsConnection },
        } = data;

        onCompleted({ postsConnection });
      },
      onError,
    },
    token,
  });

  const handleUpdatePost = async ({ postId, message }: IHandleUpdatePostParams) => {
    await executeUpdatePost({
      variables: {
        postId,
        message,
      },
    });
  };

  return {
    handleUpdatePost,
    isLoading,
  };
};

export { useUpdatePost };
