import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_ASSET_MUTATION } from './useCreateAsset.graphql';
import {
  ICreateAssetMutationGraphQLResponse,
  ICreateAssetMutationVariables,
  IHandleCreateAssetParams,
  IUseCreateAssetOptions,
  IUseCreateAsset,
} from './useCreateAsset.interfaces';

const useCreateAsset = (options?: IUseCreateAssetOptions): IUseCreateAsset => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateAssetOptions);

  const [executeCreateAsset, { loading: isLoading }] = useEkardoMutation<
  ICreateAssetMutationGraphQLResponse,
  ICreateAssetMutationVariables
  >({
    mutation: mutation ?? CREATE_ASSET_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: {
            createAsset: { asset: assetConnection, signedUrl },
          },
        } = data;

        const newAsset = {
          ...assetConnection,
          signedUrl,
        };

        void onCompleted(newAsset);
      },
      onError,
    },
    token,
  });

  const handleCreateAsset = ({
    projectId,
    alt,
    contentType,
    description,
    fileName,
    fileSizeInBytes,
    imageSize,
    tagList,
    type,
    title,
    xAxisFocus,
    yAxisFocus,
  }: IHandleCreateAssetParams) => {
    void executeCreateAsset({
      variables: {
        projectId,
        alt,
        description,
        contentType,
        fileName,
        fileSizeInBytes,
        imageSize,
        tagList,
        type,
        title,
        xAxisFocus,
        yAxisFocus,
      },
    });
  };

  return {
    handleCreateAsset,
    isLoading,
  };
};

export { useCreateAsset };
