import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONDITION_LOCATIONS } from './useGetConditionLocations.graphql';
import { IGetConditionLocationsQueryGraphQLResponse, IGetConditionLocationsQueryVariables, IHandleGetConditionLocationsParams, IUseGetConditionLocations, IUseGetConditionLocationsOptions } from './useGetConditionLocations.interfaces';

const useGetConditionLocations = (options?: IUseGetConditionLocationsOptions): IUseGetConditionLocations => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetConditionLocationsOptions);
  const [executeGetConditionLocations, { loading: isLoading }] = useEkardoLazyQuery<
  IGetConditionLocationsQueryGraphQLResponse,
  IGetConditionLocationsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { getSnippetForms },
        } = data;

        onCompleted({
          getSnippetForms,
        });
      },
      onError,
    },
    query: query ?? GET_CONDITION_LOCATIONS,
    token,
  });

  const handleGetConditionLocations = ({ contentGroupId }: IHandleGetConditionLocationsParams) => {
    void executeGetConditionLocations({
      variables: {
        contentGroupId,
      },
    });
  };

  return {
    handleGetConditionLocations,
    isLoading,
  };
};

export { useGetConditionLocations };
