import { gql } from '@apollo/client';

const GET_COMPLETED_ORDERS_FOR_PROJECT_QUERY = gql`
  query GetCompletedOrdersForProject($projectGuid: String!) {
    orders {
      getCompletedOrdersForProject(projectGuid: $projectGuid) {
        id
        guid
        createdAtUtc
        group {
          id
          name
        }
        items {
          id
          licences {
            id
          }
        }
      }
    }
  }
`;

export { GET_COMPLETED_ORDERS_FOR_PROJECT_QUERY };
