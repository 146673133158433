
import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_CONTENT_ENTITIES_VALIDATION } from './useUpdateContentEntitiesValidation.graphql';
import {
  IUpdateContentEntitiesValidationMutationGraphQLResponse,
  IUpdateContentEntitiesValidationMutationVariables,
  IHandleUpdateContentEntitiesValidationParams,
  IUseUpdateContentEntitiesValidationOptions,
  IUseUpdateContentEntitiesValidation,
} from './useUpdateContentEntitiesValidation.interfaces';

const useUpdateContentEntitiesValidation = (options?: IUseUpdateContentEntitiesValidationOptions): IUseUpdateContentEntitiesValidation => {
  const { mutation, onCompleted, onError, token, } = options ?? ({} as IUseUpdateContentEntitiesValidationOptions);

  const [executeUpdateContentEntitiesValidation, { loading: isLoading }] = useEkardoMutation<IUpdateContentEntitiesValidationMutationGraphQLResponse, IUpdateContentEntitiesValidationMutationVariables>({
    mutation: mutation ?? UPDATE_CONTENT_ENTITIES_VALIDATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { updateBehavior: isCompleted },
        } = data;

        onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleUpdateContentEntitiesValidation = async ({ contentSnippetIds, behavior }: IHandleUpdateContentEntitiesValidationParams) => {
    await executeUpdateContentEntitiesValidation({
      variables: {
        contentSnippetIds,
        behavior,
      },
    });
  };

  return {
    handleUpdateContentEntitiesValidation,
    isLoading,
  };
};

export { useUpdateContentEntitiesValidation };
