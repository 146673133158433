import { useEkardoMutation } from '@netfront/ekardo-content-library';

import {
  CREATE_ACTION_SHOW_SNIPPET,
  CREATE_ACTION_HIDE_SNIPPET,
  CREATE_ACTION_TRIGGER_EVENT,
  CREATE_ACTION_SHOW_SECTION,
  CREATE_ACTION_HIDE_SECTION,
  CREATE_ACTION_SHOW_CONTAINER,
  CREATE_ACTION_HIDE_CONTAINER,
  CREATE_ACTION_SKIP_PAGE,
} from './useCreateAction.graphql';
import {
  ICreateActionMutationGraphQLResponse,
  ICreateActionMutationVariables,
  IHandleCreateActionParams,
  IUseCreateActionOptions,
  IUseCreateAction,
} from './useCreateAction.interfaces';

const useCreateAction = (options?: IUseCreateActionOptions): IUseCreateAction => {
  const { mutation, onCompleted, onError, token, type: actionType } = options ?? ({} as IUseCreateActionOptions);

  const mutationMap = {
    QuestionActionShowType: CREATE_ACTION_SHOW_SNIPPET,
    QuestionActionHideType: CREATE_ACTION_HIDE_SNIPPET,
    QuestionActionTriggerContentEventType: CREATE_ACTION_TRIGGER_EVENT,
    QuestionActionShowSectionType: CREATE_ACTION_SHOW_SECTION,
    QuestionActionHideSectionType: CREATE_ACTION_HIDE_SECTION,
    QuestionActionShowContainerType: CREATE_ACTION_SHOW_CONTAINER,
    QuestionActionHideContainerType: CREATE_ACTION_HIDE_CONTAINER,
    QuestionActionSkipPageType: CREATE_ACTION_SKIP_PAGE,
    // TODO: @ash update these for correct queries
    QuestionActionShowSectionGroupType: CREATE_ACTION_SHOW_SNIPPET,
    QuestionActionHideSectionGroupType: CREATE_ACTION_SHOW_SNIPPET,
  };

  const [executeCreateAction, { loading: isLoading }] = useEkardoMutation<ICreateActionMutationGraphQLResponse, ICreateActionMutationVariables>({
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mutation: mutation ?? mutationMap[actionType] ?? CREATE_ACTION_SHOW_SNIPPET,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: {
            addAction: action,
          },
        } = data;

        void onCompleted({
          action
        });
      },
      onError,
    },
    token,
  });

  const handleCreateAction = async ({
    conditions,
    contentPageId,
    title,
    type,
    wrapperId,
   }: IHandleCreateActionParams) => {
    await executeCreateAction({
      variables: {
        conditions,
        contentPageId,
        title,
        type,
        wrapperId,
      },
    });
  };

  return {
    handleCreateAction,
    isLoading,
  };
};

export { useCreateAction };
