import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_FORM_DETAILS } from './useGetFormDetails.graphql';
import {
  IGetFormDetailsQueryGraphQLResponse,
  IGetFormDetailsQueryVariables,
  IHandleGetFormDetailsParams,
  IUseGetFormDetails,
  IUseGetFormDetailsOptions,
} from './useGetFormDetails.interfaces';

const useGetFormDetails = (options?: IUseGetFormDetailsOptions): IUseGetFormDetails => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetFormDetailsOptions);

  const [executeGetFormDetails, { loading: isLoading }] = useEkardoLazyQuery<IGetFormDetailsQueryGraphQLResponse, IGetFormDetailsQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: {
            getContentGroup: contentGroup,
          },
        } = data;

        void onCompleted({ contentGroup });
      },
      onError,
    },
    query: query ?? GET_FORM_DETAILS,
    token,
  });

  const handleGetFormDetails = ({ contentGroupId }: IHandleGetFormDetailsParams) => {
    void executeGetFormDetails({
      variables: {
        contentGroupId,
      },
    });
  };

  return {
    handleGetFormDetails,
    isLoading,
  };
};

export { useGetFormDetails };
