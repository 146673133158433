import { gql } from '@apollo/client';

const DELETE_ASSET_CAPTION_MUTATION = gql`
  mutation DeleteAssetCaption($id: Int!) {
    caption {
      delete(id: $id)
    }
  }
`;

export { DELETE_ASSET_CAPTION_MUTATION };
