import { useBonoboMutation } from '../useBonoboMutation';

import { BULK_REMOVE_MEMBERS_FROM_COMMUNITY } from './useBulkRemoveMembersFromCommunity.graphql';
import {
  IBulkRemoveMembersFromCommunityMutationGraphQLResponse,
  IBulkRemoveMembersFromCommunityMutationVariables,
  IHandleBulkRemoveMembersFromCommunityParams,
  IUseBulkRemoveMembersFromCommunityOptions,
  IUseBulkRemoveMembersFromCommunity,
} from './useBulkRemoveMembersFromCommunity.interfaces';

const useBulkRemoveMembersFromCommunity = (options?: IUseBulkRemoveMembersFromCommunityOptions): IUseBulkRemoveMembersFromCommunity => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseBulkRemoveMembersFromCommunityOptions);

  const [executeBulkRemoveMembersFromCommunity, { loading: isLoading }] = useBonoboMutation<
  IBulkRemoveMembersFromCommunityMutationGraphQLResponse,
  IBulkRemoveMembersFromCommunityMutationVariables
  >({
    mutation: mutation ?? BULK_REMOVE_MEMBERS_FROM_COMMUNITY,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          communityConnection: { removeUsersFromCommunity: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkRemoveMembersFromCommunity = ({ connectionId, communityId, userIds }: IHandleBulkRemoveMembersFromCommunityParams) => {
    void executeBulkRemoveMembersFromCommunity({
      variables: {
        connectionId,
        communityId,
        userIds,
      },
    });
  };

  return {
    handleBulkRemoveMembersFromCommunity,
    isLoading,
  };
};

export { useBulkRemoveMembersFromCommunity };
