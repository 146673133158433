/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_CODE_ASSET } from './useGetCodeAsset.graphql';
import {
  IGetCodeAssetQueryGraphQLResponse,
  IGetCodeAssetQueryVariables,
  IUseGetCodeAsset,
  IUseGetCodeAssetOptions,
} from './useGetCodeAsset.interfaces';

const useGetCodeAsset = (options?: IUseGetCodeAssetOptions): IUseGetCodeAsset => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetCodeAssetOptions);

  const [executeGetCodeAsset, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetCodeAssetQueryGraphQLResponse,
    IGetCodeAssetQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { getAsset: returnedAsset },
        } = data;

        onCompleted({
          returnedAsset,
        });
      },
      onError,
    },
    query: GET_CODE_ASSET,
    token,
  });

  const handleGetCodeAsset = async ({ assetId }: IGetCodeAssetQueryVariables) => {
    await executeGetCodeAsset({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleGetCodeAsset,
    isLoading,
  };
};

export { useGetCodeAsset };
