import { gql } from '@apollo/client';

const BULK_ADD_USER_IN_COMMUNITY_MUTATION = gql`
  mutation BulkAddUsersInCommunity($communityId: Int!, $userIds: [Int]!, $role: ECommunityRole!) {
    communityConnection {
      addOrUpdateUsersInCommunity(communityId: $communityId, userIds: $userIds, role: $role)
    }
  }
`;

export { BULK_ADD_USER_IN_COMMUNITY_MUTATION };
