
import { IPagesLoadedTableData, IPagesLoadedTableDataParams } from "./PagesLoadedPage.interfaces";


const getPagesLoadedTableData = ({ items }: IPagesLoadedTableDataParams): IPagesLoadedTableData[] => {
  return items.map(({
    date,
    groupName,
    id,
    title,
    url,
    userType,
   }) => {
    return {
      date,
      groupName,
      id,
      title,
      url,
      userType,
    };
  });
};

export { getPagesLoadedTableData };
