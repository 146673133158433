import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_CONTENT_SNIPPET } from './useUpdateSnippet.graphql';
import {
  IUpdateSnippetMutationGraphQLResponse,
  IUpdateSnippetMutationVariables,
  IHandleUpdateSnippetParams,
  IUseUpdateSnippetOptions,
  IUseUpdateSnippet,
} from './useUpdateSnippet.interfaces';

const useUpdateSnippet = (options?: IUseUpdateSnippetOptions): IUseUpdateSnippet => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateSnippetOptions);

  const [executeUpdateSnippet, { loading: isLoading }] = useEkardoMutation<IUpdateSnippetMutationGraphQLResponse, IUpdateSnippetMutationVariables>({
    mutation: mutation ?? UPDATE_CONTENT_SNIPPET,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { updateContentSnippet: snippet },
        } = data;

        onCompleted({
          snippet,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateSnippet = async ({
    audioSnippet,
    baseSnippet,
    buttonSnippet,
    textSnippet,
    videoSnippet,
    codeSnippet,
    documentSnippet,
    embedSnippet,
    formSnippet,
    imageSnippet,
    questionSnippet,
    quoteSnippet,
    summarySnippet,
    shouldCreateEvent,
    shouldUpdateEvent,
    updateContentEvent,
    createContentEvent,

  }: IHandleUpdateSnippetParams) => {
    await executeUpdateSnippet({
      variables: {
        audioSnippet,
        baseSnippet,
        buttonSnippet,
        codeSnippet,
        documentSnippet,
        embedSnippet,
        formSnippet,
        imageSnippet,
        questionSnippet,
        quoteSnippet,
        summarySnippet,
        textSnippet,
        videoSnippet,
        shouldCreateEvent,
        shouldUpdateEvent,
        updateContentEvent,
        createContentEvent,
      }
    });
  };

  return {
    handleUpdateSnippet,
    isLoading,
  };
};

export { useUpdateSnippet };
