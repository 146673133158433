import React, { ChangeEvent, useEffect, useState } from 'react';

import { IContentGroup, IDBGroupingContentGroup } from '@netfront/ekardo-content-library';
import {
  useBackofficeSiteUrls, useDomain
} from '@netfront/gelada-identity-library';
import {
  Button,
  Input,
  Label,
  Select,
  SidebarButtons,
  Spacing,
  Textarea,
  ToggleSwitch,
} from '@netfront/ui-library';
import { createSyntheticEvent, toKebabCase } from 'utils';

import { ContentGroupGeneralTabProps } from './ContentGroupGeneralTab.interfaces';


const ContentGroupGeneralTab = ({
  contentGroup,
  contentGroupType,
  contentGroupings = [],
  onUpdateInput,
  onSave,
  onCancel,
  onDelete,
  organisationKey,
  projectId,
  onUpdateGrouping,
  isLoading = false,
}: ContentGroupGeneralTabProps) => {

  const { isDomainReady } = useDomain();
  const { getDashboardUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });

  const [cartoonPreviewUrl, setCartoonPreviewUrl] = useState<string>('');
  const [currentContentGroup, setCurrentContentGroup] = useState<IContentGroup | undefined>(contentGroup);

  const {
    description = '',
    contentType = 'PRIVATE',
    title = '',
    url = '',
    groupingContentGroup,
  } = currentContentGroup ?? {};

  const {
    id: currentGrouping
  } = groupingContentGroup ?? {};



  const handleUpdateInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    onUpdateInput({ name, value });

    setCurrentContentGroup(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IContentGroup),
    );
  };

  const handleUpdateType = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: isChecked },
    } = event;

    onUpdateInput({name: 'contentType', value: isChecked ? 'PUBLIC': 'PRVIATE'});

    setCurrentContentGroup(
      (currentState) =>
        ({
          ...currentState,
          contentType: isChecked ? 'PUBLIC': 'PRVIATE',
        } as IContentGroup),
    );
  };

  const handleUpdateGrouping = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    const selectedGrouping = contentGroupings.find(({id: groupingId}) => groupingId === Number(value));

    if (selectedGrouping) {
      const { id, name } = selectedGrouping;

      onUpdateGrouping({id, name} as IDBGroupingContentGroup);

      setCurrentContentGroup(
        (currentState) =>
          ({
            ...currentState,
            groupingContentGroup: {id, name},
          } as IContentGroup),
      );
    }
  };

  useEffect(() => {
    if (!(isDomainReady && contentGroup)) return;
    setCartoonPreviewUrl(`${getDashboardUrl()}/dashboard/${organisationKey}/${projectId}/cartoon-pdf/${contentGroup.url}`)


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady, contentGroup]);


  return (
    <>
      <Spacing size="2x-large">
        <Input
          id="title"
          isLoading={isLoading}
          labelText="Title"
          name="title"
          placeholder="Add your title"
          tooltipText="Title of the content group"
          type="text"
          value={title}
          isRequired
          onChange={(event) => {
            handleUpdateInput(event);
            const { target: { value }} = event;
            if (!contentGroup) {
              handleUpdateInput(createSyntheticEvent('url', toKebabCase(value)));
            }
          }}
        />
      </Spacing>

      <Spacing size="2x-large">
        <Textarea
          id="description"
          isLoading={isLoading}
          labelText="Description"
          name="description"
          placeholder="Add your description here"
          tooltipText="Description of the content group"
          value={description}
          onChange={handleUpdateInput}
        />
      </Spacing>

      <Spacing size="2x-large">
        <Input
          id="url"
          isLoading={isLoading}
          labelText="Url"
          name="url"
          placeholder="Add your url"
          tooltipText="Url of the content group"
          type="text"
          value={url}
          isRequired
          onChange={handleUpdateInput}
        />
      </Spacing>

      <Spacing size="2x-large">
        <ToggleSwitch
          additionalClassNames="c-asset-toggle"
          id="isPublic"
          isChecked={contentType === 'PUBLIC'}
          labelText="Public content"
          tooltipPosition="start"
          tooltipText="If turned on, a user can view the content with out needing to be signed in."
          onChange={handleUpdateType}
        />
      </Spacing>

      {contentGroupType === 'CARTOON' && contentGroup && (
        <>
          <Spacing size="2x-large">
            <Label forId={''} labelText="Content preview" labelType="span" tooltipText="Preview the pages altogether" />
            <Button
              linkButton={{
                id: 'id_preview_content_group',
                linkComponent: 'a',
                target: '_blank',
                url: cartoonPreviewUrl,
              }}
              text="Preview"
              variant="secondary"
            />
          </Spacing>
        </>
      )}

      {contentGroup && contentGroupings.length > 0 && (
        <Spacing size="2x-large">
          <Select
            id="id_content_groupings"
            isLoading={isLoading}
            labelText="Grouping"
            name="content_groupings"
            options={contentGroupings}
            tooltipText="Add content group to a grouping"
            value={currentGrouping}
            isLabelHidden
            onChange={handleUpdateGrouping}
          />
        </Spacing>
      )}


      <SidebarButtons
        buttonSize="xs"
        isDeleteButtonDisabled={isLoading}
        isSaveButtonDisabled={isLoading}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={onSave}
        onSaveButtonType="button"
      />
    </>
  );
};

export { ContentGroupGeneralTab };
