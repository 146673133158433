import { gql } from '@apollo/client';


const DELETE_LANGUAGE_FILTER = gql`
  mutation deleteLanguageFilter( $id: Int!) {
    languageFilter {
      delete(id: $id)
    }
  }
`;

export { DELETE_LANGUAGE_FILTER };
