import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_DIRECTORY_TYPE_MUTATION } from './useCreateDirectoryType.graphql';
import {
  ICreateDirectoryTypeMutationGraphQLResponse,
  ICreateDirectoryTypeMutationVariables,
  IHandleCreateDirectoryTypeParams,
  IUseCreateDirectoryTypeOptions,
  IUseCreateDirectoryType,
} from './useCreateDirectoryType.interfaces';

const useCreateDirectoryType = (options?: IUseCreateDirectoryTypeOptions): IUseCreateDirectoryType => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateDirectoryTypeOptions);

  const [executeCreateDirectoryType, { loading: isLoading }] = useEkardoMutation<
  ICreateDirectoryTypeMutationGraphQLResponse,
  ICreateDirectoryTypeMutationVariables
  >({
    mutation: mutation ?? CREATE_DIRECTORY_TYPE_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          directoryType: {
            add: { id },
          },
        } = data;

        void onCompleted({id});
      },
      onError,
    },
    token,
  });

  const handleCreateDirectoryType = ({
    projectId,
    key,
    name,
  }: IHandleCreateDirectoryTypeParams) => {
    void executeCreateDirectoryType({
      variables: {
        projectId,
        key,
        name,
      },
    });
  };

  return {
    handleCreateDirectoryType,
    isLoading,
  };
};

export { useCreateDirectoryType };
