import React from 'react';

import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ILanguageFilterTableData } from './LanguageFilterPage.interfaces';



const LANGUAGE_FILTER_PAGE_SIZE = 10;

const LANGUAGE_FILTER_TABLE_COLUMNS: ReadonlyArray<Column<ILanguageFilterTableData>> = [
  {
    accessor: 'content',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Content</div>,
  },
  {
    accessor: 'type',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Last name</div>,
  },
  {
    accessor: 'isEmbargoingContent',
    Cell: ({ value }) => <div>{value ? 'Yes': 'No'}</div>,
    Header: () => <div>Embargoed</div>,
  },
  {
    accessor: 'isNegative',
    Cell: ({ value }) => <div>{value ? 'Yes': 'No'}</div>,
    Header: () => <div>Negative</div>,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="User posts" onClick={() => onClick(id)} />,
    Header: () => <div className="h-hide-visually">Settings</div>,
    width: '5%',
  },
];

export { LANGUAGE_FILTER_TABLE_COLUMNS, LANGUAGE_FILTER_PAGE_SIZE };
