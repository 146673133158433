import { gql } from '@apollo/client';

const EXPORT_ALL_MODULE_PAGE_COMPLETIONS = gql`
  query ExportAllModulePageCompletions(
    $ianaTimeZone: String!
    $userFlowId: Int!
    $dateFrom: DateTime
    $dateTo: DateTime
    $fileType: FileType
  ) {
    report {
      exportAllModulePageCompletions(
        ianaTimeZone: $ianaTimeZone
        userFlowId: $userFlowId
        dateFrom: $dateFrom
        dateTo: $dateTo
        fileType: $fileType
      ) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_ALL_MODULE_PAGE_COMPLETIONS };
