/* eslint-disable @typescript-eslint/indent */
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_USER_FLOW_STEP_INSIGHT } from './useGetUserFlowStepInsights.graphql';
import {
  IGetUserFlowStepInsightsQueryGraphQLResponse,
  IGetUserFlowStepInsightsQueryVariables,
  IUseGetUserFlowStepInsights,
  IUseGetUserFlowStepInsightsOptions,
} from './useGetUserFlowStepInsights.interfaces';

const useGetUserFlowStepInsights = (options?: IUseGetUserFlowStepInsightsOptions): IUseGetUserFlowStepInsights => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetUserFlowStepInsightsOptions);

  const [executeGetUserFlowStepInsights, { loading: isLoading }] = useEkardoLazyQuery<
    IGetUserFlowStepInsightsQueryGraphQLResponse,
    IGetUserFlowStepInsightsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { userFlowStepCompletionInsight } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          data: userFlowStepCompletionInsight,
        });
      },
      onError,
    },
    query: GET_USER_FLOW_STEP_INSIGHT,
    token,
  });

  const handleGetUserFlowStepInsights = async ({ end, userFlowId, start }: IGetUserFlowStepInsightsQueryVariables) => {
    await executeGetUserFlowStepInsights({
      variables: {
        end,
        start,
        userFlowId,
      },
    });
  };

  return {
    handleGetUserFlowStepInsights,
    isLoading,
  };
};

export { useGetUserFlowStepInsights };
