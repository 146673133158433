import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, InformationBox, Input, ToggleSwitch } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, TextEditor } from 'components';
import { Controller } from 'react-hook-form';
import { createSyntheticEvent } from 'utils';

import { UpsertQuoteSnippetGeneralTabProps } from './UpsertQuoteSnippetGeneralTab.interfaces';


const UpsertQuoteSnippetGeneralTab = ({ control }: UpsertQuoteSnippetGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  return (
    <ContentBuilderTabWrapper>
      <Spacing>
        <InformationBox message="Edit your code below." />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_quote_snippet_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="author"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_quote_snippet_author"
              labelText="Author"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="text"
          render={({ field, fieldState }) => (
              <TextEditor
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_text_content"
                initialValue={field.value}
                label="Text"
                isRequired
                onInputChange={(content: string) => {
                  field.onChange(createSyntheticEvent('text', content))
                }}
              />
            )
          }
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_sensitive_content"
              isChecked={field.value}
              labelText="Sensitive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isVisible"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_visible"
              isChecked={field.value}
              labelText="Is visible"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </ContentBuilderTabWrapper>
  );
};

export { UpsertQuoteSnippetGeneralTab };
