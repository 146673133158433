import { IDBAsset } from "@netfront/common-library";
import { ISectionGroup } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import {
  // getContentAnimationDetails,
  // getContentEventDetails,
  getFormattedContentAnimation,
  getFormattedContentEvent,
  getUpsertContentEventStatus,
} from "components";

const getSectionGroupDefaultValues = ({ sectionGroup } : { sectionGroup?: ISectionGroup; }): FormFieldProps => {
  const {
    // title,
    // contentEvent,
    visible: isVisible = true,
    // isSensitive = false,
  } = sectionGroup ?? {};

  // const eventDetails = getContentEventDetails(contentEvent ?? undefined);
  // const animationDetails = getContentAnimationDetails(contentEvent ?? undefined);

  return {
    // title: title ?? 'Section group',
    title: 'Section group',
    isVisible,
    isSensitive: false,
    // ...eventDetails,
    // ...animationDetails,
  }
};

const getSectionGroupCommonVariables = ({asset, eventAssetId, item, sort }: { asset?: IDBAsset; eventAssetId?: string; item: FormFieldProps; sort: number }) => {

  const {
    title = 'Section group',
    isVisible,
    isSensitive = false,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);

  const sharedVariables = {
    sectionGroup: {
      title,
      sort,
      isVisible,
      isSensitive,
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, asset, assetId: eventAssetId, type: 'SECTION' }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
  };

  return sharedVariables;

}


export { getSectionGroupDefaultValues, getSectionGroupCommonVariables };
