import { useBonoboMutation } from '../useBonoboMutation';

import { DELETE_POST_MUTATION } from './useDeletePost.graphql';
import {
  IDeletePostMutationGraphQLResponse,
  IDeletePostMutationVariables,
  IHandleDeletePostParams,
  IUseDeletePostOptions,
  IUseDeletePost,
} from './useDeletePost.interfaces';

const useDeletePost = (options?: IUseDeletePostOptions): IUseDeletePost => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseDeletePostOptions);

  const [executeDeletePost, { loading: isLoading }] = useBonoboMutation<IDeletePostMutationGraphQLResponse, IDeletePostMutationVariables>({
    mutation: mutation ?? DELETE_POST_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          post: { delete: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeletePost = ({ postId }: IHandleDeletePostParams) => {
    void executeDeletePost({
      variables: {
        postId,
      },
    });
  };

  return {
    handleDeletePost,
    isLoading,
  };
};

export { useDeletePost };
