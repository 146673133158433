/* eslint-disable @typescript-eslint/indent */
import { useHowlerLazyQuery } from 'hooks/social/useHowlerLazyQuery';

import { GET_NOTIFICATION_GROUP } from './useGetNotificationGroup.graphql';
import {
  IGetNotificationGroupQueryGraphQLResponse,
  IGetNotificationGroupQueryVariables,
  IUseGetNotificationGroup,
  IUseGetNotificationGroupOptions,
} from './useGetNotificationGroup.interfaces';

const useGetNotificationGroup = (options?: IUseGetNotificationGroupOptions): IUseGetNotificationGroup => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetNotificationGroupOptions);

  const [executeGetNotificationGroup, { loading: isLoading }] = useHowlerLazyQuery<
    IGetNotificationGroupQueryGraphQLResponse,
    IGetNotificationGroupQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ notification: { getNotificationGroup } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          notificationGroup: getNotificationGroup,
        });
      },
      onError,
    },
    query: query ?? GET_NOTIFICATION_GROUP,
    token,
  });

  const handleGetNotificationGroup = async ({ notificationGroupId }: IGetNotificationGroupQueryVariables) => {
    await executeGetNotificationGroup({
      variables: {
        notificationGroupId,
      },
    });
  };

  return {
    handleGetNotificationGroup,
    isLoading,
  };
};

export { useGetNotificationGroup };
