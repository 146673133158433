import { gql } from '@apollo/client';

const GET_TEMPLATES = gql`
  query getTemplates($projectId: String!) {
    project {
      getTemplates(projectId: $projectId) {
        id
        name
        type
      }
    }
  }
`;

export { GET_TEMPLATES };
