import { FormFieldProps } from "@netfront/ui-library";
import { IAssetCaption } from "hooks";


const getAssetCaptionDefaultValues = (caption?: IAssetCaption): FormFieldProps => {
  const {
    from,
    to,
    text,
    id,
  } = caption ?? {};

  return {
    from: from ?? 0,
    to: to ?? 0,
    text: text ?? '',
    id: id ?? 0,
  };
}

export { getAssetCaptionDefaultValues };
