import { useContext, useState, useEffect } from 'react';

import { ITab } from '@netfront/ui-library';
import { TablePageTemplate , PostSidebarGeneralView } from 'components';
import { CachingEntitiesContext, DashboardContext } from 'context';
import { useGetPaginatedPosts, useToast } from 'hooks';
import { IDBComment, IDBPost } from 'interfaces';
import last from 'lodash.last';
import { useRouter } from 'next/router';


import { POSTS_TABLE_COLUMNS, POST_PAGE_SIZE } from './ModerationPage.constants';
import { getPostsTableData } from './ModerationPage.helpers';
import { IPostsTableData } from './ModerationPage.interfaces';



const ModerationPage = () => {
  const { query: { projectId: queryProjectId } } = useRouter();



  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);

  const [projectName, setProjectName] = useState<string>('');

  const { handleToastError, handleToastSuccess } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [allPosts, setAllPosts] = useState<IDBPost[]>();
  const [filter, setFilter] = useState<string>('');
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(POST_PAGE_SIZE);
  const [postsTableData, setPostsTableData] = useState<IPostsTableData[]>();
  const [selectedPost, setSelectedPost] = useState<IDBPost>();
  const [totalPosts, setTotalPosts] = useState<number>(0);

  const {
    handleFetchMorePaginatedPosts,
    handleGetPaginatedPosts,
    isLoading: isGetPaginatedPostsLoading = false,
  } = useGetPaginatedPosts({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ postConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const posts = edges.map(({ node }) => node);

      setAllPosts(posts as unknown as IDBPost[]);
      setIsLoadMoreDisabled(posts.length >= totalCount || totalCount <= pageSize);
      setTotalPosts(totalCount);
    },
    projectId: String(projectId),
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleDeletedPost = (id: IDBPost['id']) => {
    setAllPosts((currentState): IDBPost[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.filter((post) => {
        return post.id !== id;
      });
    });

    setTotalPosts(totalPosts - 1);

    handleSideBarClose();
    handleToastSuccess({
      message: 'Post deleted successfully',
    });
  };

  const handleAddNewPostClick = () => {
    setSelectedPost(undefined);
    setIsSideBarOpen(true);
  };

  const handleFilterSearch = (value: string) => {
    handleGetPaginatedPosts({
      first: pageSize,
      message: value,
    });

    setFilter(value);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
    setSelectedPost(undefined);
  };

  const handleCreatedPost = (post: IDBPost) => {
    setAllPosts((currentState) => {
      if (!currentState) {
        return;
      }

      return [...currentState, post];
    });

    setTotalPosts(totalPosts + 1);

    handleSideBarClose();
    handleToastSuccess({
      message: 'Post created successfully',
    });
  };

  const handleCreatedComment = (post: IDBPost, comment: IDBComment) => {
    setAllPosts((currentState): IDBPost[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.map((currentPost): IDBPost => {
        return currentPost.id === post.id
          ? {
            ...post,
            commentCount: currentPost.commentCount + 1,
            comments: {
              edges: [...currentPost.comments.edges, { node: comment }],
            },
          }
          : currentPost;
      });
    });

    handleToastSuccess({
      message: 'Comment added successfully',
    });

    handleSideBarClose();
  };

  const handleUpdatedComment = (post: IDBPost, comment: IDBComment) => {
    setAllPosts((currentState): IDBPost[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.map((currentPost): IDBPost => {
        return currentPost.id === post.id
          ? {
            ...post,
            comments: {
              edges: currentPost.comments.edges.map(({ node }) => {
                return node.id === comment.id ? { node: comment } : { node };
              }),
            },
          }
          : currentPost;
      });
    });

    handleToastSuccess({
      message: 'Comment updated successfully',
    });

    handleSideBarClose();
  };

  const handleDeletedComment = (post: IDBPost, comment: IDBComment) => {
    setAllPosts((currentState): IDBPost[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.map((currentPost): IDBPost => {
        return currentPost.id === post.id
          ? {
            ...post,
            commentCount: currentPost.commentCount - 1,
            comments: {
              edges: currentPost.comments.edges.filter(({ node }) => node.id !== comment.id),
            },
          }
          : currentPost;
      });
    });

    handleToastSuccess({
      message: 'Comment deleted successfully',
    });

    handleSideBarClose();
  };

  const handleUpdatedPost = (post: IDBPost) => {
    setAllPosts((currentState): IDBPost[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.map((currentPost): IDBPost => {
        return currentPost.id === post.id ? post : currentPost;
      });
    });

    handleSideBarClose();
    handleToastSuccess({
      message: 'Post updated successfully',
    });
  };

  useEffect(() => {
    if (!projectId) return;
    handleGetPaginatedPosts({
      first: pageSize,
      message: filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allPosts) {
      return;
    }

    setPostsTableData(
      getPostsTableData({
        posts: allPosts,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedPost(allPosts.find(({ id: postId }) => id === postId));
        },
        onAttachmentButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedPost(allPosts.find(({ id: postId }) => id === postId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPosts]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetPaginatedPostsLoading;

  const tabs: ITab[] = [
    {
      iconId: 'id_general_tab_icon',
      id: 'id_general_tab',
      label: 'General',
      view: () => (
        <PostSidebarGeneralView
          projectId={String(projectId)}
          selectedPost={selectedPost}
          onClose={() => setIsSideBarOpen(false)}
          onCreated={handleCreatedPost}
          onCreatedComment={handleCreatedComment}
          onDeleted={handleDeletedPost}
          onDeletedComment={handleDeletedComment}
          onUpdated={handleUpdatedPost}
          onUpdatedComment={handleUpdatedComment}
        />
      ),
    },
  ];



  useEffect(() => {
    if (!(project && projectId)) {
      return;
    }

    const { name } = project;

    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, projectId]);

  return (
    <TablePageTemplate<IPostsTableData>
      activePage="social"
      activeSubPage="moderation"
      additionalBreadcrumbItems={[
        {
          key: '1',
          content: <a href={`${String(dashboardLink)}/social`}>Social</a>,
        },
        {
          key: '2',
          content: 'Moderation',
        },
      ]}
      columns={POSTS_TABLE_COLUMNS}
      data={postsTableData ?? []}
      defaultActiveTabId="id_general_tab"
      description={`Moderation social content for ${projectName} project`}
      handleAddNewClick={handleAddNewPostClick}
      handleOnPageSizeChange={handlePageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMorePaginatedPosts({
          after: lastCursor,
          first: pageSize,
        });
      }}
      handleSearch={handleFilterSearch}
      informationBoxMessage={`Moderate ${projectName} social content`}
      isLoading={isLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      pageSize={pageSize}
      pageTitle={projectName}
      tableType="moderations"
      tabs={tabs}
      title={`${projectName} orders page`}
      toggleIsSideBarOpen={handleSideBarClose}
      totalItems={totalPosts}
    />
  );
};

export { ModerationPage };
