import { gql } from '@apollo/client';

const GET_NOTIFICATION_GROUPS = gql`
  query getNotificationGroups($projectId: String!, $after: String, $first: Int) {
    notification {
      notificationGroups(after: $after, first: $first, projectId: $projectId) {
        edges {
          cursor
          node {
            content
            hasBeenSent
            id
            subject
            firstNotificationSendingDate
          }
        }
      }
    }
  }
`;

export { GET_NOTIFICATION_GROUPS };
