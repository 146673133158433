

import { useBonoboLazyQuery } from 'hooks';

import { EXPORT_POST_LOGS } from './useGetPostLogsReport.graphql';
import {
  IGetPostLogsReportQueryGraphQLResponse,
  IGetPostLogsReportQueryVariables,
  IHandleGetPostLogsReportParams,
  IUseGetPostLogsReport,
  IUseGetPostLogsReportOptions,
} from './useGetPostLogsReport.interfaces';

const useGetPostLogsReport = (options?: IUseGetPostLogsReportOptions): IUseGetPostLogsReport => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetPostLogsReportOptions);

  const [executeGetPostLogsReport, { loading: isLoading }] = useBonoboLazyQuery<
    IGetPostLogsReportQueryGraphQLResponse,
    IGetPostLogsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportPostLogs } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportPostLogs,
        });
      },
      onError,
    },
    query: query ?? EXPORT_POST_LOGS,
    token,
  });


  const handleGetPostLogsReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetPostLogsReportParams) => {
    await executeGetPostLogsReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetPostLogsReport,
    isLoading,
  };
};

export { useGetPostLogsReport };
