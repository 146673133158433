import { gql } from '@apollo/client';

const DELETE_CONTENT_CONTAINER = gql`
  mutation deleteContentContainer(
    $contentSectionId: Int!
    $containerId: Int!
    $contentLayoutId: Int
  ) {
    contentSection {
      deleteContentSectionContainer(
        sectionContainer: {
          sectionId: $contentSectionId
          containerId: $containerId
          contentLayoutId: $contentLayoutId
        }
      ) {
        id
      }
    }
  }
`;


export { DELETE_CONTENT_CONTAINER };
