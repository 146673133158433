import { gql } from '@apollo/client';

const CREATE_GLOSSARY_ITEM_MUTATION = gql`
  mutation CreateGlossaryItem($request: CreateTextTooltipInputType!) {
    textTooltip {
      add(request: $request) {
        id
        description
        title
        projectId
      }
    }
  }
`;

export { CREATE_GLOSSARY_ITEM_MUTATION };
