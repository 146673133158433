import { gql } from '@apollo/client';

const CREATE_COMMUNITY_MUTATION = gql`
  mutation CreateCommunity($inputType: CreateCommunityInputType!) {
    community {
      create(inputType: $inputType) {
        bannerImage {
          assetId
          fileName
          fileSizeInBytes
          s3Key
          presignedUrl
          contentType
        }
        categoryId
        description
        id
        privacy
        profileImage {
          assetId
          contentType
          fileName
          fileSizeInBytes
          presignedUrl
          s3Key
        }
        status
        title
        userConnection {
          role
          id
        }
      }
    }
  }
`;

export { CREATE_COMMUNITY_MUTATION };
