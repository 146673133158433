import { LazyQueryResultTuple, OperationVariables } from '@apollo/client';
import { getApolloLink, useApolloLazyQuery, useCookie } from '@netfront/common-library';

import { IUseTripleELazyQueryInputVariables } from './useTripleELazyQuery.interfaces';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useTripleELazyQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>({
  apolloLink,
  options,
  product,
  query,
  token,
}: IUseTripleELazyQueryInputVariables<TData, TVariables>): LazyQueryResultTuple<TData, TVariables> => {
  const { getAccessTokenCookie, getImpersonatedUserAccessTokenCookie, getImpersonatedUserTemporaryTokenCookie, getTemporaryTokenCookie } =
    useCookie();

  const accessToken = getImpersonatedUserAccessTokenCookie() ?? getAccessTokenCookie();
  const temporaryToken = getImpersonatedUserTemporaryTokenCookie() ?? getTemporaryTokenCookie();

  const authenticationLink = getApolloLink({
    apiName: 'tripleE',
    product,
    token: token ?? accessToken ?? temporaryToken,
  });

  return useApolloLazyQuery<TData, TVariables>({
    apiName: 'tripleE',
    apolloLink: apolloLink ?? authenticationLink,
    options,
    query,
  });
};

export { useTripleELazyQuery };
