import React, { useEffect, useRef, useState } from 'react';


import { TextSnippetPreviewProps } from './TextSnippetPreview.interfaces';


const TextSnippetPreview = ({ snippet }: TextSnippetPreviewProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { text = '', linkColor = '#000', fontColor = '#000', listIconColor = '#000', id } = snippet;

  const [uniqueClassName, setUniqueClassName] = useState<string>('');
  useEffect(() => {
    const parentElement = parentRef.current;

    if (!uniqueClassName || !parentElement) return;

    const styleElement = document.createElement('style');
      styleElement.textContent = `
        .${uniqueClassName} ul li::before {
          color: ${listIconColor}
        }
        .${uniqueClassName} p {
          color: ${fontColor};
        }
        .${uniqueClassName} a {
          color: ${linkColor}
        }
      `;

      // Append the style element to the head
      document.head.appendChild(styleElement);

      // Add the class to the parent element
      parentElement.classList.add(uniqueClassName);

      // Cleanup: remove the style element when the component unmounts
      return () => {
        document.head.removeChild(styleElement);
      };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueClassName, parentRef.current])

  useEffect(() => {
    setUniqueClassName(`c-text-preview--${id}`);
  }, [id]);

  return (
    <div className={`c-text-preview c-text-preview--${id}`}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export { TextSnippetPreview };
