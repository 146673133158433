import { useEffect, useState } from 'react';

import { ChartPicker, BarChartCard, IBarChartDataset, ILegend, AvailableChartProps, AvailableChart, IDoughnutFeed, DoughnutChartCard } from '@netfront/ui-library';
import { IGetUserCountByStateOnCompletedResponse, useGetUserCountByState } from 'hooks';
import { DailyKey } from 'interfaces';

import { InsightsChartsConstants } from '../Insights.constants';

import { UserCountByStateChartProps } from './UserCountByState.interfaces';

const UserCountByStateChart = ({ projectId, isFullWidth = false  }: UserCountByStateChartProps) => {
  const LABEL = 'User by state';
  const { colors, borderWidth, barThicknessSmall: barThickness } = InsightsChartsConstants;

  const [activeChart, setActiveChart] = useState<AvailableChart>('Bar');
  const [dailyKey, setDailyKey] = useState<DailyKey[]>([]);
  const [barData, setBarData] = useState<IBarChartDataset[]>([]);
  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };


  const onCompleted = (data: IGetUserCountByStateOnCompletedResponse) => {
    const { userCountByState } = data;
    setDailyKey(userCountByState);
  };

  const { handleGetUserCountByState } = useGetUserCountByState({
    onCompleted: onCompleted,
  });


  const doughnutData = dailyKey.map(
    (r, i) =>
      ({
        backgroundColor: colors[i],
        borderColor: colors[i],
        label: r.key,
        value: r.count,
      } as IDoughnutFeed),
  );

  const legends: ILegend[] = dailyKey.map((r, i) => {
    return { color: colors[i], label: r.key };
  });

  const availableCharts: AvailableChartProps[] = [
    {
      value: 'Bar',
      component: (
        <BarChartCard
          datasets={barData}
          displayedValue={dailyKey.length > 0 ? dailyKey.map((r) => r.count).reduce((a, b) => a + b) : 0}
          label={LABEL}
          labels={dailyKey.map((r) => r.key)}
          legends={legends}
        />
      ),
    },
    { value: 'Doughnut', component: <DoughnutChartCard feed={doughnutData} label={LABEL} /> },
  ];


  useEffect(() => {
    void handleGetUserCountByState({
      projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!dailyKey.length) return;
    setBarData([
      {
        backgroundColor: colors,
        data: dailyKey.map((d) => d.count),
        label: '',
        id: 'id_daily_key',
        barThickness,
        borderWidth,
      },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyKey]);

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_user_count_by_state_chart_picker"
      isFullWidth={isFullWidth}
      onChange={onActiveChartChange}
    />
  );
};

export { UserCountByStateChart };
