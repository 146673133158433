import { Button, FlexContainer, SettingsButton } from '@netfront/ui-library';
import { StatusIndicator } from 'components';
import { Column } from 'react-table';


import { IFormsTableData } from './LibraryFormsPage.interfaces';


const FORMS_TABLE_COLUMNS: ReadonlyArray<Column<IFormsTableData>> = [

  {
    accessor: 'title',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Name</div>,
    width: '40%%',
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Description</div>,
    width: '40%',
  },
  {
    accessor: 'status',
    Cell: ({ value }) => (
      <>
        <StatusIndicator status={value} />
      </>
    ),
    Header: () => <div className="text-left w-full">Status</div>,
    width: '15%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { url, id, onClick } }) => (
      <FlexContainer gap="small">
        <Button
          linkButton={{
            linkComponent: 'a',
            id: `id_form_builder_${id}`,
            url,
          }}
          size="xs"
          text="Open"
        />
        <SettingsButton supportiveText="Update tags" onClick={() => onClick(id)} />
      </FlexContainer>
    ),
    Header: () => <span className="h-hide-visually">Settings</span>,
    width: '5%',
  },
];

export { FORMS_TABLE_COLUMNS };
