import { gql } from '@apollo/client';

const GET_ASSET_USAGES_QUERY = gql`
  query GetAssetUsages($assetId: String!) {
    asset {
      usages(id: $assetId) {
        id
        location {
          contentGroup {
            id
            title
          }
          url
        }
        type
      }
    }
  }
`;

export { GET_ASSET_USAGES_QUERY };
