import React, { ChangeEvent, FormEvent, useState } from 'react';

import { Input, SidebarButtons, Spacing, Spinner } from '@netfront/ui-library';
import { useToast  } from 'hooks';
import { toKebabCase } from 'utils';

import { useCreateDirectoryType } from 'hooks/directory/useCreateDirectoryType';

import { DirectoryTypeGeneralTabProps } from './DirectoryTypeGeneralTab.interfaces';


const DirectoryTypeGeneralTab= ({ projectId, onClose, onSave }: DirectoryTypeGeneralTabProps) => {

  const { handleToastError } = useToast();
  const [selectedDirectoryType, setSelectedDirectoryType] = useState<string>('');

  const { handleCreateDirectoryType, isLoading = false } = useCreateDirectoryType({
    onCompleted: ({ id }) => {

      onSave(Number(id));
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  })


  const onInputChange = (event: ChangeEvent< HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSelectedDirectoryType(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedDirectoryType) return;
    handleCreateDirectoryType({
      key: toKebabCase(selectedDirectoryType),
      name: String(selectedDirectoryType),
      projectId,
    });
  }

  return (
    <>
      <Spinner isLoading={isLoading} />
      <form onSubmit={onSubmit}>
        <fieldset>
          <legend className="h-hide-visually">Directory types</legend>
          <Spacing>
            <Input
              id="id_directory_name"
              labelText="Directory type name"
              name="new_directory_name"
              tooltipText="The type of directory you would like to add to the project"
              type="text"
              onChange={onInputChange}
            />
          </Spacing>

          <SidebarButtons
            onCancel={onClose}
            onSaveButtonType="submit"
          />
        </fieldset>
      </form>
    </>
  );
};

export { DirectoryTypeGeneralTab };

