import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_FORM_MUTATION } from './useDeleteForm.graphql';
import {
  IDeleteFormMutationGraphQLResponse,
  IDeleteFormMutationVariables,
  IHandleDeleteFormParams,
  IUseDeleteFormOptions,
  IUseDeleteForm,
} from './useDeleteForm.interfaces';

const useDeleteForm = (options?: IUseDeleteFormOptions): IUseDeleteForm => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteFormOptions);

  const [executeDeleteForm, { loading: isLoading }] = useEkardoMutation<IDeleteFormMutationGraphQLResponse, IDeleteFormMutationVariables>({
    mutation: mutation ?? DELETE_FORM_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: {
            updateContentGroupStatus: {
              id,
            },
          },
        } = data;

        void onCompleted({
          id
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteForm = async ({ contentGroupId, status }: IHandleDeleteFormParams) => {
    await executeDeleteForm({
      variables: {
        contentGroupId,
        status,
      },
    });
  };

  return {
    handleDeleteForm,
    isLoading,
  };
};

export { useDeleteForm };
