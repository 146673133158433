

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_PAGE_DETAILS_QUERY } from './useGetContentPageDetails.graphql';
import {
  IGetContentPageDetailsQueryGraphQLResponse,
  IGetContentPageDetailsQueryVariables,
  IHandleGetContentPageDetailsParams,
  IUseContentPageDetails,
  IUseContentPageDetailsOptions,
} from './useGetContentPageDetails.interfaces';



const useGetContentPageDetails = (options?: IUseContentPageDetailsOptions): IUseContentPageDetails => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseContentPageDetailsOptions);

  const [executeGetContentPageDetails, { loading: isLoading }] = useEkardoLazyQuery<
    IGetContentPageDetailsQueryGraphQLResponse,
    IGetContentPageDetailsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { getContentPage: contentPage },
        } = data;


        onCompleted({
          contentPage,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_CONTENT_PAGE_DETAILS_QUERY,
    token,
  });

  const handleGetContentPageDetails = async ({
    contentPageId,
  }: IHandleGetContentPageDetailsParams) => {
    await executeGetContentPageDetails({
      variables: {
        contentPageId,
      },
    });
  };

  return {
    handleGetContentPageDetails,
    isLoading,
  };
};

export { useGetContentPageDetails };
