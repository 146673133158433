
import { IAllModulePageCompletedTableData, IAllModulePageCompletedTableDataParams } from "./AllModulePagesCompleted.interfaces";


const getAllModulePageCompletedTableData = ({ items }: IAllModulePageCompletedTableDataParams): IAllModulePageCompletedTableData[] => {
  return items.map(({
    completedOn = '-',
    completedOnUtc = '-',
    groupName,
    id,
    moduleTitle,
    startedOn = '-',
    startedOnUtc = '-',
    timeToComplete = '-',
    pageTitle = '-',
    userType,
   }) => {
    return {
      completedOn,
      completedOnUtc,
      groupName,
      id,
      moduleTitle,
      startedOn,
      startedOnUtc,
      timeToComplete,
      pageTitle,
      userType,
    };
  });
};

export { getAllModulePageCompletedTableData };
