import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_SECTION_DETAILS } from './useGetSectionDetails.graphql';
import {
  IGetSectionDetailsQueryGraphQLResponse,
  IGetSectionDetailsQueryVariables,
  IHandleGetSectionDetailsParams,
  IUseGetSectionDetails,
  IUseGetSectionDetailsOptions,
} from './useGetSectionDetails.interfaces';

const useGetSectionDetails = (options?: IUseGetSectionDetailsOptions): IUseGetSectionDetails => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetSectionDetailsOptions);

  const [executeGetSectionDetails, { loading: isLoading }] = useEkardoLazyQuery<
  IGetSectionDetailsQueryGraphQLResponse,
  IGetSectionDetailsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { getContentSection: section},
        } = data;

        void onCompleted({
          section,
        });
      },
      onError,
    },
    query: query ?? GET_CONTENT_SECTION_DETAILS,
    token,
  });

  const handleGetSectionDetails = ({ contentSectionId }: IHandleGetSectionDetailsParams) => {
    void executeGetSectionDetails({
      variables: {
        contentSectionId,
      },
    });
  };

  return {
    handleGetSectionDetails,
    isLoading,
  };
};

export { useGetSectionDetails };
