import { gql } from '@apollo/client';


export const ATTACH_DIRECTORY_FILE = gql`
  mutation attachFile($id: Int!, $assetId: String!) {
    directory {
      attachFile(id: $id, assetId: $assetId)
    }
  }
`;
