import React, { useState } from 'react';

import { Select, Spacing, DatePicker } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { ConditionDateQuestionProps } from './ConditionDateQuestion.interfaces';

import { ConditionDateTimespan } from '../../ConditionDateTimespan';

const ConditionDateQuestion = ({ control, setValue, initialDateRangeType = '' }: ConditionDateQuestionProps) => {
  const [dateRangeType, setDateRangeType] = useState<string>(initialDateRangeType);
  return (
    <>
      <Spacing>
        <Controller
          control={control}
          name="dateRangeType"
          render={({ field }) => (
            <Select
              id="id_date_range_type"
              labelText="Type"
              options={[
                {
                  id: 0,
                  name: 'Please select',
                  value: '',
                },
                {
                  id: 1,
                  name: 'Fluid',
                  value: 'FLUID',
                },
                {
                  id: 2,
                  name: 'Fixed',
                  value: 'FIXED',
                },
                {
                  id: 2,
                  name: 'Specific',
                  value: 'SPECIFIC',
                },
              ]}
              tooltipPosition="start"
              tooltipText="Fixed: A set point in time between two given dates, Fluid: A fluid moving time span based on the supplied date in the past or future, Specific: A specific fixed date must match the date given in the condition"
              isLabelSideBySide
              {...field}
              onChange={(event) => {
                const { target: { value }} = event;
                field.onChange(event);
                setDateRangeType(value);
              }}
            />
          )}
        />

      </Spacing>

      {dateRangeType === 'FIXED' && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="minDate"
              render={({ field }) => (
                <DatePicker
                  id="id_min_date"
                  labelText="Min date"
                  selectedDate={field.value ? new Date(String(field.value)) : undefined}
                  tooltipText="The minimum date to be used to meet the condition"
                  isLabelSideBySide
                  onSingleDateChangeHandler={(e: Date | null) => setValue('minDate', e)}
                  {...field}
                />
              )}
            />
          </Spacing>

          <Spacing>
            <Controller
              control={control}
              name="maxDate"
              render={({ field }) => (
                <DatePicker
                  id="id_max_date"
                  labelText="Max date"
                  selectedDate={field.value ? new Date(String(field.value)) : undefined}
                  tooltipText="The maximum date to be used to meet the condition"
                  isLabelSideBySide
                  onSingleDateChangeHandler={(e: Date | null) => setValue('maxDate', e)}
                  {...field}
                />
              )}
            />
          </Spacing>
        </>
      )}
      {dateRangeType === 'FLUID' && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="minTimeSpan"
              render={({ field }) => (
                <ConditionDateTimespan
                  id="minTimeSpan"
                  labelText="Minimum time span"
                  {...field}
                  onChange={({name, value}) => setValue(name, value)}
                />
              )}
            />

          </Spacing>
          <Spacing>
            <Controller
              control={control}
              name="maxTimeSpan"
              render={({ field }) => (
                <ConditionDateTimespan
                  id="minTimeSpan"
                  labelText="Maximum time span"
                  {...field}

                  onChange={({name, value}) => setValue(name, value)}
                />
              )}
            />
          </Spacing>
        </>
      )}
      {dateRangeType === 'SPECIFIC' && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="selectedDate"
              render={({ field }) => (
                <DatePicker
                  id="id_selected_date"
                  labelText="Date"
                  selectedDate={field.value ? new Date(String(field.value)) : undefined}
                  tooltipText="The specific date required to meet the condition"
                  isLabelSideBySide
                  onSingleDateChangeHandler={(e: Date | null) => setValue('maxDate', e)}
                  {...field}
                />
              )}
            />
          </Spacing>
        </>
      )}
    </>
  );
}
export { ConditionDateQuestion };
