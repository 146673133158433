import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, FormFieldProps, Input, SideBarTabSet, Spacing, Spinner, TabSetImperativeHandleRef, Textarea, ToggleSwitch, useControlledForm } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { useUpsertPage } from 'hooks';
import { Controller, FieldErrors } from 'react-hook-form';
import { toKebabCase } from 'utils';
import * as yup from 'yup';

import { getPageDefaultValues } from './FirstPageSidebarView.helpers';
import { FirstPageSidebarViewProps } from './FirstPageSidebarView.interfaces';


const FirstPageSidebarView = ({ contentGroupId, onSave, onCancel, isOpen = false }: FirstPageSidebarViewProps ) => {

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        url: yup.string().label('Url').required(),
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };


  const { handleUpsertPage, isLoading: isUpsertPageLoading = false } = useUpsertPage({
    onCreate: (returnedContentPage) => {
      reset();
      onSave(returnedContentPage);
    },
    onUpdate: (returnedContentPage) => {
      reset();
      onSave(returnedContentPage);
    }
  });


  const handleSave = (item: FormFieldProps) => {
    const {
      title = 'Page',
      seoDescription,
      status,
      url,
      tags,
      isSensitive = false,
    } = item;


    handleUpsertPage({
      contentGroupId,
      variables: {
        title,
        seoDescription,
        sensitiveContent: isSensitive,
        sort: 0,
        url,
        status,
        tags,
      },
    });
  };


  useEffect(() => {
    if (isOpen) setDefaultValues(getPageDefaultValues());


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const isLoading = isUpsertPageLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        <SideBarTabSet
          ref={tabsetRef}
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={() => {
            reset();
            onCancel();
          }}
          hasViewPadding={false}
          isSideBarOpen={isOpen}
          tabs={[
            {
              iconId: 'id_general_tab_icon',
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <>
                  <SidebarContainer>
                    <Spacing>
                      <Controller
                        control={control}
                        name="title"
                        render={({ field, fieldState }) => (
                          <Input
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_section_title"
                            labelText="Title"
                            type="text"
                            isLabelSideBySide
                            isRequired
                            {...field}
                            onChange={(event) => {
                              const { target: { value }} = event;
                              field.onChange(event);
                              setValue('url', toKebabCase(value));
                            }}
                          />
                        )}
                      />
                    </Spacing>
                    <Spacing>
                      <Controller
                        control={control}
                        name="seoDescription"
                        render={({ field, fieldState }) => (
                          <Textarea
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_page_description_textarea"
                            labelText="Description"
                            isLabelSideBySide
                            {...field}
                          />
                        )}
                      />
                    </Spacing>
                    <Spacing>
                      <Controller
                        control={control}
                        name="url"
                        render={({ field, fieldState }) => (
                          <Input
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_page_url"
                            labelText="Url"
                            type="text"
                            isLabelSideBySide
                            isRequired
                            {...field}
                          />
                        )}
                      />
                    </Spacing>
                    <Spacing>
                      <Controller
                        control={control}
                        name="isSensitive"
                        render={({ field }) => (
                          <ToggleSwitch
                            additionalClassNames={`c-sidebar-toggle`}
                            id="id_is_sensitive_content"
                            isChecked={field.value}
                            labelText="Sensitive"
                            isInline
                            isLabelSideBySide
                            {...field}
                          />
                        )}
                      />
                    </Spacing>
                  </SidebarContainer>
                </>
              ),
            },
          ]}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </>
  );
};


export { FirstPageSidebarView };
