import { LayoutRadioItemProps } from "./LayoutSelector.interfaces";

const getLayoutOptions = (containerNumber?: number): LayoutRadioItemProps[] => {

  const layoutOptions: LayoutRadioItemProps[] = [
    {
      value: '1',
      iconId: 'id_actions_icon',
      label: 'Whole',
      isVisible: !containerNumber || containerNumber === 1,
    },
    {
      value: '2',
      iconId: 'id_actions_icon',
      label: 'Half | Half',
      isVisible: !containerNumber || containerNumber === 2,
    },
    {
      value: '3',
      iconId: 'id_actions_icon',
      label: 'Two third | Third',
      isVisible: !containerNumber || containerNumber === 2,
    },
    {
      value: '4',
      iconId: 'id_actions_icon',
      label: 'Third | Two third',
      isVisible: !containerNumber || containerNumber === 2,
    },
    {
      value: '5',
      iconId: 'id_actions_icon',
      label: 'Quarter | Three quarters',
      isVisible: !containerNumber || containerNumber === 2,
    },
    {
      value: '6',
      iconId: 'id_actions_icon',
      label: 'Three quarters | Quarter',
      isVisible: !containerNumber || containerNumber === 2,
    },
    {
      value: '7',
      iconId: 'id_actions_icon',
      label: 'Thirds',
      isVisible: !containerNumber || containerNumber === 3,
    },
    {
      value: '8',
      iconId: 'id_actions_icon',
      label: 'Half | quarters',
      isVisible: !containerNumber || containerNumber === 3,
    },
    {
      value: '9',
      iconId: 'id_actions_icon',
      label: 'Quarters | Half',
      isVisible: !containerNumber || containerNumber === 3,
    },
    {
      value: '10',
      iconId: 'id_actions_icon',
      label: 'Quarters',
      isVisible: !containerNumber || containerNumber === 4,
    },
  ];

  return layoutOptions.filter(({isVisible}) => isVisible);
};


export { getLayoutOptions };
