import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SNIPPET_EVENT_DETAILS } from './useGetSnippetEventDetails.graphql';
import { IGetSnippetEventDetailsQueryGraphQLResponse, IGetSnippetEventDetailsQueryVariables, IHandleGetSnippetEventDetailsParams, IUseGetSnippetEventDetails, IUseGetSnippetEventDetailsOptions } from './useGetSnippetEventDetails.interfaces';

const useGetSnippetEventDetails = (options?: IUseGetSnippetEventDetailsOptions): IUseGetSnippetEventDetails => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetSnippetEventDetailsOptions);
  const [executeGetSnippetEventDetails, { loading: isLoading }] = useEkardoLazyQuery<
  IGetSnippetEventDetailsQueryGraphQLResponse,
  IGetSnippetEventDetailsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { getContentSnippet: snippet },
        } = data;

        onCompleted({
          snippet,
        });
      },
      onError,
    },
    query: query ?? GET_SNIPPET_EVENT_DETAILS,
    token,
  });

  const handleGetSnippetEventDetails = ({ contentSnippetId }: IHandleGetSnippetEventDetailsParams) => {
    void executeGetSnippetEventDetails({
      variables: {
        contentSnippetId,
      },
    });
  };

  return {
    handleGetSnippetEventDetails,
    isLoading,
  };
};

export { useGetSnippetEventDetails };
