const IMAGE_DEFAULT_COLOR = [236, 237, 240, 1];

const IMAGE_DEFAULT_BACKGROUND_COLOR = 'rgba(255, 255, 255, 1)';

const imageSidebarEditImageViewConstants = Object.freeze({
  imageDefaultColor: IMAGE_DEFAULT_COLOR,
  imageDefaultBackgroundColorRgba: {
    r: 255,
    g: 255,
    b: 255,
    a: 100,
  },
  imageDefaultBackgroundColorString: IMAGE_DEFAULT_BACKGROUND_COLOR,
});

export { imageSidebarEditImageViewConstants };
