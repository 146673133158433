import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ACTIONS } from './useGetActions.graphql';
import { IGetActionsQueryGraphQLResponse, IGetActionsQueryVariables, IHandleGetActionsParams, IUseGetActions, IUseGetActionsOptions } from './useGetActions.interfaces';

const useGetActions = (options?: IUseGetActionsOptions): IUseGetActions => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetActionsOptions);
  const [executeGetActions, { loading: isLoading }] = useEkardoLazyQuery<
  IGetActionsQueryGraphQLResponse,
  IGetActionsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: {
            getContentPage: { actions },
          },
        } = data;

        onCompleted({
          actions,
        });
      },
      onError,
    },
    query: query ?? GET_ACTIONS,
    token,
  });

  const handleGetActions = ({ contentPageId }: IHandleGetActionsParams) => {
    void executeGetActions({
      variables: {
        contentPageId
      },
    });
  };

  return {
    handleGetActions,
    isLoading,
  };
};

export { useGetActions };
