import { gql } from '@apollo/client';

export const FRAGMENT_FORM = gql`
  fragment form on ContentSnippetFormType {
    formType
    form {
      id
      title
      description
      formType
      url
      sort
      recommendedFormType
      contentPages {
        id
        sectionGroups {
          id
          sort
          visible
          contentSections {
            id,
            title
            sort
            sectionContainers {
              id
              title
              sectionId
              sort
              snippets {
                id
                sort
                visible
                type: __typename
                __typename
                ...text
                ...image
                ...video
                ...audio
                ...question
                ...summary
              }
            }
          }
        }

      }
    }
  }
`;
