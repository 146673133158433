import { IOption } from "@netfront/ui-library";

const getReportDropdownOptions = <T>(
  options: T[],
  textKeys: { key: keyof T, prefix?: string }[],
  valueKey: keyof T
): IOption[] => {

  const customOptions = options.map((option) => {
    const value = option[valueKey] as unknown as string | number;

    return {
      id: value,
      name: textKeys.map(({ key, prefix = '' }) => `${prefix}${String(option[key])}`).join(' - '),
      value,
    };
  }) as IOption[];

  return customOptions.length ? customOptions : [];
};

export { getReportDropdownOptions };
