import { useEffect, useState } from 'react';

import {
  AvailableChart,
  AvailableChartProps,
  ChartPicker,
  ILineChartDataset,
  ILegend,
  LineChartCard,
  BarChartCard,
  IBarChartDataset,
} from '@netfront/ui-library';
import { IGetUserFlowInsightsOnCompletedResponse, useGetUserFlowStepInsights } from 'hooks';
import { GroupedDailyCount } from 'interfaces';

import { InsightsChartsConstants } from '../Insights.constants';

import { UserFlowStepCompletionProps } from './UserFlowStepCompletion.interfaces';

const UserFlowStepCompletion = ({ userFlowId, end, start, isFullWidth = false, userFlowTitle }: UserFlowStepCompletionProps) => {
  const LABEL = userFlowTitle;

  const { colors, borderWidth, barThicknessSmall: barThickness } = InsightsChartsConstants;

  const [activeChart, setActiveChart] = useState<AvailableChart>('Line');
  const [groupedDailyCount, setGroupedDailyCount] = useState<GroupedDailyCount[]>([]);
  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const onGetUserRegistrationCompleted = (response: IGetUserFlowInsightsOnCompletedResponse) => {
    const { data } = response;
    setGroupedDailyCount(data);
  };

  const { handleGetUserFlowStepInsights } = useGetUserFlowStepInsights({
    onCompleted: onGetUserRegistrationCompleted,
  });

  const getTotalCount = () => {
    return groupedDailyCount.reduce((acc, dc) => {
      acc += dc.dailyCounts.map((r) => r.count).reduce((a, b) => a + b, 0);
      return acc;
    }, 0);
  };

  const lineCharts = groupedDailyCount.map((grouped, index) => {
    const lineChart: ILineChartDataset = {
      backgroundColor: colors[index],
      borderColor: colors[index],
      data: grouped.dailyCounts.map((r) => r.count),
      borderWidth,
      label: grouped.name,
    };
    return lineChart;
  });

  const legends = groupedDailyCount.map((grouped, index) => {
    const legend: ILegend = {
      color: colors[index],
      label: grouped.name,
    };
    return legend;
  });

  const barsData = groupedDailyCount.map((grouped, index) => {
    const barChart: IBarChartDataset = {
      backgroundColor: [colors[index]],
      barThickness,
      borderWidth,
      data: grouped.dailyCounts.map((r) => r.count),
      label: grouped.name,
      id: `id_${grouped.name}`,
    };
    return barChart;
  });

  const availableCharts: AvailableChartProps[] = [
    {
      value: 'Line',
      component: (
        <LineChartCard
          datasets={lineCharts}
          displayValue={getTotalCount()}
          label={LABEL}
          labels={groupedDailyCount[0]?.dailyCounts.map((r) =>
            new Date(r.date).toLocaleDateString('en-AU', { day: '2-digit', month: '2-digit' }),
          )}
          legends={legends}
        />
      ),
    },
    {
      value: 'Bar',
      component: (
        <BarChartCard
          datasets={barsData}
          displayedValue={getTotalCount()}
          label={LABEL}
          labels={groupedDailyCount[0]?.dailyCounts.map((r) =>
            new Date(r.date).toLocaleDateString('en-AU', { day: '2-digit', month: '2-digit' }),
          )}
          legends={legends}
        />
      ),
    },
  ];

  useEffect(() => {
    void handleGetUserFlowStepInsights({
      end,
      userFlowId,
      start,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, userFlowId]);

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_user_registration_chart_picker"
      isFullWidth={isFullWidth}
      onChange={onActiveChartChange}
    />
  );
};

export { UserFlowStepCompletion };
