export const formatCaptionTimeFormat = (time: number) => {
  return time < 10 ? `0${time}` : time;
};

export const handleCalculateCaptionTimes = (seconds: number) => {
  const minutes = Math.floor(Number(seconds) / 60);
  const hours = Math.floor(minutes / 60);

  const remainingSeconds = Number(seconds) % 60;
  const remainingMinutes = minutes % 60;

  return `${formatCaptionTimeFormat(hours)}:${formatCaptionTimeFormat(remainingMinutes)}:${formatCaptionTimeFormat(remainingSeconds)}`;
};
