import { gql } from '@apollo/client';

const DELETE_CONTENT_SECTION_GROUP = gql`
  mutation deleteContentSectionGroup($id: Int!) {
    sectionGroup {
      delete(id: $id)
    }
  }
`;


export { DELETE_CONTENT_SECTION_GROUP };
