import { useBonoboMutation } from '../useBonoboMutation';

import { DELETE_COMMENT_MUTATION } from './useDeleteComment.graphql';
import {
  IDeleteCommentMutationGraphQLResponse,
  IDeleteCommentMutationVariables,
  IHandleDeleteCommentParams,
  IUseDeleteCommentOptions,
  IUseDeleteComment,
} from './useDeleteComment.interfaces';

const useDeleteComment = (options?: IUseDeleteCommentOptions): IUseDeleteComment => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseDeleteCommentOptions);

  const [executeDeleteComment, { loading: isLoading }] = useBonoboMutation<
    IDeleteCommentMutationGraphQLResponse,
    IDeleteCommentMutationVariables
  >({
    mutation: mutation ?? DELETE_COMMENT_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          comment: { delete: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteComment = ({ commentId }: IHandleDeleteCommentParams) => {
    void executeDeleteComment({
      variables: {
        commentId,
      },
    });
  };

  return {
    handleDeleteComment,
    isLoading,
  };
};

export { useDeleteComment };
