import React, { useContext, useEffect } from 'react';

import { CMSContentPage, ContentPageProvider, IContentPage, useContentPageContext } from '@netfront/ekardo-content-library';
import { ContentBuilderContext } from 'context';
import { IEntityType } from 'interfaces';

const ContentBuilderPreviewInner = ({ contentPage }: { contentPage: IContentPage } ) => {
  const { openUpdateEntitySidebar, openDeleteEntityDialog } = useContext(ContentBuilderContext);
  const { dispatch } = useContentPageContext();


  useEffect(() => {
    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage]);

  return (
    <>
      <CMSContentPage
        editModeCallbacks={{
          dropdownOptions: [
            {
              iconId: 'id_settings_icon',
              label: 'Edit',
              isHidden: false,
              onClick: ({ id: entityId, type: entityType }) => openUpdateEntitySidebar(Number(entityId), entityType as IEntityType)
            },
            {
              iconId: 'id_bin_icon',
              label: 'Delete',
              isHidden: false,
              onClick: ({ id: entityId, type }) => openDeleteEntityDialog({ id: Number(entityId), type: type as IEntityType })
            },
          ]
        }}
        isEditMode
      />
    </>
  );
};

const ContentBuilderPreview = ({ contentPage }: { contentPage: IContentPage }) => {
  return (
    <ContentPageProvider>
      <ContentBuilderPreviewInner contentPage={contentPage} />
    </ContentPageProvider>
  );
};

export { ContentBuilderPreview };
