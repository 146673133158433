import { gql } from '@apollo/client';

const SEARCH_PAGINATED_TAGS_QUERY = gql`
  query SearchPaginatedTags($projectId: String!, $entities: [ETaggedEntity], $filter: String, $first: Int) {
    tag {
      search(projectId: $projectId, entities: $entities, filter: $filter, first: $first) {
        totalCount
        edges {
          cursor
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export { SEARCH_PAGINATED_TAGS_QUERY };
