import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_QUESTION_PREVIEW } from './useGetQuestionPreview.graphql';
import {
  IGetQuestionPreviewQueryGraphQLResponse,
  IGetQuestionPreviewQueryVariables,
  IUseGetQuestionPreview,
  IUseGetQuestionPreviewOptions,
} from './useGetQuestionPreview.interfaces';

const useGetQuestionPreview = (options?: IUseGetQuestionPreviewOptions): IUseGetQuestionPreview => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetQuestionPreviewOptions);

  const [executeGetQuestionPreview, { loading: isLoading }] = useEkardoLazyQuery<
    IGetQuestionPreviewQueryGraphQLResponse,
    IGetQuestionPreviewQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { question: config },
        } = data;

        onCompleted({
          config,
        });
      },
      onError,
    },
    query: GET_QUESTION_PREVIEW,
    token,
  });

  const handleGetQuestionPreview = async ({ id }: IGetQuestionPreviewQueryVariables) => {
    await executeGetQuestionPreview({
      variables: {
        id,
      },
    });
  };

  return {
    handleGetQuestionPreview,
    isLoading,
  };
};

export { useGetQuestionPreview };
