import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CONTENT_SNIPPET } from './useDeleteSnippet.graphql';
import {
  IDeleteSnippetMutationGraphQLResponse,
  IDeleteSnippetMutationVariables,
  IHandleDeleteSnippetParams,
  IUseDeleteSnippetOptions,
  IUseDeleteSnippet,
} from './useDeleteSnippet.interfaces';

const useDeleteSnippet = (options?: IUseDeleteSnippetOptions): IUseDeleteSnippet => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteSnippetOptions);

  const [executeDeleteSnippet, { loading: isLoading }] = useEkardoMutation<IDeleteSnippetMutationGraphQLResponse, IDeleteSnippetMutationVariables>({
    mutation: mutation ?? DELETE_CONTENT_SNIPPET,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { deleteSnippet: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteSnippet = async ({ contentSnippetId }: IHandleDeleteSnippetParams) => {
    await executeDeleteSnippet({
      variables: {
        contentSnippetId,
      },
    });
  };

  return {
    handleDeleteSnippet,
    isLoading,
  };
};

export { useDeleteSnippet };
