import { gql } from '@apollo/client';

const GET_GROUP_ACCESS = gql`
  query getGroupAccess($groupId: Int!) {
    userFlowGroupAccessQuery {
      byGroup(groupId: $groupId) {
        userFlowId
        userFlow {
          title
        }
      }
    }
  }
`;

export { GET_GROUP_ACCESS };
