import { ApolloQueryResult } from '@apollo/client';
import { useBonoboLazyQuery } from 'hooks';

import { GET_USER_COMMUNITIES_QUERY } from './useGetUserCommunities.graphql';
import {
  IGetPaginatedUserCommunitiesQueryGraphQLResponse,
  IGetPaginatedUserCommunitiesQueryVariables,
  IHandleFetchMorePaginatedUserCommunitiesParams,
  IHandleGetPaginatedUserCommunitiesParams,
  IUseGetPaginatedUserCommunities,
  IUseGetPaginatedUserCommunitiesOptions,
} from './useGetUserCommunities.interfaces';

const useGetPaginatedUserCommunities = (options?: IUseGetPaginatedUserCommunitiesOptions): IUseGetPaginatedUserCommunities => {
  const {
    fetchPolicy,
    onCompleted,
    onError,
    query,
    token,
    projectId: bonoboProjectId,
  } = options ?? ({} as IUseGetPaginatedUserCommunitiesOptions);

  const [executeGetPaginatedUserCommunities, { fetchMore, loading: isLoading }] = useBonoboLazyQuery<
    IGetPaginatedUserCommunitiesQueryGraphQLResponse,
    IGetPaginatedUserCommunitiesQueryVariables
  >({
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          community: { userCommunities: communityConnection },
        } = data;

        onCompleted({
          communityConnection,
        });
      },
      onError,
    },
    query: query ?? GET_USER_COMMUNITIES_QUERY,
    token,
  });

  const handleFetchMorePaginatedUserCommunities = ({
    after,
    first,
    filter,
    userId,
    shouldIncludeBannerImage,
    shouldIncludeProfileImage,
  }: IHandleFetchMorePaginatedUserCommunitiesParams): Promise<ApolloQueryResult<IGetPaginatedUserCommunitiesQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          community: { userCommunities: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          community: { userCommunities: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          post: {
            ...previouslyFetchedScenarioConnection,
            getFeedUserCommunities: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        filter,
        userId,
        shouldIncludeBannerImage,
        shouldIncludeProfileImage,
      },
    });
  };

  const handleGetPaginatedUserCommunities = ({
    after,
    first,
    filter,
    userId,
    shouldIncludeBannerImage,
    shouldIncludeProfileImage,
  }: IHandleGetPaginatedUserCommunitiesParams) => {
    void executeGetPaginatedUserCommunities({
      variables: {
        after,
        first,
        filter,
        userId,
        shouldIncludeBannerImage,
        shouldIncludeProfileImage,
      },
    });
  };

  return {
    handleFetchMorePaginatedUserCommunities,
    handleGetPaginatedUserCommunities,
    isLoading,
  };
};

export { useGetPaginatedUserCommunities };
