export const sortObjectArrayAlphabetically = <T>(array: T[], key: keyof T): T[] =>
  [...array].sort((a, b) => {
    const keyA = String(a[key]).toLowerCase();
    const keyB = String(b[key]).toLowerCase();
    if (keyA < keyB) {
      return -1;
    }
    if (keyA > keyB) {
      return 1;
    }
    return 0; // names are equal
  });
