import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_ASSET_MUTATION } from './useDeleteAsset.graphql';
import {
  IDeleteAssetMutationGraphQLResponse,
  IDeleteAssetMutationVariables,
  IHandleDeleteAssetParams,
  IUseDeleteAssetOptions,
  IUseDeleteAsset,
} from './useDeleteAsset.interfaces';

const useDeleteAsset = (options?: IUseDeleteAssetOptions): IUseDeleteAsset => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteAssetOptions);

  const [executeDeleteAsset, { loading: isLoading }] = useEkardoMutation<
    IDeleteAssetMutationGraphQLResponse,
    IDeleteAssetMutationVariables
  >({
    mutation: mutation ?? DELETE_ASSET_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { deleteAsset: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteAsset = ({ assetId }: IHandleDeleteAssetParams) => {
    void executeDeleteAsset({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleDeleteAsset,
    isLoading,
  };
};

export { useDeleteAsset };
