import { gql } from '@apollo/client';


const UPDATE_CONTENT_ENTITIES_VISIBILITY = gql`
  mutation updateContentEntitiesVisibility($entities: [CmsEntityInputType]!, $isVisible: Boolean!) {
    contentPage {
      toggleVisibilityEntities(entities: $entities, isVisible: $isVisible)
    }
  }
`;

export { UPDATE_CONTENT_ENTITIES_VISIBILITY };
