
import { IGroupsTableData, IGroupsTableDataParams } from "./GroupsPage.interfaces";


const getGroupsTableData = ({ items }: IGroupsTableDataParams): IGroupsTableData[] => {
  return items.map(({
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    countryCode = '',
    creationDateUtc = '',
    detailUrl = '',
    groupName = '',
    groupUrl = '',
    id,
    phoneNumber = '',
    postCode = '',
    state = '',
    type = '',
   }) => {
    return {
      addressLine1,
      addressLine2,
      city,
      countryCode,
      creationDateUtc,
      detailUrl,
      groupName,
      groupUrl,
      id,
      phoneNumber,
      postCode,
      state,
      type,
    };
  });
};

export { getGroupsTableData };
