import React, { useEffect, useRef, useState } from 'react';

import { DBDirectoryStatusType, IDirectory, useCreateDirectory } from '@netfront/ekardo-content-library';
import { Dialog, SideBarTabSet } from '@netfront/ui-library';
import { DirectoryAddressTab, DirectoryGeneralTab, DirectoryOperatingHoursTab, DirectoryTagsTab } from 'components';
import { useDeleteDirectory, useToast, useUpdateDirectory, useUpsertDirectoryAddress } from 'hooks';
import { IDirectoryOperatingHours } from 'interfaces';
import { toKebabCase } from 'utils';


import { getFormattedDirectory } from './LibraryDirectorySidebarView.helpers';
import { IEditableDirectory, LibraryDirectorySideBarViewProps } from './LibraryDirectorySidebarView.interfaces';



const LibraryDirectorySideBarView = ({ selectedDirectory, projectId, isSideBarOpen = false, onClose, onUpdate, directoryTypeOptions = [] }: LibraryDirectorySideBarViewProps) => {
  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();
  const mutableStateRef = useRef<{value: IEditableDirectory}>({ value: {} as IEditableDirectory });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);


  const { handleUpsertDirectoryAddress, isLoading: isUpdateDirectoryAddressLoading = false } = useUpsertDirectoryAddress({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Directory successfully updated',
      });
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true
      });
    },
  });

  const { handleCreateDirectory, isLoading: isCreateDirectoryLoading = false } = useCreateDirectory({
    onCompleted: ({ directory: { id }}) => {

      const { city, country, line1, postcode, state, suburb } = mutableStateRef.current.value;
      const hasAddress = Boolean(city) || Boolean(country) || Boolean(line1) || Boolean(postcode) || Boolean(state) || Boolean(suburb);

      if (hasAddress) {
        void handleUpsertDirectoryAddress({
          address: {
            city,
            country,
            line1,
            postcode,
            state,
            suburb,
          },
          directoryId: Number(id),
        });
      } else {
        handleToastSuccess({
          message: 'Directory successfully created',
        });
        onUpdate();
      }

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true
      });
    },
  });

  const { handleDeleteDirectory, isLoading: isDeleteDirectoryLoading = false } = useDeleteDirectory({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Directory successfully deleted',
      });
      setIsDeleteDialogOpen(false);
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true
      });
    },
  });

  const { handleUpdateDirectory, isLoading: isUpdateDirectoryLoading = false } = useUpdateDirectory({
    onCompleted: () => {

      const { address: existingAddress } = selectedDirectory ?? {};

      const { city: existingCity, country: existingCountry, line1: existingLine1, postcode: existingPostcode, state: existingState, suburb: existingSuburb } = existingAddress ?? {};
      const { city, country, line1, postcode, state, suburb } = mutableStateRef.current.value;

      const initialAddressWithoutLatLng = [existingCity, existingCountry, existingLine1, existingPostcode, existingState, existingSuburb].join(',');
      const updatedAddressWithoutLatLng = [city, country, line1, postcode, state, suburb].join(',');
      const isAddressChanged = initialAddressWithoutLatLng !== updatedAddressWithoutLatLng;
      if (!isAddressChanged) {
        handleToastSuccess({
          message: 'Directory successfully updated',
        })
        onUpdate();
      } else {
        void handleUpsertDirectoryAddress({
          address: {
            city,
            country,
            line1,
            postcode,
            state,
            suburb,
          },
          directoryId: Number(selectedDirectory?.id),
        });
      }
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true
      });
    },
  });


  const onDelete = () => {
    setIsDeleteDialogOpen(true);
  }


  const onSubmit = () => {
    const {
      assetId,
      description,
      directoryTypeKey: updatedDirectoryTypeKey,
      directoryTypeId,
      phoneNumber,
      email,
      title,
      subTitle,
      url,
      id,
      linkedTags,
      operatingHours,
      status,
    } = mutableStateRef.current.value;

    if (!updatedDirectoryTypeKey) {
      handleToastCustomError({ message: 'Please select a directory type' });
      return;
    }

    if (!title) {
      handleToastCustomError({ message: 'Please provide a title' });
      return;
    }

    selectedDirectory
      ? void handleUpdateDirectory({
        assetId,
        description,
        directoryTypeId: Number(directoryTypeId),
        friendlyUrl: toKebabCase(String(title)),
        phoneNumber,
        email,
        directoryId: Number(id),
        tagIds: linkedTags?.map(({ id: tagId }: {id: number}) => tagId),
        title,
        subTitle,
        status: status as DBDirectoryStatusType,
        url: url ? encodeURIComponent(url) : undefined,
        operatingHours: operatingHours.map((operatingHoursItem) => JSON.stringify(operatingHoursItem))
      })
      : void handleCreateDirectory({
        directory: {
          assetId,
          description,
          directoryTypeKey: String(updatedDirectoryTypeKey),
          friendlyUrl: toKebabCase(String(title)),
          phoneNumber,
          email,
          projectId: String(projectId),
          status: 'PUBLISHED',

          title: String(title),
          subTitle,
          url: url ? encodeURIComponent(String(url)) : undefined,
          operatingHours: operatingHours.map((operatingHoursItem) => JSON.stringify(operatingHoursItem))
        },
        tagIds: linkedTags?.map(({ id: tagId }: {id: number}) => tagId),
      });
  };

  const onInputChange = (name: string, value = '') => {
    mutableStateRef.current.value = {
      ...mutableStateRef.current.value,
      [name]: value
    }
  };

  const onTagsSelectChange = (linkedTags: IDirectory['linkedTags']) => {
    mutableStateRef.current.value = {
      ...mutableStateRef.current.value,
      linkedTags,
    }
  }

  const onDirectoryTypeChange = (id: string, value: string) => {
    mutableStateRef.current.value = {
      ...mutableStateRef.current.value,
      directoryTypeKey: value,
      directoryTypeId: Number(id),
    }
  };

  const onUpdateOperatingHours = (updatedOperatingHours: IDirectoryOperatingHours[]) => {
    mutableStateRef.current.value = {
      ...mutableStateRef.current.value,
      operatingHours: updatedOperatingHours
    }
  }


  useEffect(() => {
    mutableStateRef.current.value = getFormattedDirectory(selectedDirectory);
  }, [selectedDirectory, isSideBarOpen]);

  const isLoading = isCreateDirectoryLoading || isUpdateDirectoryLoading || isDeleteDirectoryLoading || isUpdateDirectoryAddressLoading;

  return (
    <>
      {directoryTypeOptions.length && isSideBarOpen && (
        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={onClose}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              iconId: 'id_general_tab_icon',
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <DirectoryGeneralTab
                  directoryTypeOptions={directoryTypeOptions}
                  isLoading={isLoading}
                  projectId={String(projectId)}
                  selectedDirectory={selectedDirectory}
                  onCancel={onClose}
                  onChange={onInputChange}
                  onDelete={selectedDirectory ? onDelete: undefined}
                  onDirectoryTypeChange={onDirectoryTypeChange}
                  onSave={onSubmit}
                />
              )
            },
            {
              iconId: 'id_users_icon',
              id: 'id_address_tab',
              label: 'Address',
              view: () => (
                <DirectoryAddressTab
                  selectedDirectory={selectedDirectory}
                  onCancel={onClose}
                  onChange={onInputChange}
                  onDelete={selectedDirectory ? onDelete: undefined}
                  onSave={onSubmit}
                />
              )
            },
            {
              iconId: 'id_users_icon',
              id: 'id_tags_tab',
              label: 'Tags',
              view: () => (
                <DirectoryTagsTab
                  projectId={projectId}
                  selectedDirectory={selectedDirectory}
                  onCancel={onClose}
                  onChange={onTagsSelectChange}
                  onDelete={selectedDirectory ? onDelete: undefined}
                  onSave={onSubmit}
                />
              )
            },
            {
              iconId: 'id_resources_icon',
              id: 'id_operating_hours_tab',
              label: 'Operating hours',
              view: () => (
                <DirectoryOperatingHoursTab
                  selectedDirectory={selectedDirectory}
                  onCancel={onClose}
                  onDelete={selectedDirectory ? onDelete: undefined}
                  onSave={onSubmit}
                  onUpdateOperatingHours={onUpdateOperatingHours}
                />
              )
            },
          ]}
          hideSideBarButtons
        />
      )}
      {selectedDirectory && (

        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete directory: ${selectedDirectory.title}`}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            handleDeleteDirectory({
              directoryId: selectedDirectory.id,
            });
          }}
        />
      )}
    </>
  );
};



export {LibraryDirectorySideBarView} ;
