import React, { ReactNode, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { IContentGroup } from '@netfront/ekardo-content-library';
import { ControlledForm, SidebarButtons, FormFieldProps, Select, Spacing, Spinner, useControlledForm, IOption, SearchFilter, IBreadcrumbItem } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, SidebarContainer } from 'components';
import { useGetContentGroups, useToast } from 'hooks';
import capitalize from 'lodash.capitalize';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';


import { ChooseContentGroupFromLibraryProps } from './ChooseContentGroupFromLibrary.interfaces';


const Wrapper = ({additionalBreadcrumbs = [], children, hasContainer = false} : { additionalBreadcrumbs?: IBreadcrumbItem[]; children: ReactNode; hasContainer?: boolean }) => {
  if (hasContainer) return (
    <ContentBuilderTabWrapper additionalBreadcrumbs={additionalBreadcrumbs} hasSnippetTypeBreadcrumb={false}>
      {children}
    </ContentBuilderTabWrapper>
  );

  return <SidebarContainer>{children}</SidebarContainer>;
}

const ChooseContentGroupFromLibrary = ({ onCancel, onSave, projectId, contentGroupType, additionalBreadcrumbs = [], hasContainer = false }: ChooseContentGroupFromLibraryProps) => {
  const { handleToastError } = useToast();
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({
    assetId: '',
    search: '',
  });
  const [contentGroupOptions, setContentGroupOptions] = useState<IOption[]>([]);
  const [allContentGroups, setAllContentGroups] = useState<IContentGroup[]>([]);

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, getValues, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        contentGroupId: yup.number().required('Content group'),
      }),
    ),
  });


  const { handleGetContentGroups, isLoading: isGetContentGroupsLoading = false } = useGetContentGroups({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentGroups }) => {

      setDefaultValues({
        contentGroupId: '',
        search: '',
      });
      setAllContentGroups(contentGroups);

      const formattedContentGroups = contentGroups.map(({ id, title }) => ({
          id,
          value: id,
          name: String(title),
        }));
      setContentGroupOptions(formattedContentGroups);

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleSave = (item: FormFieldProps) => {
    const { contentGroupId } = item;
    const selectedContentGroup = allContentGroups.find(({ id }) => id === contentGroupId);
    if (selectedContentGroup) onSave({ contentGroup: selectedContentGroup });
    reset();
  };

  const handleSearch = () => {
    const { search } = getValues();
    if (search === '') {
      setContentGroupOptions(allContentGroups.map(({ id, title }) => ({
        id: id,
        value: id,
        name: title,
      })));
      return;
    }

    const filteredOptions = allContentGroups.filter(({ title }) => title.toLowerCase().includes(String(search).toLowerCase()));

    setContentGroupOptions(filteredOptions.map(({ id, title }) => ({
      id: id,
      value: id,
      name: title,
    })));
  };



  useEffect(() => {
    if (!projectId) return;
    handleGetContentGroups({
      projectId: String(projectId),
      type: [contentGroupType],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentGroupType, projectId]);


  return (
    <Wrapper additionalBreadcrumbs={additionalBreadcrumbs} hasContainer={hasContainer}>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <Spinner isLoading={isGetContentGroupsLoading} />
        <Spacing size="small">
          <Controller
            control={control}
            name="search"
            render={({ field }) => (
              <SearchFilter
                id="id_search_library"
                isDisabled={false}
                labelText="Library filter"
                placeholder="Search your library"
                type="text"
                isFullWidth
                isLabelHidden
                onClear={() => {
                  setValue('search', '')
                  handleSearch();
                }}
                onSearch={handleSearch}
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control}
            name="contentGroupId"
            render={({ field, fieldState }) => (
              <Select
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_event_type"
                labelText={`Select ${capitalize(contentGroupType.toLowerCase())} asset`}
                options={contentGroupOptions}
                isLabelHidden
                {...field}
              />
            )}
          />
        </Spacing>
        <SidebarButtons
          onCancel={onCancel}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </Wrapper>
  );
};

export { ChooseContentGroupFromLibrary };
