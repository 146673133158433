import { gql } from '@apollo/client';

export const FRAGMENT_TEXT = gql`
  fragment text on ContentSnippetTextType {
    text
    fontColor
    linkColor
    listIconColor
  }
`;
