import { gql } from '@apollo/client';

const UPDATE_GLOSSARY_ITEM_MUTATION = gql`
  mutation CreateGlossaryItem($request: UpdateTextTooltipInputType!) {
    textTooltip {
      update(request: $request) {
        id
        description
        title
      }
    }
  }
`;

export { UPDATE_GLOSSARY_ITEM_MUTATION };
