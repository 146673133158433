import { gql } from '@apollo/client';

const UPDATE_TAG_MUTATION = gql`
  mutation CreateTag($value: String!, $tagId: Int!) {
    tagMutation {
      editTag(value: $value, tagId: $tagId)
    }
  }
`;

export { UPDATE_TAG_MUTATION };
