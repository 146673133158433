import React, { useContext, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ControlledForm, Dialog, FormFieldProps, SideBarTabSet, Spinner, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { AssetTagsTab, UpsertPageGeneralTab } from 'components';
import { ContentBuilderContext } from 'context';
import { useUpdatePageStatus, useGetContentPageDetails, useToast, useUpsertAssetWrapper, useUpsertPage } from 'hooks';
import { FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getPageCommonVariables, getPageDefaultValues } from './PageSidebarView.helpers';



const PageSidebarView = () => {
  const { projectId, isPageSidebarOpen, pageSidebarDetails, handleDeletePage, handleUpdatePage, handleCreatePage, closePageSidebar } = useContext(ContentBuilderContext);
  const { handleToastError } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { pageId, sort, contentGroupId } = pageSidebarDetails ?? {};

  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        url: yup.string().label('Url').required(),
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetContentPageDetails, isLoading: isGetContentPageDetailsLoading = false } = useGetContentPageDetails({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentPage }) => {

      setDefaultValues(getPageDefaultValues({ page: contentPage }));

    },
    onError: handleGetError,
  });

  const { handleUpsertPage, isLoading: isUpsertPageLoading = false } = useUpsertPage({
    onCreate: (returnedContentPage) => {
      reset();
      closePageSidebar();
      handleCreatePage(returnedContentPage);
    },
    onUpdate: (returnedContentPage) => {
      reset();
      closePageSidebar();
      handleUpdatePage(returnedContentPage);
    }
  });

  const { handleUpdatePageStatus, isLoading: isUpdatePageStatusLoading = false } = useUpdatePageStatus({
    onCompleted: () => {
      closePageSidebar();
      setIsDeleteDialogOpen(false);
      handleDeletePage();
    },
    onError: handleGetError,
  });

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const handleDelete = () => {
    if (!pageId) return;
    handleUpdatePageStatus({
      contentPageId: Number(pageId),
      status: 'DELETED',
    });
  };

  const handleSave = (item: FormFieldProps) => {

    const { assetId } = item;

    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: droppedFileAssetRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && assetId ? assetId : undefined,
      callBack: (asset?: IDBAsset) => {
        const commonVariables = getPageCommonVariables({
          uploadedAssetId: asset ? String(asset.assetId): undefined,
          sort: Number(sort ?? 0),
          item,
        });

        handleUpsertPage({
          contentPageId: pageId,
          contentGroupId,
          variables: commonVariables,
        });
      }
    });
  };


  useEffect(() => {
    if (!pageId) {
      setDefaultValues(getPageDefaultValues({}));
    } else {
      void handleGetContentPageDetails({
        contentPageId: pageId
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, pageSidebarDetails]);

  const isLoading = isUpsertAssetLoading || isUpsertPageLoading || isGetContentPageDetailsLoading || isUpdatePageStatusLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >


      <SideBarTabSet
        ref={tabsetRef}
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={() => {
          reset();
          closePageSidebar();
        }}
        hasViewPadding={false}
        isSideBarOpen={isPageSidebarOpen}
        tabs={[
          {
            iconId: 'id_general_tab_icon',
            id: 'id_general_tab',
            label: 'General',
            view: () => isPageSidebarOpen ? (
              <UpsertPageGeneralTab
                control={control}
                setValue={setValue}
                onDeleteAsset={handleRemoveAsset}
                onDrop={handleDropFile}
              />
            ) : <></>,
          },
          {
            iconId: 'id_related_tab_icon',
            id: 'id_tag_tab',
            label: 'Tags',
            view: () =>
              isPageSidebarOpen ? <AssetTagsTab
                initialTagList={defaultValues.tagList ?? []}
                projectId={String(projectId)}
                onUpdateSelectedTags={(tags: IDBAsset['tagList']) => setValue('tagList', tags)}
              />: <></>,
          },
        ]}
        onDelete={pageId ? () => setIsDeleteDialogOpen(true): undefined }
        onSaveButtonType="submit"
      />
      </ControlledForm>

      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete page?`}
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};


export { PageSidebarView };
