import { gql } from '@apollo/client';

const DELETE_CONTENT_PAGE = gql`
  mutation updatePageStatus($contentPage: UpdateContentPageStatusInputType!) {
    contentPage {
      updateContentPageStatus(contentPage: $contentPage) {
        id
      }
    }
  }
`;


export { DELETE_CONTENT_PAGE };
