import { gql } from '@apollo/client';

const GET_ANNOUNCES = gql`
  query getAnnounces($after: String, $first: Int, $projectId: String!, $filter: String) {
    announce {
      byProject(after: $after, first: $first, projectId: $projectId, filter: $filter) {
        edges {
          cursor
          node {
            end
            id
            isActive
            start
            content
            location
            title
          }
        }
      }
    }
  }
`;

export { GET_ANNOUNCES };
