import { gql } from '@apollo/client';

const CREATE_SECTION_GROUP = gql`
  mutation createContentSectionGroup($contentPageId: Int!, $sort: Int!) {
    sectionGroup {
      add(contentPageId: $contentPageId, sort: $sort) {
        id
        visible
        contentSections {
          id
        }
      }
    }
  }
`;

export { CREATE_SECTION_GROUP };
