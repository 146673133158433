import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_ASSET_CAPTION_MUTATION } from './useCreateAssetCaption.graphql';
import {
  ICreateAssetCaptionMutationGraphQLResponse,
  ICreateAssetCaptionMutationVariables,
  IHandleCreateAssetCaptionParams,
  IUseCreateAssetCaptionOptions,
  IUseCreateAssetCaption,
} from './useCreateAssetCaption.interfaces';

const useCreateAssetCaption = (options?: IUseCreateAssetCaptionOptions): IUseCreateAssetCaption => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateAssetCaptionOptions);

  const [executeCreateAssetCaption, { loading: isLoading }] = useEkardoMutation<
    ICreateAssetCaptionMutationGraphQLResponse,
    ICreateAssetCaptionMutationVariables
  >({
    mutation: mutation ?? CREATE_ASSET_CAPTION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          caption: { add: assetCaptionConnection },
        } = data;

        void onCompleted(assetCaptionConnection);
      },
      onError,
    },
    token,
  });

  const handleCreateAssetCaption = ({ assetId, from, text, to }: IHandleCreateAssetCaptionParams) => {
    void executeCreateAssetCaption({
      variables: {
        assetId,
        from,
        text,
        to,
      },
    });
  };

  return {
    handleCreateAssetCaption,
    isLoading,
  };
};

export { useCreateAssetCaption };
