import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_CONTAINER_DETAILS } from './useGetContainerDetails.graphql';
import {
  IGetContainerDetailsQueryGraphQLResponse,
  IGetContainerDetailsQueryVariables,
  IHandleGetContainerDetailsParams,
  IUseGetContainerDetails,
  IUseGetContainerDetailsOptions,
} from './useGetContainerDetails.interfaces';

const useGetContainerDetails = (options?: IUseGetContainerDetailsOptions): IUseGetContainerDetails => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetContainerDetailsOptions);

  const [executeGetContainerDetails, { loading: isLoading }] = useEkardoLazyQuery<
  IGetContainerDetailsQueryGraphQLResponse,
  IGetContainerDetailsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          container: { get: container},
        } = data;

        void onCompleted({
          container,
        });
      },
      onError,
    },
    query: query ?? GET_CONTENT_CONTAINER_DETAILS,
    token,
  });

  const handleGetContainerDetails = ({ containerId }: IHandleGetContainerDetailsParams) => {
    void executeGetContainerDetails({
      variables: {
        containerId,
      },
    });
  };

  return {
    handleGetContainerDetails,
    isLoading,
  };
};

export { useGetContainerDetails };
