import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_SECTION } from './useCreateSection.graphql';
import {
  ICreateSectionMutationGraphQLResponse,
  ICreateSectionMutationVariables,
  IUseCreateSectionOptions,
  IUseCreateSection,
  ICreateSectionMutationParams,
} from './useCreateSection.interfaces';

const useCreateSection = (options?: IUseCreateSectionOptions): IUseCreateSection => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateSectionOptions);

  const [executeCreateSection, { loading: isLoading }] = useEkardoMutation<ICreateSectionMutationGraphQLResponse, ICreateSectionMutationVariables>({
    mutation: mutation ?? CREATE_SECTION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { createSection: section },
        } = data;

        onCompleted({
          section,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateSection = async ({ contentSection }: ICreateSectionMutationParams) => {
    await executeCreateSection({
      variables: {
        contentSection,
      }
    });
  };

  return {
    handleCreateSection,
    isLoading,
  };
};

export { useCreateSection };
