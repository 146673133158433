import React from 'react';

import { BorderedCircle, FlexContainer, Icon, Popover, PopoverContent } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IProgressTrackingSubTableData } from './ProgressTrackingSubTable.interfaces';

import { getFriendlyStatus, getUserFlowStatusVariant, getVariantByDates } from '../../ProgressTrackingPage.helpers';



const PROGRESS_TRACKING_SUB_TABLE_COLUMNS: ReadonlyArray<Column<IProgressTrackingSubTableData>> = [
  {
    accessor: 'title',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>User flow title</div>,
    width: '20%',
  },
  {
    accessor: 'progress',
    Cell: ({ value = [] }) => (
      <FlexContainer gap="x-small">
        {value.map((item, index) => {
          const {status, userFlowStepId, name, trackings } = item;

          return (
            <Popover
              key={userFlowStepId}
              trigger={
                <BorderedCircle
                  value={String(index + 1)}
                  variant={getUserFlowStatusVariant(status)}
                />
              }
            >
              <PopoverContent title={name}>
                {trackings.length === 0 ? (
                  <dl className="c-progress-tracker-details">
                    <Icon className={`c-progress-tracker-details__icon c-progress-tracker-details__icon--purple`} id="id_calendar_icon"/>
                    <div className="c-progress-tracker__item">
                      <dt>Started</dt>
                      <dd>{getFriendlyStatus('AVAILABLE')}</dd>
                    </div>
                  </dl>
                ): (
                  <>
                    {
                      trackings.map((tracking) => {
                        const {started, completed, id} = tracking;
                        return (
                          <dl key={id} className="c-progress-tracker-details">
                            <Icon className={`c-progress-tracker-details__icon c-progress-tracker-details__icon--${getVariantByDates({ started, completed })}`} id="id_calendar_icon"/>
                            <div className="c-progress-tracker__item">
                              <dt>Started</dt>
                              <dd>{started ? started : getFriendlyStatus('AVAILABLE') }</dd>
                            </div>
                            {
                              started && (
                                <>
                                  <span>-</span>
                                  <div className="c-progress-tracker__item">
                                    <dt>Completed</dt>
                                    <dd>{completed ? completed : getFriendlyStatus('IN_PROGRESS')}</dd>
                                  </div>
                                </>
                              )
                            }
                          </dl>
                        );
                      })
                    }
                  </>
                )}
              </PopoverContent>
            </Popover>
          );
        })}
    </FlexContainer>
    ),
    Header: () => <div>User flow step</div>,
    width: '80%',
  },
];



export { PROGRESS_TRACKING_SUB_TABLE_COLUMNS };
