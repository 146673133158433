// TODO: Fix this linting error
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ChangeEvent, useState, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { Button, Dialog, Input, Spacing, Spinner, DatePicker, SidebarButtons } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useToast, useCreateDiscount, useUpdateDiscount, useDeleteDiscount } from 'hooks';
import { IDBDiscount } from 'interfaces';

import { DiscountSidebarGeneralViewProps } from './DiscountSidebarGeneralView.interfaces';

const DiscountSidebarGeneralView = ({ onCreated, onDeleted, onUpdated, selectedDiscount }: DiscountSidebarGeneralViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const { handleToastError } = useToast();

  const token = getAccessTokenCookie();

  const { project } = useContext(CachingEntitiesContext);
  const today = new Date();



  const [currentDiscount, setCurrentDiscount] = useState<IDBDiscount>(
    selectedDiscount ??
      ({
        id: 0,
        description: '',
        code: '',
        percentage: '',
        maxUsage: 0,
        startsAtUtc: new Date(),
        expiresAtUtc: new Date(today.setMonth(today.getMonth() + 1)),
        status: 'ACTIVE',
      } as IDBDiscount),
  );

  const {
    id: discountId,
    description: discountTitle = '',
    code: discountCode = '',
    percentage: discountPercentage = '',
    maxUsage: discountMaxUsage = 0,
    startsAtUtc: discountStartsAtUtc,
    expiresAtUtc: discountExpiresAtUtc,
  } = currentDiscount;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [discountStartDate, setDiscountStartDate] = useState<Date>(discountStartsAtUtc);
  const [discountEndDate, setDiscountEndDate] = useState<Date>(discountExpiresAtUtc);

  const { handleCreateDiscount: executeCreateDiscount, isLoading: isCreateDiscountLoading = false } = useCreateDiscount({
    onCompleted: ({ discountsConnection }) => {
      const { code, description, maxUsage, percentage, projectGuid, startsAtUtc, expiresAtUtc } = discountsConnection;

      onCreated({
        code,
        description,
        maxUsage,
        percentage,
        projectGuid,
        startsAtUtc,
        expiresAtUtc,
        status: 'ACTIVE',
      } as IDBDiscount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const { handleDeleteDiscount: executeDeleteDiscount, isLoading: isDeleteDiscountLoading = false } = useDeleteDiscount({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);

      onDeleted(currentDiscount.id);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateDiscount: executeUpdateDiscount, isLoading: isUpdateDiscountLoading } = useUpdateDiscount({
    onCompleted: ({ discountsConnection }) => {
      const { id, code, description, expiresAtUtc, startsAtUtc, status, percentage, maxUsage } = discountsConnection;

      onUpdated({
        id,
        code,
        percentage,
        description,
        maxUsage,
        expiresAtUtc,
        startsAtUtc,
        status,
      } as IDBDiscount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleChangeStartDate = (date: Date | null ) => {
    if (date) {
      setDiscountStartDate(date);

      setCurrentDiscount(
        (currentState) =>
          ({
            ...currentState,
            startsAtUtc: date,
          } as IDBDiscount),
      );
    }
  };

  const handleChangeExpiryDate = (date: Date | null) => {
    if (date) {
      setDiscountEndDate(date);

      setCurrentDiscount(
        (currentState) =>
          ({
            ...currentState,
            expiresAtUtc: date,
          } as IDBDiscount),
      );
    }
  };

  const handleUpdateDiscountInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentDiscount(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBDiscount),
    );
  };

  const handleCreateDiscount = () => {
    void executeCreateDiscount({
      projectGuid: String(project?.id),
      description: discountTitle,
      code: discountCode,
      percentage: discountPercentage,
      maxUsage: Number(discountMaxUsage),
      period: {
        startsAtUtc: discountStartsAtUtc,
        expiresAtUtc: discountExpiresAtUtc,
      },
    });
  };

  const handleUpdateDiscount = () => {
    void executeUpdateDiscount({
      id: discountId,
      description: discountTitle,
      code: discountCode,
      percentage: String(discountPercentage),
      maxUsage: discountMaxUsage,
      period: {
        startsAtUtc: discountStartsAtUtc,
        expiresAtUtc: discountExpiresAtUtc,
      },
    });
  };

  const isLoading = isCreateDiscountLoading || isDeleteDiscountLoading || isUpdateDiscountLoading;

  return (
    <div className="c-discount-sidebar-general-view">
      {isLoading && <Spinner isLoading={isLoading} />}

      <Spacing size="large">
        <Input id="title" labelText="Title" name="description" tooltipText="Discount title" type="text" value={discountTitle} onChange={handleUpdateDiscountInput} />
      </Spacing>

      <Spacing size="large">
        <Input id="code" labelText="Discount code" name="code" tooltipText="Discount code" type="text" value={discountCode} onChange={handleUpdateDiscountInput} />
      </Spacing>

      <Spacing size="large">
        <Input
          id="percentage"
          labelText="Percentage discount"
          name="percentage"
          tooltipText="Discount percentage"
          type="number"
          value={discountPercentage}
          onChange={handleUpdateDiscountInput}
        />
      </Spacing>

      <Spacing size="large">
        <Input
          id="maxUsage"
          labelText="Discount max usage"
          name="maxUsage"
          tooltipText="Discount max usage"
          type="number"
          value={discountMaxUsage}
          onChange={handleUpdateDiscountInput}
        />
      </Spacing>

      <Spacing size="large">
        <DatePicker
          id="startDate"
          labelText="Start date"
          maxDate={new Date(2030, 0, 1)}
          minDate={new Date()}
          selectedDate={new Date(discountStartDate)}
          tooltipText="Discount start date"
          onSingleDateChangeHandler={handleChangeStartDate}
        />
      </Spacing>

      <Spacing size="large">
        <DatePicker
          id="endDate"
          labelText="End date"
          maxDate={new Date(2030, 0, 1)}
          minDate={new Date()}
          selectedDate={new Date(discountEndDate)}
          tooltipText="Discount expiry date"
          onSingleDateChangeHandler={handleChangeExpiryDate}
        />
      </Spacing>

      {selectedDiscount && (
        <Spacing>
          <span className="c-image-sidebar-button-label">Deactivate discount</span>
          <Button text="Deactivate" type="button" variant="danger--tertiary" onClick={() => setIsDeleteDialogOpen(true)} />

          <Dialog
            isOpen={isDeleteDialogOpen}
            title={`Deactivate discount: ${discountTitle}`}
            onCancel={() => setIsDeleteDialogOpen(false)}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={() => {
              if (!selectedDiscount.id) {
                return;
              }

              executeDeleteDiscount({
                id: discountId,
              });
            }}
          />
        </Spacing>
      )}

      <SidebarButtons
        deleteButtonText="Deactivate"
        onDelete={selectedDiscount ? () => setIsDeleteDialogOpen(true): undefined}
        onSave={selectedDiscount ? handleUpdateDiscount : handleCreateDiscount}
        onSaveButtonType="button"
      />
    </div>
  );
};

export { DiscountSidebarGeneralView };
