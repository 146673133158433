import React from 'react';

import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IUsersTableData } from './UsersPage.interfaces';



const USER_PAGE_SIZE = 10;

const USERS_TABLE_COLUMNS: ReadonlyArray<Column<IUsersTableData>> = [
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>First name</div>,
    width: '15%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Last name</div>,
    width: '15%',
  },
  {
    accessor: 'displayedName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Display name</div>,
    width: '25%',
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Email</div>,
    width: '35%',
  },
  {
    accessor: 'postsCount',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Posts</div>,
    width: '5%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="User posts" onClick={() => onClick(id)} />,
    Header: () => <div className="h-hide-visually">Settings</div>,
    width: '5%',
  },
];

const USERS_TABLE_COLUMNS_WITH_COMMUNITIES: ReadonlyArray<Column<IUsersTableData>> = [
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>First name</div>,
    width: '15%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Last name</div>,
    width: '15%',
  },
  {
    accessor: 'displayedName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Display name</div>,
    width: '20%',
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Email</div>,
    width: '25%',
  },
  {
    accessor: 'communities',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Communities</div>,
    width: '15%',
  },
  {
    accessor: 'postsCount',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Posts</div>,
    width: '5%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="User posts" onClick={() => onClick(id)} />,
    Header: () => <div className="h-hide-visually">Settings</div>,
    width: '5%',
  },
];

export { USERS_TABLE_COLUMNS, USER_PAGE_SIZE, USERS_TABLE_COLUMNS_WITH_COMMUNITIES };
