import { gql } from '@apollo/client';

const GET_REPORTABLE_USER_FLOW = gql`
  query ($projectId: String!) {
    userFlow {
      getReportableUserFlows(projectId: $projectId) {
        id
        title
      }
    }
  }
`;

export { GET_REPORTABLE_USER_FLOW };
