import { gql } from '@apollo/client';

const CREATE_SLIDER_CONFIGURATION = gql`
  mutation createSliderQuesConfigRequest(
    $projectId: String!
    $description: String!
    $defaultValue: Int!
    $maxValue: Int!
    $minValue: Int!
    $step: Int!
    $ticks: [QuestionConfigurationSliderTickInputType]
    $title: String!
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationSlider: {
          defaultValue: $defaultValue
          maxValue: $maxValue
          minValue: $minValue
          step: $step
          ticks: $ticks
          title: $title
        }
      ) {
        id
      }
    }
  }
`;


export { CREATE_SLIDER_CONFIGURATION };
