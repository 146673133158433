import { IContentGroup } from "@netfront/ekardo-content-library"
import { FormFieldProps } from "@netfront/ui-library"

const getFormDefaultValues = (contentGroup?: IContentGroup): FormFieldProps => {
  const {
    title = '',
    description = '',
    status = 'PUBLISHED',
    id,
    formType,
    sort,
    url,
    // hasQuestionNumbers = false,
  } = contentGroup ?? {};
  return {
    id,
    title,
    description,
    status,
    sort,
    url,
    formType: formType ?? 'LIST',
    isActivated: status === 'PUBLISHED',
    hasQuestionNumbers: false,
  };
};

export { getFormDefaultValues };
