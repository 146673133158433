import { IDiscountsTableDataParams, IDiscountsTableData } from './DiscountsPage.interfaces';

const getDiscountsTableData = ({
  discounts,
  onSettingsButtonClick,
  onCopyButtonClick,
}: IDiscountsTableDataParams): IDiscountsTableData[] => {
  return discounts.map(({ id, code, description, startsAtUtc, expiresAtUtc, status }): IDiscountsTableData => {
    return {
      code,
      copyCodeData: {
        code,
        onClick: onCopyButtonClick,
      },
      description,
      id,
      expiryDate: new Date(expiresAtUtc).toLocaleDateString(),
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
      startDate: new Date(startsAtUtc).toLocaleDateString(),
      status,
    };
  });
};

export { getDiscountsTableData };
