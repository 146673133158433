import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CONDITION } from './useDeleteCondition.graphql';
import { IDeleteConditionMutationGraphQLResponse, IDeleteConditionMutationVariables, IHandleDeleteConditionParams, IUseDeleteCondition, IUseDeleteConditionOptions } from './useDeleteCondition.interfaces';

const useDeleteCondition = (options?: IUseDeleteConditionOptions): IUseDeleteCondition => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteConditionOptions);

  const [executeDeleteCondition, { loading: isLoading }] = useEkardoMutation<IDeleteConditionMutationGraphQLResponse, IDeleteConditionMutationVariables>({
    mutation: mutation ?? DELETE_CONDITION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { removeCondition: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteCondition = ({ questionConditionId }: IHandleDeleteConditionParams) => {
    void executeDeleteCondition({
      variables: {
        questionConditionId,
      },
    });
  };

  return {
    handleDeleteCondition,
    isLoading,
  };
};

export { useDeleteCondition };
