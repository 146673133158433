import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_CONTENT_GROUP } from './useCreateContentGroup.graphql';
import {
  ICreateContentGroupMutationGraphQLResponse,
  ICreateContentGroupMutationVariables,
  IHandleCreateContentGroupParams,
  IUseCreateContentGroupOptions,
  IUseCreateContentGroup,
} from './useCreateContentGroup.interfaces';

const useCreateContentGroup = (options?: IUseCreateContentGroupOptions): IUseCreateContentGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateContentGroupOptions);

  const [executeCreateContentGroup, { loading: isLoading }] = useEkardoMutation<
  ICreateContentGroupMutationGraphQLResponse,
  ICreateContentGroupMutationVariables
  >({
    mutation: mutation ?? CREATE_CONTENT_GROUP,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: {
            createContentGroup: contentGroup,
          },
        } = data;

        void onCompleted({contentGroup});
      },
      onError,
    },
    token,
  });

  const handleCreateContentGroup = ({
    contentGroup,
  }: IHandleCreateContentGroupParams) => {
    void executeCreateContentGroup({
      variables: {
        contentGroup,
      },
    });
  };

  return {
    handleCreateContentGroup,
    isLoading,
  };
};

export { useCreateContentGroup };
