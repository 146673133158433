import { DatePicker, InformationBox, Spacing, ToggleSwitch } from '@netfront/ui-library';

import { IScheduleViewProps } from './ScheduleSidebarView.interfaces';

export const ScheduleSidebarView = ({ isSchedule, date, onDateUpdate, onIsScheduleUpdate, isReadOnly }: IScheduleViewProps) => {
  return (
    <>
      <Spacing size="small">
        <ToggleSwitch
          id="schedule"
          isChecked={isSchedule}
          isDisabled={isReadOnly}
          labelText="Schedule"
          onChange={() => onIsScheduleUpdate(!isSchedule)}
        />
      </Spacing>
      {isSchedule && (
        <Spacing size="x-large">
          <DatePicker
            id="selectDate"
            labelText="Select date"
            maxDate={new Date()}
            minDate={new Date()}
            selectedDate={date}
            isRequired
            onSingleDateChangeHandler={(e: Date | null) => {
              if (!isReadOnly && e) {
                onDateUpdate(e);
              }
            }}
          />
        </Spacing>
      )}
      {!isSchedule && (
        <Spacing>
          <InformationBox message="The notifications will be sent straight away." />
        </Spacing>
      )}
    </>
  );
};
