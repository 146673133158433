import { useBonoboMutation } from '../useBonoboMutation';

import { ADD_USER_IN_COMMUNITY_MUTATION } from './useAddUserInCommunity.graphql';
import {
  IAddUserInCommunityMutationGraphQLResponse,
  IAddUserInCommunityMutationVariables,
  IHandleAddUserInCommunityParams,
  IUseAddUserInCommunityOptions,
  IUseAddUserInCommunity,
} from './useAddUserInCommunity.interfaces';

const useAddUserInCommunity = (options?: IUseAddUserInCommunityOptions): IUseAddUserInCommunity => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseAddUserInCommunityOptions);

  const [executeAddUserInCommunity, { loading: isLoading }] = useBonoboMutation<
    IAddUserInCommunityMutationGraphQLResponse,
    IAddUserInCommunityMutationVariables
  >({
    mutation: mutation ?? ADD_USER_IN_COMMUNITY_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          communityConnection: { addUserInCommunity: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleAddUserInCommunity = ({ communityId, role, userId }: IHandleAddUserInCommunityParams) => {
    void executeAddUserInCommunity({
      variables: {
        communityId,
        role,
        userId,
      },
    });
  };

  return {
    handleAddUserInCommunity,
    isLoading,
  };
};

export { useAddUserInCommunity };
