import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_GLOSSARY_ITEM_MUTATION } from './useUpdateGlossaryItem.graphql';
import {
  IUpdateGlossaryItemMutationGraphQLResponse,
  IUpdateGlossaryItemMutationVariables,
  IHandleUpdateGlossaryItemParams,
  IUseUpdateGlossaryItemOptions,
  IUseUpdateGlossaryItem,
} from './useUpdateGlossaryItem.interfaces';

const useUpdateGlossaryItem = (options?: IUseUpdateGlossaryItemOptions): IUseUpdateGlossaryItem => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateGlossaryItemOptions);

  const [executeUpdateGlossaryItem, { loading: isLoading }] = useEkardoMutation<
  IUpdateGlossaryItemMutationGraphQLResponse,
  IUpdateGlossaryItemMutationVariables
  >({
    mutation: mutation ?? UPDATE_GLOSSARY_ITEM_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          update
        } = data;

        void onCompleted(update);
      },
      onError,
    },
    token,
  });

  const handleUpdateGlossaryItem = ({
    id,
    description,
    title,
  }: IHandleUpdateGlossaryItemParams) => {
    void executeUpdateGlossaryItem({
      variables: {
        request: {
          id,
          description,
          title
        },
      },
    });
  };

  return {
    handleUpdateGlossaryItem,
    isLoading,
  };
};

export { useUpdateGlossaryItem };
