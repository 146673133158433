import { useEffect, useState } from 'react';

import { Input, Spacing, Spinner, Textarea, ToggleSwitch, DatePicker, SidebarButtons} from '@netfront/ui-library';
import { IGetAnnounceOnCompletedResponse, useAddAnnounce, useDeleteAnnounce, useGetAnnounce, useToast, useUpdateAnnounce } from 'hooks';
import { IDBAnnounce } from 'interfaces';

import { IAnnounceSidebarViewProps } from './AnnounceSidebarView.interfaces';

export const AnnounceSidebarView = ({ id, projectId, onClose }: IAnnounceSidebarViewProps) => {
  const { handleToastSuccess } = useToast();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [announce, setAnnounce] = useState<Omit<IDBAnnounce, 'id'>>({
    content: '',
    isActive: true,
    location: '',
    title: '',
  });

  const onGetAnnounceCompleted = (data: IGetAnnounceOnCompletedResponse) => {
    setAnnounce(data.announce);
    setIsLoaded(true);
  };

  const handleUpdate = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLOutputElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setAnnounce((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  };

  const onDeleteClick = () => {
    void handleDeleteAnnounce({
      id: Number(id),
    });
  };

  const onSaveClick = () => {
    if (id) {
      void handleUpdateAnnounce({
        ...announce,
        id: id,
      });
    } else {
      void handleAddAnnounce({
        ...announce,
        projectId,
      });
    }
  };
  const { handleDeleteAnnounce, isLoading: isDeleteAnnounceLoading = false } = useDeleteAnnounce({
    onCompleted() {
      onClose();
    },
  });

  const { handleUpdateAnnounce, isLoading: isUpdateAnnounceLoading = false } = useUpdateAnnounce({
    onCompleted() {
      handleToastSuccess({ message: 'Announce updated successfully' });
      onClose();
    },
  });
  const { handleAddAnnounce, isLoading: isAddAnnounceLoading = false } = useAddAnnounce({
    onCompleted() {
      handleToastSuccess({ message: 'Announce created successfully' });
      onClose();
    },
  });

  const { handleGetAnnounce, isLoading: isGetAnnounceLoading = false } = useGetAnnounce({ onCompleted: onGetAnnounceCompleted });

  const isLoading = isGetAnnounceLoading || isUpdateAnnounceLoading || isAddAnnounceLoading || isDeleteAnnounceLoading;

  useEffect(() => {
    if (!id) {
      setIsLoaded(true);
      return;
    }
    void handleGetAnnounce({ id: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form>
      {isLoading && <Spinner isLoading={isLoading} />}
      {isLoaded && (
        <>
          <Spacing size="large">
            <Input id="title" labelText="Title" name="title" type="text" value={announce.title} onChange={handleUpdate} />
          </Spacing>
          <Spacing size="large">
            <Input id="location" labelText="Location" name="location" tooltipText="Provide the page location to prompt the announcement. i.e. use 'home' to make an announcement on the home page" type="text" value={announce.location} onChange={handleUpdate} />
          </Spacing>
          <Spacing size="large">
            <Textarea id="content" labelText="Content" name="content" value={announce.content} onChange={handleUpdate} />
          </Spacing>
          <Spacing size="large">
            <DatePicker
              id="start"
              labelText="Start"
              maxDate={new Date(2030, 0, 1)}
              minDate={new Date(2020, 0, 1)}
              selectedDate={announce.start ? new Date(announce.start) : undefined}
              onSingleDateChangeHandler={(e: Date | null) => {
                if (e)
                  setAnnounce((currentState) => ({
                    ...currentState,
                    start: e,
                  }));
              }}
            />
          </Spacing>
          <Spacing size="large">
            <DatePicker
              id="end"
              labelText="end"
              maxDate={new Date(2030, 0, 1)}
              minDate={new Date(2020, 0, 1)}
              selectedDate={announce.end ? new Date(announce.end) : undefined}
              onSingleDateChangeHandler={(e: Date | null) => {
                if (e)
                  setAnnounce((currentState) => ({
                    ...currentState,
                    end: e,
                  }));
              }}
            />
          </Spacing>
          <Spacing size="large">
            <ToggleSwitch
              id="isChecked"
              isChecked={announce.isActive}
              labelText="Is active"
              onChange={() => {
                setAnnounce((currentState) => ({
                  ...currentState,
                  isActive: !announce.isActive,
                }));
              }}
            />
          </Spacing>
          <SidebarButtons
            submitButtonText={id ? 'Update' : 'Save'}
            onDelete={id ? onDeleteClick: undefined}
            onSave={onSaveClick}
            onSaveButtonType="button"
          />
        </>
      )}
    </form>
  );
};
