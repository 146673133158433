import { useVervetMutation } from '../useVervetMutation';

import { CREATE_DISCOUNT_MUTATION } from './useCreateDiscount.graphql';
import {
  ICreateDiscountMutationGraphQLResponse,
  ICreateDiscountMutationVariables,
  IHandleCreateDiscountParams,
  IUseCreateDiscountOptions,
  IUseCreateDiscount,
} from './useCreateDiscount.interfaces';

const useCreateDiscount = (options?: IUseCreateDiscountOptions): IUseCreateDiscount => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateDiscountOptions);

  const [executeCreateDiscount, { loading: isLoading }] = useVervetMutation<
    ICreateDiscountMutationGraphQLResponse,
    ICreateDiscountMutationVariables
  >({
    mutation: mutation ?? CREATE_DISCOUNT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          discounts: { create: discountsConnection },
        } = data;

        onCompleted({
          discountsConnection,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateDiscount = async ({ code, description, percentage, period, maxUsage, projectGuid }: IHandleCreateDiscountParams) => {
    await executeCreateDiscount({
      variables: {
        code,
        description,
        percentage,
        period,
        maxUsage,
        projectGuid,
      },
    });
  };

  return {
    handleCreateDiscount,
    isLoading,
  };
};

export { useCreateDiscount };
