import { IAssetTag } from "@netfront/common-library";
import { IContentPage } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";

const getPageDefaultValues = ({ page } : { page?: IContentPage; }): FormFieldProps => {
  const {
    seoDescription,
    title,
    url,
    status,
    tags,
    asset,
    // sensitiveContent: isSensitive,
  } = page ?? {};

  const { assetId, presignedUrl} = asset ?? {};

  return {
    title: title ?? 'Page',
    seoDescription: seoDescription ?? '',
    url: url ?? '',
    presignedUrl: presignedUrl ?? '',
    tags: tags ?? [],
    status: status ?? 'PUBLISHED',
    assetId: assetId ?? '',
    isSensitive: false,
  }
};

const getPageCommonVariables = ({item, uploadedAssetId, sort }: { item: FormFieldProps; sort?: number; uploadedAssetId?: string; }) => {

  const {
    title = 'Page',
    assetId,
    seoDescription,
    status,
    tags = [],
    url,
    isSensitive = false,
  } = item;


  const sharedVariables = {
    title,
    assetId: uploadedAssetId ?? assetId,
    seoDescription,
    sensitiveContent: isSensitive,
    sort,
    url,
    status,
    tags: (tags as IAssetTag[]).map(({ id }) => Number(id)),
  };

  return sharedVariables;

}




export { getPageDefaultValues, getPageCommonVariables };
