import { DBContentSnippetTypeNameType } from "@netfront/ekardo-content-library";

import { SnippetTypeConst, underScoredToResponseTypeConst } from '../../../../../constants';

const getSnippetType = (type: string): DBContentSnippetTypeNameType => {
  if (Object.values(underScoredToResponseTypeConst).includes(type)) return SnippetTypeConst.QUESTION;
  return type as DBContentSnippetTypeNameType;
};


export { getSnippetType };
