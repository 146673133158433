import React from 'react';

import { ContentBuilderUpsertAsset } from 'components';


import { UpsertAssetSnippetGeneralTabProps } from './UpsertAssetSnippetGeneralTab.interfaces';


const UpsertAssetSnippetGeneralTab = ({
  assetType,
  control,
  onDrop,
  onChooseFromLibrary,
  initialView = 'selector',
  isCreate = false,
  projectId,
  defaultValues,
  setValue,
}: UpsertAssetSnippetGeneralTabProps) => {
  return (
    <ContentBuilderUpsertAsset
      assetType={assetType}
      control={control}
      defaultValues={defaultValues}
      hasEmbedOption={['audio', 'video'].includes(assetType)}
      initialView={initialView}
      isCreate={isCreate}
      projectId={projectId}
      setValue={setValue}
      onChooseFromLibrary={onChooseFromLibrary}
      onDrop={onDrop}
    />
  );
};

export { UpsertAssetSnippetGeneralTab };
