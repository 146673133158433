import { createContext } from 'react';

import { IDashboardItem, IDashboardItemsList } from './DashboardContext.interfaces';

export interface IDashboardContext {
  dashboardItems: IDashboardItemsList[];
  dashboardLink: string | undefined;
  getDashboardItemsById: (id: string) => IDashboardItemsList[] | IDashboardItem[];
  isLoading: boolean;
}

export const DashboardContext = createContext<IDashboardContext>({
  dashboardItems: [],
  getDashboardItemsById: () => [],
  dashboardLink: undefined,
  isLoading: true,
});

// eslint-disable-next-line import/no-default-export
export default DashboardContext;
