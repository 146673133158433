/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBAsset } from "@netfront/common-library";
import { IContentSection } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getUpsertContentEventStatus } from "components";

const getSectionDefaultValues = ({ contentLayoutId, section } : { contentLayoutId?: number; section?: IContentSection; }): FormFieldProps => {
  const {
    title,
    contentEvent,
    sectionContainers = [],
    visible: isVisible = true,
    // isSensitive = false,
  } = section ?? {};

  const eventDetails = getContentEventDetails(contentEvent ?? undefined);
  const animationDetails = getContentAnimationDetails(contentEvent ?? undefined);

  return {
    title: title || 'Section',
    contentLayoutId,
    isVisible,
    isSensitive: false,
    sectionContainers,
    ...eventDetails,
    ...animationDetails,
  }
};

const getSectionCommonVariables = ({asset, createContentLayoutId, eventAssetId, item, sort }: { asset?: IDBAsset; createContentLayoutId?: number; eventAssetId?: string; item: FormFieldProps; sort: number }) => {

  const {
    title = 'Section',
    isVisible,
    contentLayoutId,
    isSensitive,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);

  const sharedVariables = {
    section: {
      title,
      contentLayoutId,
      sort,
      isVisible,
      isSensitive,
      containers: createContentLayoutId ? getBaseContainersConfig(Number(createContentLayoutId)): [],
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, asset, assetId: eventAssetId, type: 'SECTION' }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
  };

  return sharedVariables;

}


const getBaseContainersConfig = (contentLayoutId: number): { sort: number; }[] => {
  let containers: { sort: number; }[];
  switch (contentLayoutId) {
    case 1:
      containers = [{ sort: 0 }];
      break;
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      containers = [{ sort: 0 }, { sort: 1 }];
      break;
    case 7:
    case 8:
    case 9:
      containers = [{ sort: 0 }, { sort: 1 }, { sort: 2 }];
      break;
    case 10:
      containers = [{ sort: 0 }, { sort: 1 }, { sort: 2 }, { sort: 3 }];
      break;
    default:
      containers = [];
      break;
  }

  return containers;
}

export { getBaseContainersConfig, getSectionDefaultValues, getSectionCommonVariables };
