import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_FORM_MUTATION } from './useUpdateForm.graphql';
import {
  IUpdateFormMutationGraphQLResponse,
  IUpdateFormMutationVariables,
  IHandleUpdateFormParams,
  IUseUpdateFormOptions,
  IUseUpdateForm,
} from './useUpdateForm.interfaces';

const useUpdateForm = (options?: IUseUpdateFormOptions): IUseUpdateForm => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateFormOptions);

  const [executeUpdateForm, { loading: isLoading }] = useEkardoMutation<IUpdateFormMutationGraphQLResponse, IUpdateFormMutationVariables>({
    mutation: mutation ?? UPDATE_FORM_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: {
            updateContentGroup: contentGroup,
          },
        } = data;

        void onCompleted({ contentGroup });
      },
      onError,
    },
    token,
  });

  const handleUpdateForm = async ({
    contentGroupId,
    description,
    sort,
    title,
    url,
  }: IHandleUpdateFormParams) => {
    await executeUpdateForm({
      variables: {
        contentGroupId,
        description,
        sort,
        title,
        url,
      },
    });
  };

  return {
    handleUpdateForm,
    isLoading,
  };
};

export { useUpdateForm };
