import { gql } from '@apollo/client';

const UPDATE_CONTENT_GROUP = gql`
  mutation UpdateContentGroup($contentGroup: UpdateContentGroupInput!) {
    contentGroup {
      updateContentGroup(
        contentGroup: $contentGroup
      ) {
        id
      }
    }
  }
`;

export { UPDATE_CONTENT_GROUP };
