/* eslint-disable @typescript-eslint/indent */
import { useHowlerLazyQuery } from 'hooks/social/useHowlerLazyQuery';

import { GET_TEMPLATES } from './useGetTemplates.graphql';
import {
  IGetTemplatesQueryGraphQLResponse,
  IGetTemplatesQueryVariables,
  IUseGetTemplates,
  IUseGetTemplatesOptions,
} from './useGetTemplates.interfaces';

const useGetTemplates = (options?: IUseGetTemplatesOptions): IUseGetTemplates => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetTemplatesOptions);

  const [executeGetTemplates, { loading: isLoading }] = useHowlerLazyQuery<IGetTemplatesQueryGraphQLResponse, IGetTemplatesQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }
        const {
          project: { getTemplates },
        } = data;

        onCompleted({
          templates: getTemplates,
        });
      },
      onError,
    },
    query: query ?? GET_TEMPLATES,
    token,
  });

  const handleGetTemplates = async ({ projectId }: IGetTemplatesQueryVariables) => {
    await executeGetTemplates({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetTemplates,
    isLoading,
  };
};

export { useGetTemplates };
