import { gql } from '@apollo/client';

const UPSERT_DIRECTORY_ADDRESS = gql`
  mutation createOrUpdateAddress($directoryId: Int!, $address: AddressInputType!) {
    directory {
      createOrUpdateAddress(directoryId: $directoryId, address: $address) {
        id
        address {
          city
          country
          line1
          latitude
          longitude
          postcode
          state
          suburb
        }
        asset {
          assetId
          contentType
          fileName
          s3Key
          type
        }
        description
        directoryType {
          id
          name
        }
        directoryTypeId
        phoneNumber
        linkedTags {
          id
          name
        }
        title
        status
        subTitle
        url
      }
    }
  }
`;

export { UPSERT_DIRECTORY_ADDRESS };
