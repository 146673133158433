import React from 'react';

import { FlexContainer, IPillProgressBarItem, PillProgressBarList, CircularButton, Avatar, Tooltip } from '@netfront/ui-library';
import cx from 'classnames';
import { Column } from 'react-table';
import { generateRandomId } from 'utils';

import { getStatusByPercentageValue, getUserFlowStatusVariant } from './ProgressTrackingPage.helpers';
import { IProgressTrackingTableData } from './ProgressTrackingPage.interfaces';

const PROGRESS_TRACKING_PAGE_SIZE = 10;

const PERMISSION_MAP = {
  MANAGE_USERS: 'Project admin who can manage all users',
  EDIT: 'Project admin who  has edit permission',
  READ: 'Project admin who  has read permission',
  LIMITED: 'Project admin who has limited permission',
};


const PROGRESS_TRACKING_TABLE_COLUMNS: ReadonlyArray<Column<IProgressTrackingTableData>> = [
  {
    accessor: 'avatarData',
    Cell: ({ value: { fullName, isAdmin, isProjectAdmin, permission, id } }) => (
      <FlexContainer gap="x-small">
        <div className="c-avatar-title">
          <Avatar
            roleIndicator={
              isAdmin
                ? {
                    component: (
                      <Tooltip
                        additionalClassNames={cx('c-admin-star', {
                          'c-admin-star--is-admin': isProjectAdmin,
                        })}
                        iconId="id_star_icon"
                        placement="right"
                        text={PERMISSION_MAP[permission ?? 'MANAGE_USERS']}
                      />
                    ),
                  }
                : undefined
            }
            size="small"
            title={fullName}
          />
        </div>

        <div>{fullName} ({id})</div>
      </FlexContainer>
    ),
    Header: () => <div>Name</div>,
    width: '20%',
  },
  {
    accessor: 'group',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Group</div>,
    width: '15%',
  },
  {
    accessor: 'dateRegistered',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Registered</div>,
    width: '10%',
  },
  {
    accessor: 'userFlowProgress',

    Cell: ({ value = [] }) => {
      const items: IPillProgressBarItem[] = value.map((item) => {
        return {
          id: generateRandomId(),
          title: item.userFlowName,
          value: item.percentageCompleted,
          variant: getUserFlowStatusVariant(getStatusByPercentageValue(item.percentageCompleted))
        };
      });
      return <PillProgressBarList items={items} />
    },
    Header: () => <div>Status</div>,
    width: '40%',
  },
  {
    accessor: 'actions',
    Cell: ({ value: { onClick, email, id } }) => (
      <FlexContainer gap="2x-small">
        <CircularButton
        iconId="id_user_icon"
        supportiveText="View user"
        onClick={() => onClick(id)}
        />

        {email && <CircularButton
          iconId="id_invite_icon"
          linkButton={{
            url: `mailto:${email}`,
            id: 'id_email_user',
            target: '_self',
          }}
          supportiveText="Email user"
          />
        }
      </FlexContainer>
    ),
    Header: () => <div>Actions</div>,
    width: '5%',
  },
];




export { PROGRESS_TRACKING_PAGE_SIZE, PROGRESS_TRACKING_TABLE_COLUMNS };
