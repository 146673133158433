import { ApolloQueryResult } from '@apollo/client';
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { SEARCH_PAGINATED_CHARACTERS_QUERY } from './useSearchPaginatedCharacters.graphql';
import {
  ISearchPaginatedCharactersQueryGraphQLResponse,
  ISearchPaginatedCharactersQueryVariables,
  IHandleFetchMorePaginatedCharactersParams,
  IHandleSearchPaginatedCharactersParams,
  IUseSearchPaginatedCharacters,
  IUseSearchPaginatedCharactersOptions,
} from './useSearchPaginatedCharacters.interfaces';

const useSearchPaginatedCharacters = (options?: IUseSearchPaginatedCharactersOptions): IUseSearchPaginatedCharacters => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseSearchPaginatedCharactersOptions);

  const [executeSearchPaginatedCharacters, { fetchMore, loading: isLoading }] = useEkardoLazyQuery<
  ISearchPaginatedCharactersQueryGraphQLResponse,
  ISearchPaginatedCharactersQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          character: { search: characterConnection },
        } = data;

        onCompleted({
          characterConnection
        });
      },
      onError,
    },
    query: query ?? SEARCH_PAGINATED_CHARACTERS_QUERY,
    token,
  });

  const handleFetchMorePaginatedCharacters = ({
    after,
    filter,
    first,
    projectId,
  }: IHandleFetchMorePaginatedCharactersParams): Promise<ApolloQueryResult<ISearchPaginatedCharactersQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          character: { search: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }


        const {
          character: { search: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          character: {
            ...previouslyFetchedScenarioConnection,
            search: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };

      },
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  const handleSearchPaginatedCharacters = async ({ after, filter, first, projectId }: IHandleSearchPaginatedCharactersParams) => {
    await executeSearchPaginatedCharacters({
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  return {
    handleFetchMorePaginatedCharacters,
    handleSearchPaginatedCharacters,
    isLoading,
  };
};

export { useSearchPaginatedCharacters };
