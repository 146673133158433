import React, { ChangeEvent, FormEvent, useState } from 'react';

import { IAssetTag } from '@netfront/common-library';
import {
  Dialog,
  Input,
  SidebarButtons,
  Spacing,
  Spinner,
} from '@netfront/ui-library';

import { TagsGeneralTabProps } from './TagsGeneralTab.interfaces';

import { useCreateTag, useDeleteTag, useToast, useUpdateTag } from '../../../../hooks';


const TagsGeneralTab = ({
  tagItem,
  onClose,
  onUpdate,
  projectId,
}: TagsGeneralTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [currentTagItem, setCurrentTagItem] = useState<IAssetTag | undefined>(tagItem);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {
    id,
    name: tagName = '',
  } = currentTagItem ?? {};

  const { handleCreateTag, isLoading: isCreateTagLoading = false } = useCreateTag({
    onCompleted: () => {
      onUpdate();
      handleToastSuccess({ message: 'Tag created successfully' });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateTag, isLoading: isUpdateTagLoading = false } = useUpdateTag({
    onCompleted: () => {
      onUpdate();
      handleToastSuccess({ message: 'Tag updated successfully' });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteTag, isLoading: isDeleteTagLoading = false } = useDeleteTag({
    onCompleted: () => {
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });



  const handleUpdateInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentTagItem({
      ...currentTagItem ?? {},
      [name]: value,
    } as IAssetTag
    )
  };

  const handleCreate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleCreateTag({
      tagName,
      projectId,
    });
  };

  const handleUpdate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleUpdateTag({
      value: tagName,
      tagId: Number(id),
    });
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteTag({
      tagId: Number(id),
    });
  }
  const isLoading = isCreateTagLoading || isDeleteTagLoading || isUpdateTagLoading;


  return (
    <>
      <Spinner isLoading={isLoading} />
      <form onSubmit={tagItem ? handleUpdate: handleCreate}>
        <Spacing>
          <Input
            id="name"
            labelText="Name"
            maxLength={100}
            name="name"
            tooltipText="Name of the tag"
            type="text"
            value={tagName}
            isRequired
            onChange={handleUpdateInput}
          />
        </Spacing>


        {tagItem && (
          <Dialog
            isOpen={isDeleteDialogOpen}
            title={`Delete ${String(tagItem.name)}?`}
            onCancel={() => setIsDeleteDialogOpen(false)}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={handleConfirmDelete}
          />
        )}

        <SidebarButtons
          buttonSize="xs"
          onCancel={onClose}
          onDelete={tagItem ? handleDelete: undefined}
          onSaveButtonType="submit"
        />
      </form>
    </>
  );
};

export { TagsGeneralTab };
