import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_SECTIONS } from './useGetContentSections.graphql';
import {
  IGetContentSectionsQueryGraphQLResponse,
  IGetContentSectionsQueryVariables,
  IHandleGetContentSectionsParams,
  IUseGetContentSections,
  IUseGetContentSectionsOptions,
} from './useGetContentSections.interfaces';

const useGetContentSections = (options?: IUseGetContentSectionsOptions): IUseGetContentSections => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetContentSectionsOptions);

  const [executeGetContentSections, { loading: isLoading }] = useEkardoLazyQuery<
  IGetContentSectionsQueryGraphQLResponse,
  IGetContentSectionsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { getSectionsByPageUrl: contentSections},
        } = data;

        void onCompleted({
          contentSections,
        });
      },
      onError,
    },
    query: query ?? GET_CONTENT_SECTIONS,
    token,
  });

  const handleGetContentSections = ({ contentPageUrl }: IHandleGetContentSectionsParams) => {
    void executeGetContentSections({
      variables: {
        contentPageUrl,
      },
    });
  };

  return {
    handleGetContentSections,
    isLoading,
  };
};

export { useGetContentSections };
