import { gql } from '@apollo/client';

const CREATE_PAGE = gql`
  mutation createContentPage($contentPage: CreateContentPageInputType!) {
    contentPage {
      createContentPage(contentPage: $contentPage) {
        id
        url
      }
    }
  }

`;

export { CREATE_PAGE };
