import { Column } from 'react-table';

import { IModulePageTableData } from './ModulePages.interfaces';



const MODULE_PAGE_COLUMNS: ReadonlyArray<Column<IModulePageTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
  },
  {
    accessor: 'userType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User type</div>,
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
  },
  {
    accessor: 'moduleTitle',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Module title</span>,
  },
  {
    accessor: 'startedOn',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Started</span>,
  },
  {
    accessor: 'timeToCompleteAll',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Time to complete (all pages)</span>,
  },
  {
    accessor: 'timeToCompleteFirstPage',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Time to complete (first page only)</span>,
  },
  {
    accessor: 'completedOn',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Completed</span>,
  },
];

export { MODULE_PAGE_COLUMNS };
