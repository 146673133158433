import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_NUMBER_CONFIGURATION } from './useCreateNumberQuestionConfig.graphql';
import {
  ICreateNumberQuestionConfigMutationGraphQLResponse,
  ICreateNumberQuestionConfigMutationVariables,
  IHandleCreateNumberQuestionConfigParams,
  IUseCreateNumberQuestionConfigOptions,
  IUseCreateNumberQuestionConfig,
} from './useCreateNumberQuestionConfig.interfaces';

const useCreateNumberQuestionConfig = (options?: IUseCreateNumberQuestionConfigOptions): IUseCreateNumberQuestionConfig => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateNumberQuestionConfigOptions);

  const [executeCreateNumberQuestionConfig, { loading: isLoading }] = useEkardoMutation<ICreateNumberQuestionConfigMutationGraphQLResponse, ICreateNumberQuestionConfigMutationVariables>({
    mutation: mutation ?? CREATE_NUMBER_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { createQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateNumberQuestionConfig = async ({
    defaultValue,
    description,
    maxValue,
    minValue,
    projectId,
  }: IHandleCreateNumberQuestionConfigParams) => {
    await executeCreateNumberQuestionConfig({
      variables: {
        defaultValue,
        description,
        maxValue,
        minValue,
        projectId,
      }
    });
  };

  return {
    handleCreateNumberQuestionConfig,
    isLoading,
  };
};

export { useCreateNumberQuestionConfig };
