import { gql } from '@apollo/client';

const UPDATE_DIRECTORY_MUTATION = gql`
  mutation updateDirectory($directory: UpdateDirectoryInput!, $tagIds: [Int]) {
    directory {
      updateDirectory(directory: $directory, tagIds: $tagIds) {
        id
        address {
          city
          country
          line1
          latitude
          longitude
          postcode
          state
          suburb
        }
        asset {
          assetId
          contentType
          fileName
          s3Key
          type
        }
        description
        directoryType {
          id
          name
        }
        directoryTypeId
        phoneNumber
        email
        linkedTags {
          id
          name
        }
        title
        status
        subTitle
        url
        operatingHours
      }
    }
  }
`;

export { UPDATE_DIRECTORY_MUTATION };
