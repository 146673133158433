/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_USER_BY_STATE } from './useGetGroupByType.graphql';
import {
  IGetGroupByTypeQueryGraphQLResponse,
  IGetGroupByTypeQueryVariables,
  IUseGetGroupByType,
  IUseGetGroupByTypeOptions,
} from './useGetGroupByType.interfaces';

const useGetGroupByType = (options?: IUseGetGroupByTypeOptions): IUseGetGroupByType => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetGroupByTypeOptions);

  const [executeGetGroupByType, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGroupByTypeQueryGraphQLResponse,
    IGetGroupByTypeQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ insights: { groupCountByType } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          groupCountByType,
        });
      },
      onError,
    },
    query: GET_USER_BY_STATE,
    token,
  });

  const handleGetGroupByType = async ({ projectId }: IGetGroupByTypeQueryVariables) => {
    await executeGetGroupByType({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetGroupByType,
    isLoading,
  };
};

export { useGetGroupByType };
