import { ApolloQueryResult } from '@apollo/client';
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { SEARCH_PAGINATED_ASSETS_QUERY } from './useSearchPaginatedAssets.graphql';
import {
  ISearchPaginatedAssetsQueryGraphQLResponse,
  ISearchPaginatedAssetsQueryVariables,
  IHandleFetchMorePaginatedAssetsParams,
  IHandleSearchPaginatedAssetsParams,
  IUseSearchPaginatedAssets,
  IUseSearchPaginatedAssetsOptions,
} from './useSearchPaginatedAssets.interfaces';

const useSearchPaginatedAssets = (options?: IUseSearchPaginatedAssetsOptions): IUseSearchPaginatedAssets => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseSearchPaginatedAssetsOptions);

  const [executeSearchPaginatedAssets, { fetchMore, loading: isLoading }] = useEkardoLazyQuery<
    ISearchPaginatedAssetsQueryGraphQLResponse,
    ISearchPaginatedAssetsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { search: assetConnection },
        } = data;

        void onCompleted({
          assetConnection,
        });
      },
      onError,
    },
    query: query ?? SEARCH_PAGINATED_ASSETS_QUERY,
    token,
  });

  const handleFetchMorePaginatedAssets = ({
    after,
    filter,
    first,
    imageSize,
    projectId,
    favorite,
    sort,
    tags,
    types,
  }: IHandleFetchMorePaginatedAssetsParams): Promise<ApolloQueryResult<ISearchPaginatedAssetsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          asset: { search: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          asset: { search: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          asset: {
            ...previouslyFetchedScenarioConnection,
            search: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        first,
        imageSize,
        projectId,
        favorite,
        sort,
        tags,
        types,
      },
    });
  };

  const handleSearchPaginatedAssets = ({
    after,
    filter,
    first,
    imageSize,
    projectId,
    favorite,
    sort,
    tags,
    types,
  }: IHandleSearchPaginatedAssetsParams) => {
    void executeSearchPaginatedAssets({
      variables: {
        after,
        filter,
        first,
        imageSize,
        projectId,
        favorite,
        sort,
        tags,
        types,
      },
    });
  };

  return {
    handleFetchMorePaginatedAssets,
    handleSearchPaginatedAssets,
    isLoading,
  };
};

export { useSearchPaginatedAssets };
