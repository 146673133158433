import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CONTENT_PAGE } from './useUpdatePageStatus.graphql';
import {
  IUpdatePageStatusMutationGraphQLResponse,
  IUpdatePageStatusMutationVariables,
  IHandleUpdatePageStatusParams,
  IUseUpdatePageStatusOptions,
  IUseUpdatePageStatus,
} from './useUpdatePageStatus.interfaces';

const useUpdatePageStatus = (options?: IUseUpdatePageStatusOptions): IUseUpdatePageStatus => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdatePageStatusOptions);

  const [executeUpdatePageStatus, { loading: isLoading }] = useEkardoMutation<IUpdatePageStatusMutationGraphQLResponse, IUpdatePageStatusMutationVariables>({
    mutation: mutation ?? DELETE_CONTENT_PAGE,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { updateContentPageStatus: contentPage },
        } = data;

        onCompleted({
          contentPage,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdatePageStatus = async ({ contentPageId, status }: IHandleUpdatePageStatusParams) => {
    await executeUpdatePageStatus({
      variables: {
        contentPage: {
          contentPageId,
          status,
        }
      },
    });
  };

  return {
    handleUpdatePageStatus,
    isLoading,
  };
};

export { useUpdatePageStatus };
