import { gql } from '@apollo/client';

export const GET_CONDITION_LOCATIONS = gql`
  query getSnippetLocation($contentGroupId: Int!) {
    contentSnippet {
      getSnippetForms(contentGroupId: $contentGroupId) {
        id
        contentPage {
          title
          id
          status
          contentGroup {
            title
          }
        }
      }
    }
  }
`;
