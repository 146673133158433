import { gql } from '@apollo/client';

const CREATE_MULTI_RESPONSE_TEXT_CONFIGURATION = gql`
  mutation createMultiResponseQuesConfigRequestMutation(
    $maxResponse: Int!
    $minResponse: Int!
    $projectId: String!
    $description: String!
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationMultiResponseText: {
          type: FREE_TEXT
          maxResponse: $maxResponse
          minResponse: $minResponse
        }
      ) {
        id
        ... on MultiResponseText {
          id
          maxResponse
          minResponse
          type
        }
      }
    }
  }
`;
export { CREATE_MULTI_RESPONSE_TEXT_CONFIGURATION };
