import React, { useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { IContentSnippet, IDBContentPage } from '@netfront/ekardo-content-library';
import { ControlledForm, FormFieldProps, ICheckboxItem, IRadioButtonItem, Input, RadioButtonGroup, SidebarButtons, Spacing, Spinner, ToggleSwitch, useControlledForm } from '@netfront/ui-library';
import { QuestionSelector } from 'components';
import { useGetConditionLocations, useGetConditionSnippetAnswers, useToast } from 'hooks';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';


import { ConditionCheckboxQuestion } from '../ConditionCheckboxQuestion';
import { ConditionDateQuestion } from '../ConditionDateQuestion';
import { ConditionRadioQuestion } from '../ConditionRadioQuestion';
import { ConditionSliderQuestion } from '../ConditionSliderQuestion';
import { ConditionTextQuestion } from '../ConditionTextQuestion';

import { getConditionDefaultValues } from './ConditionQuestionDetails.helpers';
import { ConditionQuestionDetailsProps } from './ConditionQuestionDetails.interfaces';

import { getQuestionText, generateRandomId } from '../../../../../../../utils';
import { IActionCondition } from '../../UpsertAction/UpsertAction.interfaces';

const ConditionQuestionDetails = ({ onUpdate, onCancel, onDelete, selectedCondition }: ConditionQuestionDetailsProps) => {
  const { handleToastError } = useToast();

  // radio / checkbox
  const [questionResponses, setQuestionResponses] = useState<ICheckboxItem[]>([]);

  const [conditionQuestion, setConditionQuestion] = useState<string>('');
  const [questionLocations, setQuestionLocations] = useState<IRadioButtonItem[]>([]);
  const [currentCondition, setCurrentCondition] = useState<IActionCondition>({} as IActionCondition);
  const [questionSnippetForms, setQuestionSnippetForms] = useState<IContentSnippet[]>([]);
  const [questionConditionContentGroupId, setQuestionConditionContentGroupId] = useState<number | undefined>(undefined);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, setValue, watch } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        contentPageId: yup.number().label('Question location').required(),
        contentSnippetQuestionId: yup.number().required(),
      }),
    ),
  });

  const { type, contentSnippetQuestionId, id: conditionId, tempId } = watch();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  // Get locations of question
  const { handleGetConditionLocations, isLoading: isGetLocationsLoading = false } = useGetConditionLocations({
    onCompleted: ({ getSnippetForms }) => {
      const hasValidContentPage = (item: IContentSnippet): item is IContentSnippet & { contentPage: IDBContentPage } => {
        const { contentPage } = item;
        return (
          contentPage !== undefined &&
          contentPage.status !== 'DELETED' &&
          Boolean(contentPage.id) &&
          Boolean(contentPage.title) &&
          Boolean(contentPage.contentGroup.title)
        );
      }
      setQuestionSnippetForms(getSnippetForms);
      const conditionItemLocations: IRadioButtonItem[] = getSnippetForms
        .filter(hasValidContentPage)
        .map(
          ({
            contentPage: {
              id,
              title: contentPageTitle,
              contentGroup: { title: contentGroupTitle },
            },
          }) => ({
            id: String(id),
            labelText: `${contentPageTitle} - ${contentGroupTitle}`,
            value: id.toString(),
          }),
        );

      setQuestionLocations(conditionItemLocations);
    },
    onError: handleGetError,
  });

  // snippet answers
  const { handleGetConditionSnippetAnswers, isLoading: isGetSnippetAnswersLoading = false } = useGetConditionSnippetAnswers({
    onCompleted: ({ question }) => {

      const {
        id: questionId,
        contentPage,
        question: formQuestion,
        configuration,
      } = question;

      const { responseSet } = configuration ?? {};

      const { contentGroupId } = contentPage ?? {};

      const { availableResponses = [] } = responseSet ?? {};

      setQuestionConditionContentGroupId(Number(contentGroupId));

      const questionLabel = getQuestionText(String(formQuestion)) ?? `Question-${questionId}`;
      setConditionQuestion(`ID: ${questionId} - Question label: ${questionLabel.substring(0, 30)}`);
      setQuestionResponses(
        availableResponses.map(({ id, label }) => ({
          id: String(id),
          labelText: label,
          value: id.toString(),
        })) as ICheckboxItem[],
      );
    },
    onError: handleGetError,
  });

  const handleSaveCondition = (item: FormFieldProps) => {

    const {
      tempId: saveTempId,
      questionResponseCheckboxId: saveQuestionResponseCheckboxId = [] ,
    } = item;

    const condition = {
      ...item,
      questionResponseCheckboxId: Number(saveQuestionResponseCheckboxId[0]),
    } as IActionCondition;

    onUpdate(!saveTempId ? {...condition, tempId: generateRandomId()} : condition);
    reset();
  };

  useEffect(() => {
    if (!questionSnippetForms.length) return;

    const [firstContentSnippetForm] = questionSnippetForms;

    const { contentPage } = firstContentSnippetForm;

    const { id } = contentPage ?? {};

    setCurrentCondition({
      ...currentCondition,
      contentPageId: Number(id),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSnippetForms]);

  useEffect(() => {
    if (!questionConditionContentGroupId) return;

    handleGetConditionLocations({
      contentGroupId: questionConditionContentGroupId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionConditionContentGroupId]);

  useEffect(() => {
    if (!contentSnippetQuestionId) return;
    handleGetConditionSnippetAnswers({
      contentSnippetQuestionId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentSnippetQuestionId]);

  useEffect(() => {
    setCurrentCondition(selectedCondition);
    setDefaultValues(getConditionDefaultValues(selectedCondition));
  }, [selectedCondition]);


  const isLoading = isGetSnippetAnswersLoading || isGetLocationsLoading;


  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSaveCondition(item);
        }}
        handleSubmit={handleSubmit}
      >
        <Spacing size="2x-large">
          <Controller
            control={control}
            name="title"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_condition_title"
                labelText="Title"
                maxLength={100}
                minLength={1}
                placeholder="Create your condition title"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
      </Spacing>
      {!tempId ? (
        <QuestionSelector
          control={control}
          setValue={setValue}
        />
      ) : (
        <Spacing size="2x-large">
          <Input
            id="id_question_label"
            labelText="Question"
            name="question-label"
            type="text"
            value={conditionQuestion}
            isDisabled
            isLabelSideBySide
            onChange={() => undefined}
          />
        </Spacing>
      )}
      {contentSnippetQuestionId && type && (
        <>
          <Spacing size="2x-large" />
          <>
            {
              type === 'checkbox' && (
                <ConditionCheckboxQuestion
                  control={control}
                  questionResponses={questionResponses}
                />
              )
            }
            {
              type === 'radio' && (
                <ConditionRadioQuestion
                  control={control}
                  questionResponses={questionResponses as IRadioButtonItem[]}
                />
              )
            }
            {
              type === 'text' && (<ConditionTextQuestion control={control}/>)
            }
            {
              type === 'slider' && (<ConditionSliderQuestion control={control} />)
            }
            {
              type === 'calendar' && (
                <ConditionDateQuestion
                  control={control}
                  initialDateRangeType={defaultValues?.dateRangeType}
                  setValue={setValue}
                />
              )
            }
          </>
          {['radio', 'checkbox'].includes(String(type)) && (
            <>
              <Spacing size="2x-large" />
              <Controller
                control={control}
                name="selection"
                render={({ field }) => (
                    <RadioButtonGroup
                      items={[
                        {
                          id: 'SELECTED',
                          labelText: 'Selected',
                          value: 'SELECTED',
                        },
                        {
                          id: 'NOT_SELECTED',
                          labelText: 'Not selected',
                          value: 'NOT_SELECTED',
                        },
                      ]}
                      selectedValue={String(field.value)}
                      // tooltipPosition="start"
                      // tooltipText="This determines whether the option should be selected or not"
                      title="Question selected"
                      {...field}
                    />
                )}
              />
            </>
          )}

          <Spacing size="2x-large" />

          {questionLocations.length > 0 && (
            <Controller
              control={control}
              name="contentPageId"
              render={({ field }) => (
                  <RadioButtonGroup
                    items={questionLocations}
                    selectedValue={String(field.value)}
                    title="Question location"
                    // tooltipPosition="start"
                    // tooltipText="This action will execute if the Checkbox selection is as selected below"
                    isLabelSideBySide
                    {...field}
                  />
              )}
            />
          )}


          <Spacing size="2x-large" />
          {!conditionId && (
            <Controller
              control={control}
              name="isTemplate"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames={`c-sidebar-toggle`}
                  id="id_is_template"
                  inlineLabelPosition="start"
                  isChecked={field.value}
                  labelText="Save conditional configuration for reuse"
                  tooltipText="Templates are used to copy condition configuration. When you save this condition as a template, it will become available for reuse within all actions."
                  isInline
                  // errorMessage={getFormFieldErrorMessage(fieldState)}
                  {...field}
                />
              )}
            />
          )}
        </>
      )}
        <SidebarButtons
          isSaveButtonDisabled={!contentSnippetQuestionId && !type}
          onCancel={onCancel}
          onDelete={onDelete && tempId ? () => onDelete(String(tempId)): undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </>
  );
};

export { ConditionQuestionDetails };
