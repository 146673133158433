import { gql } from '@apollo/client';

const ADD_USER_IN_COMMUNITY_MUTATION = gql`
  mutation AddUserInCommunity($communityId: Int!, $userId: Int!, $role: ECommunityRole!) {
    communityConnection {
      addUserInCommunity(communityId: $communityId, userId: $userId, role: $role)
    }
  }
`;

export { ADD_USER_IN_COMMUNITY_MUTATION };
