import React, { useCallback, useContext } from 'react';

import { DBContentSnippetTypeNameType } from '@netfront/ekardo-content-library';
import { Spacing, Breadcrumbs, IBreadcrumbItem } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { ContentBuilderContext } from 'context';

import { ContentBuilderTabWrapperProps } from './ContentBuilderTabWrapper.interfaces';

import { SnippetTypeFriendlyText } from '../../../constants';


const ContentBuilderTabWrapper = ({ children, additionalBreadcrumbs = [], hasSnippetTypeBreadcrumb = true, isSnippet = true, hasContainerWrapper = true }: ContentBuilderTabWrapperProps) => {
  const { updateSnippetSidebarDetails, snippetSidebarDetails } = useContext(ContentBuilderContext);
  const { containerId, snippetId, sort, type } = snippetSidebarDetails ?? {};

  const handleBackButtonClick = useCallback(() => {
    updateSnippetSidebarDetails({
      containerId: Number(containerId),
      sort: Number(sort),
    });
  }, [containerId, sort, updateSnippetSidebarDetails]);

  let breadcrumbs: IBreadcrumbItem[] = !snippetId && isSnippet ? [
    {
      key: 'snippet-selector',
      onClick: handleBackButtonClick,
      content: 'Snippet select',
      iconId: 'id_home_icon',
    }
  ]: [];

  if (!snippetId && isSnippet && hasSnippetTypeBreadcrumb) {
    breadcrumbs = [
      ...breadcrumbs,
      {
        key: String(type),
        content: <span className="c-breadcrumb-item__link">{String(SnippetTypeFriendlyText[type as DBContentSnippetTypeNameType])}</span>
      }
    ];
  }

  breadcrumbs = [
    ...breadcrumbs,
    ...additionalBreadcrumbs,
  ];

  return (
    <>
      {breadcrumbs.length > 0 && (
        <SidebarContainer hasPaddingEnds={false}>
          <Spacing size="2x-small">
            <Breadcrumbs
              itemDivider="chevron"
              items={breadcrumbs}
              isContained
            />
          </Spacing>
        </SidebarContainer>
      )}
      {hasContainerWrapper ? (
        <SidebarContainer>
          {children}
        </SidebarContainer>
      ): (
        <>{children}</>
      )}

    </>
  );
};

export { ContentBuilderTabWrapper };
