import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_MULTI_LINE_FEEDBACK_CONFIG } from './useUpsertSummaryFeedbackConfigMultiLine.graphql';
import {
  IUpsertSummaryFeedbackConfigMultiLineMutationGraphQLResponse,
  IUpsertSummaryFeedbackConfigMultiLineMutationVariables,
  IHandleUpsertSummaryFeedbackConfigMultiLineParams,
  IUseUpsertSummaryFeedbackConfigMultiLineOptions,
  IUseUpsertSummaryFeedbackConfigMultiLine,
} from './useUpsertSummaryFeedbackConfigMultiLine.interfaces';

const useUpsertSummaryFeedbackConfigMultiLine = (options?: IUseUpsertSummaryFeedbackConfigMultiLineOptions): IUseUpsertSummaryFeedbackConfigMultiLine => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpsertSummaryFeedbackConfigMultiLineOptions);

  const [executeUpsertSummaryFeedbackConfigMultiLine, { loading: isLoading }] = useEkardoMutation<IUpsertSummaryFeedbackConfigMultiLineMutationGraphQLResponse, IUpsertSummaryFeedbackConfigMultiLineMutationVariables>({
    mutation: mutation ?? CREATE_MULTI_LINE_FEEDBACK_CONFIG,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { addSummaryFeedbackConfigurationMultiLine: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpsertSummaryFeedbackConfigMultiLine = async ({
    contentSnippetSummaryId,
    questionResponseSetId,
    label,
  }: IHandleUpsertSummaryFeedbackConfigMultiLineParams) => {
    await executeUpsertSummaryFeedbackConfigMultiLine({
      variables: {
        contentSnippetSummaryId,
        questionResponseSetId,
        label,
      }
    });
  };

  return {
    handleUpsertSummaryFeedbackConfigMultiLine,
    isLoading,
  };
};

export { useUpsertSummaryFeedbackConfigMultiLine };
