import { gql } from '@apollo/client';

const DELETE_DISCOUNT_MUTATION = gql`
  mutation DeleteDiscount($id: Int!) {
    discounts {
      remove(id: $id)
    }
  }
`;

export { DELETE_DISCOUNT_MUTATION };
