
import { IFeedbackTableData, IFeedbackTableDataParams } from "./FeedbackReportPage.interfaces";


const getFeedbackTableData = ({ items }: IFeedbackTableDataParams): IFeedbackTableData[] => {
  return items.map(({
    createdOn,
    createdOnUtc,
    feedback,
    groupName,
    id,
    rate,
    url,
    userType,
   }) => {
    return {
      createdOn,
      createdOnUtc,
      feedback,
      groupName,
      id,
      rate,
      url,
      userType,
    };
  });
};

export { getFeedbackTableData };
