import { gql } from '@apollo/client';

const DEFINE_RESPONSE_SET = gql`
  mutation defineResponseSetRequest(
    $questionConfigurationId: Int!
    $questionResponseSetId: Int!
  ) {
    questionConfiguration {
      defineQuestionResponseSet(
        questionConfigurationId: $questionConfigurationId
        questionResponseSetId: $questionResponseSetId
      ) {
        id
      }
    }
  }
`;

export { DEFINE_RESPONSE_SET };
