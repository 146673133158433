import React, { useEffect, useMemo, useState } from 'react';

import { InputFieldWrapper } from '@netfront/ui-library';
import dynamic from 'next/dynamic';

import { TextEditorProps } from './TextEditor.interfaces';

import "jodit/build/jodit.min.css";

// we have to import this editor in this way to pass build process
const JoditReact = dynamic(() => import('jodit-react-ts'), { ssr: false });

const TextEditor = ({
  initialValue = '',
  onInputChange,
  errorMessage,
  tooltipPosition,
  isEncouraged,
  isLabelHidden = false,
  id,
  isLoading,
  isRequired,
  label,
  successMessage,
  tooltipText,
}: TextEditorProps) => {

  const [value, setValue] = useState<string>('');
  const options = ['undo', 'redo', '|', 'paragraph', 'brush', 'bold', 'italic', 'underline', 'strikethrough', '|', 'indent' , 'outdent', 'align', '|', 'ul', 'ol', 'link', '|', 'source'];

  const config = useMemo(
    () => ({
      minHeight: 300,
      height: '100%',
      readonly: false,
      placeholder: '',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1.5,
      enter: 'p',
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sourceEditor: 'textmate',
      // sizeLG: 900,
      // sizeMD: 700,
      // sizeSM: 400,
      toolbarAdaptive: false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );


  const handleChange = (content: string) => {
    onInputChange(content);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue])

  return (
    <div key="jodit-react-editor">
      <InputFieldWrapper
        id={id}
        isEncouraged={isEncouraged}
        isLabelHidden={isLabelHidden}
        isLoading={isLoading}
        isRequired={isRequired}
        label={label}
        message={{ error: errorMessage ?? '', success: successMessage ?? '' }}
        tooltipPosition={tooltipPosition}
        tooltipText={tooltipText}
        type="textarea"
        isLabelSideBySide
      >
        <JoditReact config={config} defaultValue={value} onChange={handleChange} />
      </InputFieldWrapper>
    </div>
  );
};

export { TextEditor };
