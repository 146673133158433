/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_USER_BY_STATE } from './useGetUserCountByState.graphql';
import {
  IGetUserCountByStateQueryGraphQLResponse,
  IGetUserCountByStateQueryVariables,
  IUseGetUserCountByState,
  IUseGetUserCountByStateOptions,
} from './useGetUserCountByState.interfaces';

const useGetUserCountByState = (options?: IUseGetUserCountByStateOptions): IUseGetUserCountByState => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetUserCountByStateOptions);

  const [executeGetUserCountByState, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetUserCountByStateQueryGraphQLResponse,
    IGetUserCountByStateQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ insights: { userCountByState } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userCountByState,
        });
      },
      onError,
    },
    query: GET_USER_BY_STATE,
    token,
  });

  const handleGetUserCountByState = async ({ projectId }: IGetUserCountByStateQueryVariables) => {
    await executeGetUserCountByState({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetUserCountByState,
    isLoading,
  };
};

export { useGetUserCountByState };
