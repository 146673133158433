
import { ReactNode } from "react";

import { DBNetfrontServiceType } from "@netfront/gelada-identity-library";
import { NavigationItemProps } from "@netfront/ui-library";
import { IDashboardItem, IDashboardItemsList } from "context";
import Link from "next/link";
import { sortObjectArrayAlphabetically, toKebabCase } from "utils";

import { reportOptions } from "./DashboardContext.constants";


const getDashboardFormattedReportOptions = ({ baseUrl, services, hasMobileApplication = false, projectId }: { baseUrl: string; hasMobileApplication?: boolean; projectId: string; services?: DBNetfrontServiceType[]}): IDashboardItem[] => {

  const options: IDashboardItem[] =
    sortObjectArrayAlphabetically(reportOptions, 'group')
    .reduce((acc, item) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { items, group, service, image, iconId } = item;
      const optionItems = sortObjectArrayAlphabetically(items, 'text').map(({ route, text, isOnlyVisibleIfHasMobileApplication = false, projectId: itemProjectId, iconId: subItemIconId}) => ({
        id: route,
        description: `Reporting for ${group}: ${text}`,
        href: `${baseUrl}/${toKebabCase(group).toLowerCase()}/${route}`,
        isVisible: (
          (!service || services?.includes(service as DBNetfrontServiceType)) &&
          (route !== 'export-mobile-app-access' || (hasMobileApplication === isOnlyVisibleIfHasMobileApplication)) &&
          (route !== 'export-logins-trials' || (projectId === itemProjectId))
        )  ?? false,
        image: '',
        iconId: subItemIconId,
        title: text,
      }));

      const reportOption = {
        id: `reporting-${group.toLowerCase()}`,
        description: `Project ${group} reporting page`,
        isVisible: (!service || services?.includes(service as DBNetfrontServiceType)) ?? false,
        href: `${baseUrl}/${group.toLowerCase()}`,
        image,
        iconId,
        title: `${group}`,
        subItems: optionItems,
      };

      acc.push(reportOption);
      return acc;
    }, [] as IDashboardItem[]);
    return options;

};

const findObjectById = (array: IDashboardItemsList[], id: string): IDashboardItemsList[] | null =>  {
  for (const item of array) {
    if (item.id === id) {
      return item.subItems ?? [];
    }
    if (item.subItems && item.subItems.length > 0) {
      const subItem = findObjectById(item.subItems, id);
      if (subItem) {
        return subItem;
      }
    }
  }
  return null;
};

const filterVisibleItems = (items: IDashboardItemsList[]): IDashboardItemsList[] => {
  return items
    .filter(item => item.isVisible ?? true)
    .map(item => ({
      ...item,
      subItems: filterVisibleItems(item.subItems ?? [])
    }));
};

const formatDashboardItems = (items: IDashboardItemsList[]): NavigationItemProps[] => {
  return items.map((item) => {
    const { iconId: itemIcon, title: itemTitle, id, href, subItems, isInternal = true } = item;
    return {
      iconId: itemIcon,
      label: itemTitle,
      id,
      href: !isInternal ? href: undefined,
      wrapperComponent: isInternal ? ({ children }: { children: ReactNode }) => <Link href={href}>{children} </Link>: undefined,
      subItems: subItems && subItems.length > 0 ? formatDashboardItems(subItems) : undefined
    };
  }) as NavigationItemProps[];
};

export { getDashboardFormattedReportOptions, findObjectById, filterVisibleItems, formatDashboardItems };
