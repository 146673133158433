import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_ASSET_CAPTION_MUTATION } from './useUpdateAssetCaption.graphql';
import {
  IUpdateAssetCaptionMutationGraphQLResponse,
  IUpdateAssetCaptionMutationVariables,
  IHandleUpdateAssetCaptionParams,
  IUseUpdateAssetCaptionOptions,
  IUseUpdateAssetCaption,
} from './useUpdateAssetCaption.interfaces';

const useUpdateAssetCaption = (options?: IUseUpdateAssetCaptionOptions): IUseUpdateAssetCaption => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateAssetCaptionOptions);

  const [executeUpdateAssetCaption, { loading: isLoading }] = useEkardoMutation<
    IUpdateAssetCaptionMutationGraphQLResponse,
    IUpdateAssetCaptionMutationVariables
  >({
    mutation: mutation ?? UPDATE_ASSET_CAPTION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          caption: { update: assetCaptionConnection },
        } = data;

        void onCompleted(assetCaptionConnection);
      },
      onError,
    },
    token,
  });

  const handleUpdateAssetCaption = ({ id, from, text, to }: IHandleUpdateAssetCaptionParams) => {
    void executeUpdateAssetCaption({
      variables: {
        id,
        from,
        text,
        to,
      },
    });
  };

  return {
    handleUpdateAssetCaption,
    isLoading,
  };
};

export { useUpdateAssetCaption };
