import { useEffect, useState } from 'react';

import { useBackofficeSiteUrls, useDomain } from '@netfront/gelada-identity-library';
import { Label, Preloader, InformationBox } from '@netfront/ui-library';

import { AssetSidebarUsageViewProps } from './AssetSidebarUsageView.interfaces';

import { IAssetUsage, useToast, useGetAssetUsages } from '../../../../../../hooks';

const AssetSidebarUsageView = ({ assetId, assetType, organisationKey, projectId }: AssetSidebarUsageViewProps) => {
  const { handleToastError } = useToast();
  const { isDomainReady } = useDomain();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { getDashboardUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });

  const [assetLocations, setAssetLocations] = useState<IAssetUsage[]>([]);
  const [dashboardUrl, setDashboardUrl] = useState<string>('');

  const { handleGetAssetUsages: executeGetAssetUsages, isLoading: isGetAssetUsagesLoading = false } = useGetAssetUsages({
    onCompleted: ({ assetUsagesConnection }) => {
      setAssetLocations(assetUsagesConnection);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!assetId) {
      return;
    }

    executeGetAssetUsages({
      assetId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
    setDashboardUrl(getDashboardUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <div className="c-asset-sidebar-usage-view">
      {isGetAssetUsagesLoading ? (
        <Preloader isLoading={isGetAssetUsagesLoading} />
      ) : (
        <>
          <Label forId="copy" labelText="Page location" tooltipText={`Location of the ${String(assetType)} asset in the project`}/>

          {assetLocations.length ? (
            <ul className="c-asset-sidebar-usage-view__usage-list">
              {assetLocations.map((location) => {
                const {
                  id,
                  location: {
                    url,
                    contentGroup: { id: contentGroupId, title },
                  },
                } = location;

                return (
                  <li key={`${id}-${String(url)}`} className="c-asset-sidebar-usage-view__usage-item">
                    <a
                      className="c-asset-sidebar-usage-view__usage-title"
                      href={`${dashboardUrl}/${organisationKey}/project/${projectId}/group/page/${contentGroupId}/content`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {title}
                    </a>
                  </li>
                );
              })}
            </ul>
          ) : (
            <InformationBox message={`No usage location for the ${String(assetType)} asset`} />
          )}
        </>
      )}
    </div>
  );
};

export { AssetSidebarUsageView };
