

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { EXPORT_QUESTIONNAIRES_FROM_REQUEST } from './useGetQuestionnairesReportFromRequest.graphql';
import {
  IGetQuestionnairesReportFromRequestQueryGraphQLResponse,
  IGetQuestionnairesReportFromRequestQueryVariables,
  IHandleGetQuestionnairesReportFromRequestParams,
  IUseGetQuestionnairesReportFromRequest,
  IUseGetQuestionnairesReportFromRequestOptions,
} from './useGetQuestionnairesReportFromRequest.interfaces';

const useGetQuestionnairesReportFromRequest = (options?: IUseGetQuestionnairesReportFromRequestOptions): IUseGetQuestionnairesReportFromRequest => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetQuestionnairesReportFromRequestOptions);

  const [executeGetQuestionnairesReportFromRequest, { loading: isLoading }] = useEkardoLazyQuery<
    IGetQuestionnairesReportFromRequestQueryGraphQLResponse,
    IGetQuestionnairesReportFromRequestQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'no-cache',
      onCompleted: ({ report: { get } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: get,
        });
      },
      onError,
    },
    query: query ?? EXPORT_QUESTIONNAIRES_FROM_REQUEST,
    token,
  });


  const handleGetQuestionnairesReportFromRequest = async ({
    id,
  }: IHandleGetQuestionnairesReportFromRequestParams) => {
    await executeGetQuestionnairesReportFromRequest({
      variables: {
        id
      },
    });
  };

  return {
    handleGetQuestionnairesReportFromRequest,
    isLoading,
  };
};

export { useGetQuestionnairesReportFromRequest };
