import { gql } from '@apollo/client';

const GET_USER_BY_STATE = gql`
  query getUserCountByState($projectId: String!) {
    insights {
      userCountByState(projectId: $projectId) {
        count
        key
      }
    }
  }
`;

export { GET_USER_BY_STATE };
