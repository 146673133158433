import { gql } from '@apollo/client';


const GET_CONTENT_GROUPS = gql`
  query GetContentGroups($projectId: String!, $type: [EContentGroupType], $title: String) {
    contentGroup {
      searchContentGroups(
        projectId: $projectId
        status: [UNPUBLISHED, PUBLISHED]
        title: $title
        type: $type
      ) {
        id
        title
        type
        description
        url
        releaseDate
        estimatedTime
        contentType
        iconAssetId
        iconAsset {
          contentType
          s3Key
          presignedUrl
        }
        firstPage {
          id
          url
        }
        status
        sort
        tags {
          id
          name
        }
        groupingContentGroup {
          id
          name
        }
      }
    }
  }
`;

export { GET_CONTENT_GROUPS };
