import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CONTENT_SECTION_GROUP } from './useDeleteSectionGroup.graphql';
import {
  IDeleteSectionGroupMutationGraphQLResponse,
  IDeleteSectionGroupMutationVariables,
  IHandleDeleteSectionGroupParams,
  IUseDeleteSectionGroupOptions,
  IUseDeleteSectionGroup,
} from './useDeleteSectionGroup.interfaces';

const useDeleteSectionGroup = (options?: IUseDeleteSectionGroupOptions): IUseDeleteSectionGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteSectionGroupOptions);

  const [executeDeleteSectionGroup, { loading: isLoading }] = useEkardoMutation<IDeleteSectionGroupMutationGraphQLResponse, IDeleteSectionGroupMutationVariables>({
    mutation: mutation ?? DELETE_CONTENT_SECTION_GROUP,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          sectionGroup: { delete: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteSectionGroup = async ({ id }: IHandleDeleteSectionGroupParams) => {
    await executeDeleteSectionGroup({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteSectionGroup,
    isLoading,
  };
};

export { useDeleteSectionGroup };
