import { gql } from '@apollo/client';
import { AppFragment, AudioFragment, ButtonFragment, CodeFragment, DocumentFragment, ContentSnippetSliderFragment, EmbedFragment, FormFragment, ImageFragment, MapFragment, QuestionFragment, QuoteFragment, RegionsFragment, SliderFragment, SpacerFragment, SummaryFragment, TextFragment, VideoFragment } from 'fragments';


const GET_CONTENT_SECTIONS = gql`
  query GetContentSections($contentPageUrl: String!) {
    contentSection {
      getSectionsByPageUrl(contentPageUrl: $contentPageUrl) {
        id
        contentPageId
        contentLayoutId
        sort
        backgroundColor
        visible
        title
        borderColor
        cssStyle {
          id
          name
        }
        background {
          assetId
          contentType
          s3Key
        }
        contentEventId
        style
        contentEvent {
          id
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          asset {
            assetId
            contentType
            s3Key
          }
          backgroundColor
          borderColor
          titleColor
          style
          triggerStyle
          iconHorizontalAlignment
          isDefaultOpen
        }
        sectionContainers {
          id
          sort
          identifier
          sectionId
          backgroundColor
          borderColor
          title
          cssStyle {
            id
            name
          }
          contentEventId
          contentEvent {
            animation
            animationDirection
            entityId
            event
            eventDirection
            eventTitle
            infinite
            millisecondDelay
            millisecondSpeed
            asset {
              assetId
              contentType
              s3Key
            }
            backgroundColor
            borderColor
            titleColor
            style
            triggerStyle
            iconHorizontalAlignment
            isDefaultOpen
          }
          snippets {
            id
            sort
            visible
            cssStyle {
              id
              name
            }
            contentEventId
            contentEvent {
              event
            }
            title
            ... on ContentSnippetAvatarType {
              avatar {
                configuration {
                  key
                  value
                }
                imageUrl
              }
            }
            borderColor
            backgroundColor
            type: __typename
            __typename
            ...app
            ...audio
            ...button
            ...code
            ...document
            ...embed
            ...form
            ...image
            ...map
            ...question
            ...quote
            ...regions
            ...slider
            ...spacer
            ...summary
            ...text
            ...video
          }
        }
      }
    }
  }
  ${AppFragment}
  ${AudioFragment}
  ${ButtonFragment}
  ${CodeFragment}
  ${ContentSnippetSliderFragment}
  ${DocumentFragment}
  ${EmbedFragment}
  ${FormFragment}
  ${ImageFragment}
  ${MapFragment}
  ${QuestionFragment}
  ${QuoteFragment}
  ${RegionsFragment}
  ${SliderFragment}
  ${SpacerFragment}
  ${SummaryFragment}
  ${TextFragment}
  ${VideoFragment}
`;

export { GET_CONTENT_SECTIONS };
