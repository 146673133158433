
import { ICommentsTableData, ICommentsTableDataParams } from "./CommentsReportPage.interfaces";


const getCommentsTableData = ({ items }: ICommentsTableDataParams): ICommentsTableData[] => {
  return items.map(({
    comment = '',
    commentDate = '',
    commentDateUtc = '',
    commentId = '',
    groupName = '',
    id,
    parentCommentId = '',
    postId = '',
    userType = '',
   }) => {
    return {
      comment,
      commentDate,
      commentDateUtc,
      commentId,
      groupName,
      id,
      parentCommentId,
      postId,
      userType,
    };
  });
};

export { getCommentsTableData };
