import { gql } from '@apollo/client';

const CREATE_SECTION = gql`
  mutation createContentSection($contentSection: CreateContentSectionInput!) {
    contentSection {
      createSection(contentSection: $contentSection) {
        id
        sectionContainers {
          id
        }
      }
    }
  }

`;

export { CREATE_SECTION };
