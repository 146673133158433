import React, { useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, InformationBox, Input, ToggleSwitch, IconRadioItemProps, IconRadioGroup, Message } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { getEmbedTypeOptions } from './UpsertEmbedSnippetGeneralTab.helpers';
import { UpsertEmbedSnippetGeneralTabProps } from './UpsertEmbedSnippetGeneralTab.interfaces';


const UpsertEmbedSnippetGeneralTab = ({ assetType, control }: UpsertEmbedSnippetGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const [embedTypeOptions, setEmbedTypeOptions] = useState<IconRadioItemProps[]>([]);

  useEffect(() => {
    setEmbedTypeOptions(getEmbedTypeOptions(assetType));
  }, [assetType]);

  return (
    <>
      <Spacing>
        <InformationBox message="Edit your code below." />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_embed_snippet_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="embedProvider"
          render={({ field, fieldState }) => {
            const errorMessage = getFormFieldErrorMessage(fieldState)
            return (
              <>
                <IconRadioGroup
                  id="id_embed_provider"
                  items={embedTypeOptions}
                  label="Type"
                  selectedValue={field.value}
                  hasLabels
                  isLabelSideBySide
                  isOutlined
                  {...field}
                />
                {Boolean(errorMessage) && (
                  <SidebarContainer>
                    <Message id={`id_embed_type_error`} text={errorMessage} type="ERROR" />
                  </SidebarContainer>
                )}
              </>
            );
          }}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="code"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_embed_snippet_title"
              labelText="Code"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
            )
          }
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_sensitive_content"
              isChecked={field.value}
              labelText="Sensitive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isAutoPlay"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_auto_play"
              isChecked={field.value}
              labelText="Auto play"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isAuthorVisible"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_author_visible"
              isChecked={field.value}
              labelText="Show author"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </>
  );
};

export { UpsertEmbedSnippetGeneralTab };
