

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { EXPORT_MOBILE_APP_ACCESS } from './useGetMobileAccessReport.graphql';
import {
  IGetMobileAccessReportQueryGraphQLResponse,
  IGetMobileAccessReportQueryVariables,
  IHandleGetMobileAccessReportParams,
  IUseGetMobileAccessReport,
  IUseGetMobileAccessReportOptions,
} from './useGetMobileAccessReport.interfaces';

const useGetMobileAccessReport = (options?: IUseGetMobileAccessReportOptions): IUseGetMobileAccessReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetMobileAccessReportOptions);

  const [executeGetMobileAccessReport, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetMobileAccessReportQueryGraphQLResponse,
    IGetMobileAccessReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportMobileAppAccess } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportMobileAppAccess,
        });
      },
      onError,
    },
    query: query ?? EXPORT_MOBILE_APP_ACCESS,
    token,
  });


  const handleGetMobileAccessReport = async ({
    dateFrom,
    dateTo,
    ianaTimeZone,
    projectId,
  }: IHandleGetMobileAccessReportParams) => {
    await executeGetMobileAccessReport({
      variables: {
        dateFrom,
        dateTo,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetMobileAccessReport,
    isLoading,
  };
};

export { useGetMobileAccessReport };
