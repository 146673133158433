import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_GROUP_TYPES } from './useGetContentGroupTypes.graphql';
import {
  IGetContentGroupTypesQueryGraphQLResponse,
  IGetContentGroupTypesQueryVariables,
  IHandleGetContentGroupTypesParams,
  IUseGetContentGroupTypes,
  IUseGetContentGroupTypesOptions,
} from './useGetContentGroupTypes.interfaces';

const useGetContentGroupTypes = (options?: IUseGetContentGroupTypesOptions): IUseGetContentGroupTypes => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetContentGroupTypesOptions);

  const [executeGetContentGroupTypes, { loading: isLoading }] = useEkardoLazyQuery<
  IGetContentGroupTypesQueryGraphQLResponse,
  IGetContentGroupTypesQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { getProject}
        } = data;

        const project = getProject ?? {};

        const { availableContentGroupType: contentGroupTypes = [] } = project;

        void onCompleted({
          contentGroupTypes,
        });
      },
      onError,
    },
    query: query ?? GET_CONTENT_GROUP_TYPES,
    token,
  });

  const handleGetContentGroupTypes = ({ projectId }: IHandleGetContentGroupTypesParams) => {
    void executeGetContentGroupTypes({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetContentGroupTypes,
    isLoading,
  };
};

export { useGetContentGroupTypes };
