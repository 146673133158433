import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_MULTI_LINE_TEXT_CONFIGURATION } from './useCreateMultiLineTextQuestionConfig.graphql';
import {
  ICreateMultiLineTextQuestionConfigMutationGraphQLResponse,
  ICreateMultiLineTextQuestionConfigMutationVariables,
  IHandleCreateMultiLineTextQuestionConfigParams,
  IUseCreateMultiLineTextQuestionConfigOptions,
  IUseCreateMultiLineTextQuestionConfig,
} from './useCreateMultiLineTextQuestionConfig.interfaces';

const useCreateMultiLineTextQuestionConfig = (options?: IUseCreateMultiLineTextQuestionConfigOptions): IUseCreateMultiLineTextQuestionConfig => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateMultiLineTextQuestionConfigOptions);

  const [executeCreateMultiLineTextQuestionConfig, { loading: isLoading }] = useEkardoMutation<ICreateMultiLineTextQuestionConfigMutationGraphQLResponse, ICreateMultiLineTextQuestionConfigMutationVariables>({
    mutation: mutation ?? CREATE_MULTI_LINE_TEXT_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { createQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateMultiLineTextQuestionConfig = async ({
    caseSensitive,
    description,
    maxLength,
    minLength,
    projectId,
    regex,
  }: IHandleCreateMultiLineTextQuestionConfigParams) => {
    await executeCreateMultiLineTextQuestionConfig({
      variables: {
        caseSensitive,
        description,
        maxLength,
        minLength,
        projectId,
        regex,
      }
    });
  };

  return {
    handleCreateMultiLineTextQuestionConfig,
    isLoading,
  };
};

export { useCreateMultiLineTextQuestionConfig };
