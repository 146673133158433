import { IContentSectionContainer } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getUpsertContentEventStatus } from "components";

const getContainerDefaultValues = ({ contentLayoutId, container } : { container?: IContentSectionContainer; contentLayoutId?: number; }): FormFieldProps => {
  const {
    title,
    contentEvent,
    visible: isVisible = true,
    // isSensitive = false,
  } = container ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);

  return {
    title: title ?? 'Container',
    contentLayoutId,
    isVisible,
    isSensitive: false,
    ...eventDetails,
    ...animationDetails,
  }
};

const getContainerCommonVariables = ({ containerId, item, eventAssetId, sort }: { containerId: number; eventAssetId?: string; item: FormFieldProps; sort: number}) => {

  const {
    title = 'Container',
    isVisible,
    isSensitive,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);

  const sharedVariables = {
    container: {
      isVisible,
      sectionId: Number(containerId),
      sort,
      title,
      isSensitive,
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId, type: 'CONTAINER' }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
  };

  return sharedVariables;

}

export { getContainerDefaultValues, getContainerCommonVariables };
