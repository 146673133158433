/* eslint-disable @typescript-eslint/indent */
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_USER_FLOWS } from './useGetUserFlows.graphql';
import {
  IGetUserFlowsQueryGraphQLResponse,
  IGetUserFlowsQueryVariables,
  IUseGetUserFlows,
  IUseGetUserFlowsOptions,
} from './useGetUserFlows.interfaces';

const useGetUserFlows = (options?: IUseGetUserFlowsOptions): IUseGetUserFlows => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetUserFlowsOptions);

  const [executeGetUserFlows, { loading: isLoading }] = useEkardoLazyQuery<IGetUserFlowsQueryGraphQLResponse, IGetUserFlowsQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ userFlow: { getUserFlows } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userflows: getUserFlows,
        });
      },
      onError,
    },
    query: query ?? GET_USER_FLOWS,
    token,
  });

  const handleGetUserFlows = async ({ projectId }: IGetUserFlowsQueryVariables) => {
    await executeGetUserFlows({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetUserFlows,
    isLoading,
  };
};

export { useGetUserFlows };
