import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_BULK_GLOSSARY_ITEMS_MUTATION } from './useCreateBulkGlossaryItems.graphql';
import {
  ICreateBulkGlossaryItemsMutationGraphQLResponse,
  ICreateBulkGlossaryItemsMutationVariables,
  IHandleCreateBulkGlossaryItemsParams,
  IUseCreateBulkGlossaryItemsOptions,
  IUseCreateBulkGlossaryItems,
} from './useCreateBulkGlossaryItems.interfaces';

const useCreateBulkGlossaryItems = (options?: IUseCreateBulkGlossaryItemsOptions): IUseCreateBulkGlossaryItems => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateBulkGlossaryItemsOptions);

  const [executeCreateBulkGlossaryItems, { loading: isLoading }] = useEkardoMutation<
  ICreateBulkGlossaryItemsMutationGraphQLResponse,
  ICreateBulkGlossaryItemsMutationVariables
  >({
    mutation: mutation ?? CREATE_BULK_GLOSSARY_ITEMS_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          bulkAdd: isCompleted
        } = data;

        void onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleCreateBulkGlossaryItems = ({
    projectId,
    items,
  }: IHandleCreateBulkGlossaryItemsParams) => {
    void executeCreateBulkGlossaryItems({
      variables: {
        projectId,
        request: items,
      },
    });
  };

  return {
    handleCreateBulkGlossaryItems,
    isLoading,
  };
};

export { useCreateBulkGlossaryItems };
