import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentPage } from '@netfront/ekardo-content-library';
import { FormFieldProps } from '@netfront/ui-library';
import { ICreatePageMutationParams, IUpdatePageMutationParams, useCreatePage, useToast, useUpdatePage } from 'hooks';



const useUpsertPage = ({ onCreate, onUpdate }: { onCreate: (returnedContentPage: IContentPage) => void; onUpdate: (returnedContentPage: IContentPage) => void }) => {
  const pageVariables = useRef<{value: FormFieldProps | undefined}>({value: undefined});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const getPageVariables = ({contentGroupId, contentPageId, type }: {contentGroupId?: number; contentPageId?: number; type: 'create' | 'update'; }): ICreatePageMutationParams | IUpdatePageMutationParams => {

    const { assetId, sensitiveContent, seoDescription, tags, title, url, sort } = pageVariables.current.value ?? {};

    const variableMap = {
      create: {
        assetId,
        contentGroupId: Number(contentGroupId),
        sensitiveContent,
        seoDescription,
        seoTitle: title,
        tags,
        title,
        url
      } as ICreatePageMutationParams,
      update: {
        assetId,
        contentPageId,
        sensitiveContent,
        seoDescription,
        seoTitle: title,
        sort: Number(sort),
        tags,
        title,
        url,

      } as IUpdatePageMutationParams
    };

    return variableMap[type];
  };


  const { handleCreatePage, isLoading: isCreatePageLoading = false } = useCreatePage({
    onCompleted: ({ contentPage: returnedContentPage }) => {
      handleToastSuccess({
        message: 'Page successfully created',
      });
      onCreate(returnedContentPage);
    },
    onError: handleGetError,
  });

  const { handleUpdatePage, isLoading: isUpdatePageLoading = false } = useUpdatePage({
    onCompleted: ({ contentPage: returnedContentPage }) => {
      handleToastSuccess({
        message: 'Page successfully updated',
      });
      onUpdate(returnedContentPage);
    },
    onError: handleGetError,
  });

  const handleUpsertPage = ({ contentGroupId, contentPageId, variables }: {contentGroupId?: number; contentPageId?: number; variables: FormFieldProps; }) => {
    pageVariables.current.value = variables;

    if (!contentPageId) {

      const createPageVariables = getPageVariables({
        contentPageId: Number(contentPageId),
        contentGroupId: Number(contentGroupId),
        type: 'create',
      }) as ICreatePageMutationParams;

      handleCreatePage({
        ...createPageVariables
      });

    } else {
      const updatePageVariables = getPageVariables({
        contentPageId: Number(contentPageId),
        type: 'update',
      }) as IUpdatePageMutationParams;

      handleUpdatePage({
        ...updatePageVariables,
      });
    }
  };

  return {
    isLoading: isCreatePageLoading || isUpdatePageLoading,
    handleUpsertPage,
  };
};
export { useUpsertPage };
