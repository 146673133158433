import { gql } from '@apollo/client';

const CREATE_TOPIC_MUTATION = gql`
  mutation CreateTopic($projectId: String!, $title: String!, $description: String, $status: ETopicStatus) {
    topic {
      addTopic(projectId: $projectId, title: $title, description: $description, status: $status) {
        id
        title
        description
        status
      }
    }
  }
`;

export { CREATE_TOPIC_MUTATION };
