import { ReactNode, useEffect, useState } from 'react';

import { AudioUpload, ImageUpload, VideoUpload, DocumentUpload } from '@netfront/ui-library';
import capitalize from 'lodash.capitalize';

import { UploaderProps } from './Uploader.interfaces';


const Uploader = ({
  asset,
  assetType,
  initialUrl,
  labelText,
  name,
  onDelete,
  onDrop ,
  handleDownload,
  tooltipText,
  isRequired = true,
  hasRemoveButton = true,
  isLabelSideBySide = false
}: UploaderProps) => {
  const [uploaderComponent, setUploaderComponent] = useState<ReactNode>(<></>);

  const label = labelText ?? `${capitalize(assetType)} upload`;
  const tooltip = tooltipText ?? `Upload your ${assetType}`;
  const defaultName = name ?? `${assetType}Url`;

  useEffect(() => {
    const ComponentMap: {[key: string]: ReactNode } = {
      image: () => <ImageUpload
        handleDownload={handleDownload}
        hasRemoveButton={hasRemoveButton}
        hasReplaceButton={Boolean(asset) && hasRemoveButton}
        id="id_image_upload"
        initialUrl={initialUrl}
        isLabelSideBySide={isLabelSideBySide}
        isRequired={isRequired}
        labelText={label}
        name={defaultName}
        tooltipText={tooltip}
        onDelete={onDelete}
        onDrop={onDrop}
      />,
      audio: () => <AudioUpload
        handleDownload={handleDownload}
        hasRemoveButton={hasRemoveButton}
        hasReplaceButton={Boolean(asset) && hasRemoveButton}
        id="id_audio_upload"
        initialUrl={initialUrl}
        isLabelSideBySide={isLabelSideBySide}
        isRequired={isRequired}
        labelText={label}
        name={defaultName}
        tooltipText={tooltip}
        onDelete={onDelete}
        onDrop={onDrop}
      />,
      video: () => <VideoUpload
        handleDownload={handleDownload}
        hasRemoveButton={hasRemoveButton}
        hasReplaceButton={Boolean(asset) && hasRemoveButton}
        id="id_video_upload"
        initialUrl={initialUrl}
        isRequired={isRequired}
        labelText={label}
        name={defaultName}
        tooltipText={tooltip}
        // isLabelSideBySide={isLabelSideBySide}
        onDelete={onDelete}
        onDrop={onDrop}
      />,
      document: () => <DocumentUpload
        asset={asset}
        handleDownload={handleDownload}
        hasRemoveButton={hasRemoveButton}
        hasReplaceButton={Boolean(asset) && hasRemoveButton}
        id="id_document_upload"
        isLabelSideBySide={isLabelSideBySide}
        isRequired={isRequired}
        labelText={label}
        name={defaultName}
        tooltipText={tooltip}
        onDelete={onDelete}
        onDrop={onDrop}
      />,
    }

    setUploaderComponent(ComponentMap[assetType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, assetType, initialUrl, handleDownload]);



  return <>{uploaderComponent}</>;
};

export { Uploader };
