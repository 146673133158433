import { gql } from '@apollo/client';

const CREATE_QUESTION_CONFIG_MESSAGE = gql`
  mutation createQuestionConfigMessages(
    $configurationId: Int!
    $messages: [QuestionConfigurationMessageInputType]!
  ) {
    questionConfiguration {
      defineMessages(
        configurationId: $configurationId
        messages: $messages
      ) {
        id
      }
    }
  }
`;

export { CREATE_QUESTION_CONFIG_MESSAGE };
