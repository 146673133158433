import { useState, useRef, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { IDBAsset, useCookie } from '@netfront/common-library';
import { ButtonIconOnly, DropzoneFileUpload, Label, Preloader, SidebarButtons, Spacing } from '@netfront/ui-library';
import { useCreateCommunityAsset, useToast } from 'hooks';
import { pushImageToAws } from 'utils';

import { communitySidebarThemeViewConstants } from './CommunitySidebarThemeView.constants';
import { CommunitySidebarThemeViewProps } from './CommunitySidebarThemeView.interfaces';

const CommunitySidebarThemeView = ({ onClose, projectId, selectedCommunity }: CommunitySidebarThemeViewProps) => {
  const uploadedProfileImageRef = useRef<HTMLImageElement>(null);
  const uploadedBannerImageRef = useRef<HTMLImageElement>(null);

  const { maxPostImageFileSize } = communitySidebarThemeViewConstants;

  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { handleToastError, handleToastSuccess } = useToast();

  const [droppedBannerImage, setDroppedBannerImage] = useState<File>();
  const [droppedProfileImage, setDroppedProfileImage] = useState<File>();
  const [isDroppedBannerImage, setIsDroppedBannerImage] = useState<boolean>(false);
  const [isDroppedProfileImage, setIsDroppedProfileImage] = useState<boolean>(false);
  const [isRemoveUploadedBannerImage, setIsRemoveUploadedBannerImage] = useState<boolean>(false);
  const [isRemoveUploadedProfileImage, setIsRemoveUploadedProfileImage] = useState<boolean>(false);
  const [isBannerImageUploadToAwsLoading, setIsBannerImageUploadToAwsLoading] = useState<boolean>(false);
  const [isProfileImageUploadToAwsLoading, setIsProfileImageUploadToAwsLoading] = useState<boolean>(false);
  const [currentProfileImage, setCurrentProfileImage] = useState(selectedCommunity?.profileImage);
  const [currentBannerImage, setCurrentBannerImage] = useState(selectedCommunity?.bannerImage);

  const { handleCreateCommunityAsset: executeCreateCommunityProfileAsset, isLoading: isCreateCommunityProfileAssetLoading = false } =
    useCreateCommunityAsset({
      onCompleted: async ({ signedUrl, presignedUrl, assetId }) => {
        setIsProfileImageUploadToAwsLoading(true);

        await pushImageToAws(signedUrl, droppedProfileImage, () => {
          setIsProfileImageUploadToAwsLoading(false);

          setCurrentProfileImage({
            ...currentProfileImage,
            assetId,
            fileName: droppedProfileImage?.name,
            fileSizeInBytes: droppedProfileImage?.size,
            contentType: droppedProfileImage?.type,
            presignedUrl,
          } as IDBAsset);

          handleToastSuccess({
            message: 'Profile image uploaded successfully',
          });
        }).catch((error) => {
          handleToastError({
            error,
          });
        });
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
      projectId: String(projectId),
    });

  const { handleCreateCommunityAsset: executeCreateCommunityBannerAsset, isLoading: isCreateCommunityBannerAssetLoading = false } =
    useCreateCommunityAsset({
      onCompleted: async ({ signedUrl, presignedUrl, assetId }) => {
        setIsBannerImageUploadToAwsLoading(true);

        const options = {
          method: 'PUT',
          body: droppedBannerImage,
        };

        await fetch(signedUrl, options)
          .then(() => {
            setIsBannerImageUploadToAwsLoading(false);

            setCurrentBannerImage({
              ...currentBannerImage,
              assetId,
              fileName: droppedProfileImage?.name,
              fileSizeInBytes: droppedProfileImage?.size,
              contentType: droppedProfileImage?.type,
              presignedUrl,
            } as IDBAsset);

            handleToastSuccess({
              message: 'Banner image uploaded successfully',
            });
          })
          .catch((error) => {
            handleToastError({
              error,
            });
          });
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
      projectId: String(projectId),
    });

  const handleDropProfileImage = (acceptedFile: File[]) => {
    const [file] = acceptedFile;

    const { name, type, size } = file;

    setDroppedProfileImage(file);
    setIsRemoveUploadedProfileImage(!isRemoveUploadedProfileImage);
    setIsDroppedProfileImage(true);

    void executeCreateCommunityProfileAsset({
      asset: {
        communityId: Number(selectedCommunity?.id),
        contentType: type,
        fileName: name,
        fileSizeInBytes: size,
        type: 'IMAGE',
        usage: 'PROFILE',
        alt: 'Profile image',
      },
    });
  };

  const handleDropBannerImage = (acceptedFile: File[]) => {
    const [file] = acceptedFile;

    const { name, type, size } = file;

    setDroppedBannerImage(file);
    setIsRemoveUploadedBannerImage(!isRemoveUploadedBannerImage);
    setIsDroppedBannerImage(true);

    void executeCreateCommunityBannerAsset({
      asset: {
        communityId: Number(selectedCommunity?.id),
        contentType: type,
        fileName: name,
        fileSizeInBytes: size,
        type: 'IMAGE',
        usage: 'BANNER',
        alt: 'Banner image',
      },
    });
  };

  const handleRemoveUploadedProfileImage = () => {
    setCurrentProfileImage((profile) => {
      if (!profile) {
        return;
      }

      return {
        ...profile,
        presignedUrl: '',
      };
    });

    setIsRemoveUploadedProfileImage(false);
    setIsDroppedProfileImage(false);
  };

  const handleRemoveUploadedBannerImage = () => {
    setCurrentBannerImage((banner) => {
      if (!banner) {
        return;
      }

      return {
        ...banner,
        presignedUrl: '',
      };
    });

    setIsRemoveUploadedBannerImage(false);
    setIsDroppedBannerImage(false);
  };

  const isLoading =
    isCreateCommunityProfileAssetLoading ||
    isCreateCommunityBannerAssetLoading ||
    isBannerImageUploadToAwsLoading ||
    isProfileImageUploadToAwsLoading;

  useEffect(() => {
    if (!isDroppedProfileImage && selectedCommunity) {
      return;
    }

    if (uploadedProfileImageRef.current === null) {
      return;
    }

    uploadedProfileImageRef.current.src = URL.createObjectURL(droppedProfileImage as Blob);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDroppedProfileImage]);

  useEffect(() => {
    if (!isDroppedBannerImage && selectedCommunity) {
      return;
    }

    if (uploadedBannerImageRef.current === null) {
      return;
    }

    uploadedBannerImageRef.current.src = URL.createObjectURL(droppedBannerImage as Blob);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDroppedBannerImage]);

  const isUploadedProfileImageVisible = currentProfileImage?.presignedUrl;
  const isProfileImageDropzoneVisible = !currentProfileImage?.presignedUrl;

  const isUploadedBannerImageVisible = currentBannerImage?.presignedUrl;
  const isBannerImageDropzoneVisible = !currentBannerImage?.presignedUrl;

  return (
    <div className="c-community-sidebar-theme-view">
      {isLoading && <Preloader isLoading={isLoading} />}

      {isUploadedProfileImageVisible && (
        <>
          <Spacing>
            <Label forId="image" labelText="Profile image upload" tooltipText="Upload your profile image"/>

            <div className="c-image-sidebar-general-view__uploaded-image-container">
              <div className="c-image-sidebar-general-view__uploaded-image">
                <img ref={uploadedProfileImageRef} alt="your image" src={currentProfileImage.presignedUrl} />

                <div className="c-remove-asset-button">
                  <ButtonIconOnly iconId="id_close_icon" text="" onClick={handleRemoveUploadedProfileImage} />
                </div>
              </div>
            </div>
          </Spacing>
        </>
      )}

      {isProfileImageDropzoneVisible && (
        <Spacing size="2x-large">
          <DropzoneFileUpload
            fileType="image"
            labelText="Profile image upload"
            maxFileSize={maxPostImageFileSize}
            tooltipText="Upload your profile image"
            onDrop={handleDropProfileImage}
          />
        </Spacing>
      )}

      {isUploadedBannerImageVisible && (
        <>
          <Spacing>
            <Label forId="image" labelText="Banner image upload"  tooltipText="Upload your banner image"/>

            <div className="c-image-sidebar-general-view__uploaded-image-container">
              <div className="c-image-sidebar-general-view__uploaded-image">
                <img ref={uploadedBannerImageRef} alt="your image" src={currentBannerImage.presignedUrl} />

                <div className="c-remove-asset-button">
                  <ButtonIconOnly iconId="id_close_icon" text="" onClick={handleRemoveUploadedBannerImage} />
                </div>
              </div>
            </div>
          </Spacing>
        </>
      )}

      {isBannerImageDropzoneVisible && (
        <Spacing size="2x-large">
          <DropzoneFileUpload
            fileType="image"
            labelText="Banner image upload"
            maxFileSize={maxPostImageFileSize}
            tooltipText="Upload your banner image"
            onDrop={handleDropBannerImage}
          />
        </Spacing>
      )}

      <SidebarButtons
        onCancel={onClose}
      />
    </div>
  );
};

export { CommunitySidebarThemeView };
