import React, { useState, useEffect, useRef, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { IContentSnippet } from '@netfront/ekardo-content-library';
import { ControlledForm, FormFieldProps, SidebarButtons, SingleCheckbox, Spinner, TabSet, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { AnimationTab, EventTab, UpsertSummarySnippetFeedbackTab, UpsertSummarySnippetGeneralTab, animationTabValidation, eventTabValidation, getSummarySnippetCommonVariables, getSummarySnippetDefaultValues, setQuickAddSummaryValues } from 'components';
import { CachingEntitiesContext } from 'context';
import { useUpsertAssetWrapper, useUpsertSnippet } from 'hooks';
import { Controller, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';



import { UpsertSummarySnippetSidebarViewProps } from './UpsertSummarySnippetSidebarView.interfaces';

const UpsertSummarySnippetSidebarView = ({ containerId, onClose, onCreate, onDelete, onUpdate, snippet, sort, isLoading: isSnippetLoading = false }: UpsertSummarySnippetSidebarViewProps) => {

  const { project } = useContext(CachingEntitiesContext);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);
  const [currentSort, setCurrentSort] = useState(sort);

  const { control, handleSubmit, reset, watch, setValue, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        userFlowStepId: yup.number().label('Module').required(),
        contentSnippetQuestionId: yup.number().label('Question').required(),
        ...eventTabValidation,
        ...animationTabValidation,
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();

  const { handleUpsertSnippet, isLoading: isUpsertSnippetLoading = false } = useUpsertSnippet({
    onCreate: () => {
      const { isCreateNew = false } = getValues();

      if (isCreateNew) {
        droppedFileEventAssetRef.current.value = undefined;
        hasDeletedOriginalImageRef.current.value = false;
        setCurrentSort(currentSort + 1);
        setDefaultValues(setQuickAddSummaryValues(getValues()));
      } else {
        reset();
      }
      onCreate(isCreateNew as boolean);
    },
    onUpdate: (returnedSnippet) => {
      reset();
      onUpdate(returnedSnippet);
    }
  });

  const handleSave = (item: FormFieldProps) => {

    const { assetId } = item;

    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(project?.id),
      uploadedFile: droppedFileEventAssetRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && assetId ? assetId : undefined,
      callBack: (asset?: IDBAsset) => {

        const commonVariables = getSummarySnippetCommonVariables({
          item,
          eventAssetId: asset ? String(asset.assetId): undefined
        });


        handleUpsertSnippet({
          containerId,
          snippetId: snippet? Number(snippet.id): undefined,
          sort: currentSort,
          variables: commonVariables,
        });

      }
    });
  };

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };


  useEffect(() => {
    setDefaultValues(getSummarySnippetDefaultValues({ snippet }));
  }, [snippet]);


  const additionalButton = !snippet ? (
    <Controller
      control={control}
      name="isCreateNew"
      render={({ field }) => (
        <SingleCheckbox
          hasPadding={false}
          id="id_is_create_new_snippet"
          isChecked={field.value}
          labelText="Create another snippet"
          {...field}
        />
      )}
    />
  ) : undefined;

  const isLoading =
    isUpsertSnippetLoading ||
    isUpsertAssetLoading ||
    isSnippetLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
      <TabSet
        defaultActiveTabId="id_general_tab"
        tabs={
          [
            {
              iconId: 'id_general_tab_icon',
              id: 'id_general_tab',
              label: 'General',
              view: () => isLoading ? <></> :(
                <>
                  <UpsertSummarySnippetGeneralTab
                    control={control}
                    initialQuestionId={defaultValues.contentSnippetQuestionId ? Number(defaultValues.contentSnippetQuestionId) : undefined}
                    initialUserFlowStepId={defaultValues.userFlowStepId ? Number(defaultValues.userFlowStepId): undefined }
                    setValue={setValue}
                  />
                  <SidebarButtons
                    additionalButton={additionalButton}
                    onCancel={onClose}
                    onDelete={snippet ? onDelete: undefined}
                    onSaveButtonType="submit"
                  />
                </>

              ),
            },
            {
              iconId: 'id_seo_tab_icon',
              id: 'id_feedback_tab',
              label: 'Feedback',
              isHidden: !snippet,
              view: () => isLoading ? <></> :(
                <UpsertSummarySnippetFeedbackTab
                  initialQuestionId={defaultValues.contentSnippetQuestionId}
                  snippet={snippet as IContentSnippet}
                  watch={watch}
                  onClose={onClose}
                  onDelete={onDelete}
                />
              ),
            },
            {
              iconId: 'id_usage_icon',
              id: 'id_event_tab',
              label: 'Event',
              view: () => isLoading ? <></> : (
                <>
                  <EventTab
                    control={control}
                    initialEvent={defaultValues.event}
                    onDeleteAsset={handleRemoveAsset}
                    onDrop={handleDropFile}
                  />
                  <SidebarButtons
                    additionalButton={additionalButton}
                    onCancel={onClose}
                    onDelete={snippet ? onDelete: undefined}
                    onSaveButtonType="submit"
                  />
                </>
              ),
            },
            {
              iconId: 'id_film_icon',
              id: 'id_animation_tab',
              label: 'Animation',
              view: () => isLoading ? <></> : (
                <>
                  <AnimationTab
                    animationType={defaultValues.animation ?? ''}
                    control={control}
                  />
                  <SidebarButtons
                    additionalButton={additionalButton}
                    onCancel={onClose}
                    onDelete={snippet ? onDelete: undefined}
                    onSaveButtonType="submit"
                  />
                </>
              ),
            },
          ]
        }
        />
      </ControlledForm>
    </>
  );
};


export { UpsertSummarySnippetSidebarView };
