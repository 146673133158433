import { gql } from '@apollo/client';

const EXPORT_QUESTIONNAIRES_FROM_REQUEST = gql`
  query ExportQuestionnairesFromRequest(
    $id: String!
  ) {
    report {
      get(id: $id) {
        asset {
          assetId
          presignedUrl
        }
      }
    }
  }
`;


export { EXPORT_QUESTIONNAIRES_FROM_REQUEST };
