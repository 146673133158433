import React from 'react';

import { Input } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { ConditionSliderQuestionProps } from './ConditionSliderQuestion.interfaces';


const ConditionSliderQuestion = ({ control }: ConditionSliderQuestionProps) => (
  <div className="c-action__item c-action__item__slider h-flex">
    <div className="c-action__item__slider--item">
      <Controller
        control={control}
        name="minValue"
        render={({ field }) => (
          <Input
            id="id_slider_min"
            labelText="Min value"
            tooltipPosition="start"
            tooltipText="The min value of slider"
            type="number"
            isLabelSideBySide
            {...field}
          />
        )}
      />
    </div>
    <div className="c-action__item__slider--item">
      <Controller
        control={control}
        name="maxValue"
        render={({ field }) => (
          <Input
            id="id_slider_max"
            labelText="Max value"
            tooltipPosition="start"
            tooltipText="The max value of slider"
            type="number"
            isLabelSideBySide
            {...field}
          />
        )}
      />
    </div>
  </div>
);

export { ConditionSliderQuestion };
