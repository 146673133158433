/* eslint-disable @typescript-eslint/indent */
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_LINKABLE_PAGES } from './useGetLinkablePages.graphql';
import {
  IGetLinkablePagesQueryGraphQLResponse,
  IGetLinkablePagesVariables,
  IUseGetLinkablePages,
  IUseGetLinkablePagesOptions,
} from './useGetLinkablePages.interfaces';

const useGetLinkablePages = (options?: IUseGetLinkablePagesOptions): IUseGetLinkablePages => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetLinkablePagesOptions);

  const [executeGetLinkablePages, { loading: isLoading }] = useEkardoLazyQuery<
    IGetLinkablePagesQueryGraphQLResponse,
    IGetLinkablePagesVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { search: { edges } },
        } = data;

        const contentPages = edges.map(({ node }) => node);

        onCompleted({
          contentPages,
        });
      },
      onError,
    },
    query: GET_LINKABLE_PAGES,
    token,
  });

  const handleGetLinkablePages = async ({ projectId, contentGroupId }: IGetLinkablePagesVariables) => {
    await executeGetLinkablePages({
      variables: {
        projectId,
        contentGroupId,
      },
    });
  };

  return {
    handleGetLinkablePages,
    isLoading,
  };
};

export { useGetLinkablePages };
