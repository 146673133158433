import { useEkardoMutation } from '@netfront/ekardo-content-library';

import {
  CREATE_CONDITION_ON_ACTION_CHECKBOX,
  CREATE_CONDITION_ON_ACTION_RADIO,
  CREATE_CONDITION_ON_ACTION_SLIDER,
  CREATE_CONDITION_ON_ACTION_TEXT,
  CREATE_CONDITION_ON_ACTION_DATE,
} from './useCreateCondition.graphql';
import { ICreateConditionMutationGraphQLResponse, ICreateConditionMutationVariables, IHandleCreateConditionParams, IUseCreateCondition, IUseCreateConditionOptions } from './useCreateCondition.interfaces';

const useCreateCondition = (options?: IUseCreateConditionOptions): IUseCreateCondition => {
  const { mutation, onCompleted, onError, token, conditionType } = options ?? ({} as IUseCreateConditionOptions);

  const addConditionMutationQueryMap = {
    checkbox: CREATE_CONDITION_ON_ACTION_CHECKBOX,
    radio: CREATE_CONDITION_ON_ACTION_RADIO,
    slider: CREATE_CONDITION_ON_ACTION_SLIDER,
    text: CREATE_CONDITION_ON_ACTION_TEXT,
    calendar: CREATE_CONDITION_ON_ACTION_DATE,
  };

  const [executeCreateCondition, { loading: isLoading }] = useEkardoMutation<ICreateConditionMutationGraphQLResponse, ICreateConditionMutationVariables>({
    mutation: mutation ?? addConditionMutationQueryMap[conditionType],
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { addConditionOnAction: condition },
        } = data;

        onCompleted({
          condition,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateCondition = (variables: IHandleCreateConditionParams) => {
    void executeCreateCondition({
      variables,
    });
  };

  return {
    handleCreateCondition,
    isLoading,
  };
};

export { useCreateCondition };
