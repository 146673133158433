import { useState, useEffect } from 'react';

import { DBContentGroupTypeType } from '@netfront/ekardo-content-library';
import { useRouter } from 'next/router';

import { ContentGroupList } from './ContentGroupList';


const ContentGroupPage = ({ isBetaVersion = false }: {isBetaVersion?: boolean }) => {
  const { query } = useRouter();
  const { type } = query;



  const [contentGroupType, setContentGroupType] = useState<DBContentGroupTypeType>();

  useEffect(() => {
    if (!type) {
      return;
    }

    setContentGroupType(String(type).toUpperCase() as DBContentGroupTypeType);
  }, [type]);


  return (
    contentGroupType ? <ContentGroupList contentGroupType={contentGroupType} isBetaVersion={isBetaVersion} /> : <></>
  );
};

export { ContentGroupPage };
