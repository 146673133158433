import { gql } from '@apollo/client';

const UPDATE_POST_MUTATION = gql`
  mutation UpdatePost($postId: Int!, $message: String!) {
    post {
      update(request: { id: $postId, message: $message, shareOption: EVERYONE, postType: FEED }) {
        id
        topic {
          id
          title
        }
        tags
        authorId
        author {
          id
          displayedName
          firstName
          lastName
        }
        message
        commentCount
        comments {
          edges {
            node {
              authorId
              author {
                id
                displayedName
                firstName
                lastName
                email
                postCount
              }
              message
              postId
            }
          }
        }
        createdDate
        createdDateTime
        assets {
          assetId
          s3Key
          contentType
          fileName
          presignedUrl
        }
      }
    }
  }
`;

export { UPDATE_POST_MUTATION };
