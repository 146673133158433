import { Column } from 'react-table';

import { IGroupsTableData } from './GroupsPage.interfaces';


const GROUPS_COLUMNS: ReadonlyArray<Column<IGroupsTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Group id</div>,
    width: '5%',
  },
  {
    accessor: 'type',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Type</div>,
    width: '5%',
  },
  {
    accessor: 'groupUrl',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group url</span>,
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
  },
  {
    accessor: 'addressLine1',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Address 1</span>,
  },
  {
    accessor: 'addressLine2',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Address 2</span>,
  },
  {
    accessor: 'city',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">City</span>,
  },
  {
    accessor: 'state',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">State</span>,
  },
  {
    accessor: 'postCode',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Postcode</span>,
  },
  {
    accessor: 'countryCode',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Country code</span>,
    width: '5%',
  },
  {
    accessor: 'phoneNumber',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Phone number</span>,
  },
  {
    accessor: 'detailUrl',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Detail url</span>,
  },
  {
    accessor: 'creationDateUtc',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Creation date (utc)</span>,
  },
];

export { GROUPS_COLUMNS };
