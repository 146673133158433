

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { EXPORT_GROUPS } from './useGetGroupsReport.graphql';
import {
  IGetGroupsReportQueryGraphQLResponse,
  IGetGroupsReportQueryVariables,
  IHandleGetGroupsReportParams,
  IUseGetGroupsReport,
  IUseGetGroupsReportOptions,
} from './useGetGroupsReport.interfaces';

const useGetGroupsReport = (options?: IUseGetGroupsReportOptions): IUseGetGroupsReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetGroupsReportOptions);

  const [executeGetGroupsReport, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGroupsReportQueryGraphQLResponse,
    IGetGroupsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportGroups } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportGroups,
        });
      },
      onError,
    },
    query: query ?? EXPORT_GROUPS,
    token,
  });


  const handleGetGroupsReport = async ({
    fileType,
    projectId,
  }: IHandleGetGroupsReportParams) => {
    await executeGetGroupsReport({
      variables: {
        fileType,
        projectId,
      },
    });
  };

  return {
    handleGetGroupsReport,
    isLoading,
  };
};

export { useGetGroupsReport };
