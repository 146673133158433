import { DBQuestionActionTypeNameType } from "@netfront/ekardo-content-library";
import { IEntityType } from "interfaces";

const WRAPPER_TYPE_ACTION_KEY_MAP: {[ key: string]: string} = {
  container: 'containerId',
  section: 'sectionId',
  targetSnippet: 'targetSnippetId',
  page: 'contentPageId',
};

const QUESTION_CONDITION_TYPE_MAP: {[key: string]: string } = {
  QuestionConditionCheckboxSelectedType: 'checkbox',
  QuestionConditionRadioResponseSelectedType: 'radio',
  QuestionConditionTextType: 'text',
  QuestionConditionSliderValueSelectedType: 'slider',
  QuestionConditionDateSelectedType: 'calendar',
};

type ActionDefaultTypeMap = Record<IEntityType, DBQuestionActionTypeNameType>

const ACTION_DEFAULT_TYPE: ActionDefaultTypeMap =  {
  targetSnippet: 'QuestionActionHideType',
  container: 'QuestionActionHideContainerType',
  section: 'QuestionActionHideSectionType',
  page: 'QuestionActionSkipPageType',
  sectionGroup: 'QuestionActionHideType',

};

export { QUESTION_CONDITION_TYPE_MAP, WRAPPER_TYPE_ACTION_KEY_MAP, ACTION_DEFAULT_TYPE };
