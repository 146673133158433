import { gql } from '@apollo/client';

const GET_ALL_PROJECT_DISCOUNTS_QUERY = gql`
  query GetAllProjectDiscounts($projectGuid: String!) {
    discounts {
      getAllProjectDiscounts(projectGuid: $projectGuid) {
        code
        createdAtUtc
        description
        expiresAtUtc
        id
        maxUsage
        modifiedAtUtc
        percentage
        projectGuid
        startsAtUtc
        status
      }
    }
  }
`;

export { GET_ALL_PROJECT_DISCOUNTS_QUERY };
