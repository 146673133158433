

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { EXPORT_ALL_MODULE_PAGES } from './useGetAllModulePagesReport.graphql';
import {
  IGetAllModulePagesReportQueryGraphQLResponse,
  IGetAllModulePagesReportQueryVariables,
  IHandleGetAllModulePagesReportParams,
  IUseGetAllModulePagesReport,
  IUseGetAllModulePagesReportOptions,
} from './useGetAllModulePagesReport.interfaces';

const useGetAllModulePagesReport = (options?: IUseGetAllModulePagesReportOptions): IUseGetAllModulePagesReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAllModulePagesReportOptions);

  const [executeGetAllModulePagesReport, { loading: isLoading }] = useEkardoLazyQuery<
    IGetAllModulePagesReportQueryGraphQLResponse,
    IGetAllModulePagesReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportAllModulePages } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportAllModulePages,
        });
      },
      onError,
    },
    query: query ?? EXPORT_ALL_MODULE_PAGES,
    token,
  });


  const handleGetAllModulePagesReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    userFlowId,
  }: IHandleGetAllModulePagesReportParams) => {
    await executeGetAllModulePagesReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        userFlowId,
      },
    });
  };

  return {
    handleGetAllModulePagesReport,
    isLoading,
  };
};

export { useGetAllModulePagesReport };
