/* eslint-disable @typescript-eslint/indent */
import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { QUERY } from './useCreateNotificationGroup.graphql';
import {
  ICreateNotificationGroupQueryGraphQLResponse,
  ICreateNotificationGroupQueryVariables,
  IUseCreateNotificationGroup,
  IUseCreateNotificationGroupOptions,
} from './useCreateNotificationGroup.interfaces';

const useCreateNotificationGroup = (options?: IUseCreateNotificationGroupOptions): IUseCreateNotificationGroup => {
  const { onCompleted, onError, token } = options ?? ({} as IUseCreateNotificationGroupOptions);

  const [executeCreateNotificationGroup, { loading: isLoading }] = useHowlerMutation<
    ICreateNotificationGroupQueryGraphQLResponse,
    ICreateNotificationGroupQueryVariables
  >({
    options: {
      onCompleted: () => {
        if (!onCompleted) {
          return;
        }

        onCompleted({});
      },
      onError,
    },
    mutation: QUERY,
    token,
  });

  const handleCreateNotificationGroup = async ({ notificationGroup, type, date }: ICreateNotificationGroupQueryVariables) => {
    await executeCreateNotificationGroup({
      variables: {
        notificationGroup,
        type,
        date,
      },
    });
  };

  return {
    handleCreateNotificationGroup,
    isLoading,
  };
};

export { useCreateNotificationGroup };
