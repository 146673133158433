import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_DIRECTORY_TYPES } from './useGetDirectoryTypes.graphql';
import {
  IGetDirectoryTypesQueryGraphQLResponse,
  IGetDirectoryTypesQueryVariables,
  IHandleGetDirectoryTypesParams,
  IUseGetDirectoryTypes,
  IUseGetDirectoryTypesOptions,
} from './useGetDirectoryTypes.interfaces';

const useGetDirectoryTypes = (options?: IUseGetDirectoryTypesOptions): IUseGetDirectoryTypes => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetDirectoryTypesOptions);

  const [executeGetDirectoryTypes, { loading: isLoading }] = useEkardoLazyQuery<IGetDirectoryTypesQueryGraphQLResponse, IGetDirectoryTypesQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          directoryType: { get: directoryTypes },
        } = data;

        onCompleted({directoryTypes});
      },
      onError,
    },
    query: query ?? GET_DIRECTORY_TYPES,
    token,
  });

  const handleGetDirectoryTypes = ({ projectId }: IHandleGetDirectoryTypesParams) => {
    void executeGetDirectoryTypes({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetDirectoryTypes,
    isLoading,
  };
};

export { useGetDirectoryTypes };
