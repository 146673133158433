import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus } from "components";

const getTextSnippetDefaultValues = ({ snippet } : { snippet?: IContentSnippet;}): FormFieldProps => {
  const {
    title,
    contentEvent,
    text,
    visible: isVisible = true,
    // isSensitive = false,
  } = snippet ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);

  return {
    title: title ?? 'Text snippet',
    text: text ?? '',
    isVisible,
    isSensitive: false,
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
  };
};

const getTextSnippetCommonVariables = ({ eventAssetId, item }: { eventAssetId?: IDBAsset['assetId']; item: FormFieldProps; }) => {
  const {
    title = 'Text snippet',
    text,
    isVisible,
    // isSensitive,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
    },
    textSnippet: {
      text,
    },
    type: 'text',
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
  };

  return sharedVariables;
}

const setQuickAddTextValues = (values: FormFieldProps) => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Text snippet',
    text: '',
  };
};

export { getTextSnippetDefaultValues, getTextSnippetCommonVariables, setQuickAddTextValues };
