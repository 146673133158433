import { gql } from '@apollo/client';

const TOGGLE_ASSET_PUBLIC_MUTATION = gql`
  mutation ToggleAssetPublic($assetId: String!) {
    asset {
      toggleAssetPublic(assetId: $assetId) {
        s3Key
        contentType
        assetId
        alt
        fileName
        tagList {
          id
          name
        }
        transcript
        alt
        xAxisFocus
        yAxisFocus
        uploaded
        isPublic
        presignedUrl
        publicS3Key
        fileName
        publicUrl
        title
      }
    }
  }
`;

export { TOGGLE_ASSET_PUBLIC_MUTATION };
