import { IDBDirectory, useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_DIRECTORIES } from './useGetDirectories.graphql';
import {
  IGetDirectoriesQueryGraphQLResponse,
  IGetDirectoriesQueryVariables,
  IHandleGetDirectoriesParams,
  IUseGetDirectories,
  IUseGetDirectoriesOptions,
} from './useGetDirectories.interfaces';

const useGetDirectories = (options?: IUseGetDirectoriesOptions): IUseGetDirectories => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetDirectoriesOptions);

  const [executeGetDirectories, { loading: isLoading }] = useEkardoLazyQuery<IGetDirectoriesQueryGraphQLResponse, IGetDirectoriesQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data: { directory: { searchDirectories: IDBDirectory[]; }; }) => {
        if (!onCompleted) {
          return;
        }

        const {
          directory: { searchDirectories: directories },
        } = data;

        onCompleted({
          directories,
        });
      },
      onError,
    },
    query: query ?? GET_DIRECTORIES,
    token,
  });

  const handleGetDirectories = ({ directoryTypeId, projectId, title }: IHandleGetDirectoriesParams) => {
    void executeGetDirectories({
      variables: {
        directoryTypeId,
        projectId,
        title
      },
    });
  };

  return {
    handleGetDirectories,
    isLoading,
  };
};

export { useGetDirectories };
