import { gql } from '@apollo/client';

const GET_COUNTRIES_QUERY = gql`
  query getCountries {
    __type(name: "ECountries") {
      enumValues {
        name
        description
      }
    }
  }
`;

export { GET_COUNTRIES_QUERY };
