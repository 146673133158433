
import { IGroupsContactsTableData, IGroupsContactsTableDataParams } from "./GroupsContactsPage.interfaces";


const getGroupsContactsTableData = ({ items }: IGroupsContactsTableDataParams): IGroupsContactsTableData[] => {
  return items.map(({
    detailUrl = '',
    displayName = '',
    groupAddressCity = '',
    groupAddressCountry = '',
    groupAddressLine1 = '',
    groupAddressLine2 = '',
    groupAddressState = '',
    groupName = '',
    id,
   }) => {
    return {
      detailUrl,
      displayName,
      groupAddressCity,
      groupAddressCountry,
      groupAddressLine1,
      groupAddressLine2,
      groupName,
      groupAddressState,
      id,
    };
  });
};

export { getGroupsContactsTableData };
