import { ApolloQueryResult } from '@apollo/client';
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { SEARCH_PAGINATED_TAGS_QUERY } from './useSearchPaginatedTags.graphql';
import {
  ISearchPaginatedTagsQueryGraphQLResponse,
  ISearchPaginatedTagsQueryVariables,
  IHandleFetchMorePaginatedTagsParams,
  IHandleSearchPaginatedTagsParams,
  IUseSearchPaginatedTags,
  IUseSearchPaginatedTagsOptions,
} from './useSearchPaginatedTags.interfaces';

const useSearchPaginatedTags = (options?: IUseSearchPaginatedTagsOptions): IUseSearchPaginatedTags => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseSearchPaginatedTagsOptions);

  const [executeSearchPaginatedTags, { fetchMore, loading: isLoading }] = useEkardoLazyQuery<
  ISearchPaginatedTagsQueryGraphQLResponse,
  ISearchPaginatedTagsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          tag: { search: { edges, totalCount } },
        } = data;

        onCompleted({
          edges,
          totalCount,
        });
      },
      onError,
    },
    query: query ?? SEARCH_PAGINATED_TAGS_QUERY,
    token,
  });

  const handleFetchMorePaginatedTags = ({
    after,
    filter,
    first,
    projectId,
  }: IHandleFetchMorePaginatedTagsParams): Promise<ApolloQueryResult<ISearchPaginatedTagsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          tag: { search: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          tag: { search: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          tag: {
            ...previouslyFetchedScenarioConnection,
            search: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  const handleSearchPaginatedTags = async ({ after, filter, first, projectId, entities }: IHandleSearchPaginatedTagsParams) => {
    await executeSearchPaginatedTags({
      variables: {
        after,
        filter,
        first,
        projectId,
        entities,
      },
    });
  };

  return {
    handleFetchMorePaginatedTags,
    handleSearchPaginatedTags,
    isLoading,
  };
};

export { useSearchPaginatedTags };
