import { gql } from '@apollo/client';

const EXPORT_BOOKMARKS = gql`
  query ExportBookmarks($ianaTimeZone: String!, $projectId: String, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    report {
      exportBookmarks(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_BOOKMARKS };
