import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_SLIDER_CONFIGURATION } from './useCreateSliderQuestionConfig.graphql';
import {
  ICreateSliderQuestionConfigMutationGraphQLResponse,
  ICreateSliderQuestionConfigMutationVariables,
  IHandleCreateSliderQuestionConfigParams,
  IUseCreateSliderQuestionConfigOptions,
  IUseCreateSliderQuestionConfig,
} from './useCreateSliderQuestionConfig.interfaces';

const useCreateSliderQuestionConfig = (options?: IUseCreateSliderQuestionConfigOptions): IUseCreateSliderQuestionConfig => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateSliderQuestionConfigOptions);

  const [executeCreateSliderQuestionConfig, { loading: isLoading }] = useEkardoMutation<ICreateSliderQuestionConfigMutationGraphQLResponse, ICreateSliderQuestionConfigMutationVariables>({
    mutation: mutation ?? CREATE_SLIDER_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { createQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateSliderQuestionConfig = async ({
    defaultValue,
    description,
    maxValue,
    minValue,
    projectId,
    step,
    ticks,
    title,
  }: IHandleCreateSliderQuestionConfigParams) => {
    await executeCreateSliderQuestionConfig({
      variables: {
        defaultValue,
        description,
        maxValue,
        minValue,
        projectId,
        step,
        ticks,
        title,
      }
    });
  };

  return {
    handleCreateSliderQuestionConfig,
    isLoading,
  };
};

export { useCreateSliderQuestionConfig };
