import { gql } from '@apollo/client';

const CREATE_COMMUNITY_ASSET_MUTATION = gql`
  mutation CreateCommunityAsset($asset: CreateCommunityAssetInputType!) {
    asset {
      createCommunityAsset(asset: $asset) {
        asset {
          presignedUrl
        }
        signedUrl
      }
    }
  }
`;

export { CREATE_COMMUNITY_ASSET_MUTATION };
