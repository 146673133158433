import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_TAG_MUTATION } from './useUpdateTag.graphql';
import {
  IUpdateTagMutationGraphQLResponse,
  IUpdateTagMutationVariables,
  IHandleUpdateTagParams,
  IUseUpdateTagOptions,
  IUseUpdateTag,
} from './useUpdateTag.interfaces';

const useUpdateTag = (options?: IUseUpdateTagOptions): IUseUpdateTag => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateTagOptions);

  const [executeUpdateTag, { loading: isLoading }] = useEkardoMutation<IUpdateTagMutationGraphQLResponse, IUpdateTagMutationVariables>({
    mutation: mutation ?? UPDATE_TAG_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          tagMutation: {
            editTag: isCompleted,
          },
        } = data;

        void onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleUpdateTag = async ({ value, tagId }: IHandleUpdateTagParams) => {
    await executeUpdateTag({
      variables: {
        value,
        tagId,
      },
    });
  };

  return {
    handleUpdateTag,
    isLoading,
  };
};

export { useUpdateTag };
