import { useBonoboMutation } from '../useBonoboMutation';

import { CREATE_COMMUNITY_ASSET_MUTATION } from './useCreateCommunityAsset.graphql';
import {
  ICreateCommunityAssetMutationGraphQLResponse,
  ICreateCommunityAssetMutationVariables,
  IHandleCreateCommunityAssetParams,
  IUseCreateCommunityAssetOptions,
  IUseCreateCommunityAsset,
} from './useCreateCommunityAsset.interfaces';

const useCreateCommunityAsset = (options?: IUseCreateCommunityAssetOptions): IUseCreateCommunityAsset => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseCreateCommunityAssetOptions);

  const [executeCreateCommunityAsset, { loading: isLoading }] = useBonoboMutation<
    ICreateCommunityAssetMutationGraphQLResponse,
    ICreateCommunityAssetMutationVariables
  >({
    mutation: mutation ?? CREATE_COMMUNITY_ASSET_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: {
            createCommunityAsset: { asset: assetConnection, signedUrl },
          },
        } = data;

        const newAsset = {
          ...assetConnection,
          signedUrl,
        };

        void onCompleted(newAsset);
      },
      onError,
    },
    token,
  });

  const handleCreateCommunityAsset = ({ asset }: IHandleCreateCommunityAssetParams) => {
    void executeCreateCommunityAsset({
      variables: {
        asset,
      },
    });
  };

  return {
    handleCreateCommunityAsset,
    isLoading,
  };
};

export { useCreateCommunityAsset };
