import { gql } from '@apollo/client';

const DELETE_FORM_MUTATION = gql`
  mutation UpdateContentGroup($contentGroupId: Int!, $status: EContentGroupStatus!) {
    contentGroup {
      updateContentGroupStatus(id: $contentGroupId, status: $status) {
        id
      }
    }
  }
`;

export { DELETE_FORM_MUTATION };
