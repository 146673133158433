import { Avatar, FlexContainer, SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IUserGroupTableData } from './GroupAccessPage.interfaces';


const GROUP_STATUS_TYPE = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
});

const GROUPS_TABLE_COLUMNS: ReadonlyArray<Column<IUserGroupTableData>> = [
  {
    accessor: 'avatarData',

    Cell: ({ value: { name } }) => (
      <FlexContainer gap="x-small">
        <div className="c-avatar-title relative">
          <Avatar size="small" title={name} />
        </div>
        <div className="text-left w-full word-break-all">{name}</div>
      </FlexContainer>
    ),
    Header: () => <div className="text-left w-full">Title</div>,
    width: '30%',
  },
  {
    accessor: 'userCount',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User count</div>,
    width: '30%',
  },
  {
    accessor: 'created',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value.toString()}</div>,
    Header: () => <div className="text-left w-full">Registered</div>,
    width: '30%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update group" onClick={() => onClick(id)} />,
    width: '5%',
  },
];

export { GROUPS_TABLE_COLUMNS, GROUP_STATUS_TYPE };
