import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus } from "components";

const getCodeSnippetDefaultValues = ({ snippet, htmlContent = '' } : { htmlContent?: string; snippet?: IContentSnippet;}): FormFieldProps => {
  const {
    title,
    contentEvent,
    location,
    visible: isVisible = true,
    // isSensitive = false,
  } = snippet ?? {};

  const { assetId: htmlAssetId } = location ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  return {
    title: title ?? 'Code snippet',
    htmlContent,
    isVisible,
    isSensitive: false,
    initialHtmlAssetId: htmlAssetId,
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
  };
};

const getCodeSnippetCommonVariables = ({codeSnippetAsset, eventAssetId, item, }: {codeSnippetAsset: IDBAsset; eventAssetId?: IDBAsset['assetId']; item: FormFieldProps; }) => {

  const {
    title = 'Code snippet',
    isVisible,
    // isSensitive,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
    },
    codeSnippet: {
      locationId: codeSnippetAsset.assetId,
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    type: 'code',
  };

  return sharedVariables;

};

const setQuickAddCodeValues = (values: FormFieldProps) => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Code snippet',
    htmlContent: '',
    initialHtmlAssetId: undefined,
  };
};

export { getCodeSnippetDefaultValues, getCodeSnippetCommonVariables, setQuickAddCodeValues };
