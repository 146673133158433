import { gql } from '@apollo/client';

const CREATE_RESPONSE_SET = gql`
  mutation createResponseSet(
    $projectId: String!
    $title: String!
    $responsesRadio: [QuestionResponseRadioInputType]
    $responsesCheckbox: [QuestionResponseCheckboxInputType]
    $responsesDropDownList: [QuestionResponseDropDownListInputType]
  ) {
    questionSet {
      add(
        projectId: $projectId
        title: $title
        responsesRadio: $responsesRadio
        responsesCheckbox: $responsesCheckbox
        responsesDropDownList: $responsesDropDownList
      ) {
        id
      }
    }
  }
`;

export { CREATE_RESPONSE_SET };
