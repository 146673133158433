

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { EXPORT_BOOKMARKS } from './useGetBookmarksReport.graphql';
import {
  IGetBookmarksReportQueryGraphQLResponse,
  IGetBookmarksReportQueryVariables,
  IHandleGetBookmarksReportParams,
  IUseGetBookmarksReport,
  IUseGetBookmarksReportOptions,
} from './useGetBookmarksReport.interfaces';

const useGetBookmarksReport = (options?: IUseGetBookmarksReportOptions): IUseGetBookmarksReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetBookmarksReportOptions);

  const [executeGetBookmarksReport, { loading: isLoading }] = useEkardoLazyQuery<
    IGetBookmarksReportQueryGraphQLResponse,
    IGetBookmarksReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportBookmarks } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportBookmarks,
        });
      },
      onError,
    },
    query: query ?? EXPORT_BOOKMARKS,
    token,
  });


  const handleGetBookmarksReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetBookmarksReportParams) => {
    await executeGetBookmarksReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetBookmarksReport,
    isLoading,
  };
};

export { useGetBookmarksReport };
