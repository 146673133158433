/* eslint-disable @typescript-eslint/no-unsafe-return */

import { SettingsButton } from '@netfront/ui-library';

import { IGetLibraryDirectoryTableDataParams, ILibraryDirectoryTableData } from './LibraryDirectoryPage.interfaces';


const getLibraryDirectoryTableData = ({ directories, onSettingsButtonClick }: IGetLibraryDirectoryTableDataParams): ILibraryDirectoryTableData[] => {
  return directories.map(
    (directory) => {

      const {  title, url, phoneNumber, status, asset, id } = directory
      const { presignedUrl } = asset ?? {};

      const tableData: ILibraryDirectoryTableData = {
        avatar: {
          title,
          presignedUrl,
        },
        id,
        presignedUrl,
        link: url,
        phone: String(phoneNumber),
        status: (
          <button className="c-status-button" type="button" onClick={() => onSettingsButtonClick(directory)}>
            <span className={`c-status-icon--${status.toLowerCase()}`} />
            {status}
          </button>
        ),
        settings: <SettingsButton supportiveText={`${String(title)} settings`} onClick={() => onSettingsButtonClick(directory)} />,
      };

      return tableData;
    },
  );
};

export { getLibraryDirectoryTableData };
