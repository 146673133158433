import { gql } from '@apollo/client';

const DELETE_GROUP_ACCESS = gql`
  mutation deleteGroupAccess($userFlowId: Int!, $groupId: Int!) {
    userFlowGroupAccessMutation {
      delete(userFlowId: $userFlowId, groupId: $groupId)
    }
  }
`;

export { DELETE_GROUP_ACCESS };
