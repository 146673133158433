

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { EXPORT_MODULE_PAGES } from './useGetModulePagesReport.graphql';
import {
  IGetModulePagesReportQueryGraphQLResponse,
  IGetModulePagesReportQueryVariables,
  IHandleGetModulePagesReportParams,
  IUseGetModulePagesReport,
  IUseGetModulePagesReportOptions,
} from './useGetModulePagesReport.interfaces';

const useGetModulePagesReport = (options?: IUseGetModulePagesReportOptions): IUseGetModulePagesReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetModulePagesReportOptions);

  const [executeGetModulePagesReport, { loading: isLoading }] = useEkardoLazyQuery<
    IGetModulePagesReportQueryGraphQLResponse,
    IGetModulePagesReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportModulePages } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportModulePages,
        });
      },
      onError,
    },
    query: query ?? EXPORT_MODULE_PAGES,
    token,
  });


  const handleGetModulePagesReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    stepId,
  }: IHandleGetModulePagesReportParams) => {
    await executeGetModulePagesReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        stepId,
      },
    });
  };

  return {
    handleGetModulePagesReport,
    isLoading,
  };
};

export { useGetModulePagesReport };
