import { gql } from '@apollo/client';

const DELETE_CHARACTER_MUTATION = gql`
  mutation DeleteCharacter($characterId: Int!) {
    character {
      removeCharacter(characterId: $characterId)
    }
  }
`;

export { DELETE_CHARACTER_MUTATION };
