import { useContext, useEffect, useState } from 'react';

import { DashboardListTemplatePage } from 'components';
import { CachingEntitiesContext } from 'context';


const ReportingFeedbackDashboardPage = () => {
  const { project } = useContext(CachingEntitiesContext);

  const [projectName, setProjectName] = useState<string>(project?.name ?? '');
  const [projectId, setProjectId] = useState<string>(project?.id ?? '');
  const [organisationKey, setOrganisationKey] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name, organisationId, id } = project;

    setProjectId(id);
    setProjectName(name);
    setOrganisationKey(organisationId);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, project?.organisationId, project?.id]);

  return (
    <DashboardListTemplatePage
      activePage="reporting"
      activeSubPage="reporting-feedback"
      additionalClassNames="c-library-dashboard__page-layout"
      breadcrumbItems={[
        {
          key: '0',
          content: <a href={`/dashboard/${String(organisationKey)}/${String(projectId)}`}>Dashboard</a>,
        },
        {
          key: '1',
          content: 'Feedback reporting',
        },
      ]}
      description={`Feedback reporting dashboard for ${projectName || 'the project'}`}
      informationBoxMessage={`Download reports based on type`}
      isLoading={isLoading}
      logoUrl={project?.logo?.presignedUrl}
      pageId="reporting-feedback"
      pageTitle={`${projectName ? projectName + ' feedback reporting' : 'Feedback reporting'} dashboard`}
      projectName={projectName}
    />

  );
};

export { ReportingFeedbackDashboardPage };
