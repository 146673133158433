import { gql } from '@apollo/client';

const CREATE_MULTI_LINE_FEEDBACK_CONFIG = gql`
  mutation createMultiLineSummaryFeedbackConfig($contentSnippetSummaryId: Int!, $label: String!) {
    summaryFeedback {
      addSummaryFeedbackConfigurationRankAndNote(
        contentSnippetSummaryId: $contentSnippetSummaryId
        label: $label
        configuration: [NOTE, EDIT_NOTE]
      )
    }
  }
`;


export { CREATE_MULTI_LINE_FEEDBACK_CONFIG };
