

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { EXPORT_LOGIN_TRIALS } from './useGetLoginsTrialsReport.graphql';
import {
  IGetLoginsTrialsReportQueryGraphQLResponse,
  IGetLoginsTrialsReportQueryVariables,
  IHandleGetLoginsTrialsReportParams,
  IUseGetLoginsTrialsReport,
  IUseGetLoginsTrialsReportOptions,
} from './useGetLoginsTrialsReport.interfaces';

const useGetLoginsTrialsReport = (options?: IUseGetLoginsTrialsReportOptions): IUseGetLoginsTrialsReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetLoginsTrialsReportOptions);

  const [executeGetLoginsTrialsReport, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetLoginsTrialsReportQueryGraphQLResponse,
    IGetLoginsTrialsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ customQueries: { ourfutures: { trialLogins } } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: trialLogins,
        });
      },
      onError,
    },
    query: query ?? EXPORT_LOGIN_TRIALS,
    token,
  });


  const handleGetLoginsTrialsReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetLoginsTrialsReportParams) => {
    await executeGetLoginsTrialsReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetLoginsTrialsReport,
    isLoading,
  };
};

export { useGetLoginsTrialsReport };
