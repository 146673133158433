import { DBContentSnippetTypeNameType } from "@netfront/ekardo-content-library";

export const underScoredToResponseTypeConst: { [key: string]: string } = {
  CALENDAR: 'Calendar',
  CHECKBOX: 'Checkbox',
  DROP_DOWN_LIST: 'DropDownList',
  MATCH: 'Match',
  MULTI_RESPONSE: 'MultiResponseText',
  MULTI_TEXT: 'MultiText',
  NUMBER: 'Number',
  RADIO: 'Radio',
  RATING: 'Rating',
  SINGLE_TEXT: 'SingleText',
  SLIDER: 'Slider',
};

export const responseTypeToUnderScoredConst: { [key: string]: string } = {
  Calendar: 'CALENDAR',
  Checkbox: 'CHECKBOX',
  DropDownList: 'DROP_DOWN_LIST',
  Match: 'MATCH',
  MultiResponseText: 'MULTI_RESPONSE',
  MultiText: 'MULTI_TEXT',
  Number: 'NUMBER',
  Radio: 'RADIO',
  Rating: 'RATING',
  SingleText: 'SINGLE_TEXT',
  Slider: 'SLIDER',
};

export const underScoredResponseTypeToFriendly: { [key: string]: string } = {
  CALENDAR: 'Calendar',
  CHECKBOX: 'Multi select',
  DROP_DOWN_LIST: 'Drop down list',
  MATCH: 'Match',
  MULTI_RESPONSE: 'Multi response',
  MULTI_TEXT: 'Multi line text',
  NUMBER: 'Number',
  RADIO: 'Single select',
  RATING: 'Rating',
  SINGLE_TEXT: 'Single line text',
  SLIDER: 'Slider',
};

export const responseTypeToFriendly: { [key: string]: string } = {
  Calendar: 'Calendar',
  Checkbox: 'Checkbox',
  DropDownList: 'Drop down list',
  Match: 'Match',
  MultiResponseText: 'Multi-answer',
  MultiText: 'Multi line text',
  Number: 'Number',
  Radio: 'Radio',
  Rating: 'Rating',
  SingleText: 'Single line text',
  Slider: 'Slider',
};

export const responseShortToFriendly = {
  Ca: 'Calendar',
  CB: 'Checkbox',
  DD: 'Match',
  Dd: 'Drop down list',
  Nu: 'Number',
  Ma: 'Match',
  ML: 'Multi line text',
  MR: 'Multi-answer',
  RB: 'Radio',
  SL: 'Single line text',
  Sl: 'Slider',
};


export const SnippetTypeConst: {[key: string]: DBContentSnippetTypeNameType} = {
  APP: 'ContentSnippetAppType',
  AUDIO: 'ContentSnippetAudioType',
  BUTTON: 'ContentSnippetButtonType',
  CLUSTER: 'ContentSnippetClusterResultType',
  CODE: 'ContentSnippetCodeType',
  DOCUMENT: 'ContentSnippetDocumentType',
  EMBED: 'ContentSnippetEmbedType',
  FORM: 'ContentSnippetFormType',
  IMAGE: 'ContentSnippetImageType',
  IMAGE_MAP: 'ContentSnippetImageMapType',
  MAP: 'ContentSnippetMapType',
  QUESTION: 'ContentSnippetQuestionType',
  QUOTE: 'ContentSnippetQuoteType',
  REVEAL: 'Reveal',
  SLIDER: 'ContentSnippetSliderType',
  SPACER: 'ContentSnippetSpacerType',
  TABLE: 'Table',
  TABS: 'Tabs',
  TEXT: 'ContentSnippetTextType',
  VIDEO: 'ContentSnippetVideoType',
  SUMMARY: 'ContentSnippetSummaryType',
  AVATAR: 'ContentSnippetAvatarType',
};


export const SnippetTypeFriendlyText: {[key in DBContentSnippetTypeNameType]: string} = {
  ContentSnippetAppType: 'App',
  ContentSnippetAudioType: 'Audio',
  ContentSnippetButtonType: 'Button',
  ContentSnippetClusterResultType: 'Cluster',
  ContentSnippetCodeType: 'Code',
  ContentSnippetDocumentType: 'Document',
  ContentSnippetEmbedType: 'Embed',
  ContentSnippetFormType: 'Form',
  ContentSnippetImageType: 'Image',
  ContentSnippetImageMapType: 'Image map',
  ContentSnippetMapType: 'Map',
  ContentSnippetQuestionType: 'Question',
  ContentSnippetQuoteType: 'Quote',
  Reveal: 'Reveal',
  ContentSnippetSliderType: 'Slider',
  ContentSnippetSpacerType: 'Spacer',
  Table: 'Table',
  Tabs: 'Tabs',
  ContentSnippetTextType: 'Text',
  ContentSnippetVideoType: 'Video',
  ContentSnippetSummaryType: 'Summary',
  ContentSnippetAvatarType: 'Avatar',
  ContentSnippetSummaryResultType: 'Summary result',
};
