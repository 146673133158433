import { useState } from 'react';

import { IDropDownListOption } from '@netfront/ekardo-content-library';
import { Button, Input, InputFieldWrapper, Select, SelectWithSearch, Spacing } from '@netfront/ui-library';

import {
  IGeneralNotificationGroupDetails,
  IGeneralNotificationGroupSidebarViewProps,
} from './GeneralNotificationGroupSidebarView.interfaces';

export const GeneralNotificationGroupSidebarView = ({
  defaultTemplate,
  details: currentDetails,
  handleTemplateUpdate,
  handleUpdateInput,
  templates,
  isReadOnly,
}: IGeneralNotificationGroupSidebarViewProps) => {
  const [isSearchTemplateVisible, setSearchTemplateVisible] = useState<boolean>(false);
  const [details, setDetails] = useState<IGeneralNotificationGroupDetails>(currentDetails);

  const handleUpdate = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLOutputElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setDetails(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IGeneralNotificationGroupDetails),
    );
  };

  const getTemplatesOptions = (): IDropDownListOption[] => {
    return templates
      .filter((r) => r.type === details.type)
      .map(({ id, name }) => ({
        id: String(id),
        label: name,
        value: String(id),
      }));
  };

  const onInputBlur = () => {
    handleUpdateInput(details);
  };

  const templateOptions = getTemplatesOptions();

  return (
    <>
      <Spacing size="large">
        <Input
          id="title"
          isDisabled={isReadOnly}
          labelText="Title"
          name="title"
          type="text"
          value={details.title}
          onBlur={onInputBlur}
          onChange={handleUpdate}
        />
      </Spacing>
      <Spacing size="large">
        <Select
          key="notificationTypeSelect"
          id="notificationTypeSelect"
          isDisabled={isReadOnly}
          labelText="Type"
          name="notificationTypeSelect"
          options={[
            { id: 1, name: 'Email', value: 'EMAIL' },
            { id: 2, name: 'Sms', value: 'SMS' },
            { id: 3, name: 'Internal', value: 'INTERNAL_NOTIFICATION' },
            { id: 3, name: 'Push', value: 'PUSH_NOTIFICATION' },
          ]}
          value={details.type}
          isRequired
          onChange={(e) => {
            const {
              target: { value },
            } = e;

            handleUpdateInput({
              ...details,
              type: value as 'EMAIL' | 'SMS' | 'INTERNAL_NOTIFICATION' | 'PUSH_NOTIFICATION',
            });
          }}
        />
      </Spacing>
      <Spacing size="x-large">
        {isReadOnly && (
          <Input
            id="title"
            isDisabled={true}
            labelText=""
            name="title"
            type="text"
            value={defaultTemplate}
            isLabelHidden
            onBlur={onInputBlur}
            onChange={handleUpdate}
          />
        )}
        {!isReadOnly && (
          <InputFieldWrapper id="templates" label="Templates" isRequired>
            <>
              <SelectWithSearch
                buttonText="All available templates"
                countText="templates"
                defaultValue={defaultTemplate}
                isDisplaySearchContent={isSearchTemplateVisible}
                searchList={templateOptions}
                onDisplaySearchContent={() => setSearchTemplateVisible(!isSearchTemplateVisible)}
                onSearchItemClick={(id) => {
                  handleTemplateUpdate(Number(id));
                  setDetails(
                    (currentState) =>
                      ({
                        ...currentState,
                        templateId: Number(id),
                      } as IGeneralNotificationGroupDetails),
                  );
                }}
              />
              {details.templateId && (
                <Button
                  text="Clear"
                  variant="secondary"
                  onClick={() => {
                    handleTemplateUpdate(undefined);
                    setDetails(
                      (currentState) =>
                        ({
                          ...currentState,
                          templateId: undefined,
                        } as IGeneralNotificationGroupDetails),
                    );
                  }}
                />
              )}
            </>
          </InputFieldWrapper>
        )}
      </Spacing>
      <Spacing size="large">
        <Input
          id="message"
          isDisabled={Boolean(details.templateId) || isReadOnly}
          labelText="Message"
          name="message"
          type="text"
          value={details.message}
          onBlur={onInputBlur}
          onChange={handleUpdate}
        />
      </Spacing>
    </>
  );
};
