import { Column } from 'react-table';

import { IQuestionnairesTableData } from './QuestionnairesReportPage.interfaces';



const QUESTIONNAIRES_COLUMNS: ReadonlyArray<Column<IQuestionnairesTableData>> = [
  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
  },
  {
    accessor: 'trackingId',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Tracking id</div>,
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
  },
  {
    accessor: 'userType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">User type</span>,
  },
  {
    accessor: 'tag',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Tag</span>,
  },
  {
    accessor: 'startedOnUtc',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Started (utc)</span>,
  },
  {
    accessor: 'completedOnUtc',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Completed (utc)</span>,
  },
  {
    accessor: 'timeToComplete',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Time to complete</span>,
  },
];

export { QUESTIONNAIRES_COLUMNS };
