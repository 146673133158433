import { Column } from 'react-table';

import { IPostsTableData } from './PostsReportPage.interfaces';



const POSTS_COLUMNS: ReadonlyArray<Column<IPostsTableData>> = [
  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Author id</div>,
    width: '7%'
  },
  {
    accessor: 'postId',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Post id</div>,
    width: '7%'
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
    width: '10%'
  },
  {
    accessor: 'userType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">User type</span>,
    width: '10%'
  },
  {
    accessor: 'post',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Post</span>,
    width: '41%'
  },

  {
    accessor: 'likes',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Likes</span>,
    width: '5%'
  },
  {
    accessor: 'feeling',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Feeling</span>,
    width: '10%'
  },
  {
    accessor: 'postDate',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Post date</span>,
    width: '10%'
  },

];

export { POSTS_COLUMNS };
