import { Column } from 'react-table';

import { IPostLogsTableData } from './PostLogsReportPage.interfaces';



const POST_LOGS_COLUMNS: ReadonlyArray<Column<IPostLogsTableData>> = [
  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Author id</div>,
    width: '7%',
  },
  {
    accessor: 'postId',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Post id</div>,
    width: '7%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Group name</div>,
    width: '10%',
  },
  {
    accessor: 'userType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User type</div>,
    width: '10%',
  },

  {
    accessor: 'post',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Post</span>,
    width: '46%',
  },
  {
    accessor: 'postDate',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Post date</span>,
    width: '10%',
  },
  {
    accessor: 'action',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Action</span>,
    width: '10%',
  },

];

export { POST_LOGS_COLUMNS };
