import React, { ReactNode } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  Input,
  Select,
  Spacing,
  Textarea,
  ToggleSwitch,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { FormsGeneralTabProps } from './FormsGeneralTab.interfaces';


const FormsGeneralTab = ({
  control,
  isInContentBuilder = false,
  isLoading = false,
  isReadOnly = false,
}: FormsGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const Wrapper = ({ children }: { children: ReactNode }) => {
    if (isInContentBuilder) return <>{children}</>;

    return <SidebarContainer hasPaddingEnds>{children}</SidebarContainer>;
  };

  return (
    <Wrapper>
      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_form_title"
              isLoading={isLoading}
              labelText="Title"
              tooltipText="Title of the form"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_description"
              isDisabled={isReadOnly}
              isLoading={isLoading}
              labelText="Description"
              placeholder="Add your description here"
              tooltipText="Description of the form"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="formType"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="formType"
              isDisabled={isReadOnly}
              isLoading={isLoading}
              labelText="Type"
              options={[
                {
                  id: 0,
                  value: 'LIST',
                  name: 'List',
                },
                {
                  id: 1,
                  value: 'TABLE',
                  name: 'Table',
                },
                {
                  id: 2,
                  value: 'QUIZ',
                  name: 'Quiz',
                },
              ]}
              tooltipText="Type of the form"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="isActivated"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames="c-sidebar-toggle"
              id="id_is_activated"
              inlineLabelPosition="start"
              isChecked={field.value}
              isDisabled={isReadOnly}
              labelText="Activated"
              tooltipPosition="start"
              tooltipText="Status of the form"
              isInline
              {...field}
            />
          )}
        />
      </Spacing>

      {isInContentBuilder && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="isSensitive"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames="c-sidebar-toggle"
                  id="id_is_sensitive_content"
                  inlineLabelPosition="start"
                  isChecked={field.value}
                  labelText="Sensitive"
                  tooltipPosition="start"
                  tooltipText="Determine whether the asset is sensitive, e.g contains strong language or culturally offensive"
                  isInline
                  {...field}
                />
              )}
            />
          </Spacing>
          <Spacing>
            <Controller
              control={control}
              name="isVisible"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames="c-sidebar-toggle"
                  id="id_is_visible"
                  inlineLabelPosition="start"
                  isChecked={field.value}
                  labelText="Visible on load"
                  tooltipPosition="start"
                  tooltipText="Determine whether the form is visible by default"
                  isInline
                  {...field}
                />
              )}
            />
          </Spacing>
        </>
      )}
    </Wrapper>
  );
};

export { FormsGeneralTab };
