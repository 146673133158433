import { Column } from 'react-table';

import { IMobileAccessTableData } from './MobileAccessReportPage.interfaces';



const MOBILE_ACCESS_COLUMNS: ReadonlyArray<Column<IMobileAccessTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
    width: '7%',
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Email</div>,
    width: '25%',
  },
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">First name</div>,
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Last name</div>,
  },
  {
    accessor: 'schoolCode',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">School code</div>,
    width: '7%',
  },
  {
    accessor: 'appVersion',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">App version</div>,
  },
  {
    accessor: 'dateOpened',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Date opened</div>,
  },
  {
    accessor: 'lastDateOpened',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Last date opened</div>,
  },
  {
    accessor: 'totalOpen',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Total open</span>,
    width: '5%',
  },

];

export { MOBILE_ACCESS_COLUMNS };
