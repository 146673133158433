import React, { useState, useContext, useEffect } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Select, Spacing, Spinner, IOption } from '@netfront/ui-library';
import { QuestionPreview } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetProjectSteps, useGetQuestions, useToast } from 'hooks';
import { IQuestionSelectorOption } from 'interfaces';
import { Controller } from 'react-hook-form';
import { getQuestionText, generateRandomId, sortObjectArrayAlphabetically } from 'utils';

import { SummarySnippetQuestionSelectorProps } from './SummarySnippetQuestionSelector.interfaces';



const SummarySnippetQuestionSelector = ({
  control,
  initialUserFlowStepId,
  initialQuestionId,
  setValue,
}: SummarySnippetQuestionSelectorProps) => {

  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError } = useToast();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const [questionOptions, setQuestionOptions] = useState<IQuestionSelectorOption[]>([]);
  const [userFlowStepOptions, setUserFlowStepOptions] = useState<IOption[]>([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | undefined>(initialQuestionId ? String(initialQuestionId) : undefined);
  const [selectedUserFlowStepId, setSelectedUserFlowStepId] = useState<number | undefined>(initialUserFlowStepId ?? undefined);

  const { handleGetQuestions, isLoading: isGetQuestionsLoading = false } = useGetQuestions({
    onCompleted: ({ groupedContentSnippetQuestions }) => {
      const newQuestionOptions = groupedContentSnippetQuestions.flatMap(({ contentSnippetFormId, questions: contentSnippetQuestions }) =>
        contentSnippetQuestions.map((contentSnippetQuestion) => {
          const { id, question } = contentSnippetQuestion;
          const label = getQuestionText(String(question), 'text/html') ?? `Question-${id}`;
          return {
            id: `${generateRandomId()}-${id}`,
            initialId: id,
            name: `ID: ${id} - Question label: ${label.substring(0, 30)}`,
            value: `${contentSnippetFormId}-${id}`,
          };
        }),
      );

      setQuestionOptions(sortObjectArrayAlphabetically<IQuestionSelectorOption>(newQuestionOptions, 'name'));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetProjectSteps, isLoading: isGetProjectStepsLoading = false } = useGetProjectSteps({
    onCompleted: ({ userFlowSteps }) => {
      if (!userFlowSteps.length) {
        return;
      }

      setUserFlowStepOptions(
        userFlowSteps.map(({ id, stepName }) => ({
          id,
          name: stepName,
          value: id,
        })),
      );
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleUserFlowStepChange = ({ target: { value } }: { target: { value: string}}) => {
    setSelectedQuestionId(undefined);
    setSelectedUserFlowStepId(Number(value));
  };


  useEffect(() => {
    if (!project) return;
    void handleGetProjectSteps({
      projectId: String(project.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (!selectedUserFlowStepId) return;
    void handleGetQuestions({
      userFlowStepId: Number(selectedUserFlowStepId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserFlowStepId]);

  const isLoading = isGetQuestionsLoading || isGetProjectStepsLoading;


  return (
    <>
      <Spinner isLoading={isLoading} />
      <Spacing>
        <Controller
          control={control}
          name="userFlowStepId"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_user_flow_step"
              labelText="Select a module"
              options={userFlowStepOptions}
              isLabelSideBySide
              isRequired
              {...field}
              onChange={(event) => {
                field.onChange(event);
                setValue('questionFormId', '');
                handleUserFlowStepChange(event);
              }}
            />
          )}
        />
      </Spacing>
      <Spacing>
      <Controller
          control={control}
          name="questionFormId"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_questions"
              labelText="Select a question"
              options={questionOptions}
              isLabelSideBySide
              isRequired
              {...field}
              onChange={(event) => {
                const { target: { value } }: { target: { value: string}} = event;
                const [contentSnippetFormId, questionId] = value.split('-');
                field.onChange(event);
                setValue('contentSnippetFormId', Number(contentSnippetFormId));
                setValue('contentSnippetQuestionId', Number(questionId));
                setSelectedQuestionId(questionId);
              }}
            />
          )}
        />
      </Spacing>
      {selectedQuestionId && (
        <Spacing>
          <QuestionPreview questionId={Number(selectedQuestionId)} />
        </Spacing>
      )}
    </>
  );
};

export { SummarySnippetQuestionSelector };
