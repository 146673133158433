import { gql } from '@apollo/client';

const CREATE_CODE_ASSET = gql`
  mutation createAsset(
    $projectId: String!
    $type: EAssetType!
    $fileName: String!
    $contentType: String!
    $alt: String!
    $fileSizeInBytes: Int!
  ) {
    asset {
      createAsset(
        asset: {
          projectId: $projectId
          type: $type
          fileName: $fileName
          contentType: $contentType
          fileSizeInBytes: $fileSizeInBytes
          alt: $alt
        }
      ) {
        signedUrl
        asset {
          assetId
          s3Key
          fileName
          contentType
          presignedUrl
        }
      }
    }
  }
`;


export { CREATE_CODE_ASSET };

