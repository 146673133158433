import { useContext, useEffect, useState } from 'react';

import {
  useBackofficeSiteUrls,
  useDomain,
  useIdentitySiteUrls,
  DBNetfrontServiceType,
} from '@netfront/gelada-identity-library';
import {  } from 'components';
import { useSchedulerSiteUrls } from 'hooks';
import { useUserIsAdmin } from 'utils';

import { CachingEntitiesContext } from 'context/CachingEntitiesContext';
import Announcement from 'public/images/announcement-icon.svg';
import CardApps from 'public/images/card-apps.svg';
import CardAudio from 'public/images/card-audio.svg';
import ContentImage from 'public/images/card-content.svg';
import CardDirectory from 'public/images/card-directory.svg';
import DiscountsImage from 'public/images/card-discounts.svg';
import CardDocument from 'public/images/card-document.svg';
import CardForm from 'public/images/card-form.svg';
import CardImage from 'public/images/card-image.svg';
import InsightsImage from 'public/images/card-insights.svg';
import CardInteractive from 'public/images/card-interactive.svg';
import LibraryImage from 'public/images/card-library.svg';
import ModerationImage from 'public/images/card-moderation.svg';
import NotificationImage from 'public/images/card-notifications.svg';
import ProgressTrackingImage from 'public/images/card-progress-tracking.svg';
import ReportingImage from 'public/images/card-reporting.svg';
import CardSlider from 'public/images/card-slider.svg';
import SocialImage from 'public/images/card-social.svg';
import SubscriptionsImage from 'public/images/card-subscriptions.svg';
import CardTag from 'public/images/card-tags.svg';
import TopicsImage from 'public/images/card-topic.svg';
import UserImage from 'public/images/card-users.svg';
import CardVideo from 'public/images/card-video.svg';
import CommunitiesImage from 'public/images/communities-icon.svg';
import ActivityImage from 'public/images/contentGroupTypes/activity.svg';
import BlogImage from 'public/images/contentGroupTypes/blog.svg';
import CartoonImage from 'public/images/contentGroupTypes/cartoon.svg';
import ConsentImage from 'public/images/contentGroupTypes/consent.svg';
import EventsImage from 'public/images/contentGroupTypes/events.svg';
import HelpImage from 'public/images/contentGroupTypes/help.svg';
import LearningImage from 'public/images/contentGroupTypes/learning.svg';
import NewsImage from 'public/images/contentGroupTypes/news.svg';
import PageImage from 'public/images/contentGroupTypes/page.svg';
import QuestionnaireImage from 'public/images/contentGroupTypes/questionnaire.svg';
import ResourcesImage from 'public/images/contentGroupTypes/resources.svg';
import Scheduler from 'public/images/icon-notification-scheduler.svg';
import NotificationTemplate from 'public/images/icon-notification-template.svg';
import LanguageFilterImage from 'public/images/language-filter-icon.svg';

import DashboardContext from './DashboardContext';
import { NetfrontServices } from './DashboardContext.constants';
import { filterVisibleItems, findObjectById, getDashboardFormattedReportOptions } from './DashboardContext.helpers';
import { DashboardContextProps, IDashboardItemsList } from './DashboardContext.interfaces';



export function DashboardProvider({ children }: DashboardContextProps) {
  const { project, contentGroupTypes, bonoboProject } = useContext(CachingEntitiesContext);
  const { isDomainReady } = useDomain();
  const [dashboardItemsList, setDashboardItemsList] = useState<IDashboardItemsList[]>([]);
  const [dashboardLink, setDashboardLink] = useState<string>('');
  const { isAdmin } = useUserIsAdmin();

  const { getDashboardUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });

  const { getBaseUrl: getIdentitySiteBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const { getBaseUrl: getSchedulerSiteBaseUrl } = useSchedulerSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_SCHEDULER_SITE_LOCAL_PORT,
  });

  useEffect(() => {
    if (!(project && isDomainReady && contentGroupTypes)) {
      return;
    }

    const { services, id: projectId, organisation, features, hasMobileApplication } = project;

    const { features: bonoboFeatures = []} = bonoboProject ?? {};

    const ekardoBaseUrl = `/dashboard/${String(organisation.key)}/${String(projectId)}`;
    const backofficeBaseUrl = `${getDashboardUrl()}/${String(organisation.key)}/${String(projectId)}`;
    const identityBaseUrl = getIdentitySiteBaseUrl();
    const schedulerSiteUrl = getSchedulerSiteBaseUrl();

    const cardItems: IDashboardItemsList[] = [
      {
        id: 'content',
        description: 'Project content page',
        isVisible: services?.includes('EKARDO') ?? false,
        href: `${ekardoBaseUrl}/content`,
        image: ContentImage.src,
        iconId: 'id_content_icon',
        title: 'Content',
        subItems: [
          {
            id: 'learning',
            description: 'View and manage learning content',
            href: `${ekardoBaseUrl}/content/learning`,
            isVisible: contentGroupTypes.includes('Learning'),
            image: LearningImage.src,
            iconId: 'id_learning_icon',
            title: 'Learning',
          },
          {
            id: 'resource',
            description: 'View and manage resources content',
            href: `${ekardoBaseUrl}/content/resource`,
            isVisible: contentGroupTypes.includes('Resource'),
            image: ResourcesImage.src,
            iconId: 'id_resource_icon',
            title: 'Resources',
          },
          {
            id: 'page',
            description: 'View and manage pages content',
            href: `${ekardoBaseUrl}/content/page`,
            isVisible: contentGroupTypes.includes('Page'),
            image: PageImage.src,
            iconId: 'id_form_icon',
            title: 'Page',
          },
          {
            id: 'news',
            description: 'View and manage news content',
            href: `${ekardoBaseUrl}/content/news`,
            isVisible: contentGroupTypes.includes('News'),
            image: NewsImage.src,
            iconId: 'id_transcript_tab_icon',
            title: 'News',
          },
          {
            id: 'blog',
            description: 'View and manage blog content',
            href: `${ekardoBaseUrl}/content/blog`,
            isVisible: contentGroupTypes.includes('Blog'),
            image: BlogImage.src,
            iconId: 'id_resource_icon',
            title: 'Blog',
          },
          {
            id: 'questionnaire',
            description: 'View and manage questionnaire content',
            href: `${ekardoBaseUrl}/content/questionnaire`,
            isVisible: contentGroupTypes.includes('Questionnaire'),
            image: QuestionnaireImage.src,
            iconId: 'id_questionnaire_icon',
            title: 'Questionnaire',
          },
          {
            id: 'events',
            description: 'View and manage events content',
            href: `${ekardoBaseUrl}/content/events`,
            isVisible: contentGroupTypes.includes('Events'),
            image: EventsImage.src,
            iconId: 'id_calendar_icon',
            title: 'Events',
          },
          {
            id: 'help',
            description: 'View and manage help content',
            href: `${ekardoBaseUrl}/content/help`,
            isVisible: contentGroupTypes.includes('Help'),
            image: HelpImage.src,
            iconId: 'id_help_docs_icon',
            title: 'Help',
          },
          {
            id: 'consent',
            description: 'View and manage consent content',
            href: `${ekardoBaseUrl}/content/consent`,
            isVisible: contentGroupTypes.includes('Consent'),
            image: ConsentImage.src,
            iconId: 'id_notes_tab_icon',
            title: 'Consent',
          },
          {
            id: 'cartoon',
            description: 'View and manage cartoon content',
            href: `${ekardoBaseUrl}/content/cartoon`,
            isVisible: contentGroupTypes.includes('Cartoon'),
            image: CartoonImage.src,
            iconId: 'id_user_icon',
            title: 'Cartoon',
          },
          {
            id: 'activity',
            description: 'View and manage activity content',
            href: `${ekardoBaseUrl}/content/activity`,
            isVisible: contentGroupTypes.includes('Activity'),
            image: ActivityImage.src,
            iconId: 'id_actions_icon',
            title: 'Activity',
          },
        ],
      },
      {
        id: 'alpha-content',
        description: 'Project content page',
        isVisible: (isAdmin && services?.includes('EKARDO')) ?? false,
        href: `${ekardoBaseUrl}/alpha-content`,
        image: ContentImage.src,
        iconId: 'id_content_icon',
        title: 'Alpha Content [FOR TESTING]',
        subItems: [
          {
            id: 'alpha-learning',
            description: 'View and manage learning content',
            href: `${ekardoBaseUrl}/alpha-content/learning`,
            isVisible: contentGroupTypes.includes('Learning'),
            image: LearningImage.src,
            iconId: 'id_learning_icon',
            title: 'Learning',
          },
          {
            id: 'alpha-resource',
            description: 'View and manage resources content',
            href: `${ekardoBaseUrl}/alpha-content/resource`,
            isVisible: contentGroupTypes.includes('Resource'),
            image: ResourcesImage.src,
            iconId: 'id_resource_icon',
            title: 'Resources',
          },
          {
            id: 'alpha-page',
            description: 'View and manage pages content',
            href: `${ekardoBaseUrl}/alpha-content/page`,
            isVisible: contentGroupTypes.includes('Page'),
            image: PageImage.src,
            iconId: 'id_form_icon',
            title: 'Page',
          },
          {
            id: 'alpha-news',
            description: 'View and manage news content',
            href: `${ekardoBaseUrl}/alpha-content/news`,
            isVisible: contentGroupTypes.includes('News'),
            image: NewsImage.src,
            iconId: 'id_transcript_tab_icon',
            title: 'News',
          },
          {
            id: 'alpha-blog',
            description: 'View and manage blog content',
            href: `${ekardoBaseUrl}/alpha-content/blog`,
            isVisible: contentGroupTypes.includes('Blog'),
            image: BlogImage.src,
            iconId: 'id_resource_icon',
            title: 'Blog',
          },
          {
            id: 'alpha-questionnaire',
            description: 'View and manage questionnaire content',
            href: `${ekardoBaseUrl}/alpha-content/questionnaire`,
            isVisible: contentGroupTypes.includes('Questionnaire'),
            image: QuestionnaireImage.src,
            iconId: 'id_questionnaire_icon',
            title: 'Questionnaire',
          },
          {
            id: 'alpha-events',
            description: 'View and manage events content',
            href: `${ekardoBaseUrl}/alpha-content/events`,
            isVisible: contentGroupTypes.includes('Events'),
            image: EventsImage.src,
            iconId: 'id_calendar_icon',
            title: 'Events',
          },
          {
            id: 'alpha-help',
            description: 'View and manage help content',
            href: `${ekardoBaseUrl}/alpha-content/help`,
            isVisible: contentGroupTypes.includes('Help'),
            image: HelpImage.src,
            iconId: 'id_help_docs_icon',
            title: 'Help',
          },
          {
            id: 'alpha-consent',
            description: 'View and manage consent content',
            href: `${ekardoBaseUrl}/alpha-content/consent`,
            isVisible: contentGroupTypes.includes('Consent'),
            image: ConsentImage.src,
            iconId: 'id_notes_tab_icon',
            title: 'Consent',
          },
          {
            id: 'alpha-cartoon',
            description: 'View and manage cartoon content',
            href: `${ekardoBaseUrl}/alpha-content/cartoon`,
            isVisible: contentGroupTypes.includes('Cartoon'),
            image: CartoonImage.src,
            iconId: 'id_user_icon',
            title: 'Cartoon',
          },
          {
            id: 'alpha-activity',
            description: 'View and manage activity content',
            href: `${ekardoBaseUrl}/alpha-content/activity`,
            isVisible: contentGroupTypes.includes('Activity'),
            image: ActivityImage.src,
            iconId: 'id_actions_icon',
            title: 'Activity',
          },
        ],
      },
      {
        id: 'library',
        description: 'Project library page',
        href: `${ekardoBaseUrl}/library`,
        isVisible: services?.includes('EKARDO') ?? false,
        image: LibraryImage.src,
        iconId: 'id_libraries_icon',
        title: 'Libraries',
        subItems: [
          {
            id: 'image',
            description: 'Project library images page',
            href: `${ekardoBaseUrl}/library/assets/images`,
            isVisible: true,
            image: CardImage.src,
            iconId: 'id_image_hollow_icon',
            title: 'Image',
          },
          {
            id: 'document',
            description: 'Project library documents page',
            href: `${ekardoBaseUrl}/library/assets/documents`,
            isVisible: true,
            image: CardDocument.src,
            iconId: 'id_resources_icon',
            title: 'Document',
          },
          {
            id: 'video',
            description: 'Project library videos page',
            href: `${ekardoBaseUrl}/library/assets/videos`,
            isVisible: true,
            image: CardVideo.src,
            iconId: 'id_hollow_video_icon',
            title: 'Video',
          },
          {
            id: 'audio',
            description: 'Project library audios page',
            href: `${ekardoBaseUrl}/library/assets/audios`,
            isVisible: true,
            image: CardAudio.src,
            iconId: 'id_audio_tab_icon',
            title: 'Audio',
          },
          {
            id: 'form',
            description: 'Project library forms page',
            href: `${ekardoBaseUrl}/library/forms`,
            isVisible: true,
            image: CardForm.src,
            iconId: 'id_forms_icon',
            title: 'Forms',
          },
          {
            id: 'slider',
            description: 'Project library slider page',
            href: `${backofficeBaseUrl}/library/slider`,
            isVisible: true,
            image: CardSlider.src,
            iconId: 'id_slider_icon',
            title: 'Slider',
          },
          {
            id: 'app',
            description: 'Project library apps page',
            href: `${backofficeBaseUrl}/library/myApps`,
            isInternal: false,
            isVisible: true,
            image: CardApps.src,
            iconId: 'id_marketplace_icon',
            title: 'App',
          },
          {
            id: 'tags',
            description: 'Project library tags page',
            href: `${ekardoBaseUrl}/library/tags`,
            isVisible: true,
            image: CardTag.src,
            iconId: 'id_tags_icon',
            title: 'Tags',
          },
          {
            id: 'directory',
            description: 'Project library tags page',
            href: `${ekardoBaseUrl}/library/directory`,
            isVisible: true,
            image: CardDirectory.src,
            iconId: 'id_directory_icon',
            title: 'Directory',
          },
          {
            id: 'interactive-regions',
            description: 'Project library Interactive regions page',
            href: `${backofficeBaseUrl}/library/imageMap`,
            isInternal: false,
            isVisible: true,
            image: CardInteractive.src,
            iconId: 'id_cluster_icon',
            title: 'Interactive regions',
          },
          {
            id: 'glossary',
            description: 'Project library glossary page',
            href: `${ekardoBaseUrl}/library/glossary`,
            isVisible: true,
            image: CardForm.src,
            iconId: 'id_glossary_icon',
            title: 'Glossary',
          },
          {
            id: 'character',
            description: 'Project library character page',
            href: `${ekardoBaseUrl}/library/character`,
            isVisible: contentGroupTypes.includes('Cartoon'),
            image: CartoonImage.src,
            iconId: 'id_user_icon',
            title: 'Character',
          },
        ],
      },
      {
        id: 'users',
        description: 'Project users page',
        href: `${identityBaseUrl}/project/${String(projectId)}/user`,
        isInternal: false,
        image: UserImage.src,
        iconId: 'id_partners_icon',
        title: 'Users',
        subItems: [],
      },
      {
        id: 'subscriptions',
        isVisible: (services?.includes(NetfrontServices.MURIQUI as DBNetfrontServiceType) && services.includes(NetfrontServices.VERVET as DBNetfrontServiceType)) ?? false,
        description: 'Project subscriptions page',
        href: `${ekardoBaseUrl}/subscriptions`,
        image: SubscriptionsImage.src,
        iconId: 'id_subscriptions_icon',
        title: 'Subscriptions',
        subItems: [
          {
            id: 'orders',
            description: 'Project subscriptions orders page',
            href: `${ekardoBaseUrl}/subscriptions/orders`,
            image: SubscriptionsImage.src,
            iconId: 'id_orders_icon',
            title: 'Orders',
          },
          {
            id: 'discount-codes',
            description: 'Project subscriptions discount codes page',
            href: `${ekardoBaseUrl}/subscriptions/discounts`,
            image: DiscountsImage.src,
            iconId: 'id_discount_icon',
            title: 'Discount codes',
          },
        ],
      },
      {
        id: 'social',
        description: 'Project social page',
        href: `${ekardoBaseUrl}/social`,
        image: SocialImage.src,
        iconId: 'id_social_icon',
        // isVisible: services?.includes(`${NetfrontServices.BONOBO_MANAGEMENT as DBNetfrontServiceType}`),
        isVisible: services?.includes(`${NetfrontServices.BONOBO as DBNetfrontServiceType}`) ?? false,
        title: 'Social',
        subItems: [
          {
            id: 'moderation',
            description: 'Project social moderation page',
            href: `${ekardoBaseUrl}/social/moderation`,
            iconId: 'id_moderation_icon',
            image: ModerationImage.src,
            title: 'Moderation',
            isVisible: true,
          },
          {
            id: 'topics',
            description: 'Project social topics page',
            href: `${ekardoBaseUrl}/social/topics`,
            iconId: 'id_topics_icon',
            image: TopicsImage.src,
            title: 'Topics',
            isVisible: features?.includes('HAS_BONOBO_TOPIC') ?? false,
          },
          {
            id: 'communities',
            description: 'Project social communities page',
            href: `${ekardoBaseUrl}/social/communities`,
            iconId: 'id_group_requests_icon',
            image: CommunitiesImage.src,
            title: 'Communities',
            isVisible: features?.includes('HAS_BONOBO_COMMUNITY') ?? false,
          },
          {
            id: 'users',
            description: 'User social communities page',
            href: `${ekardoBaseUrl}/social/users`,
            iconId: 'id_members_icon',
            image: UserImage.src,
            title: 'Users',
            isVisible: features?.includes('HAS_BONOBO_COMMUNITY') ?? false,
          },
          {
            id: 'language-filter',
            description: 'User social language filter page',
            href: `${ekardoBaseUrl}/social/language-filter`,
            iconId: 'id_flagging_icon',
            image: LanguageFilterImage.src,
            title: 'Language filters',
            isVisible: (features?.includes('HAS_BONOBO_COMMUNITY') && bonoboFeatures.includes('HAS_LANGUAGE_FILTER')) ?? false,
          },
        ]
      },
      {
        id: 'insights',
        description: 'Project insights page',
        href: `${ekardoBaseUrl}/insight`,
        image: InsightsImage.src,
        iconId: 'id_insights_icon',
        title: 'Insight',
        subItems: [],
      },
      {
        id: 'progress-tracking',
        description: 'Project progress tracking page',
        href: `${ekardoBaseUrl}/progress-tracking`,
        image: ProgressTrackingImage.src,
        iconId: 'id_user_flow_icon',
        isVisible: features?.includes('HAS_PROGRESS_TRACKER') ?? false,
        title: 'Progress tracking',
        subItems: [],
      },
      {
        id: 'reporting',
        description: 'Project reporting page',
        // href: `${backofficeBaseUrl}/reporting/export-logins`,
        href: `${ekardoBaseUrl}/reporting`,
        image: ReportingImage.src,
        iconId: 'id_reporting_icon',
        title: 'Reporting',
        subItems: getDashboardFormattedReportOptions({ services, baseUrl: `${ekardoBaseUrl}/reporting`, hasMobileApplication, projectId: projectId }),
      },
      {
        id: 'notifications',
        isVisible: services?.includes(`${NetfrontServices.HOWLER as DBNetfrontServiceType}`) ?? services?.includes(`${NetfrontServices.CAPUCHIN  as DBNetfrontServiceType}`) ?? false,
        description: 'Project notifications page',
        href: `${ekardoBaseUrl}/notifications`,
        image: NotificationImage.src,
        iconId: 'id_notification_icon',
        title: 'Notifications',
        subItems: [
          {
            href: `${schedulerSiteUrl}/project/${projectId}/scheduler/scenarios/users`,
            iconId: 'id_calendar_icon',
            image: Scheduler.src,
            isInternal: false,
            description: 'Notify',
            id: 'scheduler',
            isVisible: services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false,
            title: 'Scheduler',
          },
          {
            id: 'notify',
            description: 'Notify',
            href: `${ekardoBaseUrl}/notifications/notify`,
            iconId: 'id_notify_users_icon',
            image: LibraryImage.src,
            title: 'Notify users',
          },
          {
            id: 'templates',
            image: NotificationTemplate.src,
            iconId: 'id_transcript_tab_icon',
            description: 'Templates',
            href: `${backofficeBaseUrl}/notifications/notification-templates`,
            isInternal: false,
            isVisible: services?.includes(NetfrontServices.HOWLER as DBNetfrontServiceType) ?? false,
            title: 'Templates',
          },
          {
            id: 'announcements',
            description: 'Announcements',
            iconId: 'id_announcement_icon',
            href: `${ekardoBaseUrl}/notifications/announcements`,
            image: Announcement.src,
            title: 'Announcements',
          },
        ]
      },
      {
        id: 'group-access',
        isVisible: services?.includes(`${NetfrontServices.VERVET as DBNetfrontServiceType}`) ?? false,
        description: 'Project group access page',
        href: `${ekardoBaseUrl}/group-access`,
        image: SubscriptionsImage.src,
        iconId: 'id_group_access_icon',
        title: 'Group access',
        subItems: [],
      },
    ];

    setDashboardItemsList(filterVisibleItems(cardItems));
    setDashboardLink(ekardoBaseUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, isDomainReady, contentGroupTypes, bonoboProject]);



  const getDashboardItemsById = (id: string): IDashboardItemsList[] => {
    if (id === 'project') return dashboardItemsList;
    const dashboardItem = findObjectById(dashboardItemsList, id);
    return dashboardItem ?? [];
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardItems: dashboardItemsList,
        getDashboardItemsById,
        dashboardLink,
        isLoading: dashboardItemsList.length === 0
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}
