import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONDITION_TEMPLATES } from './useGetConditionTemplates.graphql';
import { IGetConditionTemplatesQueryGraphQLResponse, IGetConditionTemplatesQueryVariables, IHandleGetConditionTemplatesParams, IUseGetConditionTemplates, IUseGetConditionTemplatesOptions } from './useGetConditionTemplates.interfaces';

const useGetConditionTemplates = (options?: IUseGetConditionTemplatesOptions): IUseGetConditionTemplates => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetConditionTemplatesOptions);
  const [executeGetConditionTemplates, { loading: isLoading }] = useEkardoLazyQuery<
  IGetConditionTemplatesQueryGraphQLResponse,
  IGetConditionTemplatesQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { getConditions: conditions },
        } = data;

        onCompleted({
          conditions,
        });
      },
      onError,
    },
    query: query ?? GET_CONDITION_TEMPLATES,
    token,
  });

  const handleGetConditionTemplates = ({ projectId }: IHandleGetConditionTemplatesParams) => {
    void executeGetConditionTemplates({
      variables: {
        projectId
      },
    });
  };

  return {
    handleGetConditionTemplates,
    isLoading,
  };
};

export { useGetConditionTemplates };
