import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_RADIO_FEEDBACK_CONFIG } from './useUpsertSummaryFeedbackConfigRadio.graphql';
import {
  IUpsertSummaryFeedbackConfigRadioMutationGraphQLResponse,
  IUpsertSummaryFeedbackConfigRadioMutationVariables,
  IHandleUpsertSummaryFeedbackConfigRadioParams,
  IUseUpsertSummaryFeedbackConfigRadioOptions,
  IUseUpsertSummaryFeedbackConfigRadio,
} from './useUpsertSummaryFeedbackConfigRadio.interfaces';

const useUpsertSummaryFeedbackConfigRadio = (options?: IUseUpsertSummaryFeedbackConfigRadioOptions): IUseUpsertSummaryFeedbackConfigRadio => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpsertSummaryFeedbackConfigRadioOptions);

  const [executeUpsertSummaryFeedbackConfigRadio, { loading: isLoading }] = useEkardoMutation<IUpsertSummaryFeedbackConfigRadioMutationGraphQLResponse, IUpsertSummaryFeedbackConfigRadioMutationVariables>({
    mutation: mutation ?? CREATE_RADIO_FEEDBACK_CONFIG,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { addSummaryFeedbackConfigurationRadio: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpsertSummaryFeedbackConfigRadio = async ({
    contentSnippetSummaryId,
    questionResponseSetId,
    label,
  }: IHandleUpsertSummaryFeedbackConfigRadioParams) => {
    await executeUpsertSummaryFeedbackConfigRadio({
      variables: {
        contentSnippetSummaryId,
        questionResponseSetId,
        label,
      }
    });
  };

  return {
    handleUpsertSummaryFeedbackConfigRadio,
    isLoading,
  };
};

export { useUpsertSummaryFeedbackConfigRadio };
