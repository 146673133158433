import React from 'react';

import { Input } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { ConditionTextQuestionProps } from './ConditionTextQuestion.interfaces';

const ConditionTextQuestion = ({ control }: ConditionTextQuestionProps) => (
  <Controller
    control={control}
    name="text"
    render={({ field }) => (
      <Input
        id="id_condition_text"
        labelText="Question response"
        tooltipText="This action will execute if the text added is as defined below"
        type="text"
        isLabelSideBySide
        {...field}
      />
    )}
  />
);

export { ConditionTextQuestion };
