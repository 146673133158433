

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { EXPORT_MODULE_PAGE_COMPLETIONS } from './useGetModulePageCompletionsReport.graphql';
import {
  IGetModulePageCompletionsReportQueryGraphQLResponse,
  IGetModulePageCompletionsReportQueryVariables,
  IHandleGetModulePageCompletionsReportParams,
  IUseGetModulePageCompletionsReport,
  IUseGetModulePageCompletionsReportOptions,
} from './useGetModulePageCompletionsReport.interfaces';

const useGetModulePageCompletionsReport = (options?: IUseGetModulePageCompletionsReportOptions): IUseGetModulePageCompletionsReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetModulePageCompletionsReportOptions);

  const [executeGetModulePageCompletionsReport, { loading: isLoading }] = useEkardoLazyQuery<
    IGetModulePageCompletionsReportQueryGraphQLResponse,
    IGetModulePageCompletionsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportModulePageCompletions } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportModulePageCompletions,
        });
      },
      onError,
    },
    query: query ?? EXPORT_MODULE_PAGE_COMPLETIONS,
    token,
  });


  const handleGetModulePageCompletionsReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    stepId,
  }: IHandleGetModulePageCompletionsReportParams) => {
    await executeGetModulePageCompletionsReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        stepId,
      },
    });
  };

  return {
    handleGetModulePageCompletionsReport,
    isLoading,
  };
};

export { useGetModulePageCompletionsReport };
