import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus } from "components";

const getSummarySnippetDefaultValues = ({ snippet } : { snippet?: IContentSnippet;}): FormFieldProps => {
  const { title, contentEvent, contentSnippetQuestionId, contentSnippetFormId, userFlowStepId, visible: isVisible = true } = snippet ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);

  return {
    title: title ?? 'Summary snippet',
    contentSnippetFormId,
    contentSnippetQuestionId,
    questionFormId: snippet ? `${String(snippet.contentSnippetFormId)}-${String(snippet.contentSnippetQuestionId)}` : undefined,
    userFlowStepId,
    isVisible,
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
  }
};

const getSummarySnippetCommonVariables = ({ eventAssetId, item }: {eventAssetId?: IDBAsset['assetId']; item: FormFieldProps}) => {

  const {
    title = 'Summary snippet',
    contentSnippetFormId,
    contentSnippetQuestionId,
    userFlowStepId,
    isVisible,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
    },
    summarySnippet: {
      contentSnippetFormId,
      contentSnippetQuestionId,
      userFlowStepId,
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    type: 'summary',
  };

  return sharedVariables;

};

const setQuickAddSummaryValues = (values: FormFieldProps) => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Summary snippet',
    contentSnippetFormId: undefined,
    contentSnippetQuestionId: undefined,
    questionFormId: undefined,
    userFlowStepId: undefined
  };
};

export { getSummarySnippetDefaultValues, getSummarySnippetCommonVariables, setQuickAddSummaryValues };
