import { useEffect, useRef, useState } from 'react';


import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledForm, Dialog, FormFieldProps, SideBarTabSet,  Spinner, useControlledForm } from '@netfront/ui-library';
import { CharacterGeneralTab } from 'components';
import { useCreateAsset, useCreateCharacter, useDeleteAsset, useDeleteCharacter, useToast, useUpdateCharacter } from 'hooks';
import { ICharacter } from 'interfaces';
import { pushImageToAws } from 'utils';
import * as yup from 'yup';


import { ICharacterFormFields, CharacterSidebarViewProps } from './CharacterSidebarView.interfaces';


const CharacterSidebarView = ({
  handleSideBarClose,
  isSideBarOpen = false,
  onUpdate,
  projectId,
  selectedCharacter,
}: CharacterSidebarViewProps) => {

  const { handleToastError, handleToastSuccess } = useToast();

  const droppedFileRef = useRef<{value: File | undefined}>({ value: undefined });
  const characterRef = useRef<{value: ICharacter | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({ name: '', image: ''});

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().label('Name').required(),
      }),
    ),
  });

  const { handleDeleteCharacter, isLoading: isDeleteCharacterLoading = false } = useDeleteCharacter({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      handleToastSuccess({ message: 'Character successfully deleted'});
      reset();
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleCreateCharacter, isLoading: isCreateCharacterLoading = false } = useCreateCharacter({
    onCompleted: () => {
      handleToastSuccess({ message: 'Character successfully created'});
      reset();
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateCharacter, isLoading: isUpdateCharacterLoading = false } = useUpdateCharacter({
    onCompleted: () => {
      handleToastSuccess({ message: 'Character successfully updated'});
      reset();
      onUpdate();

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteAsset } = useDeleteAsset({
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCreateAsset, isLoading: isCreateAssetLoading = false } = useCreateAsset({
    onCompleted: async (data) => {

      const { signedUrl, assetId } = data;


      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, droppedFileRef.current.value, () => {
        const {
          name = '',
          imageId,
        } = characterRef.current.value ?? {};

        setIsAssetUploadToAwsLoading(false);

        if (imageId) {
          handleDeleteAsset({
            assetId: imageId
          });
        }

        if (!selectedCharacter) {
          handleCreateCharacter({
            projectId,
            name,
            image: assetId,
          });
        } else {
          handleUpdateCharacter({
            characterId: selectedCharacter.id,
            name,
            image: assetId,
          });
        }

      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileRef.current.value = uploadedFile;
  }

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  }


  const handleSave = ({ name } : ICharacterFormFields) => {
    if (droppedFileRef.current.value) {
      const { name: imageName, type, size, } = droppedFileRef.current.value;

      characterRef.current.value = {
        ...characterRef.current.value ?? {} as ICharacter,
        name,
      };

      handleCreateAsset({
        alt: imageName,
        contentType: type,
        description: imageName,
        fileName: imageName,
        fileSizeInBytes: size,
        imageSize: 'SMALL',
        title: imageName,
        projectId,
        tagList: [],
        type: 'IMAGE'
      });
    } else {

      if (!selectedCharacter) {
        handleCreateCharacter({
          projectId,
          name
        });
      } else {

        if (hasDeletedOriginalImageRef.current.value && selectedCharacter.imageId) {
          handleDeleteAsset({
            assetId: selectedCharacter.imageId,
          });
        }

        handleUpdateCharacter({
          characterId: selectedCharacter.id,
          name,
        });
      }
    }
  };

  useEffect(() => {
    setDefaultValues({
      name: selectedCharacter?.name ?? '',
      image: selectedCharacter?.image?.presignedUrl ?? ''
    });

    characterRef.current.value = selectedCharacter ?? {} as ICharacter;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCharacter, isSideBarOpen]);

  const isLoading = isDeleteCharacterLoading || isCreateCharacterLoading || isUpdateCharacterLoading || isCreateAssetLoading || isAssetUploadToAwsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete character?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedCharacter) {
            return;
          }

          if (selectedCharacter.imageId) {
            handleDeleteAsset({
              assetId: selectedCharacter.imageId
            });
          }

          handleDeleteCharacter({
            characterId: selectedCharacter.id,
          });
        }}
      />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          const { name } = item;
          handleSave({name});
        }}
        handleSubmit={handleSubmit}
      >


        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={handleSideBarClose}
          hideSideBarButtons={true}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              iconId: 'id_general_tab_icon',
              id: 'id_general_tab',
              label: 'General',
              view: () =>
                <CharacterGeneralTab
                  control={control}
                  isLoading={isLoading}
                  onCancel={handleSideBarClose}
                  onDelete={() => setIsDeleteDialogOpen(true)}
                  onDeleteAsset={handleRemoveAsset}
                  onDrop={handleDropFile}
                />
            },
          ]}

        />
      </ControlledForm>
    </>
  );
};

export { CharacterSidebarView };
