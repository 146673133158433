import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_SECTION_GROUP } from './useCreateSectionGroup.graphql';
import {
  ICreateSectionGroupMutationGraphQLResponse,
  ICreateSectionGroupMutationVariables,
  IUseCreateSectionGroupOptions,
  IUseCreateSectionGroup,
  ICreateSectionGroupMutationParams,
} from './useCreateSectionGroup.interfaces';

const useCreateSectionGroup = (options?: IUseCreateSectionGroupOptions): IUseCreateSectionGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateSectionGroupOptions);

  const [executeCreateSectionGroup, { loading: isLoading }] = useEkardoMutation<ICreateSectionGroupMutationGraphQLResponse, ICreateSectionGroupMutationVariables>({
    mutation: mutation ?? CREATE_SECTION_GROUP,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          sectionGroup: { add: sectionGroup },
        } = data;

        onCompleted({
          sectionGroup,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateSectionGroup = async ({
    contentPageId,
    sort,
    // title,
    // isVisible,
  }: ICreateSectionGroupMutationParams) => {
    await executeCreateSectionGroup({
      variables: {
        contentPageId,
        sort,
        // title,
        // isVisible,
      }
    });
  };

  return {
    handleCreateSectionGroup,
    isLoading,
  };
};

export { useCreateSectionGroup };
