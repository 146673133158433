import { gql } from '@apollo/client';

const GET_TEACHER_REGISTRATION_NO_TRIAL_QUERY = gql`
  query TeacherRegistrationNoTrial($projectId: String!, $start: DateTime!, $end: DateTime!) {
    insights {
      userRegistration(
        projectId: $projectId
        start: $start
        end: $end
        options: {
          userTypeIds:[10]
          customFieldsFiltering: [{ customFieldId: 28, hasValue: false }]
        }
      ) {
        count
        date
      }
    }
  }
`;

export { GET_TEACHER_REGISTRATION_NO_TRIAL_QUERY };
