import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentSectionContainer } from '@netfront/ekardo-content-library';
import { getUpsertContentEventVariables } from 'components';
import { ICreateContainerMutationParams, IUpdateContainerMutationParams, useCreateContentEvent, useCreateContainer, useToast, useUpdateContainer } from 'hooks';

import { IContainerConfig } from './useUpsertContainer.interfaces';


const useUpsertContainer = ({ onCreate, onUpdate }: { onCreate: () => void; onUpdate: (returnedContainer: IContentSectionContainer) => void }) => {
  const containerVariables = useRef<{value :IContainerConfig | undefined}>({value: undefined});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const getContainerVariables = ({containerId, contentLayoutId, type }: {containerId?: number; contentLayoutId?: number;  type: 'create' | 'update'; }): ICreateContainerMutationParams | IUpdateContainerMutationParams => {

    const { container = {}, contentEvent = {}, shouldCreateEvent = false, shouldUpdateEvent = false } = containerVariables.current.value ?? {};

    const { sectionId, sort, title, isVisible} = container;

    const { contentEntity, contentEventId } = contentEvent;

    const variableMap = {
      create: {
        contentLayoutId: Number(contentLayoutId),
        sort,
        sectionId,
        title,
        isVisible,
      } as ICreateContainerMutationParams,
      update: {
        // contentContainer: {
        //   contentLayoutId,
        //   containerId: Number(containerId),
        //   sectionId,
        //   title,
        //   visible: isVisible,
        // },
        // TODO: @ash temporary until update container takes contentContainer
        contentLayoutId,
        containerId: Number(containerId),
        sectionId,
        title,
        visible: isVisible,
        // end
        shouldCreateEvent,
        shouldUpdateEvent,
        updateContentEvent: shouldUpdateEvent ? {
          contentEventId: Number(contentEventId),
          ...getUpsertContentEventVariables(contentEvent, 'update'),
        }: undefined ,
        createContentEvent: shouldCreateEvent ? {
          entityId: Number(sectionId),
          contentEntity,
          ...getUpsertContentEventVariables(contentEvent, 'create'),
        }: undefined
      } as IUpdateContainerMutationParams
    };

    return variableMap[type];
  };

  const { handleCreateContentEvent, isLoading: isCreateContentEventLoading = false } = useCreateContentEvent({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Container successfully created',
      });

      onCreate();

    },
    onError: handleGetError,
  });

  const { handleCreateContainer, isLoading: isCreateContainerLoading = false } = useCreateContainer({
    onCompleted: ({ section : { id }}) => {

      const { shouldCreateEvent = false, contentEvent = {} } = containerVariables.current.value ?? {};

      if (!shouldCreateEvent) {
        handleToastSuccess({
          message: 'Container successfully created',
        });
        onCreate();
      } else {

        const { contentEntity } = contentEvent;
        handleCreateContentEvent({
          contentEvent: {
            entityId: id,
            contentEntity,
            ...getUpsertContentEventVariables(contentEvent, 'create'),
          }
        });
      }

    },
    onError: handleGetError,
  });

  const { handleUpdateContainer, isLoading: isUpdateContainerLoading = false } = useUpdateContainer({
    onCompleted: ({ container: returnedContainer }) => {
      handleToastSuccess({
        message: 'Container successfully updated',
      });
      onUpdate(returnedContainer);
    },
    onError: handleGetError,
  });

  const handleUpsertContainer = ({ containerId, contentLayoutId, variables }: {containerId?: number; contentLayoutId: number;  variables: IContainerConfig; }) => {
    containerVariables.current.value = variables;

    if (!containerId) {

      const createContainerVariables = getContainerVariables({
        contentLayoutId: Number(contentLayoutId),
        type: 'create',
      }) as ICreateContainerMutationParams;

      handleCreateContainer({
        ...createContainerVariables
      });

    } else {
      const updateContainerVariables = getContainerVariables({
        containerId: Number(containerId),
        type: 'update',
      }) as IUpdateContainerMutationParams;

      handleUpdateContainer({
        ...updateContainerVariables,
      });
    }
  };

  return {
    isLoading: isCreateContainerLoading || isUpdateContainerLoading || isCreateContentEventLoading,
    handleUpsertContainer,
  };
};
export { useUpsertContainer };
