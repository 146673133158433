import { gql } from '@apollo/client';

const EXPORT_GROUPS = gql`
  query ExportGroups($projectId: String!, $fileType: FileType) {
    report {
      exportGroups(projectId: $projectId, fileType: $fileType) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_GROUPS };
