/* eslint-disable @typescript-eslint/indent */
import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { DELETE_ANNOUNCE_QUERY } from './useDeleteAnnounce.graphql';
import {
  IDeleteAnnounceQueryGraphQLResponse,
  IDeleteAnnounceQueryVariables,
  IUseDeleteAnnounce,
  IUseDeleteAnnounceOptions,
} from './useDeleteAnnounce.interfaces';

const useDeleteAnnounce = (options?: IUseDeleteAnnounceOptions): IUseDeleteAnnounce => {
  const { onCompleted, onError, token } = options ?? ({} as IUseDeleteAnnounceOptions);

  const [executeDeleteAnnounce, { loading: isLoading }] = useHowlerMutation<IDeleteAnnounceQueryGraphQLResponse, IDeleteAnnounceQueryVariables>({
    options: {
      onCompleted: () => {
        if (!onCompleted) {
          return;
        }

        onCompleted({});
      },
      onError,
    },
    mutation: DELETE_ANNOUNCE_QUERY,
    token,
  });

  const handleDeleteAnnounce = async (params: IDeleteAnnounceQueryVariables) => {
    await executeDeleteAnnounce({
      variables: params,
    });
  };

  return {
    handleDeleteAnnounce,
    isLoading,
  };
};

export { useDeleteAnnounce };
