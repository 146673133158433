import { useEffect, useState } from 'react';

import { IDropDownListOption } from '@netfront/ekardo-content-library';
import { useSearchGroups } from '@netfront/gelada-identity-library';
import {
  Button,
  Checkbox,
  InputFieldWrapper,
  SelectWithSearch,
  SidebarButtons,
  Spacing,
  Spinner,
} from '@netfront/ui-library';
import { IProgressTrackingFilterOptions } from 'components';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';


import { ProgressTrackingFilterTabProps } from './ProgressTrackingFilterTab.interfaces';

const ProgressTrackingFilterTab = ({
  filterOptions,
  onUpdate,
  onCancel,
  isSidebarOpen = false
}: ProgressTrackingFilterTabProps) => {

  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const [currentFilterOptions, setCurrentFilterOptions] = useState<IProgressTrackingFilterOptions>(filterOptions);
  const [projectId, setProjectId] = useState<string>();
  const [groupDropdownListOptions, setGroupsDropdownItems] = useState<IDropDownListOption[]>();
  const [isGroupSearchContentVisible, setIsGroupSearchContentVisible] = useState<boolean>(false);


  const { handleSearchGroups, isLoading: isSearchGroupsLoading = false } = useSearchGroups({
    onCompleted: ({ groups }) => {
      const groupsSelectSearchList = groups.map(
        ({ id, name }): IDropDownListOption => ({
          id: String(id),
          label: name,
          value: String(id),
        }),
      );

      setGroupsDropdownItems(
          [
              {
                id: '0',
                label: 'All groups',
                value: '0',
              },
              ...groupsSelectSearchList,
          ]

      );
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const {
    status,
    groupId,
  } = currentFilterOptions;


  const handleUpdateInput = ({ name, value }: { name: keyof IProgressTrackingFilterOptions; value: number | string }) => {
    setCurrentFilterOptions(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IProgressTrackingFilterOptions),
    );
  };

  const handleResetFilters = () => {
    onUpdate({
      status: 'ACTIVE',
      groupId: undefined,
    });
  };

  useEffect(() => {
    if (!queryProjectId) return;

    setProjectId(queryProjectId as string);

  }, [queryProjectId]);


  useEffect(() => {
    if (!projectId) return;
    if (!isSidebarOpen) return;

    void handleSearchGroups({
      projectId: String(projectId),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isSidebarOpen]);


  return (
    <>
    <Spinner isLoading={isSearchGroupsLoading} />
      <Spacing size="2x-large">
        <Checkbox
          id="id_is_active"
          isChecked={status === 'ACTIVE'}
          labelText="Is user active?"
          name="isActive"
          value=""
          onChange={({ target: { checked: isChecked, name }}) => {
            handleUpdateInput({
              name: name as  keyof IProgressTrackingFilterOptions,
              value: isChecked ? 'ACTIVE': 'INACTIVE'
            });
          }}
        />
      </Spacing>


      <Spacing size="x-large">
        <InputFieldWrapper
          id="groups"
          isLabelHidden={false}
          label="Group"
          labelType="span"
          tooltipPosition="start"
          tooltipText="Filter by a single group"
          type="select"
          isRequired
        >
          <SelectWithSearch
            additionalClassNames="c-select-with-search__users-table"
            buttonText={groupDropdownListOptions?.find(({ id }) => Number(id) === Number(groupId))?.label ?? 'All groups'}
            isDisplaySearchContent={isGroupSearchContentVisible}
            searchList={groupDropdownListOptions ?? []}
            isAvatarVisible
            onDisplaySearchContent={() => setIsGroupSearchContentVisible(!isGroupSearchContentVisible)}
            onSearchItemClick={(id: string | number) =>
              setCurrentFilterOptions(
                (currentState) =>
                  ({
                    ...currentState,
                    groupId: Number(id),
                  } as IProgressTrackingFilterOptions),
              )
            }
          />
        </InputFieldWrapper>

      </Spacing>

      <SidebarButtons
        additionalButton={<Button size="xs" text="Reset" type="button" onClick={handleResetFilters} />}
        buttonSize="xs"
        onCancel={onCancel}
        onSave={() => onUpdate(currentFilterOptions)}
        onSaveButtonType="button"
      />

    </>
  );
};

export { ProgressTrackingFilterTab };
