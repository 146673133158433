import { gql } from '@apollo/client';

const REMOVE_MEMBER_BY_AUTHORITY_MUTATION = gql`
  mutation RemoveMemberByAuthority($connectionId: Int = null, $communityId: Int!, $memberId: Int!) {
    communityConnection {
      removeMemberByAuthority(connectionId: $connectionId, communityId: $communityId, memberId: $memberId)
    }
  }
`;

export { REMOVE_MEMBER_BY_AUTHORITY_MUTATION };
