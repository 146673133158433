import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { InformationBox, Input, Spacing, ToggleSwitch } from '@netfront/ui-library';
import { SidebarContainer , LayoutSelector } from 'components';
import { Controller } from 'react-hook-form';


import { UpsertSectionGeneralTabProps } from './UpsertSectionGeneralTab.interfaces';


const UpsertSectionGeneralTab = ({ control, containerNumber }: UpsertSectionGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>
      <Spacing>
        <InformationBox message="Edit your section below." />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_section_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />

      </Spacing>


      <Spacing>
        <Controller
          control={control}
          name="contentLayoutId"
          render={({ field, fieldState }) => (
            <LayoutSelector
              containerNumber={containerNumber}
              fieldState={fieldState}
              initialValue={Number(field.value)}
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="isVisible"
          render={({ field }) => {
            return (
              <ToggleSwitch
                additionalClassNames={`c-sidebar-toggle`}
                id="id_is_visible"
                inlineLabelPosition="start"
                isChecked={field.value}
                labelText="Visible on load"
                tooltipPosition="start"
                tooltipText="Determine whether the section is visible by default"
                isInline
                {...field}
              />
            );
          }}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_sensitive_content"
              isChecked={field.value}
              labelText="Sensitive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

    </SidebarContainer>
  );
};

export { UpsertSectionGeneralTab };
