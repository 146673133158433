import { useContext, useEffect, useState } from 'react';

import { Pagination, SidebarNavigation, TablePageTemplate as NetfrontTablePageTemplate, SideBarTabSet, IBreadcrumbItem, PageContainer, ITableRequiredParameters } from '@netfront/ui-library';
import Link from 'next/link';

import { TablePageTemplateProps } from './TablePageTemplate.interfaces';

import { PageLayout } from '../..';
import { DashboardContext, formatDashboardItems } from '../../../context';



const TablePageTemplate = <T extends ITableRequiredParameters>({
  additionalBreadcrumbItems = [],
  activePage,
  activeSubPage,
  activePath,
  bulkActions,
  bulkActionPlaceHolder,
  childrenEnd,
  childrenMiddle,
  childrenStart,
  columns,
  data,
  defaultActiveTabId = 'id_general_tab',
  description,
  handleAddNewClick,
  handleOnPageSizeChange,
  handleOnPaginate,
  handleSearch,
  informationBoxMessage,
  isLoading,
  isPaginationDisabled = false,
  isSideBarOpen = false,
  pageSize = 10,
  pageTitle,
  searchPlaceholder = '',
  tabs = [],
  title,
  toggleIsSideBarOpen,
  totalItems = 0,
  hideSideBarButtons = true,
  tableType = '',
  onSaveButtonType = 'button',
  onSelectRows,
  size,
  iconId,
  avatarImageSrc,
  children,
  renderSubComponent
}: TablePageTemplateProps<T>) => {

  const { dashboardItems, dashboardLink } = useContext(DashboardContext);
  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>([]);

  useEffect(() => {
    if (!dashboardLink) {
      return;
    }

    setBreadcrumbItems([
      {
        key: '0',
        content: <Link href={dashboardLink}>Dashboard</Link>,
      },
      ...additionalBreadcrumbItems
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardLink]);

  return (
    <PageLayout
      additionalClassNames="c-table-page-template"
      isPreloaderVisible={isLoading}
      meta={{ description: description }}
      title={pageTitle}
      hasPrivateLayout
    >

      <SidebarNavigation
        activePage={activePage}
        activePath={activePath}
        activeSubPage={activeSubPage}
        dashboardLink={dashboardLink}
        navigationItems={formatDashboardItems(dashboardItems)}
      >
        <PageContainer>

          <NetfrontTablePageTemplate<T>
            addNewOnClick={handleAddNewClick}
            avatarImageSrc={avatarImageSrc}
            breadcrumbItems={breadcrumbItems}
            bulkActionPlaceHolder={bulkActionPlaceHolder}
            bulkActions={bulkActions}
            childrenEnd={childrenEnd}
            childrenMiddle={childrenMiddle}
            childrenStart={childrenStart}
            columns={columns}
            data={data}
            emptyStateImageUrl="/images/empty-state.png"
            iconId={iconId}
            informationBoxMessage={informationBoxMessage}
            pageTitle={pageTitle}
            renderSubComponent={renderSubComponent}
            searchPlaceholder={searchPlaceholder}
            size={size}
            tableType={tableType}
            title={title}
            onSearch={handleSearch}
            onSelectRows={onSelectRows}
          >
            {children}
          </NetfrontTablePageTemplate>

          {totalItems > pageSize && handleOnPaginate && handleOnPageSizeChange && (
            <Pagination
              isLoadMoreDisabled={isPaginationDisabled}
              pageSize={pageSize}
              totalItems={totalItems}
              onPageChange={handleOnPaginate}
              onPageSizeChange={handleOnPageSizeChange}
            />
          )}
        </PageContainer>

      </SidebarNavigation>
      {tabs.length > 0 && (
        <SideBarTabSet
          defaultActiveTabId={defaultActiveTabId}
          handleOpenCloseSideBar={toggleIsSideBarOpen ? toggleIsSideBarOpen : () => {return}}
          hideSideBarButtons={hideSideBarButtons}
          isSideBarOpen={isSideBarOpen}
          tabs={tabs}
          onSaveButtonType={onSaveButtonType}
        />
      )}
    </PageLayout>
  );
};

export { TablePageTemplate };
