import { IDBNotificationGroupInputType } from 'interfaces';

export const Ensure = (notificationGroup: IDBNotificationGroupInputType) => {
  if (notificationGroup.notifications.length === 0) {
    throw new Error('Please add at least one user in the notification');
  }
  if (!notificationGroup.templateId && !notificationGroup.content) {
    throw new Error('Please select a template or add a message in your notification');
  }
};
