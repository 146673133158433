import { gql } from '@apollo/client';

const GET_ANNOUNCE_QUERY = gql`
  query getAnnounce($id: Int!) {
    announce {
      get(id: $id) {
        end
        id
        isActive
        start
        content
        location
        title
      }
    }
  }
`;

export { GET_ANNOUNCE_QUERY };
