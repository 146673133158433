import { gql } from '@apollo/client';

const GET_CONTENT_GROUP_TYPES = gql`
  query($projectId: String!) {
    project {
      getProject(projectId: $projectId) {
        availableContentGroupType
      }
    }
  }
`;

export { GET_CONTENT_GROUP_TYPES };
