import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CODE_ASSET } from './useDeleteCodeAsset.graphql';
import {
  IDeleteCodeAssetMutationGraphQLResponse,
  IDeleteCodeAssetMutationVariables,
  IHandleDeleteCodeAssetParams,
  IUseDeleteCodeAssetOptions,
  IUseDeleteCodeAsset,
} from './useDeleteCodeAsset.interfaces';

const useDeleteCodeAsset = (options?: IUseDeleteCodeAssetOptions): IUseDeleteCodeAsset => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteCodeAssetOptions);

  const [executeDeleteCodeAsset, { loading: isLoading }] = useEkardoMutation<IDeleteCodeAssetMutationGraphQLResponse, IDeleteCodeAssetMutationVariables>({
    mutation: mutation ?? DELETE_CODE_ASSET,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { deleteAsset: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteCodeAsset = async ({ assetId }: IHandleDeleteCodeAssetParams) => {
    await executeDeleteCodeAsset({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleDeleteCodeAsset,
    isLoading,
  };
};

export { useDeleteCodeAsset };
