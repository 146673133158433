import { gql } from '@apollo/client';

const CREATE_CALENDER_CONFIGURATION = gql`
  mutation createCalenderQuesConfigRequest(
    $projectId: String!
    $description: String!
    $defaultDate: DateTime
    $minDate: DateTime
    $maxDate: DateTime
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationCalendar: {
          defaultDate: $defaultDate
          minDate: $minDate
          maxDate: $maxDate
        }
      ) {
        id
      }
    }
  }
`;

export { CREATE_CALENDER_CONFIGURATION };
