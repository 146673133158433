import { gql } from '@apollo/client';

const GET_USER_FLOW_STEP_INSIGHT = gql`
  query ($userFlowId: Int!, $start: DateTime, $end: DateTime) {
    report {
      userFlowStepCompletionInsight(userFlowId: $userFlowId, from: $start, to: $end) {
        name
        dailyCounts {
          count
          date
        }
      }
    }
  }
`;

export { GET_USER_FLOW_STEP_INSIGHT };
