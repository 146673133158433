import { gql } from '@apollo/client';


const DELETE_CONTENT_ENTITIES = gql`
  mutation deleteContentEntities($entities: [CmsEntityInputType]!) {
    contentPage {
      deleteEntities(entities: $entities)
    }
  }
`;

export { DELETE_CONTENT_ENTITIES };
