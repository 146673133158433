import { useBonoboMutation } from '../useBonoboMutation';

import { UPDATE_TOPIC_MUTATION } from './useUpdateTopic.graphql';
import {
  IUpdateTopicMutationGraphQLResponse,
  IUpdateTopicMutationVariables,
  IHandleUpdateTopicParams,
  IUseUpdateTopicOptions,
  IUseUpdateTopic,
} from './useUpdateTopic.interfaces';

const useUpdateTopic = (options?: IUseUpdateTopicOptions): IUseUpdateTopic => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseUpdateTopicOptions);

  const [executeUpdateTopic, { loading: isLoading }] = useBonoboMutation<
    IUpdateTopicMutationGraphQLResponse,
    IUpdateTopicMutationVariables
  >({
    mutation: mutation ?? UPDATE_TOPIC_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          topic: { editTopic: topicsConnection },
        } = data;

        onCompleted({
          topicsConnection,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateTopic = async ({ title, topicId, status }: IHandleUpdateTopicParams) => {
    await executeUpdateTopic({
      variables: {
        title,
        topicId,
        status,
      },
    });
  };

  return {
    handleUpdateTopic,
    isLoading,
  };
};

export { useUpdateTopic };
