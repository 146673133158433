import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, DatePicker, FlexContainer, IDateRange, IOption, Select } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { getReportDropdownOptions, TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetModulePageCompletionsReport, useGetProjectSteps, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { ALL_MODULE_PAGE_COLUMNS } from './ModulePageCompletions.constants';
import { getModulePageCompletionTableData } from './ModulePageCompletions.helpers';
import { ModulePageCompletionsCsvRowData, IModulePageCompletionItem, IModulePageCompletionTableData } from './ModulePageCompletions.interfaces';

import { DROPDOWN } from '../../Reporting.constants';


const ModulePageCompletions = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allModulePageCompletionItems, setAllModulePageCompletionItems] = useState<IModulePageCompletionItem[]>([]);
  const [modulePageCompletionItems, setModulePageCompletionItems] = useState<IModulePageCompletionItem[]>();
  const [modulePageCompletionTableData, setModulePageCompletionTableData] = useState<IModulePageCompletionTableData[]>([]);
  const [dateRange, setDateRange] = useState<IDateRange>();
  const [stepId, setStepId] = useState<number>();
  const [userFlowOptions, setUserFlowOptions] = useState<IOption[]>([]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const { handleGetProjectSteps, isLoading: isGetProjectStepsLoading = false } = useGetProjectSteps({
    onCompleted: (data) => {
        const { userFlowSteps } = data;

        setUserFlowOptions(
          getReportDropdownOptions(
            userFlowSteps,
            [{ key: 'stepName' }, { key: 'url', prefix: 'URL: /' }],
            'id',
          )
        );
    }
  });

  const {
    handleGetModulePageCompletionsReport,
    isLoading: isGetModulePagesLoading = false,
  } = useGetModulePageCompletionsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<ModulePageCompletionsCsvRowData>(String(res.data), {
            header: true,
          skipEmptyLines: true,
          });


          const formattedItems = data.map((row: ModulePageCompletionsCsvRowData) => ({
            id: Number(row['User id']),
            groupName: String(row['Group name']),
            completedOn: String(row['Completed']),
            completedOnUtc: String(row['Completed (utc)']),
            moduleTitle: String(row['Module title']),
            startedOn: String(row['Started']),
            startedOnUtc: String(row['Started (utc)']),
            timeToComplete: String(row['Time to complete']),
            pageTitle: String(row['Page title']),
            userType: String(row['User type']),
          }));
          
          setTotalCount(data.length);
          setAllModulePageCompletionItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setModulePageCompletionItems(paginatedItems);
          setLastItemIndex(pageSize);
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!stepId) {
      handleToastCustomError({
        message: 'Please select a step',
      });
      return;
    }

    void handleGetModulePageCompletionsReport({
      dateFrom: dateRange?.start ? dateRange.start : undefined,
      dateTo: dateRange?.end ? dateRange.end : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      stepId,
      fileType: 'CSV',
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setModulePageCompletionItems(allModulePageCompletionItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setModulePageCompletionItems(allModulePageCompletionItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!modulePageCompletionItems) {
      return;
    }

    setModulePageCompletionTableData(
      getModulePageCompletionTableData({
        items: modulePageCompletionItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modulePageCompletionItems]);

  useEffect(() => {
    if (!projectId) return;
    void handleGetProjectSteps({ projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetProjectStepsLoading || isGetModulePagesLoading;

  return (
    <TablePageTemplate<IModulePageCompletionTableData>
      activePath={['reporting', 'reporting-content', 'export-module-page-completions']}
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Reporting',
      }, {
        key: '2',
        content: 'Module page completions',
      }]}
      childrenEnd={
        <FlexContainer gap="2x-small">
          <Button
            isDisabled={!stepId}
            size="xs"
            text="Generate"
            onClick={generateReport}
          />
          {csvUrl && (
            <Button
              additionalClassNames="c-report-download-button"
              iconId="id_csv_icon"
              linkButton={{
                linkComponent: 'a',
                id: `id_csv_download`,
                url: csvUrl,
                target: '_blank'
              }}
              size="xs"
              text="Download"
              isIconOnly
            />
          )}
        </FlexContainer>
      }
      childrenMiddle={(
        <FlexContainer gap="2x-small">
          <div className="c-report-datepicker">
            <DatePicker
                hasPadding={false}
                id="id_date_selector"
                labelText="Select date range"
                selectedDate={dateRange}
                isLabelHidden
                isRange
                onDateRangeChangeHandler={(e) => {
                  if (!e) return;
                  setDateRange(e);
                }}
              />
          </div>

          <Select
            additionalClassNames="c-asset-sort-select"
            hasPadding={false}
            id="id_userflow_select"
            labelDefaultText={DROPDOWN.DEFAULT_OPTION_LABEL.STEP}
            labelText="Step"
            name="userflow_select"
            options={userFlowOptions}
            value={String(stepId)}
            isLabelHidden
            onChange={({ target: { value }}) => setStepId(Number(value))}
          />
        </FlexContainer>
      )}
      columns={ALL_MODULE_PAGE_COLUMNS}
      data={modulePageCompletionTableData}
      description={`Module completion reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports module page completions based on the selected filters'}
      isLoading={isLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      searchPlaceholder="Search"
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="reports"
      title={`${String(projectName)} reporting`}
      totalItems={totalCount}
    />
  );
};

export { ModulePageCompletions };
