import { ChangeEvent, useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { IDropDownListOption } from '@netfront/ekardo-content-library';
import {
  Input,
  NavigationButton,
  Select,
  SelectWithSearch,
  Spacing,
  Preloader,
  Textarea,
  InputFieldWrapper,
  SidebarButtons,
} from '@netfront/ui-library';
import { useToast, useGetPaginatedProjectCommunities, useAddUserInCommunity, useUpdateCommunityRole } from 'hooks';
import { IDBCommunity, DBUserConnectionRole } from 'interfaces';

import { CommunityFormViewProps } from './CommunityFormView.interfaces';

const CommunityFormView = ({ selectedCommunity, projectId, userId, onCreated, onOpenCommunityForm }: CommunityFormViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { handleToastError, handleToastSuccess } = useToast();

  const [currentCommunity, setCurrentCommunity] = useState<IDBCommunity | undefined>(selectedCommunity);
  const [isCommunitySearchContentVisible, setSearchIsCommunityContentVisible] = useState<boolean>(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState<number | undefined>(selectedCommunity?.id);
  const [userCommunities, setUserCommunities] = useState<IDropDownListOption[]>([]);
  const [userCommunityRole, setUserCommunityRole] = useState<DBUserConnectionRole>(selectedCommunity?.userConnection?.role ?? 'OWNER');

  const { handleGetPaginatedProjectCommunities, isLoading: isGetPaginatedUserCommunitiesLoading = false } =
    useGetPaginatedProjectCommunities({
      fetchPolicy: 'cache-and-network',
      projectId,
      onCompleted: ({ communityConnection: { edges } }) => {
        const communities = edges.map(({ node }) => node);

        setUserCommunities(
          communities.map(({ id, title }) => ({
            id: String(id),
            label: title,
            value: String(id),
          })),
        );
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
    });

  const { handleAddUserInCommunity: executeAddUserInCommunity, isLoading: isAddUserInCommunityLoading = false } = useAddUserInCommunity({
    onCompleted: () => {
      onCreated();

      handleToastSuccess({
        message: 'User added to the community successfully',
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  const { handleUpdateCommunityRole: executeUpdateCommunityRole, isLoading: isUpdateCommunityRoleLoading = false } = useUpdateCommunityRole(
    {
      onCompleted: () => {
        onCreated();

        handleToastSuccess({
          message: 'User role updated successfully',
        });
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
      projectId,
    },
  );

  const handleUpdateCommunityInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentCommunity(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBCommunity),
    );
  };

  const handleAddUserInCommunity = () => {
    executeAddUserInCommunity({
      communityId: Number(selectedCommunityId),
      role: userCommunityRole,
      userId: Number(userId),
    });
  };

  const handleUpdateCommunityRole = () => {
    executeUpdateCommunityRole({
      inputType: {
        communityId: Number(selectedCommunityId),
        id: Number(selectedCommunity?.userConnection?.id),
        role: userCommunityRole,
      },
    });
  };

  const handleUpdateCommunityRoleInput = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setUserCommunityRole(value as DBUserConnectionRole);
  };

  const isLoading = isGetPaginatedUserCommunitiesLoading || isAddUserInCommunityLoading || isUpdateCommunityRoleLoading;

  useEffect(() => {
    if (currentCommunity) {
      return;
    }

    handleGetPaginatedProjectCommunities({
      filter: '',
      projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="c-user-sidebar-community-view">
      {isLoading && <Preloader isLoading={isLoading} />}

      <Spacing size="2x-large">
        <NavigationButton
          additionalClassNames="c-action__back-to-action"
          direction="previous"
          iconId="id_caret_icon"
          rotationCssSuffix="90"
          text="Back to communities"
          onClick={() => onOpenCommunityForm()}
        />
      </Spacing>

      {currentCommunity ? (
        <>
          <Spacing size="large">
            <Input
              id="title"
              labelText="Title"
              name="title"
              tooltipText="Community title"
              type="text"
              value={currentCommunity.title}
              isDisabled
              onChange={handleUpdateCommunityInput}
            />

          </Spacing>

          <Spacing size="large">
            <Textarea
              id="description"
              labelText="Description"
              maxLength={500}
              name="description"
              tooltipText="Community description"
              value={currentCommunity.description}
              isDisabled
              onChange={handleUpdateCommunityInput}
            />
          </Spacing>

          <Spacing size="large">
            <Select
              id="privacy"
              labelText="Privacy"
              name="privacy"
              options={[
                {
                  name: 'Public',
                  value: 'PUBLIC',
                  id: 1,
                },
                {
                  name: 'Private',
                  value: 'PRIVATE',
                  id: 2,
                },
              ]}
              tooltipText="Public communities will be accessible by all users"
              value={currentCommunity.privacy}
              isDisabled
              onChange={handleUpdateCommunityInput}
            />
          </Spacing>

          <Spacing size="large">
            <Select
              id="role"
              labelText="Role"
              name="role"
              options={[
                {
                  name: 'Owner',
                  value: 'OWNER',
                  id: 1,
                },
                {
                  name: 'Moderator',
                  value: 'MODERATOR',
                  id: 2,
                },
                {
                  name: 'Member',
                  value: 'MEMBER',
                  id: 3,
                },
              ]}
              tooltipText="User role in the community"
              value={userCommunityRole}
              onChange={handleUpdateCommunityRoleInput}
            />
          </Spacing>
        </>
      ) : (
        <>
          <Spacing size="x-large">
            <InputFieldWrapper id="communities" label="Communities" type="select" isRequired>
              <SelectWithSearch
                buttonText="All available communities"
                countText="communities"
                isDisplaySearchContent={isCommunitySearchContentVisible}
                searchList={userCommunities}
                isAvatarVisible
                onDisplaySearchContent={() => setSearchIsCommunityContentVisible(!isCommunitySearchContentVisible)}
                onSearchItemClick={(id) => {
                  setSelectedCommunityId(Number(id));
                }}
              />
            </InputFieldWrapper>
          </Spacing>

          <Spacing size="large">
            <Select
              id="role"
              labelText="Role"
              name="role"
              options={[
                {
                  name: 'Owner',
                  value: 'OWNER',
                  id: 1,
                },
                {
                  name: 'Moderator',
                  value: 'MODERATOR',
                  id: 2,
                },
                {
                  name: 'Member',
                  value: 'MEMBER',
                  id: 3,
                },
              ]}
              tooltipText="User role in the community"
              value={userCommunityRole}
              isRequired
              onChange={handleUpdateCommunityRoleInput}
            />
          </Spacing>
        </>
      )}

      <SidebarButtons
        buttonSize="xs"
        onSave={selectedCommunity ? handleUpdateCommunityRole : handleAddUserInCommunity}
        onSaveButtonType="button"
      />
    </div>
  );
};

export { CommunityFormView };
