import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { InformationBox, Input, Spacing } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, SummarySnippetQuestionSelector } from 'components';
import { Controller } from 'react-hook-form';

import { UpsertSummarySnippetGeneralTabProps } from './UpsertSummarySnippetGeneralTab.interfaces';


const UpsertSummarySnippetGeneralTab = ({
  control,
  initialUserFlowStepId,
  initialQuestionId,
  setValue,
}: UpsertSummarySnippetGeneralTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  return (
    <ContentBuilderTabWrapper>
      <>
        <Spacing>
          <InformationBox message="Summarise previous responses." />
        </Spacing>
        <Spacing>
          <Controller
            control={control}
            name="title"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_summary_snippet_title"
                labelText="Title"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <SummarySnippetQuestionSelector
          control={control}
          initialQuestionId={initialQuestionId}
          initialUserFlowStepId={initialUserFlowStepId}
          setValue={setValue}
        />
      </>
    </ContentBuilderTabWrapper>
  );
};

export { UpsertSummarySnippetGeneralTab };
