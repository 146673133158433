import { useBonoboMutation } from '../useBonoboMutation';

import { UPDATE_COMMENT_MUTATION } from './useUpdateComment.graphql';
import {
  IUpdateCommentMutationGraphQLResponse,
  IUpdateCommentMutationVariables,
  IHandleUpdateCommentParams,
  IUseUpdateCommentOptions,
  IUseUpdateComment,
} from './useUpdateComment.interfaces';

const useUpdateComment = (options?: IUseUpdateCommentOptions): IUseUpdateComment => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseUpdateCommentOptions);

  const [executeUpdateComment, { loading: isLoading }] = useBonoboMutation<
    IUpdateCommentMutationGraphQLResponse,
    IUpdateCommentMutationVariables
  >({
    mutation: mutation ?? UPDATE_COMMENT_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          comment: { update: commentsConnection },
        } = data;

        onCompleted({ commentsConnection });
      },
      onError,
    },
    token,
  });

  const handleUpdateComment = async ({ message, id }: IHandleUpdateCommentParams) => {
    await executeUpdateComment({
      variables: {
        message,
        id,
      },
    });
  };

  return {
    handleUpdateComment,
    isLoading,
  };
};

export { useUpdateComment };
