import { gql } from '@apollo/client';

const GET_USER_FLOW_INSIGHT = gql`
  query ($projectId: String!, $start: DateTime, $end: DateTime) {
    report {
      userFlowCompletionInsight(projectId: $projectId, from: $start, to: $end) {
        name
        dailyCounts {
          count
          date
        }
      }
    }
  }
`;

export { GET_USER_FLOW_INSIGHT };
