import { useEffect, useState } from 'react';

import { IDBUser } from '@netfront/gelada-identity-library';
import { ITab, SidebarButtons, Spinner, TabSet } from '@netfront/ui-library';
import { useCreateNotificationGroup, useGetNotificationGroup, useGetTemplates } from 'hooks';
import { IDBNotification, IDBNotificationGroupInputType, IDBTemplate } from 'interfaces';

import { GeneralNotificationGroupSidebarView } from './GeneralNotificationGroupSidebarView/GeneralNotificationGroupSidebarView';
import {
  IGeneralNotificationGroupDetails,
  NotificationType,
} from './GeneralNotificationGroupSidebarView/GeneralNotificationGroupSidebarView.interfaces';
import { InviteNotificationGroupSidebarView } from './InviteNotificationGroupSidebarView/InviteNotificationGroupSidebarView';
import { Ensure } from './NotificationGroupSidebar.utils';
import { INotificationGroupSidebarViewProps } from './NotificationGroupSidebarView.interfaces';
import { ScheduleSidebarView } from './ScheduleSidebarView/ScheduleSidebarView';

export const NotificationGroupSidebarView = ({ projectId, notificationGroupId, onClose }: INotificationGroupSidebarViewProps) => {
  const [templates, setTemplates] = useState<IDBTemplate[]>([]);
  const [general, setGeneral] = useState<IGeneralNotificationGroupDetails>({ message: '', title: '', type: 'EMAIL' });
  const [defaultTemplate, setDefaultTemplate] = useState<string>();
  const [users, setUsers] = useState<IDBUser[]>([]);
  const [isScheduled, setIScheduled] = useState<boolean>(false);
  const [date, setDate] = useState<Date | undefined>();
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

  const { handleCreateNotificationGroup, isLoading: isCreateNotificationGroupLoading = false } = useCreateNotificationGroup({
    onCompleted() {
      // eslint-disable-next-line no-console
      console.info('Created');
    },
  });
  const { handleGetTemplates, isLoading: isGetTemplatesLoading = false } = useGetTemplates({
    onCompleted(data) {
      const { templates: fetchTemplates } = data;
      setTemplates(fetchTemplates);
    },
  });

  const handleTemplateUpdate = (id?: number) => {
    setGeneral({ ...general, templateId: id });
    if (id) {
      setDefaultTemplate(templates.find((r) => r.id === id)?.name ?? 'All available templates');
    } else {
      setDefaultTemplate('All available templates');
    }
  };

  const handleUpdateInput = (value: IGeneralNotificationGroupDetails) => {
    setGeneral(value);
  };

  const { handleGetNotificationGroup, isLoading: isGetNotificationGroupLoading = false } = useGetNotificationGroup({
    onCompleted(data) {
      const { notificationGroup } = data;
      const { notifications, content, hasBeenSent, subject, templateId, template } = notificationGroup;
      let type = 'EMAIL' as NotificationType;
      const [notification] = notifications;
      if (notifications.length > 0) {
        const { sendOn, type: notificationType } = notification;
        type = notificationType?.toUpperCase() as NotificationType;

        if (sendOn) {
          setIScheduled(true);
          setDate(new Date(sendOn));
        }
        setIsReadOnly(hasBeenSent);
        setUsers(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          notifications
            .map((r) => {
              const { user } = r;
              if (user) {
                return {
                  avatar: { configurations: [], id: 0, imageUrl: '', __typename: 'AvatarType' },
                  credential: { email: user.email, phoneNumber: '', services: [], secondaryEmail: '' },
                  communityName: '',
                  firstName: user.firstname,
                  customFields: [],
                  lastName: user.lastname,
                  notes: [],
                  status: 'ACTIVE',
                  id: user.id,
                  created: new Date(),
                };
              }
              return null;
            })
            .filter((r) => r != null),
        );
      }
      if (template) {
        const { name } = template;
        setDefaultTemplate(name);
      }
      setGeneral({ title: subject, templateId, message: content, type });
    },
  });

  useEffect(() => {
    if (!notificationGroupId) {
      return;
    }
    void handleGetNotificationGroup({ notificationGroupId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationGroupId]);

  const onSaveClick = () => {
    const notifications = users.map((r) => {
      const notification: IDBNotification = {
        userId: r.id,
      };
      return notification;
    });

    const notificationGroup: IDBNotificationGroupInputType = {
      id: notificationGroupId,
      content: general.templateId ? undefined : general.message,
      projectId: projectId,
      subject: general.title,
      templateId: general.templateId,
      notifications,
    };
    try {
      Ensure(notificationGroup);
      void handleCreateNotificationGroup({ notificationGroup, date: date, type: general.type });
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.info(ex);
    }
  };

  useEffect(() => {
    void handleGetTemplates({ projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs: ITab[] = [
    {
      iconId: 'id_general_tab_icon',
      id: 'general',
      label: 'General',
      view: () => (
        <GeneralNotificationGroupSidebarView
          defaultTemplate={defaultTemplate}
          details={general}
          handleTemplateUpdate={handleTemplateUpdate}
          handleUpdateInput={handleUpdateInput}
          isReadOnly={isReadOnly}
          templates={templates}
        />
      ),
    },
    {
      iconId: 'id_team_icon',
      id: 'users',
      label: 'Users',
      view: () => (
        <InviteNotificationGroupSidebarView
          isReadOnly={isReadOnly}
          users={users}
          onUpdate={(e) => {
            setUsers(e);
          }}
        />
      ),
    },
    {
      iconId: 'id_circle_plus_icon',
      id: 'schedule',
      label: 'Schedule',
      view: () => (
        <ScheduleSidebarView
          date={date}
          isReadOnly={isReadOnly}
          isSchedule={isScheduled}
          onDateUpdate={(e) => setDate(e)}
          onIsScheduleUpdate={(e) => setIScheduled(e)}
        />
      ),
    },
  ];

  return (
    <form>
      <Spinner isLoading={isGetTemplatesLoading || isCreateNotificationGroupLoading || isGetNotificationGroupLoading} />
      <TabSet defaultActiveTabId="general" tabs={tabs} />
      {!isReadOnly &&
        <SidebarButtons
          onCancel={onClose}
          onSave={onSaveClick}
          onSaveButtonType="button"
        />
      }
    </form>
  );
};
