/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_TEACHER_REGISTRATION_NO_TRIAL_QUERY } from './useGetTeacherRegistrationNoTrial.graphql';
import {
  IGetTeacherRegistrationNoTrialQueryGraphQLResponse,
  IGetTeacherRegistrationNoTrialQueryVariables,
  IUseGetTeacherRegistrationNoTrial,
  IUseGetTeacherRegistrationNoTrialOptions,
} from './useGetTeacherRegistrationNoTrial.interfaces';

const useGetTeacherRegistrationNoTrial = (options?: IUseGetTeacherRegistrationNoTrialOptions): IUseGetTeacherRegistrationNoTrial => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetTeacherRegistrationNoTrialOptions);

  const [executeGetTeacherRegistrationNoTrial, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetTeacherRegistrationNoTrialQueryGraphQLResponse,
    IGetTeacherRegistrationNoTrialQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ insights: { userRegistration } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userRegistration,
        });
      },
      onError,
    },
    query: GET_TEACHER_REGISTRATION_NO_TRIAL_QUERY,
    token,
  });

  const handleGetTeacherRegistrationNoTrial = async ({ projectId, end, start }: IGetTeacherRegistrationNoTrialQueryVariables) => {
    await executeGetTeacherRegistrationNoTrial({
      variables: {
        projectId,
        end,
        start,
      },
    });
  };

  return {
    handleGetTeacherRegistrationNoTrial,
    isLoading,
  };
};

export { useGetTeacherRegistrationNoTrial };
