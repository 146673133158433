import { gql } from '@apollo/client';

const GET_FORM_DETAILS = gql`
  query getContentGroupFormDetailReq($contentGroupId: Int!) {
    contentGroup {
      getContentGroup(contentGroupId: $contentGroupId) {
        id
        projectId
        sort
        title
        url
        description
        status
        formType
      }
    }
  }
`;


export { GET_FORM_DETAILS };


// contentPages {
//   id
//   contentSections {
//     id
//     sectionContainers {
//       id
//       snippets {
//         id
//         ... on ContentSnippetQuestionType {
//           question
//           configuration {
//             id
//             ... on Checkbox {
//               responseSet {
//                 ...ResponseSetFragment
//               }
//             }
//             ... on Radio {
//               responseSet {
//                 ...ResponseSetFragment
//               }
//             }
//             ... on DropDownList {
//               responseSet {
//                 ...ResponseSetFragment
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
// correctResponses {
//   contentGroupId
//   contentSnippetId
//   ... on CorrectResponseCheckboxType {
//     correctResponses {
//       questionResponseCheckboxId
//     }
//   }
//   ... on CorrectResponseRadioType {
//     questionResponseRadioId
//   }
//   ... on CorrectResponseDropDownListType {
//     questionResponseDropDownListId
//   }
// }
