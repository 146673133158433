import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ASSET_CAPTIONS_QUERY } from './useGetAssetCaptions.graphql';
import {
  IGetAssetCaptionsQueryGraphQLResponse,
  IGetAssetCaptionsQueryVariables,
  IHandleGetAssetCaptionsParams,
  IUseGetAssetCaptions,
  IUseGetAssetCaptionsOptions,
} from './useGetAssetCaptions.interfaces';

const useGetAssetCaptions = (options?: IUseGetAssetCaptionsOptions): IUseGetAssetCaptions => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAssetCaptionsOptions);

  const [executeGetAssetCaptions, { loading: isLoading }] = useEkardoLazyQuery<
    IGetAssetCaptionsQueryGraphQLResponse,
    IGetAssetCaptionsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          caption: { byAsset: assetCaptionsConnection },
        } = data;

        void onCompleted({
          assetCaptionsConnection,
        });
      },
      onError,
    },
    query: query ?? GET_ASSET_CAPTIONS_QUERY,
    token,
  });

  const handleGetAssetCaptions = ({ assetId }: IHandleGetAssetCaptionsParams) => {
    void executeGetAssetCaptions({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleGetAssetCaptions,
    isLoading,
  };
};

export { useGetAssetCaptions };
