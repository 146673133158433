import { gql } from '@apollo/client';

const UPDATE_FORM_MUTATION = gql`
  mutation updateContentGroupForm(
    $contentGroupId: Int!
    $sort: Int!
    $title: String!
    $description: String!
    $url: String!
  ) {
    contentGroup {
      updateContentGroup(
        contentGroup: {
          contentGroupId: $contentGroupId
          title: $title
          description: $description
          url: $url
          sort: $sort
        }
      ) {
        createdByUserId
        description
        id
        sort
        title
        url
      }
    }
  }
`;

export { UPDATE_FORM_MUTATION };
