import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_ACTION } from './useDeleteAction.graphql';
import { IDeleteActionMutationGraphQLResponse, IDeleteActionMutationVariables, IHandleDeleteActionParams, IUseDeleteAction, IUseDeleteActionOptions } from './useDeleteAction.interfaces';

const useDeleteAction = (options?: IUseDeleteActionOptions): IUseDeleteAction => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteActionOptions);

  const [executeDeleteAction, { loading: isLoading }] = useEkardoMutation<IDeleteActionMutationGraphQLResponse, IDeleteActionMutationVariables>({
    mutation: mutation ?? DELETE_ACTION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { removeAction: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteAction = ({ actionId }: IHandleDeleteActionParams) => {
    void executeDeleteAction({
      variables: {
        actionId,
      },
    });
  };

  return {
    handleDeleteAction,
    isLoading,
  };
};

export { useDeleteAction };
