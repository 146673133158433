import { gql } from '@apollo/client';

const SEARCH_PAGINATED_CHARACTERS_QUERY = gql`
  query searchPaginatedCharacters($projectId: String!, $filter: String, $first: Int, $after: String) {
    character {
      search(projectId: $projectId, filter: $filter, first: $first, after: $after) {
        totalCount
        edges {
          cursor
          node {
            id
            name
            image {
              assetId
              alt
              presignedUrl
            }
          }
        }
      }
    }
  }
`;

export { SEARCH_PAGINATED_CHARACTERS_QUERY };
