import { useState, useEffect } from 'react';

import { AssetType } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { AssetList } from './AssetList';


const AssetLibraryPage = () => {

  const { query } = useRouter();
  const { type } = query;

  const [assetType, setAssetType] = useState<string>('');

  useEffect(() => {
    if (!type) {
      return;
    }

    setAssetType(String(type).substring(0, type.length - 1));
  }, [type]);

  return (
    assetType ? <AssetList assetType={assetType as AssetType} /> : <></>

  );
};

export { AssetLibraryPage };
