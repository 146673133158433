import { gql } from '@apollo/client';

const DELETE_CONTENT_SECTION = gql`
  mutation deleteContentSection($contentSectionId: Int!) {
    contentSection {
      deleteContentSection(sectionContainerId: $contentSectionId)
    }
  }
`;


export { DELETE_CONTENT_SECTION };
