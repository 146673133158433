import { validateOnCompletedAndOnErrorOptions } from '@netfront/common-library';
import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_CODE_ASSET } from './useCreateCodeAsset.graphql';
import {
  ICreateCodeAssetMutationGraphQLResponse,
  ICreateCodeAssetMutationVariables,
  IHandleCreateCodeAssetParams,
  IUseCreateCodeAssetOptions,
  IUseCreateCodeAsset,
} from './useCreateCodeAsset.interfaces';


const useCreateCodeAsset = (options?: IUseCreateCodeAssetOptions): IUseCreateCodeAsset => {
  const { mutation, onCompleted, onCompletedAsync, onError, onErrorAsync, product, token } = options ?? {};

  validateOnCompletedAndOnErrorOptions({
    onCompleted,
    onCompletedAsync,
    onError,
    onErrorAsync,
  });

  const [executeCreateCodeAsset, { loading: isLoading }] = useLoggedGeladaMutation<
  ICreateCodeAssetMutationGraphQLResponse,
  ICreateCodeAssetMutationVariables
  >({
    mutation: mutation ?? CREATE_CODE_ASSET,
    options: {
      onCompleted: (data) => {
        const {
          asset: { createAsset: returnedAsset },
        } = data;


        if (onCompleted) {
          onCompleted({
            returnedAsset,
          });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({
            returnedAsset,
          });
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }

        if (onErrorAsync) {
          void onErrorAsync(error);
        }
      },
    },
    product,
    token,
  });

  const handleCreateCodeAsset = async ({ projectId, type, fileName, contentType, alt, fileSizeInBytes }: IHandleCreateCodeAssetParams) => {
    await executeCreateCodeAsset({
      variables: {
        projectId,
        type,
        fileName,
        contentType,
        alt,
        fileSizeInBytes,
      },
    });
  };

  return {
    handleCreateCodeAsset,
    isLoading,
  };
};

export { useCreateCodeAsset };
