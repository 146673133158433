import { gql } from '@apollo/client';

const GET_LANGUAGE_FILTERS_QUERY = gql`
  query getLanguageFilters(
    $after: String
    $first: Int
    $content: String
    $projectId: String!
    $type: ELanguageFilterType
  ) {
    languageFilter {
      list(after: $after, first: $first, content: $content, projectId: $projectId, type: $type) {
        edges {
          cursor
          node {
            id
            content
            isEmbargoingContent
            isNegative
            projectId
            type
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_LANGUAGE_FILTERS_QUERY };
