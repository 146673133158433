/* eslint-disable @typescript-eslint/indent */
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_USER_FLOW_INSIGHT } from './useGetUserFlowInsights.graphql';
import {
  IGetUserFlowInsightsQueryGraphQLResponse,
  IGetUserFlowInsightsQueryVariables,
  IUseGetUserFlowInsights,
  IUseGetUserFlowInsightsOptions,
} from './useGetUserFlowInsights.interfaces';

const useGetUserFlowInsights = (options?: IUseGetUserFlowInsightsOptions): IUseGetUserFlowInsights => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetUserFlowInsightsOptions);

  const [executeGetUserFlowInsights, { loading: isLoading }] = useEkardoLazyQuery<
    IGetUserFlowInsightsQueryGraphQLResponse,
    IGetUserFlowInsightsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { userFlowCompletionInsight } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          data: userFlowCompletionInsight,
        });
      },
      onError,
    },
    query: GET_USER_FLOW_INSIGHT,
    token,
  });

  const handleGetUserFlowInsights = async ({ end, projectId, start }: IGetUserFlowInsightsQueryVariables) => {
    await executeGetUserFlowInsights({
      variables: {
        end,
        start,
        projectId,
      },
    });
  };

  return {
    handleGetUserFlowInsights,
    isLoading,
  };
};

export { useGetUserFlowInsights };
