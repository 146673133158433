import React, { useEffect, useState } from 'react';

import { Spacing, Select, Input } from '@netfront/ui-library';

import { getAdditionalParameterOptions, getAdditionalParameters } from './AdditionalActionParametersSelector.helpers';
import { AdditionalActionParametersSelectorProps, IAdditionalParameterOptions, ParameterMapType } from './AdditionalActionParametersSelector.interfaces';

const AdditionalActionParametersSelector = ({ selectedActionType, onSelectParameters, actionDetails, selectedParamsId }: AdditionalActionParametersSelectorProps) => {
  const [options, setOptions] = useState<IAdditionalParameterOptions[]>([]);
  const [actionDetailsLabel, setActionDetailsLabel] = useState('');
  const [additionalParametersValue, setAdditionalParametersValue] = useState<string>('');

  const handleSelectAdditionalParameters = (value: string) => {
    const params = getAdditionalParameters(value as keyof ParameterMapType);
    onSelectParameters({
      params,
      value,
    });
    setAdditionalParametersValue(value);
  };

  useEffect(() => {
    if (!selectedActionType) return;
    setOptions(getAdditionalParameterOptions(selectedActionType));
  }, [selectedActionType]);

  useEffect(() => {
    if (!selectedParamsId) return;
    setAdditionalParametersValue(selectedParamsId);
  }, [selectedParamsId]);

  useEffect(() => {
    if (!actionDetails) return;

    const { __typename: ActionType, type } = actionDetails;

    if (ActionType === 'QuestionActionTriggerContentEventType') {
      setActionDetailsLabel(type);
    }
  }, [actionDetails]);

  return (
    <>
      {actionDetails ? (
        <Spacing size="2x-large">
          <Input
            id="id_additional_action_details"
            labelText="Additional action details"
            name="additional_action_details"
            type="text"
            value={actionDetailsLabel}
            isDisabled
            isLabelSideBySide
            onChange={() => { return }}
          />
        </Spacing>
      ) : (
        <Spacing size="2x-large">
          <Select
            id="id_additional_parameters_select"
            labelText="Additional Action details"
            name="additional_parameters_select"
            options={
              options
                .filter(({ isHidden = false }) => !isHidden)
                .map(({ id: optionId, value, name }) => ({
                  id: optionId,
                  value,
                  name,
                }))
            }
            value={additionalParametersValue}
            isLabelSideBySide
            onChange={({ target: { value } }: { target: { value: string}}) => handleSelectAdditionalParameters(value)}
          />
        </Spacing>
      )}
    </>
  );
};

export { AdditionalActionParametersSelector };
