import { gql } from '@apollo/client';
import { AppFragment, AudioFragment, ButtonFragment, CodeFragment, DocumentFragment, ContentSnippetSliderFragment, EmbedFragment, FormFragment, ImageFragment, MapFragment, QuestionFragment, QuoteFragment, RegionsFragment, SliderFragment, SpacerFragment, SummaryFragment, TextFragment, VideoFragment } from 'fragments';

const UPDATE_SECTION_GROUP = gql`
  mutation UpdateContentSectionGroup(
    $contentSectionGroup: UpdateContentSectionGroupInput!,
    $shouldCreateEvent: Boolean!,
    $createContentEvent: CreateContentEventInput,
    $shouldUpdateEvent: Boolean!,
    $updateContentEvent: UpdateContentEventInput,
  ) {
    contentEvent {
      createEvent(contentEvent: $createContentEvent)
        @include(if: $shouldCreateEvent) {
        id
      }
      updateEvent(contentEvent: $updateContentEvent)
        @include(if: $shouldUpdateEvent) {
        id
      }
    }
    sectionGroup {
      update(contentSectionGroup: $contentSectionGroup) {
        id
        contentPageId
        sort
        backgroundColor
        visible
        borderColor
        cssStyle {
          id
          name
        }
        background {
          assetId
          contentType
          s3Key
        }
        title
        style
        contentEvent {
          id
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          asset {
            assetId
            contentType
            s3Key
          }
          backgroundColor
          borderColor
          titleColor
          style
          triggerStyle
          iconHorizontalAlignment
          isDefaultOpen
        }
        contentSections {
          id
          contentPageId
          contentLayoutId
          sort
          backgroundColor
          visible
          borderColor
          cssStyle {
            id
            name
          }
          background {
            assetId
            contentType
            s3Key
          }
          title
          style
          contentEvent {
            id
            animation
            animationDirection
            entityId
            event
            eventDirection
            eventTitle
            infinite
            millisecondDelay
            millisecondSpeed
            asset {
              assetId
              contentType
              s3Key
            }
            backgroundColor
            borderColor
            titleColor
            style
            triggerStyle
            iconHorizontalAlignment
            isDefaultOpen
          }
          sectionContainers {
            id
            sort
            identifier
            sectionId
            backgroundColor
            borderColor
            cssStyle {
              id
              name
            }
            contentEventId
            contentEvent {
              animation
              animationDirection
              entityId
              event
              eventDirection
              eventTitle
              infinite
              millisecondDelay
              millisecondSpeed
              asset {
                assetId
                contentType
                s3Key
              }
              backgroundColor
              borderColor
              titleColor
              style
              triggerStyle
              iconHorizontalAlignment
              isDefaultOpen
            }
            snippets {
              id
              sort
              visible
              cssStyle {
                id
                name
              }
              ... on ContentSnippetAvatarType {
                avatar {
                  configuration {
                    key
                    value
                  }
                  imageUrl
                }
              }
              borderColor
              backgroundColor
              type: __typename
              __typename
              ...app
              ...audio
              ...button
              ...code
              ...document
              ...embed
              ...form
              ...image
              ...map
              ...question
              ...quote
              ...regions
              ...slider
              ...spacer
              ...summary
              ...text
              ...video
            }
          }
        }

      }
    }
  }

  ${AppFragment}
  ${AudioFragment}
  ${ButtonFragment}
  ${CodeFragment}
  ${ContentSnippetSliderFragment}
  ${DocumentFragment}
  ${EmbedFragment}
  ${FormFragment}
  ${ImageFragment}
  ${MapFragment}
  ${QuestionFragment}
  ${QuoteFragment}
  ${RegionsFragment}
  ${SliderFragment}
  ${SpacerFragment}
  ${SummaryFragment}
  ${TextFragment}
  ${VideoFragment}

`;

export { UPDATE_SECTION_GROUP };
