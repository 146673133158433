/* eslint-disable @typescript-eslint/indent */
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_GROUP_ACCESS } from './useGetGroupAccess.graphql';
import {
  IGetGroupAccessQueryGraphQLResponse,
  IGetGroupAccessQueryVariables,
  IUseGetGroupAccess,
  IUseGetGroupAccessOptions,
} from './useGetGroupAccess.interfaces';

const useGetGroupAccess = (options?: IUseGetGroupAccessOptions): IUseGetGroupAccess => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetGroupAccessOptions);

  const [executeGetGroupAccess, { loading: isLoading }] = useEkardoLazyQuery<
    IGetGroupAccessQueryGraphQLResponse,
    IGetGroupAccessQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ userFlowGroupAccessQuery: { byGroup } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          groupAccess: byGroup,
        });
      },
      onError,
    },
    query: query ?? GET_GROUP_ACCESS,
    token,
  });

  const handleGetGroupAccess = async ({ groupId }: IGetGroupAccessQueryVariables) => {
    await executeGetGroupAccess({
      variables: {
        groupId,
      },
    });
  };

  return {
    handleGetGroupAccess,
    isLoading,
  };
};

export { useGetGroupAccess };
