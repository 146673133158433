import { useContext, useEffect, useState } from 'react';

import { AppHeader, Footer, PageLayoutTemplate, Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import Head from 'next/head';

import { OrganisationMenu } from '../OrganisationMenu';
import { UserAccountMenu } from '../UserAccountMenu';

import { IMeta, PageLayoutProps } from './PageLayout.interfaces';

import { CachingEntitiesContext } from '../../context';

const PageLayout: React.FC<PageLayoutProps> = ({
  additionalClassNames,
  children,
  hasPrivateLayout,
  isPreloaderVisible = false,
  meta,
  title,
  isDisplayLayoutTemplate = true,
}: PageLayoutProps) => {
  const { project, organisation, cmsBaseUrl } = useContext(CachingEntitiesContext);

  const { description = 'Description' } = meta ?? ({} as IMeta);
  const [appLogo, setAppLogo] = useState<{ href: string, imageSrc: string} | undefined>();

  useEffect(() => {
    if (!(cmsBaseUrl && project && organisation)) return;
    setAppLogo({
      href: `${String(cmsBaseUrl)}/dashboard/${String(organisation.key)}`,
      imageSrc: project.logo?.presignedUrl ?? '',
    })
  }, [cmsBaseUrl, project, organisation]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta content={description} name="description" />
      </Head>
      {isDisplayLayoutTemplate ? (
        <PageLayoutTemplate
          additionalClassNames={cx('c-page-layout-template', additionalClassNames)}
          footer={<Footer projectName={project?.name ?? organisation?.name} />}
          header={
            <AppHeader logo={appLogo}>
              {hasPrivateLayout && (
                <>
                  <OrganisationMenu />
                  <UserAccountMenu />
                </>
              )}
            </AppHeader>
          }
        >
          <Spinner isLoading={isPreloaderVisible} />

          <main className="c-page-layout-template__main">
            {children}
          </main>
        </PageLayoutTemplate>
      ) : (
        children
      )}
    </>
  );
};

export { PageLayout };
