import { useContext, useState, useEffect } from 'react';

import { CachingEntitiesContext } from '../../../context';
import { DashboardListTemplatePage } from '../../Shared';

const ProjectDashboardPage = () => {
  const { project, isLoading = false } = useContext(CachingEntitiesContext);

  const [projectName, setProjectName] = useState<string>('');

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <DashboardListTemplatePage
      activePage="project"
      additionalClassNames="c-project-dashboard__page-layout"
      breadcrumbItems={[
        {
          content: <span>Dashboard</span>,
          key: 'Dashboard',
        },
        {
          content: <span>{projectName}</span>,
          key: 'projectName',
        },
      ]}
      description={`Project dashboard for ${projectName} project`}
      informationBoxMessage={<>Manage <strong>{projectName}</strong> project</>}
      isLoading={isLoading}
      logoUrl={project?.logo?.presignedUrl}
      pageId="project"
      pageTitle={`${projectName ? projectName + ' dashboard' : 'Dashboard'}`}
      projectName={projectName}
    />
  );
};

export { ProjectDashboardPage };
