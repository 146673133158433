import { gql } from '@apollo/client';

export const FRAGMENT_APP = gql`
  fragment app on ContentSnippetAppType {
    appComponentId
    app {
      description
      id
      title
      files {
        appComponentId
        fileType
        id
        locationId
      }
    }
  }
`;
