import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus } from "components";

import { IQuestionSnippetConfig } from "./UpsertQuestionSnippetSidebarView.interfaces";

import { responseTypeToUnderScoredConst } from '../../../../../constants';

const getQuestionSnippetDefaultValues = ({ snippet, questionType } : { questionType?: string; snippet?: IContentSnippet; }): FormFieldProps => {
  const { title, contentEvent, question, configuration, behavior, majorNumber, identifier, visible: isVisible = true } = snippet ?? {};

  const {
    __typename: type,
    allowUserGeneratedResponses: isAllowUserGeneratedResponses = false,
    minLength,
    maxLength,
    regex,
    maxCheckedResponse,
    minValue,
    maxValue,
    defaultValue,
    id: configId,
    minDate,
    maxDate,
    defaultDate,
    responseSet,
    step,
  } = configuration ?? {};

  const { id: responseSetId, title: responseSetTitle, availableResponses = []} = responseSet ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);

  return {
    title: title ?? 'Question snippet',
    question: question ?? '',
    behavior,
    resType: responseTypeToUnderScoredConst[!snippet ? String(questionType) : String(type)],
    majorNumber,
    identifier,
    isVisible,
    allowUserGeneratedResponses: isAllowUserGeneratedResponses,
    minLength: minLength ?? 1,
    maxLength: maxLength ?? 1000,
    regex: regex ?? '',
    maxCheckedResponse,
    minValue: minValue ?? 0,
    maxValue: maxValue ?? 100,
    defaultValue: defaultValue ?? 0,
    sliderMinValue: minValue ?? 0,
    sliderMaxValue: minValue ?? 10,
    sliderStep: step ?? 1,
    sliderDefaultValue: defaultValue ?? 5,
    sliderTicks: availableResponses,
    configId,
    minDate,
    maxDate,
    defaultDate,
    responseSetId,
    responseSetTitle,
    maxResponse: 10,
    minResponse: 3,
    isCreateForm: false,
    isCreateNew: false,

    ...eventDetails,
    ...animationDetails,
  };
};

const getQuestionSnippetCommonVariables = ({eventAssetId, item }: {eventAssetId?: IDBAsset['assetId']; item: FormFieldProps}): IQuestionSnippetConfig => {
  const {
    title = 'Question snippet',
    question,
    majorNumber,
    behavior,
    configId,
    isVisible,
    identifier,

    // config
    resType,
    allowUserGeneratedResponses,
    maxLength,
    minLength,
    regex,
    maxCheckedResponse,
    minValue,
    maxValue,
    defaultValue,
    minDate,
    maxDate,
    defaultDate,
    sliderMinValue,
    sliderMaxValue,
    sliderDefaultValue,
    sliderStep,
    sliderTicks,
    maxResponse,
    minResponse,
    responseSetId,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
    },
    questionSnippet: {
      majorNumber,
      behavior,
      minorNumber: '',
      question,
      configId,
      identifier,
    },
    configuration: {
      resType,
      allowUserGeneratedResponses,
      maxLength,
      minLength,
      regex,
      maxCheckedResponse,
      minValue,
      maxValue,
      defaultValue,
      minDate,
      maxDate,
      defaultDate,
      sliderMinValue,
      sliderMaxValue,
      sliderDefaultValue,
      sliderStep,
      sliderTicks,
      maxResponse,
      minResponse,
      responseSetId,
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    type: 'question',
  };

  return sharedVariables;
};


const setQuickAddQuestionValues = (values: FormFieldProps) => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Question snippet',
    question: '',
  };
};

export { getQuestionSnippetDefaultValues, getQuestionSnippetCommonVariables, setQuickAddQuestionValues };
