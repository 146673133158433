/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_STUDENT_REGISTRATION_NO_TRIAL_QUERY } from './useGetStudentRegistrationNoTrial.graphql';
import {
  IGetStudentRegistrationNoTrialQueryGraphQLResponse,
  IGetStudentRegistrationNoTrialQueryVariables,
  IUseGetStudentRegistrationNoTrial,
  IUseGetStudentRegistrationNoTrialOptions,
} from './useGetStudentRegistrationNoTrial.interfaces';

const useGetStudentRegistrationNoTrial = (options?: IUseGetStudentRegistrationNoTrialOptions): IUseGetStudentRegistrationNoTrial => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetStudentRegistrationNoTrialOptions);

  const [executeGetStudentRegistrationNoTrial, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetStudentRegistrationNoTrialQueryGraphQLResponse,
    IGetStudentRegistrationNoTrialQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ insights: { userRegistration } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userRegistration,
        });
      },
      onError,
    },
    query: GET_STUDENT_REGISTRATION_NO_TRIAL_QUERY,
    token,
  });

  const handleGetStudentRegistrationNoTrial = async ({ projectId, end, start }: IGetStudentRegistrationNoTrialQueryVariables) => {
    await executeGetStudentRegistrationNoTrial({
      variables: {
        projectId,
        end,
        start,
      },
    });
  };

  return {
    handleGetStudentRegistrationNoTrial,
    isLoading,
  };
};

export { useGetStudentRegistrationNoTrial };
