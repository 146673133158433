/* eslint-disable @typescript-eslint/indent */
import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_GROUP_ACCESS } from './useAddGroupAccess.graphql';
import {
  IDeleteGroupAccessQueryGraphQLResponse,
  IDeleteGroupAccessQueryVariables,
  IUseDeleteGroupAccess,
  IUseDeleteGroupAccessOptions,
} from './useAddGroupAccess.interfaces';

const useDeleteGroupAccess = (options?: IUseDeleteGroupAccessOptions): IUseDeleteGroupAccess => {
  const { onCompleted, onError, token } = options ?? ({} as IUseDeleteGroupAccessOptions);

  const [executeDeleteGroupAccess, { loading: isLoading }] = useEkardoMutation<
    IDeleteGroupAccessQueryGraphQLResponse,
    IDeleteGroupAccessQueryVariables
  >({
    options: {
      onCompleted: () => {
        if (!onCompleted) {
          return;
        }

        onCompleted({});
      },
      onError,
    },
    mutation: DELETE_GROUP_ACCESS,
    token,
  });

  const handleDeleteGroupAccess = async ({ groupId, userFlowId }: IDeleteGroupAccessQueryVariables) => {
    await executeDeleteGroupAccess({
      variables: {
        groupId,
        userFlowId,
      },
    });
  };

  return {
    handleDeleteGroupAccess,
    isLoading,
  };
};

export { useDeleteGroupAccess };
