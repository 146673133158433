
import { IFormsTableData, IFormsTableDataParams } from "./LibraryFormsPage.interfaces";


const getFormsTableData = ({ formItems, onSettingsButtonClick, baseUrl }: IFormsTableDataParams): IFormsTableData[] => {
  return formItems.map(({ description, id, title, sort, status }) => {
    return {
      description,
      title,
      id,
      sort,
      status: status === 'PUBLISHED' ? 'Active': 'Inactive',
      settingsButtonData: {
        url: `${baseUrl}/${id}?library=true`,
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getFormsTableData };
