import { ChangeEvent, useEffect, useState } from 'react';

import { Select, Spacing } from '@netfront/ui-library';
import { useToast, useUpdateBonoboUserStatus } from 'hooks';
import { IDBBonoboUser } from 'interfaces';

import { UserSidebarUserViewProps } from './UserSidebarUserView.interfaces';

const UserSidebarUserView = ({ selectedUser, onUserUpdate }: UserSidebarUserViewProps) => {
  const { handleToastSuccess } = useToast();

  const [user, setUser] = useState<IDBBonoboUser | undefined>();
  const [userStatus, setUserStatus] = useState<'ACTIVE' | 'BANNED'>();

  const { handleUpdateBonoboUserStatus } = useUpdateBonoboUserStatus({
    onCompleted() {
      if (user && userStatus) {
        onUserUpdate(user);
      }
      handleToastSuccess({ message: 'User status updated' });
    },
  });

  const handleUpdateUserStatus = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!selectedUser) {
      return;
    }

    const {
      target: { value },
    } = event;

    const status = value as 'ACTIVE' | 'BANNED';
    if (user) {
      const updatedUser = { ...user, status };
      setUser(updatedUser);
    }
    setUserStatus(status);

    void handleUpdateBonoboUserStatus({
      status,
      userId: selectedUser.id,
    });
  };

  useEffect(() => {
    if (!selectedUser) {
      return;
    }
    setUser(selectedUser);
    const { status } = selectedUser;
    setUserStatus(status);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  return (
    <Spacing size="large">
      <Select
        id="status"
        labelText="Status"
        name="status"
        options={[
          {
            name: 'Active',
            value: 'ACTIVE',
            id: 1,
          },
          {
            name: 'Banned',
            value: 'BANNED',
            id: 2,
          },
        ]}
        tooltipText="The status determines if the user is able to share"
        value={userStatus}
        onChange={handleUpdateUserStatus}
      />
    </Spacing>
  );
};

export { UserSidebarUserView };
