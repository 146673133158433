import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { ButtonIconOnly, Dialog, FlexContainer, Icon, InformationBox, Preloader, SettingsButton, Spacing } from '@netfront/ui-library';
import cx from 'classnames';
import { useGetPaginatedUserCommunities, useRemoveMemberByAuthority, useToast } from 'hooks';
import { IDBCommunity } from 'interfaces';

import { CommunityFormView } from './CommunityFormView';
import { UserSidebarCommunitiesViewProps } from './UserSidebarCommunitiesView.interfaces';

const UserSidebarCommunitiesView = ({ additionalClassNames, projectId, selectedUserId }: UserSidebarCommunitiesViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { handleToastError, handleToastSuccess } = useToast();

  const [isCommunityFormVisible, setIsCommunityFormVisible] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedCommunity, setSelectedCommunity] = useState<IDBCommunity>();
  const [userCommunities, setUserCommunities] = useState<IDBCommunity[]>();

  const { handleGetPaginatedUserCommunities, isLoading: isGetPaginatedUserCommunitiesLoading = false } = useGetPaginatedUserCommunities({
    fetchPolicy: 'cache-and-network',
    projectId,
    onCompleted: ({ communityConnection: { edges } }) => {
      const communities = edges.map(({ node }) => node);

      setUserCommunities(communities);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const { handleRemoveMemberByAuthority: executeRemoveMemberByAuthority, isLoading: isRemoveMemberByAuthorityLoading = false } =
    useRemoveMemberByAuthority({
      onCompleted: ({ isCompleted }) => {
        if (!isCompleted) {
          return;
        }

        setUserCommunities((currentState) => currentState?.filter((community) => community.id !== selectedCommunity?.id));
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
      projectId,
    });

  const handleAddedUserInCommunity = () => {
    setIsCommunityFormVisible(false);

    handleGetPaginatedUserCommunities({
      filter: '',
      userId: selectedUserId,
      shouldIncludeBannerImage: true,
      shouldIncludeProfileImage: true,
    });
  };

  const handleRemoveMemberByAuthority = (communityData: IDBCommunity) => {
    const { id: communityId, userConnection } = communityData;

    executeRemoveMemberByAuthority({
      communityId,
      memberId: Number(userConnection?.userId),
    });

    handleToastSuccess({
      message: 'User removed from community successfully',
    });

    setIsDeleteDialogOpen(false);
  };

  const handleClickUpdateCommunity = (community: IDBCommunity) => {
    setSelectedCommunity(community);
    setIsCommunityFormVisible(true);
  };

  useEffect(() => {
    if (!projectId || !selectedUserId) {
      return;
    }

    handleGetPaginatedUserCommunities({
      filter: '',
      userId: selectedUserId,
      shouldIncludeBannerImage: true,
      shouldIncludeProfileImage: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, selectedUserId]);

  const isLoading = isGetPaginatedUserCommunitiesLoading || isRemoveMemberByAuthorityLoading;

  if (!selectedUserId) return <></>;

  return (
    <div className={cx('c-user-communities-sidebar-view', additionalClassNames)}>
      <>
        {!isCommunityFormVisible ? (
          <>
            {isLoading ? (
              <Preloader isLoading={isLoading} />
            ) : userCommunities?.length !== 0 ? (
              <>
                <ul className="c-user-communities-sidebar-view__community-list">
                  {userCommunities?.map((currentUserCommunity) => {
                    const { id, title } = currentUserCommunity;

                    return (
                      <li key={`${id}-${title}`} className="c-user-communities-sidebar-view__community-item">
                        <span className="c-user-communities-sidebar-view__community__name">{title}</span>

                        <section className="c-user-communities-sidebar-view__add-section">
                          <SettingsButton
                            supportiveText="Open community"
                            onClick={() => handleClickUpdateCommunity(currentUserCommunity)}
                          />

                          <button
                            className="c-user-communities-sidebar-view__bin"
                            title="Delete community"
                            type="button"
                            onClick={() => {
                              setSelectedCommunity(currentUserCommunity);
                              setIsDeleteDialogOpen(true);
                            }}
                          >
                            <Icon className="c-user-communities-sidebar-view__bin-icon" id="id_bin_icon" />
                          </button>

                          <Dialog
                            isOpen={isDeleteDialogOpen}
                            title={`Delete the community?`}
                            onCancel={() => setIsDeleteDialogOpen(false)}
                            onClose={() => setIsDeleteDialogOpen(false)}
                            onConfirm={() => handleRemoveMemberByAuthority(currentUserCommunity)}
                          />
                        </section>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <Spacing>
                <InformationBox message="No communities found for the user" />
              </Spacing>
            )}

            {!isLoading && (
              <FlexContainer justifyContent="end" tag="section">
                <ButtonIconOnly
                  iconId="id_plus_icon"
                  text="Add community"
                  onClick={() => {
                    setSelectedCommunity(undefined);
                    setIsCommunityFormVisible(true);
                  }}
                />
              </FlexContainer>

            )}
          </>
        ) : (
          <CommunityFormView
            projectId={projectId}
            selectedCommunity={selectedCommunity}
            selectedUserId={selectedUserId}
            userId={selectedUserId}
            onCreated={handleAddedUserInCommunity}
            onOpenCommunityForm={() => setIsCommunityFormVisible(false)}
          />
        )}
      </>
    </div>
  );
};

export { UserSidebarCommunitiesView };
