import { gql } from '@apollo/client';

const DELETE_CONTENT_SNIPPET = gql`
  mutation deleteContentSnippet($contentSnippetId: Int!) {
    contentSnippet {
      deleteSnippet(contentSnippetId: $contentSnippetId)
    }
  }
`;


export { DELETE_CONTENT_SNIPPET };
