/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { ISummaryFeedbackConfiguration } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";

import { RESPONSE_TYPE } from "../../UpsertSummarySnippetFeedbackTab.constants";

const getFeedbackDefaultValues = (feedbackConfig?: ISummaryFeedbackConfiguration): FormFieldProps => {
  const { label, __typename: type, responseSetId } = feedbackConfig ?? {};

  return {
    label: label || '',
    type: String(RESPONSE_TYPE[String(type)]),
    responseSetId: responseSetId,
  };
};

export { getFeedbackDefaultValues };
