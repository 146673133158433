import { useBonoboMutation } from '../useBonoboMutation';

import { UPDATE_COMMUNITY_ROLE_MUTATION } from './useUpdateCommunityRole.graphql';
import {
  IUpdateCommunityRoleMutationGraphQLResponse,
  IUpdateCommunityRoleMutationVariables,
  IHandleUpdateCommunityRoleParams,
  IUseUpdateCommunityRoleOptions,
  IUseUpdateCommunityRole,
} from './useUpdateCommunityRole.interfaces';

const useUpdateCommunityRole = (options?: IUseUpdateCommunityRoleOptions): IUseUpdateCommunityRole => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseUpdateCommunityRoleOptions);

  const [executeUpdateCommunityRole, { loading: isLoading }] = useBonoboMutation<
    IUpdateCommunityRoleMutationGraphQLResponse,
    IUpdateCommunityRoleMutationVariables
  >({
    mutation: mutation ?? UPDATE_COMMUNITY_ROLE_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          communityConnection: { updateCommunityRole: communityRoleConnection },
        } = data;

        onCompleted({ communityRoleConnection });
      },
      onError,
    },
    token,
  });

  const handleUpdateCommunityRole = async ({ inputType: { communityId, role, id } }: IHandleUpdateCommunityRoleParams) => {
    await executeUpdateCommunityRole({
      variables: {
        inputType: {
          communityId,
          role,
          id,
        },
      },
    });
  };

  return {
    handleUpdateCommunityRole,
    isLoading,
  };
};

export { useUpdateCommunityRole };
