import { gql } from '@apollo/client';

const GET_NOTIFICATION_GROUP = gql`
  query getNotificationGroup($notificationGroupId: Int!) {
    notification {
      getNotificationGroup(id: $notificationGroupId) {
        id
        content
        subject
        hasBeenSent
        templateId
        template {
          name
        }
        notifications {
          sendingDate
          sendOn
          type
          receiver
          user {
            id
            email
            firstname
            lastname
          }
        }
      }
    }
  }
`;

export { GET_NOTIFICATION_GROUP };
