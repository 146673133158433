
import { INotificationsTableData, INotificationsTableDataParams } from "./NotificationsReportPage.interfaces";


const getNotificationsTableData = ({ items }: INotificationsTableDataParams): INotificationsTableData[] => {
  return items.map(({
    events = '',
    id,
    receiver = '',
    sendDate = '',
    sendDateUtc = '',
    sentOn = '',
    sentOnUtc = '',
    status = '',
    subject = '',
    templateName = '',
    type = '',
    userId,
   }) => {
    return {
      events,
      id,
      receiver,
      sendDate,
      sendDateUtc,
      sentOn,
      sentOnUtc,
      status,
      subject,
      templateName,
      type,
      userId,
    };
  });
};

export { getNotificationsTableData };
