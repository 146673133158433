
import { IModulePageTableData, IModulePageTableDataParams } from "./ModulePages.interfaces";


const getModulePageTableData = ({ items }: IModulePageTableDataParams): IModulePageTableData[] => {
  return items.map(({
    completedOn = '-',
    completedOnUtc = '-',
    groupName,
    id,
    moduleTitle,
    startedOn = '-',
    startedOnUtc = '-',
    timeToCompleteAll = '-',
    timeToCompleteFirstPage = '-',
    userType,
   }) => {
    return {
      completedOn,
      completedOnUtc,
      groupName,
      id,
      moduleTitle,
      startedOn,
      startedOnUtc,
      timeToCompleteAll,
      timeToCompleteFirstPage,
      userType,
    };
  });
};

export { getModulePageTableData };
