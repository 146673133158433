import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_FORM_MUTATION } from './useCreateForm.graphql';
import {
  ICreateFormMutationGraphQLResponse,
  ICreateFormMutationVariables,
  IHandleCreateFormParams,
  IUseCreateFormOptions,
  IUseCreateForm,
} from './useCreateForm.interfaces';

const useCreateForm = (options?: IUseCreateFormOptions): IUseCreateForm => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateFormOptions);

  const [executeCreateForm, { loading: isLoading }] = useEkardoMutation<ICreateFormMutationGraphQLResponse, ICreateFormMutationVariables>({
    mutation: mutation ?? CREATE_FORM_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: {
            createContentGroupForm: contentGroup,
          },
        } = data;

        void onCompleted({
          contentGroup
        });
      },
      onError,
    },
    token,
  });

  const handleCreateForm = async ({ projectId, title, description, formType }: IHandleCreateFormParams) => {
    await executeCreateForm({
      variables: {
        projectId,
        title,
        description,
        formType,
      },
    });
  };

  return {
    handleCreateForm,
    isLoading,
  };
};

export { useCreateForm };
