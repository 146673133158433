import { useBonoboMutation } from '../useBonoboMutation';

import { BULK_ADD_USER_IN_COMMUNITY_MUTATION } from './useBulkAddUserInCommunity.graphql';
import {
  IBulkAddUserInCommunityMutationGraphQLResponse,
  IBulkAddUserInCommunityMutationVariables,
  IHandleBulkAddUserInCommunityParams,
  IUseBulkAddUserInCommunityOptions,
  IUseBulkAddUserInCommunity,
} from './useBulkAddUserInCommunity.interfaces';

const useBulkAddUserInCommunity = (options?: IUseBulkAddUserInCommunityOptions): IUseBulkAddUserInCommunity => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseBulkAddUserInCommunityOptions);

  const [executeBulkAddUserInCommunity, { loading: isLoading }] = useBonoboMutation<
  IBulkAddUserInCommunityMutationGraphQLResponse,
  IBulkAddUserInCommunityMutationVariables
  >({
    mutation: mutation ?? BULK_ADD_USER_IN_COMMUNITY_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          communityConnection: { bulkAddUserInCommunity: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkAddUserInCommunity = ({ communityId, role, userIds }: IHandleBulkAddUserInCommunityParams) => {
    void executeBulkAddUserInCommunity({
      variables: {
        communityId,
        role,
        userIds,
      },
    });
  };

  return {
    handleBulkAddUserInCommunity,
    isLoading,
  };
};

export { useBulkAddUserInCommunity };
