import { gql } from '@apollo/client';



const GET_CONTENT_PAGE_DETAILS_QUERY = gql`
  query getContentPage(
    $contentPageId: Int!
  ) {
    contentPage {
      getContentPage(contentPageId: $contentPageId) {
        id
        seoDescription
        title
        url
        status
        tags {
          id
          name
          sort
        }
        asset {
          assetId
          presignedUrl
        }
      }
    }
  }
`;

export { GET_CONTENT_PAGE_DETAILS_QUERY };
