/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_ACTIVE_GROUPS_QUERY } from './useGetActiveGroups.graphql';
import {
  IGetActiveGroupsQueryGraphQLResponse,
  IGetActiveGroupsQueryVariables,
  IUseGetActiveGroups,
  IUseGetActiveGroupsOptions,
} from './useGetActiveGroups.interfaces';

const useGetActiveGroups = (options?: IUseGetActiveGroupsOptions): IUseGetActiveGroups => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetActiveGroupsOptions);

  const [executeGetActiveGroups, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetActiveGroupsQueryGraphQLResponse,
    IGetActiveGroupsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ insights: { activeGroups } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          activeGroups,
        });
      },
      onError,
    },
    query: GET_ACTIVE_GROUPS_QUERY,
    token,
  });

  const handleGetActiveGroups = async ({ projectId, end, start }: IGetActiveGroupsQueryVariables) => {
    await executeGetActiveGroups({
      variables: {
        projectId,
        end,
        start,
      },
    });
  };

  return {
    handleGetActiveGroups,
    isLoading,
  };
};

export { useGetActiveGroups };
