
import { ITagsTableData, ITagsTableDataParams } from "./TagsPage.interfaces";


const getTagsTableData = ({ tagItems, onSettingsButtonClick }: ITagsTableDataParams): ITagsTableData[] => {
  return tagItems.map(({ id, name, sort, projectId }) => {
    return {
      name,
      id,
      sort,
      settingsButtonData: {
        item: {
          id, name, sort, projectId
        },
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getTagsTableData };
