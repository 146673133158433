import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Input, Spacing, Textarea, ToggleSwitch } from '@netfront/ui-library';
import { SidebarContainer, Uploader } from 'components';
import { Controller } from 'react-hook-form';
import { toKebabCase } from 'utils';

import { UpsertPageGeneralTabProps } from './UpsertPageGeneralTab.interfaces';



const UpsertPageGeneralTab = ({ control, onDeleteAsset, onDrop, setValue }: UpsertPageGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>
      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_section_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
              onChange={(event) => {
                const { target: { value }} = event;
                field.onChange(event);
                setValue('url', toKebabCase(value))
              }}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="seoDescription"
          render={({ field, fieldState }) => (
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_page_description_textarea"
              labelText="Description"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="url"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_page_url"
              labelText="Url"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing >
        <Controller
          control={control}
          name="presignedUrl"
          render={({ field }) => (
            <Uploader
              assetType="image"
              initialUrl={field.value}
              isRequired={false}
              labelText="Image"
              isLabelSideBySide
              onDelete={onDeleteAsset}
              onDrop={onDrop}
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_sensitive_content"
              isChecked={field.value}
              labelText="Sensitive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

    </SidebarContainer>
  );
};

export { UpsertPageGeneralTab };
