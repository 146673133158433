import React, { useEffect, useState } from 'react';

import { DBQuestionConfigurationTypeType, IDBMatchItem, IDBQuestionResponse, IQuestionConfiguration } from '@netfront/ekardo-content-library';
import {
  RadioButtonGroup,
  CheckboxGroup,
  Input,
  Textarea,
  Select,
  Spacing,
  RangeInput,
  CustomRating,
  DatePicker,
  CustomRatingProps,
  InputFieldWrapper,
  Spinner,
} from '@netfront/ui-library';


import { QuestionPreviewProps } from './QuestionPreview.interfaces';

import { underScoredToResponseTypeConst } from '../../constants';
import { useGetQuestionPreview, useToast } from '../../hooks';
import { getQuestionText } from '../../utils';


const QuestionPreview = ({ questionId }: QuestionPreviewProps) => {
  const { handleToastError } = useToast();

  const [labelText, setLabelText] = useState<string>('');
  const [questionType, setQuestionType] = useState<DBQuestionConfigurationTypeType>();
  const [options, setOptions] = useState<IDBQuestionResponse[]>([]);
  const [config, setConfig] = useState<IQuestionConfiguration>();

  const { handleGetQuestionPreview, isLoading = false } = useGetQuestionPreview({
    onCompleted: ({ config: { question, configuration } }) => {
      const { __typename: inputType, responseSet } = configuration ?? {};
      const { availableResponses = [] } = responseSet ?? {};

      const label = getQuestionText(String(question), 'text/html') ?? `Question-${String(questionId)}`;

      setLabelText(label);
      setQuestionType(inputType);
      setOptions(availableResponses);
      setConfig(configuration as IQuestionConfiguration);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!questionId) return;

    void handleGetQuestionPreview({
      id: Number(questionId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);



  return (
    <>
      <Spinner isLoading={isLoading} />
      <InputFieldWrapper
        id="id_question_preview"
        label="Question preview"
        labelType="span"
        type="custom"
        isLabelSideBySide
      >
        <div className="c-question-preview">
          {questionType === underScoredToResponseTypeConst.CALENDAR && (
            <DatePicker id="id_question_date_preview" labelText="questionPreview" maxDate={config?.maxDate ? new Date(String(config.maxDate)): undefined} minDate={config?.minDate ? new Date(String(config.minDate)): undefined} selectedDate={new Date(String(config?.defaultDate))} isLabelHidden />
          )}

          {questionType === underScoredToResponseTypeConst.CHECKBOX && (
            <CheckboxGroup
              items={options.map(({ value, label }) => ({
                id: String(value),
                labelText: label,
                value: String(value)
              }))}
              legendText={labelText}
              name="question-checkbox-preview"
              isDisabled
              onChange={() => undefined}
            />
          )}

          {questionType === underScoredToResponseTypeConst.RATING && (
            <>
              <p className="c-label">{labelText}</p>
              <CustomRating
                iconCount={config?.maxRating}
                isLabelTextVisible={config?.areLabelsVisible}
                name="custom_rating_preview"
                precisionType={String(config?.precision).toLowerCase() as CustomRatingProps["precisionType"] ?? 'half' }
                rateValue={Number(config?.initialValue)}
                ratingIconType={String(config?.iconType).toLowerCase() as CustomRatingProps["ratingIconType"] ?? 'star'}
              />
            </>
          )}

          {questionType === underScoredToResponseTypeConst.DROP_DOWN_LIST && (
            <Select
              id="id_dropdown_preview"
              labelText={labelText}
              name="questions-single-select"
              options={options.map(({ id, label, value }) => ({
                id,
                name: String(label),
                value: String(value),
                isDisabled: true,
              }))}
              isDisabled
              onChange={() => undefined}
            />
          )}
          {questionType === underScoredToResponseTypeConst.MATCH && (
            <div>
              <ul style={{ listStyle: 'none', padding: '0' }}>
                {config?.matchItems.map((matchItem: IDBMatchItem, matchItemIndex: number) => (
                  <li key={matchItem.text}>
                    <p>
                      <strong>{matchItem.text}</strong>
                    </p>
                    <ul>
                      {config.responseSet.availableResponses
                        .filter(({ correctPosition }) => correctPosition === matchItemIndex)
                        .map(({ label }) => (
                          <li key={label}>{label}</li>
                        ))}
                    </ul>
                  </li>
                ))}
                {
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  (config?.responseSet.availableResponses.filter(({ correctPosition }) => correctPosition === null).length ?? 0) > 0 && (
                    <li>
                      <p style={{ fontStyle: 'italic' }}>Uncategorised</p>
                      <ul>
                        {config?.responseSet.availableResponses
                          .filter(({ correctPosition }) => correctPosition == null)
                          .map(({ label }) => (
                            <li key={label}>{label}</li>
                          ))}
                      </ul>
                    </li>
                  )}
              </ul>
            </div>
          )}
          {questionType === underScoredToResponseTypeConst.MULTI_RESPONSE && (
            <>
              <p className="c-label">{labelText}</p>
              {Array.from({ length: config?.minResponse ?? 0 }).map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Spacing key={String(index)} size="x-small">
                  <Input
                    id={`id_multi_response_preview_${index}`}
                    labelText={labelText}
                    name={`question-multi-response-text-preview-${index}`}
                    type="text"
                    isDisabled
                    isLabelHidden
                    onChange={() => undefined}
                  />
                </Spacing>
              ))}
            </>
          )}
          {questionType === underScoredToResponseTypeConst.MULTI_TEXT && (
            <Textarea
              id="id_multi_text_preview"
              labelText={labelText}
              name="question-multi-text-preview"
              isDisabled
              onChange={() => undefined}
            />
          )}
          {questionType === underScoredToResponseTypeConst.NUMBER && (
            <Input
              id="id_number_preview"
              labelText={labelText}
              name="question-number-preview"
              type="number"
              isDisabled
              onChange={() => undefined}
            />
          )}
          {questionType === underScoredToResponseTypeConst.RADIO && (
            <RadioButtonGroup
              items={options.map(({ id, label, value }) => ({
                id: String(id),
                labelText: String(label),
                value: String(value),
                isDisabled: true,
              }))}
              name="question-radio-preview"
              selectedValue=""
              title={labelText}
              onChange={() => undefined}
            />
          )}
          {questionType === underScoredToResponseTypeConst.SINGLE_TEXT && (
            <Input
              id="id_single_text_preview"
              labelText={labelText}
              name="question-single-text-preview"
              type="text"
              isDisabled
              onChange={() => undefined}
            />
          )}
          {questionType === underScoredToResponseTypeConst.SLIDER && (
            <>
              <p className="c-label">{labelText}</p>
              <RangeInput id="id_question_preview" maxValue={config?.maxValue} minValue={config?.minValue} step={config?.step} ticks={config?.ticks} onChange={() => { return }} />
            </>
          )}
        </div>
      </InputFieldWrapper>
    </>
  );
};

export { QuestionPreview };
