import { gql } from '@apollo/client';

const QUERY = gql`
  mutation createNotificationGroup($notificationGroup: InputNotificationGroup!, $type: ENotificationType!, $date: DateTime) {
    notification {
      sendNotifications(notificationGroup: $notificationGroup, notificationType: $type, date: $date)
    }
  }
`;

export { QUERY };
