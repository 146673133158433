import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { DBQuestionBehavior } from '@netfront/ekardo-content-library';
import { Dialog, Select, Spacing, Spinner, RadioButtonGroup } from '@netfront/ui-library';
import { ContentBuilderContext, IBulkActionEntity } from 'context';
import { useDeleteContentEntities, useToast, useUpdateContentEntitiesValidation, useUpdateContentEntitiesVisibility } from 'hooks';
import pluralize from 'pluralize';

import { CONTENT_BUILDER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP, CONTENT_BUILDER_BULK_ACTION_DIALOG_TITLE_MAP } from './ContentBuilderBulkActionDialog.constants';
import { ContentBuilderBulkActionDialogProps, IEntityItem } from './ContentBuilderBulkActionDialog.types';


const ContentBuilderBulkActionDialog = ({ isOpen = false }: ContentBuilderBulkActionDialogProps) => {
  const { handleToastError, handleToastSuccess } = useToast();
  const { bulkActionDetails, closeBulkActionDialog, handleBulkAction } = useContext(ContentBuilderContext);


  const [behavior, setbehavior] = useState<DBQuestionBehavior>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [entityItem, setEntityItem] = useState<IEntityItem>({} as IEntityItem);

  const { type = 'delete', selectedEntities = [] } = bulkActionDetails ?? {};

  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };

  const { handleDeleteContentEntities, isLoading: isDeleteContentEntitiesLoading = false } = useDeleteContentEntities({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Entities successfully deleted',
        });
        handleResetState();
        handleBulkAction();
      };
    },
    onError: handleError
  });

  const { handleUpdateContentEntitiesVisibility, isLoading: isUpdateContentEntitiesVisibilityLoading = false } = useUpdateContentEntitiesVisibility({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Entities visibility successfully updated',
        });
        handleResetState();
        handleBulkAction();
      };
    },
    onError: handleError
  });

  const { handleUpdateContentEntitiesValidation, isLoading: isUpdateContentEntitiesValidationLoading = false } = useUpdateContentEntitiesValidation({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Entities validation successfully updated',
        });
        handleResetState();
        handleBulkAction();
      };
    },
    onError: handleError
  });

  const handleResetState = () => {
    setEntityItem({} as IEntityItem);
    closeBulkActionDialog();
  };

  const deleteEntities = () => {
    void handleDeleteContentEntities({
      entities: selectedEntities.map((entity) => ({ id: Number(entity.id), entityType: entity.type })),
    });
  };

  const updateVisibility = () => {
    void handleUpdateContentEntitiesVisibility({
      entities: selectedEntities.map((entity) => ({ id: Number(entity.id), entityType: entity.type })),
      isVisible,
    });
  };

  const updateValidation = () => {
    if (!behavior) return;
    void handleUpdateContentEntitiesValidation({
      contentSnippetIds: selectedEntities.filter(({ type: entityType }) => entityType === 'SNIPPET').map(({id}) => Number(id)),
      behavior,
    });
  };



  const handleSave = () => {
    if (type === 'delete') deleteEntities();
    if (type === 'updateValidation') updateValidation();
    if (type === 'updateVisibility') updateVisibility();
  };

  const handleUpdateValidationInput = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setbehavior(value as DBQuestionBehavior);
  };

  const handleUpdateVisibiltyInput = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setIsVisible(value === 'true');
  };

  const categorizeByType = (entityArray: IBulkActionEntity[]): IEntityItem => {
    return entityArray.reduce((acc, entity) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!acc[entity.type]) {
        acc[entity.type] = [];
      }

      acc[entity.type].push( Number(entity.id));
      return acc;
    }, {} as IEntityItem);
  };

  const order: (keyof IEntityItem)[] = ['SECTION_GROUP', 'SECTION', 'CONTAINER','SNIPPET'];

  const sortedEntitiesByKey = Object.keys(entityItem)
  .filter((key): key is keyof IEntityItem => order.includes(key as keyof IEntityItem)) // Type guard to ensure the keys are valid
  .sort((a, b) => order.indexOf(a) - order.indexOf(b)); // Type assertion


  useEffect(() => {
    setEntityItem(categorizeByType(selectedEntities));
  }, [selectedEntities]);




  const isLoading = isUpdateContentEntitiesVisibilityLoading || isDeleteContentEntitiesLoading || isUpdateContentEntitiesValidationLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <Dialog
        additionalClassNames="c-content-builder-bulk-action-dialog"
        confirmText={CONTENT_BUILDER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[type]}
        isOpen={isOpen}
        title={CONTENT_BUILDER_BULK_ACTION_DIALOG_TITLE_MAP[type]}
        onCancel={handleResetState}
        onClose={handleResetState}
        onConfirm={handleSave}
      >
        <div className="">
          <p>You are about to {type === 'delete' ? 'delete': 'update'} the following:</p>
          <Spacing>
            <ul className="c-content-builder-bulk-action-dialog__list">
            {sortedEntitiesByKey
              .filter((key): key is keyof IEntityItem => order.includes(key) && entityItem[key].length > 0)
              .map((key) => (
                <li key={key}>
                  <p>
                    <strong>{entityItem[key].length}</strong> {pluralize(key.toLowerCase(), selectedEntities.length)}
                    {type === 'delete' && ['SECTION', 'CONTAINER', 'SECTION_GROUP'].includes(key) ? ' and all child entities.' : '.'}
                  </p>
                </li>
              )
            )}
            </ul>
          </Spacing>

          {type === 'updateValidation' && (
            <Spacing size="large">
              <Select
                id="id_behavior"
                labelText="Behavior"
                name="behavior"
                options={[
                  {
                    name: 'Mandatory',
                    value: 'MANDATORY',
                    id: 1,
                  },
                  {
                    name: 'Not required',
                    value: 'NOT_REQUIRED',
                    id: 2,
                  },
                  {
                    name: 'Prompt',
                    value: 'PROMPT',
                    id: 3,
                  },
                ]}
                value={behavior}
                isRequired
                onChange={handleUpdateValidationInput}
              />
            </Spacing>
          )}
          {type === 'updateVisibility' && (
            <Spacing size="large">
              <RadioButtonGroup
                  items={[
                    {
                      id: 'true',
                      labelText: 'True',
                      value: 'true',
                    },
                    {
                      id: 'false',
                      labelText: 'False',
                      value: 'false',
                    },
                  ]}
                  name="visibility"
                  selectedValue={String(isVisible)}
                  title="Is visible?"
                  onChange={handleUpdateVisibiltyInput}
                />
            </Spacing>
          )}
        </div>
      </Dialog>
    </>
  );
};

export { ContentBuilderBulkActionDialog };
