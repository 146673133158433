import { ChangeEvent, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Select, Spacing, Input, Checkbox, Dialog, SidebarButtons, Preloader } from '@netfront/ui-library';
import { useBulkUpsertLanguageFilter, useToast, useDeleteLanguageFilter } from 'hooks';
import { DBLanguageFilterType } from 'interfaces';

import { LanguageFilterSidebarGeneralViewProps } from './LanguageFilterSidebarGeneralView.interfaces';

const LanguageFilterSidebarGeneralView = ({ onUpdate, selectedLanguageFilter, projectId, onClose }: LanguageFilterSidebarGeneralViewProps) => {
  const { handleToastSuccess, handleToastError } = useToast();

  const [content, setContent] = useState<string>('');
  const [isEmbargoed, setIsEmbargoed] = useState<boolean>(false);
  const [isNegative, setIsNegative] = useState<boolean>(false);
  const [type, setType] = useState<DBLanguageFilterType | undefined>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);


  const { handleBulkUpsertLanguageFilter: handleUpsertLanguageFilter, isLoading: isUpsertLanguageFilterLoading = false } = useBulkUpsertLanguageFilter({
    onCompleted: () => {
      handleToastSuccess({
        message: `Language filter successfully ${selectedLanguageFilter ? 'updated': 'created'}`,
      });
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleDeleteLanguageFilter, isLoading: isDeleteLanguageFilterLoading = false } = useDeleteLanguageFilter({
    onCompleted: ({ isCompleted }) => {

      if (isCompleted) {
        onUpdate();
        handleToastSuccess({
          message: 'Language filter successfully deleted',
        });
      }

    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleSave = () => {
    handleUpsertLanguageFilter({
      items: [
        {
          id: selectedLanguageFilter?.id,
          content,
          isEmbargoingContent: isEmbargoed,
          isNegative,
          projectId: String(projectId),
          type: type as DBLanguageFilterType,
        }
      ]
    });

  };


  useEffect(() => {
    setContent(selectedLanguageFilter?.content ?? '')
    setIsEmbargoed(selectedLanguageFilter?.isEmbargoingContent ?? false)
    setIsNegative(selectedLanguageFilter?.isNegative ?? false )
    setType(selectedLanguageFilter?.type ?? undefined)
  }, [selectedLanguageFilter]);

  const isLoading = isUpsertLanguageFilterLoading || isDeleteLanguageFilterLoading;


  return (
    <>
      {isLoading && <Preloader isLoading={isLoading} />}
      <Spacing size="large">
        <Input
          id="content"
          labelText="Content"
          name="content"
          type="text"
          value={content}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const { target: { value } } = event;
            setContent(value);
          }}
        />
      </Spacing>
      <Spacing size="large">
        <Select
          id="id_filter_type"
          labelText="Filter type"
          name="filterType"
          options={[
            {
              id: 'STRICT',
              name: 'Strict',
              value: 'STRICT',
            },
            {
              id: 'MULTIPLE_WAYS',
              name: 'Multiple ways',
              value: 'MULTIPLE_WAYS',
            },
            {
              id: 'TRICKED',
              name: 'Tricked',
              value: 'TRICKED',
            },
          ]}
          value={type}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            const { target: { value } } = event;
            setType(value as DBLanguageFilterType);
          }}
        />
      </Spacing>
      <Spacing>
        <Checkbox
          id="id_is_negative"
          isChecked={isNegative}
          labelText="is Negative content?"
          name="isNegative"
          value=""
          onChange={(e) => {
            const { checked: isChecked } = e.target;
            setIsNegative(isChecked);
          }}
        />
      </Spacing>
      <Spacing>
        <Checkbox
          id="id_is_embargoing_content"
          isChecked={isEmbargoed}
          labelText="is embargoing content?"
          name="isEmbargoed"
          value=""
          onChange={(e) => {
            const { checked: isChecked } = e.target;
            setIsEmbargoed(isChecked);
          }}
        />
      </Spacing>

      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Do you want to delete the language filter?`}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedLanguageFilter) {
            return;
          }

          void handleDeleteLanguageFilter({
            id: selectedLanguageFilter.id,
          });
        }}
      />

      <SidebarButtons
        onCancel={onClose}
        onDelete={selectedLanguageFilter ? () => setIsDeleteDialogOpen(true) : undefined}
        onSave={handleSave}
        onSaveButtonType="button"
      />

    </>
  );
};

export { LanguageFilterSidebarGeneralView };
