import { useBonoboLazyQuery } from '../social/useBonoboLazyQuery';

import { GET_BONOBO_PROJECT } from './useGetBonoboProjectFeatures.graphql';
import {
  IGetBonoboProjectFeaturesQueryGraphQLResponse,
  IGetBonoboProjectFeaturesQueryVariables,
  IHandleGetBonoboProjectFeaturesParams,
  IUseGetBonoboProjectFeatures,
  IUseGetBonoboProjectFeaturesOptions,
} from './useGetBonoboProjectFeatures.interfaces';

const useGetBonoboProjectFeatures = (options?: IUseGetBonoboProjectFeaturesOptions): IUseGetBonoboProjectFeatures => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetBonoboProjectFeaturesOptions);

  const [executeGetBonoboProjectFeatures, { loading: isLoading }] = useBonoboLazyQuery<
    IGetBonoboProjectFeaturesQueryGraphQLResponse,
    IGetBonoboProjectFeaturesQueryVariables
  >({
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { listFeatures: features },
        } = data;

        const project = {
          id: bonoboProjectId,
          features,
        };

        void onCompleted({
          project,
        });
      },
      onError,
    },
    query: query ?? GET_BONOBO_PROJECT,
    token,
  });

  const handleGetBonoboProjectFeatures = ({ projectId }: IHandleGetBonoboProjectFeaturesParams) => {
    void executeGetBonoboProjectFeatures({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetBonoboProjectFeatures,
    isLoading,
  };
};

export { useGetBonoboProjectFeatures };
