import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { TOGGLE_ASSET_PUBLIC_MUTATION } from './useToggleAssetPublic.graphql';
import {
  IToggleAssetPublicMutationGraphQLResponse,
  IToggleAssetPublicMutationVariables,
  IHandleToggleAssetPublicParams,
  IUseToggleAssetPublicOptions,
  IUseToggleAssetPublic,
} from './useToggleAssetPublic.interfaces';

const useToggleAssetPublic = (options?: IUseToggleAssetPublicOptions): IUseToggleAssetPublic => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseToggleAssetPublicOptions);

  const [executeToggleAssetPublic, { loading: isLoading }] = useEkardoMutation<
    IToggleAssetPublicMutationGraphQLResponse,
    IToggleAssetPublicMutationVariables
  >({
    mutation: mutation ?? TOGGLE_ASSET_PUBLIC_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { toggleAssetPublic: assetConnection },
        } = data;

        onCompleted(assetConnection);
      },
      onError,
    },
    token,
  });

  const handleToggleAssetPublic = ({ assetId }: IHandleToggleAssetPublicParams) => {
    void executeToggleAssetPublic({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleToggleAssetPublic,
    isLoading,
  };
};

export { useToggleAssetPublic };
