import { gql } from '@apollo/client';

const CREATE_CONTAINER = gql`
  mutation addContentContainer($sectionContainer: AddSectionContainerInput!) {
    contentSection {
      addSectionContainer(sectionContainer: $sectionContainer) {
        id
      }
    }
  }
`;

export { CREATE_CONTAINER };
