import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_ASSET_CAPTION_MUTATION } from './useDeleteAssetCaption.graphql';
import {
  IDeleteAssetCaptionMutationGraphQLResponse,
  IDeleteAssetCaptionMutationVariables,
  IHandleDeleteAssetCaptionParams,
  IUseDeleteAssetCaptionOptions,
  IUseDeleteAssetCaption,
} from './useDeleteAssetCaption.interfaces';

const useDeleteAssetCaption = (options?: IUseDeleteAssetCaptionOptions): IUseDeleteAssetCaption => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteAssetCaptionOptions);

  const [executeDeleteAssetCaption, { loading: isLoading }] = useEkardoMutation<
    IDeleteAssetCaptionMutationGraphQLResponse,
    IDeleteAssetCaptionMutationVariables
  >({
    mutation: mutation ?? DELETE_ASSET_CAPTION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          caption: { delete: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteAssetCaption = ({ id }: IHandleDeleteAssetCaptionParams) => {
    void executeDeleteAssetCaption({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteAssetCaption,
    isLoading,
  };
};

export { useDeleteAssetCaption };
