/* eslint-disable @typescript-eslint/indent */
import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { ADD_GROUP_ACCESS } from './useAddGroupAccess.graphql';
import {
  IAddGroupAccessQueryGraphQLResponse,
  IAddGroupAccessQueryVariables,
  IUseAddGroupAccess,
  IUseAddGroupAccessOptions,
} from './useAddGroupAccess.interfaces';

const useAddGroupAccess = (options?: IUseAddGroupAccessOptions): IUseAddGroupAccess => {
  const { onCompleted, onError, token } = options ?? ({} as IUseAddGroupAccessOptions);

  const [executeAddGroupAccess, { loading: isLoading }] = useEkardoMutation<
    IAddGroupAccessQueryGraphQLResponse,
    IAddGroupAccessQueryVariables
  >({
    options: {
      onCompleted: () => {
        if (!onCompleted) {
          return;
        }

        onCompleted({});
      },
      onError,
    },
    mutation: ADD_GROUP_ACCESS,
    token,
  });

  const handleAddGroupAccess = async ({ groupId, userFlowId }: IAddGroupAccessQueryVariables) => {
    await executeAddGroupAccess({
      variables: {
        groupId,
        userFlowId,
      },
    });
  };

  return {
    handleAddGroupAccess,
    isLoading,
  };
};

export { useAddGroupAccess };
