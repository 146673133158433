import { gql } from '@apollo/client';

const DELETE_TOPIC_MUTATION = gql`
  mutation RemoveTopic($topicId: Int!) {
    topic {
      removeTopic(topicId: $topicId)
    }
  }
`;

export { DELETE_TOPIC_MUTATION };
