import { gql } from '@apollo/client';


const GET_CONTENT_PAGES = gql`
  query getContentPages($url: String!, $projectId: String!) {
    contentGroup {
      getContentGroupByUrl(url: $url, projectId: $projectId) {
        id
        title
        url
        firstPage {
          id
          url
        }
        contentPages(topLevelOnly: true, status: [PUBLISHED, UNPUBLISHED]) {
          id
          title
          url
          sort
        }
      }
    }
  }
`;

export { GET_CONTENT_PAGES };
