import { gql } from "@apollo/client";

export const ASSET_FRAGMENT = gql`
  fragment asset on AssetType {
    alt
    assetId
    contentType
    description
    fileName
    isFavorite
    isPublic
    tagList {
      id
      name
    }
    presignedUrl
    projectId
    publicS3Key
    publicUrl
    s3Key
    title
    transcript
    type
    uploaded
    user {
      firstName
      lastName
    }
    xAxisFocus
    yAxisFocus
  }
`;
