import { useVervetMutation } from '../useVervetMutation';

import { DELETE_DISCOUNT_MUTATION } from './useDeleteDiscount.graphql';
import {
  IDeleteDiscountMutationGraphQLResponse,
  IDeleteDiscountMutationVariables,
  IHandleDeleteDiscountParams,
  IUseDeleteDiscountOptions,
  IUseDeleteDiscount,
} from './useDeleteDiscount.interfaces';

const useDeleteDiscount = (options?: IUseDeleteDiscountOptions): IUseDeleteDiscount => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteDiscountOptions);

  const [executeDeleteDiscount, { loading: isLoading }] = useVervetMutation<
    IDeleteDiscountMutationGraphQLResponse,
    IDeleteDiscountMutationVariables
  >({
    mutation: mutation ?? DELETE_DISCOUNT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          discounts: { remove: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteDiscount = ({ id }: IHandleDeleteDiscountParams) => {
    void executeDeleteDiscount({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteDiscount,
    isLoading,
  };
};

export { useDeleteDiscount };
