
export const animationDirectionOptions = [
  {
    id: 0,
    value: 'TOP',
    name: 'Top',
  },
  {
    id: 1,
    value: 'RIGHT',
    name: 'Right',
  },
  {
    id: 2,
    value: 'LEFT',
    name: 'Left',
  },
  {
    id: 3,
    value: 'BOTTOM',
    name: 'Bottom',
  },
];

export const animationTypeOptions = [
  {
    id: 0,
    value: 'NONE',
    name: 'None',
  },
  {
    id: 1,
    value: 'BOUNCE_IN',
    name: 'Bounce In',
  },
  {
    id: 2,
    value: 'FADE_IN',
    name: 'Fade In',
  },
  {
    id: 3,
    value: 'ZOOM_IN',
    name: 'Zoom In',
  },
  {
    id: 4,
    value: 'SLIDE_IN',
    name: 'Slide In',
  },
  {
    id: 5,
    value: 'ROTATE_IN',
    name: 'Rotate In',
  },
  // The following types don't have direction.
  {
    id: 6,
    value: 'BOUNCE',
    name: 'Bounce',
  },
  {
    id: 7,
    value: 'FLASH',
    name: 'Flash',
  },
  {
    id: 8,
    value: 'PULSE',
    name: 'Pulse',
  },
  {
    id: 9,
    value: 'RUBBER_BAND',
    name: 'Rubber band',
  },
  {
    id: 10,
    value: 'SHAKE',
    name: 'Shake',
  },
  {
    id: 11,
    value: 'HEAD_SHAKE',
    name: 'Head Shake',
  },
  {
    id: 12,
    value: 'SWING',
    name: 'Swing',
  },
  {
    id: 13,
    value: 'TADA',
    name: 'Tada',
  },
  {
    id: 14,
    value: 'WOBBLE',
    name: 'Wobble',
  },
  {
    id: 15,
    value: 'JELLO',
    name: 'Jello',
  },
  {
    id: 16,
    value: 'HINGE',
    name: 'Hinge',
  },
  {
    id: 17,
    value: 'HEART_BEAT',
    name: 'Heart Beat',
  },
  {
    id: 18,
    value: 'JACK_IN_THE_BOX',
    name: 'Jack In The Box',
  },
];

export const delayTimeOptions = [
  {
    id: 0,
    value: 1000,
    name: '1s',
  },
  {
    id: 1,
    value: 2000,
    name: '2s',
  },
  {
    id: 2,
    value: 3000,
    name: '3s',
  },
  {
    id: 3,
    value: 4000,
    name: '4s',
  },
  {
    id: 4,
    value: 5000,
    name: '5s',
  },
];

export const animationSpeedOptions = [
  {
    id: 0,
    value: 500,
    name: 'faster',
  },
  {
    id: 1,
    value: 800,
    name: 'fast',
  },
  {
    id: 2,
    value: 1000,
    name: 'default',
  },
  {
    id: 3,
    value: 2000,
    name: 'slow',
  },
  {
    id: 4,
    value: 3000,
    name: 'slower',
  },
];

export const typesWithAnimationDirection = ['BOUNCE_IN', 'FADE_IN', 'ZOOM_IN', 'SLIDE_IN', 'ROTATE_IN'];

