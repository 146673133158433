import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { ATTACH_DIRECTORY_FILE } from './useAttachDirectoryFile.graphql';
import {
  IAttachDirectoryFileMutationGraphQLResponse,
  IAttachDirectoryFileMutationVariables,
  IHandleAttachDirectoryFileParams,
  IUseAttachDirectoryFile,
  IUseAttachDirectoryFileOptions,
} from './useAttachDirectoryFile.interfaces';

const useAttachDirectoryFile = (options?: IUseAttachDirectoryFileOptions): IUseAttachDirectoryFile => {
  const { onCompleted, onError, mutation, token } = options ?? ({} as IUseAttachDirectoryFileOptions);

  const [executeAttachDirectoryFile, { loading: isLoading }] = useEkardoMutation<
  IAttachDirectoryFileMutationGraphQLResponse,
  IAttachDirectoryFileMutationVariables
  >({
    mutation: mutation ?? ATTACH_DIRECTORY_FILE,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          directory: { attachFile: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });


  const handleAttachDirectoryFile = ({ id, assetId }: IHandleAttachDirectoryFileParams) => {
    void executeAttachDirectoryFile({
      variables: {
        id,
        assetId,
      },
    });
  };

  return {
    handleAttachDirectoryFile,
    isLoading,
  };
};

export { useAttachDirectoryFile };
