import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, DatePicker, FlexContainer, IDateRange } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetCommentsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { COMMENTS_COLUMNS } from './CommentsReportPage.constants';
import { getCommentsTableData } from './CommentsReportPage.helpers';
import { CommentsCsvRowData, ICommentsItem, ICommentsTableData } from './CommentsReportPage.interfaces';



const CommentsReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [commentsItems, setCommentsItems] = useState<ICommentsItem[]>();
  const [allCommentsItems, setAllCommentsItems] = useState<ICommentsItem[]>([]);
  const [commentsTableData, setCommentsTableData] = useState<ICommentsTableData[]>([]);
  const [dateRange, setDateRange] = useState<IDateRange>();
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetCommentsReport,
    isLoading: isGetCommentsLoading = false,
  } = useGetCommentsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<CommentsCsvRowData>(String(res.data), {
            header: true,
            skipEmptyLines: true,
          });

          const formattedItems = data.map((row: CommentsCsvRowData) => ({
            id: Number(row['Author id']),
            commentId: row['Comment id'],
            groupName: String(row['Group name']),
            comment: String(row['Comment']),
            commentDate: String(row['Comment date']),
            commentDateUtc: String(row['Comment date (utc)']),
            postId: row['Post id'],
            parentCommentId: row['Parent comment id'],
            userType: String(row['User type']),
          }));
          
          setTotalCount(data.length);
          setAllCommentsItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setCommentsItems(paginatedItems);
          setLastItemIndex(pageSize);

        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  const generateReport = () => {
    void handleGetCommentsReport({
      dateFrom: dateRange?.start ? dateRange.start : undefined,
      dateTo: dateRange?.end ? dateRange.end : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: 'CSV'
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setCommentsItems(allCommentsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setCommentsItems(allCommentsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!commentsItems) {
      return;
    }

    setCommentsTableData(
      getCommentsTableData({
        items: commentsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetCommentsLoading;

  return (
    <TablePageTemplate<ICommentsTableData>
      activePath={['reporting', 'reporting-social', 'export-comments']}
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Reporting',
      }, {
        key: '2',
        content: 'Comments',
      }]}
      childrenEnd={
        <FlexContainer gap="2x-small">
          <Button
            size="xs"
            text="Generate"
            onClick={generateReport}
          />
          {csvUrl && (
            <Button
              additionalClassNames="c-report-download-button"
              iconId="id_csv_icon"
              linkButton={{
                linkComponent: 'a',
                id: `id_csv_download`,
                url: csvUrl,
                target: '_blank'
              }}
              size="xs"
              text="Download"
              isIconOnly
            />
          )}
        </FlexContainer>
      }
      childrenMiddle={(
        <DatePicker
          hasPadding={false}
          id="id_date_selector"
          labelText="Select date range"
          selectedDate={dateRange}
          isLabelHidden
          isRange
          onDateRangeChangeHandler={(e) => {
            if (!e) return;
            setDateRange(e);
          }}
        />
      )}
      columns={COMMENTS_COLUMNS}
      data={commentsTableData}
      description={`Comments reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports comments based on the selected filters'}
      isLoading={isLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      searchPlaceholder="Search"
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="reports"
      title={`${String(projectName)} reporting`}
      totalItems={totalCount}
    />
  );
};

export { CommentsReportPage };
