import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentSection } from '@netfront/ekardo-content-library';
import { getUpsertContentEventVariables } from 'components';
import { ICreateSectionMutationParams, IUpdateSectionMutationParams, useCreateContentEvent, useCreateSection, useToast, useUpdateSection } from 'hooks';

import { ISectionConfig } from './useUpsertSection.interfaces';


const useUpsertSection = ({ onCreate, onUpdate }: { onCreate: () => void; onUpdate: (returnedSection: IContentSection) => void }) => {
  const sectionVariables = useRef<{value :ISectionConfig | undefined}>({value: undefined});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const getSectionVariables = ({contentPageId, sectionGroupId, sectionId, type }: {contentPageId?: number; sectionGroupId?: number; sectionId?: number; type: 'create' | 'update'; }): ICreateSectionMutationParams | IUpdateSectionMutationParams => {

    const { section = {}, contentEvent = {}, shouldCreateEvent = false, shouldUpdateEvent = false } = sectionVariables.current.value ?? {};

    const { containers = [], sort, contentLayoutId, title, isVisible} = section;

    const { contentEntity, contentEventId } = contentEvent;

    const variableMap = {
      create: {
        contentSection: {
          contentPageId: Number(contentPageId),
          sectionGroupId: Number(sectionGroupId),
          contentLayoutId,
          sort,
          containers,
          title,
          visible: isVisible,
        }
      } as ICreateSectionMutationParams,
      update: {
        contentSection: {
          contentLayoutId,
          sectionId: Number(sectionId),
          sort,
          title,
          visible: isVisible,
        },
        shouldCreateEvent,
        shouldUpdateEvent,
        updateContentEvent: shouldUpdateEvent ? {
          contentEventId: Number(contentEventId),
          ...getUpsertContentEventVariables(contentEvent, 'update'),
        }: undefined ,
        createContentEvent: shouldCreateEvent ? {
          entityId: Number(sectionId),
          contentEntity,
          ...getUpsertContentEventVariables(contentEvent, 'create'),
        }: undefined
      } as IUpdateSectionMutationParams
    };

    return variableMap[type];
  };

  const { handleCreateContentEvent, isLoading: isCreateContentEventLoading = false } = useCreateContentEvent({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Section successfully created',
      });

      onCreate();

    },
    onError: handleGetError,
  });

  const { handleCreateSection, isLoading: isCreateSectionLoading = false } = useCreateSection({
    onCompleted: ({ section : { id }}) => {

      const { shouldCreateEvent = false, contentEvent = {} } = sectionVariables.current.value ?? {};

      if (!shouldCreateEvent) {
        handleToastSuccess({
          message: 'Section successfully created',
        });
        onCreate();
      } else {

        const { contentEntity } = contentEvent;
        handleCreateContentEvent({
          contentEvent: {
            entityId: id,
            contentEntity,
            ...getUpsertContentEventVariables(contentEvent, 'create'),
          }
        });
      }

    },
    onError: handleGetError,
  });

  const { handleUpdateSection, isLoading: isUpdateSectionLoading = false } = useUpdateSection({
    onCompleted: ({ section: returnedSection }) => {
      handleToastSuccess({
        message: 'Section successfully updated',
      });
      onUpdate(returnedSection);
    },
    onError: handleGetError,
  });

  const handleUpsertSection = ({ containerId, contentPageId, sectionId, variables }: {containerId: number; contentPageId: number; sectionId?: number; variables: ISectionConfig; }) => {
    sectionVariables.current.value = variables;

    if (!sectionId) {

      const createSectionVariables = getSectionVariables({
        contentPageId: Number(contentPageId),
        sectionGroupId: Number(containerId),
        type: 'create',
      }) as ICreateSectionMutationParams;

      handleCreateSection({
        ...createSectionVariables
      });

    } else {
      const updateSectionVariables = getSectionVariables({
        sectionId: Number(sectionId),
        type: 'update',
      }) as IUpdateSectionMutationParams;

      handleUpdateSection({
        ...updateSectionVariables,
      });
    }
  };

  return {
    isLoading: isCreateSectionLoading || isUpdateSectionLoading || isCreateContentEventLoading,
    handleUpsertSection,
  };
};
export { useUpsertSection };
