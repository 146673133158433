import { ChangeEvent, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Dialog, Select, Spacing, Spinner } from '@netfront/ui-library';
import { useToast, useBulkDeleteLanguageFilters, useBulkUpsertLanguageFilter } from 'hooks';
import { DBLanguageFilterType } from 'interfaces';
import pluralize from 'pluralize';

import { LANGUAGE_FILTER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP, LANGUAGE_FILTER_BULK_ACTION_DIALOG_TITLE_MAP } from './LanguageFiltersBulkActionDialog.constants';
import { LanguageFiltersBulkActionDialogProps } from './LanguageFiltersBulkActionDialog.types';


const LanguageFiltersBulkActionDialog = ({
  bulkActionType,
  isOpen = false,
  onSave,
  handleCloseDialog,
  selectedLanguageFilters,
}: LanguageFiltersBulkActionDialogProps) => {
  const { handleToastSuccess, handleToastError } = useToast();

  const [selectedType, setSelectedType] = useState<string>();


  const { handleBulkDeleteLanguageFilters, isLoading: isBulkDeleteLanguageFiltersLoading = false } =
  useBulkDeleteLanguageFilters({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) {
        return;
      }

      onSave();

      handleToastSuccess({
        message: 'Language filters successfully deleted',
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleBulkUpsertLanguageFilter: handleBulkUpdateLanguageFilterType, isLoading: isBulkUpdateLanguageFilterTypeLoading = false } = useBulkUpsertLanguageFilter({
    onCompleted: () => {
      handleToastSuccess({
        message: `Language filters successfully updated`,
      });
      onSave();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const updateType = () => {
    if (!selectedType) return;
    handleBulkUpdateLanguageFilterType({
      items: selectedLanguageFilters?.map((item) => {
        return {
          id: item.id,
          content: item.content,
          isEmbargoingContent: item.isEmbargoingContent,
          isNegative: item.isNegative,
          projectId: item.projectId,
          type: selectedType as DBLanguageFilterType
        }
      }) ?? []
    });
  };



  const deleteUsers = () => {

    handleBulkDeleteLanguageFilters({
      ids: selectedLanguageFilters?.map((item) => Number(item.id)) ?? [],
    });
  };

  const saveMap = {
    updateType: updateType,
    delete: deleteUsers
  };


  const handleSave = () => {
    const bulkHandler = saveMap[bulkActionType];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    bulkHandler();
  }


  const handleUpdateSelectedType = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setSelectedType(value);
  };

  const isLoading = isBulkUpdateLanguageFilterTypeLoading || isBulkDeleteLanguageFiltersLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <Dialog
        confirmText={LANGUAGE_FILTER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]}
        isOpen={isOpen}
        title={LANGUAGE_FILTER_BULK_ACTION_DIALOG_TITLE_MAP[bulkActionType]}
        onCancel={handleCloseDialog}
        onClose={handleCloseDialog}
        onConfirm={handleSave}
      >
        <p>{LANGUAGE_FILTER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]} {selectedLanguageFilters?.length ?? 0} {pluralize('language filter', selectedLanguageFilters?.length ?? 0)}</p>
        {bulkActionType === 'updateType' && (
          <Spacing size="x-large">
            <Select
              id="type"
              labelText="Type"
              name="type"
              options={[
                {
                  id: 'STRICT',
                  name: 'Strict',
                  value: 'STRICT',
                },
                {
                  id: 'MULTIPLE_WAYS',
                  name: 'Multiple ways',
                  value: 'MULTIPLE_WAYS',
                },
                {
                  id: 'TRICKED',
                  name: 'Tricked',
                  value: 'TRICKED',
                },
              ]}
              value={selectedType}
              isRequired
              onChange={handleUpdateSelectedType}
            />
          </Spacing>
        )}
      </Dialog>
    </>
  );
};

export { LanguageFiltersBulkActionDialog };
