import { useContext, useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import { CachingEntitiesContext } from '../../../../context';
import { DashboardListTemplatePage } from '../../../Shared';

const SubscriptionsDashboardPage = () => {
  const { query } = useRouter();
  const { projectId: queryProjectId, organisationId: queryOrganisationId } = query;

  const { project } = useContext(CachingEntitiesContext);

  const [projectId, setProjectId] = useState<string>('');
  const [organisationId, setOrganisationId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dashboardUrl, setDashboardUrl] = useState<string>('');

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setOrganisationId(queryOrganisationId as string);
  }, [queryOrganisationId]);

  useEffect(() => {
    if (!(organisationId && projectId)) return;
    setDashboardUrl(`/dashboard/${String(organisationId)}/${String(projectId)}`);
  }, [organisationId, projectId]);

  return (
    <DashboardListTemplatePage
      activePage="subscriptions"
      additionalClassNames="c-subscriptions-dashboard__page-layout"
      breadcrumbItems={[
        {
          key: '0',
          content: <a href={dashboardUrl}>Dashboard</a>,
        },
        {
          key: '1',
          content: 'Subscriptions',
        },
      ]}
      description={`Subscriptions dashboard for ${projectName} project`}
      informationBoxMessage={<>Review <strong>{projectName}</strong> orders and manage discount codes</>}
      isLoading={isLoading}
      logoUrl={project?.logo?.presignedUrl}
      pageId="subscriptions"
      pageTitle={`${projectName ? projectName + ' subscriptions' : 'Subscriptions'} dashboard`}
      projectName={projectName}
    />
  );
};

export { SubscriptionsDashboardPage };
