import { gql } from '@apollo/client';

const CREATE_BULK_GLOSSARY_ITEMS_MUTATION = gql`
  mutation CreateBulkGlossaryItems($request: [TextToolTipInputType]!, $projectId: String!) {
    textTooltip {
      bulkAdd(request: $request, projectId: $projectId)
    }
  }
`;

export { CREATE_BULK_GLOSSARY_ITEMS_MUTATION };
