import { gql } from '@apollo/client';

const EXPORT_LOGIN_TRIALS = gql`
  query ($dateFrom: DateTime!, $dateTo: DateTime!, $ianaTimeZone: String!, $projectId: String!, $fileType: FileType!) {
    customQueries {
      ourfutures {
        trialLogins(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
          s3Key
          contentType
          presignedUrl
        }
      }
    }
  }
`;

export { EXPORT_LOGIN_TRIALS };
