import { SidebarContainerProps } from './SidebarContainer.interfaces';

const SidebarContainer = ({ children, hasPaddingEnds = true }: SidebarContainerProps) => {
  return (
    <div className={`c-sidebar-container ${hasPaddingEnds ? 'c-sidebar-container--padding-ends': ''}`}>
      {children}
    </div>
  );
};

export { SidebarContainer };
