

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_GROUP_CODES } from './useGetGroupCodes.graphql';
import {
  IGetGroupCodesQueryGraphQLResponse,
  IGetGroupCodesQueryVariables,
  IHandleGetGroupCodesParams,
  IUseGetGroupCodes,
  IUseGetGroupCodesOptions,
} from './useGetGroupCodes.interfaces';

const useGetGroupCodes = (options?: IUseGetGroupCodesOptions): IUseGetGroupCodes => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetGroupCodesOptions);

  const [executeGetGroupCodes, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGroupCodesQueryGraphQLResponse,
    IGetGroupCodesQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ group: { searchGroups } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          groups: searchGroups,
        });
      },
      onError,
    },
    query: query ?? GET_GROUP_CODES,
    token,
  });


  const handleGetGroupCodes = async ({
    projectId,
  }: IHandleGetGroupCodesParams) => {
    await executeGetGroupCodes({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetGroupCodes,
    isLoading,
  };
};

export { useGetGroupCodes };
