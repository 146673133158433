/* eslint-disable @typescript-eslint/indent */
import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { UPDATE_ANNOUNCE_QUERY } from './useUpdateAnnounce.graphql';
import {
  IUpdateAnnounceQueryGraphQLResponse,
  IUpdateAnnounceQueryVariables,
  IUseUpdateAnnounce,
  IUseUpdateAnnounceOptions,
} from './useUpdateAnnounce.interfaces';

const useUpdateAnnounce = (options?: IUseUpdateAnnounceOptions): IUseUpdateAnnounce => {
  const { onCompleted, onError, token } = options ?? ({} as IUseUpdateAnnounceOptions);

  const [executeUpdateAnnounce, { loading: isLoading }] = useHowlerMutation<
    IUpdateAnnounceQueryGraphQLResponse,
    IUpdateAnnounceQueryVariables
  >({
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }
        const {
          announce: { update },
        } = data;

        onCompleted({ announce: update });
      },
      onError,
    },
    mutation: UPDATE_ANNOUNCE_QUERY,
    token,
  });

  const handleUpdateAnnounce = async (params: IUpdateAnnounceQueryVariables) => {
    await executeUpdateAnnounce({
      variables: params,
    });
  };

  return {
    handleUpdateAnnounce,
    isLoading,
  };
};

export { useUpdateAnnounce };
