import React, { ChangeEvent, useEffect, useState } from 'react';

import { Input, Select, SidebarButtons, Spacing } from '@netfront/ui-library';
import { getFormattedDirectory, IEditableDirectory } from 'components';
import { useToast , useGetCountries, IGetCountriesOnCompletedResponse } from 'hooks';


import { DirectoryAddressTabProps } from './DirectoryAddressTab.interfaces';




const DirectoryAddressTab = ({ onChange, selectedDirectory, onCancel, onDelete, onSave }: DirectoryAddressTabProps) => {
  const { handleToastError } = useToast();
  const [editableDirectory, setEditableDirectory] = useState<IEditableDirectory>({} as IEditableDirectory);
  const [countryDropdownListOptions, setCountryDropdownListOptions] = useState<{id: number, name: string; value: string}[]>([]);

  const {
    city = '',
    country = '',
    latitude = '',
    longitude = '',
    line1 = '',
    postcode = '',
    state = '',
    suburb = '',
  } = editableDirectory;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { handleGetCountries } = useGetCountries({
    onCompleted: ({ enumValues }: IGetCountriesOnCompletedResponse) => {
      setCountryDropdownListOptions(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
        enumValues.map((value, id) => {
          const { name } = value;
          return {
            id,
            name,
            value: name,
          };
        }),
      );
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const onInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const {
      target: { value, name },
    } = event;
    onChange?.(name, value);
    setEditableDirectory({
      ...editableDirectory,
      [name]: value,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    handleGetCountries();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedDirectory) return;
    setEditableDirectory(getFormattedDirectory(selectedDirectory));
  }, [selectedDirectory]);

  return (
    <>
      <fieldset>
        <legend className="h-hide">Address</legend>

        <Spacing>
          <Input  id="line1" labelText="Address line 1" name="line1" tooltipText="The first address line" type="text" value={line1} onChange={onInputChange} />
        </Spacing>
        <Spacing>
          <Input id="suburb" labelText="Suburb" name="suburb" tooltipText="The suburb / town eg: Potts Point" type="text" value={suburb} onChange={onInputChange} />
        </Spacing>
        <Spacing>
          <Input
            id="postcode"
            labelText="Postcode"
            name="postcode"
            tooltipText="The postcode of your area eg: 2050"
            type="text"
            value={postcode}
            onChange={onInputChange}
          />
        </Spacing>
        <Spacing>
          <Input id="city" labelText="City" name="city" tooltipText="The city eg: Sydney" type="text" value={city} onChange={onInputChange} />
        </Spacing>
        <Spacing>
          <Input id="state" labelText="State" name="state" tooltipText="The state or territory eg: NSW" type="text" value={state} onChange={onInputChange} />
        </Spacing>
        <Spacing>
          <Select
            id="country"
            labelText="Country"
            name="country"
            options={countryDropdownListOptions}
            tooltipText="The country eg: Australia"
            value={country}
            onChange={onInputChange}
          />
        </Spacing>
        <Spacing>

          <Input id="latitude" labelText="Latitude" name="latitude" tooltipText="The the distance north or south of the equator measured in degrees" type="number" value={latitude} onChange={onInputChange} />
        </Spacing>
        <Spacing>
          <Input id="longitude" labelText="Longitude" name="longitude" tooltipText="The the distance measured in degrees east or west of an imaginary line that runs from the north pole to the south pole and passes through Greenwich, England." type="number" value={longitude} onChange={onInputChange} />
        </Spacing>
      </fieldset>
      <SidebarButtons
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={onSave}
      />
    </>
  );
};

export { DirectoryAddressTab };

