import React, { useContext, useEffect, useState } from 'react';

import { IContentSnippet } from '@netfront/ekardo-content-library';
import { AssetType, Dialog, SideBar, Spinner } from '@netfront/ui-library';
import { ContentBuilderContext } from 'context';
import { useGetSnippetDetails, useToast } from 'hooks';

import { SnippetTypeSelectorSidebarView, UpsertCodeSnippetSidebarView, UpsertQuestionSnippetSidebarView, UpsertSummarySnippetSidebarView, UpsertTextSnippetSidebarView, UpsertFormSnippetSidebarView, UpsertAssetSnippetSidebarView, UpsertEmbedSnippetSidebarView, UpsertQuoteSnippetSidebarView, UpsertButtonSnippetSidebarView } from '../Snippets';


import { SnippetTypeConst } from '../../../../constants';



const SnippetSidebarView = () => {
  const { isSnippetSidebarOpen, closeSnippetSidebar, snippetSidebarDetails, updateSnippetSidebarDetails, handleDeleteEntity, handleCreateEntity, handleUpdateSnippet: handleUpdateEntity } = useContext(ContentBuilderContext);
  const { handleToastError } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [snippet, setSnippet] = useState<IContentSnippet>()

  const { type: snippetType, containerId, snippetId, sort, questionType } = snippetSidebarDetails ?? {};

  const { handleGetSnippetDetails, isLoading: isGetSnippetDetailsLoading = false } = useGetSnippetDetails({
    fetchPolicy: 'no-cache',
    onCompleted: ({ snippet: returnedSnippet }) => {

      const { __typename: type, sort: returnedSort } = returnedSnippet;

      setSnippet(returnedSnippet);
      updateSnippetSidebarDetails({
        containerId: Number(containerId),
        snippetId,
        sort: returnedSort,
        type,
      });

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const SnippetTypeSidebarMap = {
    [SnippetTypeConst.SUMMARY]: UpsertSummarySnippetSidebarView,
    [SnippetTypeConst.CODE]: UpsertCodeSnippetSidebarView,
    [SnippetTypeConst.TEXT]: UpsertTextSnippetSidebarView,
    [SnippetTypeConst.EMBED]: UpsertEmbedSnippetSidebarView,
    [SnippetTypeConst.QUESTION]: UpsertQuestionSnippetSidebarView,
    [SnippetTypeConst.AUDIO]: UpsertAssetSnippetSidebarView,
    [SnippetTypeConst.VIDEO]: UpsertAssetSnippetSidebarView,
    [SnippetTypeConst.DOCUMENT]: UpsertAssetSnippetSidebarView,
    [SnippetTypeConst.FORM]: UpsertFormSnippetSidebarView,
    [SnippetTypeConst.IMAGE]: UpsertAssetSnippetSidebarView,
    [SnippetTypeConst.BUTTON]: UpsertButtonSnippetSidebarView,
    [SnippetTypeConst.QUOTE]: UpsertQuoteSnippetSidebarView,
  };

  const assetTypeMap = {
    [SnippetTypeConst.AUDIO]: 'audio',
    [SnippetTypeConst.VIDEO]: 'video',
    [SnippetTypeConst.DOCUMENT]: 'document',
    [SnippetTypeConst.IMAGE]: 'image',
    [SnippetTypeConst.EMBED]: String(snippet?.embedAssetType ?? 'AUDIO').toLowerCase(),
  };

  const assetType = snippetType ? assetTypeMap[snippetType]: undefined;

  const SnippetSidebar = snippetType ? SnippetTypeSidebarMap[snippetType]: SnippetTypeSelectorSidebarView;

  const handleDelete = () => {
    if (!snippet) return;
    setIsDeleteDialogOpen(false);
    handleDeleteEntity({
      type: 'targetSnippet',
      id: snippet.id
    });
  };

  const handleCreateSnippet = (isQuickAdd = false) => {
    if (!isQuickAdd) closeSnippetSidebar();
    handleCreateEntity();
  };

  const handleUpdateSnippet = (returnedSnippet: IContentSnippet) => {
    closeSnippetSidebar();
    handleUpdateEntity(returnedSnippet);
  };

  const handleCloseSidebar = () => {
    setSnippet(undefined);
    closeSnippetSidebar();
  };

  useEffect(() => {
    if (!snippetId) {
      setSnippet(undefined);
    } else {
      handleGetSnippetDetails({
        contentSnippetId: snippetId,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippetId]);

  return (
    <SideBar
      isSideBarOpen={isSnippetSidebarOpen}
      isCloseButtonVisible
      onClose={handleCloseSidebar}
    >
      <>
        <Spinner isLoading={isGetSnippetDetailsLoading} />
        <SnippetSidebar
          assetType={assetType as AssetType}
          containerId={Number(containerId)}
          isLoading={isGetSnippetDetailsLoading}
          questionType={questionType}
          snippet={snippet}
          sort={Number(sort)}
          onClose={handleCloseSidebar}
          onCreate={handleCreateSnippet}
          onDelete={snippet ? () => setIsDeleteDialogOpen(true): undefined }
          onUpdate={handleUpdateSnippet}
        />
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete snippet?`}
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
        />
      </>
    </SideBar>
  );
};


export { SnippetSidebarView };
