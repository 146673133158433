import { gql } from '@apollo/client';

export const FRAGMENT_MAP = gql`
  fragment map on ContentSnippetMapType {
    longitude
    latitude
    zoom
    draggable
    grayscale
  }
`;
