import { gql } from '@apollo/client';


const GET_HORIZONTAL_ALIGNMENT_OPTIONS = gql`
  query getHorizontalAlignmentOptions {
    __type(name: "EHorizontalAlignment") {
      enumValues {
        name
      }
    }
  }
`;

export { GET_HORIZONTAL_ALIGNMENT_OPTIONS };
