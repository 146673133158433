import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CONTENT_GROUP } from './useDeleteContentGroup.graphql';
import {
  IDeleteContentGroupMutationGraphQLResponse,
  IDeleteContentGroupMutationVariables,
  IHandleDeleteContentGroupParams,
  IUseDeleteContentGroupOptions,
  IUseDeleteContentGroup,
} from './useDeleteContentGroup.interfaces';

const useDeleteContentGroup = (options?: IUseDeleteContentGroupOptions): IUseDeleteContentGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteContentGroupOptions);

  const [executeContentGroup, { loading: isLoading }] = useEkardoMutation<
  IDeleteContentGroupMutationGraphQLResponse,
  IDeleteContentGroupMutationVariables
  >({
    mutation: mutation ?? DELETE_CONTENT_GROUP,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: { updateContentGroupStatus: contentGroup },
        } = data;

        void onCompleted({
          contentGroup,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteContentGroup = ({ contentGroupId }: IHandleDeleteContentGroupParams) => {
    void executeContentGroup({
      variables: {
        contentGroupId,
        status: 'DELETED',
      },
    });
  };

  return {
    handleDeleteContentGroup,
    isLoading,
  };
};

export { useDeleteContentGroup };
