import { gql } from '@apollo/client';

const GET_TOPICS_PER_PROJECT_QUERY = gql`
  query GetTopicsPerProject($projectId: String! $status: ETopicStatus) {
    topic {
      getTopicsPerProject(projectId: $projectId, status: $status) {
        id
        title
        description
        status
      }
    }
  }
`;

export { GET_TOPICS_PER_PROJECT_QUERY };
