import React, { ReactNode, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { DBAssetTypeType, IDBAsset, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, SidebarButtons, FormFieldProps, Select, Spacing, Spinner, useControlledForm, IOption, SearchFilter, IBreadcrumbItem } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, SidebarContainer } from 'components';
import { useSearchPaginatedAssets, useToast } from 'hooks';
import capitalize from 'lodash.capitalize';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { ChooseAssetFromLibraryProps } from './ChooseAssetFromLibrary.interfaces';


const Wrapper = ({additionalBreadcrumbs = [], children, hasContainer = false} : { additionalBreadcrumbs?: IBreadcrumbItem[]; children: ReactNode; hasContainer?: boolean }) => {
  if (hasContainer) return (
    <ContentBuilderTabWrapper additionalBreadcrumbs={additionalBreadcrumbs} hasSnippetTypeBreadcrumb={false}>
      {children}
    </ContentBuilderTabWrapper>
  );

  return <SidebarContainer>{children}</SidebarContainer>;
}

const ChooseAssetFromLibrary = ({ onCancel, onSave, projectId, assetType, additionalBreadcrumbs = [], hasContainer = false }: ChooseAssetFromLibraryProps) => {
  const { handleToastError } = useToast();
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({
    assetId: '',
    search: '',
  });
  const [assetOptions, setAssetOptions] = useState<IOption[]>([]);
  const [allAssets, setAllAssets] = useState<IDBAsset[]>([]);

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, getValues, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        assetId: yup.string().required('Asset'),
      }),
    ),
  });


  const {
    handleSearchPaginatedAssets,
    isLoading = false,
  } = useSearchPaginatedAssets({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ assetConnection: { edges } }) => {

      setDefaultValues({
        assetId: '',
        search: '',
      });
      const assets = edges.map(({ node }) => node);
      setAllAssets(assets);

      setAssetOptions(assets.map(({ assetId, title, fileName}) => ({
        id: assetId,
        value: assetId,
        name: title ?? fileName,
      })));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleSave = (item: FormFieldProps) => {
    const { assetId } = item;
    const selectedAsset = allAssets.find(({assetId: id }) => id === assetId);
    if (selectedAsset) onSave({ asset: selectedAsset });
    reset();
  };

  const handleSearch = () => {
    const { search } = getValues();
    if (search === '') {
      setAssetOptions(allAssets.map(({ assetId, title, fileName}) => ({
        id: assetId,
        value: assetId,
        name: title ?? fileName,
      })));
      return;
    }

    const filteredOptions = allAssets.filter(({ title, fileName }) => fileName.toLowerCase().includes(String(search).toLowerCase()) || title?.toLowerCase().includes(String(search).toLowerCase()));

    setAssetOptions(filteredOptions.map(({ assetId, title, fileName}) => ({
      id: assetId,
      value: assetId,
      name: title ?? fileName,
    })));
  };


  useEffect(() => {
    if (!projectId) return;
    handleSearchPaginatedAssets({
      imageSize: 'SMALL',
      projectId: String(projectId),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetType, projectId]);


  return (
    <Wrapper additionalBreadcrumbs={additionalBreadcrumbs} hasContainer={hasContainer}>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <Spinner isLoading={isLoading} />
        <Spacing size="small">
          <Controller
            control={control}
            name="search"
            render={({ field }) => (
              <SearchFilter
                id="id_search_library"
                isDisabled={false}
                labelText="Library filter"
                placeholder="Search your library"
                type="text"
                isFullWidth
                isLabelHidden
                onClear={() => {
                  setValue('search', '')
                  handleSearch();
                }}
                onSearch={handleSearch}
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control}
            name="assetId"
            render={({ field, fieldState }) => (
              <Select
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_event_type"
                labelText={`Select ${capitalize(assetType.toLowerCase())} asset`}
                options={assetOptions}
                isLabelHidden
                {...field}
              />
            )}
          />
        </Spacing>
        <SidebarButtons
          onCancel={onCancel}
          onSaveButtonType="submit"
        />


      </ControlledForm>
    </Wrapper>
  );
};

export { ChooseAssetFromLibrary };
