import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IGlossaryTableData } from './GlossaryPage.interfaces';


const GLOSSARY_TABLE_COLUMNS: ReadonlyArray<Column<IGlossaryTableData>> = [

  {
    accessor: 'title',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Title</div>,
    width: '25%',
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value.toString()}</div>,
    Header: () => <div className="text-left w-full">Description</div>,
    width: '70%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { item, onClick } }) => <SettingsButton supportiveText="Update group" onClick={() => onClick(item)} />,
    Header: () => <span className="h-hide-visually">Settings</span>,
    width: '5%',
  },
];

export { GLOSSARY_TABLE_COLUMNS };
