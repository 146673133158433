import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPSERT_DIRECTORY_ADDRESS } from './useUpsertDirectoryAddress.graphql';
import {
  IUpsertDirectoryAddressMutationGraphQLResponse,
  IUpsertDirectoryAddressMutationVariables,
  IHandleUpsertDirectoryAddressParams,
  IUseUpsertDirectoryAddressOptions,
  IUseUpsertDirectoryAddress,
} from './useUpsertDirectoryAddress.interfaces';

const useUpsertDirectoryAddress = (options?: IUseUpsertDirectoryAddressOptions): IUseUpsertDirectoryAddress => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpsertDirectoryAddressOptions);

  const [executeUpsertDirectoryAddress, { loading: isLoading }] = useEkardoMutation<
  IUpsertDirectoryAddressMutationGraphQLResponse,
  IUpsertDirectoryAddressMutationVariables
  >({
    mutation: mutation ?? UPSERT_DIRECTORY_ADDRESS,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          directory: {
            createOrUpdateAddress: directory,
          },
        } = data;

        void onCompleted({directory});
      },
      onError,
    },
    token,
  });

  const handleUpsertDirectoryAddress = async ({
    address,
    directoryId,
  }: IHandleUpsertDirectoryAddressParams) => {
    await executeUpsertDirectoryAddress({
      variables: {
        address,
        directoryId,
      },
    });
  };

  return {
    handleUpsertDirectoryAddress,
    isLoading,
  };
};

export { useUpsertDirectoryAddress };
