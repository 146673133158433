import { gql } from '@apollo/client';


export const DELETE_DIRECTORY_MUTATION = gql`
  mutation removeDirectory($directoryId: Int!) {
    directory {
      removeDirectory(directoryId: $directoryId)
    }
  }
`;
