/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_TEACHER_REGISTRATION_TRIAL_QUERY } from './useGetTeacherRegistrationTrial.graphql';
import {
  IGetTeacherRegistrationTrialQueryGraphQLResponse,
  IGetTeacherRegistrationTrialQueryVariables,
  IUseGetTeacherRegistrationTrial,
  IUseGetTeacherRegistrationTrialOptions,
} from './useGetTeacherRegistrationTrial.interfaces';

const useGetTeacherRegistrationTrial = (options?: IUseGetTeacherRegistrationTrialOptions): IUseGetTeacherRegistrationTrial => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetTeacherRegistrationTrialOptions);

  const [executeGetTeacherRegistrationTrial, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetTeacherRegistrationTrialQueryGraphQLResponse,
    IGetTeacherRegistrationTrialQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ insights: { userRegistration } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userRegistration,
        });
      },
      onError,
    },
    query: GET_TEACHER_REGISTRATION_TRIAL_QUERY,
    token,
  });

  const handleGetTeacherRegistrationTrial = async ({ projectId, end, start }: IGetTeacherRegistrationTrialQueryVariables) => {
    await executeGetTeacherRegistrationTrial({
      variables: {
        projectId,
        end,
        start,
      },
    });
  };

  return {
    handleGetTeacherRegistrationTrial,
    isLoading,
  };
};

export { useGetTeacherRegistrationTrial };
