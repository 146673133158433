import { useContext, useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { useProtectedRoute } from '@netfront/gelada-identity-library';
import { ITab, Select } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { TOPICS_TABLE_COLUMNS } from './TopicsPage.constants';
import { getTopicsTableData } from './TopicsPage.helpers';
import { ITopicsTableData } from './TopicsPage.interfaces';

import { TablePageTemplate, TopicsSidebarGeneralView } from '../../../../components';
import { CachingEntitiesContext, DashboardContext } from '../../../../context';
import { useGetTopicsPerProject, useToast } from '../../../../hooks';
import { IDBTopic } from '../../../../interfaces';

const TopicsPage = () => {
  const { getAccessTokenCookie } = useCookie();
  const { isAuthenticated } = useProtectedRoute();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);

  const [allTopics, setAllTopics] = useState<IDBTopic[]>([]);
  const [filter, setFilter] = useState<string>();
  const [filteredTopics, setFilteredTopics] = useState<IDBTopic[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedTopic, setSelectedTopic] = useState<IDBTopic>();
  const [topicsTableData, setTopicsTableData] = useState<ITopicsTableData[]>([]);
  const [status, setStatus] = useState<IDBTopic['status'] | undefined>();

  const token = getAccessTokenCookie();

  const { handleGetTopicsPerProject, isLoading: isGetTopicsForConnectedUserLoading = false } = useGetTopicsPerProject({
    onCompleted: ({ topicsPerProjectConnection: topics }) => {
      setAllTopics(topics);
      setFilteredTopics(topics);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId: String(projectId),
  });

  const handleAddNewTopicClick = () => {
    setSelectedTopic(undefined);
    setIsSideBarOpen(true);
  };

  const handleCreatedTopic = (topic: IDBTopic) => {
    setAllTopics((currentState) => [...currentState, topic]);
    setFilteredTopics((currentState) => [...currentState, topic]);

    handleSideBarClose();

    handleToastSuccess({
      message: 'Topic created successfully',
    });
  };

  const handleDeletedTopic = (topicId: IDBTopic['id']) => {
    const filteredTopicsList = allTopics.filter(({ id }) => id !== topicId);

    setFilteredTopics(filteredTopicsList);

    handleSideBarClose();

    handleToastSuccess({
      message: 'Topic deleted successfully',
    });
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);
  };

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
  };

  const handleUpdatedTopic = (topic: IDBTopic) => {
    setAllTopics((currentState): IDBTopic[] => {
      return currentState.map((currentTopic): IDBTopic => {
        return currentTopic.id === topic.id ? topic : currentTopic;
      });
    });

    setFilteredTopics((currentState): IDBTopic[] => {
      return currentState.map((currentTopic): IDBTopic => {
        return currentTopic.id === topic.id ? topic : currentTopic;
      });
    });

    handleSideBarClose();
    handleToastSuccess({
      message: 'Topic updated successfully',
    });
  };

  useEffect(() => {
    if (!(isAuthenticated && projectId)) {
      return;
    }

    void handleGetTopicsPerProject({
      projectId: String(projectId),
      status: status ? status : null,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId, status]);

  useEffect(() => {
    setFilteredTopics(allTopics.filter(({ title }) => title.toLowerCase().includes(String(filter).toLowerCase())));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    setTopicsTableData(
      getTopicsTableData({
        topics: filteredTopics,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedTopic(filteredTopics.find(({ id: topicId }) => id === topicId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTopics]);

  const tabs: ITab[] = [
    {
      iconId: 'id_general_tab_icon',
      id: 'id_general_tab',
      label: 'General',
      view: () => (
        <TopicsSidebarGeneralView
          projectId={String(projectId)}
          selectedTopic={selectedTopic}
          onClose={handleSideBarClose}
          onCreated={handleCreatedTopic}
          onDeleted={handleDeletedTopic}
          onUpdated={handleUpdatedTopic}
        />
      ),
    },
  ];

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  return (
    <TablePageTemplate<ITopicsTableData>
      activePage="social"
      activeSubPage="topics"
      additionalBreadcrumbItems={[
        {
          key: '1',
          content: <a href={`${String(dashboardLink)}/social`}>Social</a>,
        },
        {
          key: '2',
          content: 'Topics',
        },
      ]}
      childrenMiddle={
        <Select
          hasPadding={false}
          id="id_topic_status_filter"
          labelDefaultText="All statuses"
          labelText="Status"
          name="status"
          options={[
            {
              id: 'ACTIVE',
              name: 'Active',
              value: 'ACTIVE'
            },
            {
              id: 'UNPUBLISHED',
              name: 'Unpublished',
              value: 'UNPUBLISHED'
            },
            {
              id: 'DELETED',
              name: 'Deleted',
              value: 'DELETED'
            },
          ]}
          value={status}
          isLabelHidden
          onChange={({ target: { value}}) => setStatus(value as IDBTopic['status'])}
        />
      }
      columns={TOPICS_TABLE_COLUMNS}
      data={topicsTableData}
      defaultActiveTabId="id_general_tab"
      description={`Topics social content for ${projectName} project`}
      handleAddNewClick={handleAddNewTopicClick}
      handleSearch={handleFilterSearch}
      informationBoxMessage={`Manage ${projectName} social topics`}
      isLoading={isGetTopicsForConnectedUserLoading}
      isSideBarOpen={isSideBarOpen}
      pageTitle={projectName}
      tableType="topics"
      tabs={tabs}
      title={`${projectName} Topics dashboard`}
      toggleIsSideBarOpen={handleSideBarClose}
    />
  );
};

export { TopicsPage };
