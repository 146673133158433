import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_DROPDOWN_LIST_CONFIGURATION } from './useCreateDropDownListQuestionConfig.graphql';
import {
  ICreateDropDownListQuestionConfigMutationGraphQLResponse,
  ICreateDropDownListQuestionConfigMutationVariables,
  IHandleCreateDropDownListQuestionConfigParams,
  IUseCreateDropDownListQuestionConfigOptions,
  IUseCreateDropDownListQuestionConfig,
} from './useCreateDropDownListQuestionConfig.interfaces';

const useCreateDropDownListQuestionConfig = (options?: IUseCreateDropDownListQuestionConfigOptions): IUseCreateDropDownListQuestionConfig => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateDropDownListQuestionConfigOptions);

  const [executeCreateDropDownListQuestionConfig, { loading: isLoading }] = useEkardoMutation<ICreateDropDownListQuestionConfigMutationGraphQLResponse, ICreateDropDownListQuestionConfigMutationVariables>({
    mutation: mutation ?? CREATE_DROPDOWN_LIST_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { createQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateDropDownListQuestionConfig = async ({
    canModify,
    description,
    projectId,
    title,
  }: IHandleCreateDropDownListQuestionConfigParams) => {
    await executeCreateDropDownListQuestionConfig({
      variables: {
        canModify,
        description,
        projectId,
        title,
      }
    });
  };

  return {
    handleCreateDropDownListQuestionConfig,
    isLoading,
  };
};

export { useCreateDropDownListQuestionConfig };
