import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, DatePicker, FlexContainer, IDateRange } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetPagesLoadedReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { PAGES_LOADED_COLUMNS } from './PagesLoadedPage.constants';
import { getPagesLoadedTableData } from './PagesLoadedPage.helpers';
import { PagesLoadedCsvRowData, IPagesLoadedItem, IPagesLoadedTableData } from './PagesLoadedPage.interfaces';

const PagesLoadedPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError, handleToastCustomError } = useToast();
  const { query: { projectId: queryProjectId } } = useRouter();

  const [projectName, setProjectName] = useState<string>();
  const [pagesLoadedItems, setPagesLoadedItems] = useState<IPagesLoadedItem[]>();
  const [allPagesLoadedItems, setAllPagesLoadedItems] = useState<IPagesLoadedItem[]>([]);
  const [pagesLoadedTableData, setPagesLoadedTableData] = useState<IPagesLoadedTableData[]>([]);
  const [dateRange, setDateRange] = useState<IDateRange>();
  const [csvUrl, setCsvUrl] = useState<string>();
  const [projectId, setProjectId] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetPagesLoadedReport,
    isLoading: isGetPagesLoadedLoading = false,
  } = useGetPagesLoadedReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<PagesLoadedCsvRowData>(String(res.data), {
            header: true,
          skipEmptyLines: true,
          });


          const formattedItems = data.map((row: PagesLoadedCsvRowData) => ({
            id: Number(row['User id']),
            groupName: String(row['Group name']),
            title: String(row['Title']),
            url: String(row['Url']),
            date: String(row['Date']),
            userType: String(row['User type']),
          }));
          
          setTotalCount(data.length);
          setAllPagesLoadedItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setPagesLoadedItems(paginatedItems);
          setLastItemIndex(pageSize);
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!(dateRange && projectId)) {
      return;
    }

    if (!dateRange.end || !dateRange.start) return;

    void handleGetPagesLoadedReport({
      dateFrom: dateRange.start,
      dateTo: dateRange.end,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fileType: 'CSV',
      projectId,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setPagesLoadedItems(allPagesLoadedItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setPagesLoadedItems(allPagesLoadedItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!pagesLoadedItems) {
      return;
    }

    setPagesLoadedTableData(
      getPagesLoadedTableData({
        items: pagesLoadedItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesLoadedItems]);


  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    if (!queryProjectId) return;
    setProjectId(queryProjectId as string);
  }, [queryProjectId])

  const isLoading = isGetPagesLoadedLoading;

  return (
    <TablePageTemplate<IPagesLoadedTableData>
      activePath={['reporting', 'reporting-content', 'export-pages']}
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Reporting',
      }, {
        key: '2',
        content: 'Pages',
      }]}
      childrenEnd={
        <FlexContainer gap="2x-small">
          <Button
            isDisabled={!dateRange || !dateRange.start || !dateRange.end}
            size="xs"
            text="Generate"
            onClick={generateReport}
          />
          {csvUrl && (
            <Button
              additionalClassNames="c-report-download-button"
              iconId="id_csv_icon"
              linkButton={{
                linkComponent: 'a',
                id: `id_csv_download`,
                url: csvUrl,
                target: '_blank'
              }}
              size="xs"
              text="Download"
              isIconOnly
            />
          )}
        </FlexContainer>
      }
      childrenMiddle={(
        <DatePicker
          hasPadding={false}
          id="id_date_selector"
          labelText="Select date range"
          selectedDate={dateRange}
          isLabelHidden
          isRange
          onDateRangeChangeHandler={(e) => {
            if (!e) return;
            setDateRange(e);
          }}
        />
      )}
      columns={PAGES_LOADED_COLUMNS}
      data={pagesLoadedTableData}
      description={`Pages loaded reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports pages loaded based on the selected filters'}
      isLoading={isLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      searchPlaceholder="Search"
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="reports"
      title={`${String(projectName)} reporting`}
      totalItems={totalCount}

    />
  );
};

export { PagesLoadedPage };
