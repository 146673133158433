import { forwardRef, useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { InputFieldWrapper } from '@netfront/ui-library';
import cx from 'classnames';

import { iconConfig } from './LayoutSelector.contants';
import { getLayoutOptions } from './LayoutSelector.helpers';
import { LayoutRadioItemProps, LayoutRadioProps, LayoutSelectorProps } from './LayoutSelector.interfaces';

const LayoutRadio = forwardRef<HTMLInputElement, LayoutRadioProps>(
  (
    {
      name,
      isSelected,
      label,
      value,
      onChange
    },
    ref, // Forwarded ref
  ) => {
    return (
      <label className={cx('c-layout-selector', { 'is-selected': isSelected })}>
        <input
          ref={ref} // Forward ref to input element
          checked={isSelected}
          className="c-layout-selector__input"
          name={name}
          type="radio"
          value={value}
          onChange={onChange}
        />
        <div className="c-layout-selector__icon-container">
          {iconConfig[Number(value)].map((iconSize, index) => (
            <span key={`${value}-${index}`} className="c-layout-selector__icon" style={{ width: iconSize }} />
          ))}
        </div>
        <span>{label}</span>
      </label>
    );
  }
);

LayoutRadio.displayName = 'LayoutRadio';

const LayoutSelector: React.FC<LayoutSelectorProps> = forwardRef<HTMLInputElement, LayoutSelectorProps>(
  (
    {
      containerNumber,
      onChange,
      initialValue,
      fieldState
    },
    ref,
  ) => {
    const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

    const [selectedValue, setSelectedValue] = useState<number>();
    const [items, setItems] = useState<LayoutRadioItemProps[]>([]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      onChange(event);
      setSelectedValue(Number(newValue));
    };

    useEffect(() => {
      setSelectedValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      setItems(getLayoutOptions(containerNumber));

    }, [containerNumber]);

    return (
      <fieldset className="c-layout-selector-group">
        <InputFieldWrapper
          id="id_layout_selector"
          label="Layout"
          labelFontWeight="light"
          labelType="legend"
          message={{success: '', error: getFormFieldErrorMessage(fieldState)}}
          tooltipPosition="start"
          tooltipText="Select a layout style for your section"
          type="radio"
          isLabelSideBySide
          isRequired

        >
          <div className="c-layout-selector__radios">
            {items.map((item) => (
              <LayoutRadio
                key={item.value}
                ref={ref}
                {...item}
                isSelected={Number(item.value) === selectedValue}
                name="contentLayoutId"
                onChange={handleOnChange}
              />
            ))}
          </div>
        </InputFieldWrapper>
      </fieldset>
    );
  }
);

LayoutSelector.displayName = 'LayoutSelector';


export { LayoutSelector };
