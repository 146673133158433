import { gql } from '@apollo/client';

const GET_BONOBO_PROJECT = gql`
  query GetBonoboProject($projectId: String!) {
    project {
      listFeatures(projectId: $projectId)
    }
  }
`;

export { GET_BONOBO_PROJECT };
