import { useVervetLazyQuery } from '../useVervetLazyQuery';

import { GET_COMPLETED_ORDERS_FOR_PROJECT_QUERY } from './useGetCompletedOrdersForProject.graphql';
import {
  IGetCompletedOrdersForProjectQueryGraphQLResponse,
  IGetCompletedOrdersForProjectQueryVariables,
  IHandleGetCompletedOrdersForProjectParams,
  IUseGetCompletedOrdersForProject,
  IUseGetCompletedOrdersForProjectOptions,
} from './useGetCompletedOrdersForProject.interfaces';

const useGetCompletedOrdersForProject = (options?: IUseGetCompletedOrdersForProjectOptions): IUseGetCompletedOrdersForProject => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetCompletedOrdersForProjectOptions);

  const [executeGetCompletedOrdersForProject, { loading: isLoading }] = useVervetLazyQuery<
    IGetCompletedOrdersForProjectQueryGraphQLResponse,
    IGetCompletedOrdersForProjectQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          orders: { getCompletedOrdersForProject: ordersConnection },
        } = data;

        onCompleted({
          ordersConnection,
        });
      },
      onError,
    },
    query: query ?? GET_COMPLETED_ORDERS_FOR_PROJECT_QUERY,
    token,
  });

  const handleGetCompletedOrdersForProject = ({ projectGuid }: IHandleGetCompletedOrdersForProjectParams) => {
    void executeGetCompletedOrdersForProject({
      variables: {
        projectGuid,
      },
    });
  };

  return {
    handleGetCompletedOrdersForProject,
    isLoading,
  };
};

export { useGetCompletedOrdersForProject };
