import { useEffect, useState } from 'react';

import { IDBUserFlow } from '@netfront/ekardo-content-library';
import { IGetReportableUserFlowsOnCompletedResponse, useGetReportableUserFlows } from 'hooks';

import { UserFlowStepCompletion } from '../UserFlowStepCompletion/UserFlowStepCompletion';

import { UserFlowStepCompletionsProps } from './UserFlowStepCompletion.interfaces';

const UserFlowStepCompletions = ({ projectId, end, start, isFullWidth = false }: UserFlowStepCompletionsProps) => {
  const [userFlows, setUserFlows] = useState<IDBUserFlow[]>([]);

  const onGetReportableUserFlows = (response: IGetReportableUserFlowsOnCompletedResponse) => {
    const { userFlows: responseUserFlows } = response;
    setUserFlows(responseUserFlows);
  };

  const { handleGetReportableUserFlows } = useGetReportableUserFlows({
    onCompleted: onGetReportableUserFlows,
  });

  useEffect(() => {
    void handleGetReportableUserFlows({
      projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      {userFlows.map((userFlow) => {
        return (
          <UserFlowStepCompletion
            key={`userflow-${userFlow.id}`}
            end={end}
            isFullWidth={isFullWidth}
            start={start}
            userFlowId={userFlow.id}
            userFlowTitle={userFlow.title}
          />
        );
      })}
    </>
  );
};

export { UserFlowStepCompletions };
