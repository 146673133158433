import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IOrdersTableData } from './OrdersPage.interfaces';

const ORDERS_TABLE_COLUMNS: ReadonlyArray<Column<IOrdersTableData>> = [
  {
    accessor: 'groupName',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Group name</div>,
  },
  {
    accessor: 'latestOrder',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Latest order</div>,
  },
  {
    accessor: 'licences',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Total licences</div>,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update Order" onClick={() => onClick(id)} />,
  },
];

export { ORDERS_TABLE_COLUMNS };
