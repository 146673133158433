/* eslint-disable @typescript-eslint/indent */
import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { DELETE_SUMMARY_FEEDBACK_ITEM } from './useDeleteSnippetSummaryFeedbackConfigItem.graphql';
import {
  IDeleteSnippetSummaryFeedbackConfigItemQueryGraphQLResponse,
  IDeleteSnippetSummaryFeedbackConfigItemQueryVariables,
  IUseDeleteSnippetSummaryFeedbackConfigItem,
  IUseDeleteSnippetSummaryFeedbackConfigItemOptions,
} from './useDeleteSnippetSummaryFeedbackConfigItem.interfaces';

const useDeleteSnippetSummaryFeedbackConfigItem = (options?: IUseDeleteSnippetSummaryFeedbackConfigItemOptions): IUseDeleteSnippetSummaryFeedbackConfigItem => {
  const { onCompleted, onError, token } = options ?? ({} as IUseDeleteSnippetSummaryFeedbackConfigItemOptions);

  const [executeDeleteSnippetSummaryFeedbackConfigItem, { loading: isLoading }] = useHowlerMutation<IDeleteSnippetSummaryFeedbackConfigItemQueryGraphQLResponse, IDeleteSnippetSummaryFeedbackConfigItemQueryVariables>({
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { deleteSummaryFeedbackConfiguration: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    mutation: DELETE_SUMMARY_FEEDBACK_ITEM,
    token,
  });

  const handleDeleteSnippetSummaryFeedbackConfigItem = async ({contentSnippetSummaryId}: IDeleteSnippetSummaryFeedbackConfigItemQueryVariables) => {
    await executeDeleteSnippetSummaryFeedbackConfigItem({
      variables: {
        contentSnippetSummaryId,
      },
    });
  };

  return {
    handleDeleteSnippetSummaryFeedbackConfigItem,
    isLoading,
  };
};

export { useDeleteSnippetSummaryFeedbackConfigItem };
