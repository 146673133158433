/* eslint-disable @typescript-eslint/indent */
import { useBonoboMutation } from 'hooks/social';

import { UPDATE_USER_STATUS } from './useUpdateBonoboUserStatus.graphql';
import {
  IUpdateBonoboUserStatusQueryGraphQLResponse,
  IUpdateBonoboUserStatusQueryVariables,
  IUseUpdateBonoboUserStatus,
  IUseUpdateBonoboUserStatusOptions,
} from './useUpdateBonoboUserStatus.interfaces';

const useUpdateBonoboUserStatus = (options?: IUseUpdateBonoboUserStatusOptions): IUseUpdateBonoboUserStatus => {
  const { onCompleted, onError, token } = options ?? ({} as IUseUpdateBonoboUserStatusOptions);

  const [executeUpdateBonoboUserStatus, { loading: isLoading }] = useBonoboMutation<
    IUpdateBonoboUserStatusQueryGraphQLResponse,
    IUpdateBonoboUserStatusQueryVariables
  >({
    options: {
      onCompleted: () => {
        if (!onCompleted) {
          return;
        }

        onCompleted({});
      },
      onError,
    },
    mutation: UPDATE_USER_STATUS,
    token,
  });

  const handleUpdateBonoboUserStatus = async ({ status, userId }: IUpdateBonoboUserStatusQueryVariables) => {
    await executeUpdateBonoboUserStatus({
      variables: {
        status,
        userId,
      },
    });
  };

  return {
    handleUpdateBonoboUserStatus,
    isLoading,
  };
};

export { useUpdateBonoboUserStatus };
