import { useBonoboMutation } from '../useBonoboMutation';

import { REMOVE_MEMBER_BY_AUTHORITY_MUTATION } from './useRemoveMemberByAuthority.graphql';
import {
  IRemoveMemberByAuthorityMutationGraphQLResponse,
  IRemoveMemberByAuthorityMutationVariables,
  IHandleRemoveMemberByAuthorityParams,
  IUseRemoveMemberByAuthorityOptions,
  IUseRemoveMemberByAuthority,
} from './useRemoveMemberByAuthority.interfaces';

const useRemoveMemberByAuthority = (options?: IUseRemoveMemberByAuthorityOptions): IUseRemoveMemberByAuthority => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseRemoveMemberByAuthorityOptions);

  const [executeRemoveMemberByAuthority, { loading: isLoading }] = useBonoboMutation<
    IRemoveMemberByAuthorityMutationGraphQLResponse,
    IRemoveMemberByAuthorityMutationVariables
  >({
    mutation: mutation ?? REMOVE_MEMBER_BY_AUTHORITY_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          communityConnection: { removeMemberByAuthority: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleRemoveMemberByAuthority = ({ connectionId, communityId, memberId }: IHandleRemoveMemberByAuthorityParams) => {
    void executeRemoveMemberByAuthority({
      variables: {
        connectionId,
        communityId,
        memberId,
      },
    });
  };

  return {
    handleRemoveMemberByAuthority,
    isLoading,
  };
};

export { useRemoveMemberByAuthority };
