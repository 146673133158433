import { useContext, useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { useProtectedRoute } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { DISCOUNTS_TABLE_COLUMNS } from './DiscountsPage.constants';
import { getDiscountsTableData } from './DiscountsPage.helpers';
import { IDiscountsTableData } from './DiscountsPage.interfaces';

import { DiscountSidebarGeneralView, TablePageTemplate } from '../../../../components';
import { CachingEntitiesContext, DashboardContext } from '../../../../context';
import { useGetAllProjectDiscounts, useToast } from '../../../../hooks';
import { IDBDiscount } from '../../../../interfaces';

const DiscountsPage = () => {
  const { getAccessTokenCookie } = useCookie();
  const { isAuthenticated } = useProtectedRoute();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);

  const [allDiscounts, setAllDiscounts] = useState<IDBDiscount[]>([]);
  const [discountsTableData, setDiscountsTableData] = useState<IDiscountsTableData[]>([]);
  const [filteredDiscounts, setFilteredDiscounts] = useState<IDBDiscount[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedDiscount, setSelectedDiscount] = useState<IDBDiscount>();

  const token = getAccessTokenCookie();

  const { handleGetAllProjectDiscounts, isLoading: isGetAllProjectDiscountsLoading = false } = useGetAllProjectDiscounts({
    onCompleted: ({ discountsConnection: discounts }) => {
      setAllDiscounts(discounts);
      setFilteredDiscounts(discounts);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleAddNewDiscountClick = () => {
    setSelectedDiscount(undefined);
    setIsSideBarOpen(true);
  };

  const handleCreatedDiscount = (discount: IDBDiscount) => {
    setAllDiscounts((currentState) => [...currentState, discount]);
    setFilteredDiscounts((currentState) => [...currentState, discount]);

    handleSideBarClose();

    handleToastSuccess({
      message: 'Discount created successfully',
    });
  };

  const handleDeletedDiscount = (discountId: IDBDiscount['id']) => {
    setAllDiscounts((currentState): IDBDiscount[] => {
      return currentState.map((currentDiscount): IDBDiscount => {
        return currentDiscount.id !== discountId ? currentDiscount : { ...currentDiscount, status: 'INACTIVE' };
      });
    });

    setFilteredDiscounts((currentState): IDBDiscount[] => {
      return currentState.map((currentDiscount): IDBDiscount => {
        return currentDiscount.id !== discountId ? currentDiscount : { ...currentDiscount, status: 'INACTIVE' };
      });
    });

    handleSideBarClose();

    handleToastSuccess({
      message: 'Discount deactivated successfully',
    });
  };

  const handleFilterSearch = (value: string) => {
    setFilteredDiscounts(allDiscounts.filter(({ code }) => code.toLowerCase().includes(value.toLowerCase())));
  };

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
  };

  const handleUpdatedDiscount = (discount: IDBDiscount) => {
    setAllDiscounts((currentState): IDBDiscount[] => {
      return currentState.map((currentDiscount): IDBDiscount => {
        return currentDiscount.id === discount.id ? discount : currentDiscount;
      });
    });

    setFilteredDiscounts((currentState): IDBDiscount[] => {
      return currentState.map((currentDiscount): IDBDiscount => {
        return currentDiscount.id === discount.id ? discount : currentDiscount;
      });
    });

    handleSideBarClose();
    handleToastSuccess({
      message: 'Discount updated successfully',
    });
  };

  useEffect(() => {
    if (!(isAuthenticated && projectId)) {
      return;
    }

    void handleGetAllProjectDiscounts({
      projectGuid: String(projectId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId]);

  useEffect(() => {
    setDiscountsTableData(
      getDiscountsTableData({
        discounts: filteredDiscounts,
        onCopyButtonClick: async (code: IDBDiscount['code']) => {
          await navigator.clipboard.writeText(code);

          handleToastSuccess({
            message: `Code copied to clipboard`,
          });
        },
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedDiscount(filteredDiscounts.find(({ id: discountId }) => id === discountId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDiscounts]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <TablePageTemplate<IDiscountsTableData>
      activePage="subscriptions"
      activeSubPage="orders"
      additionalBreadcrumbItems={[
        {
          key: '1',
          content: <a href={`${String(dashboardLink)}/subscriptions`}>Subscriptions</a>,
        },
        {
          key: '2',
          content: 'Discounts',
        },
      ]}
      columns={DISCOUNTS_TABLE_COLUMNS}
      data={discountsTableData}
      description={`Manage discounts to apply to the ${projectName} orders`}
      handleAddNewClick={handleAddNewDiscountClick}
      handleSearch={handleFilterSearch}
      informationBoxMessage={`Manage ${projectName} discounts`}
      isLoading={isGetAllProjectDiscountsLoading}
      isSideBarOpen={isSideBarOpen}
      pageTitle={projectName}
      tableType="discounts"
      tabs={[
        {
          iconId: 'id_general_tab_icon',
          id: 'id_general_tab',
          label: 'General',
          view: () => (
            <DiscountSidebarGeneralView
              selectedDiscount={selectedDiscount}
              onCreated={handleCreatedDiscount}
              onDeleted={handleDeletedDiscount}
              onUpdated={handleUpdatedDiscount}
            />
          ),
        },
      ]}
      title={`${projectName} discounts page`}
      toggleIsSideBarOpen={handleSideBarClose}
    />
  );
};

export { DiscountsPage };
