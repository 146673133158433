import { IOption } from "@netfront/ui-library";

import { responseTypeToUnderScoredConst, responseTypeToFriendly } from "../../../../../../constants";

const getQuestionResponseTypeOptions = (keysToIgnore: string[] = []) => {
  const responseOptions: IOption[] = Object.keys(responseTypeToUnderScoredConst)
    .filter((key) => !keysToIgnore.includes(key))
    .reduce((acc, key) => {
      acc.push({
        id: key,
        value: responseTypeToUnderScoredConst[key],
        name: responseTypeToFriendly[key]
      });
      return acc;
    }, [] as IOption[]);

    return responseOptions;
};


export { getQuestionResponseTypeOptions };
