import { gql } from '@apollo/client';

const CREATE_TAG_MUTATION = gql`
  mutation CreateTag($projectId: String!, $tagName: String!) {
    tagMutation {
      addTagInProject(projectId: $projectId, tagName: $tagName) {
        id
        name
      }
    }
  }
`;

export { CREATE_TAG_MUTATION };
