import { gql } from '@apollo/client';

const BULK_DELETE_LANGUAGE_FILTERS = gql`
  mutation BulkDeleteLanguageFilters($ids: [Int]!) {
    languageFilter {
      bulkDelete(ids: $ids)
    }
  }
`;

export { BULK_DELETE_LANGUAGE_FILTERS };
