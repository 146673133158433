import { gql } from '@apollo/client';


const GET_CONTENT_GROUPINGS = gql`
  query SearchContentGroups($projectId: String!) {
    contentGroup {
      getGroupings(projectId: $projectId) {
        id
        name
      }
    }
  }
`;
export { GET_CONTENT_GROUPINGS };
