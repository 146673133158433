import { gql } from '@apollo/client';


const UPDATE_CONTENT_ENTITIES_VALIDATION = gql`
  mutation updateContentEntitiesValidation($contentSnippetIds: [Int]!, $behavior: EQuestionBehavior!) {
    contentSnippet {
      updateBehavior(contentSnippetIds: $contentSnippetIds, behavior: $behavior)
    }
  }
`;

export { UPDATE_CONTENT_ENTITIES_VALIDATION };
