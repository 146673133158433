import { StatusIndicatorProps } from './StatusIndicator.interfaces';

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  return (
    <div className="c-status-indicator">
      <div className={`c-status-indicator__color c-status-indicator__color--${status.toLowerCase()}`}></div>
      <div className="c-status-indicator__status">{status}</div>
    </div>
  );
};

export { StatusIndicator };
