import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_USER_PROGRESS_TRACKINGS } from './useGetUserProgressTracking.graphql';
import {
  IGetUserProgressTrackingQueryGraphQLResponse,
  IGetUserProgressTrackingQueryVariables,
  IHandleGetUserProgressTrackingParams,
  IUseGetUserProgressTracking,
  IUseGetUserProgressTrackingOptions,
} from './useGetUserProgressTracking.interfaces';

const useGetUserProgressTracking = (options?: IUseGetUserProgressTrackingOptions): IUseGetUserProgressTracking => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetUserProgressTrackingOptions);

  const [executeGetUserProgressTracking, { loading: isLoading }] = useEkardoLazyQuery<IGetUserProgressTrackingQueryGraphQLResponse, IGetUserProgressTrackingQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          user: {
            getUsers: progressTrackings,
          },
        } = data;

        void onCompleted({ progressTrackings });
      },
      onError,
    },
    query: query ?? GET_USER_PROGRESS_TRACKINGS,
    token,
  });

  const handleGetUserProgressTracking = ({ userIds }: IHandleGetUserProgressTrackingParams) => {
    void executeGetUserProgressTracking({
      variables: {
        userIds,
      },
    });
  };

  return {
    handleGetUserProgressTracking,
    isLoading,
  };
};

export { useGetUserProgressTracking };
