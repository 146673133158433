import { Column } from 'react-table';

import { IAllModulePageCompletedTableData } from './AllModulePagesCompleted.interfaces';


const ALL_MODULE_PAGE_COLUMNS: ReadonlyArray<Column<IAllModulePageCompletedTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
  },
  {
    accessor: 'userType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User type</div>,
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
  },
  {
    accessor: 'pageTitle',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Page title</span>,
  },
  {
    accessor: 'moduleTitle',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Module title</span>,
  },
  {
    accessor: 'startedOn',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Started</span>,
  },
  {
    accessor: 'timeToComplete',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Time to complete</span>,
  },
  {
    accessor: 'completedOn',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Completed</span>,
  },
];

export { ALL_MODULE_PAGE_COLUMNS };
