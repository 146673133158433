
import { IQuestionnairesTableData, IQuestionnairesTableDataParams } from "./QuestionnairesReportPage.interfaces";

const removeHeadersFromArray = (originalArray: string[], valuesToRemove: string[]): string[] => {
  return originalArray.filter(item => !valuesToRemove.includes(item));
};


const getQuestionnairesTableData = ({ items }: IQuestionnairesTableDataParams): IQuestionnairesTableData[] => {
  return items.map(({
    completedOnUtc = '',
    groupName = '',
    id,
    startedOnUtc = '',
    tag = '',
    timeToComplete = '',
    trackingId = '',
    userType = '',
   }) => {
    return {
      completedOnUtc,
      groupName,
      id,
      startedOnUtc,
      tag,
      timeToComplete,
      trackingId,
      userType,
    };
  });
};

export { getQuestionnairesTableData, removeHeadersFromArray };
