import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_ACTION } from './useUpdateAction.graphql';
import {
  IUpdateActionMutationGraphQLResponse,
  IUpdateActionMutationVariables,
  IHandleUpdateActionParams,
  IUseUpdateActionOptions,
  IUseUpdateAction,
} from './useUpdateAction.interfaces';

const useUpdateAction = (options?: IUseUpdateActionOptions): IUseUpdateAction => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateActionOptions);


  const [executeUpdateAction, { loading: isLoading }] = useEkardoMutation<IUpdateActionMutationGraphQLResponse, IUpdateActionMutationVariables>({
    mutation: mutation ?? UPDATE_ACTION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { update: action },
        } = data;

        onCompleted({
          action,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateAction = async (variables: IHandleUpdateActionParams) => {
    await executeUpdateAction({
      variables,
    });
  };

  return {
    handleUpdateAction,
    isLoading,
  };
};

export { useUpdateAction };
