import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ASSET_USAGES_QUERY } from './useGetAssetUsages.graphql';
import {
  IGetAssetUsagesQueryGraphQLResponse,
  IGetAssetUsagesQueryVariables,
  IHandleGetAssetUsagesParams,
  IUseGetAssetUsages,
  IUseGetAssetUsagesOptions,
} from './useGetAssetUsages.interfaces';

const useGetAssetUsages = (options?: IUseGetAssetUsagesOptions): IUseGetAssetUsages => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAssetUsagesOptions);

  const [executeGetAssetUsages, { loading: isLoading }] = useEkardoLazyQuery<
    IGetAssetUsagesQueryGraphQLResponse,
    IGetAssetUsagesQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { usages: assetUsagesConnection },
        } = data;

        void onCompleted({
          assetUsagesConnection,
        });
      },
      onError,
    },
    query: query ?? GET_ASSET_USAGES_QUERY,
    token,
  });

  const handleGetAssetUsages = ({ assetId }: IHandleGetAssetUsagesParams) => {
    void executeGetAssetUsages({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleGetAssetUsages,
    isLoading,
  };
};

export { useGetAssetUsages };
