
import { IPostsTableData, IPostsTableDataParams } from "./PostsReportPage.interfaces";


const getPostsTableData = ({ items }: IPostsTableDataParams): IPostsTableData[] => {
  return items.map(({
    feeling = '',
    groupName = '',
    id,
    likes = '',
    post = '',
    postDate = '',
    postDateUtc = '',
    postId = '',
    userType = '',
   }) => {
    return {
      feeling,
      groupName,
      id,
      likes,
      post,
      postDate,
      postDateUtc,
      postId,
      userType,
    };
  });
};

export { getPostsTableData };
