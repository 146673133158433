import { gql } from '@apollo/client';

const DELETE_CONTENT_GROUP = gql`
  mutation UpdateContentGroup(
    $contentGroupId: Int!
    $status: EContentGroupStatus!
  ) {
    contentGroup {
      updateContentGroupStatus(id: $contentGroupId, status: $status) {
        id
      }
    }
  }
`;

export { DELETE_CONTENT_GROUP };
