import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_SECTION_GROUP_DETAILS } from './useGetSectionGroupDetails.graphql';
import {
  IGetSectionGroupDetailsQueryGraphQLResponse,
  IGetSectionGroupDetailsQueryVariables,
  IHandleGetSectionGroupDetailsParams,
  IUseGetSectionGroupDetails,
  IUseGetSectionGroupDetailsOptions,
} from './useGetSectionGroupDetails.interfaces';

const useGetSectionGroupDetails = (options?: IUseGetSectionGroupDetailsOptions): IUseGetSectionGroupDetails => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetSectionGroupDetailsOptions);

  const [executeGetSectionGroupDetails, { loading: isLoading }] = useEkardoLazyQuery<
  IGetSectionGroupDetailsQueryGraphQLResponse,
  IGetSectionGroupDetailsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          sectionGroup: { get: sectionGroup},
        } = data;

        void onCompleted({
          sectionGroup,
        });
      },
      onError,
    },
    query: query ?? GET_CONTENT_SECTION_GROUP_DETAILS,
    token,
  });

  const handleGetSectionGroupDetails = ({ id }: IHandleGetSectionGroupDetailsParams) => {
    void executeGetSectionGroupDetails({
      variables: {
        id,
      },
    });
  };

  return {
    handleGetSectionGroupDetails,
    isLoading,
  };
};

export { useGetSectionGroupDetails };
