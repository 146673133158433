import { IContentGroup } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { UseFormSetValue } from "react-hook-form";

const handleSelectContentGroup = ({contentGroup, setValue } : { contentGroup: IContentGroup; setValue: UseFormSetValue<FormFieldProps> }) => {
  const {
    id,
    status,
    formType,
    url,
    description,
    title,
    sort,
  } = contentGroup;

  setValue('id', id);
  setValue('title', title);
  setValue('formTitle', title);
  setValue('description', description);
  setValue('status', status);
  setValue('isActivated', status === 'PUBLISHED');
  setValue('sort', sort);
  setValue('url', url);
  setValue('formType', formType);
  setValue('hasChosenFromLibrary', true);

};

const handleCancelSelectContentGroup = ({ setValue } : { setValue: UseFormSetValue<FormFieldProps> }) => {
  setValue('id', undefined);
  setValue('title', '');
  setValue('formTitle', '');
  setValue('description', '');
  setValue('status', '');
  setValue('isActivated', false);
  setValue('sort', '');
  setValue('url', '');
  setValue('formType', '');
  setValue('hasChosenFromLibrary', false);

};

export { handleSelectContentGroup, handleCancelSelectContentGroup };
