import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SUMMARY_FEEDBACK_CONFIGURATION } from './useGetSnippetSummaryFeedbackConfig.graphql';
import {
  IGetSnippetSummaryFeedbackConfigQueryGraphQLResponse,
  IGetSnippetSummaryFeedbackConfigQueryVariables,
  IUseGetSnippetSummaryFeedbackConfig,
  IUseGetSnippetSummaryFeedbackConfigOptions,
} from './useGetSnippetSummaryFeedbackConfig.interfaces';

const useGetSnippetSummaryFeedbackConfig = (options?: IUseGetSnippetSummaryFeedbackConfigOptions): IUseGetSnippetSummaryFeedbackConfig => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetSnippetSummaryFeedbackConfigOptions);

  const [executeGetSnippetSummaryFeedbackConfig, { loading: isLoading }] = useEkardoLazyQuery<
    IGetSnippetSummaryFeedbackConfigQueryGraphQLResponse,
    IGetSnippetSummaryFeedbackConfigQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: {
            getContentSnippet: { summaryFeedbackConfiguration: config },
          },
        } = data;

        onCompleted({
          config,
        });
      },
      onError,
    },
    query: GET_SUMMARY_FEEDBACK_CONFIGURATION,
    token,
  });

  const handleGetSnippetSummaryFeedbackConfig = async ({ contentSnippetSummaryId }: IGetSnippetSummaryFeedbackConfigQueryVariables) => {
    await executeGetSnippetSummaryFeedbackConfig({
      variables: {
        contentSnippetSummaryId,
      },
    });
  };

  return {
    handleGetSnippetSummaryFeedbackConfig,
    isLoading,
  };
};

export { useGetSnippetSummaryFeedbackConfig };
