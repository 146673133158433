import { PageTitleBannerProps } from './PageTitleBanner.types';

const PageTitleBanner = ({ afterTitle, beforeTitle, containerClassName, title }: PageTitleBannerProps) => {
  return (
    <section className="c-page-title-banner">
      <div className={`c-container c-page-title-banner__container ${containerClassName ? containerClassName : ''}`}>
        {beforeTitle ? beforeTitle : null}
        <h1 className="c-page-title-banner__container__header">{title}</h1>
        {afterTitle ? afterTitle : null}
      </div>
    </section>
  );
};

export { PageTitleBanner };
