import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_RADIO_CONFIGURATION } from './useCreateRadioQuestionConfig.graphql';
import {
  ICreateRadioQuestionConfigMutationGraphQLResponse,
  ICreateRadioQuestionConfigMutationVariables,
  IHandleCreateRadioQuestionConfigParams,
  IUseCreateRadioQuestionConfigOptions,
  IUseCreateRadioQuestionConfig,
} from './useCreateRadioQuestionConfig.interfaces';

const useCreateRadioQuestionConfig = (options?: IUseCreateRadioQuestionConfigOptions): IUseCreateRadioQuestionConfig => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateRadioQuestionConfigOptions);

  const [executeCreateRadioQuestionConfig, { loading: isLoading }] = useEkardoMutation<ICreateRadioQuestionConfigMutationGraphQLResponse, ICreateRadioQuestionConfigMutationVariables>({
    mutation: mutation ?? CREATE_RADIO_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { createQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateRadioQuestionConfig = async ({
    allowUserGeneratedResponses,
    canModify,
    description,
    projectId,
    title,
  }: IHandleCreateRadioQuestionConfigParams) => {
    await executeCreateRadioQuestionConfig({
      variables: {
        allowUserGeneratedResponses,
        canModify,
        description,
        projectId,
        title,
      }
    });
  };

  return {
    handleCreateRadioQuestionConfig,
    isLoading,
  };
};

export { useCreateRadioQuestionConfig };
