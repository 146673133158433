import { gql } from '@apollo/client';

const ADD_TO_FAVORITE_ASSETS_MUTATION = gql`
  mutation AddAssetToFavorite($assetId: String!) {
    user {
      addFavouriteAsset(assetId: $assetId)
    }
  }
`;

export { ADD_TO_FAVORITE_ASSETS_MUTATION };
