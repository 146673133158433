import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_SINGLE_TEXT_CONFIGURATION } from './useCreateSingleTextQuestionConfig.graphql';
import {
  ICreateSingleTextQuestionConfigMutationGraphQLResponse,
  ICreateSingleTextQuestionConfigMutationVariables,
  IHandleCreateSingleTextQuestionConfigParams,
  IUseCreateSingleTextQuestionConfigOptions,
  IUseCreateSingleTextQuestionConfig,
} from './useCreateSingleTextQuestionConfig.interfaces';

const useCreateSingleTextQuestionConfig = (options?: IUseCreateSingleTextQuestionConfigOptions): IUseCreateSingleTextQuestionConfig => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateSingleTextQuestionConfigOptions);

  const [executeCreateSingleTextQuestionConfig, { loading: isLoading }] = useEkardoMutation<ICreateSingleTextQuestionConfigMutationGraphQLResponse, ICreateSingleTextQuestionConfigMutationVariables>({
    mutation: mutation ?? CREATE_SINGLE_TEXT_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { createQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateSingleTextQuestionConfig = async ({
    description,
    maxLength,
    minLength,
    projectId,
    regex,
  }: IHandleCreateSingleTextQuestionConfigParams) => {
    await executeCreateSingleTextQuestionConfig({
      variables: {
        description,
        maxLength,
        minLength,
        projectId,
        regex,
      }
    });
  };

  return {
    handleCreateSingleTextQuestionConfig,
    isLoading,
  };
};

export { useCreateSingleTextQuestionConfig };
