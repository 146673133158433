import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_TAG_MUTATION } from './useDeleteTag.graphql';
import {
  IDeleteTagMutationGraphQLResponse,
  IDeleteTagMutationVariables,
  IHandleDeleteTagParams,
  IUseDeleteTagOptions,
  IUseDeleteTag,
} from './useDeleteTag.interfaces';

const useDeleteTag = (options?: IUseDeleteTagOptions): IUseDeleteTag => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteTagOptions);

  const [executeDeleteTag, { loading: isLoading }] = useEkardoMutation<IDeleteTagMutationGraphQLResponse, IDeleteTagMutationVariables>({
    mutation: mutation ?? DELETE_TAG_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          tagMutation: {
            removeTag: isCompleted,
          },
        } = data;

        void onCompleted({
          isCompleted
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteTag = async ({ tagId }: IHandleDeleteTagParams) => {
    await executeDeleteTag({
      variables: {
        tagId,
      },
    });
  };

  return {
    handleDeleteTag,
    isLoading,
  };
};

export { useDeleteTag };
