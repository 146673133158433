/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_GROUP_USERS } from './useGetGroupMemberships.graphql';
import {
  IGetGroupMembershipsQueryGraphQLResponse,
  IGetGroupMembershipsQueryVariables,
  IUseGetGroupMemberships,
  IUseGetGroupMembershipsOptions,
} from './useGetGroupMemberships.interfaces';

const useGetGroupMemberships = (options?: IUseGetGroupMembershipsOptions): IUseGetGroupMemberships => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetGroupMembershipsOptions);

  const [executeGetGroupMemberships, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGroupMembershipsQueryGraphQLResponse,
    IGetGroupMembershipsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ group: { getUsers } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          memberships: getUsers,
        });
      },
      onError,
    },
    query: GET_GROUP_USERS,
    token,
  });

  const handleGetGroupMemberships = async ({ groupId }: IGetGroupMembershipsQueryVariables) => {
    await executeGetGroupMemberships({
      variables: {
        groupId,
      },
    });
  };

  return {
    handleGetGroupMemberships,
    isLoading,
  };
};

export { useGetGroupMemberships };
