/* eslint-disable @typescript-eslint/indent */
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_RESPONSE_SET_SUGGESTIONS } from './useGetResponseSetSuggestions.graphql';
import {
  IGetResponseSetSuggestionsQueryGraphQLResponse,
  IGetResponseSetSuggestionsVariables,
  IUseGetResponseSetSuggestions,
  IUseGetResponseSetSuggestionsOptions,
} from './useGetResponseSetSuggestions.interfaces';

const useGetResponseSetSuggestions = (options?: IUseGetResponseSetSuggestionsOptions): IUseGetResponseSetSuggestions => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetResponseSetSuggestionsOptions);

  const [executeGetResponseSetSuggestions, { loading: isLoading }] = useEkardoLazyQuery<
    IGetResponseSetSuggestionsQueryGraphQLResponse,
    IGetResponseSetSuggestionsVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionResponseSet: { searchResponseSet },
        } = data;

        onCompleted({
          searchResponseSet,
        });
      },
      onError,
    },
    query: GET_RESPONSE_SET_SUGGESTIONS,
    token,
  });

  const handleGetResponseSetSuggestions = async ({ projectId, title, type }: IGetResponseSetSuggestionsVariables) => {
    await executeGetResponseSetSuggestions({
      variables: {
        projectId,
        title,
        type,
      },
    });
  };

  return {
    handleGetResponseSetSuggestions,
    isLoading,
  };
};

export { useGetResponseSetSuggestions };
