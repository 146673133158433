import { ChangeEvent, useState, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { Dialog, Input, Select, SidebarButtons, Spacing, Spinner } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useToast, useCreateTopic, useUpdateTopic, useDeleteTopic } from 'hooks';
import { IDBTopic } from 'interfaces';

import { TopicsSidebarGeneralViewProps } from './TopicsSidebarGeneralView.interfaces';

const TopicsSidebarGeneralView = ({
  onClose,
  onCreated,
  onDeleted,
  onUpdated,
  selectedTopic,
  projectId,
}: TopicsSidebarGeneralViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { project } = useContext(CachingEntitiesContext);

  const { handleToastError } = useToast();

  const [currentTopic, setCurrentTopic] = useState<IDBTopic>(
    selectedTopic ??
      ({
        id: 0,
        title: '',
        status: 'UNPUBLISHED',
      } as IDBTopic),
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const { id: topicId, title: topicTitle = '', status: topicStatus } = currentTopic;

  const { handleCreateTopic: executeCreateTopic, isLoading: isCreateTopicLoading = false } = useCreateTopic({
    onCompleted: ({ topicsConnection }) => {
      const { title, status } = topicsConnection;

      onCreated({
        title,
        status,
      } as IDBTopic);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  const { handleDeleteTopic: executeDeleteTopic, isLoading: isDeleteTopicLoading = false } = useDeleteTopic({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);

      onDeleted(currentTopic.id);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  const { handleUpdateTopic: executeUpdateTopic, isLoading: isUpdateTopicLoading } = useUpdateTopic({
    onCompleted: ({ topicsConnection }) => {
      const { id, title, status } = topicsConnection;

      onUpdated({
        id,
        title,
        status,
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  const handleUpdateTopicInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentTopic(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBTopic),
    );
  };

  const handleCreateTopic = () => {
    void executeCreateTopic({
      projectId: String(project?.id),
      title: topicTitle,
      status: topicStatus,
    });
  };

  const handleUpdateTopic = () => {
    void executeUpdateTopic({
      topicId: Number(topicId),
      title: topicTitle,
      status: topicStatus,
    });
  };

  const isLoading = isCreateTopicLoading || isDeleteTopicLoading || isUpdateTopicLoading;

  return (
    <div className="c-topic-sidebar-general-view">
      {isLoading && <Spinner isLoading={isLoading} />}
      <Spacing size="large">
        <Input id="title" labelText="Title" name="title" tooltipText="Topic title" type="text" value={topicTitle} onChange={handleUpdateTopicInput} />

      </Spacing>

      <Spacing size="large">
        <Select
          id="id_topic_status"
          labelText="Status"
          name="status"
          options={[
            {
              id: 'ACTIVE',
              name: 'Active',
              value: 'ACTIVE'
            },
            {
              id: 'UNPUBLISHED',
              name: 'Unpublished',
              value: 'UNPUBLISHED'
            },
            {
              id: 'DELETED',
              name: 'Deleted',
              value: 'DELETED'
            },
          ]}
          value={topicStatus}
          onChange={handleUpdateTopicInput}
        />

      </Spacing>

      {selectedTopic && (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete topic: ${topicTitle}`}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            if (!topicId) {
              return;
            }

            executeDeleteTopic({
              topicId,
            });
          }}
        />
      )}

      <SidebarButtons
        onCancel={onClose}
        onDelete={selectedTopic? () => setIsDeleteDialogOpen(true) : undefined}
        onSave={selectedTopic ? handleUpdateTopic : handleCreateTopic}
        onSaveButtonType="button"
      />
    </div>
  );
};

export { TopicsSidebarGeneralView };
