import { gql } from '@apollo/client';

const DELETE_ANNOUNCE_QUERY = gql`
  mutation deleteAnnounce($id: Int!) {
    announce {
      delete(id: $id)
    }
  }
`;

export { DELETE_ANNOUNCE_QUERY };
