import { IDBAsset } from "@netfront/common-library";
import { FormFieldProps } from "@netfront/ui-library";

const getAssetDefaultValues = ({ asset, assetIdKey = 'assetId', isSensitive, title  }: { asset?: IDBAsset; assetIdKey?: string; isSensitive?: boolean; title?: string }): FormFieldProps => {
  const {
    alt,
    assetId,
    contentType,
    description,
    fileName,
    isPublic,
    presignedUrl,
    publicUrl,
    tagList,
    title: assetTitle,
    user,
    // isSensitive: isAssetSensitive,
  } = asset ?? {};

  const { firstName, lastName} = user ?? {};
  return {
    alt: alt ?? '',
    author: `${String(firstName ?? '')} ${String(lastName ?? '')}`,
    [`${assetIdKey}`]: assetId ?? '',
    contentType,
    description: description ?? '',
    fileName: fileName ?? '',
    isDownloadAvailable: false,
    isPublic: isPublic ?? false,
    presignedUrl: presignedUrl ?? '',
    publicUrl: publicUrl ?? '',
    tagList: tagList ?? [],
    title: title ?? assetTitle ?? fileName ?? '',
    asset,
    isSensitive: isSensitive ?? false
  }
};

export { getAssetDefaultValues };
