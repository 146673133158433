import React, { useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Textarea, ToggleSwitch, Spacing, InformationBox, Input } from '@netfront/ui-library';
import { ContentBuilderTabWrapper } from 'components';
import { Controller } from 'react-hook-form';

import { UpsertCodeSnippetGeneralTabProps } from './UpsertCodeSnippetGeneralTab.interfaces';


const UpsertCodeSnippetGeneralTab = ({ control }: UpsertCodeSnippetGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);

  return (
    <ContentBuilderTabWrapper>
      <Spacing>
        <InformationBox message="Edit your code below." />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_code_snippet_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />

      </Spacing>
      <Spacing size="large">
        <ToggleSwitch
          additionalClassNames="c-sidebar-toggle"
          id="id_code_preview"
          isChecked={isShowPreview}
          labelText="Show preview"
          isInline
          isLabelSideBySide
          onChange={() => setIsShowPreview(!isShowPreview)}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="htmlContent"
          render={({ field, fieldState }) => (
            <>
              {!isShowPreview ? (
                <Textarea
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_code_snippet_textarea"
                  labelText="Content"
                  isLabelSideBySide
                  isRequired
                  {...field}
                />
              ) : (
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: field.value,
                  }}
                />
              )}
            </>
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_sensitive_content"
              isChecked={field.value}
              labelText="Sensitive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isVisible"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_visible"
              isChecked={field.value}
              labelText="Is visible"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </ContentBuilderTabWrapper>
  );
};

export { UpsertCodeSnippetGeneralTab };
