import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IAssetTag } from '@netfront/common-library';
import { TagsGeneralTab, TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useSearchPaginatedTags, useToast } from 'hooks';
import last from 'lodash.last';
import { useRouter } from 'next/router';


import { TAGS_TABLE_COLUMNS } from './TagsPage.constants';
import { getTagsTableData } from './TagsPage.helpers';
import { ITagsTableData } from './TagsPage.interfaces';


const TagsPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError } = useToast();
  const defaultPageSize = 10;

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allTagItems, setAllTagItems] = useState<IAssetTag[]>();
  const [filter, setFilter] = useState<string>();
  const [tagItem, setTagItem] = useState<IAssetTag>();
  const [tagsTableData, setTagsTableData] = useState<ITagsTableData[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalTagItems, setTotalTagItems] = useState(0);

  const {
    handleFetchMorePaginatedTags,
    handleSearchPaginatedTags,
    isLoading: isSearchPaginatedTagsLoading = false } = useSearchPaginatedTags(
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ edges = [], totalCount = 0 }) => {
        const lastEdge = last(edges);

        if (lastEdge && lastEdge.cursor !== lastCursor) {
          setLastCursor(lastEdge.cursor);
        }

        const tags: IAssetTag[] = edges.flatMap(({ node }) => (node ? node : []));

        setAllTagItems(tags);
        setIsLoadMoreDisabled(tags.length >= totalCount || totalCount <= pageSize);
        setTotalTagItems(totalCount);
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
  );

  const handleFilterSearch = (value: string) => {
    void handleSearchPaginatedTags({
      first: pageSize,
      projectId: String(projectId),
      filter: value,
    });

    setFilter(value);
  };

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setTagItem(undefined);
  };

  const handleUpdate = () => {
    handleCloseSidebar();
    void handleSearchPaginatedTags({
      first: pageSize,
      projectId: String(projectId),
      filter: filter,
    });
  }

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };


  const addNewTagItem = () => {
    setTagItem(undefined);
    setIsSideBarOpen(true);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleSearchPaginatedTags({
      first: pageSize,
      projectId: String(projectId),
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allTagItems) {
      return;
    }

    setTagsTableData(
      getTagsTableData({
        tagItems: allTagItems,
        onSettingsButtonClick: (item) => {
          setIsSideBarOpen(true);
          setTagItem(item);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTagItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <TablePageTemplate<ITagsTableData>
      activePage="library"
      activeSubPage="tags"
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Library',
      }, {
        key: '2',
        content: 'Tags',
      }]}
      columns={TAGS_TABLE_COLUMNS}
      data={tagsTableData}
      description={`Tags for ${String(projectName)}`}
      handleAddNewClick={addNewTagItem}
      handleOnPageSizeChange={handleOnPageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMorePaginatedTags({
          after: lastCursor,
          first: pageSize,
          projectId: String(projectId),
          filter: filter,
        });
      }}
      handleSearch={handleFilterSearch}
      informationBoxMessage="Manage project tags"
      isLoading={isSearchPaginatedTagsLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={projectName ?? ''}
      searchPlaceholder="Search"
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="tags"
      tabs={[
        {
          iconId: 'id_general_tab_icon',
          id: 'id_general_tab',
          label: 'General',
          view: () => <TagsGeneralTab projectId={String(projectId)} tagItem={tagItem} onClose={handleCloseSidebar} onUpdate={handleUpdate}/>,
        },
      ]}
      title={`${String(projectName)} Tags`}
      toggleIsSideBarOpen={handleCloseSidebar}
      totalItems={totalTagItems}
      hideSideBarButtons
    />
  );
};

export { TagsPage };
