/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_USER_REGISTRATION } from './useGetUserRegistration.graphql';
import {
  IGetUserRegistrationQueryGraphQLResponse,
  IGetUserRegistrationQueryVariables,
  IUseGetUserRegistration,
  IUseGetUserRegistrationOptions,
} from './useGetUserRegistration.interfaces';

const useGetUserRegistration = (options?: IUseGetUserRegistrationOptions): IUseGetUserRegistration => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetUserRegistrationOptions);

  const [executeGetUserRegistration, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetUserRegistrationQueryGraphQLResponse,
    IGetUserRegistrationQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ insights: { userRegistration } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userRegistration,
        });
      },
      onError,
    },
    query: GET_USER_REGISTRATION,
    token,
  });

  const handleGetUserRegistration = async ({ end, projectId, start }: IGetUserRegistrationQueryVariables) => {
    await executeGetUserRegistration({
      variables: {
        end,
        start,
        projectId,
      },
    });
  };

  return {
    handleGetUserRegistration,
    isLoading,
  };
};

export { useGetUserRegistration };
