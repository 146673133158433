import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ACTION_DETAILS } from './useGetActionDetails.graphql';
import { IGetActionDetailsQueryGraphQLResponse, IGetActionDetailsQueryVariables, IHandleGetActionDetailsParams, IUseGetActionDetails, IUseGetActionDetailsOptions } from './useGetActionDetails.interfaces';

const useGetActionDetails = (options?: IUseGetActionDetailsOptions): IUseGetActionDetails => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetActionDetailsOptions);
  const [executeGetActionDetails, { loading: isLoading }] = useEkardoLazyQuery<
  IGetActionDetailsQueryGraphQLResponse,
  IGetActionDetailsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { get: actionDetails },
        } = data;

        onCompleted({
          actionDetails,
        });
      },
      onError,
    },
    query: query ?? GET_ACTION_DETAILS,
    token,
  });

  const handleGetActionDetails = ({ actionId }: IHandleGetActionDetailsParams) => {
    void executeGetActionDetails({
      variables: {
        actionId,
      },
    });
  };

  return {
    handleGetActionDetails,
    isLoading,
  };
};

export { useGetActionDetails };
