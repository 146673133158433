/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_USER_ACTIVE } from './useGetUserActive.graphql';
import {
  IGetUserActiveQueryGraphQLResponse,
  IGetUserActiveQueryVariables,
  IUseGetUserActive,
  IUseGetUserActiveOptions,
} from './useGetUserActive.interfaces';

const useGetUserActive = (options?: IUseGetUserActiveOptions): IUseGetUserActive => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetUserActiveOptions);

  const [executeGetUserActive, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetUserActiveQueryGraphQLResponse,
    IGetUserActiveQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ insights: { userActive } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userActive,
        });
      },
      onError,
    },
    query: GET_USER_ACTIVE,
    token,
  });

  const handleGetUserActive = async ({ projectId, end, start }: IGetUserActiveQueryVariables) => {
    await executeGetUserActive({
      variables: {
        end,
        start,
        projectId,
      },
    });
  };

  return {
    handleGetUserActive,
    isLoading,
  };
};

export { useGetUserActive };
