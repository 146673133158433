import { gql } from '@apollo/client';

const UPDATE_TOPIC_MUTATION = gql`
  mutation UpdateTopic($topicId: Int!, $title: String!, $status: ETopicStatus) {
    topic {
      editTopic(topicId: $topicId, title: $title, status: $status) {
        id
        title
        description
        status
      }
    }
  }
`;

export { UPDATE_TOPIC_MUTATION };
