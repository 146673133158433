import { gql } from '@apollo/client';

const BULK_UPDATE_COMMUNITY_ROLE_MUTATION = gql`
  mutation UpdateCommunityRole($communityId: Int!, $userIds: [Int]!, $role: ECommunityRole!) {
    communityConnection {
      addOrUpdateUsersInCommunity(communityId: $communityId, userIds: $userIds, role: $role)
    }
  }
`;

export { BULK_UPDATE_COMMUNITY_ROLE_MUTATION };
