
import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_CONTENT_ENTITIES_VISIBILITY } from './useUpdateContentEntitiesVisibility.graphql';
import {
  IUpdateContentEntitiesVisibilityMutationGraphQLResponse,
  IUpdateContentEntitiesVisibilityMutationVariables,
  IHandleUpdateContentEntitiesVisibilityParams,
  IUseUpdateContentEntitiesVisibilityOptions,
  IUseUpdateContentEntitiesVisibility,
} from './useUpdateContentEntitiesVisibility.interfaces';

const useUpdateContentEntitiesVisibility = (options?: IUseUpdateContentEntitiesVisibilityOptions): IUseUpdateContentEntitiesVisibility => {
  const { mutation, onCompleted, onError, token, } = options ?? ({} as IUseUpdateContentEntitiesVisibilityOptions);

  const [executeUpdateContentEntitiesVisibility, { loading: isLoading }] = useEkardoMutation<IUpdateContentEntitiesVisibilityMutationGraphQLResponse, IUpdateContentEntitiesVisibilityMutationVariables>({
    mutation: mutation ?? UPDATE_CONTENT_ENTITIES_VISIBILITY,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { toggleVisibilityEntities: isCompleted },
        } = data;

        onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleUpdateContentEntitiesVisibility = async ({ entities, isVisible }: IHandleUpdateContentEntitiesVisibilityParams) => {
    await executeUpdateContentEntitiesVisibility({
      variables: {
        entities,
        isVisible,
      },
    });
  };

  return {
    handleUpdateContentEntitiesVisibility,
    isLoading,
  };
};

export { useUpdateContentEntitiesVisibility };
