import React from 'react';

import { FlexContainer, SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IPostsTableData } from './ModerationPage.interfaces';

const POST_PAGE_SIZE = 10;

const NOT_AVAILABLE = 'N/A';

const POSTS_TABLE_COLUMNS: ReadonlyArray<Column<IPostsTableData>> = [
  {
    accessor: 'author',
    Cell: ({ value }) => <div>{value ? `${value.firstName} ${value.lastName}` : NOT_AVAILABLE}</div>,
    Header: () => <div>User / Alias</div>,
    width: '15%',
  },
  {
    accessor: 'post',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Post</div>,
    width: '40%'
  },

  {
    accessor: 'comments',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Comments</div>,
    width: '5%',
  },
  {
    accessor: 'createdDate',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Date</div>,
    width: '10%'
  },
  {
    accessor: 'attachments',
    Cell: ({ value }) => {
      return (
        <FlexContainer gap="2x-small" isWrappable>
        {
          value.map(({icon, onClick, id}, index) => (
            <button key={index} className="c-posts-table__attachment-button" onClick={() => onClick(id)}>
              {icon}
            </button>
          )
        )}
        </FlexContainer>
      )
    },
    Header: () => <div>Attachment</div>,
    width: '25%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update scenario" onClick={() => onClick(id)} />,
    Header: () => <div className="h-hide-visually">Settings</div>,
    width: '5%',
  },
];

export { POSTS_TABLE_COLUMNS, POST_PAGE_SIZE };
