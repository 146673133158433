import { ImageSizeType } from 'hooks';

import IconPdf from '../../../../../public/images/files/icon-pdf.svg';
import IconWord from '../../../../../public/images/files/icon-word.svg';
import IconExcel from '../../../../../public/images/files/icon-xlsx.svg';

const MAX_ASSET_TITLE_CHARACTER_LENGTH_GRID_VIEW = 16;

const DEFAULT_PAGE_SIZE = 10;

const ASSET_SORT_SELECT_OPTIONS = [
  {
    id: 1,
    name: 'Alphabetical asc',
    value: 'ALPHABETIC',
  },
  {
    id: 2,
    name: 'Alphabetical desc',
    value: 'ALPHABETIC_DESCENDING',
  },
  {
    id: 3,
    name: 'Newest',
    value: 'NEWEST',
  },
  {
    id: 4,
    name: 'Oldest',
    value: 'OLDEST',
  },
];

export const ASSET_LIST_VIEW_SELECT_OPTIONS_COOKIE_MAP = Object.freeze({
  grid: 'SMALL' as ImageSizeType,
  list: 'EXTRA_SMALL' as ImageSizeType,
  grid_compact: 'EXTRA_SMALL' as ImageSizeType,
  list_compact: 'XX_SMALL' as ImageSizeType,
});

export const ASSET_LIST_VIEW_SELECT_OPTIONS_SELECTION_MAP = Object.freeze({
  grid: [false, false],
  grid_compact: [true, false],
  list: [false, true],
  list_compact: [true, true],
});

export const ALL_ASSET_TYPES = ['image', 'audio', 'video', 'document'];

export const ASSET_LIST_ACCEPTED_DOCUMENT_FILE_TYPES = Object.freeze({
  'application/msword': {
    icon: IconWord,
  },
  'application/pdf': {
    icon: IconPdf,
  },
  'application/vnd.ms-excel': {
    icon: IconExcel,
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    icon: IconExcel,
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    icon: IconWord,
  },
});

const ASSET_LIST_VIEW_TYPE_COOKIE_EXPIRY = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30); // 30 days;

const assetListConstants = Object.freeze({
  assetListAcceptedDocumentFileTypes: ASSET_LIST_ACCEPTED_DOCUMENT_FILE_TYPES,
  assetSortSelectOptions: ASSET_SORT_SELECT_OPTIONS,
  defaultPageSize: DEFAULT_PAGE_SIZE,
  maxAssetTitleCharacterLengthGridView: MAX_ASSET_TITLE_CHARACTER_LENGTH_GRID_VIEW,
  assetListViewTypeCookieExpiry: ASSET_LIST_VIEW_TYPE_COOKIE_EXPIRY,
  assetListViewSelectOptionsCookieMap: ASSET_LIST_VIEW_SELECT_OPTIONS_COOKIE_MAP,
  assetListViewSelectOptionsSelectionMap: ASSET_LIST_VIEW_SELECT_OPTIONS_SELECTION_MAP,
  allAssetTypes: ALL_ASSET_TYPES,
});

export { assetListConstants };
