import { Column } from 'react-table';

import { IFeedbackTableData } from './FeedbackReportPage.interfaces';



const FEEDBACK_COLUMNS: ReadonlyArray<Column<IFeedbackTableData>> = [
  {
    accessor: 'userType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User type</div>,
    width: '10%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Group name</div>,
    width: '10%',
  },
  {
    accessor: 'createdOn',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Created on</span>,
    width: '20%',
  },
  {
    accessor: 'rate',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Rate</span>,
    width: '5%',
  },
  {
    accessor: 'feedback',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Feedback</span>,
  },
  {
    accessor: 'url',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Url</span>,
  },

];

export { FEEDBACK_COLUMNS };
