import { gql } from '@apollo/client';

const ADD_GROUP_ACCESS = gql`
mutation addGroupAccess($userFlowId: Int!, $groupId: Int!) {
  userFlowGroupAccessMutation {
    add(userFlowId: $userFlowId, groupId: $groupId)
  }
}

`;

export { ADD_GROUP_ACCESS };
