import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { removeItemFromLocalStorage, saveItemWithExpiryToLocalStorage } from '@netfront/common-library';
import {
  IGeladaProject,
  useBackofficeSiteUrls,
  useDomain,
  useGetGeladaProject,
  useGetGeladaOrganisationByKey,
  IGeladaOrganisation,
  useProtectedRoute,
} from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import { useGetBonoboProjectFeatures, useGetContentGroupTypes, useToast } from 'hooks';
import { IBonoboProject } from 'interfaces';
import { useRouter } from 'next/router';

import CachingEntitiesContext from './CachingEntitiesContext';
import { CachingEntitiesContextProps } from './CachingEntitiesContext.interfaces';

export function CachingEntitiesProvider({ children }: Readonly<CachingEntitiesContextProps>) {
  const { getBaseUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });
  const { isDomainReady } = useDomain();
  const { isAuthenticated } = useProtectedRoute({
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    query: { projectId: queryProjectId, organisationId: queryOrganisationId },
  } = useRouter();
  const { handleToastError } = useToast();

  const [cmsBaseUrl, setCmsBaseUrl] = useState<string>('');
  const [contentGroupTypes, setContentGroupTypes] = useState<string[]>();
  const [contentGroupTypesExpiry, setContentGroupTypesExpiry] = useState<number>();
  const [organisationId, setOrganisationId] = useState<string>('');
  const [organisation, setOrganisation] = useState<IGeladaOrganisation>();
  const [organisationExpiry, setOrganisationExpiry] = useState<number>();
  const [project, setProject] = useState<IGeladaProject>();
  const [bonoboProject, setBonoboProject] = useState<IBonoboProject>();
  const [projectId, setProjectId] = useState<string>('');
  const [projectExpiry, setProjectExpiry] = useState<number>();
  const [bonoboProjectExpiry, setBonoboProjectExpiry] = useState<number>();

  const { handleGetGeladaProject, isLoading: isGetGeladaProjectLoading } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaProject }) => {
      saveItemWithExpiryToLocalStorage('project', JSON.stringify(geladaProject), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setProject(geladaProject);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetBonoboProjectFeatures, isLoading: isGetBonoboProjectFeaturesLoading } = useGetBonoboProjectFeatures({
    projectId: String(projectId),
    onCompleted: ({ project: returnedBonoboProject }) => {
      if (!returnedBonoboProject) return;
      saveItemWithExpiryToLocalStorage('bonoboProject', JSON.stringify(returnedBonoboProject), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setBonoboProject(returnedBonoboProject);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetContentGroupTypes, isLoading: isGetContentGroupTypesLoading } = useGetContentGroupTypes({
    fetchPolicy: 'cache-first',
    onCompleted: ({ contentGroupTypes: returnedContentGroupTypes }) => {
      saveItemWithExpiryToLocalStorage('contentGroupTypes', JSON.stringify(returnedContentGroupTypes), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setContentGroupTypes(returnedContentGroupTypes);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetGeladaOrganisationByKey, isLoading: isGetGeladaOrganisationLoading } = useGetGeladaOrganisationByKey({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaOrganisation }) => {
      saveItemWithExpiryToLocalStorage('organisation', JSON.stringify(geladaOrganisation), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setOrganisation(geladaOrganisation);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!projectId) {
      return;
    }

    if (projectId !== project?.id) {
      removeItemFromLocalStorage('project');
      removeItemFromLocalStorage('contentGroupTypes');
      removeItemFromLocalStorage('bonoboProject');

      void handleGetGeladaProject({
        projectId: String(projectId),
        shouldIncludeProjectLogo: true,
        shouldIncludeProjectSettings: true,
      });

      void handleGetContentGroupTypes({
        projectId: String(projectId),
      });

      void handleGetBonoboProjectFeatures({
        projectId: String(projectId),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project]);

  useEffect(() => {
    if (!organisationId) {
      return;
    }

    if (Number(organisationId) !== organisation?.id) {
      removeItemFromLocalStorage('organisation');

      void handleGetGeladaOrganisationByKey({
        organisationKey: String(organisationId),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    const contentGroupTypesJson = JSON.parse(String(window.localStorage.getItem('contentGroupTypes')));

    if (!contentGroupTypesJson) {
      return;
    }

    setContentGroupTypes(JSON.parse(String(contentGroupTypesJson.value)) as string[]);
    setContentGroupTypesExpiry(Number(contentGroupTypesJson.expiry));
  }, []);

  useEffect(() => {
    if (!projectId) return;
    const projectJSON = JSON.parse(String(window.localStorage.getItem('project')));

    if (!projectJSON) {
      return;
    }

    setProject(JSON.parse(String(projectJSON.value)) as IGeladaProject);
    setProjectExpiry(Number(projectJSON.expiry));
  }, [projectId]);

  useEffect(() => {
    if (!projectId) return;
    const projectJSON = JSON.parse(String(window.localStorage.getItem('bonoboProject')));

    if (!projectJSON) {
      return;
    }

    setBonoboProject(JSON.parse(String(projectJSON.value)) as IBonoboProject);
    setBonoboProjectExpiry(Number(projectJSON.expiry));
  }, [projectId]);

  useEffect(() => {
    if (!bonoboProjectExpiry) {
      return;
    }

    if (new Date().getTime() < bonoboProjectExpiry) {
      return;
    }

    removeItemFromLocalStorage('bonoboProject');
  }, [bonoboProjectExpiry]);

  useEffect(() => {
    if (!projectExpiry) {
      return;
    }

    if (new Date().getTime() < projectExpiry) {
      return;
    }

    removeItemFromLocalStorage('project');
  }, [projectExpiry]);

  useEffect(() => {
    if (!contentGroupTypesExpiry) {
      return;
    }

    if (new Date().getTime() < contentGroupTypesExpiry) {
      return;
    }

    removeItemFromLocalStorage('contentGroupTypes');
  }, [contentGroupTypesExpiry]);

  useEffect(() => {
    if (!organisationId) return;
    const organisationJSON = JSON.parse(String(window.localStorage.getItem('organisation')));

    if (!organisationJSON) {
      return;
    }

    setOrganisation(JSON.parse(String(organisationJSON.value)) as IGeladaOrganisation);
    setOrganisationExpiry(Number(organisationJSON.expiry));
  }, [organisationId]);

  useEffect(() => {
    if (!organisationExpiry) {
      return;
    }

    if (new Date().getTime() < organisationExpiry) {
      return;
    }

    removeItemFromLocalStorage('organisation');
  }, [organisationExpiry]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setCmsBaseUrl(getBaseUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!isAuthenticated) return;
    setProjectId(queryProjectId as string);
  }, [queryProjectId, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) return;
    setOrganisationId(queryOrganisationId as string);
  }, [queryOrganisationId, isAuthenticated]);

  const isLoading = isGetGeladaProjectLoading ?? isGetGeladaOrganisationLoading ?? isGetContentGroupTypesLoading ?? isGetBonoboProjectFeaturesLoading;

  return (
    <CachingEntitiesContext.Provider
      value={{
        cmsBaseUrl,
        contentGroupTypes,
        isLoading,
        organisation,
        project,
        bonoboProject,
      }}
    >
      {isLoading ? <Spinner isLoading={true} /> : children}
    </CachingEntitiesContext.Provider>
  );
}
