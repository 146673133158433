import { ApolloQueryResult } from '@apollo/client';
import { useBonoboLazyQuery } from 'hooks';

import { GET_PROJECT_COMMUNITIES_QUERY } from './useGetCommunitiesByProject.graphql';
import {
  IGetPaginatedProjectCommunitiesQueryGraphQLResponse,
  IGetPaginatedProjectCommunitiesQueryVariables,
  IHandleFetchMorePaginatedProjectCommunitiesParams,
  IHandleGetPaginatedProjectCommunitiesParams,
  IUseGetPaginatedProjectCommunities,
  IUseGetPaginatedProjectCommunitiesOptions,
} from './useGetCommunitiesByProject.interfaces';

const useGetPaginatedProjectCommunities = (options?: IUseGetPaginatedProjectCommunitiesOptions): IUseGetPaginatedProjectCommunities => {
  const {
    fetchPolicy,
    onCompleted,
    onError,
    query,
    token,
    projectId: bonoboProjectId,
  } = options ?? ({} as IUseGetPaginatedProjectCommunitiesOptions);

  const [executeGetPaginatedProjectCommunities, { fetchMore, loading: isLoading }] = useBonoboLazyQuery<
  IGetPaginatedProjectCommunitiesQueryGraphQLResponse,
  IGetPaginatedProjectCommunitiesQueryVariables
  >({
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          community: { byProject: communityConnection },
        } = data;

        onCompleted({
          communityConnection,
        });
      },
      onError,
    },
    query: query ?? GET_PROJECT_COMMUNITIES_QUERY,
    token,
  });

  const handleFetchMorePaginatedProjectCommunities = ({
    after,
    first,
    filter,
    projectId,
  }: IHandleFetchMorePaginatedProjectCommunitiesParams): Promise<
  ApolloQueryResult<IGetPaginatedProjectCommunitiesQueryGraphQLResponse>
  > => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          community: { byProject: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          community: { byProject: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          post: {
            ...previouslyFetchedScenarioConnection,
            getFeedProjectCommunities: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        filter,
        projectId,
      },
    });
  };

  const handleGetPaginatedProjectCommunities = ({ after, first, filter, projectId }: IHandleGetPaginatedProjectCommunitiesParams) => {
    void executeGetPaginatedProjectCommunities({
      variables: {
        after,
        first,
        filter,
        projectId,
      },
    });
  };

  return {
    handleFetchMorePaginatedProjectCommunities,
    handleGetPaginatedProjectCommunities,
    isLoading,
  };
};

export { useGetPaginatedProjectCommunities };
