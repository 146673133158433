import { Column } from 'react-table';

import { IGoalsTableData } from './GoalsReportPage.interfaces';



const GOALS_COLUMNS: ReadonlyArray<Column<IGoalsTableData>> = [
  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Id</div>,
    width: '10%',
  },
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">First name</div>,
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Last name</div>,
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Email</div>,
  },
  {
    accessor: 'goalTitle',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Goal title</div>,
  },
  {
    accessor: 'createdOn',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Creation date</span>,
  },

];

export { GOALS_COLUMNS };
