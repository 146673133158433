import { gql } from '@apollo/client';

const EXPORT_PAGE_LOADED = gql`
  query ExportPageLoaded($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime!, $dateTo: DateTime!, $fileType: FileType) {
    report {
      exportPageLoaded(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_PAGE_LOADED };
