
import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CONTENT_ENTITIES } from './useDeleteContentEntities.graphql';
import {
  IDeleteContentEntitiesMutationGraphQLResponse,
  IDeleteContentEntitiesMutationVariables,
  IHandleDeleteContentEntitiesParams,
  IUseDeleteContentEntitiesOptions,
  IUseDeleteContentEntities,
} from './useDeleteContentEntities.interfaces';

const useDeleteContentEntities = (options?: IUseDeleteContentEntitiesOptions): IUseDeleteContentEntities => {
  const { mutation, onCompleted, onError, token, } = options ?? ({} as IUseDeleteContentEntitiesOptions);

  const [executeDeleteContentEntities, { loading: isLoading }] = useEkardoMutation<IDeleteContentEntitiesMutationGraphQLResponse, IDeleteContentEntitiesMutationVariables>({
    mutation: mutation ?? DELETE_CONTENT_ENTITIES,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { deleteEntities: isCompleted },
        } = data;

        onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleDeleteContentEntities = async ({ entities }: IHandleDeleteContentEntitiesParams) => {
    await executeDeleteContentEntities({
      variables: {
        entities,
      },
    });
  };

  return {
    handleDeleteContentEntities,
    isLoading,
  };
};

export { useDeleteContentEntities };
