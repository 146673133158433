import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CONTENT_CONTAINER } from './useDeleteContainer.graphql';
import {
  IDeleteContainerMutationGraphQLResponse,
  IDeleteContainerMutationVariables,
  IHandleDeleteContainerParams,
  IUseDeleteContainerOptions,
  IUseDeleteContainer,
} from './useDeleteContainer.interfaces';

const useDeleteContainer = (options?: IUseDeleteContainerOptions): IUseDeleteContainer => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteContainerOptions);

  const [executeDeleteContainer, { loading: isLoading }] = useEkardoMutation<IDeleteContainerMutationGraphQLResponse, IDeleteContainerMutationVariables>({
    mutation: mutation ?? DELETE_CONTENT_CONTAINER,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { deleteContentSectionContainer: section },
        } = data;

        onCompleted({
          section,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteContainer = async ({
    containerId,
    contentLayoutId,
    contentSectionId,
   }: IHandleDeleteContainerParams) => {
    await executeDeleteContainer({
      variables: {
        containerId,
        contentLayoutId,
        contentSectionId,
      },
    });
  };

  return {
    handleDeleteContainer,
    isLoading,
  };
};

export { useDeleteContainer };
