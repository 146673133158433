import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_SECTION_GROUP } from './useUpdateSectionGroup.graphql';
import {
  IUpdateSectionGroupMutationGraphQLResponse,
  IUpdateSectionGroupMutationVariables,
  IUseUpdateSectionGroupOptions,
  IUseUpdateSectionGroup,
  IUpdateSectionGroupMutationParams,
} from './useUpdateSectionGroup.interfaces';

const useUpdateSectionGroup = (options?: IUseUpdateSectionGroupOptions): IUseUpdateSectionGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateSectionGroupOptions);

  const [executeUpdateSectionGroup, { loading: isLoading }] = useEkardoMutation<IUpdateSectionGroupMutationGraphQLResponse, IUpdateSectionGroupMutationVariables>({
    mutation: mutation ?? UPDATE_SECTION_GROUP,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          sectionGroup: { update: sectionGroup },
        } = data;

        onCompleted({
          sectionGroup,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateSectionGroup = async ({
    contentSectionGroup,
    shouldCreateEvent,
    shouldUpdateEvent,
    updateContentEvent,
    createContentEvent,

  }: IUpdateSectionGroupMutationParams) => {
    await executeUpdateSectionGroup({
      variables: {
        contentSectionGroup,
        shouldCreateEvent,
        shouldUpdateEvent,
        updateContentEvent,
        createContentEvent,
      }
    });
  };

  return {
    handleUpdateSectionGroup,
    isLoading,
  };
};

export { useUpdateSectionGroup };
