

import { useTripleELazyQuery } from 'hooks';

import { EXPORT_USER_GOALS } from './useGetGoalsReport.graphql';
import {
  IGetGoalsReportQueryGraphQLResponse,
  IGetGoalsReportQueryVariables,
  IHandleGetGoalsReportParams,
  IUseGetGoalsReport,
  IUseGetGoalsReportOptions,
} from './useGetGoalsReport.interfaces';

const useGetGoalsReport = (options?: IUseGetGoalsReportOptions): IUseGetGoalsReport => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: tripleEProjectId } = options ?? ({} as IUseGetGoalsReportOptions);

  const [executeGetGoalsReport, { loading: isLoading }] = useTripleELazyQuery<
    IGetGoalsReportQueryGraphQLResponse,
    IGetGoalsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          project_id: tripleEProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportUserGoals } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportUserGoals,
        });
      },
      onError,
    },
    query: query ?? EXPORT_USER_GOALS,
    token,
  });


  const handleGetGoalsReport = async ({
    dateFrom,
    dateTo,
  }: IHandleGetGoalsReportParams) => {
    await executeGetGoalsReport({
      variables: {
        dateFrom,
        dateTo,
      },
    });
  };

  return {
    handleGetGoalsReport,
    isLoading,
  };
};

export { useGetGoalsReport };
