import { useBonoboMutation } from '../useBonoboMutation';

import { BULK_DELETE_LANGUAGE_FILTERS } from './useBulkDeleteLanguageFilters.graphql';
import {
  IBulkDeleteLanguageFiltersMutationGraphQLResponse,
  IBulkDeleteLanguageFiltersMutationVariables,
  IHandleBulkDeleteLanguageFiltersParams,
  IUseBulkDeleteLanguageFiltersOptions,
  IUseBulkDeleteLanguageFilters,
} from './useBulkDeleteLanguageFilters.interfaces';

const useBulkDeleteLanguageFilters = (options?: IUseBulkDeleteLanguageFiltersOptions): IUseBulkDeleteLanguageFilters => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkDeleteLanguageFiltersOptions);

  const [executeBulkDeleteLanguageFilters, { loading: isLoading }] = useBonoboMutation<
  IBulkDeleteLanguageFiltersMutationGraphQLResponse,
  IBulkDeleteLanguageFiltersMutationVariables
  >({
    mutation: mutation ?? BULK_DELETE_LANGUAGE_FILTERS,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          languageFilter: { bulkDelete: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkDeleteLanguageFilters = ({ ids }: IHandleBulkDeleteLanguageFiltersParams) => {
    void executeBulkDeleteLanguageFilters({
      variables: {
        ids,
      },
    });
  };

  return {
    handleBulkDeleteLanguageFilters,
    isLoading,
  };
};

export { useBulkDeleteLanguageFilters };
