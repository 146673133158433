

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { EXPORT_QUESTIONNAIRES } from './useGetQuestionnairesReport.graphql';
import {
  IGetQuestionnairesReportQueryGraphQLResponse,
  IGetQuestionnairesReportQueryVariables,
  IHandleGetQuestionnairesReportParams,
  IUseGetQuestionnairesReport,
  IUseGetQuestionnairesReportOptions,
} from './useGetQuestionnairesReport.interfaces';

const useGetQuestionnairesReport = (options?: IUseGetQuestionnairesReportOptions): IUseGetQuestionnairesReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetQuestionnairesReportOptions);

  const [executeGetQuestionnairesReport, { loading: isLoading }] = useEkardoLazyQuery<
    IGetQuestionnairesReportQueryGraphQLResponse,
    IGetQuestionnairesReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportQuestionnaires } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportQuestionnaires,
        });
      },
      onError,
    },
    query: query ?? EXPORT_QUESTIONNAIRES,
    token,
  });


  const handleGetQuestionnairesReport = async ({
    dateFrom,
    dateTo,
    ianaTimeZone,
    projectId,
    fileType,
    columnHeadingType,
    includeNonAccountData = false,
    stepId,
    includeDeletedQuestions = false,
  }: IHandleGetQuestionnairesReportParams) => {
    await executeGetQuestionnairesReport({
      variables: {
        dateFrom,
        dateTo,
        ianaTimeZone,
        projectId,
        fileType,
        columnHeadingType,
        includeNonAccountData,
        stepId,
        includeDeletedQuestions,
      },
    });
  };

  return {
    handleGetQuestionnairesReport,
    isLoading,
  };
};

export { useGetQuestionnairesReport };
