import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_QUESTION_IN_CONTENT_GROUP } from './useGetQuestions.graphql';
import {
  IGetQuestionsQueryGraphQLResponse,
  IGetQuestionsQueryVariables,
  IUseGetQuestions,
  IUseGetQuestionsOptions,
} from './useGetQuestions.interfaces';

const useGetQuestions = (options?: IUseGetQuestionsOptions): IUseGetQuestions => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetQuestionsOptions);

  const [executeGetQuestions, { loading: isLoading }] = useEkardoLazyQuery<
    IGetQuestionsQueryGraphQLResponse,
    IGetQuestionsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { getAllQuestionInStep: groupedContentSnippetQuestions },
        } = data;

        onCompleted({
          groupedContentSnippetQuestions,
        });
      },
      onError,
    },
    query: GET_QUESTION_IN_CONTENT_GROUP,
    token,
  });

  const handleGetQuestions = async ({ userFlowStepId }: IGetQuestionsQueryVariables) => {
    await executeGetQuestions({
      variables: {
        userFlowStepId,
      },
    });
  };

  return {
    handleGetQuestions,
    isLoading,
  };
};

export { useGetQuestions };
