import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_HORIZONTAL_ALIGNMENT_OPTIONS } from './useGetHorizontalAlignmentOptions.graphql';
import {
  IGetHorizontalAlignmentOptionsQueryGraphQLResponse,
  IUseGetHorizontalAlignmentOptions,
  IUseGetHorizontalAlignmentOptionsOptions,
} from './useGetHorizontalAlignmentOptions.interfaces';

const useGetHorizontalAlignmentOptions = (options?: IUseGetHorizontalAlignmentOptionsOptions): IUseGetHorizontalAlignmentOptions => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetHorizontalAlignmentOptionsOptions);

  const [executeGetHorizontalAlignmentOptions, { loading: isLoading }] = useEkardoLazyQuery<IGetHorizontalAlignmentOptionsQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          __type: { enumValues: horizontalOptions},
        } = data;

        void onCompleted({
          options: horizontalOptions,
        });
      },
      onError,
    },
    query: query ?? GET_HORIZONTAL_ALIGNMENT_OPTIONS,
    token,
  });

  const handleGetHorizontalAlignmentOptions = () => {
    void executeGetHorizontalAlignmentOptions();
  };

  return {
    handleGetHorizontalAlignmentOptions,
    isLoading,
  };
};

export { useGetHorizontalAlignmentOptions };
