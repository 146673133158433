import { ApolloQueryResult } from '@apollo/client';
import { useBonoboLazyQuery } from 'hooks';

import { GET_LANGUAGE_FILTERS_QUERY } from './useGetPaginatedLanguageFilters.graphql';
import {
  IGetPaginatedLanguageFiltersQueryGraphQLResponse,
  IGetPaginatedLanguageFiltersQueryVariables,
  IHandleFetchMorePaginatedLanguageFiltersParams,
  IHandleGetPaginatedLanguageFiltersParams,
  IUseGetPaginatedLanguageFilters,
  IUseGetPaginatedLanguageFiltersOptions,
} from './useGetPaginatedLanguageFilters.interfaces';

const useGetPaginatedLanguageFilters = (options?: IUseGetPaginatedLanguageFiltersOptions): IUseGetPaginatedLanguageFilters => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetPaginatedLanguageFiltersOptions);

  const [executeGetPaginatedLanguageFilters, { fetchMore, loading: isLoading }] = useBonoboLazyQuery<
  IGetPaginatedLanguageFiltersQueryGraphQLResponse,
  IGetPaginatedLanguageFiltersQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          languageFilter: { list: LanguageFilterConnection },
        } = data;

        onCompleted({
          LanguageFilterConnection,
        });
      },
      onError,
    },
    query: query ?? GET_LANGUAGE_FILTERS_QUERY,
    token,
  });

  const handleFetchMorePaginatedLanguageFilters = ({
    after,
    content,
    projectId,
    type,
    first,
  }: IHandleFetchMorePaginatedLanguageFiltersParams): Promise<ApolloQueryResult<IGetPaginatedLanguageFiltersQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          languageFilter: { list: newlyFetchedLanguageFilterConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedLanguageFilterConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          languageFilter: { list: previouslyFetchedLanguageFilterConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedLanguageFilterConnection;

        return {
          ...previousQueryResult,
          languageFilter: {
            ...previouslyFetchedLanguageFilterConnection,
            list: {
              ...newlyFetchedLanguageFilterConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        content,
        projectId,
        type,
        first,
      },
    });
  };

  const handleGetPaginatedLanguageFilters = ({
    after,
    content,
    projectId,
    type,
    first,
  }: IHandleGetPaginatedLanguageFiltersParams) => {
    void executeGetPaginatedLanguageFilters({
      variables: {
        after,
        content,
        projectId,
        type,
        first,
      },
    });
  };

  return {
    handleFetchMorePaginatedLanguageFilters,
    handleGetPaginatedLanguageFilters,
    isLoading,
  };
};

export { useGetPaginatedLanguageFilters };
