
import { IModulePageCompletionTableData, IModulePageCompletionTableDataParams } from "./ModulePageCompletions.interfaces";


const getModulePageCompletionTableData = ({ items }: IModulePageCompletionTableDataParams): IModulePageCompletionTableData[] => {
  return items.map(({
    completedOn = '-',
    completedOnUtc = '-',
    groupName,
    id,
    moduleTitle,
    startedOn = '-',
    startedOnUtc = '-',
    timeToComplete = '-',
    pageTitle = '-',
    userType,
   }) => {
    return {
      completedOn,
      completedOnUtc,
      groupName,
      id,
      moduleTitle,
      startedOn,
      startedOnUtc,
      timeToComplete,
      pageTitle,
      userType,
    };
  });
};

export { getModulePageCompletionTableData };
