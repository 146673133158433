import { useBonoboMutation } from '../useBonoboMutation';

import { CREATE_POST_MUTATION } from './useCreatePost.graphql';
import {
  ICreatePostMutationGraphQLResponse,
  ICreatePostMutationVariables,
  IHandleCreatePostParams,
  IUseCreatePostOptions,
  IUseCreatePost,
} from './useCreatePost.interfaces';

const useCreatePost = (options?: IUseCreatePostOptions): IUseCreatePost => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseCreatePostOptions);

  const [executeCreatePost, { loading: isLoading }] = useBonoboMutation<ICreatePostMutationGraphQLResponse, ICreatePostMutationVariables>({
    mutation: mutation ?? CREATE_POST_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          post: { create: postsConnection },
        } = data;

        onCompleted({ postsConnection });
      },
      onError,
    },
    token,
  });

  const handleCreatePost = async ({ message, topicId, tags }: IHandleCreatePostParams) => {
    await executeCreatePost({
      variables: {
        message,
        topicId,
        tags,
      },
    });
  };

  return {
    handleCreatePost,
    isLoading,
  };
};

export { useCreatePost };
