import { useState, useEffect } from 'react';

import { Table } from '@netfront/ui-library';


import { PROGRESS_TRACKING_SUB_TABLE_COLUMNS } from './ProgressTrackingSubTable.constants';
import { getProgressTrackingSubTableData } from './ProgressTrackingSubTable.helpers';
import { IProgressTrackingSubTableData, ProgressTrackingSubTableProps } from './ProgressTrackingSubTable.interfaces';




const ProgressTrackingSubTable = ({ data }: ProgressTrackingSubTableProps) => {

  const [tableData, setTableData] = useState<IProgressTrackingSubTableData[]>([]);

  useEffect(() => {
    setTableData(
      getProgressTrackingSubTableData({
        userFlowProgress: data.userFlowProgress,
      })
    );

  }, [data]);

  return (
    <Table<IProgressTrackingSubTableData>
      columns={PROGRESS_TRACKING_SUB_TABLE_COLUMNS}
      data={tableData}
      isFullWidth
    />
  );

};

export { ProgressTrackingSubTable };
