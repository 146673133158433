import { IGetUsersTableDataParams, IUsersTableData } from "./UsersPage.interfaces";


const getUsersTableData = ({ users, onSettingsButtonClick }: IGetUsersTableDataParams): IUsersTableData[] => {
  return users.map(({ id, firstName, lastName, email, posts, communityConnections, displayedName }): IUsersTableData => {

    const communityTitles: string[] = communityConnections.edges.map(({ node: { community: { title }} }) => title);
    return {
      firstName,
      lastName,
      displayedName,
      email,
      id,
      communities: communityTitles.join(', '),
      postsCount: posts.length,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getUsersTableData };
