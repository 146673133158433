import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_CONTENT_GROUP } from './useUpdateContentGroup.graphql';
import {
  IUpdateContentGroupMutationGraphQLResponse,
  IUpdateContentGroupMutationVariables,
  IHandleUpdateContentGroupParams,
  IUseUpdateContentGroupOptions,
  IUseUpdateContentGroup,
} from './useUpdateContentGroup.interfaces';

const useUpdateContentGroup = (options?: IUseUpdateContentGroupOptions): IUseUpdateContentGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateContentGroupOptions);

  const [executeUpdateContentGroup, { loading: isLoading }] = useEkardoMutation<
  IUpdateContentGroupMutationGraphQLResponse,
  IUpdateContentGroupMutationVariables
  >({
    mutation: mutation ?? UPDATE_CONTENT_GROUP,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: {
            updateContentGroup: contentGroup,
          },
        } = data;

        void onCompleted({contentGroup});
      },
      onError,
    },
    token,
  });

  const handleUpdateContentGroup = ({
    contentGroup,
  }: IHandleUpdateContentGroupParams) => {
    void executeUpdateContentGroup({
      variables: {
        contentGroup,
      },
    });
  };

  return {
    handleUpdateContentGroup,
    isLoading,
  };
};

export { useUpdateContentGroup };
