import { useBonoboMutation } from '../useBonoboMutation';

import { BULK_UPSERT_LANGUAGE_FILTER } from './useBulkUpsertLanguageFilter.graphql';
import {
  IBulkUpsertLanguageFilterMutationGraphQLResponse,
  IBulkUpsertLanguageFilterMutationVariables,
  IHandleBulkUpsertLanguageFilterParams,
  IUseBulkUpsertLanguageFilterOptions,
  IUseBulkUpsertLanguageFilter,
} from './useBulkUpsertLanguageFilter.interfaces';

const useBulkUpsertLanguageFilter = (options?: IUseBulkUpsertLanguageFilterOptions): IUseBulkUpsertLanguageFilter => {
  const { mutation, onCompleted, onError, token, } = options ?? ({} as IUseBulkUpsertLanguageFilterOptions);

  const [executeBulkUpsertLanguageFilter, { loading: isLoading }] = useBonoboMutation<IBulkUpsertLanguageFilterMutationGraphQLResponse, IBulkUpsertLanguageFilterMutationVariables>({
    mutation: mutation ?? BULK_UPSERT_LANGUAGE_FILTER,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          languageFilter: { bulkAddOrUpdate: isCompleted },
        } = data;

        onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleBulkUpsertLanguageFilter = async ({ items }: IHandleBulkUpsertLanguageFilterParams) => {
    await executeBulkUpsertLanguageFilter({
      variables: {
        items,
      },
    });
  };

  return {
    handleBulkUpsertLanguageFilter,
    isLoading,
  };
};

export { useBulkUpsertLanguageFilter };
