import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_DIRECTORY_MUTATION } from './useUpdateDirectory.graphql';
import {
  IUpdateDirectoryMutationGraphQLResponse,
  IUpdateDirectoryMutationVariables,
  IHandleUpdateDirectoryParams,
  IUseUpdateDirectoryOptions,
  IUseUpdateDirectory,
} from './useUpdateDirectory.interfaces';

const useUpdateDirectory = (options?: IUseUpdateDirectoryOptions): IUseUpdateDirectory => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateDirectoryOptions);

  const [executeUpdateDirectory, { loading: isLoading }] = useEkardoMutation<
  IUpdateDirectoryMutationGraphQLResponse,
  IUpdateDirectoryMutationVariables
  >({
    mutation: mutation ?? UPDATE_DIRECTORY_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          directory: {
            updateDirectory: directory,
          },
        } = data;

        void onCompleted({directory});
      },
      onError,
    },
    token,
  });

  const handleUpdateDirectory = ({
    assetId,
    description,
    directoryId,
    directoryTypeId,
    email,
    friendlyUrl,
    operatingHours,
    phoneNumber,
    status,
    subTitle,
    tagIds,
    title,
    url,
  }: IHandleUpdateDirectoryParams) => {
    void executeUpdateDirectory({
      variables: {
        directory: {
          assetId,
          description,
          directoryId,
          directoryTypeId,
          email,
          friendlyUrl,
          operatingHours,
          phoneNumber,
          status,
          subTitle,
          title,
          url,
        },
        tagIds,
      },
    });
  };

  return {
    handleUpdateDirectory,
    isLoading,
  };
};

export { useUpdateDirectory };
