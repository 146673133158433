import { DBQuestionActionTypeNameType } from "@netfront/ekardo-content-library";

import { IAdditionalParameterOptions, ParameterMapType } from "./AdditionalActionParametersSelector.interfaces";

export const getAdditionalParameterOptions = (type: DBQuestionActionTypeNameType): IAdditionalParameterOptions[] => [
  {
    id: 1,
    name: 'Open',
    value: 'QuestionActionTriggerContentEventTypeOpen',
    isHidden: type !== 'QuestionActionTriggerContentEventType',
  },
  {
    id: 2,
    name: 'Close',
    value: 'QuestionActionTriggerContentEventTypeClose',
    isHidden: type !== 'QuestionActionTriggerContentEventType',
  },
];



export const getAdditionalParameters = (value: keyof ParameterMapType): { [key: string]: unknown } => {
  const parameterMap: ParameterMapType = {
    QuestionActionTriggerContentEventTypeOpen: {
      type: 'OPEN',
    },
    QuestionActionTriggerContentEventTypeClose: {
      type: 'CLOSE',
    },
  };

  return parameterMap[value];
};
