import { FRAGMENT_CALENDAR } from './calendarConfig';
import { FRAGMENT_CHECKBOX } from './checkboxConfig';
import { FRAGMENT_DROPDOWNLIST } from './dropDownListConfig';
import { FRAGMENT_MATCH } from './matchConfig';
import { FRAGMENT_MULTILINE } from './multiLineTextConfig';
import { FRAGMENT_MULTI_RESPONSE } from './multiResponseConfig';
import { FRAGMENT_NUMBER } from './numberConfig';
import { FRAGMENT_RADIO } from './radioConfig';
import { FRAGMENT_SINGLELINE } from './singleLineTextConfig';
import { FRAGMENT_SLIDER } from './sliderConfig';
import { FRAGMENT_SUMMARY } from './summaryConfig';

export const CalendarFragment = FRAGMENT_CALENDAR;
export const CheckboxFragment = FRAGMENT_CHECKBOX;
export const DropDownFragment = FRAGMENT_DROPDOWNLIST;
export const MatchFragment = FRAGMENT_MATCH;
export const MultilineFragment = FRAGMENT_MULTILINE;
export const MultiResponseFragment = FRAGMENT_MULTI_RESPONSE;
export const NumberFragment = FRAGMENT_NUMBER;
export const RadioFragment = FRAGMENT_RADIO;
export const SingleLineFragment = FRAGMENT_SINGLELINE;
export const SliderFragment = FRAGMENT_SLIDER;
export const SummaryFragment = FRAGMENT_SUMMARY;
