import { gql } from '@apollo/client';

const CREATE_CHARACTER_MUTATION = gql`
  mutation createCharacter($projectId: String!, $name: String!, $image: String) {
    character {
      addCharacter(projectId: $projectId, name: $name, image: $image) {
        id
        name
        projectId
        image {
          alt
          assetId
          presignedUrl
        }
      }
    }
  }
`;

export { CREATE_CHARACTER_MUTATION };
