import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { ADD_TO_FAVORITE_ASSETS_MUTATION } from './useAddToFavoriteAssets.graphql';
import {
  IAddToFavoriteAssetsMutationGraphQLResponse,
  IAddToFavoriteAssetsMutationVariables,
  IHandleAddToFavoriteAssetsParams,
  IUseAddToFavoriteAssetsOptions,
  IUseAddToFavoriteAssets,
} from './useAddToFavoriteAssets.interfaces';

const useAddToFavoriteAssets = (options?: IUseAddToFavoriteAssetsOptions): IUseAddToFavoriteAssets => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAddToFavoriteAssetsOptions);

  const [executeAddToFavoriteAssets, { loading: isLoading }] = useEkardoMutation<
    IAddToFavoriteAssetsMutationGraphQLResponse,
    IAddToFavoriteAssetsMutationVariables
  >({
    mutation: mutation ?? ADD_TO_FAVORITE_ASSETS_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          user: { addFavouriteAsset: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleAddToFavoriteAssets = ({ assetId }: IHandleAddToFavoriteAssetsParams) => {
    void executeAddToFavoriteAssets({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleAddToFavoriteAssets,
    isLoading,
  };
};

export { useAddToFavoriteAssets };
