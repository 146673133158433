import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ITagsTableData } from './TagsPage.interfaces';


const TAGS_TABLE_COLUMNS: ReadonlyArray<Column<ITagsTableData>> = [

  {
    accessor: 'name',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Name</div>,
    width: '95%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { item, onClick } }) => <SettingsButton supportiveText="Update tags" onClick={() => onClick(item)} />,
    Header: () => <span className="h-hide-visually">Settings</span>,
    width: '5%',
  },
];

export { TAGS_TABLE_COLUMNS };
