
import { IProfileInformationTableData, IProfileInformationTableDataParams } from "./ProfileInformationPage.interfaces";


const getProfileInformationTableData = ({ items }: IProfileInformationTableDataParams): IProfileInformationTableData[] => {
  return items.map(({
    email,
    firstName,
    groupName,
    id,
    lastName,
    registrationDate,
    registrationDateUtc,
    role,
    status,
   }) => {
    return {
      email,
      firstName: firstName ?? '',
      groupName,
      id,
      lastName: lastName ?? '',
      registrationDate,
      registrationDateUtc,
      role,
      status,
    };
  });
};

export { getProfileInformationTableData };
