import { ICommunitiesTableDataParams, ICommunitiesTableData } from './CommunitiesPage.interfaces';

const getCommunitiesTableData = ({ communities, onSettingsButtonClick }: ICommunitiesTableDataParams): ICommunitiesTableData[] => {
  return communities.map(({ id, description, title, status, privacy }): ICommunitiesTableData => {
    return {
      id,
      title,
      privacy,
      description,
      status,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getCommunitiesTableData };
