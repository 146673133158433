import React, { useState } from 'react';

import { Spacing, Select } from '@netfront/ui-library';

import { ConditionTemplateSelectorProps } from '../ConditionsOverview/ConditionTemplateSelector.interfaces';

const ConditionTemplateSelector = ({ templateOptions, onSelectTemplate }: ConditionTemplateSelectorProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleSelect = (value: string) => {
    onSelectTemplate(value);
    setSelectedOption(value);
    setTimeout(() => setSelectedOption(''), 500);
  };

  return (
    <Spacing>
      <Select
        id="id_action_type_select"
        labelText="Quick add condition"
        name="action_type_dropdown"
        options={[
          {
            id: 0,
            name: 'Select an option',
            value: '',
          },
          ...templateOptions.map(({ id: optionId, title: name }) => ({
            id: optionId,
            value: optionId,
            name,
          })),
        ]}
        value={selectedOption}
        isLabelSideBySide
        onChange={({ target: { value } }) => handleSelect(value)}
      />
    </Spacing>
  );
};


export { ConditionTemplateSelector };
