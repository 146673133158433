import { gql } from '@apollo/client';

const DELETE_GLOSSARY_ITEM_MUTATION = gql`
  mutation DeletedGlossaryItem($id: Int!) {
    textTooltip {
      delete(id: $id)
    }
  }
`;

export { DELETE_GLOSSARY_ITEM_MUTATION };
