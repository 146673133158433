import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_GROUPINGS } from './useGetContentGroupings.graphql';
import {
  IGetContentGroupingsQueryGraphQLResponse,
  IGetContentGroupingsQueryVariables,
  IHandleGetContentGroupingsParams,
  IUseGetContentGroupings,
  IUseGetContentGroupingsOptions,
} from './useGetContentGroupings.interfaces';

const useGetContentGroupings = (options?: IUseGetContentGroupingsOptions): IUseGetContentGroupings => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetContentGroupingsOptions);

  const [executeGetContentGroupings, { loading: isLoading }] = useEkardoLazyQuery<
  IGetContentGroupingsQueryGraphQLResponse,
  IGetContentGroupingsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: { getGroupings: contentGroupings},
        } = data;

        void onCompleted({
          contentGroupings,
        });
      },
      onError,
    },
    query: query ?? GET_CONTENT_GROUPINGS,
    token,
  });

  const handleGetContentGroupings = ({ projectId }: IHandleGetContentGroupingsParams) => {
    void executeGetContentGroupings({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetContentGroupings,
    isLoading,
  };
};

export { useGetContentGroupings };
