
import { IAllModulePageTableData, IAllModulePageTableDataParams } from "./AllModulePages.interfaces";


const getAllModulePageTableData = ({ items }: IAllModulePageTableDataParams): IAllModulePageTableData[] => {
  return items.map(({
    completedOn = '-',
    completedOnUtc = '-',
    groupName,
    id,
    moduleTitle,
    startedOn = '-',
    startedOnUtc = '-',
    timeToCompleteAll = '-',
    timeToCompleteFirstPage = '-',
    userType,
   }) => {
    return {
      completedOn,
      completedOnUtc,
      groupName,
      id,
      moduleTitle,
      startedOn,
      startedOnUtc,
      timeToCompleteAll,
      timeToCompleteFirstPage,
      userType,
    };
  });
};

export { getAllModulePageTableData };
