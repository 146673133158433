import { gql } from '@apollo/client';

const UPDATE_CHARACTER_MUTATION = gql`
  mutation updateCharacter($characterId: Int!, $name: String!, $image: String) {
    character {
      updateCharacter(
        name: $name
        characterId: $characterId
        image: $image
      ) {
        id
        name
        image {
          assetId
          alt
          presignedUrl
        }
      }
    }
  }
`;

export { UPDATE_CHARACTER_MUTATION };
