import { gql } from '@apollo/client';


export const UPDATE_CONDITION_RADIO = gql`
  mutation updateCondition(
    $contentPageId: Int!
    $contentSnippetQuestionId: Int!
    $isTemplate: Boolean!
    $questionActionId: Int!
    $questionConditionId: Int!
    $questionResponseRadioId: Int!
    $title: String!
    $selection: EQuestionConditionSelection!
  ) {
    questionAction {
      updateCondition(
        conditionId: $questionConditionId
        radioResponseSelected: {
          questionResponseRadioId: $questionResponseRadioId
          questionActionId: $questionActionId
          title: $title
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          isTemplate: $isTemplate
          selection: $selection
        }
      ) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        ... on QuestionConditionRadioResponseSelectedType {
          questionResponseRadioId
          selection
        }
      }
    }
  }
`;

export const UPDATE_CONDITION_TEXT = gql`
  mutation updateConditionText(
    $contentPageId: Int!
    $contentSnippetQuestionId: Int!
    $isTemplate: Boolean!
    $questionActionId: Int!
    $questionConditionId: Int!
    $text: String!
    $title: String!
  ) {
    questionAction {
      updateCondition(
        conditionId: $questionConditionId
        textEquals: {
          questionActionId: $questionActionId
          contentPageId: $contentPageId
          title: $title
          contentSnippetQuestionId: $contentSnippetQuestionId
          text: $text
          isTemplate: $isTemplate
        }
      ) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        ... on QuestionConditionTextType {
          text
        }
      }
    }
  }
`;

export const UPDATE_CONDITION_SLIDER = gql`
  mutation UpdateConditionSlider(
    $questionConditionId: Int!
    $questionActionId: Int!
    $title: String!
    $contentSnippetQuestionId: Int!
    $contentPageId: Int!
    $isTemplate: Boolean!
    $minValue: Int!
    $maxValue: Int!
  ) {
    questionAction {
      updateCondition(
        conditionId: $questionConditionId
        slider: {
          questionActionId: $questionActionId
          contentPageId: $contentPageId
          title: $title
          contentSnippetQuestionId: $contentSnippetQuestionId
          minValue: $minValue
          maxValue: $maxValue
          isTemplate: $isTemplate
        }
      ) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        __typename
        ... on QuestionConditionSliderValueSelectedType {
          minValue
          maxValue
        }
      }
    }
  }
`;

export const UPDATE_CONDITION_CHECKBOX = gql`
  mutation UpdateConditionCheckbox(
    $questionConditionId: Int!
    $questionActionId: Int!
    $title: String!
    $contentSnippetQuestionId: Int!
    $contentPageId: Int!
    $isTemplate: Boolean!
    $questionResponseCheckboxId: Int!
    $selection: EQuestionConditionSelection!
  ) {
    questionAction {
      updateCondition(
        conditionId: $questionConditionId
        checkbox: {
          questionActionId: $questionActionId
          contentPageId: $contentPageId
          title: $title
          contentSnippetQuestionId: $contentSnippetQuestionId
          isTemplate: $isTemplate
          questionResponseCheckboxId: $questionResponseCheckboxId
          selection: $selection
        }
      ) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        __typename
        ... on QuestionConditionCheckboxSelectedType {
          questionResponseCheckboxId
          selection
        }
      }
    }
  }
`;

export const UPDATE_CONDITION_DATE = gql`
  mutation UpdateConditionDate(
    $questionConditionId: Int!
    $questionActionId: Int!
    $title: String!
    $contentSnippetQuestionId: Int!
    $contentPageId: Int!
    $isTemplate: Boolean!
    $dateRangeType: EConditionDateType!
    $minDate: DateTime
    $maxDate: DateTime
    $selectedDate: DateTime
    $minTimeSpan: Int
    $maxTimeSpan: Int
  ) {
    questionAction {
      updateCondition(
        conditionId: $questionConditionId
        dateSelected: {
          questionActionId: $questionActionId
          contentPageId: $contentPageId
          title: $title
          contentSnippetQuestionId: $contentSnippetQuestionId
          type: $dateRangeType
          minDate: $minDate
          maxDate: $maxDate
          selectedDate: $selectedDate
          minTimeSpan: $minTimeSpan
          maxTimeSpan: $maxTimeSpan
          isTemplate: $isTemplate
        }
      ) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        __typename
        ... on QuestionConditionDateSelectedType {
          dateRangeType: type
          minDate
          maxDate
          selectedDate
          minTimeSpan
          maxTimeSpan
        }
      }
    }
  }
`;
