
const BORDER_WIDTH = 1;

const BAR_THICKNESS_SMALL = 15;

const BAR_THICKNESS_LARGE = 30;

const COLORS = [
  '#972868',
  '#4bc2ca',
  '#985dad',
  '#bded3e',
  '#ed633e',
  '#6a3d9a',
  '#3ec2a5',
  '#c73e82',
  '#f7a81b',
  '#5a72cc',
  '#e83e23',
  '#39a53e',
  '#d63e87',
  '#f0c725',
  '#4287f5',
  '#e24e42',
  '#3eb489',
  '#9e3ec7',
  '#f5b042',
  '#2c46b5',
];

const InsightsChartsConstants = Object.freeze({
  borderWidth: BORDER_WIDTH,
  barThicknessSmall: BAR_THICKNESS_SMALL,
  barThicknessLarge: BAR_THICKNESS_LARGE,
  colors: COLORS

});

export { InsightsChartsConstants };
