import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_GROUPS } from './useGetContentGroups.graphql';
import {
  IGetContentGroupsQueryGraphQLResponse,
  IGetContentGroupsQueryVariables,
  IHandleGetContentGroupsParams,
  IUseGetContentGroups,
  IUseGetContentGroupsOptions,
} from './useGetContentGroups.interfaces';

const useGetContentGroups = (options?: IUseGetContentGroupsOptions): IUseGetContentGroups => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetContentGroupsOptions);

  const [executeGetContentGroups, { loading: isLoading }] = useEkardoLazyQuery<
  IGetContentGroupsQueryGraphQLResponse,
  IGetContentGroupsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: { searchContentGroups: contentGroups},
        } = data;

        void onCompleted({
          contentGroups,
        });
      },
      onError,
    },
    query: query ?? GET_CONTENT_GROUPS,
    token,
  });

  const handleGetContentGroups = ({ projectId, type, title }: IHandleGetContentGroupsParams) => {
    void executeGetContentGroups({
      variables: {
        projectId,
        title,
        type,
      },
    });
  };

  return {
    handleGetContentGroups,
    isLoading,
  };
};

export { useGetContentGroups };
