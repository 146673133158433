/* eslint-disable @typescript-eslint/indent */
import { useContext, useEffect, useState } from 'react';

import { useJwtDecoder, useCookie, IGeladaAccessTokenPayload } from '@netfront/common-library';
import { useIdentitySiteUrls, useDomain } from '@netfront/gelada-identity-library';
import { Dropdown, Icon, OrganisationNavigation, OrganisationNavigationItemProps } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { CachingEntitiesContext, PermissionContext } from '../../context';

const OrganisationMenu = () => {
  const { isDomainReady } = useDomain();

  const { getDecodedJwt } = useJwtDecoder();
  const { getAccessTokenCookie } = useCookie();
  const { hasPermission } = useContext(PermissionContext);

  const { query } = useRouter();
  const { organisationId: queryOrganisationId, projectId: queryProjectId } = query;

  const { organisation, project } = useContext(CachingEntitiesContext);

  const { getBaseUrl: identityBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [projectId, setProjectId] = useState<string>('');
  const [organisationId, setOrganisationId] = useState<string>('');
  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isCustomBuild, setIsCustomBuild] = useState<boolean>(false);
  const [navigationList, setNavigationList] = useState<OrganisationNavigationItemProps[]>([]);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(identityBaseUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    const accessToken = getAccessTokenCookie();
    if (!accessToken) return;

    const decoded = getDecodedJwt(accessToken) as IGeladaAccessTokenPayload;
    const claim = decoded.custom_build;
    setIsCustomBuild(Boolean(String(claim).toLowerCase() === 'true'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenCookie]);

  useEffect(() => {

    if (!identityUrl) return;

    const dropdownNavigationItems: OrganisationNavigationItemProps[] = [
      {
        isHidden: !organisation || !hasPermission(String(projectId), 'MANAGE_USERS'),
        header: {
          title: `Current account`,
          name: organisationId ? organisation?.name  ?? '': 'Organisations',
          iconId: 'id_switch_icon',
          href: !isCustomBuild ? `${identityUrl}/dashboard`: undefined
        },
        options: [
          {
            iconId: 'id_team_icon',
            label: 'Team',
            isHidden: isCustomBuild || !hasPermission(String(projectId), 'MANAGE_USERS'),
            href: `${identityUrl}/account/${String(organisation?.key)}/team`,
          },
        ],
      },
      {
        isHidden: !project,
        header: {
          title: `Current project`,
          name: project?.name ?? '',
          iconId: 'id_switch_icon',
          href: !isCustomBuild ? `${identityUrl}/dashboard`: undefined
        },
        options: [
          {
            iconId: 'id_users_icon',
            label: 'Users',
            isHidden: !project,
            href: `${identityUrl}/project/${String(project?.id)}/user`
          },
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityUrl, isCustomBuild, organisation, organisationId, project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setOrganisationId(queryOrganisationId as string);
  }, [queryOrganisationId]);

  return (
    <div className="c-organisation-menu">
      {organisation && (
        <Dropdown
          dropdownId="organisation-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<Icon className="c-app-header__item" id="id_organisation_icon" />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <div className="c-organisation-menu">
            <OrganisationNavigation
              dropdownNavigationItems={navigationList}
            />
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export { OrganisationMenu };
