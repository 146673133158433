import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CHARACTER_MUTATION } from './useDeleteCharacter.graphql';
import {
  IDeleteCharacterMutationGraphQLResponse,
  IDeleteCharacterMutationVariables,
  IHandleDeleteCharacterParams,
  IUseDeleteCharacterOptions,
  IUseDeleteCharacter,
} from './useDeleteCharacter.interfaces';

const useDeleteCharacter = (options?: IUseDeleteCharacterOptions): IUseDeleteCharacter => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteCharacterOptions);

  const [executeDeleteCharacter, { loading: isLoading }] = useEkardoMutation<IDeleteCharacterMutationGraphQLResponse, IDeleteCharacterMutationVariables>({
    mutation: mutation ?? DELETE_CHARACTER_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          character: {
            removeCharacter: isCompleted,
          },
        } = data;

        void onCompleted({
          isCompleted
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteCharacter = async ({ characterId }: IHandleDeleteCharacterParams) => {
    await executeDeleteCharacter({
      variables: {
        characterId,
      },
    });
  };

  return {
    handleDeleteCharacter,
    isLoading,
  };
};

export { useDeleteCharacter };
