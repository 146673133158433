import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { REMOVE_FROM_FAVORITE_ASSETS_MUTATION } from './useRemoveFromFavoriteAssets.graphql';
import {
  IRemoveFromFavoriteAssetsMutationGraphQLResponse,
  IRemoveFromFavoriteAssetsMutationVariables,
  IHandleRemoveFromFavoriteAssetsParams,
  IUseRemoveFromFavoriteAssetsOptions,
  IUseRemoveFromFavoriteAssets,
} from './useRemoveFromFavoriteAssets.interfaces';

const useRemoveFromFavoriteAssets = (options?: IUseRemoveFromFavoriteAssetsOptions): IUseRemoveFromFavoriteAssets => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseRemoveFromFavoriteAssetsOptions);

  const [executeRemoveFromFavoriteAssets, { loading: isLoading }] = useEkardoMutation<
    IRemoveFromFavoriteAssetsMutationGraphQLResponse,
    IRemoveFromFavoriteAssetsMutationVariables
  >({
    mutation: mutation ?? REMOVE_FROM_FAVORITE_ASSETS_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          user: { removeFavouriteAsset: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleRemoveFromFavoriteAssets = ({ assetId }: IHandleRemoveFromFavoriteAssetsParams) => {
    void executeRemoveFromFavoriteAssets({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleRemoveFromFavoriteAssets,
    isLoading,
  };
};

export { useRemoveFromFavoriteAssets };
