import { gql } from '@apollo/client';

const UPDATE_COMMUNITY_ROLE_MUTATION = gql`
  mutation UpdateCommunityRole($inputType: UpdateCommunityRuleInputType!) {
    communityConnection {
      updateCommunityRole(inputType: $inputType) {
        role
      }
    }
  }
`;

export { UPDATE_COMMUNITY_ROLE_MUTATION };
