import { gql } from '@apollo/client';

// TODO: @ash add conditions type to conditions array
// TODO: @ash pass conditions and deletedIds to update function

// $title: String!
// $deletedConditionIds: [Int]
// $conditions: [Int]
// would come as follows
// {
//   questionActionId: Int! // i dont think this would be needed
//   questionConditionId: Int // optional to determine whether its create or update (if present then update)
//   title: String!
//   contentSnippetQuestionId: Int!
//   contentPageId: Int!
//   isTemplate: Boolean!
//   type: String! 'checkbox' | 'radio' | 'text' | 'slider'

//   // slider
//   minValue: Int
//   maxValue: Int

//   // checkbox
//   questionResponseCheckboxId: Int

//   // radio
//   questionResponseRadioId: Int

//   // text
//   text: String

// }
export const UPDATE_ACTION = gql`
  mutation questionActionUpdateSnippet($contentPageId: Int!, $actionId: Int!, $title: String) {
    questionAction {
      update(contentPageId: $contentPageId, actionId: $actionId, title: $title) {
        id
      }
    }
  }
`;
