import { gql } from '@apollo/client';

const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteComment($commentId: Int!) {
    comment {
      delete(commentId: $commentId)
    }
  }
`;

export { DELETE_COMMENT_MUTATION };
