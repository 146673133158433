import { gql } from '@apollo/client';

const EXPORT_COMMENT_LOGS = gql`
  query ExportCommentLogs($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime, $dateTo: DateTime) {
    report {
      exportCommentLogs(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_COMMENT_LOGS };
