import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_FORM_STATUS } from './useUpdateFormStatus.graphql';
import {
  IUpdateFormStatusMutationGraphQLResponse,
  IUpdateFormStatusMutationVariables,
  IHandleUpdateFormStatusParams,
  IUseUpdateFormStatusOptions,
  IUseUpdateFormStatus,
} from './useUpdateFormStatus.interfaces';

const useUpdateFormStatus = (options?: IUseUpdateFormStatusOptions): IUseUpdateFormStatus => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateFormStatusOptions);

  const [executeUpdateFormStatus, { loading: isLoading }] = useEkardoMutation<IUpdateFormStatusMutationGraphQLResponse, IUpdateFormStatusMutationVariables>({
    mutation: mutation ?? UPDATE_FORM_STATUS,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: {
            updateContentGroupStatus: {
              id,
            },
          },
        } = data;

        void onCompleted({ id });
      },
      onError,
    },
    token,
  });

  const handleUpdateFormStatus = async ({
    contentGroupId,
    status,
  }: IHandleUpdateFormStatusParams) => {
    await executeUpdateFormStatus({
      variables: {
        contentGroupId,
        status,
      },
    });
  };

  return {
    handleUpdateFormStatus,
    isLoading,
  };
};

export { useUpdateFormStatus };
