import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_PROJECT_STEPS } from './useGetProjectSteps.graphql';
import {
  IGetProjectStepsQueryGraphQLResponse,
  IGetProjectStepsQueryVariables,
  IUseGetProjectSteps,
  IUseGetProjectStepsOptions,
} from './useGetProjectSteps.interfaces';

const useGetProjectSteps = (options?: IUseGetProjectStepsOptions): IUseGetProjectSteps => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetProjectStepsOptions);

  const [executeGetProjectSteps, { loading: isLoading }] = useEkardoLazyQuery<
    IGetProjectStepsQueryGraphQLResponse,
    IGetProjectStepsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlowStep: { getStepsOfProject: userFlowSteps },
        } = data;

        onCompleted({
          userFlowSteps,
        });
      },
      onError,
    },
    query: GET_PROJECT_STEPS,
    token,
  });

  const handleGetProjectSteps = async ({ projectId }: IGetProjectStepsQueryVariables) => {
    await executeGetProjectSteps({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetProjectSteps,
    isLoading,
  };
};

export { useGetProjectSteps };
