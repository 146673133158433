import { gql } from '@apollo/client';

import {
  CalendarFragment,
  CheckboxFragment,
  DropDownFragment,
  MatchFragment,
  MultilineFragment,
  MultiResponseFragment,
  NumberFragment,
  RadioFragment,
  SingleLineFragment,
  SliderFragment,
} from '../questionConfiguration';

export const FRAGMENT_QUESTION = gql`
  fragment question on ContentSnippetQuestionType {
    majorNumber
    configuration {
      ...calendarConf
      ...checkboxConf
      ...dropDownListConf
      ...matchConf
      ...multiResponseConf
      ...multiTextConf
      ...numberConf
      ...radioConf
      ...singleTextConf
      ...sliderConf
      __typename
    }
    identifier
    question
    mandatory
    behavior
    majorNumber
    visible
    questionText: question
    questionTextWithTooltips: questionWithTooltips
    #answers(contentPageId: $contentPageId, userFlowStepTrackId: null) {
    #  ... on QuestionAnswerSinglelineTextType {
    #    text
    #    __typename
    #  }
    #  ... on QuestionAnswerMultilineTextType {
    #    text
    #    __typename
    #  }
    #  ... on QuestionAnswerRadioType {
    #    questionResponseRadioId
    #    __typename
    #  }
    #  ... on QuestionAnswerCheckboxType {
    #    selectedAnswers {
    #      questionResponseCheckboxId
    #      __typename
    #    }
    #    __typename
    #  }
    #  ... on QuestionAnswerDropDownListType {
    #    questionResponseDropDownListId
    #    __typename
    #  }
    #  ... on QuestionAnswerNumberType {
    #    numberAnswered
    #    __typename
    #  }
    #  ... on QuestionAnswerSliderType {
    #    value
    #    __typename
    #  }
    #  ... on QuestionAnswerCalendarType {
    #    dateAnswered
    #    __typename
    #  }
    #  __typename
    #}
  }
  ${CalendarFragment}
  ${CheckboxFragment}
  ${DropDownFragment}
  ${MatchFragment}
  ${MultilineFragment}
  ${MultiResponseFragment}
  ${NumberFragment}
  ${RadioFragment}
  ${SingleLineFragment}
  ${SliderFragment}
`;
