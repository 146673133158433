import { useState, useEffect } from 'react';

import { IUserFlow } from '@netfront/ekardo-content-library';
import { ButtonList, SidebarButtons, Spinner } from '@netfront/ui-library';
import { IGetUserFlowsOnCompletedResponse, useAddGroupAccess, useGetUserFlows } from 'hooks';

import { IAddGroupAccessProps } from './AddGroupAccess.interfaces';

export const AddGroupAccess = ({ groupId, onAdd, onClose, projectId }: IAddGroupAccessProps) => {
  const [userFlows, setUserFlows] = useState<IUserFlow[]>([]);
  const onGetUserFlowsCompleted = (data: IGetUserFlowsOnCompletedResponse) => {
    const { userflows: returnedUserFlows } = data;
    setUserFlows(returnedUserFlows);
  };

  const onAddGroupAccessCompleted = () => {
    onAdd();
  };

  const { handleAddGroupAccess } = useAddGroupAccess({ onCompleted: onAddGroupAccessCompleted });

  const { handleGetUserFlows, isLoading: isGetUserFlowsLoading = false } = useGetUserFlows({ onCompleted: onGetUserFlowsCompleted });

  const handleAddGroupPermission = (id: string | number) => {
    void handleAddGroupAccess({ groupId: groupId, userFlowId: Number(id) });
  };

  useEffect(() => {
    void handleGetUserFlows({ projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spinner isLoading={isGetUserFlowsLoading} />
      <section>
        <ButtonList
          countText="contacts"
          itemCountThreshold={10}
          listItems={userFlows.map((r) => {
            return { label: r.title, id: r.id };
          })}
          onListItemClick={handleAddGroupPermission}
        />
      </section>
      <SidebarButtons
        onCancel={onClose}
      />
    </>
  );
};
