import { gql } from '@apollo/client';

const CREATE_ASSET_CAPTION_MUTATION = gql`
  mutation CreateAssetCaption($assetId: String!, $from: Int!, $to: Int!, $text: String = null) {
    caption {
      add(assetId: $assetId, from: $from, to: $to, text: $text) {
        from
        to
        id
        text
      }
    }
  }
`;

export { CREATE_ASSET_CAPTION_MUTATION };
