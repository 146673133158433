import React from 'react';

import { Label, RadioButtonGroup } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { ConditionRadioQuestionProps } from './ConditionRadioQuestion.interfaces';

const ConditionRadioQuestion = ({ control, questionResponses }: ConditionRadioQuestionProps) => (
  <Controller
    control={control}
    name="questionResponseRadioId"
    render={({ field }) => (
      <>
        <Label
          additionalClassNames="c-action__item__label h-flex"
          forId="question response"
          labelText="Question response"
          tooltipPosition="start"
          tooltipText="This action will execute if the checkbox selection is as selected below"
        />
        <RadioButtonGroup
          items={questionResponses}
          selectedValue={field.value}
          {...field}
        />
      </>
    )}
  />
);


export { ConditionRadioQuestion };
