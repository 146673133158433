import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_DIRECTORY_MUTATION } from './useDeleteDirectory.graphql';
import {
  IDeleteDirectoryMutationGraphQLResponse,
  IDeleteDirectoryMutationVariables,
  IHandleDeleteDirectoryParams,
  IUseDeleteDirectory,
  IUseDeleteDirectoryOptions,
} from './useDeleteDirectory.interfaces';

const useDeleteDirectory = (options?: IUseDeleteDirectoryOptions): IUseDeleteDirectory => {
  const { onCompleted, onError, mutation, token } = options ?? ({} as IUseDeleteDirectoryOptions);


  const [executeDeleteDirectory, { loading: isLoading }] = useEkardoMutation<
  IDeleteDirectoryMutationGraphQLResponse,
  IDeleteDirectoryMutationVariables
  >({
    mutation: mutation ?? DELETE_DIRECTORY_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          directory: { removeDirectory: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteDirectory = ({ directoryId }: IHandleDeleteDirectoryParams) => {
    void executeDeleteDirectory({
      variables: {
        directoryId,
      },
    });
  };

  return {
    handleDeleteDirectory,
    isLoading,
  };
};

export { useDeleteDirectory };
