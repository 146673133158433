import { useVervetMutation } from '../useVervetMutation';

import { UPDATE_DISCOUNT_MUTATION } from './useUpdateDiscount.graphql';
import {
  IUpdateDiscountMutationGraphQLResponse,
  IUpdateDiscountMutationVariables,
  IHandleUpdateDiscountParams,
  IUseUpdateDiscountOptions,
  IUseUpdateDiscount,
} from './useUpdateDiscount.interfaces';

const useUpdateDiscount = (options?: IUseUpdateDiscountOptions): IUseUpdateDiscount => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateDiscountOptions);

  const [executeUpdateDiscount, { loading: isLoading }] = useVervetMutation<
    IUpdateDiscountMutationGraphQLResponse,
    IUpdateDiscountMutationVariables
  >({
    mutation: mutation ?? UPDATE_DISCOUNT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          discounts: { update: discountsConnection },
        } = data;

        onCompleted({
          discountsConnection,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateDiscount = async ({ code, description, percentage, period, maxUsage, id }: IHandleUpdateDiscountParams) => {
    await executeUpdateDiscount({
      variables: {
        code,
        description,
        percentage,
        period,
        maxUsage,
        id,
      },
    });
  };

  return {
    handleUpdateDiscount,
    isLoading,
  };
};

export { useUpdateDiscount };
