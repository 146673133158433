import { LogoAvatar, FlexContainer, Icon } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ILibraryDirectoryTableData } from './LibraryDirectoryPage.interfaces';


const LIBRARY_DIRECTORY_TABLE_COLUMNS: ReadonlyArray<Column<ILibraryDirectoryTableData>> = [
  {
    accessor: 'avatar',
    Cell: ({ value: { title, presignedUrl } }) => (
      <FlexContainer gap="x-small">
        <div className="">
          <LogoAvatar
            logoUrl={presignedUrl}
            size="small"
            title={title}
          />
        </div>

        <div className="flex items-center w-full">{title}</div>
      </FlexContainer>
    ),
    Header: <div className="text-left w-full">Title</div>,
    width: '43%',
  },
  {
    accessor: 'link',
    Cell: ({ value }) => (
      <div className="text-left w-full">
        {value ? (
          <a className="c-link" href={value}>
            {value}
          </a>
        ) : (
          <Icon className="c-minus-icon" id="id_minus_icon" />
        )}
      </div>
    ),
    Header: () => <div className="text-left w-full">Link</div>,
    width: '30%',
  },
  {
    accessor: 'phone',
    Cell: ({ value }) => (
      <div className="text-left w-full">
        {
          value ? (
            <a className="c-phone-link" href={`tel:${String(value).replace(/\s/g, '')}`}>{value}</a>
          ) : (
            <div>
              <Icon className="c-minus-icon" id="id_minus_icon" />
            </div>
          )
        }
      </div>
    ),
    Header: () => <div className="text-left w-full">Phone</div>,
    width: '15%',
  },
  {
    accessor: 'status',
    Cell: ({ value }) => (
      <div className="text-left w-full">{value}</div>
    ),
    Header: () => <div className="text-left w-full">Status</div>,
    width: '10%',
  },
  {
    accessor: 'settings',
    Cell: ({ value }) => <div className="flex justify-end">{value}</div>,
    Header: <span className="h-hide-visually">Settings</span>,
    width: '2%',
  },
];

export { LIBRARY_DIRECTORY_TABLE_COLUMNS };
