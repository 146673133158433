import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_CHARACTER_MUTATION } from './useCreateCharacter.graphql';
import {
  ICreateCharacterMutationGraphQLResponse,
  ICreateCharacterMutationVariables,
  IHandleCreateCharacterParams,
  IUseCreateCharacterOptions,
  IUseCreateCharacter,
} from './useCreateCharacter.interfaces';

const useCreateCharacter = (options?: IUseCreateCharacterOptions): IUseCreateCharacter => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateCharacterOptions);

  const [executeCreateCharacter, { loading: isLoading }] = useEkardoMutation<ICreateCharacterMutationGraphQLResponse, ICreateCharacterMutationVariables>({
    mutation: mutation ?? CREATE_CHARACTER_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          character: {
            addCharacter: character,
          },
        } = data;

        void onCompleted({
          character
        });
      },
      onError,
    },
    token,
  });

  const handleCreateCharacter = async ({ projectId, name, image }: IHandleCreateCharacterParams) => {
    await executeCreateCharacter({
      variables: {
        projectId,
        name,
        image,
      },
    });
  };

  return {
    handleCreateCharacter,
    isLoading,
  };
};

export { useCreateCharacter };
