import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { ISectionGroup } from '@netfront/ekardo-content-library';
import { getUpsertContentEventVariables } from 'components';
import { ICreateSectionGroupMutationParams, IUpdateSectionGroupMutationParams, useCreateContentEvent, useCreateSectionGroup, useToast, useUpdateSectionGroup } from 'hooks';

import { ISectionGroupConfig } from './useUpsertSectionGroup.interfaces';


const useUpsertSectionGroup = ({ onCreate, onUpdate }: { onCreate: () => void; onUpdate: (returnedSection: ISectionGroup) => void }) => {
  const sectionGroupVariables = useRef<{value :ISectionGroupConfig | undefined}>({value: undefined});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const getSectionGroupVariables = ({contentPageId, sectionGroupId, type }: {contentPageId?: number; sectionGroupId?: number; type: 'create' | 'update'; }): ICreateSectionGroupMutationParams | IUpdateSectionGroupMutationParams => {

    const { sectionGroup = {}, contentEvent = {}, shouldCreateEvent = false, shouldUpdateEvent = false } = sectionGroupVariables.current.value ?? {};

    const { sort, contentLayoutId, title, isVisible} = sectionGroup;

    const { contentEntity, contentEventId } = contentEvent;

    const variableMap = {
      create: {
        contentPageId: Number(contentPageId),
        sort,
        // title,
        // isVisible,
      } as ICreateSectionGroupMutationParams,
      update: {
        contentSectionGroup: {
          contentLayoutId,
          sectionGroupId: Number(sectionGroupId),
          sort,
          title,
          visible: isVisible,
        },
        shouldCreateEvent,
        shouldUpdateEvent,
        updateContentEvent: shouldUpdateEvent ? {
          contentEventId: Number(contentEventId),
          ...getUpsertContentEventVariables(contentEvent, 'update'),
        }: undefined ,
        createContentEvent: shouldCreateEvent ? {
          entityId: Number(sectionGroupId),
          contentEntity,
          ...getUpsertContentEventVariables(contentEvent, 'create'),
        }: undefined
      } as IUpdateSectionGroupMutationParams
    };

    return variableMap[type];
  };

  const { handleCreateContentEvent, isLoading: isCreateContentEventLoading = false } = useCreateContentEvent({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Section successfully created',
      });

      onCreate();

    },
    onError: handleGetError,
  });

  const { handleCreateSectionGroup, isLoading: isCreateSectionLoading = false } = useCreateSectionGroup({
    onCompleted: ({ sectionGroup : { id }}) => {

      const { shouldCreateEvent = false, contentEvent = {} } = sectionGroupVariables.current.value ?? {};

      if (!shouldCreateEvent) {
        handleToastSuccess({
          message: 'Section successfully created',
        });
        onCreate();
      } else {

        const { contentEntity } = contentEvent;
        handleCreateContentEvent({
          contentEvent: {
            entityId: id,
            contentEntity,
            ...getUpsertContentEventVariables(contentEvent, 'create'),
          }
        });
      }

    },
    onError: handleGetError,
  });

  const { handleUpdateSectionGroup, isLoading: isUpdateSectionLoading = false } = useUpdateSectionGroup({
    onCompleted: ({ sectionGroup: returnedSectionGroup }) => {
      handleToastSuccess({
        message: 'Section group successfully updated',
      });
      onUpdate(returnedSectionGroup);
    },
    onError: handleGetError,
  });

  const handleUpsertSectionGroup = ({ contentPageId, sectionGroupId, variables }: {contentPageId: number; sectionGroupId?: number; variables: ISectionGroupConfig; }) => {
    sectionGroupVariables.current.value = variables;

    if (!sectionGroupId) {

      const createSectionGroupVariables = getSectionGroupVariables({
        contentPageId: Number(contentPageId),
        type: 'create',
      }) as ICreateSectionGroupMutationParams;

      handleCreateSectionGroup({
        ...createSectionGroupVariables
      });

    } else {
      const updateSectionGroupVariables = getSectionGroupVariables({
        sectionGroupId: Number(sectionGroupId),
        type: 'update',
      }) as IUpdateSectionGroupMutationParams;

      handleUpdateSectionGroup({
        ...updateSectionGroupVariables,
      });
    }
  };

  return {
    isLoading: isCreateSectionLoading || isUpdateSectionLoading || isCreateContentEventLoading,
    handleUpsertSectionGroup,
  };
};
export { useUpsertSectionGroup };
