import { FormEvent, useState } from 'react';

import {
  ButtonIconOnly,
  FlexContainer,
  Icon,
  Label,
  SidebarButtons,
  Spacing,
  Spinner,
  Table,
} from '@netfront/ui-library';
import { Uploader } from 'components';
import { read as readXLSX, utils as utilsXLSX } from 'xlsx';


import { GlossaryItemBulkAddTabProps } from './GlossaryItemBulkAddTab.interfaces';

import { useCreateBulkGlossaryItems, useToast } from '../../../../hooks';

const GlossaryItemBulkAddTab = ({ projectId, onClose, onUpdate }: GlossaryItemBulkAddTabProps) => {

  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();

  const [newGlossaryItems, setNewGlossaryItems] = useState<{description: string; id: string; title: string; }[]>([]);


  const { handleCreateBulkGlossaryItems, isLoading: isCreateBulkGlossaryItemsLoading = false } = useCreateBulkGlossaryItems({
    onCompleted: () => {
      handleToastSuccess({
        message: `Glossary items successfully created`,
      });
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleCreateBulkInvitations = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newGlossaryItems.find((r) => r.title === '' || r.description === '')) {
      handleToastCustomError({ message: 'All the excel fields must be mapped-out' });
      return;
    }


    void handleCreateBulkGlossaryItems({
      projectId,
      items: newGlossaryItems.map(({title, description}) => {
        return {
          title,
          description,
        }
      }),
    });
  };


  const handleReadExcel = (input: File) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const { target } = event;
      const workbook = readXLSX(target?.result, {
        type: 'binary',
      });

      const [sheet] = Object.values(workbook.Sheets);
      const contentAsObject = utilsXLSX.sheet_to_json(sheet) as [];
      const formattedItems = contentAsObject.map(({Title: title, Description: description}) => {
        return {
          title,
          description,
          id: (Math.random() + 1).toString(36).substring(7)
        };
      });

      setNewGlossaryItems(formattedItems);
    };
    reader.readAsBinaryString(input);
  }

  const handleClearGlossaryItems = () => {
    setNewGlossaryItems([]);
  };

  const handleFileDrop = (uploadedFile: File | undefined) => {
    if (uploadedFile) handleReadExcel(uploadedFile);
  };

  const isLoading = isCreateBulkGlossaryItemsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />

      <form
        onSubmit={handleCreateBulkInvitations}
      >


        <Spacing>
          {Boolean(newGlossaryItems.length) && (
            <FlexContainer>
              <Label fontWeight="semi-bold" forId="" labelText="Uploaded items" labelType={'span'}/>
              <ButtonIconOnly
                iconId="id_close_icon"
                text="close sidebar"
                onClick={handleClearGlossaryItems}
              />
            </FlexContainer>
          )}
          {Boolean(newGlossaryItems.length) && (
            <>
              <Table
                columns={[
                  {
                    accessor: 'title',
                    Cell: ({ value }: { value: string }) => <div>{value}</div>,
                    Header: () => <div>Title</div>,
                  },
                  {
                    accessor: 'description',
                    Cell: ({ value }: { value: string }) => <div>{value}</div>,
                    Header: <div>Description</div>,
                  },
                ]}
                data={newGlossaryItems}
              />
            </>
          )}
        </Spacing>

        <Spacing size="2x-large">
          <Label fontWeight="semi-bold" forId="" labelText="Download template" labelType={'span'} tooltipText="Download our predefined excel template to add your glossary items to"/>
          <a
            href="https://ekardo.s3.ap-southeast-2.amazonaws.com/file-templates/glossary_item_template.xlsx"
            id="download-template"
            download
          >
            <Icon className="c-icon--large" id="id_xlsx_icon" />
          </a>
        </Spacing>

        <Uploader
          assetType={'document'}
          labelText={'Upload region'}
          tooltipText={'Upload multiple glossary items at once by using an excel document'}
          onDelete={handleClearGlossaryItems}
          onDrop={handleFileDrop}
        />

        <SidebarButtons
          buttonSize="xs"
          isSaveButtonDisabled={Boolean(!newGlossaryItems.length)}
          submitButtonText="Save"
          onCancel={onClose}
          onSaveButtonType="submit"
        />

      </form>
    </>
  );
};

export { GlossaryItemBulkAddTab };
