import { gql } from '@apollo/client';

const EXPORT_PROFILE_INFORMATION = gql`
  query ($projectId: String!, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType, $ianaTimeZone:String! $groupId: Int) {
    report {
      exportGroupUsers(projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType,ianaTimeZone:$ianaTimeZone, groupId: $groupId) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;


export { EXPORT_PROFILE_INFORMATION };
