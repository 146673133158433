import { gql } from '@apollo/client';


const BULK_UPSERT_LANGUAGE_FILTER = gql`
  mutation upsertLanguageFilter($items: [LanguageFilterItemInputType]!) {
    languageFilter {
      bulkAddOrUpdate(items: $items)
    }
  }
`;

export { BULK_UPSERT_LANGUAGE_FILTER };
