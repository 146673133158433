import React, { ChangeEvent, useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { InformationBox, Select, Spacing, ToggleSwitch } from '@netfront/ui-library';
import { ContentBuilderTabWrapper } from 'components';
import { Controller } from 'react-hook-form';


import { animationSpeedOptions, animationTypeOptions, delayTimeOptions, animationDirectionOptions, typesWithAnimationDirection } from './AnimationTab.constants';
import { AnimationTabProps } from './AnimationTab.interfaces';


const AnimationTab = ({ control, animationType, isSnippet = true }: AnimationTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const [hasAnimationDirection, setHasAnimationDirection] = useState<boolean>(false);


  useEffect(() => {
    if (!animationType) return;

    setHasAnimationDirection(typesWithAnimationDirection.includes(animationType));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationType]);



  return (
    <ContentBuilderTabWrapper isSnippet={isSnippet}>
      <Spacing>
        <InformationBox message="Edit your animation configuration." />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="animation"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_animation_type"
              labelText="Animation tyoe"
              options={animationTypeOptions}
              {...field}
              isLabelSideBySide
              onChange={(changeEvent: ChangeEvent<HTMLSelectElement>) => {
                const { target: { value }} = changeEvent;
                field.onChange(changeEvent);
                setHasAnimationDirection(typesWithAnimationDirection.includes(value));
              }}
            />
          )}
        />
      </Spacing>
      {hasAnimationDirection && (
        <Spacing>
          <Controller
            control={control}
            name="animationDirection"
            render={({ field, fieldState }) => (
              <Select
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_animation_direction_type"
                labelText="Animation direction"
                options={animationDirectionOptions}
                isLabelSideBySide
                {...field}
              />
            )}
          />
        </Spacing>
      )}

      <Spacing>
        <Controller
          control={control}
          name="millisecondDelay"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_delay_time"
              labelText="Delay Time"
              options={delayTimeOptions}
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="millisecondSpeed"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_animation_speed"
              labelText="Animation Speed"
              options={animationSpeedOptions}
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isInfinite"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames="c-sidebar-toggle"
              id="id_is_infinite"
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText="Infinite"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </ContentBuilderTabWrapper>
  );
};

export { AnimationTab };
