

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { EXPORT_PROFILE_INFORMATION } from './useGetProfileInformationReport.graphql';
import {
  IGetProfileInformationReportQueryGraphQLResponse,
  IGetProfileInformationReportQueryVariables,
  IHandleGetProfileInformationReportParams,
  IUseGetProfileInformationReport,
  IUseGetProfileInformationReportOptions,
} from './useGetProfileInformationReport.interfaces';

const useGetProfileInformationReport = (options?: IUseGetProfileInformationReportOptions): IUseGetProfileInformationReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetProfileInformationReportOptions);

  const [executeGetProfileInformationReport, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetProfileInformationReportQueryGraphQLResponse,
    IGetProfileInformationReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportGroupUsers } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportGroupUsers,
        });
      },
      onError,
    },
    query: query ?? EXPORT_PROFILE_INFORMATION,
    token,
  });


  const handleGetProfileInformationReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
    groupId,
  }: IHandleGetProfileInformationReportParams) => {
    await executeGetProfileInformationReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
        groupId,
      },
    });
  };

  return {
    handleGetProfileInformationReport,
    isLoading,
  };
};

export { useGetProfileInformationReport };
