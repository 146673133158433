
import { IPostLogsTableData, IPostLogsTableDataParams } from "./PostLogsReportPage.interfaces";


const getPostLogsTableData = ({ items }: IPostLogsTableDataParams): IPostLogsTableData[] => {
  return items.map(({
    action = '',
    groupName = '',
    id,
    post = '',
    postDate = '',
    postDateUtc = '',
    postId = '',
    userType = '',
   }) => {
    return {
      action,
      groupName,
      id,
      post,
      postDate,
      postDateUtc,
      postId,
      userType,
    };
  });
};

export { getPostLogsTableData };
